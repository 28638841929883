import React, { FC, useCallback, useMemo, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@apollo/react-hooks";
import {MNPRouteState} from "../../MNPFlowRoutes";
import {useAuthDataContext} from "../../../../shared/hooks/AuthentificationProvider";
import AcquisitionMessages from "../../../acquisitionFlow/Acquisition.messages";
import {
	DeliveryTypeEnum,
	Mutation,
	MutationCreate_Mnp_OrderArgs,
	Package,
	ProductTypeEnum, SalesTypeEnum,
	SubscriptionInput
} from "../../../../graphql/types";
import {
	genCustomerCreateOrder,
	genCustomerGenerateDocument, genExistingCustomerGenerateDocument,
	prepareExistingCustomerForCreateOrder
} from "../../../../shared/utils";
import {PayloadProps} from "../../../../shared/hooks/useDocumentGeneration";
import {useAcquisitionUploadDocument} from "../../../../shared/hooks/useAcquisitionUploadDocument";
import {CREATE_MNP_ORDER} from "../../../../graphql/mutations/order";
import CommonMessages from "../../../../Common.messages";
import {INDEX_PATH} from "../MNPFlowRoutes";
import {
	CircularProgress,
	DocumentGeneration,
	SuccessMessageTemplate,
	UploadFiles
} from "../../../../shared/components";
import {MnpMessages} from "../../Mnp.messages";
import {PlanCard} from "../../../fmc/Pages/Summary/PlanCard";
import {OptionsCard} from "../../../../shared/components/option/OptionsCard";
import {ErrorMessage} from "../../../../shared/components/ErrorMessageTemplate";
import {TARIFF_PLAN_TYPE_POSTPAID} from "../../../acquisitionFlow/NewCustomer/Pages";
import {StepWrapper} from "../../../../shared/components/navigation/StepWrapper";
import moment from "moment";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";



const MnpSummary: FC<RouteComponentProps<never, StaticContext, MNPRouteState>> = props => {
	const intl = useIntl();
	const { history} = props;
	const state = useNavigationState<MNPRouteState>();
	const {userName, currentDealerIdentety} = useAuthDataContext();
	const [spinner, runSpinner] = useState<boolean>(false);
	const [errorMsg, setError] = useState<string>("");
	const [order, createOrderFor] = useState<string>("");
	const [confirmationCode, setConfirmationCode] = useState<string|undefined|null>("");
	const textInfo = useMemo(() => intl.formatMessage(AcquisitionMessages.infoMessageUploadDocument), [intl.locale]);

	const msisdn = state?.msisdn || "";
	const {donor_first_name, donor_last_name, donor_passport_id_card, donor_okpo} = state;

	const skipCustomerData = state?.personalInfo?.skipPersonalData;
	const options = state?.options?.filter((item: Package) => item.code)?.map(item => item.code) || [];
	if(state?.plan?.selectedOptionCode) {
		options.push(state?.plan?.selectedOptionCode);
	}
	const {...customer} = state?.personalInfo || state?.customer;
	const customerForCreateOrder = state?.isNewCustomer ? genCustomerCreateOrder(customer, skipCustomerData) : prepareExistingCustomerForCreateOrder(customer, intl.locale);
	let contractPayload;
	if (state?.isNewCustomer) {
		contractPayload = {
			...genCustomerGenerateDocument(customer),
			tariffPlanName: state?.plan?.code,
			tariffPlanType: state?.plan?.planType,
			salesman_username: userName,
			orderType: undefined, //TODO,
			icc: state?.iccId
		} as PayloadProps;
	} else {
		contractPayload = {
			...genExistingCustomerGenerateDocument(state?.customer!, intl.locale),
			tariffPlanName: state?.plan?.code,
			tariffPlanType: state?.plan?.planType,
			salesman_username: userName,
			orderType: undefined, //TODO
			icc: state?.iccId
		} as PayloadProps;
	}

	const [uploadDocument, files, setFiles, uploadProgress, error] = useAcquisitionUploadDocument();
	const [createOrder] = useMutation<Required<Pick<Mutation, "create_mnp_order">>, MutationCreate_Mnp_OrderArgs>(CREATE_MNP_ORDER, {
		onError: () => {
			setError(intl.formatMessage(CommonMessages.createOrderFail, {msisdn}));
		}
	});

	const onClickDone = useCallback(async(): Promise<any> => {
		history.push(INDEX_PATH);
	}, []);

	const productSubscription = (): SubscriptionInput => {
		const userAndPayer = skipCustomerData ? {} : {
			payer: customerForCreateOrder,
			user: customerForCreateOrder,
		};
		const isMinPossibleDate = state?.minPossibleDate;
		return {
			msisdn,
			type: state?.plan?.code!,
			packages: options,
			product_type: ProductTypeEnum.Subscription,
			...userAndPayer,
			mnp: {
				document_id: customer?.id_document_number!,
				msisdn: state.msisdn,
				current_operator: state.donorOperator,
				starter_kit: state.is_starterkit,
				contract_signed: true,
				...(!isMinPossibleDate && {transfer_time: moment.parseZone(state?.transferTime).utc(true).format()}),
				donor_segment: state.donor_segment,
				...(donor_first_name ? {donor_first_name}: null),
				...(donor_last_name ? {donor_last_name}: null),
				...(donor_passport_id_card ? {donor_passport_id_card}: null),
				...(donor_okpo ? {donor_okpo}: null)
			}
		} as SubscriptionInput;
	};
	const onSubmit = () => {
		runSpinner(false);
		setError("");
		let billing_account;
		if (state?.isNewCustomer) {
			billing_account = {};
		} else 	billing_account = state?.account_id ? { billing_account: { id: state?.account_id, type: state?.billing_type }} : {billing_account: { type: state?.plan?.billing_type }};

		createOrder({
			variables: {
				details: {
					offer: state?.plan?.offerCode!,
					delivery_type: DeliveryTypeEnum.Direct,
					customer: customerForCreateOrder,
					sales_info: {
						sales_type: SalesTypeEnum.MnpAcquisition,
						reseller_code: currentDealerIdentety?.reseller_code!
					},
					products: {
						subscription: productSubscription(),
						sim: {
							product_type: ProductTypeEnum.Simcard,
							type: state?.plan?.offerSimCardCode!,
							icc: state?.iccId || "",
							msisdn: {
								msisdn: msisdn || "",
							},
							reservation_id: "",
						}
					},
					...billing_account,
				}
			}
		}).then(({data}) => {
			if (data?.create_mnp_order?.status === 202) {
				setConfirmationCode(data?.create_mnp_order?.attributes?.confirmation_code);
				if (files.length > 0) {
					uploadDocument({
						purpose: "MNP_FLOW",
						confirmation_code: ""//TODO no reservation code for mnp flow
					}, () => {
						runSpinner(false);
					}, () => {
						runSpinner(false);
					});
				} else {
					runSpinner(false);
				}
				createOrderFor(msisdn);
			} else if (data?.create_mnp_order?.error) {
				throw data?.create_mnp_order?.error;
			} else {
				throw intl.formatMessage(CommonMessages.createOrderFail, {msisdn});
			}
		}).catch((error) => {
			setError(error);
			runSpinner(false);
		});
	};
	return (
		<StepWrapper<MNPRouteState> isValid>
			<div className="ipos-content-box SummaryPage">
				<div className="row">
					<div className="col-12 col-md-6">
						<h4><FormattedMessage {...MnpMessages.mnpMsisdn} />: {state?.msisdn}</h4>
					</div>
					<div className="col-12 col-md-4">
						<h4><FormattedMessage {...CommonMessages.icc} />: {state?.iccId}</h4>
					</div>
					<div className="col-12 col-md-6">
						<h4><FormattedMessage {...MnpMessages.donorOprator} />: {state?.donorOperator}</h4>
					</div>
					<div className="col-12">
						<PlanCard
							change_price={state?.plan?.change_price!}
							fee={state?.plan?.fee!}
							periodic_unit={state?.plan?.periodicUnit!}
							periodic_amount={state?.plan?.periodicAmount!}
							name={state?.plan?.name}
							medium_description={state?.plan?.medium_description}
							long_description={state?.plan?.long_description}
						/>
					</div>
					<div className="col-12 my-3">
						<OptionsCard packages={state?.options}/>
					</div>
				</div>
				<DocumentGeneration
					skipCustomerData={skipCustomerData}
					payload={contractPayload}
					msisdn={msisdn}
					setContractGenerationError={setError}
				/>
				<UploadFiles
					setFiles={setFiles}
					files={files}
					uploadProgress={uploadProgress}
					error={error}
					hideDocumentLink={true}
					textInfo={textInfo}
					required={!skipCustomerData}
				/>
				<SuccessMessageTemplate show={Boolean(order)}>
					<FormattedMessage {...CommonMessages.createOrderSuccess} values={{order}}/>
				</SuccessMessageTemplate>
				<SuccessMessageTemplate show={Boolean(confirmationCode)}>
					<FormattedMessage {...CommonMessages.orderId} values={{confirmationCode}}/>
				</SuccessMessageTemplate>
				<ErrorMessage error={errorMsg}/>
				<div className="text-right">
					{spinner && (<span className="pr-2"><CircularProgress showText={false}/></span>)}
					{Boolean(order) ? (
						<button type="button" className="btn btn-primary" onClick={onClickDone}>
							<FormattedMessage {...CommonMessages.done} />
						</button>
					) : (
						<button type="button" className="btn btn-primary" onClick={onSubmit}
							disabled={!Boolean(files?.length && files?.length > 0) && state.plan.planType === TARIFF_PLAN_TYPE_POSTPAID}>
							<FormattedMessage {...CommonMessages.submit} />
						</button>
					)}
				</div>
			</div>
		</StepWrapper>
	);
};

export { MnpSummary };
