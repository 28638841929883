import gql from "graphql-tag";
import { Customer } from "../fragments/customer.fragments";

export const GET_EXISTING_COMPANY = gql`
    query getExistingCompany($first_name: String, $business_uid: String) {
        companys(first_name: $first_name, business_uid: $business_uid) {
            ...Customer
        }
    }
    ${Customer}
`;

export const GET_RESERVED_BILLING_ACCOUNT_ID = gql`
    mutation getReservedBillingAccountId {
        billing_account_id_reservations {
            request_id,
            message,
            status,
            error
        }
    }
`;

export const GET_BILLING_ACCOUNTS = gql`
    query getBillingAccounts($company_id: ID!, $page_size: Int, $page_number: Int) {
        company_billing_accounts(company_id: $company_id, page_size: $page_size, page_number: $page_number) {
            id,
            name,
            invoice_delivery_method,
            payment_method,
            status,
            type {
                code,
                name
            }
        }
    }
`;
