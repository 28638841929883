export interface HasId {
	id: string;
}

class GraphQLUtils {
	/**
	 * Converts input data that comes directly from GraphQL into array of T filtering out all null values
	 */
	static convertToArray<T>(items: Array<T | null> | null | undefined): Array<T> {
		if (!items) {
			return [];
		} else {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return items.filter((option: T | null) => !!option).map((option: T | null): T => option!);
		}
	}

	/**
	 * Converts input data that comes directly from GraphQL into array of T and adds an ID. In case if ID was not null
	 * in initial data - keeps that id, otherwise adds ID based on the index of element in the input array.
	 */
	static convertToArrayWithId<T extends object>(items: Array<T | null> | null | undefined, idField: string = "id"): Array<T & HasId> {
		if (!items) {
			return [];
		} else {
			return items
				.filter((option: T | null) => !!option)
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				.map((option: T | null): T => option!)
				.map((option: T, index: number): T & HasId => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const id = (option.hasOwnProperty(idField) ? (option as any)[idField] : index + "") as string;
					return {
						...option,
						id
					};
				});
		}
	}
}

export {
	GraphQLUtils,
};
