import React, {FC, useMemo, useState} from "react";
import { useFormik } from "formik";
import { ApolloError } from "apollo-client";
import {CircularProgress, InputFieldFormik, SuccessMessageTemplate} from "../../../../shared/components";
import { RouteComponentProps, StaticContext } from "react-router";
import {MNPRouteState} from "../../MNPFlowRoutes";
import {FormattedMessage, useIntl} from "react-intl";
import {useApolloClient, useQuery} from "@apollo/react-hooks";
import {DonorSegmentEnum, Query, QueryOtp_SubscriptionArgs} from "../../../../graphql/types";
import {GET_DATES_OF_PORTATION, MNP_QUERY} from "../../../../graphql/queries/subscription";
import {MnpMessages} from "../../Mnp.messages";
import {
	MNP_FLOW_BILLING_ACCOUNT_POSTPAID,
	MNP_FLOW_PLAN_POSTPAID,
} from "../MNPFlowRoutes";
import {mnpData} from "../../Mnp.schema";
import {useOtp} from "../../../../shared/components/Otp";
import moment, {Moment} from "moment";
import {config, FIRST_DAY_OF_WEEK} from "../../../../config/constants";
import {SelectFieldFormik} from "../../../../shared/components/SelectField";
import {DONOR_OPERATORS} from "../../../../shared/utils/common_mock_data";
import { SingleDatePicker } from "react-dates";
import UserMessages from "../../../user/User.messages";
import {ErrorMessage} from "../../../../shared/components/ErrorMessageTemplate";
import CommonMessages from "../../../../Common.messages";
import {FormStepWrapper} from "../../../../shared/components/navigation/FormStepWrapper";
import { useNavigationState, useUpdateNavigatioState } from "../../../../shared/components/navigation/useNavigationState";
import { DonorSegment } from "../../DonorSegment";
import { InputWithCheckBox } from "../../../../shared/components";

const MNPMsisdnValidationPage: FC<RouteComponentProps<never, StaticContext, MNPRouteState>> = props => {
	const intl = useIntl();
	const client = useApolloClient();
	const state = useNavigationState<MNPRouteState>();
	const updateNavigationState  = useUpdateNavigatioState<MNPRouteState>();
	const { history } = props;
	const [error, setError] = useState<string>("");
	const [success, setSuccess] = useState<boolean>(Boolean(state?.msisdn));

	const searchMsisdn = (msisdn: string, otp: string): Promise<string|void|Required<Pick<Query, "otp_subscription">>> => {
		return client.query<Required<Pick<Query, "otp_subscription">>, QueryOtp_SubscriptionArgs>({
			query: MNP_QUERY,
			fetchPolicy: "network-only",
			variables: {
				msisdn,
				otp
			}
		}).then(({data}) => {
			if (data?.otp_subscription?.subscription?.id) {
				setSuccess(false);
				setError(intl.formatMessage(MnpMessages.kyivstarMsisdn));
			} else {
				setSuccess(true);
				setError("");
			}
		}).catch((error: ApolloError) => {
			if (error.message.includes("No active subscription found")) {
				setSuccess(true);
				setError("");
				return;
			} else {
				setSuccess(false);
				throw new Error("403");
			}
		});
	};
	const onSubmit = (values) => {
		if (!error) {
			updateNavigationState( {
				...state,
				msisdn: values.msisdn,
				donorOperator: values.donorOperator,
				dateValue: dateValue,
				timeValue,
				transferTime,
				donor_segment: values.donor_segment,
				donor_first_name: (values.donor_segment === DonorSegmentEnum.b2cPassportized || values.donor_segment === DonorSegmentEnum.mmc) ? values.donor_first_name : "",
				donor_last_name: (values.donor_segment === DonorSegmentEnum.b2cPassportized || values.donor_segment === DonorSegmentEnum.mmc) ? values.donor_last_name : "",
				donor_passport_id_card: values.donor_segment === DonorSegmentEnum.popEntrepreneur ? values.donor_passport_id_card : "",
				donor_okpo: values.donor_segment === DonorSegmentEnum.popB2b ? values.donor_okpo : "",
				id_document_type: values.donor_segment === DonorSegmentEnum.popEntrepreneur ? values.id_document_type : "",
				minPossibleDate: minPossibleDate
			} as MNPRouteState);
			history.push({
				pathname: state?.isNewCustomer ? MNP_FLOW_PLAN_POSTPAID : MNP_FLOW_BILLING_ACCOUNT_POSTPAID,
			});
		}
	};

	const validationSchema = useMemo(() => mnpData(intl), [intl.locale]);

	const {errors, setFieldValue, touched, handleChange, values, handleBlur, handleSubmit} = useFormik({
		initialValues: {
			msisdn: state?.msisdn || "",
			donorOperator: state?.donorOperator || "",
			dateValue: state?.dateValue || "",
			timeValue: state?.timeValue,
			transferTime: state?.transferTime,
			donor_segment: state?.donor_segment || "",
			donor_first_name: state?.donor_first_name || "",
			donor_last_name: state?.donor_last_name || "",
			donor_passport_id_card: state?.donor_passport_id_card || "",
			donor_okpo: state?.donor_okpo || "",
			id_document_type: state?.id_document_type || "",
		},
		onSubmit,
		validationSchema: validationSchema,
	});
	const {handleOtp, loading} = useOtp(values.msisdn, searchMsisdn);

	const {data: portationDates, loading: portationDatesLoading} = useQuery<Query>(GET_DATES_OF_PORTATION);
	const clearError = () => {
		setError("");
		setSuccess(false);
	};

	const formik = {errors, setFieldValue, touched, handleChange, values, handleBlur};

	const onMsisdnChange = () => {
		clearError();
		handleOtp(values.msisdn);
	};

	const [focused, setFocused] = useState();
	const [dateValue, setDateValue] = useState<Date|undefined|null>(state?.dateValue);
	const [timeValue, setTimeValue] = useState<string>(state?.timeValue || "00:00");
	const [transferTime, setTransferTime] = useState<Date>(state?.transferTime);
	const [minPossibleDate, setMinPossibleDate] = useState<boolean|null>(state?.minPossibleDate || true);

	const onFocusChange = ({ focused }) => {
		setFocused(focused);
	};

	const onDateChange = (date: Moment) => {
		setDateValue(date.toDate());
	};

	const onTimeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
		const times = ev.target.value.split(":");
		setTimeValue(ev.target.value);
		if (dateValue) {
			const dateWithTime = moment(dateValue).set("hour", parseInt(times[0])).set("minute", parseInt(times[1]));
			setTransferTime(dateWithTime.toDate());
		}
	};

	const showDateVal = dateValue && moment(dateValue);
	const isBlocked = day => {
		const availableDates = portationDates?.portin_duedates?.map(eachDay => moment(eachDay?.due_date, "DD.MM.YYYY"));
		return !availableDates?.some(date => day.isSame(date, "day"));
	};
	const formId = "mnp-validation-form";

	const onChangeInt = (selected: boolean) => {
		if (selected) {
			setDateValue(null);
			setTimeValue("00:00");
			setMinPossibleDate(true);
		} else {
			setDateValue(state?.dateValue);
			setTimeValue(state?.timeValue || "00:00");
			setMinPossibleDate(false);
		}
	};

	return (<>
		<FormStepWrapper<MNPRouteState> isValid newState={{...state}} formId={formId}>
			<div className="ipos-content-box">
				<form id={formId} onSubmit={handleSubmit}>
					<div className="row">
						<div className="col-sm-4 col-12">
							<InputFieldFormik
								required
								name="msisdn"
								id="msisdn"
								attr={{ maxLength: config.PHONE_LENGTH }}
								placeholder={intl.formatMessage(MnpMessages.enterMsisdn)}
								value={formik.values.msisdn}
								formik={formik}
							/>
						</div>
						<div className="col-sm-4 col-12 mb-3">
							<button type="button" className="btn btn-primary btn-block" onClick={onMsisdnChange}>
								<FormattedMessage {...UserMessages.search} />
							</button>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-4 col-12 mt-3">
							<SelectFieldFormik
								required
								showPlaceholder={true}
								name="donorOperator"
								id="donor-operator"
								values={DONOR_OPERATORS(intl)}
								attr={{ maxLength: config.PHONE_LENGTH }}
								placeholder={intl.formatMessage(MnpMessages.chooeseDonorOperator)}
								value={formik.values.donorOperator}
								formik={formik}
							/>
						</div>
						<div className="col-sm-4 col-12">
							<FormattedMessage {...MnpMessages.dateOfPortation}/>
							<InputWithCheckBox
								label={"Min. possible date"}
								id={"individual_tax_number"}
								checked={true}
								onChange={onChangeInt}
								className="col-sm-12"
							>
								{(selected) => (
									<div className="d-flex form-group">
									<SingleDatePicker
										date={showDateVal}
										focused={focused}
										onFocusChange={onFocusChange}
										placeholder={config.DATE_PICKER_FORMAT}
										id={"date-of-portation"}
										block={false}
										onDateChange={onDateChange}
										displayFormat={config.DATE_PICKER_FORMAT}
										numberOfMonths={1}
										hideKeyboardShortcutsPanel={true}
										firstDayOfWeek={FIRST_DAY_OF_WEEK}
										isDayBlocked={isBlocked}
										disabled={selected}
									/>
									<div className="time-picker">
										<input
											required
											className="form-control"
											type="time"
											step="60"
											value={timeValue}
											placeholder="Time"
											onChange={onTimeChange}
											disabled={selected}
										/>
									</div>
								</div>	
								)}
							</InputWithCheckBox>
						</div>
					</div>
					<DonorSegment formik={formik} />
				</form>
				<ErrorMessage error={error} />
				<SuccessMessageTemplate show={success}>
					{intl.formatMessage({ ...CommonMessages.otpSuccess })}
				</SuccessMessageTemplate>
				{loading || portationDatesLoading && <CircularProgress />}
			</div>
		</FormStepWrapper>
	</>
	);
};

export {
	MNPMsisdnValidationPage
};