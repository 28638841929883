/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface FmcPostpaidMessagesType {
	address: MessageDescriptor;
	balance: MessageDescriptor;
	billingAccountNumber: MessageDescriptor;
	billingAccountType: MessageDescriptor;
	customer: MessageDescriptor;
	debt: MessageDescriptor;
	document: MessageDescriptor;
	eligibleForFmc: MessageDescriptor;
	inn: MessageDescriptor;
	issueDate: MessageDescriptor;
	no: MessageDescriptor;
	options: MessageDescriptor;
	packagesToBeDeactivated: MessageDescriptor;
	plan: MessageDescriptor;
	search: MessageDescriptor;
	summary: MessageDescriptor;
	title: MessageDescriptor;
	validUntil: MessageDescriptor;
	yes: MessageDescriptor;
}
const FmcPostpaidMessages: FmcPostpaidMessagesType = defineMessages({
	address: {
		id: "fmc-postpaid-customer-details-address",
		description: "Customer details Address label",
		defaultMessage: "Address"
	},
	balance: {
		id: "fmc-postpaid-customer-details-balance",
		description: "Customer details balance label",
		defaultMessage: "Balance"
	},
	billingAccountNumber: {
		id: "fmc-postpaid-billing-account-details-billing-account-number",
		description: "Billing account details - Billing account number label",
		defaultMessage: "Billing account number"
	},
	billingAccountType: {
		id: "fmc-postpaid-billing-account-details-billing-account-type",
		description: "Billing account details - Billing account number label",
		defaultMessage: "Billing account type"
	},
	customer: {
		id: "fmc-postpaid-customer-details-customer",
		description: "Customer details Customer label",
		defaultMessage: "Customer"
	},
	debt: {
		id: "fmc-postpaid-customer-details-debt",
		description: "Customer details debtlabel debt",
		defaultMessage: "{lineType} debt"
	},
	document: {
		id: "fmc-postpaid-customer-details-document",
		description: "Customer details document label",
		defaultMessage: "Document"
	},
	eligibleForFmc: {
		id: "fmc-postpaid-customer-details-eligible-for-fmc",
		description: "Customer details Eligible for FMC label",
		defaultMessage: "Eligible for FMC"
	},
	inn: {
		id: "fmc-postpaid-billing-account-details-billing-account-inn",
		description: "Billing account details INN label",
		defaultMessage: "INN"
	},
	issueDate: {
		id: "fmc-postpaid-customer-details-issue-date",
		description: "Customer details Issue date label",
		defaultMessage: "Issue date"
	},
	no: {
		id: "fmc-postpaid-customer-details-eligible-for-fmc-no",
		description: "Customer details Eligible for FMC label answer no",
		defaultMessage: "No"
	},
	options: {
		id: "fmc-postpaid-customer-details-options",
		description: "Customer details options label",
		defaultMessage: "Options"
	},
	packagesToBeDeactivated: {
		id: "fmc-postpaid-packages-to-be-deactivated",
		description: "Packages deactivated title",
		defaultMessage: "Packages deactivated"
	},
	plan: {
		id: "fmc-postpaid-flow-step-title-plan",
		description: "Title for FMC postpaid step plan",
		defaultMessage: "Plan"
	},
	search: {
		id: "fmc-postpaid-flow-step-title-search",
		description: "Title for FMC postpaid step search",
		defaultMessage: "Search"
	},
	summary: {
		id: "fmc-postpaid-flow-step-title-summary",
		description: "Title for FMC postpaid step summary",
		defaultMessage: "Summary"
	},
	title: {
		id: "fmc-postpaid-flow-title",
		description: "Title for FMC button main screen",
		defaultMessage: "Postpaid FMC"
	},
	validUntil: {
		id: "fmc-postpaid-customer-details-valid-until",
		description: "Customer details Valid until label",
		defaultMessage: "Valid until"
	},
	yes: {
		id: "fmc-postpaid-customer-details-eligible-for-fmc-yes",
		description: "Customer details Eligible for FMC label answer yes",
		defaultMessage: "Yes"
	},
});

export default FmcPostpaidMessages;
export { FmcPostpaidMessages };
