import React, { useCallback, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { PrePayForm } from "./PrePay/PrePayForm";
import {
	ACQUISITION_FLOW_ICCID_PATH,
	ACQUISITION_FLOW_MSISDN_PATH,
	ACQUISITION_FLOW_SUMMARY_PATH
} from "../../Routes/AcquisitionFlowRoutes";
import AcquisitionMessages from "../../../Acquisition.messages";
import { NavigationHeader, CircularProgress } from "../../../../../shared/components";
import { TARIFF_PLAN_TYPE_POSTPAID, TARIFF_PLAN_TYPE_PREPAID } from "../index";
import { AcquisitionFlow } from "../../Routes/AcquisitionFlow";
import { PostPayFlow } from "./PostPayForm/PostPayFlow";
import { AcquisitionFlowState } from "../SummaryPage/SummaryPage";
import { config } from "../../../../../config/constants";
import { useMutation } from "@apollo/react-hooks";
import { Mutation, MutationCheck_Cdt_Consumer_Credit_DecisionArgs, GisStreet } from "../../../../../graphql/types";
import { CHECK_CUSTOMER_CREDIT_DECISION } from "../../../../../graphql/mutations/customerMutation";
import { ErrorMessage } from "../../../../../shared/components/ErrorMessageTemplate";
import { CdtUtils } from "../../../../../shared/utils";
import { GisStreetSearchResult } from "../../../../../graphql/localTypes";

const NOT_DEFINED_STREET = { selected: [{ id: "Not Defined", name: "" }] as GisStreet[], result: [] } as unknown as GisStreetSearchResult;
const initGist = {
	result: [],
	selected: []
};
const initialValues = {
	skipPersonalData: false,
	first_name: "",
	last_name: "",
	gender: "",
	nationality: "",
	id_document_number: "",
	id_document_type: "",
	contact_phone: "",
	date_of_birth: "",
	middle_name: "",
	first_name_alt: "",
	comments: "",
	country: config.DEFAULT_COUNTRY,
	province: initGist,
	city: initGist,
	streetType: "",
	street: initGist,
	building: "",
	house_number: initGist,
	email: "",
	additional_emails: "",
	postal_code: "",
	apartment: "",
	district: initGist,
	is_company: false,
	password: "",
	id_doc_issued_by: "",
	id_doc_issue_date: "",
	id_doc_valid_till_date: "",
	individual_tax_number: "",
	isEmpty: true,
	authorized: false,
	name: "",
};
const WITHOUT_DOCUMENT_INIT_FORM_VALUE = {
	last_name: "",
	middle_name: "",
	first_name: "",
	gender: "",
	date_of_birth: "",
	id_document_type: "passport",
	street: NOT_DEFINED_STREET,
	nationality: "UA",
	is_company: false,
	id_document_number: "",
	contact_phone: "",
};

const WITH_DOCUMENT_INIT_FORM_VALUE = {
	id_doc_valid_till_date: "",
	individual_tax_number: "",
	last_name: "",
	middle_name: "",
	first_name: "",
	gender: "",
	date_of_birth: "",
	email: "",
	id_document_type: "",
	id_document_number: "",
	id_doc_issued_by: "",
	id_doc_issue_date: "",
	id_document_type_additional: "",
	id_document_number_additional: "",
	id_doc_issue_date_additional: "",
	contact_phone: "",
	additional_document: false,
	postal_code: "",
	province: initGist,
	district: initGist,
	city: initGist,
	house_number: initGist,
	street: initGist,
	authorized: false,
	name: "",
};

const ContactInfoPage = (props: RouteComponentProps<never, StaticContext, AcquisitionFlowState>) => {
	const formId = "acquisition-flow-contact-info-form-id";
	const {history, location} = props;
	const intl = useIntl();
	const {state} = location;
	const [withoutDocument, setWithoutDocument] = useState(Boolean(state?.personalInfo?.withoutDocument));
	const [error, setError] = useState<string>("");
	const personalInfoInit = state?.personalInfo || initialValues;
	const [checkCustomerCreditDecision, {loading}] = useMutation<Mutation, MutationCheck_Cdt_Consumer_Credit_DecisionArgs>(CHECK_CUSTOMER_CREDIT_DECISION);
	const [acqusitoinFlowData, setAcqusitoinFlowData] = useState<AcquisitionFlowState| undefined>({
		...state,
		personalInfo: {...personalInfoInit,
			...(state?.personalInfo?.additional_document ? {
				id_doc_issue_date_additional: state?.personalInfo?.id_doc_issue_date || "",
				id_document_number_additional: state?.personalInfo?.id_document_number || "",
				id_document_type_additional: state?.personalInfo?.id_document_type || "",
				id_doc_issue_date: "",
				id_document_number: "",
				id_document_type: "",
			} : {})

		}});

	const onNext = () => history.push({
		pathname: ACQUISITION_FLOW_SUMMARY_PATH,
		state: acqusitoinFlowData,
	});

	const onClickBack = useCallback(() => history.push({
		pathname: ACQUISITION_FLOW_ICCID_PATH,
		state
	}), [state]);
	// redirect to MSISDN page if msisdn is empty
	if (!state?.msisdn) {
		history.push(ACQUISITION_FLOW_MSISDN_PATH);
	}

	const {plan } = {...state};
	const personalInfo = acqusitoinFlowData?.personalInfo;
	const handleNextStep = (values: any) => {
		history.push({
			pathname: ACQUISITION_FLOW_SUMMARY_PATH,
			state: {
				...state,
				personalInfo: { ...values}
			}
		});
	};

	const onSubmit = (values) => {
		if (plan?.planType === TARIFF_PLAN_TYPE_POSTPAID) {
			checkCustomerCreditDecision({
				variables: {
					orderer: CdtUtils.creditDecisionNewCustomerOrderer(values),
					order: CdtUtils.creditDecisionNewCustomerOrder(),
				}
			}).then(({data, errors}) => {
				try {
					CdtUtils.handleCdtResponse(errors, intl, data);
				} catch(error) {
					setError(error as any);
				}
				const {
					additional_document,
					id_doc_issue_date_additional,
					id_document_number_additional,
					id_document_type_additional,
					...fields
				} = values;
				if (additional_document) {
					fields["id_doc_issue_date"] = id_doc_issue_date_additional;
					fields["id_document_number"] = id_document_number_additional;
					fields["id_document_type"] = id_document_type_additional;
					fields["additional_document"] = true;
				}
				fields["withoutDocument"] = withoutDocument;
				values = fields;
				handleNextStep(values);
			});
		} else {
			handleNextStep(values);
		}
	};
	const onClickSkipPersonalData = () => {
		const skipPersonalData =  !acqusitoinFlowData?.personalInfo?.skipPersonalData;
		setAcqusitoinFlowData({
			...state,
			personalInfo: skipPersonalData ? {
				skipPersonalData,
				first_name: "Anonymous",
				last_name: "Anonymous",
				id_document_type: "passport",
				nationality: "UA",
				postal_code: "01000",
				street: NOT_DEFINED_STREET,
				is_company: false,
				id_document_number: "",
			} : initialValues
		});
	};
	const prepaidAndSkipPersonalData = plan?.planType === TARIFF_PLAN_TYPE_PREPAID && personalInfo?.skipPersonalData;
	const renderNavigationHeader = () => {
		if (prepaidAndSkipPersonalData) {
			return <NavigationHeader
				FlowComp={AcquisitionFlow}
				next={onNext}
				back={onClickBack}
				disabledNext={loading}
			/>;
		} else {
			return <NavigationHeader
				FlowComp={AcquisitionFlow}
				formId={formId}
				back={onClickBack}
			/>;
		}
	};

	const onClickWithoutDocuments = () => {
		setAcqusitoinFlowData({
			...state,
			personalInfo: (!withoutDocument ? WITHOUT_DOCUMENT_INIT_FORM_VALUE : WITH_DOCUMENT_INIT_FORM_VALUE)});
		setWithoutDocument((prev) => !prev);
	};
	return (
		<>
			{renderNavigationHeader()}
			{plan?.planType === TARIFF_PLAN_TYPE_PREPAID && <div className="ipos-content-box ContactInfo">
				<div className="custom-control custom-checkbox" onClick={onClickSkipPersonalData}>
					<input
						className="custom-control-input"
						type="checkbox"
						checked={acqusitoinFlowData?.personalInfo?.skipPersonalData}
					/>
					<label className="custom-control-label">
						<FormattedMessage {...AcquisitionMessages.skipPersonalData} />
					</label>
				</div>
				{!prepaidAndSkipPersonalData && <PrePayForm
					onSubmit={onSubmit}
					initialValues={personalInfo}
					formId={formId}
				/>}
			</div>}
			{plan?.planType === TARIFF_PLAN_TYPE_POSTPAID && (
				<PostPayFlow
					formId={formId}
					onSubmit={onSubmit}
					showWithoudDocuments={true}
					initialValues={personalInfo}
					withoutDocument={withoutDocument}
					onClickWithoutDocuments={onClickWithoutDocuments}
				/>)
			}
			<ErrorMessage error={error} />
			{loading && <CircularProgress />}
		</>);
};

export {
	ContactInfoPage,
	WITHOUT_DOCUMENT_INIT_FORM_VALUE,
	WITH_DOCUMENT_INIT_FORM_VALUE,
	initialValues as contactInfoInitualValues
};
