import { InMemoryCache } from "apollo-cache-inmemory";
import { SubscriptionClient } from "../../graphql/fragments/subscription.fragments";

const updateSimCardIcc = (_, variables: any, {cache, getCacheKey}: {
    cache: InMemoryCache;
    getCacheKey: any;
}) => {
	const { icc, subscriptioniId } = variables;
	const id = getCacheKey({ __typename: "Subscription", id: subscriptioniId });
	cache.writeFragment({
		id,
		fragment: SubscriptionClient.fragments.simCardIcc,
		data: {
			__typename: "Subscription",
			sim_card: {
				icc: icc,
				__typename: "SimCard",
			}
		},
	});
};

export { updateSimCardIcc };
