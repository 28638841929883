import { IntlShape } from "react-intl";
import * as Yup from "yup";
import { UserMessages } from "../../../user/User.messages";
import { CommonMessages } from "../../../../Common.messages";
import { config } from "../../../../config/constants";
import { onlyDigitsExp, passwordExp } from "../../../../shared/utils";
import { validateMsisdn } from "../../../../shared/utils/utils";

const validationNumber = (self, value, intl) => {
	if(!self.parent.id_type) {
		return self.createError({
			message: intl.formatMessage({...UserMessages.selectDocumentType}),
			path: self.path,
		});
	}
	if (!value) {
		return self.createError({
			message: intl.formatMessage({...CommonMessages.required}),
			path: self.path,
		});
	} else if (self.parent.id_type === "passport") {
		if (value.length !== config.PASSPORT_LENGTH) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.amountSymbols}, { symbols: config.PASSPORT_LENGTH }),
				path: self.path,
			});
		} else if (!passwordExp.test(value)) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.inputFormat}, { format: config.PASSPORT_FORMAT }),
				path: self.path,
			});
		} else {
			return true;
		}
	} else if (self.parent.id_type === "national_id") {
		if (value.length !== config.ID_CARD_LENGTH) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.inputOnlyDigits}, { number: config.NATIONAL_ID }),
				path: self.path,
			});
		}
		return onlyDigitsExp.test(value) ? true : self.createError({
			message: intl.formatMessage({...CommonMessages.onlyDigits}),
			path: self.path,
		});
	} else if (self.parent.id_type === "individual_tax_number") {
		if(value.length < config.INDIVIDUAL_TAX_NUMBER) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.inputOnlyDigits}, { number: config.INDIVIDUAL_TAX_NUMBER }),
				path: self.path,
			});
		}
		return onlyDigitsExp.test(value) ? true : self.createError({
			message: intl.formatMessage({...CommonMessages.onlyDigits}),
			path: self.path,
		});
	} else if (self.parent.id_type === "billing_account") {
		return true;
	} else if (self.parent.id_type === "msisdn") {
		const [status, message] = validateMsisdn(value, intl);
		return !status ? self.createError({
			message,
			path: self.path,
		}) : status;
	}
};

const schemaValidationExistingCustomer = (intl: IntlShape) => {
	return Yup.object().shape({
		id_number: Yup.string().test({
			name: "validation_id_number",
			test: function(value) {
				return validationNumber(this,value, intl);
			}
		}).required(intl.formatMessage({...CommonMessages.required})),
		id_type: Yup.string().required(intl.formatMessage({...CommonMessages.required})),
	});
};

export { schemaValidationExistingCustomer };