import React, {useState} from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { MNPRouteState} from "../../MNPFlowRoutes";
import {useNavigationState, useUpdateNavigatioState} from "../../../../shared/components/navigation/useNavigationState";
import {BILLING_EXISTING_ACCOUNT, BILLING_NEW_ACCOUNT} from "../../../../config/constants";
import {FormattedMessage} from "react-intl";
import {AcquisitionMessages} from "../../../acquisitionFlow/Acquisition.messages";
import {BillingAccount as ExistingBillingAccount} from "../../../acquisitionFlow/ExistingCustomer/Pages/Billing/BillingAccount";
import {useFormik} from "formik";
import {MNP_FLOW_PLAN_POSTPAID} from "../MNPFlowRoutes";
import {FormStepWrapper} from "../../../../shared/components/navigation/FormStepWrapper";

const formId = "mnp-postpaid-billing-account-form-id";
const MnpBillingAccount = (props: RouteComponentProps<never, StaticContext, MNPRouteState>) => {
	const state = useNavigationState<MNPRouteState>();
	const updateNavigationState  = useUpdateNavigatioState<MNPRouteState>();

	const { history } = props;
	const [billingStatus, setShowBilling] = useState<string>(state?.billing_status || "");

	const onChange = (setFieldValue) => (e) => {
		const billingStatus = e.target.value;
		if (billingStatus === BILLING_NEW_ACCOUNT) {
			setFieldValue("account_id", "");
		}
		setFieldValue("status", billingStatus);
		setShowBilling(billingStatus);
	};

	const onSubmit = (value) => {
		const {status, account_id, billing_type} = value;

		if (billingStatus && BILLING_EXISTING_ACCOUNT === billingStatus) {
			updateNavigationState({
				...state,
				billing_status: status,
				account_id,
				billing_type,
			});
			value.account_id && history.push({
				pathname: MNP_FLOW_PLAN_POSTPAID,
			});
		} else if (billingStatus && BILLING_NEW_ACCOUNT === billingStatus) {
			updateNavigationState({
				...state,
				billing_status: status,
				account_id,
			});
			history.push(MNP_FLOW_PLAN_POSTPAID);
		}
	};

	const {setFieldValue, values, handleSubmit} = useFormik({
		initialValues: {
			status: billingStatus,
			account_id: state?.account_id || "",
			billing_type: state?.billing_type || ""
		},
		onSubmit
	});


	return (<FormStepWrapper<MNPRouteState> isValid={!!values.status} formId={formId}>
		<div className="ipos-content-box">
			<form id={formId} onSubmit={handleSubmit}>
				<div className="custom-control custom-radio">
					<div>
						<input
							className="custom-control-input"
							name="status"
							type="radio"
							id={"my-radio-billing-first"}
							value={BILLING_NEW_ACCOUNT}
							checked={values.status === BILLING_NEW_ACCOUNT}
							onChange={onChange(setFieldValue)}
						/>
						<label className="custom-control-label" htmlFor={"my-radio-billing-first"}>
							<FormattedMessage {...AcquisitionMessages.createBilling} />
						</label>
					</div>
					<div>
						<input
							className="custom-control-input"
							name="status"
							type="radio"
							value={BILLING_EXISTING_ACCOUNT}
							checked={values.status === BILLING_EXISTING_ACCOUNT}
							id={"my-radio-billing-second"}
							onChange={onChange(setFieldValue)}
						/>
						<label className="custom-control-label" htmlFor={"my-radio-billing-second"}>
							<FormattedMessage {...AcquisitionMessages.chooseBilling} />
						</label>
					</div>
					{billingStatus === BILLING_EXISTING_ACCOUNT && (
						<div className="mt-2">
							<ExistingBillingAccount
								setFieldValue={setFieldValue}
								account_id={values.account_id}
								customer={state?.customer}
								isB2bBillingAccount={false}
							/>
						</div>
					)}
				</div>
			</form>
		</div>
	</FormStepWrapper>
	);
};

export { MnpBillingAccount };