import gql from "graphql-tag";
import { language } from "./Languages.fragment";

const bundle =  {
	fragments: {
		full: gql`
            fragment bundleFull on Package {
                id,
                code,
                name {
                    ...languageFull
                },
                allow_re_activation,
                periodic_amount,
                periodic_unit,
                charge_type,
                fees {
                    fee,
                    fee_type,
                    tax_inclusive,
                },
                is_mandatory,
                product_instance_id,
                short_description {
                    ...languageFull
                },
                medium_description {
                    ...languageFull
                },
                long_description {
                    ...languageFull
                },
            },
            ${language.fragments.full}
         `
	}
};

export {
	bundle,
};
