/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface ChangeMsisdnMessagesType {
	customerRequest: MessageDescriptor;
	faultCorrection: MessageDescriptor;
	lost: MessageDescriptor;
	noAvailableMsisdns: MessageDescriptor;
	notEnoughBalance: MessageDescriptor;
	other: MessageDescriptor;
	reasonLabel: MessageDescriptor;
	replacementReason: MessageDescriptor;
	search: MessageDescriptor;
	searchForCustomNumber: MessageDescriptor;
	searchMaskPlaceholder: MessageDescriptor;
	selectMsisdn: MessageDescriptor;
}
const ChangeMsisdnMessages: ChangeMsisdnMessagesType = defineMessages({
	customerRequest: {
		id: "change-msisdn-reason-customerRequest",
		description: "Change msisdn reason Customer Request",
		defaultMessage: "Customer Request"
	},
	faultCorrection: {
		id: "change-msisdn-reason-faultCorrection",
		description: "Change msisdn reason Fault Correction",
		defaultMessage: "Fault Correction"
	},
	lost: {
		id: "change-msisdn-reason-lost",
		description: "Change msisdn reason Lost",
		defaultMessage: "Lost"
	},
	noAvailableMsisdns: {
		id: "change-msisdn-no-available-msisdns",
		description: "Change msisdn Search not found",
		defaultMessage: "Requested MSISDN is not available"
	},
	notEnoughBalance: {
		id: "change-msisdn-not-enough-balance",
		description: "Message not enough balance on modal dialog",
		defaultMessage: "Not enough balance for MSISDN change"
	},
	other: {
		id: "change-msisdn-reason-other",
		description: "Change msisdn reason Other",
		defaultMessage: "Other"
	},
	reasonLabel: {
		id: "change-msisdn-placeholder-input-reason",
		description: "Change msisdn input reason",
		defaultMessage: "Reason"
	},
	replacementReason: {
		id: "change-msisnd-replacement-reason",
		description: "Replacement msisdn reason label",
		defaultMessage: "Replacement reason"
	},
	search: {
		id: "change-msisdn-search",
		description: "Change msisdn Search button label",
		defaultMessage: "Search"
	},
	searchForCustomNumber: {
		id: "change-msisdn-select",
		description: "Change msisdn Search for a custom number label",
		defaultMessage: "Search for a custom number"
	},
	searchMaskPlaceholder: {
		id: "change-msisdn-mask-placeholder",
		description: "Change msisdn Search input placeholder",
		defaultMessage: "mask"
	},
	selectMsisdn: {
		id: "change-msisdn-select-title",
		description: "Change msisdn content title",
		defaultMessage: "Choose new MSISDN"
	},
});

export default ChangeMsisdnMessages;
export { ChangeMsisdnMessages };
