import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { CustomerNavigation } from "../../../shared/components/CustomerNavigation";
import { fttbFlowRoutesConfig, iposRoutes } from "../../../routes/RoutesMap";
import {
	FTTB_FLOW_ADDRESS_PATH,
	FTTB_FLOW_OPTIONS_PATH,
	FTTB_FLOW_PLAN_PATH,
	FTTB_FLOW_SUMMARY_PATH
} from "./FttbFlowRoutes";
import { FTTBMessages } from "../FTTB.messages";
import { useLocation } from "react-router-dom";
import { FttbRouteState } from "../fttb.types";

const lengthMainPath = iposRoutes.FTTB_FLOW.createLink().length;

const FttbFlow: FC = () => {
	const history = useHistory();
	const {
		pathname,
		state,
	} = useLocation<FttbRouteState>();
	const intl = useIntl();

	const addressLink = () => {
		if (state?.confirmation_code) {
			history.push({
				pathname: FTTB_FLOW_ADDRESS_PATH,
				state
			});
		}
	};

	const planLink = () => {
		if (state?.selectedFttbTariffPlan?.code) {
			history.push({ pathname: FTTB_FLOW_PLAN_PATH, state});
		}
	};

	const optionsLink = () => {
		if (state?.selectedFttbTariffPlan?.code) {
			history.push({ pathname: FTTB_FLOW_OPTIONS_PATH, state});
		}
	};

	const summaryLink = () => {
		if (state?.selectedFttbTariffPlan?.code) {
			history.push({pathname: FTTB_FLOW_SUMMARY_PATH, state});
		}
	};

	const routesIndexesConfig = {
		[fttbFlowRoutesConfig[0]]: {
			message: intl.formatMessage({...FTTBMessages.address}),
			link: addressLink,
			step: 1,
		},
		[fttbFlowRoutesConfig[1]]: {
			message: intl.formatMessage({...FTTBMessages.plan}),
			link: planLink,
			step: 2,
		},
		[fttbFlowRoutesConfig[2]]: {
			message: intl.formatMessage({...FTTBMessages.options}),
			link: optionsLink,
			step: 3,
		},
		[fttbFlowRoutesConfig[3]]: {
			message: intl.formatMessage({...FTTBMessages.summary}),
			link: summaryLink,
			step: 4,
		},
	};
	return (
		<div>
			<CustomerNavigation<typeof fttbFlowRoutesConfig>
				pathname={pathname}
				routesConfig={fttbFlowRoutesConfig}
				routesIndexesConfig={routesIndexesConfig}
				baseRouteLength={lengthMainPath}
			/>
		</div>
	);
};

export { FttbFlow };