import { ACTION_CODE_STATUS } from "../../graphql/fragments/actionCodeStatus";
import { CONFIRMATION_ACTION } from "../../graphql/fragments/confirmationAction";
import { LocalQuery, MutationResolveActionModalArgs } from "../../graphql/localTypes";
import { GET_APPLICATION_CONTEXT } from "../../graphql/queries/localQuery";
import { InMemoryCache } from "apollo-cache-inmemory";

const resolveActionModal = (_, variables: MutationResolveActionModalArgs, {cache, getCacheKey}: {
    cache: InMemoryCache;
    getCacheKey: any;
}) => {
	const { spinnerStatus, code, action, globalSpinner } = variables;
	const {context} = cache.readQuery<LocalQuery>({query: GET_APPLICATION_CONTEXT})!;
	const idConfirmationAction = getCacheKey({ __typename: "ConfirmationAction", id: "confirmationAction" });
	const idActionCodeStatus = getCacheKey({ __typename: "ActionCodeStatus", id: code + action + context?.subscriptionId });

	cache.writeFragment({
		id: idConfirmationAction,
		fragment: CONFIRMATION_ACTION,
		data: {
			__typename: "ConfirmationAction",
			status: false,
		},
	});
	cache.writeFragment({
		id: idActionCodeStatus,
		fragment: ACTION_CODE_STATUS,
		data: {
			__typename: "ActionCodeStatus",
			status: spinnerStatus,
			code,
			code3rdParty: variables?.code3rdParty,
		},
	});

	const data = { spinner: { status: globalSpinner || false, __typename: "SpinnerStatus" }};
	cache.writeData({ data });
};

export {
	resolveActionModal
};
