import React, {useCallback, useState} from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { PrePayForm } from "../../acquisitionFlow/NewCustomer/Pages/ContactInfo/PrePay/PrePayForm";
import {
	TARIFF_PLAN_TYPE_PREPAID,
} from "../../acquisitionFlow/NewCustomer/Pages";
import { NavigationHeader } from "../../../shared/components";
import { MNPFlow } from "./MNPFlow";
import { MNP_FLOW_SUMMARY_PATH, MNP_FLOW_PLAN_PATH, MNP_FLOW_OPTION_PATH, MNPRouteState } from "../MNPFlowRoutes";
import { contactInfoInitualValues } from "../../acquisitionFlow/NewCustomer/Pages/ContactInfo/ContactInfoPage";
import {Passportization} from "./Passportization";
import {MnpMessages} from "../Mnp.messages";
import {FormattedMessage} from "react-intl";

const MnpContactInfo = (
	props: RouteComponentProps<never, StaticContext, MNPRouteState>
) => {
	const formId = "acquisition-flow-contact-info-form-id";
	const { history, location } = props;
	const { state } = location;
	const personalInfoInit = state?.personalInfo || contactInfoInitualValues;

	const { plan } = state || {};
	const personalInfo = {
		...personalInfoInit,
		...(state?.personalInfo?.additional_document
			? {
				id_doc_issue_date_additional:
                        state?.personalInfo?.id_doc_issue_date || "",
				id_document_number_additional:
                        state?.personalInfo?.id_document_number || "",
				id_document_type_additional:
                        state?.personalInfo?.id_document_type || "",
				id_doc_issue_date: "",
				id_document_number: "",
				id_document_type: "",
			}
			: {}),
	};

	const onClickBack = useCallback(
		() =>
			history.push({
				pathname: MNP_FLOW_OPTION_PATH,
				state,
			}),
		[state]
	);

	const onSubmit = (values) => {
		history.push({
			pathname: MNP_FLOW_SUMMARY_PATH,
			state: {
				...state,
				personalInfo: { ...values },
				passportization,
			},
		});
	};

	const passportizationDefaultVal = state?.passportization || "";
	const [passportization, setPassportization] = useState<string>(passportizationDefaultVal);

	if (!state?.plan?.id) {
		history.push(MNP_FLOW_PLAN_PATH);
	}

	return (
		<>
			<NavigationHeader
				FlowComp={MNPFlow}
				formId={formId}
				back={onClickBack}
			/>
			{plan?.planType === TARIFF_PLAN_TYPE_PREPAID && (
				<div className="ipos-content-box ContactInfo">
					<div className="w-required-field"><FormattedMessage {...MnpMessages.userPassportization}/></div>
					<Passportization setPassportization={setPassportization} passportization={passportization} />
					<PrePayForm
						onSubmit={onSubmit}
						initialValues={personalInfo}
						formId={formId}
					/>
				</div>
			)}
		</>
	);
};

export { MnpContactInfo };
