import { gql } from "apollo-boost";

export const CAS_REQUEST_OTP = gql`
    mutation CasRequestOtp($msisdn: String!) {
        cas_request_otp(msisdn: $msisdn) {
            result,
            message
       }
    }
`;
export const CAS_VALIDATE_OTP = gql`
    query CasValidateOtp($msisdn: String!, $otp: String!) {
        cas_validate_otp(msisdn: $msisdn, otp: $otp) {
            result,
            message
       }
    }
`;