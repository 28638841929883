import React from "react";
import { MsisdnTopBarInfo } from "../../shared/components";
import { FormattedMessage } from "react-intl";
import { MnpMessages } from "./Mnp.messages";
import { Switch } from "react-router";
import { ErrorBoundaryRoute } from "../../shared/error/ErrorBoundaryRoute";
import { MNPMsisdnValidationPage } from "./flow/MNPMsisdnValidationPage";
import {
	MNP_FLOW_MSISDN_PATH,
	MNP_FLOW_TEMPORARY_MSISDN_PATH,
	MNP_FLOW_PLAN_PATH,
	MNP_FLOW_OPTION_PATH,
	MNP_FLOW_CONTACT_INFO_PATH,
	MNP_FLOW_SUMMARY_PATH,
} from "./MNPFlowRoutes";
import { MNPTemporaryMsisdn } from "./flow/MNPTemporaryMsisdn";
import { MnpPlan } from "./flow/MnpPlan";
import { MnpOptions } from "./flow/MnpOptions";
import { MnpContactInfo } from "./flow/MnpContactInfo";
import { MnpSummary } from "./flow/MnpSummary";

const MNPPageRouter = () => {
	return (
		<>
			<MsisdnTopBarInfo>
				<FormattedMessage {...MnpMessages.mnpMsisdn} />
			</MsisdnTopBarInfo>
			<div id="w-app-body">
				<Switch>
					<ErrorBoundaryRoute
						exact
						path={MNP_FLOW_MSISDN_PATH}
						component={MNPMsisdnValidationPage}
					/>
					<ErrorBoundaryRoute
						exact
						path={MNP_FLOW_TEMPORARY_MSISDN_PATH}
						component={MNPTemporaryMsisdn}
					/>
					<ErrorBoundaryRoute
						exact
						path={MNP_FLOW_PLAN_PATH}
						component={MnpPlan}
					/>
					<ErrorBoundaryRoute
						exact
						path={MNP_FLOW_OPTION_PATH}
						component={MnpOptions}
					/>
					<ErrorBoundaryRoute
						exact
						path={MNP_FLOW_CONTACT_INFO_PATH}
						component={MnpContactInfo}
					/>
					<ErrorBoundaryRoute
						exact
						path={MNP_FLOW_SUMMARY_PATH}
						component={MnpSummary}
					/>
				</Switch>
			</div>
		</>
	);
};

export { MNPPageRouter };
