import * as Yup from "yup";
import { QueryCustomersArgs } from "./../../../../../../graphql/types";
import moment from "moment";
import { IntlShape } from "react-intl";
import { config } from "../../../../../../config/constants";
import { CommonMessages } from "../../../../../../Common.messages";
import { DocumentTypeEnum } from "../../../../../../shared/types/DocumentTypeEnum";
import {
	emailExp,
	matchInnWithBirthDay,
	namingFIOExp,
	onlyDigitsExp,
	passwordExp,
	phoneUkrExp
} from "../../../../../../shared/utils";
import { client } from "../../../../../../apollo";
import { Query } from "../../../../../../graphql/types";
import { debounce } from "lodash";
import { GET_EXISTING_CUSTOMER } from "../../../../../../graphql/queries/customer";
import RegistrationMessages from "../../../../../registration/Registration.messages";

const checkIfCustomerExist = debounce((self: Yup.TestContext, id_type: string|null|undefined, id_number: string|null|undefined, intl: IntlShape): true | Promise<true | Yup.ValidationError> => {
	if (id_type && id_number) {
		return client.query<Required<Pick<Query, "customers">>, QueryCustomersArgs>({
			query: GET_EXISTING_CUSTOMER,
			variables: {
				id_number,
				id_type
			},
		})
			.then((data) => {
				return data?.data?.customers?.[0]?.id ? self.createError({
					message: intl.formatMessage(RegistrationMessages.startExisitingCustomerFlow),
					path: self.path,
				}) : true;
			});

	}
	return true;
}, 500);


const validateDocType = (self: Yup.TestContext, value, intl: IntlShape) => {
	if (value && self.parent?.id_document_type === DocumentTypeEnum.passport) {
		if (value.length !== config.PASSPORT_LENGTH) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.amountSymbols}, { symbols: config.PASSPORT_LENGTH }),
				path: self.path,
			});
		} else if (!passwordExp.test(value)) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.inputFormat}, { format: config.PASSPORT_FORMAT }),
				path: self.path,
			});
		} else {
			return true;
		}
	} else if (value && self.parent?.id_document_type === DocumentTypeEnum.national_id) {
		if (value.length !== config.ID_CARD_LENGTH) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.inputOnlyDigits}, { number: config.NATIONAL_ID }),
				path: self.path,
			});
		}
		return onlyDigitsExp.test(value) ? true : self.createError({
			message: intl.formatMessage({...CommonMessages.onlyDigits}),
			path: self.path,
		});
	}
	return true;
};
const postPayPersonal = (intl: IntlShape) => {
	const followFormatErrorMsg = intl.formatMessage({...CommonMessages.inputFormat}, { format: config.DATE_PICKER_FORMAT });
	const capitalLetterErrorMsg: string = intl.formatMessage({...CommonMessages.capitalLetter});
	const requiredErrorMsg: string = intl.formatMessage({...CommonMessages.required});
	return Yup.object().shape({
		first_name: Yup.string().matches(namingFIOExp, capitalLetterErrorMsg).required(requiredErrorMsg),
		middle_name: Yup.string().matches(namingFIOExp, capitalLetterErrorMsg),
		last_name: Yup.string()
			.test("last_name", capitalLetterErrorMsg, (value) => (value ? namingFIOExp.test(value) : true))
			.required(requiredErrorMsg),
		date_of_birth: Yup.string()
			.test("date_of_birth_incorrect", followFormatErrorMsg, (value) => (value === null ? false : true))
			.nullable()
			.required(requiredErrorMsg),
	});
};

const postPayRegistration = (intl: IntlShape) => {
	const emailErrorMsg = intl.formatMessage({...CommonMessages.validEmail});
	const requiredErrorMsg: string = intl.formatMessage({...CommonMessages.required});
	const selectFromDropdownList: string = intl.formatMessage({...CommonMessages.selectFromDropdownList});
	const onlyDigitsErrorMsg: string = intl.formatMessage({...CommonMessages.onlyDigits});
	const capitalLetterErrorMsg: string = intl.formatMessage({...CommonMessages.capitalLetter});
	const inputOnlyDigits = intl.formatMessage({...CommonMessages.inputOnlyDigits}, {number: config.INDIVIDUAL_TAX_NUMBER});
	const followFormatErrorMsg = intl.formatMessage({...CommonMessages.inputFormat}, { format: config.DATE_PICKER_FORMAT });
	const phoneFormatErrorMsg = intl.formatMessage({...CommonMessages.inputFormat}, { format: config.PHONE_FORMAT });
	const intErrorMsg: string = intl.formatMessage({...CommonMessages.intErrorMgs});
	const birthDayErrorMsg: string = intl.formatMessage({...CommonMessages.birthDayErrorMsg});
	const dateDocIssueErrorMsg: string = intl.formatMessage({...CommonMessages.dateDocIssueErrorMsg});

	return Yup.object().shape({
		first_name: Yup.string().matches(namingFIOExp, capitalLetterErrorMsg).required(requiredErrorMsg),
		last_name: Yup.string().test("last_name", capitalLetterErrorMsg, (value) => (value ? namingFIOExp.test(value) : true)),
		middle_name: Yup.string().matches(namingFIOExp, capitalLetterErrorMsg),
		date_of_birth: Yup.string()
			.test("date_of_birth_incorrect", followFormatErrorMsg, (value) => (value === null ? false : true))
			.test("date_of_birth", birthDayErrorMsg, function (value) {
				if (value && this.parent.individual_tax_number && this.parent.individual_tax_number !== config.INDIVIDUAL_TAX_NUMBER_EMPTY) {
					return matchInnWithBirthDay(this.parent.individual_tax_number, value);
				} else {
					return true;
				}
			}).nullable().required(requiredErrorMsg),
		individual_tax_number: Yup.string()
			.matches(onlyDigitsExp, onlyDigitsErrorMsg)
			.min(config.INDIVIDUAL_TAX_NUMBER, inputOnlyDigits)
			.test("int_match_to_email", intErrorMsg, function (value) {
				if (this.parent.date_of_birth && value && value !== config.INDIVIDUAL_TAX_NUMBER_EMPTY) {
					return matchInnWithBirthDay(value, this.parent.date_of_birth);
				} else {
					return true;
				}
			}),
		id_document_type: Yup.string().test("name", requiredErrorMsg, function(value) {
			return !this.parent.additional_document && !value ? false : true;
		}),
		id_document_number: Yup.string().test({
			name: "id_document_number",
			test: function (value) {
				return validateDocType(this, value, intl);
			}
		}).test({
			name: "check_existinig_customer",
			test: function (value) {
				return checkIfCustomerExist(this, this.parent?.id_document_type, value, intl) as boolean | Yup.ValidationError | Promise<boolean | Yup.ValidationError>;
			}
		}).test("id_number_required", requiredErrorMsg,function(value) {
			return this.parent.additional_document ? true : !!value;
		}),
		id_doc_issue_date: Yup.string().test("id_doc_issue_date", dateDocIssueErrorMsg, function(value) {
			if (value && (this.parent.id_document_type === DocumentTypeEnum.passport || this.parent.id_document_type === DocumentTypeEnum.national_id)) {
				if (this.parent.date_of_birth) {
					return moment(value).isAfter(moment(this.parent.date_of_birth).add(16, "years"));
				} else {
					return true;
				}
			}
			return true;
		}).nullable(),
		id_document_number_additional: Yup.string().test("id_document_number_additional", requiredErrorMsg, function(value) {
			return this.parent.additional_document && !value ? false : true;
		}),
		id_doc_issue_date_additional: Yup.string().test("id_doc_issue_date_additional", requiredErrorMsg, function(value) {
			return this.parent.additional_document && !value ? false : true;
		}).nullable(),
		id_document_type_additional: Yup.string().test("id_document_type_additional", requiredErrorMsg, function(value) {
			return this.parent.additional_document && !value ? false : true;
		}),
		contact_phone: Yup.string().matches(phoneUkrExp, phoneFormatErrorMsg).required(requiredErrorMsg),
		email: Yup.string().matches(emailExp, emailErrorMsg),
		city: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		street: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		province: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		district: Yup.object({
			selected: Yup.array()
		}),
		house_number: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		postal_code: Yup.string().required(requiredErrorMsg),
	});
};

export {
	postPayRegistration,
	postPayPersonal
};