import { IntlShape } from "react-intl";
import { config, RECURRENT } from "../../config/constants";
import { SelectedTariffPlanDetails } from "../../graphql/localTypes";
import { Maybe, Package, SubscriptionType } from "../../graphql/types";
import { extractFee } from "./acquisitionFlowUtils";

class TariffPlanUtils {
	static convertToSelectedTariffPlan(intl: IntlShape, pack: Maybe<Package>|undefined, subType: Maybe<SubscriptionType>|undefined): SelectedTariffPlanDetails {
		if (!subType?.id) {
			throw new Error("No TP id")
		}
		return {
			periodicAmount: pack?.periodic_amount!,
			periodicUnit: pack?.periodic_unit!,
			short_description: pack?.short_description!,
			medium_description: pack?.medium_description!,
			long_description: pack?.long_description!,
			description: pack?.medium_description?.[intl.locale],
			name: pack?.name!,
			planType: subType?.payment_type!,
			change_price_currency: subType?.change_price_currency!,
			change_price: subType?.change_price!,
			fee: extractFee(pack),
			feeCurrency: config.DEFAULT_CURRENCY!,
			feeRecurringPeriod: RECURRENT,
			id: subType?.id!,
			selectedOptionCode: pack?.code!,
			selectedOptionId: pack?.id!,
			code: subType?.code!,
			subscription_type_category: subType?.subscription_type_category!,
			billing_type: subType?.billing_account_type?.code!,
		}
	}
}

export {
	TariffPlanUtils
};