import { OrderTypeEnum } from "./../../graphql/types";
import { useEffect, useState } from "react";
import { CommonMessages } from "../../Common.messages";
import { useIntl } from "react-intl";
import { generateDocumentLinkNewCustomer } from "../utils/personalizationUtils";

export interface PayloadProps {
    orderType?: OrderTypeEnum;
    address: {
        house_number: string;
        apartment: string;
        // district: string;
        building:  string;
        contact_phone: string;
        email: string;
        language: string;
        province: string;
        postal_code: string;
        street: string;
        city: string;
    };
    info: {
        gender: string;
        first_name: string;
        middle_name: string;
        last_name: string;
        date_of_birth: string;
    };
    idDocuments: {
        id_document_number: string;
        id_document_type: string;
        id_doc_issue_date: string;
        id_doc_valid_till_date: string;
        id_doc_issued_by: string;
        password: string;
        individual_tax_number: string;
    };
    icc?: string;
    tariffPlanName?: string;
    tariffPlanType?: string;
    msisdnReservationCode?: string;
    salesman_username?: string;
    billing_account?: string
    isNewBA?: boolean;
    msisdnOtp?: string;
    transferTime?: string;
}

const useDocumentGeneration = ({
	payload,
	msisdn,
	setContractGenerationError,
	orderType,
	skipCustomerData
}: {
    payload: PayloadProps|undefined;
    msisdn?: string;
    setContractGenerationError: (value: (((prevState: string) => string) | string)) => void;
    orderType?: string;
    skipCustomerData?: boolean;
}) => {
	const intl = useIntl();
	const repeatStart = 1;
	const [loadingContractGeneration, setLoadingContractGeneration] = useState<boolean>(true);
	const [contractUrl, setContractUrl] = useState<string>();
	const [isRequestFired, setRequestFired] = useState<boolean>(false);

	useEffect(() => {
		if (!skipCustomerData && payload?.info.last_name && !isRequestFired && msisdn) {
			setRequestFired(true);
			const language = intl.locale;
			generateDocumentLinkNewCustomer(language, msisdn, payload,repeatStart, orderType).then(blob => {
				setContractUrl(window.URL.createObjectURL(blob));
				setLoadingContractGeneration(false);
			}).catch(() => {
				setContractGenerationError(intl.formatMessage(CommonMessages.contractGenerationFail));
				setLoadingContractGeneration(false);
			});
		}
	}, [payload]);
	return {loadingContractGeneration, contractUrl};
};

export {
	useDocumentGeneration
};
