/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface NotificationsMessagesType {
	refund: MessageDescriptor;
	router: MessageDescriptor;
}
const NotificationsMessages: NotificationsMessagesType = defineMessages({
	refund: {
		id: "compensation-case-money-refund",
		description: "money refund msg",
		defaultMessage: "Please open the compensation case in case managment if customer wants money refund"
	},
	router: {
		id: "notification-pop-transfer-router-package",
		description: "Transfer router package nottification msg fmc pop flow",
		defaultMessage: "Router + if there are any discounts, will be transferred to FMC"
	},
});

export default NotificationsMessages;
export { NotificationsMessages };
