import React, { FC, useState, useCallback, useRef } from "react";
import {
	FTTB_FLOW_PLAN_PATH,
	FTTB_FLOW_SUMMARY_PATH,
} from "../../Routes/FttbFlowRoutes";
import { RouteComponentProps, StaticContext } from "react-router";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import { NavigationHeader, CircularProgress } from "../../../../shared/components";
import { FttbFlow } from "../../Routes/FttbFlow";
import { FormattedMessage } from "react-intl";
import ChangePlanMessages from "../../../change-bar/plan/ChangePlan.messages";
import { OptionsFamilies } from "../../../acquisitionFlow/NewCustomer/Pages/OptionsPage/OptionsFamilies";
import {GET_OPTIONAL_CHILD_PRODUCTS, GET_OPTIONAL_PRODUCTS} from "../../../../graphql/queries/acquisitionFlow";
import { Query, ProductFamily, Package, Maybe } from "../../../../graphql/types";
import {
	OptionProductChecked
} from "../../../acquisitionFlow/NewCustomer/Pages/OptionsPage/OptionProduct";
import { ConnectionOption } from "./ConnectionOption";
import {
	filterChildPackages,
	filterOutActiveBundleFromAvailableFamily,
	findPackage
} from "../../../../shared/utils/bundleUtils";
import {config} from "../../../../config/constants";
import { FttbRouteState } from "../../fttb.types";
import { OtherOptions, SelectedOtherOptions } from "./OtherOptions";
import {prepareOptionalProductsForRadio, prepareUncheckClick} from "../../../../shared/utils";
import { RegionOptionalProducts } from "../../../../shared/components/option/RegionOptionalProducts";

const Options: FC<RouteComponentProps<never, StaticContext, FttbRouteState>> = props => {
	const availableFamilies = useRef<Maybe<Array<Maybe<ProductFamily>>>>([]);

	const { location:{state}, history } = props;
	const { flat, gisHouseId} = state?.address || {};
	const subscriptioTypeId = state?.selectedFttbTariffPlan?.id;
	const otherOptionRef = useRef<SelectedOtherOptions>({
		comment: state?.options?.comment || "",
		complexOffer: Boolean(state?.options?.complexOffer),
		starterPack: Boolean(state?.options?.starterPack),
	});

	const [optionalProduct, setOptionalProduct] = useState<Package[]>(state?.options?.packages || []);
	const initDesiredConnectionDate = state?.options?.desiredConnectionDate ? new Date(state?.options?.desiredConnectionDate) : undefined;

	const [date, setDate] = useState<Date|undefined|null>(initDesiredConnectionDate);
	const [isParentChecked, setIsParentChecked] = useState<boolean>(false);
	const [parentCode, setParentCode] = useState<string | null | undefined>("");
	const {data, loading} = useQuery<Query>(GET_OPTIONAL_PRODUCTS, {
		variables: {subscription_type_id: subscriptioTypeId},
	});

	const [getChildOffers, {data: childOffersData, loading: loadingChildOffers}] = useLazyQuery<Required<Pick<Query, "child_offers">>>(GET_OPTIONAL_CHILD_PRODUCTS);

	const onChecked = (value: OptionProductChecked) => {
		if (value.optionalProduct.code === "GPONFTTB") {
			setIsParentChecked(true);
		} else {
			setIsParentChecked(value.checked);
		}
		setParentCode(value.optionalProduct.code);
		getChildOffers({ variables: { subscription_type_id: subscriptioTypeId, parent_product: Number(value.optionalProduct.id), relation_type: "all" }});
		const filteredOptions = optionalProduct.filter(pack => {
			return prepareOptionalProductsForRadio(pack, value);
		});

		if (value.checked || value.optionalProduct.code === "GPONFTTB") {
			// setOptionalProduct([...filteredOptions, value.optionalProduct]);
			if (!optionalProduct.some(pkg => pkg?.code === value.optionalProduct.code)) {
				setOptionalProduct(prevOptionalProduct => [...prevOptionalProduct, value.optionalProduct]);
			}
		} else {
			if (!loadingChildOffers) {
				setOptionalProduct(optionalProduct.filter((pack) => {
					return  prepareUncheckClick(pack, value, childOffersData);
				}));
			}
		}
	};
	const onChildChecked = (value: OptionProductChecked) => {
		if (value.checked) {
			setOptionalProduct([...optionalProduct, value.optionalProduct]);
		} else {
			setOptionalProduct(optionalProduct.filter((pack) => pack.code !== value.optionalProduct.code));
		}
	};

	const available_families = data?.optional_products;
	let available_child_offers = childOffersData?.child_offers;

	if (available_families && available_families.length > 0) {
		availableFamilies.current = filterOutActiveBundleFromAvailableFamily(available_families,
			[{code: config.FAST_CONNECTION_PACKAGE_CODE}],
			state?.address?.simpleConnection,
			state?.housePreviousActivationStatus,
			state?.isGigabitAvailable,
			state?.networkType
		);
	}

	if (available_child_offers && available_child_offers.length > 0 && state?.address?.simpleConnection) {
		available_child_offers = filterChildPackages(available_child_offers);
	}
	const onClickNext = useCallback(async () => {
		history.push({
			pathname: FTTB_FLOW_SUMMARY_PATH,
			state: {
				...state,
				options: {
					comment: otherOptionRef.current?.comment,
					complexOffer: otherOptionRef.current?.complexOffer,
					desiredConnectionDate: date?.toString(),
					packages: optionalProduct,
					fttbFast: findPackage(available_families,  config.FAST_CONNECTION_PACKAGE_CODE),
					starterPack: otherOptionRef.current?.starterPack
				},

			}
		});
	}, [optionalProduct, date]);

	const onClickBack = () => {
		history.push({ pathname: FTTB_FLOW_PLAN_PATH, state });
	};

	return (
		<>
			<NavigationHeader FlowComp={FttbFlow} back={onClickBack} next={onClickNext} disabledNext={!date && !state?.address?.simpleConnection}/>
			<div className="ipos-content-box">
				{!state?.address?.simpleConnection && <>
					<ConnectionOption
						currentDate={date}
						onSelectedDate={setDate}
					/>
					<OtherOptions
						otherOptionRef={otherOptionRef}
					/></>}
				{loading ? <CircularProgress/> :
					!(data?.optional_products && data.optional_products.length > 0) &&
                    <div className="column">
                    	<FormattedMessage {...ChangePlanMessages.optionalProductsNotFound} />
                    </div>
				}
				{availableFamilies.current?.map((family: Maybe<ProductFamily>) => (
                    family?.packages?.length && family.packages.length > 0) &&
                    <OptionsFamilies
                    	key={family.id!}
                    	family={family}
                    	onChecked={onChecked}
                    	onChildChecked={onChildChecked}
                    	childOffersData={available_child_offers}
                    	isParentChecked={isParentChecked}
                    	parentCode={parentCode}
                    	loadingChildOffers={loadingChildOffers}
                    	selectedOptionalProduct={optionalProduct}
                    />)}
				{!loading && <RegionOptionalProducts
					subscriptioTypeId={subscriptioTypeId}
					additionalLoading={loading}
					flat={flat}
					gisHouseId={gisHouseId}
					onChecked={onChecked}
					onChildChecked={onChildChecked}
					childOffersData={available_child_offers}
					isParentChecked={isParentChecked}
					parentCode={parentCode}
					loadingChildOffers={loadingChildOffers}
					selectedOptionalProduct={optionalProduct}/>
				}
			</div>
		</>
	);
};

export { Options };
