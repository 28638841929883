import { useMutation } from "@apollo/react-hooks";
import { useState } from "react";
import { Mutation, MutationUpload_Acquisition_DocumentArgs } from "../../graphql/types";
import { useIntl } from "react-intl";
import CommonMessages from "../../Common.messages";
import { UPLOAD_ACQUISITION_DOCUMENT } from "../../graphql/mutations/order";
import { GET_UID_DOCUMENT_RESERVATION } from "../../graphql/mutations/subscription";

interface AcqusitionUploadDocumentDetails {
    purpose: string;
    confirmation_code: string;
}

const useAcquisitionUploadDocument = (): [
    ({purpose, confirmation_code}: AcqusitionUploadDocumentDetails, onDone: () => void, onFail?: () => void) => void,
    any[],
    (any) => void,
    boolean,
    string] => {
	const intl = useIntl();
	const [uploadProgress, setUploadProgress] = useState<boolean>(false);
	const [files, setFiles] = useState([]);
	const [error, setError] = useState<string>("");
	const [uploadAcquisitionDocument] = useMutation<Mutation, MutationUpload_Acquisition_DocumentArgs>(UPLOAD_ACQUISITION_DOCUMENT);
	const [document_uid_reservation] = useMutation(GET_UID_DOCUMENT_RESERVATION);
	const errorLabel = intl.formatMessage({...CommonMessages.documentUploadFail});

	return [({purpose, confirmation_code}: AcqusitionUploadDocumentDetails,
		onDone: () => void,
		onFail?: () => void) => {
		setError("");
		setUploadProgress(true);
		document_uid_reservation().then(({ data }) => {
			if(data?.document_uid_number_reservations?.request_id) {
				uploadAcquisitionDocument({
					fetchPolicy: "no-cache",
					variables: {
						details: {
							confirmation_code,
							document_type: "Acquisition Generic Contract",
							files,
							purpose,
							document_uid: data?.document_uid_number_reservations?.request_id
						}
					}
				}).then(result => {
					setUploadProgress(false);
					if (!Boolean(result.data?.upload_acquisition_document?.error || result.errors)) {
						onDone();
					} else {
						setError(errorLabel);
						if (onFail) {
							onFail();
						}
					}
				});
			} else {
				setError(errorLabel);
				if (onFail) {
					onFail();
				}
			}
		});

	}, files, setFiles, uploadProgress, error];
};

export { useAcquisitionUploadDocument };
