import moment from "moment";
import { BSSAPI_DATE_FORMAT } from "../../config/constants";

export enum ChangeActivationType {
	IMMEDIATE = "IMMEDIATE",
	NEXT_MONTH = "NEXT_MONTH",
}


export class DateTimeUtils {
	static toBssApiDateFormat(date?: Date): string {
		return date ? moment(date).format(BSSAPI_DATE_FORMAT) : "";
	}

	static convertToBssActivationDate = (type: ChangeActivationType | undefined, date: Date = new Date()): string => {
		if (type === ChangeActivationType.NEXT_MONTH) {
			return DateTimeUtils.toBssApiDateFormat(moment(date).add(1, "month").startOf("month").toDate());
		} else {
			return DateTimeUtils.toBssApiDateFormat(date);
		}
	};
}