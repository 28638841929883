import ApplicationRoute from "../../../routes/ApplicationRoute";

const routes = {
	FMC_POSTPAID_FLOW: new ApplicationRoute("/fmc-postpaid-flow"),
	FMC_FLOW_POSTPAID_SEARCH: new ApplicationRoute("/fmc-postpaid-flow/search"),
	FMC_FLOW_POSTPAID_ADDRESS: new ApplicationRoute("/fmc-postpaid-flow/address"),
	FMC_FLOW_POSTPAID_PLAN: new ApplicationRoute("/fmc-postpaid-flow/plan"),
	FMC_FLOW_POSTPAID_OPTIONS: new ApplicationRoute("/fmc-postpaid-flow/options"),
	FMC_FLOW_POSTPAID_SUMMARY: new ApplicationRoute("/fmc-postpaid-flow/summary"),

};

const FMC_POSTPAID_FLOW_PATH: string = routes.FMC_POSTPAID_FLOW.createLink();
const FMC_FLOW_POSTPAID_SEARCH_PATH: string = routes.FMC_FLOW_POSTPAID_SEARCH.createLink();
const FMC_FLOW_POSTPAID_FTTB_ADDRESS_PATH: string = routes.FMC_FLOW_POSTPAID_ADDRESS.createLink();
const FMC_FLOW_POSTPAID_PLAN_PATH: string = routes.FMC_FLOW_POSTPAID_PLAN.createLink();
const FMC_FLOW_POSTPAID_OPTIONS_PATH: string = routes.FMC_FLOW_POSTPAID_OPTIONS.createLink();
const FMC_FLOW_POSTPAID_SUMMARY_PATH: string = routes.FMC_FLOW_POSTPAID_SUMMARY.createLink();

export {
	routes,
	FMC_FLOW_POSTPAID_SEARCH_PATH,
	FMC_POSTPAID_FLOW_PATH,
	FMC_FLOW_POSTPAID_PLAN_PATH,
	FMC_FLOW_POSTPAID_SUMMARY_PATH,
	FMC_FLOW_POSTPAID_FTTB_ADDRESS_PATH,
	FMC_FLOW_POSTPAID_OPTIONS_PATH,
};

