import { onError } from "apollo-link-error";
import { client } from "./index";
import { Storage } from "../shared/utils/storageUtils";

import {
	LocalQuery,
	ModalStatusArgs,
} from "../graphql/localTypes";
import { GET_MODAL } from "../graphql/queries";
import { MSISDNS, RESERVED_MSISDNS, STORAGE_KEY_CURRENT_DEALER_IDENTITY, STORAGE_KEY_IS_AUTHENTICATED } from "../config/constants";
import { iposRoutes } from "../routes/RoutesMap";
import {history} from "../App";
import { cache, initCleanCache } from "./cache";
const showModalError = (message: string) => {
	client.writeQuery<Pick<LocalQuery, "modal">, ModalStatusArgs>({
		query: GET_MODAL,
		data: {
			modal:{
				__typename: "ModalStatus",
				message,
				status: true
			}
		},
	});
};

const clearIdentity = () => {
	Storage.local.set(STORAGE_KEY_IS_AUTHENTICATED, false);
	Storage.local.set(STORAGE_KEY_CURRENT_DEALER_IDENTITY, "");
	Storage.local.set(MSISDNS, []);
	Storage.local.set(RESERVED_MSISDNS, []);
	return client.clearStore().then(() => {
		cache.writeData<LocalQuery>({
			data: {...initCleanCache},
		});
	});
};

const linkErorr = onError(({ response, graphQLErrors, networkError, operation }) => {
	
	if (networkError) {
		if ((networkError as any)?.statusCode  === 401) {
			if (operation.operationName === "UserIdentity") {
				clearIdentity().then(() => {
					history.push(iposRoutes.LOGIN.createLink());
				});
			} else {
				clearIdentity().then(() => {
					history.push(iposRoutes.LOGOUT.createLink());
				});
			}
		} else if ((networkError as any)?.statusCode !== 403) {
			showModalError(`[Network error]: ${networkError}`);
		}
	}
	if (response && (operation.operationName === "OtpMnpSubscription")) {
		return;
	} else if (graphQLErrors && response && (networkError as any)?.statusCode !== 403) {
		response.data = {};
		response.errors = [];
	}
});

export {
	linkErorr,
	clearIdentity
};
