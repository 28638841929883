import gql from "graphql-tag";
import { language } from "./Languages.fragment";
import { bundle } from "./Packages.fragments";

const TariffPlan =  {
	fragments: {
		full: gql`
          fragment tariffPlanDetails on SubscriptionType {
            id,
            name {...languageFull},
            payment_type,
            code,
            change_price,
            line_type,
            change_price_currency,
            long_info_text {...languageFull},
            info_text {...languageFull},
            option_groups {
                name {...languageFull},
                packages {...bundleFull}
            },
            tags
          },
          ${bundle.fragments.full},
          ${language.fragments.full},
        `,
		withoutOptionGrops: gql`
          fragment tariffPlanWithoutOptionGroupsDetails on SubscriptionType {
            id,
            name {...languageFull},
            payment_type,
            code,
            change_price,
            line_type,
            change_price_currency,
            long_info_text {...languageFull},
            info_text {...languageFull},
          },
          ${language.fragments.full},
        `,
	}
};
export {
	TariffPlan,
};
