import React, {FC, useMemo, useState} from "react";
import {StepWrapper} from "../../../../shared/components/navigation/StepWrapper";
import {SelectFieldFormik} from "../../../../shared/components/SelectField";
import {CircularProgress, InputFieldFormik} from "../../../../shared/components";
import {FormattedMessage, useIntl} from "react-intl";
import {UserMessages} from "../../../user/User.messages";
import { useFormik } from "formik";
import {B2BFTTBFlowMessages} from "../B2BFttb.messgaes";
import {idDocumentType, company_search_type} from "../../../../shared/utils/common_mock_data";
import {SearchCompanyEnumEnum} from "../../../../shared/types/B2BFttbEnum";
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_EXISTING_COMPANY} from "../../../../graphql/queries";
import {B2BFttbRouteState} from "./b2bFttb.type";
import {CommonMessages} from "../../../../Common.messages";
import {RouteComponentProps, StaticContext} from "react-router";
import { Customer, Query, QueryCompanysArgs } from "../../../../graphql/types";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";

const SearchCompany:FC<RouteComponentProps<never, StaticContext, B2BFttbRouteState>> = (props) => {
	const state = useNavigationState<B2BFttbRouteState>();
	const intl = useIntl();
	const [disableSelectCompanyId, setDisableSelectCompanyId] = useState(false);
	const [showCompanies, setShowCompanies] = useState(false);
	const [companies, setCompany] = useState<(Customer|null)[]|null>(state?.companies || null);
	const [existingCompany, { loading }] = useLazyQuery<Query, QueryCompanysArgs>(GET_EXISTING_COMPANY, {
		onCompleted: (data: Query) => {
			setCompany(data?.companys || []);
		}
	});
	const [companyId, setCompanyId] = useState(state?.company_id || "");
	const initialValues = {id_number: "", id_type: "", search_type: ""};
	const onSubmit = (values) => {
		if (values.search_type === SearchCompanyEnumEnum.byName) {
			existingCompany({ variables: { first_name: values.id_number}});
		} else if (values.search_type === SearchCompanyEnumEnum.byId) {
			existingCompany({ variables: { business_uid: values.id_number}});
		}
		setShowCompanies(true);
	};
	const onChangeSearchType = (fieldName, setFieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = e.target.value;
		if (value === SearchCompanyEnumEnum.byName) {
			setDisableSelectCompanyId(true);
		} else setDisableSelectCompanyId(false);
		setFieldValue(fieldName, e.target.value);
	};
	const onChangeId = (fieldName, setFieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue(fieldName, e.target.value);
	};
	const onChangeSearchCompany = (fieldName, setFieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue(fieldName, e.target.value);
	};
	const onChange = (e, company) => {
		const company_id = e.target.value;
		setCompanyId(company_id);
	};
	const formik = useFormik({initialValues, onSubmit});
	const searchTypes = useMemo(() => company_search_type(intl), [intl.locale]);
	const companyIdTypes = useMemo(() => idDocumentType(intl), [intl.locale]);
	return (
		// @ts-ignore
		<StepWrapper<B2BFttbRouteState> isValid={!!companyId} newState={{ company_id: companyId, companies }}>
			<form noValidate onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className="col-12">
						<SelectFieldFormik
							required
							name="search_type"
							id="search-type"
							showPlaceholder={true}
							placeholder={intl.formatMessage({...B2BFTTBFlowMessages.searchType})}
							attr={{maxLength: 256}}
							value={formik.values.search_type}
							values={searchTypes}
							formik={formik}
							onChange={onChangeSearchType}
						/>
					</div>
					<div className="col-6">
						<SelectFieldFormik
							required
							name="id_type"
							id="id-type"
							showPlaceholder={true}
							placeholder={intl.formatMessage({...B2BFTTBFlowMessages.companyIdType})}
							attr={{maxLength: 256, disabled : disableSelectCompanyId}}
							value={formik.values.id_type}
							values={companyIdTypes}
							formik={formik}
							onChange={onChangeId}
						/>
					</div>
					<div className="col-6">
						<InputFieldFormik
							required
							name="id_number"
							id="id-number"
							placeholder={intl.formatMessage({...B2BFTTBFlowMessages.enterCompanyIdOrName})}
							attr={{maxLength: 256}}
							value={formik.values.id_number}
							formik={formik}
							onChange={onChangeSearchCompany}
						/>
					</div>
					<div className="col-12 mb-2">
						<button type="submit" className="btn btn-block btn-primary">
							<FormattedMessage {...UserMessages.search} />
						</button>
					</div>
					{
						loading &&
						<div className="col-12 mb-2"><CircularProgress/></div>
					}
					<div>
						{
							companies?.length && companies?.length > 0 ?
							companies?.map((company, index) => (
								<div key={company?.id || index}>
									<div className="custom-control custom-radio ml-3">
										<input
											type="radio"
											name="account_id"
											className="custom-control-input"
											id={`my-account-${index}`}
											checked={company?.id === companyId}
											onChange={e => onChange(e, company)}
											value={company?.id || ""}
										/>
										<label className="custom-control-label" htmlFor={`my-account-${index}`}>
											<div className="title"><FormattedMessage {...CommonMessages.firstName} />:&nbsp;</div>
											<div className="value">{company?.first_name}</div>
											<div className="title"><FormattedMessage {...CommonMessages.lastName} />:&nbsp;</div>
											<div className="value">{company?.last_name}</div>
										</label>
									</div>
								</div>
							)) : <div className="ml-3">{showCompanies && !loading && <FormattedMessage {...B2BFTTBFlowMessages.noCompanyFound} />}</div>
						}
					</div>
				</div>
			</form>
		</StepWrapper>
	);
};

export { SearchCompany };