import React, { ChangeEvent, useState, useEffect, MutableRefObject, FC, } from "react";
import { FormattedMessage } from "react-intl";
import { FTTBMessages } from "../../FTTB.messages";

export interface SelectedOtherOptions {
    comment: string;
    complexOffer: boolean;
    starterPack: boolean;
}
interface OtherOptionsProps {
    otherOptionRef: MutableRefObject<SelectedOtherOptions>;
}

const OtherOptions: FC<OtherOptionsProps> = props => {
	const {otherOptionRef} = props;
	const [comment, setComment] = useState<string>(otherOptionRef.current.comment);
	const [complexOffer, setComplexOffer] = useState<boolean>(Boolean(otherOptionRef.current.complexOffer));
	const [starterPack, setStarterPack] = useState<boolean>(Boolean(otherOptionRef.current.starterPack));
	const onCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
		setComment(e.target.value);
	};

	useEffect(() => {
		otherOptionRef.current = {
			comment,
			complexOffer,
			starterPack
		};
	}, [comment, complexOffer, starterPack]);

	return (
		<table className="table w-tile-table">
			<tbody>
				<tr className="w-tile-table-row w-tile-table-row-primary">
					<td className="w-100 w-required-field"><FormattedMessage {...FTTBMessages.otherOptions} /></td>
				</tr>
				<tr>
					<td colSpan={2} className="w-details-drawer">
						<table className="table w-tile-table">
							<tbody>
								<tr className="w-tile-table-row">
									<td className="shrink lead"><i className="fa fa-cubes p-2 bg-gray-200"></i></td>
									<td>
										<div className="w-emphasize">
											<FormattedMessage {...FTTBMessages.complexOffer}/>
										</div>
									</td>
									<td className="actions">
										<div className="custom-control custom-checkbox">
											<input
												id={"complex_sale"}
												className="custom-control-input"
												type="checkbox"
												checked={complexOffer}
												onChange={() => setComplexOffer(!complexOffer)}
											/>
											<label className="custom-control-label" htmlFor={"complex_sale"}/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>

						<table className="table w-tile-table">
							<tbody>
								<tr className="w-tile-table-row">
									<td className="shrink lead"><i className="fa fa-cubes p-2 bg-gray-200"></i></td>
									<td>
										<div className="w-emphasize">
											<FormattedMessage {...FTTBMessages.needsStarterPack}/>
										</div>
									</td>
									<td className="actions">
										<div className="custom-control custom-checkbox">
											<input
												id={"need_starterkit"}
												className="custom-control-input"
												type="checkbox"
												checked={starterPack}
												onChange={() => setStarterPack(!starterPack)}
											/>
											<label className="custom-control-label" htmlFor={"need_starterkit"}/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>

						<table className="table w-tile-table">

							<tbody>
								<tr className="w-tile-table-row">
									<td className="shrink lead"><i className="fa fa-cubes p-2 bg-gray-200"></i></td>
									<td className="d-flex align-items-center">
										<div className="w-emphasize">
											<FormattedMessage {...FTTBMessages.comment}/>&nbsp;
										</div>
										<input
											className="form-control"
											type="text"
											value={comment}
											onChange={onCommentChange}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

export { OtherOptions };