import { useMemo, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { FormattedMessage } from "react-intl";
import { SelectedTariffPlanDetails } from "../../../../../graphql/localTypes";
import CommonMessages from "../../../../../Common.messages";
import { ACQUISITION_FLOW_MSISDN_PATH, ACQUISITION_FLOW_OPTIONS_PATH } from "../../Routes/AcquisitionFlowRoutes";
import cssns from "../../../../../shared/utils/cssnsConfig";
import { NavigationHeader } from "../../../../../shared/components";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { TARIFF_PLAN_TYPE_POSTPAID, TARIFF_PLAN_TYPE_PREPAID } from "../index";
import { OfferSubscriptionProps, prepareOfferings } from "../../../../../shared/utils";
import { AcquisitionFlow } from "../../Routes/AcquisitionFlow";
import { Query, LineTypeEnum  } from "../../../../../graphql/types";
import { AcquisitionFlowState } from "../SummaryPage/SummaryPage";
import { PlanList } from "../../../../../shared/components/tariffPlan/PlanList";
import { GET_OFFERINGS_WITHOUT_OPTION_GROUPS } from "../../../../../graphql/queries/acquisitionFlow";

const {React} = cssns("Plan-Page");

const PlanPage = (props: RouteComponentProps<never, StaticContext, AcquisitionFlowState>) => {
	const {history, location} = props;
	const {state} = location;
	const [acqusitoinFlowData, setAcqusitoinFlowData] = useState<AcquisitionFlowState| undefined>(state);
	const {data, loading} = useQuery<Required<Pick<Query, "offerings">>>(GET_OFFERINGS_WITHOUT_OPTION_GROUPS);
	const offerings: (OfferSubscriptionProps | undefined)[] | undefined = useMemo(() => {
		if (data?.offerings) {
			return prepareOfferings(data.offerings, LineTypeEnum.Mobile);
		} else {
			return [];
		}
	}, [data?.offerings]);
	// redirect to MSISDN page if msisdn is empty
	if (!state?.msisdn && !loading) {
		history.push(ACQUISITION_FLOW_MSISDN_PATH);
	}
	const handleSelectTariffPlan = (plan: SelectedTariffPlanDetails) => {
		setAcqusitoinFlowData({
			...state,
			plan,
			options: [],
		});
	};
	const onClickBack =() => history.push({
		pathname: ACQUISITION_FLOW_MSISDN_PATH,
		state
	});
	const onClickNext = () => {
		if (acqusitoinFlowData?.plan?.code) {
			history.push({
				pathname: ACQUISITION_FLOW_OPTIONS_PATH,
				state: acqusitoinFlowData
			});
		}
	};


	const defaulTab = acqusitoinFlowData?.plan?.planType || TARIFF_PLAN_TYPE_PREPAID;
	return (
		<>
			<NavigationHeader FlowComp={AcquisitionFlow} next={onClickNext} back={onClickBack}/>
			<div className="ipos-content-box">
				<div className="w-box-tabset">
					<Tabs defaultActiveKey={defaulTab} id="uncontrolled-tab-example">
						<Tab eventKey={TARIFF_PLAN_TYPE_PREPAID} title={<FormattedMessage {...CommonMessages.prepaid}/>}>
							<PlanList
								offerings={offerings}
								type={TARIFF_PLAN_TYPE_PREPAID}
								loading={loading}
								plan={acqusitoinFlowData?.plan}
								handleSelectTariffPlan={handleSelectTariffPlan}
							/>
						</Tab>
						<Tab eventKey={TARIFF_PLAN_TYPE_POSTPAID} title={<FormattedMessage {...CommonMessages.postpaid}/>}>
							<PlanList
								offerings={offerings}
								type={TARIFF_PLAN_TYPE_POSTPAID}
								loading={loading}
								plan={acqusitoinFlowData?.plan}
								handleSelectTariffPlan={handleSelectTariffPlan}
							/>
						</Tab>
					</Tabs>
				</div>
			</div>
		</>
	);
};

export { PlanPage };
