import React, { FC } from "react";
import { CommonMessages } from "../../Common.messages";
import { FormattedMessage} from "react-intl";
import Modal from "react-bootstrap/Modal";
import { DealerIdentities } from "./DealerIdentities";
import { useAuthDataContext } from "../hooks/AuthentificationProvider";
import { useAuth } from "../hooks/useAuth";

const IdentityModal: FC = () => {
	const isAuthenticated = useAuth();
	const { dealerIdentities, currentDealerIdentety, loading} = useAuthDataContext();
	const isDealerIdentities = Boolean(dealerIdentities && dealerIdentities.length > 0);
	return (
		<Modal
			show={isDealerIdentities && !currentDealerIdentety?.reseller_code && isAuthenticated && !loading}
			centered
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onHide={() => {}}
		>
			<Modal.Header>
				<Modal.Title>
					{ <FormattedMessage {...CommonMessages.selectDealerIdentity} />}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<DealerIdentities variant="outline-primary"/>
			</Modal.Body>
		</Modal>
	);
};

export { IdentityModal };
