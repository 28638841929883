import gql from "graphql-tag";
import { language } from "../fragments/Languages.fragment";
import { bundle } from "../fragments/Packages.fragments";

export const GET_REGION_OPTIONAL_PRODUCTS = gql`
    query getRegionOptionalProducts($subscription_type_id: ID!, $gis_house_id: String, $gis_flat_id: String) {
        region_optional_products(subscription_type_id: $subscription_type_id, gis_house_id: $gis_house_id, gis_flat_id: $gis_flat_id) {
            id,
            name {...languageFull},
            packages {
                tags
                ...bundleFull
            }
        }
    },
    ${bundle.fragments.full},
    ${language.fragments.full},
`;