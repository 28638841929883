import React from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import {MNPRouteState} from "../../MNPFlowRoutes";
import {contactInfoInitualValues} from "../../../acquisitionFlow/NewCustomer/Pages/ContactInfo/ContactInfoPage";
import {MNP_FLOW_PLAN_POSTPAID, MNP_FLOW_SUMMARY_POSTPAID} from "../MNPFlowRoutes";
import {PostPayFlow} from "../../../acquisitionFlow/NewCustomer/Pages/ContactInfo/PostPayForm/PostPayFlow";
import {FormStepWrapper} from "../../../../shared/components/navigation/FormStepWrapper";
import { useNavigationState, useUpdateNavigatioState } from "../../../../shared/components/navigation/useNavigationState";

const MnpContactInfo = (
	props: RouteComponentProps<never, StaticContext, MNPRouteState>
) => {
	const formId = "acquisition-flow-contact-info-form-id";
	const { history } = props;
	const state = useNavigationState<MNPRouteState>();
	const updateNavigationState  = useUpdateNavigatioState<MNPRouteState>();

	const personalInfoInit = state?.personalInfo || contactInfoInitualValues;

	const personalInfo = {
		...personalInfoInit,
		...(state?.personalInfo?.additional_document
			? {
				id_doc_issue_date_additional:
                        state?.personalInfo?.id_doc_issue_date || "",
				id_document_number_additional:
                        state?.personalInfo?.id_document_number || "",
				id_document_type_additional:
                        state?.personalInfo?.id_document_type || "",
				id_doc_issue_date: "",
				id_document_number: "",
				id_document_type: "",
			}
			: {}),
	};

	const onSubmit = (values) => {
		const {
			additional_document,
			id_doc_issue_date_additional,
			id_document_number_additional,
			id_document_type_additional,
			...fields
		} = values;
		if (additional_document) {
			fields["id_doc_issue_date"] = id_doc_issue_date_additional;
			fields["id_document_number"] = id_document_number_additional;
			fields["id_document_type"] = id_document_type_additional;
			fields["additional_document"] = true;
		}
		values = fields;
		updateNavigationState({
			...state,
			personalInfo: { ...values },
		});
		history.push({
			pathname: MNP_FLOW_SUMMARY_POSTPAID,
		});
	};

	if (!state?.plan?.id) {
		history.push(MNP_FLOW_PLAN_POSTPAID);
	}

	return (
		<FormStepWrapper<MNPRouteState> isValid formId={formId}>
			<div className="ipos-content-box ContactInfo">
				<PostPayFlow
					onSubmit={onSubmit}
					formId={formId}
					initialValues={personalInfo}
				/>
			</div>
		</FormStepWrapper>
	);
};

export { MnpContactInfo };
