import React, { useState } from "react";
import {config, FIRST_DAY_OF_WEEK} from "../../../../config/constants";
import moment, { Moment } from "moment";
import { SingleDatePicker, isInclusivelyBeforeDay } from "react-dates";

interface DatePickerItemProps {
    currentDate: Date|undefined|null;
    onChange: (date: Date) => void;
    disabled: boolean;
}

const ConnectionDay = (props: DatePickerItemProps) => {
	const { disabled } = props;
	const [focused, setFocused] = useState();
	const today = props.currentDate?.getDate() === new Date().getDate();

	const onFocusChange = ({ focused }) => {
		setFocused(focused);
	};

	const onDateChange = (date: Moment|null) => {
		if (!date) {
			return;
		}
		if (props.currentDate) {
			const times = [props.currentDate.getHours(), props.currentDate.getMinutes()];
			const dateWithtime = date.set("hour", times[0]).set("minute", times[1]);
			props.onChange(dateWithtime.toDate());
		} else {
			props.onChange(date.startOf("day").toDate());
		}
	};

	const onTimeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
		const times = ev.target.value.split(":");
		if (props.currentDate) {
			const dateWithtime = moment(props.currentDate).set("hour", parseInt(times[0])).set("minute", parseInt(times[1]));
			props.onChange(dateWithtime.toDate());
		}
	};

	const isOutsideRange = (day: moment.Moment): boolean => {
		return isInclusivelyBeforeDay(day, moment());
	};

	const timeValue = today ? "" : props.currentDate && moment(props.currentDate).format("HH:mm") || "";
	const dateValue = today ? undefined : props.currentDate && moment(props.currentDate);
	return (
		<div className="d-flex">
			<SingleDatePicker
				isOutsideRange={isOutsideRange}
				//withFullScreenPortal={true} TODO mobile view
				date={dateValue}
				focused={focused}
				onFocusChange={onFocusChange}
				placeholder={config.DATE_PICKER_FORMAT}
				id={"desired-connection-date"}
				block={false}
				disabled={disabled}
				onDateChange={onDateChange}
				displayFormat={config.DATE_PICKER_FORMAT}
				numberOfMonths={1}
				hideKeyboardShortcutsPanel={true}
				firstDayOfWeek={FIRST_DAY_OF_WEEK}
			/>
			<div className="time-picker">
				<input
					disabled={disabled}
					className="form-control"
					type="time"
					step="60"
					// min="9:00"
					// max="18:00"
					value={timeValue}
					placeholder="Time"
					onChange={onTimeChange}
				/>
			</div>
		</div>
	);
};

export { ConnectionDay };