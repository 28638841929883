import { IntlShape } from "react-intl";
import { CommonMessages } from "../../../../Common.messages";
import { config } from "../../../../config/constants";
import * as Yup from "yup";
import { firstCapitalExp, namingFIOExp, phoneUkrExp } from "../../../../shared/utils";

const addressValidation = (intl: IntlShape) => {
	const requiredErrorMsg: string = intl.formatMessage({...CommonMessages.required});
	const phoneFormatErrorMsg = intl.formatMessage({...CommonMessages.inputFormat}, { format: config.PHONE_FORMAT });
	const illegalSymbolsErrorMsg: string = intl.formatMessage({...CommonMessages.illegalSymbolsValidation});
	const nameTest = (value) => (value ? namingFIOExp.test(value) : true);
	const capitalLetterErrorMsg: string = intl.formatMessage({...CommonMessages.capitalLetter});

	return Yup.object().shape({
		contact_phone: Yup.string().matches(phoneUkrExp, phoneFormatErrorMsg).required(requiredErrorMsg),
		city: Yup.string().required(requiredErrorMsg),
		last_name: Yup.string()
			.matches(firstCapitalExp, capitalLetterErrorMsg)
			.test("last_name", illegalSymbolsErrorMsg, nameTest)
			.required(requiredErrorMsg),
		first_name: Yup.string()
			.matches(firstCapitalExp, capitalLetterErrorMsg)
			.test("last_name", illegalSymbolsErrorMsg, nameTest)
			.required(requiredErrorMsg),
		middle_name: Yup.string()
			.matches(firstCapitalExp, capitalLetterErrorMsg)
			.test("last_name", illegalSymbolsErrorMsg, nameTest)
			.required(requiredErrorMsg),
		province: Yup.string().required(requiredErrorMsg),
		street: Yup.string().required(requiredErrorMsg),
		house_number: Yup.string().required(requiredErrorMsg),
		gisHouseId: Yup.string().required(requiredErrorMsg),
		flat: Yup.string().required(requiredErrorMsg),
	});
};

export {
	addressValidation
};