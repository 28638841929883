import React from "react";
import { FormattedMessage } from "react-intl";
import { CommonMessages } from "../../../../Common.messages";
import { AcquisitionMessages } from "../../Acquisition.messages";

interface ExistingCustometInfoType {
    customers: Array<any>| null;
    onClick: (id: any) => void;
	mnpPostpaid?: boolean;
}

const ExistingCustomerInfo = (props: ExistingCustometInfoType) => {
	if (!props.customers || props.customers.length === 0) return null;
	const onClick = (id) => () => {
		props.onClick(id);
	};
	return (
		<>
			<div className="customers">
				{props.customers.map((customer) => (
					<div key={customer.id} className="result">
						<div className="title"><FormattedMessage {...CommonMessages.firstName} />:&nbsp;</div>
						<div className="value">{customer.first_name}</div>
						<div className="title"><FormattedMessage {...CommonMessages.lastName} />:&nbsp;</div>
						<div className="value">{customer.last_name}</div>
						<div className="btn btn-secondary button" onClick={onClick(customer.id)}>
							<FormattedMessage {...CommonMessages.next} /></div>
					</div>
				))}
			</div>
			{
				!props.mnpPostpaid &&
				<div className="alert alert-info mb-0 message">
					<p className="mb-0"><FormattedMessage {...AcquisitionMessages.infoLabel} /></p>
				</div>
			}
		</>
	);
};

export { ExistingCustomerInfo };