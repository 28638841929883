import React, {FC, useMemo} from "react";
import {SelectedTariffPlanDetails} from "../../../graphql/localTypes";
import {FormikErrors, FormikHandlers, FormikHelpers, FormikState, FormikTouched} from "formik";
import {UserNameInfo, UserNameInfoPartialProps} from "../../../shared/components/formBlocks/UserNameInfo";
import {FormattedMessage, useIntl} from "react-intl";
import {initialLabels} from "../../../shared/utils";
import RegistrationMessages from "../../registration/Registration.messages";
import {InputFieldFormik, UploadFiles} from "../../../shared/components";
import {FTTBMessages} from "../FTTB.messages";
import {PlanCard} from "../../fmc/Pages/Summary/PlanCard";
import {AcquisitionMessages} from "../../acquisitionFlow/Acquisition.messages";
import {UploadB2BFttbDocuments} from "../../../shared/components/document/B2BUploadDocuments";
import {B2BFttbContractUtils} from "../../../shared/components/document/B2BFttbContractUtils";
import {B2BFttbRouteState} from "./Pages/b2bFttb.type";

interface FttbSummaryDetailsProps {
	selectedFttbTariffPlan?: SelectedTariffPlanDetails;
	simpleConnection: boolean;
	formik: {
		values: DettailsValues;
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean | undefined
		) => any;
		touched: FormikTouched<DettailsValues>;
		handleChange: (
			eventOrPath: string | React.ChangeEvent<DettailsValues>
		) =>
			| void
			| ((
					eventOrTextValue: string | React.ChangeEvent<DettailsValues>
			  ) => void);
		errors: FormikErrors<DettailsValues>;
	};
	setFiles: React.Dispatch<React.SetStateAction<[]>>;
	files: any;
	uploadProgress: boolean;
	state: B2BFttbRouteState;
}
interface DettailsValues extends UserNameInfoPartialProps {
	phone: string;
	city: string;
	province: string;
	street: string;
	house: string;
	flat: string;
}
const lablesField = {
	house_number: "",
	apartment: "",
	street: "",
	province: "",
	contact_phone: "",
	city: "",
	flat: "",
};
const B2BFttbSummaryDetails: FC<FttbSummaryDetailsProps> = props => {
	const intl = useIntl();
	const {
		simpleConnection,
		selectedFttbTariffPlan,
		formik,
		setFiles,
		files,
		uploadProgress,
		state
	} = props;
	const {values} = formik;
	const textInfo = useMemo(() => intl.formatMessage(AcquisitionMessages.infoMessageUploadDocument), [intl.locale]);
	const labels = useMemo(
		() => initialLabels(intl, lablesField, RegistrationMessages),
		[intl.locale]
	);
	/*const contractPayload = {
		...genCustomerGenerateDocument(formik),
	} as PayloadProps;*/

	return (
		<>
			<div className="row">
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.contact_phone}
						id="phone"
						attr={{ disabled: true }}
						name="phone"
						value={values.phone}
						formik={formik}
					/>
				</div>
				<UserNameInfo disabled={true} formik={formik as (FormikState<DettailsValues> & FormikHandlers & FormikHelpers<DettailsValues>)}/>
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.province}
						id="province"
						attr={{ disabled: true }}
						name="province"
						value={values.province}
						formik={formik}
					/>
				</div>
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.city}
						id="city"
						attr={{ disabled: true }}
						name="city"
						value={values.city}
						formik={formik}
					/>
				</div>
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.street}
						id="street"
						attr={{ disabled: true }}
						name="street"
						value={values.street}
						formik={formik}
					/>
				</div>
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.house_number}
						id="house"
						attr={{ disabled: true }}
						name="house"
						value={values.house}
						formik={formik}
					/>
				</div>
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.flat}
						id="flat"
						attr={{ disabled: true }}
						name="flat"
						value={values.flat}
						formik={formik}
					/>
				</div>
				{simpleConnection && <div className="col-12">
					<h4>
						<FormattedMessage {...FTTBMessages.simpleConnectionHasBeenSelected} />
					</h4>
				</div>}
			</div>
			{selectedFttbTariffPlan && <div className="row">
				 <div className="col-12 my-3">
					<PlanCard
						change_price={selectedFttbTariffPlan?.change_price}
						fee={selectedFttbTariffPlan?.fee}
						periodic_unit={selectedFttbTariffPlan?.periodicUnit}
						periodic_amount={selectedFttbTariffPlan?.periodicAmount}
						name={selectedFttbTariffPlan?.name}
						medium_description={selectedFttbTariffPlan?.medium_description}
						long_description={selectedFttbTariffPlan?.long_description}
					/>
				</div>
			</div>}
			<div className="col-12">
				<UploadB2BFttbDocuments
					payload={B2BFttbContractUtils.formatPayload(state, intl.locale)}
					files={files}
					setFiles={setFiles}
					uploadProgress={uploadProgress}/>
			</div>
		</>
	);
};
export { B2BFttbSummaryDetails };
