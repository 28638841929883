import React, { FC, useState, useCallback, useMemo } from "react";
import { useFormik } from "formik";
import { InputFieldFormik, CircularProgress, NavigationHeader } from "../../../shared/components";
import { useIntl } from "react-intl";
import { useLazyQuery } from "@apollo/react-hooks";
import { Query, QueryLimited_SubscriptionArgs } from "../../../graphql/types";
import { MnpMessages } from "../Mnp.messages";
import { RouteComponentProps, StaticContext } from "react-router";
import { MNPRouteState, MNP_FLOW_PLAN_PATH, MNP_FLOW_MSISDN_PATH } from "../MNPFlowRoutes";
import { addPrefixToMsisdn } from "../../../shared/utils/utils";
import { MNPFlow } from "./MNPFlow";
import { temporaryMsisdn } from "../Mnp.schema";
import { GET_TEMPORARY_MSISDN_SUBSCRIPTION } from "../../../graphql/queries/subscription";
import { SubscriptionStatus } from "../../../shared/types";
import { ErrorMessage } from "../../../shared/components/ErrorMessageTemplate";
import ErrorMessages from "../../../shared/error/Error.messages";

const MNPTemporaryMsisdn: FC<RouteComponentProps<never, StaticContext, MNPRouteState>> = props => {
	const formId = "mnp-temporaty-msisdn-form-id";
	const intl = useIntl();
	const [error, setError] = useState<string>("");
	const {location: {state}, history} = props;

	const [searchMsisdn, { loading, variables }] = useLazyQuery<Required<Pick<Query, "limited_subscription">>, QueryLimited_SubscriptionArgs>(GET_TEMPORARY_MSISDN_SUBSCRIPTION, {
		fetchPolicy: "cache-and-network",
		onCompleted: (data) => {
			if (data?.limited_subscription?.is_portout_ongoing) {
				setError(intl.formatMessage({...ErrorMessages.msisdnPortingRequestOngoing}, {msisdn: variables.msisdn}));
				return;
			}

			if(!data?.limited_subscription?.subscription_exists
                || (data.limited_subscription.status !== SubscriptionStatus.ACTIVE &&
                    data.limited_subscription.status !== SubscriptionStatus.IDLE)) {
				setError(intl.formatMessage({...ErrorMessages.subscriptionNotFoundForMsisdn}));
				return;
			} 

			if(!data?.limited_subscription?.is_starterkit && !data?.limited_subscription?.is_prepaid) {
				setError(intl.formatMessage({...ErrorMessages.notStarterPack}));
				return;	
			}

			history.push({
				pathname: MNP_FLOW_PLAN_PATH,
				state: {
					...state,
					temporaryMsisdn: variables.msisdn,
					is_starterkit: Boolean(data?.limited_subscription?.is_starterkit),
					subscription: data?.limited_subscription,
				} as MNPRouteState,
			});
		}
	});


	const onSubmit = (values) => {
		setError("");
		searchMsisdn({
			variables: {
				msisdn: addPrefixToMsisdn(values.msisdn)
			}});
	};
	const validationSchema = useMemo(() => temporaryMsisdn(intl), [intl.locale]);
	const initialValues = {msisdn: state?.temporaryMsisdn || ""};

	const { values, setFieldValue, errors, touched, handleSubmit, handleChange } = useFormik({
		onSubmit,
		initialValues,
		validationSchema,
	});
	const formik = {values, setFieldValue, errors, touched, handleChange};

	const onClickBack = useCallback(() => history.push(MNP_FLOW_MSISDN_PATH, state), []);
	return (
		<>
			<NavigationHeader FlowComp={MNPFlow} back={onClickBack} formId={formId} disabledNext={loading}/>
			<div className="ipos-content-box">
				<form id={formId} onSubmit={handleSubmit}>
					<InputFieldFormik
						id="msisdn"
						label={intl.formatMessage(MnpMessages.enterTemporaryMsisdn)}
						value={values.msisdn}
						name="msisdn"
						required
						formik={formik}
					/>
				</form>
				<ErrorMessage error={error} />
				{loading && <CircularProgress />}
			</div>
		</>
	);
};

export {
	MNPTemporaryMsisdn
};
