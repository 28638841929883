import React from "react";
import { FormattedMessage } from "react-intl";
import { CommonMessages } from "../../Common.messages";
import { CircularProgress, HasPermission, MsisdnTopBarInfo } from "../../shared/components";
import { PermissionRolesEnum } from "../../shared/types/PermissionRolesEnum";
import { ExistingCustomer } from "../acquisitionFlow/ExistingCustomer";
import { NewCustomer } from "../acquisitionFlow/NewCustomer/NewCustomer";
import { FTTB } from "../fttb/FTTB";
import { FMC } from "../fmc/FMC";
import { useQuery } from "@apollo/react-hooks";
import { QueryLivesettingArgs, Query } from "../../graphql/types";
import { GET_CSR_TAB_LINK } from "../../graphql/queries/livesettings";
import { LiveSettingEnum } from "../../graphql/types";
import { MainMnp } from "../mnp/MainMnp";
import { useAuthDataContext } from "../../shared/hooks/AuthentificationProvider";
import { config, CSRFTOKEN } from "../../config/constants";
import Cookies from "js-cookie";

const Main = () => {
	const csrf = Cookies.get(CSRFTOKEN);

	const {data} = useQuery<Query, QueryLivesettingArgs>(GET_CSR_TAB_LINK, {
		variables: {
			name: LiveSettingEnum.CsrtbLink,
		},
		skip: !Boolean(csrf),
	});
	const { loading } = useAuthDataContext();
	if (loading) {
		return <CircularProgress/>;
	}
	return (
		<>
			<MsisdnTopBarInfo showIcon={false}><FormattedMessage {...CommonMessages.frontPage}/></MsisdnTopBarInfo>
			<div id="w-app-body">
				<main className="layout">
					<HasPermission accessFor={[PermissionRolesEnum.CUSTOMER_MANAGEMENT_PERMISSION]}>
						<div className="w-box">
							<a className="btn btn-block btn-secondary" rel="noopener noreferrer" target="_blank" href={data?.livesetting?.value!}>
								<FormattedMessage {...CommonMessages.customerManagement} />
							</a>
						</div>
					</HasPermission>
					<div className="w-box">
						<HasPermission accessFor={[PermissionRolesEnum.CREATE_ACQUISITION_ORDER]}>
							<NewCustomer/>
						</HasPermission>
						{config.POSTPAID_ON && <HasPermission accessFor={[PermissionRolesEnum.CREATE_RETENTION_ORDER]}>
							<ExistingCustomer/>
						</HasPermission>}
						<HasPermission accessFor={[PermissionRolesEnum.CREATE_MNP_ORDER]}>
							<MainMnp/>
						</HasPermission>
					</div>
					<HasPermission accessFor={[PermissionRolesEnum.CREATE_FTTB_ORDER]}>
						<div className="w-box">
							<FTTB />
						</div>
					</HasPermission>
					<HasPermission accessFor={[PermissionRolesEnum.CREATE_FMC_ORDER]}>
						<div className="w-box">
							<FMC />
						</div>
					</HasPermission>
				</main>
			</div>
		</>
	);
};

export { Main };
