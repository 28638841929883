import moment from "moment";
import { Field } from "formik";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { config } from "../../../../../../../config/constants";
import { UserMessages } from "../../../../../../user/User.messages";
import { DocumentTypeEnum } from "../../../../../../../shared/types/DocumentTypeEnum";
import { RegistrationMessages } from "../../../../../../registration/Registration.messages";
import { document_type_post_pay, transformToEngLetter, document_type_post_pay_additional } from "../../../../../../../shared/utils";
import { DatePickerField, InputField, SelectField } from "../../../../../../../shared/components";

interface RegistrationDocumentProps {
    setFieldValue: (field: string, value: any) => void;
    setValues: (obbj: any) => void;
    values: any;
    labels: any;
}

const RegistrationDocument = (props: RegistrationDocumentProps) => {
	const intl = useIntl();
	const documentTypes = useMemo(() => document_type_post_pay(intl), [intl.locale]);
	const documentAdditionalTypes = useMemo(() => document_type_post_pay_additional(intl), [intl.locale]);
	const placeholder = useMemo(() => intl.formatMessage({...UserMessages.choose}), [intl.locale]);

	const onChangeDocumentNumber = (fieldName, setFieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = e.target.value.trim().toUpperCase();
		const transformValue = transformToEngLetter(value);
		setFieldValue(fieldName, transformValue);
	};

	const lengthOfDocumentNumber = (values) => {
		if (values.id_document_type === DocumentTypeEnum.passport) {
			return config.PASSPORT_LENGTH;
		}
		if (values.id_document_type === DocumentTypeEnum.national_id) {
			return config.NATIONAL_ID;
		}
		return config.DEFAULT_INPUT_LENGTH;
	};

	const onChangeAdditionalDocument = () => {
		props.setValues( {
			...props.values,
			additional_document: !props.values.additional_document,
			id_document_type_additional: "",
			id_document_number_additional: "",
			id_doc_issue_date_additional: "",
			id_doc_valid_till_date: "",
			id_document_number: "",
			id_doc_issued_by: "",
			id_doc_issue_date: "",
		});
	};
	return (
		<>
			<div className="col-12 col-sm-6">
				<Field
					required={!props.values.additional_document}
					name="id_document_type"
					id="id_document_type"
					label={props.labels.id_document_type}
					placeholder={placeholder}
					attr={{
						maxLength: 256,
						disabled: props.values.additional_document
					}}
					values={documentTypes}
					component={SelectField}
				/>
			</div>
			<div className="col-12 col-sm-6">
				<Field
					required={!props.values.additional_document}
					name="id_document_number"
					id="document-number"
					label={props.labels.id_document_number}
					fullWidth
					attr={{
						maxLength: lengthOfDocumentNumber(props.values),
						disabled: props.values.additional_document
					}}
					onChange={onChangeDocumentNumber}
					component={InputField}
				/>
			</div>
			<div className="col-12 col-sm-12">
				<Field
					name="id_doc_issued_by"
					id="issued-by"
					label={props.labels.id_doc_issued_by}
					attr={{maxLength: 256}}
					component={InputField}
				/>
			</div>
			<div className="col-12 col-sm-6">
				<Field
					required={!props.values.additional_document}
					min={moment(config.MIN_DATE_FORM)}
					max={moment()}
					name="id_doc_issue_date"
					disabled={props.values.additional_document}
					label={props.labels.id_doc_issue_date}
					format={config.DATE_PICKER_FORMAT}
					component={DatePickerField}
				/>
			</div>
			<div className="col-12 col-sm-6">
				<Field
					max={moment().endOf("year").add(config.DATE_STEP, "year")}
					min={moment().subtract(1, "day")}
					disabled={props.values.additional_document}
					name="id_doc_valid_till_date"
					label={props.labels.id_doc_valid_till_date}
					format={config.DATE_PICKER_FORMAT}
					component={DatePickerField}
				/>
			</div>
			<div className="col-12">
				<div className="custom-control custom-checkbox">
					<input
						name="additional_document"
						type="checkbox"
						onChange={onChangeAdditionalDocument}
						checked={props.values.additional_document}
						className="custom-control-input"
						id="additional_document"
					/>
					<label className="custom-control-label" htmlFor="additional_document">
						<FormattedMessage {...RegistrationMessages.withOut} values={{
							value:intl.formatMessage({...RegistrationMessages.originalPassport})
						}}/>
					</label>
				</div>
			</div>
			{props.values.additional_document && (<>
				<div className="col-12 col-sm-6">
					<Field
						required
						name="id_document_type_additional"
						id="id_document_type"
						label={intl.formatMessage({...RegistrationMessages.id_document_type_additional})}
						placeholder={placeholder}
						attr={{maxLength: 256}}
						values={documentAdditionalTypes}
						component={SelectField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						required
						name="id_document_number_additional"
						id="document-number"
						label={intl.formatMessage({...RegistrationMessages.id_document_number_additional})}
						fullWidth
						attr={{maxLength: lengthOfDocumentNumber(props.values)}}
						onChange={onChangeDocumentNumber}
						component={InputField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						required
						min={moment(config.MIN_DATE_FORM)}
						max={moment()}
						name="id_doc_issue_date_additional"
						label={intl.formatMessage({...RegistrationMessages.id_doc_issue_date_additional})}
						format={config.DATE_PICKER_FORMAT}
						component={DatePickerField}
					/>
				</div>
			</>)}
		</>
	);
};

export { RegistrationDocument };