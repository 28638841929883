import React, { useMemo, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { companyType, initialLabels } from "../../../../../../shared/utils";
import { SelectField } from "../../../../../../shared/components";
import { RegistrationMessages } from "../../../../../registration/Registration.messages";
import { schemaNewCustomer, schemaNewCompany } from "./PrePayForm.schema";
import InitialValuesType from "../../../../../registration/Registration.types";
import UserMessages from "../../../../../user/User.messages";
import "react-dates/initialize";
import { CompanyForm } from "./CompanyForm";
import { PersonForm } from "./PersonForm";
import { AddressForm } from "../AddressForm";
import { AuthorizedPersonForm } from "../AuthorizedPersonForm";

interface PrePayFormProps {
    initialValues: any;
    onSubmit: (values: any) => void;
    formId: string;
    children?: any;
}

const PrePayForm = (props: PrePayFormProps) => {
	const intl = useIntl();
	const formId = props.formId ? props.formId : "";
	const [isCompany, setCompany] = useState(false);
	const validationCustomer = useMemo(() => schemaNewCustomer(intl), [intl.locale]);
	const validationCompany = useMemo(() => schemaNewCompany(intl), [intl.locale]);
	const labels = useMemo(() => initialLabels<InitialValuesType>(intl, props.initialValues, RegistrationMessages), [intl.locale]);
	const placeholder = useMemo(() => intl.formatMessage({...UserMessages.choose}), [intl.locale]);
	const onChange = (name, setFieldValue) => (e) => {
		const value = e.target.value === "false" ? false : true;
		setFieldValue(name, value);
		setCompany(value);
	};
	return (
		<>
			<Formik
				onSubmit={props.onSubmit}
				initialValues={props.initialValues}
				enableReinitialize={true}
				validationSchema={isCompany ? validationCompany : validationCustomer}
			>
				{({values, setFieldValue, setTouched, setValues, touched, errors}) => {
					return (<Form id={formId} noValidate>
						<div className="row">
							<div className="col-12 col-sm-6">
								<Field
									name="is_company"
									id="is_company"
									label={labels.is_company}
									intl={intl}
									placeholder={placeholder}
									onChange={onChange}
									values={companyType(intl)}
									component={SelectField}
								/>
							</div>
						</div>
						{!values.is_company && (<>
							<PersonForm
								values={values}
								setFieldValue={setFieldValue}
								initialValues={props.initialValues}
							/>
							<AddressForm
								setTouched={setTouched}
								errors={errors}
								touched={touched}
								values={values}
								setValues={setValues}
								requiredShow={true}
								setFieldValue={setFieldValue}
							/>
							<AuthorizedPersonForm values={values} setValues={setValues}/>
						</>)}
						{values.is_company && (<>
							<CompanyForm
								values={values}
								setFieldValue={setFieldValue}
								initialValues={props.initialValues} />
							<AddressForm
								setTouched={setTouched}
								errors={errors}
								touched={touched}
								values={values}
								setValues={setValues}
								requiredShow={true}
								setFieldValue={setFieldValue}
							/>
							<AuthorizedPersonForm values={values} setValues={setValues}/>
						</>)}
						{props.children}
					</Form>);
				}}
			</Formik>
		</>
	);
};

export { PrePayForm };