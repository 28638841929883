import React from "react";
import { MsisdnTopBarInfo } from "../../../../shared/components";
import { Switch } from "react-router";
import { ErrorBoundaryRoute } from "../../../../shared/error/ErrorBoundaryRoute";
import { MsisdnPage } from "../Pages/MsisdnPage/MsisdnPage";
import { PlanPage } from "../Pages/PlanPage/PlanPage";
import { OptionsPage } from "../Pages/OptionsPage/OptionsPage";
import { IccidPage } from "../Pages/IccidPage/IccidPage";
import { ContactInfoPage } from "../Pages/ContactInfo/ContactInfoPage";
import { SummaryPage } from "../Pages/SummaryPage/SummaryPage";
import { FormattedMessage } from "react-intl";
import { AcquisitionMessages } from "../../Acquisition.messages";
import {
	ACQUISITION_FLOW_MSISDN_PATH,
	ACQUISITION_FLOW_PLAN_PATH,
	ACQUISITION_FLOW_OPTIONS_PATH,
	ACQUISITION_FLOW_ICCID_PATH,
	ACQUISITION_FLOW_CONTACT_INFO_PATH,
	ACQUISITION_FLOW_SUMMARY_PATH
} from "./AcquisitionFlowRoutes";

const AcquisitionPageRouter = () => {
	return (
		<>
			<MsisdnTopBarInfo>
				<FormattedMessage {...AcquisitionMessages.acquisitionFlow} />
			</MsisdnTopBarInfo>
			<div id="w-app-body">
				<Switch>
					<ErrorBoundaryRoute exact path={ACQUISITION_FLOW_MSISDN_PATH} component={MsisdnPage}/>
					<ErrorBoundaryRoute path={ACQUISITION_FLOW_PLAN_PATH} component={PlanPage}/>
					<ErrorBoundaryRoute path={ACQUISITION_FLOW_OPTIONS_PATH} component={OptionsPage}/>
					<ErrorBoundaryRoute path={ACQUISITION_FLOW_ICCID_PATH} component={IccidPage}/>
					<ErrorBoundaryRoute path={ACQUISITION_FLOW_CONTACT_INFO_PATH} component={ContactInfoPage}/>
					<ErrorBoundaryRoute path={ACQUISITION_FLOW_SUMMARY_PATH} component={SummaryPage}/>
				</Switch>
			</div>
		</>
	);
};

export { AcquisitionPageRouter };