import gql from "graphql-tag";

const POST_ACTIVATE_PACKAGE = gql`
    mutation addPackage($subscription_id: ID!, $package: String!) {
        add_package(subscription_id: $subscription_id, package: $package) {
          request_id,
          status,
          error
        }
    }
`;

const REMOVE_ACTIVE_PACKAGE = gql`
    mutation removePackage($subscription_id: ID!, $package: String!, $product_instance_id: Int) {
        remove_package(subscription_id: $subscription_id, package: $package, product_instance_id: $product_instance_id) {
          request_id,
          status,
          error
        }
    }
`;

const UPDATE_ICC_CHANGE_SIM = gql`
    mutation changeSim($subscription_id: ID!, $old_icc: String!, $new_icc: String!, $reason: String!) {
        change_sim(subscription_id: $subscription_id, old_icc: $old_icc, new_icc: $new_icc, reason: $reason) {
            request_id,
            status,
            error,
        }
    }
`;

const CHANGE_MSISDN = gql`
    mutation changeMsisdn($subscription_id: ID!, $old_msisdn: String!, $new_msisdn: String!, $reason: String!) { 
        change_msisdn(subscription_id: $subscription_id, old_msisdn: $old_msisdn, new_msisdn: $new_msisdn, reason: $reason) {
            request_id,
            status,
            error,
            error_code,
        }
    }
`;

const CHANGE_TARIFF_PLAN = gql`
    mutation changeTariffPlan($subscription_id: ID!, $type_id: String!, $new_packages: [PackageInput], $send_sms: Boolean!) {
        change_subscription_type(subscription_id: $subscription_id, type_id: $type_id, new_packages: $new_packages, send_sms: $send_sms) {
            request_id,
            status,
            error
        }
    }
`;

const CHANGE_CUSTOMER = gql`
    mutation changeCustomers($subscription_id: ID!, $customer_id: ID!) {
        personalize_for_existing_customer(subscription_id: $subscription_id, customer_id: $customer_id) {
            request_id,
            status,
            error
        }
    }
`;

const BALANCE_TRANSFER = gql`
    mutation DealerBalanceTransfer($target_msisdn: String!, $amount: Int!, $currency: String!) {
        dealer_balance_transfer(target_msisdn: $target_msisdn, amount: $amount, currency: $currency) {
            request_id,
            status,
            error
        }
    }
`;

const ACTIVATE_SUBSCRIPTION = gql`
    mutation activateSubscription($subscription_id: ID!) {
        activate_subscription(subscription_id: $subscription_id) {
            request_id,
            status,
            error
        }
    }
`;
const UPLOAD_DOCUMENT = gql`
    mutation UploadDocument($details: UploadDocument!) {
        upload_document(details: $details) {
            request_id,
            status,
            error
        }
    }
`;

const GET_UID_DOCUMENT_RESERVATION = gql`
    mutation documentUidNumberReservations {
        document_uid_number_reservations {
            request_id,
            status,
            error,
            error_code,
        }
    }
`;

const CAMPAING_OFFER_OPT_IN = gql`
    mutation campaignOfferAction($subscription_id: ID!, $offer_id: String!, $action_type_name: String!) {
        campaign_offer_action(subscription_id: $subscription_id, offer_id: $offer_id, action_type_name: $action_type_name) {
            request_id,
            status,
            error
        }
    }
`;

const RESERVE_MSISDN = gql`
    mutation reserveMsisdn($msisdn: String!) {
        reserve_msisdn(msisdn: $msisdn) {
            request_id,
            status,
            error,
            attributes {
                reservation_id,
                reserve_valid_for,
            }
        }
    }
`;

export {
	BALANCE_TRANSFER,
	UPDATE_ICC_CHANGE_SIM,
	CHANGE_TARIFF_PLAN,
	POST_ACTIVATE_PACKAGE,
	REMOVE_ACTIVE_PACKAGE,
	CHANGE_CUSTOMER,
	ACTIVATE_SUBSCRIPTION,
	CHANGE_MSISDN,
	UPLOAD_DOCUMENT,
	GET_UID_DOCUMENT_RESERVATION,
	CAMPAING_OFFER_OPT_IN,
	RESERVE_MSISDN,
};
