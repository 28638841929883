/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface DropdownLanguageMessagesType {
	en: MessageDescriptor;
	ky: MessageDescriptor;
	ru: MessageDescriptor;
	uk: MessageDescriptor;
	uz: MessageDescriptor;
}
const DropdownLanguageMessages: DropdownLanguageMessagesType = defineMessages({
	en: {
		id: "code-language-en",
		description: "Language",
		defaultMessage: "English"
	},
	ky: {
		id: "code-language-ky",
		description: "Language",
		defaultMessage: "Кыргызча"
	},
	ru: {
		id: "code-language-ru",
		description: "Language",
		defaultMessage: "Русский"
	},
	uk: {
		id: "code-language-uk",
		description: "Language",
		defaultMessage: "Українська"
	},
	uz: {
		id: "code-language-uz",
		description: "Language",
		defaultMessage: "Oʻzbek"
	},
});

export default DropdownLanguageMessages;
export { DropdownLanguageMessages };
