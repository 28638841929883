import * as Yup from "yup";
import { IntlShape } from "react-intl";
import { digitalExp, maxIccWithoutPrefix } from "../../../../../shared/utils";
import { CommonMessages } from "../../../../../Common.messages";

const iccIdSchema = (intl: IntlShape) => {
	return Yup.object().shape({
		iccId: Yup.string()
			.matches(digitalExp, intl.formatMessage({...CommonMessages.onlyDigits}))
			.max(maxIccWithoutPrefix, intl.formatMessage({...CommonMessages.iccValidationMax}, {max: maxIccWithoutPrefix}))
			.required(intl.formatMessage({...CommonMessages.required})),
	});
};

export { iccIdSchema };
