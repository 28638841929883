import React, { FC, useCallback, useMemo, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@apollo/react-hooks";
import { AcquisitionMessages } from "../../../Acquisition.messages";
import { CommonMessages } from "../../../../../Common.messages";
import { UploadFiles } from "../../../../../shared/components/UploadFiles";
import { useAuthDataContext } from "../../../../../shared/hooks/AuthentificationProvider";
import { Mutation, MutationCreate_Acquisition_OrderArgs, Package, SubscriptionInput } from "../../../../../graphql/types";
import { ErrorMessageTemplate, SuccessMessageTemplate, NavigationHeader, CircularProgress } from "../../../../../shared/components";
import { ACQUISITION_FLOW_CONTACT_INFO_PATH, INDEX_PATH } from "../../Routes/AcquisitionFlowRoutes";
import { DeliveryTypeEnum, ProductTypeEnum, SalesTypeEnum } from "../../../../../graphql/types";
import { useAcquisitionUploadDocument } from "../../../../../shared/hooks/useAcquisitionUploadDocument";
import { clearLocalStorageFromNewCustomer, genCustomerCreateOrder, genCustomerGenerateDocument } from "../../../../../shared/utils/acquisitionFlowUtils";
import { DocumentGeneration } from "../../../../../shared/components/DocumentGeneration";
import { AcquisitionFlow } from "../../Routes/AcquisitionFlow";
import { SelectedTariffPlanDetails, PersonalInfo } from "../../../../../graphql/localTypes";
import { OptionsCard } from "../../../../../shared/components/option/OptionsCard";
import { PlanCard } from "../../../../fmc/Pages/Summary/PlanCard";
import { TARIFF_PLAN_TYPE_POSTPAID, TARIFF_PLAN_TYPE_PREPAID } from "..";
import { PayloadProps } from "../../../../../shared/hooks/useDocumentGeneration";
import { CREATE_ACQUISITION_ORDER } from "../../../../../graphql/mutations/order";
import { REMOVE_SPECIAL_CHARACTER } from "../../../../../shared/utils";
import { msisdnActions, useStoreContext } from "../../../../../shared/components/navigation/StepStateProvider";

export interface AcquisitionFlowState {
    msisdn: string;
    reservationId: string;
    personalInfo: PersonalInfo;
    iccId: string;
    plan: SelectedTariffPlanDetails;
    options: Package[];
    reservation_id: string;
    account_id?: string;
}
const SummaryPage: FC<RouteComponentProps<never, StaticContext, AcquisitionFlowState>> = props => {
	const intl = useIntl();

	const {dispatch} = useStoreContext();
	const {location, history} = props;
	const {state} = location;
	const {userName, currentDealerIdentety} = useAuthDataContext();
	const [documentUploadSuccess, setDocumentUploadSuccess] = useState<boolean>(false);
	const [spinner, runSpinner] = useState<boolean>(false);
	const [errorMsg, setError] = useState<string>("");
	const [order, createOrderFor] = useState<string>("");
	const textInfo = useMemo(() => intl.formatMessage(AcquisitionMessages.infoMessageUploadDocument), [intl.locale]);
	const clearOldData = async (): Promise<any> => {
		await clearLocalStorageFromNewCustomer();
	};
	const msisdn = state?.msisdn || "";

	const skipCustomerData = state?.personalInfo?.skipPersonalData;
	const options = state?.options.filter((item: Package) => item.code).map(item => item.code!) || [];
	if(state?.plan?.selectedOptionCode) {
		options.push(state?.plan?.selectedOptionCode);
	}
	const {...customer} = state?.personalInfo;
	const customerForCreateOrder = genCustomerCreateOrder(customer, skipCustomerData);
	const contractPayload = {
		...genCustomerGenerateDocument(customer),
		icc: state?.iccId,
		tariffPlanName: state?.plan?.code,
		tariffPlanType: state?.plan.planType,
		msisdnReservationCode: state?.reservationId,
		salesman_username: userName,
		billing_account: state?.account_id
	} as PayloadProps;
	const [uploadDocument, files, setFiles, uploadProgress, error] = useAcquisitionUploadDocument();
	const [createOrder] = useMutation<Required<Pick<Mutation, "create_acquisition_order">>, MutationCreate_Acquisition_OrderArgs>(CREATE_ACQUISITION_ORDER, {
		onError: () => {
			setError(intl.formatMessage(CommonMessages.createOrderFail, {msisdn}));
		}
	});

	const onClickDone = useCallback(async(): Promise<any> => {
		await clearOldData();
		history.push(INDEX_PATH);
	}, []);

	const onClickNext = Boolean(order) ? onClickDone : undefined;

	const onClickBack = useCallback(() => {
		history.push({
			pathname: ACQUISITION_FLOW_CONTACT_INFO_PATH,
			state,
		});
	}, []);

	const productSubscription = (): SubscriptionInput => {
		const userAndPayer = skipCustomerData ? {} : {
			payer: customerForCreateOrder,
			user: customerForCreateOrder,
		};
		return {
			// connection_type: ConnectionTypeEnum.FourthGen,
			msisdn,
			type: state?.plan?.code || "",
			packages: options,
			product_type: ProductTypeEnum.Subscription,
			...userAndPayer,
			reservation_id: state?.reservationId || "",
		};
	};

	const confirmationCode = state?.reservationId?.replace(REMOVE_SPECIAL_CHARACTER, "");
	//default commercial billing account type { type: "1"}
	const billing_account = state.plan.planType === TARIFF_PLAN_TYPE_POSTPAID ? { type: "1"} : {};
	const onSubmit = () => {
		setDocumentUploadSuccess(false);
		runSpinner(true);
		setError("");
		createOrder({
			variables: {
				details: {
					offer: state?.plan?.offerCode || "",
					delivery_type: DeliveryTypeEnum.Direct,
					customer: customerForCreateOrder,
					confirmation_code: confirmationCode || "",
					sales_info: {
						sales_type: SalesTypeEnum.Acquisition,
						reseller_code: currentDealerIdentety?.reseller_code
					},
					products: {
						subscription: productSubscription(),
						sim: {
							product_type: ProductTypeEnum.Simcard,
							type: state?.plan.offerSimCardCode!,
							icc: state?.iccId || "",
							msisdn: { msisdn },
							reservation_id: state?.reservationId || "",
						}
					},
					billing_account
				}
			}
		}).then(({data}) => {
			if (data?.create_acquisition_order?.status === 202) {
				if (files.length > 0) {
					uploadDocument({
						purpose: "ACQUISITION_FLOW",
						confirmation_code: state?.reservationId!
					}, () => {
						setDocumentUploadSuccess(true);
						runSpinner(false);
					}, () => {
						runSpinner(false);
					});
				} else {
					runSpinner(false);
				}
				createOrderFor(msisdn);
			} else if (data?.create_acquisition_order?.error) {
				throw data?.create_acquisition_order?.error;
			} else {
				throw intl.formatMessage(CommonMessages.createOrderFail, {msisdn});
			}
		}).then(() => {
			dispatch(msisdnActions.removeMsisdn(msisdn));
			return clearOldData();
		}).catch((error) => {
			setError(error);
			runSpinner(false);
		});
	};
	return (
		<>
			<NavigationHeader
				FlowComp={AcquisitionFlow}
				next={onClickNext}
				back={onClickBack}
				hide={Boolean(order)}
			/>
			<div className="ipos-content-box SummaryPage">
				<div className="row">
					<div className="col-12 col-md-4">
						<h4><FormattedMessage {...CommonMessages.msisdn} />: {state?.msisdn}</h4>
					</div>
					<div className="col-12 col-md-4">
						<h4><FormattedMessage {...CommonMessages.icc} />: {state?.iccId}</h4>
					</div>
					<div className="col-12">
						<PlanCard
							change_price={state?.plan?.change_price!}
							fee={state?.plan?.fee!}
							periodic_unit={state?.plan?.periodicUnit!}
							periodic_amount={state?.plan?.periodicAmount!}
							name={state?.plan?.name}
							medium_description={state?.plan?.medium_description}
							long_description={state?.plan?.long_description}
						/>
					</div>
					<div className="col-12 my-3">
						<OptionsCard packages={state?.options}/>
					</div>
				</div>
				{!skipCustomerData && !errorMsg && !order && <DocumentGeneration
					skipCustomerData={skipCustomerData}
					payload={contractPayload}
					msisdn={msisdn}
					setContractGenerationError={setError}
				/>}
				<UploadFiles
					setFiles={setFiles}
					files={files}
					uploadProgress={uploadProgress}
					error={error}
					hideDocumentLink={true}
					textInfo={textInfo}
					required={!skipCustomerData && !state?.personalInfo?.withoutDocument}
				/>
				{Boolean(order) && (<SuccessMessageTemplate show={true}>
					<FormattedMessage {...CommonMessages.createOrderSuccess} values={{order}}/>
				</SuccessMessageTemplate>)}
				{documentUploadSuccess && (<SuccessMessageTemplate show={true}>
					<FormattedMessage {...CommonMessages.documentUploadSuccess} values={{order}}/>
				</SuccessMessageTemplate>)}
				<ErrorMessageTemplate show={errorMsg}>{errorMsg}</ErrorMessageTemplate>
				<div className="text-right">
					{spinner && (<span className="pr-2"><CircularProgress showText={false}/></span>)}
					{Boolean(order) ? (
						<button type="button" className="btn btn-primary" onClick={onClickDone}>
							<FormattedMessage {...CommonMessages.done} />
						</button>
					) : (
						<button type="button" className="btn btn-primary" onClick={onSubmit}
							disabled={
								spinner || (!Boolean(files?.length && files?.length > 0) && !skipCustomerData && state.plan.planType === TARIFF_PLAN_TYPE_PREPAID)
                                || !Boolean(files?.length && files?.length > 0) && !state?.personalInfo?.withoutDocument && state.plan.planType === TARIFF_PLAN_TYPE_POSTPAID}>
							<FormattedMessage {...CommonMessages.submit} />
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export { SummaryPage };
