/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface ChangePlanMessagesType {
	active: MessageDescriptor;
	alert: MessageDescriptor;
	availablePlans: MessageDescriptor;
	changePlan: MessageDescriptor;
	childProductNotFound: MessageDescriptor;
	currentPlan: MessageDescriptor;
	fee: MessageDescriptor;
	fullDescription: MessageDescriptor;
	migrateNow: MessageDescriptor;
	migrationFee: MessageDescriptor;
	monthlyFee: MessageDescriptor;
	newPlan: MessageDescriptor;
	optionalProductsNotFound: MessageDescriptor;
	plan: MessageDescriptor;
	recommendedPlansNotFound: MessageDescriptor;
	recommendedTariffPlans: MessageDescriptor;
	selectPlan: MessageDescriptor;
	selected: MessageDescriptor;
	subscription: MessageDescriptor;
	tariffDetails: MessageDescriptor;
}
const ChangePlanMessages: ChangePlanMessagesType = defineMessages({
	active: {
		id: "change-plan-active",
		description: "Active tariff plan status",
		defaultMessage: "Active"
	},
	alert: {
		id: "change-plan-tariff-label-alert",
		description: "Text, delay update tariff plan",
		defaultMessage: "After confirmation, the process will take up to a few minutes to complete. During that time old plan remains visible."
	},
	availablePlans: {
		id: "change-plan-tariff-title-for-available-plans",
		description: "Label for available plans",
		defaultMessage: "Available plans"
	},
	changePlan: {
		id: "change-plan-title",
		description: "Change Plan dialog title",
		defaultMessage: "Change Plan"
	},
	childProductNotFound: {
		id: "change-plan-optional-child-product-not-found",
		description: "Optional child products for tariff plan empty",
		defaultMessage: "There is no optional child products"
	},
	currentPlan: {
		id: "change-plan-current",
		description: "Current tariff plans title",
		defaultMessage: "Current plan"
	},
	fee: {
		id: "change-plan-tariff-table-fee-title",
		description: "Title of the table column",
		defaultMessage: "Recurring fee"
	},
	fullDescription: {
		id: "change-plan-tariff-title-for-description",
		description: "Label for describle tariff plan",
		defaultMessage: "Full description"
	},
	migrateNow: {
		id: "change-plan-migrate-now",
		description: "Migrate now button",
		defaultMessage: "Migrate now"
	},
	migrationFee: {
		id: "change-plan-tariff-table-migration-fee-title",
		description: "Title of the table column Migration Fee",
		defaultMessage: "Migration Fee"
	},
	monthlyFee: {
		id: "change-plan-monthly-fee",
		description: "Title for monthly fee",
		defaultMessage: "Monthly Fee"
	},
	newPlan: {
		id: "change-plan-tariff-title-column-new-plan",
		description: "Label for block change tariff plan",
		defaultMessage: "New Plan"
	},
	optionalProductsNotFound: {
		id: "change-plan-optional-product-not-found",
		description: "Optional products for tariff plan empty",
		defaultMessage: "There is no optional products"
	},
	plan: {
		id: "change-plan-tariff-title-for-column-available-table",
		description: "Label for column of the available table",
		defaultMessage: "Plan"
	},
	recommendedPlansNotFound: {
		id: "change-plan-tariff-not-found",
		description: "Recommended plans empty list",
		defaultMessage: "There is no recommended plans"
	},
	recommendedTariffPlans: {
		id: "change-plan-recommended",
		description: "Recommended tariff plans title",
		defaultMessage: "Recommended tariff plans"
	},
	selectPlan: {
		id: "change-plan-tariff-title-for-button",
		description: "Title button",
		defaultMessage: "Select plan"
	},
	selected: {
		id: "change-plan-tariff-title-for-button-selected",
		description: "Title button",
		defaultMessage: "Selected"
	},
	subscription: {
		id: "change-plan-tariff-link-to-subscription",
		description: "Title for link",
		defaultMessage: "Subscription"
	},
	tariffDetails: {
		id: "change-plan-tariff-details",
		description: "Tariff details button",
		defaultMessage: "Tariff details"
	},
});

export default ChangePlanMessages;
export { ChangePlanMessages };
