import { useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { FttbAddressEnum } from "../../shared/types/FttbEnum";
import { gisSystem } from "../../shared/utils/common_mock_data";
import {
	GET_CITIES,
	GET_DISTRICTS,
	GET_FLATS,
	GET_HOUSES,
	GET_REGIONS,
	GET_STREETS
} from "../../graphql/queries/gisSystem";
import {GisCity, GisHouse, GisStreet, GisRegion, GisDistrict, Maybe, GisFlat} from "../../graphql/types";
import { config } from "../../config/constants";
import { useIntl } from "react-intl";

interface LoadingStateProps {
    [FttbAddressEnum.city]: boolean;
    [FttbAddressEnum.street]: boolean;
    [FttbAddressEnum.house_number]: boolean;
    [FttbAddressEnum.province]: boolean;
    [FttbAddressEnum.district]: boolean;
	[FttbAddressEnum.flat]: boolean;
}

interface GisSystemState {
    // [FttbAddressEnum.countries]: GisCountry[];
    [FttbAddressEnum.city]: GisCity[];
    [FttbAddressEnum.street]: GisStreet[];
    [FttbAddressEnum.house_number]: GisHouse[];
    [FttbAddressEnum.province]: GisRegion[];
    [FttbAddressEnum.district]: GisDistrict[];
	[FttbAddressEnum.flat]?: GisFlat[];
}

const data = true ?
	{
		// countries: [] as GisCountry[],
		city: [] as GisCity[],
		street: [] as GisStreet[],
		house_number: [] as GisHouse[],
		district: [] as GisDistrict[],
		province: [] as GisRegion[],
		flat: [] as GisFlat
	} : gisSystem;

const useGisSystems = (initGis: GisSystemState|undefined): [
    LoadingStateProps,
    GisSystemState,
    (type: FttbAddressEnum, value: Maybe<GisHouse>[] | null | undefined) => void,
    (type: keyof typeof FttbAddressEnum, query: string, id?: string) => void,
] => {
	const {locale} = useIntl();
	const [gisState, updateGisState] = useState<GisSystemState>(initGis || data as GisSystemState);
	const [isLoading, setIsLoading] = useState<LoadingStateProps>({
		[FttbAddressEnum.city]: false,
		[FttbAddressEnum.street]: false,
		[FttbAddressEnum.house_number]: false,
		[FttbAddressEnum.province]: false,
		[FttbAddressEnum.district]: false,
		[FttbAddressEnum.flat]: false,
	});
	const [searchCities] = useLazyQuery(GET_CITIES, {
		fetchPolicy: "cache-and-network",
		onCompleted: (data) => {
			if (data?.gis_cities) {
				updateGisState({...gisState, city: data.gis_cities});
				setIsLoading({...isLoading, city: false});
			}
		}
	});

	const [searchRegions] = useLazyQuery(GET_REGIONS, {
		fetchPolicy: "cache-and-network",
		onCompleted: (data) => {
			if (data?.gis_regions) {
				updateGisState({...gisState, province: data.gis_regions as never});
				setIsLoading({...isLoading, province: false});
			}
		}
	});

	const [searchDistricts] = useLazyQuery(GET_DISTRICTS, {
		fetchPolicy: "cache-and-network",
		onCompleted: (data) => {
			if (data?.gis_districts) {
				updateGisState({...gisState, district: data.gis_districts as never});
				setIsLoading({...isLoading, district: false});
			}
		}
	});

	const [searchStreets] = useLazyQuery(GET_STREETS, {
		fetchPolicy: "cache-and-network",
		onCompleted: (data) => {
			if (data?.gis_streets) {
				updateGisState({...gisState, street: data?.gis_streets });
				setIsLoading({...isLoading, street: false});
			}
		}
	});

	const [searchHouses] = useLazyQuery(GET_HOUSES, {
		fetchPolicy: "cache-and-network",
		onCompleted: (data) => {
			if (data?.gis_houses) {
				updateGisState({...gisState, house_number: [data.gis_houses] as never});
				setIsLoading({...isLoading, house_number: false});
			}
		}
	});

	const [searchFlats] = useLazyQuery(GET_FLATS, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			if (data?.gis_flats) {
				data?.gis_flats.forEach((flat) => {
					flat.flat_number = JSON.parse(flat.flat_number.replace(/'/g, "\""))[locale];
				});
				updateGisState({...gisState, flat: data?.gis_flats });
				setIsLoading({...isLoading, flat: false});
			}
		}
	});

	const fetchData = (type: keyof typeof FttbAddressEnum, query: string, id?: string) => {
		setIsLoading({...isLoading, [type]: true});
		if (FttbAddressEnum.city === type) {
			searchCities({
				variables: {
					language: locale,
					city_name: query,
					country_id: config.GIS_COUNTRY_ID,
					region_id: id,
				}
			});
		} else if (FttbAddressEnum.street === type) {
			searchStreets({
				variables: {
					language: locale,
					street_name: query,
					city_id: id,
				}
			});
		} else if (FttbAddressEnum.house_number === type) {
			searchHouses({
				variables: {
					language: locale,
					house_id: +query,
				}
			});
		} else if (FttbAddressEnum.province === type) {
			searchRegions({
				variables: {
					language: locale,
					country_id: config.GIS_COUNTRY_ID
				}
			});
		} else if (FttbAddressEnum.district === type) {
			searchDistricts({
				variables: {
					language: locale,
					region_id: id,
				}
			});
		} else if (FttbAddressEnum.flat === type) {
			searchFlats({
				variables: {
					flat_number: query,
					house_id: id,
				}
			});
		}
	};
	const updateGisSystem = (type: FttbAddressEnum, value: Maybe<GisHouse>[] | null | undefined) => {
		updateGisState({...gisState, [type]: value});
	};

	return [isLoading, gisState, updateGisSystem, fetchData];
};

export { useGisSystems };
