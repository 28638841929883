import { IntlShape } from "react-intl";
import { groupBy } from "./groupUtils";
import { config, ICC_LENGTH, VALID_MSISDN_PREFIXES } from "../../config/constants";
import { onlyDigitsExp } from "./validationRules";
import { CommonMessages } from "../../Common.messages";
import { mappingLetters } from "./common_mock_data";

const getClasses = (condition: boolean, classesRight, clasessLeft: string): string => condition ? classesRight : clasessLeft;

const handlePhone = (contact_phone: string): string => {
	contact_phone = contact_phone.trim();
	if (contact_phone[contact_phone.length - 1] === ",") {
		return contact_phone.slice(0, -1);
	} else {
		return contact_phone;
	}
};

const groupByMsisdnByCategory = (msisdns: Array<any>) => {
	return groupBy(msisdns.concat().sort((a, b) => (a?.price! > b?.price!) ? 1 : -1), (m) => m.number_category);
};

const compareWithBalance = (balance?: number, ...comparedValue: number[]): boolean => {
	if(!Number.isNaN(balance!) && balance! >= 0) {
		return balance! >= comparedValue.reduce((item, acc) => item + acc, 0);
	} else {
		return false;
	}
};

const addPrefixToMsisdn = (phone: string): string => {
	const regExp = new RegExp(VALID_MSISDN_PREFIXES);
	const msisdn = phone.split(" ").join("");

	if (msisdn.length === 12) {
		return msisdn;
	} else if (msisdn.length === 9) {
		return config.DEFAULT_MSISDN_PREFIX + msisdn;
	} else if (msisdn.length === 10 && regExp.test(msisdn)) {
		return config.DEFAULT_MSISDN_PREFIX + msisdn.substr(1);
	} else {
		return msisdn;
	}
};

const hasRole = (userPermissions: Record<string, boolean>|undefined, roles: string[] = []): boolean => roles.some((role: string) => userPermissions?.[role] || false);

const validateMsisdn = (value: string, intl: IntlShape): [boolean, string] => {
	if(value.length === 0) {
		return [false, ""];
	} else if(!onlyDigitsExp.test(value)) {
		return [false, intl.formatMessage({...CommonMessages.onlyDigits})];
	} else if(value.length < 9) {
		return [false, (intl.formatMessage({...CommonMessages.invalidMsisdn}))];
	} else if(value.length === 10 && value[0] !== "0") {
		return [false, (intl.formatMessage({...CommonMessages.msisdnStartWith}, { value: "0"}))];
	} else if(value.length === 11) {
		return [false, (intl.formatMessage({...CommonMessages.invalidMsisdn}))];
	} else if(value.length === 12 && value.substr(0, 3) !== config.DEFAULT_MSISDN_PREFIX) {
		return [false, (intl.formatMessage({...CommonMessages.msisdnStartWith}, { value: config.DEFAULT_MSISDN_PREFIX}))];
	} else {
		return [true, ""];
	}
};

const onChangeIcc = (fieldName, setFieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
	const strIcc = (e.target.value).split(" ").join("");
	const maxLength = ICC_LENGTH - config.DEFAULT_ICC_PREFIX.length;
	if (!strIcc.startsWith(config.DEFAULT_ICC_PREFIX) && strIcc.length > maxLength) {
		return;
	} 
	if (strIcc.startsWith(config.DEFAULT_ICC_PREFIX) && strIcc.length === ICC_LENGTH) {
		setFieldValue(fieldName, strIcc.split(config.DEFAULT_ICC_PREFIX)?.[1]);
	} else {
		setFieldValue(fieldName, strIcc);
	}
};

const isClient = !!(typeof document !== "undefined" || process.env.BROWSER);

const transformToEngLetter = (value: string) => {
	let updateString = "";
	for (let i = 0; i < value.length; i++) {
		const letterCode = value.charCodeAt(i);
		if (mappingLetters[letterCode]) {
			updateString += mappingLetters[letterCode];
		} else {
			updateString += value[i];
		}
	}
	return updateString;
};

const isRequestFailed = (status) => {
	return !isNaN(status) ? status >=400 && status <=511 : false;
};

const isRequestSuccess = (status) => !isRequestFailed(status);


export {
	hasRole,
	isClient,
	getClasses,
	transformToEngLetter,
	handlePhone,
	validateMsisdn,
	addPrefixToMsisdn,
	compareWithBalance,
	groupByMsisdnByCategory,
	onChangeIcc,
	isRequestFailed,
	isRequestSuccess,
};
