import { useIntl } from "react-intl";
import { useQuery } from "@apollo/react-hooks";
import { GET_BILLING_ACCOUNTS } from "../../../graphql/queries/subscription";
import { LineTypeEnum, Query, QueryMsisdnArgs } from "../../../graphql/types";
import { useStoreContext } from "../../../shared/components/navigation/StepStateProvider";
import { MsisdnErrorsUtils } from "../../../shared/utils/MsisdnErrorsUtils.utils";

const gsmBarrings = [
	"BAR_RETENTION",
	"BAR_POPWITHOUTDOCSOFT",
	"BAR_POPWITHOUTDOCHARD",
	// "BAR_ISD",
	// "BAR_SMS_OUT",
	// "BAR_SMS_IN",
	// "BAR_INET_MMS",
	// "BAR_ROAM_OUT"
];
const BARRING_LEVEL_CODE_FRAUD = "fraud";
const BA_STATUS_ACTIVE = "active";
const BA_NAME = "Commercial";
const useBillingAccountValidation = (msisdn, lineType) => {
	const {dispatch } = useStoreContext();
	const intl = useIntl();
	useQuery<Required<Pick<Query, "otp_subscription">>, QueryMsisdnArgs>(GET_BILLING_ACCOUNTS, {
		variables: {msisdn: msisdn},
		skip: !msisdn,
		onCompleted: (data) => {
			const subscription = data?.otp_subscription?.subscription;

			if (subscription && lineType === LineTypeEnum.Mobile) {
				const firstActiveBarring = subscription?.barrings?.find(bar => bar?.id && gsmBarrings.includes(bar.id));
				const activeBillingAccount = subscription?.billing_accounts?.find(acc => acc?.type?.name === BA_NAME && acc?.status === BA_STATUS_ACTIVE);
				MsisdnErrorsUtils.validate(!activeBillingAccount, "billingAccountNotActive")
					.next(!!subscription?.billing_accounts?.find(acc => acc?.barrings?.find(bar => bar?.barring_level_codes?.map(code => code?.toLowerCase()).includes(BARRING_LEVEL_CODE_FRAUD))), "baFraudBarring")
					.next(!!subscription?.barrings?.find(bar => bar?.barring_level_codes?.map(code => code?.toLowerCase()).includes(BARRING_LEVEL_CODE_FRAUD)), "subFraudBarring")
					.next(!!firstActiveBarring, firstActiveBarring?.name?.[intl.locale] || "barringFromList")
					.dispatch(msisdn!, dispatch);
			}

			if (subscription && lineType === LineTypeEnum.Fttb) {
			}
		}
	});
};
export {
	useBillingAccountValidation
};
