import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FormNavHeader } from "./FormNavHeader";
import { errorActions, useStoreContext } from "./StepStateProvider";
import { useNavigationState } from "./useNavigationState";

export interface FormStepWrapperProps<S> {
	isValid: boolean;
	formId: string;
	newState?: Partial<S>|null;
	stateCallBack?: () => Partial<S>|undefined;
	previousStepStateKey?: string;
}

const FormStepWrapper: <S>(p: React.PropsWithChildren<FormStepWrapperProps<S>>) => React.ReactElement<FormStepWrapperProps<S>>  = <S extends Record<string, unknown>>(props: React.PropsWithChildren<FormStepWrapperProps<S>>) => {
	const { isValid, formId, previousStepStateKey} = props;
	const history = useHistory<S>();
	const {
		pathname,
	} = useLocation<S>();
	const state  = useNavigationState<S>();

	const {dispatch, state: {paths, errors, msisdnErrors}} = useStoreContext();

	useEffect(() => {
		dispatch(errorActions.cleanError());
		dispatch(errorActions.cleanMsisdnErrors());
	}, []);


	const currentIndex = paths?.findIndex((config) => config.pathname === pathname);

	const pathBack = typeof(currentIndex) === "number" && (currentIndex === 0 ? "/" : paths[currentIndex-1]?.pathname);

	const onClickBack = useCallback(() => {
		pathBack && history.push({ pathname: pathBack });
	}, [paths]);

	const isValidForNextStep = !(errors && errors?.length > 0 && msisdnErrors && Object.keys(msisdnErrors).length > 0);
	if (pathBack && previousStepStateKey && !state?.[previousStepStateKey]) {
		history.push(pathBack);
	}
	return (
		<>
			<FormNavHeader formId={formId} back={onClickBack} isValid={isValid && isValidForNextStep}/>
			<div className="ipos-content-box">
				{props.children}
			</div>
		</>
	);
};

export {
	FormStepWrapper,
};