import * as React from "react";
import { config } from "../../config/constants";
import { FormattedMessage } from "react-intl";
import PackagesMessages from "./Packages.messages";
import { getFormattedCurrency } from "../../shared/utils/currencyUtils";
import { Fee, Package } from "../../graphql/types";
import UnitOfTimeMessages from "./UnitOfTime.messages";
import CommonMessages from "../../Common.messages";

type PackagePricesProps = Partial<Pick<Package, "periodic_amount" | "periodic_unit">> & Fee;

const PackagePrices = (props: PackagePricesProps) => {
	const price = getFormattedCurrency(config.DEFAULT_CURRENCY, props.fee, 0);

	if (price && props.fee_type === "single") {
		return <>{price}</>;
	} 
	if (props.fee !== 0) {
		if (price && props.fee_type === "recurrent") {
			const periodic = props.periodic_unit && UnitOfTimeMessages[props.periodic_unit?.toUpperCase()];
			const periodicUnit =  periodic && <FormattedMessage {...periodic}/>;
			return <>{price} <FormattedMessage {...PackagesMessages.recurrentDelimiter} /> {props.periodic_amount} {periodicUnit}</>;
		}
		return null;
	} else {
		return <FormattedMessage {...CommonMessages.withOutPeriodicFee} />;
	}
};

export { PackagePrices };
