import React, { useCallback } from "react";
import { FormikProps } from "../../types";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { FTTBMessages } from "../../../modules/fttb/FTTB.messages";
import { useIntl } from "react-intl";
import { CommonMessages } from "../../../Common.messages";
import { debounce } from "lodash";
interface InputFieldFormikProps {
    id: any;
    name: string;
    isLoading: boolean;
    multiple: boolean;
    className: string;
    labelKey?: any;
    minLength?: number;
    placeholder?: any;
    defaultSelected: any;
    onChange: any;
    onSearch: any;
    onFocus?: any;
    useCallBackRelationValue?: any;
    renderMenuItemChildren?: any;
    error?: string;
    options: any[];
    disabled?: boolean;
    formik: FormikProps;
	allowNew?: boolean;
}

const AsyncTypeaheadFormik = (props: InputFieldFormikProps) => {
	const {
		id,
		name,
		useCallBackRelationValue,
		className,
		isLoading,
		placeholder,
		labelKey,
		onChange,
		options,
		defaultSelected,
		formik: {errors, touched},
		allowNew,

	} = props;
	const intl = useIntl();
	const formikError = touched[name] && !!errors[name];
	const error = formikError ? errors[name] : props.error;
	const onSearchCallBack = useCallback(debounce(props.onSearch, 500), [useCallBackRelationValue]);
	let additionalProps = {};
	if (allowNew) {
		additionalProps["allowNew"] = true;
		additionalProps["newSelectionPrefix"] = intl.formatMessage({...FTTBMessages.newFlatSelectionPrefix})
	}
	return (<>
		<AsyncTypeahead
			id={id}
			isLoading={isLoading}
			className={`${className} ${error ? "is-invalid" : ""}`}
			multiple={props.multiple}
			minLength={props.minLength}
			labelKey={labelKey}
			onChange={onChange}
			onSearch={onSearchCallBack}
			onFocus={props.onFocus}
			defaultSelected={defaultSelected}
			options={options}
			emptyLabel={intl.formatMessage(CommonMessages.noMatchesFound)}
			// isValid={value?.length}
			isInvalid={error}
			disabled={props.disabled}
			placeholder={placeholder}
			renderMenuItemChildren={props.renderMenuItemChildren}
			{...additionalProps}
			// onBlur={handleBlur}
		/>
		{error && (<div className="invalid-feedback">{error}</div>)}
	</>

	);
};

export { AsyncTypeaheadFormik };