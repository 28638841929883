import React from "react";
import { MsisdnTopBarInfo } from "../../../../shared/components";
import { Switch } from "react-router";
import { ErrorBoundaryRoute } from "../../../../shared/error/ErrorBoundaryRoute";
import { FormattedMessage } from "react-intl";
import { AcquisitionMessages } from "../../Acquisition.messages";
import {
	ACQUISITION_EXISTING_FLOW_PLAN_PATH,
	ACQUISITION_EXISTING_FLOW_ICCID_PATH,
	ACQUISITION_EXISTING_FLOW_MSISDN_PATH,
	ACQUISITION_EXISTING_FLOW_OPTIONS_PATH,
	ACQUISITION_EXISTING_FLOW_SUMMARY_PATH,
	ACQUISITION_EXISTING_FLOW_BILLING_PATH,
	ACQUISITION_EXISTING_FLOW_CONTACT_INFO_PATH
} from "./AcquisitionExistingFlowPath";
import { BillingPage, IccId, MsisdnPage, Options, Plan, ContactInfo, Summary } from "../Pages";

const AcquisitionExistingPageRouter = () => {
	return (
		<>
			<MsisdnTopBarInfo>
				<FormattedMessage {...AcquisitionMessages.acquisitionFlow} />
			</MsisdnTopBarInfo>
			<div id="w-app-body">
				<Switch>
					<ErrorBoundaryRoute path={ACQUISITION_EXISTING_FLOW_BILLING_PATH} component={BillingPage}/>
					<ErrorBoundaryRoute path={ACQUISITION_EXISTING_FLOW_MSISDN_PATH} component={MsisdnPage}/>
					<ErrorBoundaryRoute path={ACQUISITION_EXISTING_FLOW_PLAN_PATH} component={Plan}/>
					<ErrorBoundaryRoute path={ACQUISITION_EXISTING_FLOW_OPTIONS_PATH} component={Options}/>
					<ErrorBoundaryRoute path={ACQUISITION_EXISTING_FLOW_ICCID_PATH} component={IccId}/>
					<ErrorBoundaryRoute path={ACQUISITION_EXISTING_FLOW_CONTACT_INFO_PATH} component={ContactInfo}/>
					<ErrorBoundaryRoute path={ACQUISITION_EXISTING_FLOW_SUMMARY_PATH} component={Summary}/>
				</Switch>
			</div>
		</>
	);
};

export { AcquisitionExistingPageRouter };