import {
    DeliveryTypeEnum,
    SalesTypeEnum,
    IdTypeEnum,
    FttbConnectionTypeEnum,
    ProductTypeEnum,
    Mutation, MutationCreate_Fttb_OrderArgs
} from "../../../graphql/types";
import {B2B_BILLING_ACCOUNT_TYPE, config} from "../../../config/constants";
import CommonMessages from "../../../Common.messages";
import { FTTBMessages } from "../FTTB.messages";
import { isRequestSuccess } from "../../../shared/utils/utils";
import { MutationTuple } from "@apollo/react-hooks";
import { REMOVE_SPECIAL_CHARACTER } from "../../../shared/utils";
import { IntlShape } from "react-intl";
import {B2BFttbRouteState} from "./Pages/b2bFttb.type";
const FTTB_FAIL = "FTTB_FAIL";
class B2BFttbService {
    static async handleFttbOrder(
        createOrder: MutationTuple<Required<Pick<Mutation, "create_fttb_order">>, MutationCreate_Fttb_OrderArgs>[0],
        state: B2BFttbRouteState,
        intl: IntlShape,
        setSuccess: React.Dispatch<React.SetStateAction<string>>,
        runSpinner: React.Dispatch<React.SetStateAction<boolean>>,
        setError: React.Dispatch<React.SetStateAction<string>>,
        reseller_code: string|undefined|null,
        uploadDocument: any,
        files: any,
        setDocumentUploadSuccess: React.Dispatch<React.SetStateAction<boolean>>
    ) {
        // 4003 --> default billing account type for b2b new billing account creation
        const billing_account = state?.account_id ? { billing_account: { id: state?.account_id, type: state?.billing_type, delivery_zone: state?.delivery_zone || ""}} : {billing_account: { type: B2B_BILLING_ACCOUNT_TYPE }};
        const confirmationCode = state?.fttbMsisdnConfirmation_code!.replace(REMOVE_SPECIAL_CHARACTER, "");
        const packages = {};
        return createOrder({
            variables: {
                details: {
                    offer: state?.selectedFttbTariffPlan?.offerCode!,
                    confirmation_code: confirmationCode,
                    delivery_type: DeliveryTypeEnum.Direct,
                    sales_info: {
                        sales_type: SalesTypeEnum.B2BFttbAcquisition,
                        reseller_code,
                    },
                    user: {
                        first_name: state.first_name,
                        last_name: state.last_name,
                        middle_name: state.middle_name,
                        nationality: "UA",
                        language: intl.locale,
                        is_company: true,
                        id_number: "",
                        is_anonymous_customer: true,
                        id_type: IdTypeEnum.Passport,
                        title: "male",
                        address: {
                            street: state.address?.gisStreetId,
                            country: config.DEFAULT_COUNTRY!,
                            postal_code: state.address?.gisZipId,
                            city: state.address?.gisCityId,
                            house_number: state.address?.gisHouseId,
                            apartment: state.address?.flat,
                            contact_phone: state?.contact_phone!,
                            province: state?.address?.gisProvinceId,
                        }
                    },
                    customer: {
                        first_name: state.first_name,
                        last_name: state.last_name,
                        middle_name: state.middle_name,
                        nationality: "UA",
                        language: intl.locale,
                        is_company: true,
                        id_number: "",
                        is_anonymous_customer: true,
                        id_type: IdTypeEnum.Passport,
                        title: "male",
                        address: {
                            street: state.address?.gisStreetId,
                            country: config.DEFAULT_COUNTRY!,
                            postal_code: state.address?.gisZipId,
                            city: state.address?.gisCityId,
                            house_number: state.address?.gisHouseId,
                            apartment: state.address?.flat,
                            contact_phone: state?.contact_phone!,
                            province: state?.address?.gisProvinceId,
                        },
                        existing_customer_id: Number(state?.company_id)
                    },
                    products: {
                        subscription: {
                            type: state?.selectedFttbTariffPlan?.code!,
                            msisdn: state?.fttbMsisdn!,
                            product_type: ProductTypeEnum.Subscription,
                            packages: [state?.selectedFttbTariffPlan?.selectedOptionCode!],
                            reservation_id: state?.fttbMsisdnConfirmation_code!,
                            ...(state.housePreviousActivationStatus
                                ? {
                                    fttb_connection_type: state.address
                                        ?.simpleConnection
                                        ? FttbConnectionTypeEnum.Simple
                                        : FttbConnectionTypeEnum.Normal,
                                }
                                : {}),
                        },
                    },
                    by_pass_telemarketing: true,
                    ...billing_account,
                },
            },
        }).then(({ data }) => {
            const status = data?.create_fttb_order?.status;
            if (status && isRequestSuccess(status)) {
                setSuccess(
                    intl.formatMessage(
                        { ...CommonMessages.createOrderSuccess },
                        {
                            order: `${state?.fttbMsisdn} ${intl.formatMessage({
                                ...FTTBMessages.fttbConnection,
                            })}`,
                        }
                    )
                );
                if (files.length > 0) {
                    uploadDocument({
                        purpose: "B2B_FTTB_FLOW",
                        confirmation_code: confirmationCode
                    }, () => {
                        setDocumentUploadSuccess(true);
                        runSpinner(false);
                    }, () => {
                        runSpinner(false);
                    });
                }
                runSpinner(false);
            } else if (data?.create_fttb_order?.error) {
                throw data?.create_fttb_order?.error;
            }
            if (!data?.create_fttb_order) {
                throw intl.formatMessage(
                    { ...CommonMessages.createOrderFail },
                    {
                        msisdn: intl.formatMessage({
                            ...FTTBMessages.fttbConnection,
                        }),
                    }
                );
            }
        })
            .catch((error) => {
                setError(error);
                runSpinner(false);
                return FTTB_FAIL;
            });
    }
}
export { B2BFttbService , FTTB_FAIL};
