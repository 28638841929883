import { useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { Msisdn, Query, QueryMsisdnsArgs } from "../../graphql/types";
import { GET_MSISDNS } from "../../graphql/queries/msisdn";
import { config } from "../../config/constants";
import { msisdnActions, useStoreContext } from "../components/navigation/StepStateProvider";
import { GraphQLUtils } from "../utils/GraphQL.utils";

type GetMsisdnType = () => [Msisdn[], boolean, (args: Record<string, any>) => void];

const useGetMsisdn: GetMsisdnType = () => {
	const {dispatch, state: {
		msisdns
	}} = useStoreContext();


	const [getMsisdns, { loading }] = useLazyQuery<Required<Pick<Query, "msisdns">>, QueryMsisdnsArgs>(GET_MSISDNS, {
		fetchPolicy: "no-cache",
		onCompleted: ({ msisdns }) => {
			if (msisdns && msisdns.length > 0) {
				dispatch(msisdnActions.refresh(GraphQLUtils.convertToArray<Msisdn>(msisdns)));
			} else {
				dispatch(msisdnActions.clean());
			}
		}
	});
	useEffect(() => {
		if (msisdns.length === 0) {
			getMsisdns({variables: { mask: "", category: config.MSISDN_NORMAL_CATEGORY }});
		}
	}, []);
	return [msisdns, loading, getMsisdns];
};


export { useGetMsisdn };