import { useLocation } from "react-router";
import { useStoreContext } from "./StepStateProvider";

const useNavigation = <S>() => {
	const {state: {paths}} = useStoreContext();
	const {
		pathname,
	} = useLocation<S>();
	const currentIndex = paths?.findIndex((config) => config.pathname === pathname);
	const pathBack = typeof(currentIndex) === "number" && (currentIndex === 0 ? "/" : paths[currentIndex-1]?.pathname);
	const pathNext = typeof(currentIndex) === "number" && (currentIndex === -1 ? "/" : paths[currentIndex+1]?.pathname);

	return {pathNext, pathBack}
}

export {
	useNavigation
}