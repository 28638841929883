import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/react-hooks";
import { LocalQuery } from "../../../../graphql/localTypes";
import { GET_ACQUISITION_FLOW_STEPS } from "../../../../graphql/queries/localQuery";
import { CustomerNavigation } from "../../../../shared/components/CustomerNavigation";
import {
	ACQUISITION_FLOW_MSISDN_PATH,
	ACQUISITION_FLOW_PLAN_PATH,
	ACQUISITION_FLOW_OPTIONS_PATH,
	ACQUISITION_FLOW_ICCID_PATH,
	ACQUISITION_FLOW_CONTACT_INFO_PATH,
	ACQUISITION_FLOW_SUMMARY_PATH,
} from "./AcquisitionFlowRoutes";
import { acquisitionFlowRoutesConfig, iposRoutes } from "../../../../routes/RoutesMap";
import AcquisitionMessages from "../../Acquisition.messages";
import { AcquisitionFlowState } from "../Pages/SummaryPage/SummaryPage";

const lengthMainPath = iposRoutes.ACQUISITION_FLOW.createLink().length;

const AcquisitionFlow: FC = () => {
	const intl = useIntl();
	const history = useHistory();
	const {
		pathname,
	} = useLocation<AcquisitionFlowState>();

	const { data } = useQuery<LocalQuery>(GET_ACQUISITION_FLOW_STEPS);
	const msisdnLink = () => {
		if (pathname !== ACQUISITION_FLOW_MSISDN_PATH) {
			history.push(ACQUISITION_FLOW_MSISDN_PATH);
		}
	};
	const planLink = () => {
		if (pathname !== ACQUISITION_FLOW_PLAN_PATH && data?.acquisitionFlow?.msisdn) {
			history.push(ACQUISITION_FLOW_PLAN_PATH);
		}
	};
	const optionsLink = () => {
		if (pathname !== ACQUISITION_FLOW_OPTIONS_PATH &&
            data?.acquisitionFlow?.msisdn &&
            !data?.acquisitionFlow?.plan.isEmpty
		) {
			history.push(ACQUISITION_FLOW_OPTIONS_PATH);
		}
	};

	const iccidLink = () => {
		if (pathname !== ACQUISITION_FLOW_ICCID_PATH &&
            data?.acquisitionFlow?.msisdn &&
            data?.acquisitionFlow.options.length > 0 &&
            !data?.acquisitionFlow?.plan.isEmpty) {
			history.push(ACQUISITION_FLOW_ICCID_PATH);
		}
	};
	const contactInfoLink = () => {
		if (pathname !== ACQUISITION_FLOW_CONTACT_INFO_PATH &&
            data?.acquisitionFlow?.msisdn &&
            data?.acquisitionFlow?.iccId &&
            data?.acquisitionFlow.options.length > 0 &&
            !data?.acquisitionFlow?.plan.isEmpty) {
			history.push(ACQUISITION_FLOW_CONTACT_INFO_PATH);
		}
	};
	const summaryLink = () => {
		if (pathname !== ACQUISITION_FLOW_SUMMARY_PATH &&
            data?.acquisitionFlow?.msisdn &&
            data?.acquisitionFlow?.plan &&
            data?.acquisitionFlow?.iccId &&
            data?.acquisitionFlow.options.length > 0 &&
            !data?.acquisitionFlow?.plan.isEmpty &&
            !data?.acquisitionFlow?.personalInfo.isEmpty) {
			history.push(ACQUISITION_FLOW_SUMMARY_PATH);
		}
	};

	const routesIndexesConfig = {
		[acquisitionFlowRoutesConfig[0]]: {
			message: intl.formatMessage({...AcquisitionMessages.msisdn}),
			link: msisdnLink,
			step: 1,
		},
		[acquisitionFlowRoutesConfig[1]]: {
			message: intl.formatMessage({...AcquisitionMessages.plan}),
			link: planLink,
			step: 2,
		},
		[acquisitionFlowRoutesConfig[2]]: {
			message: intl.formatMessage({...AcquisitionMessages.options}),
			link: optionsLink,
			step: 3,
		},
		[acquisitionFlowRoutesConfig[3]]: {
			message: intl.formatMessage({...AcquisitionMessages.iccid}),
			link: iccidLink,
			step: 4,
		},
		[acquisitionFlowRoutesConfig[4]]: {
			message: intl.formatMessage({...AcquisitionMessages.contactInfo}),
			link: contactInfoLink,
			step: 5,
		},
		[acquisitionFlowRoutesConfig[5]]: {
			message: intl.formatMessage({...AcquisitionMessages.summary}),
			link: summaryLink,
			step: 6,
		},
	};

	return (
		<CustomerNavigation<typeof acquisitionFlowRoutesConfig>
			pathname={pathname}
			routesConfig={acquisitionFlowRoutesConfig}
			routesIndexesConfig={routesIndexesConfig}
			baseRouteLength={lengthMainPath}
		/>
	);
};

export { AcquisitionFlow };