import React from "react";
import { FormikProps } from "../../types";
import { Typeahead } from "react-bootstrap-typeahead";
import { CommonMessages } from "../../../Common.messages";
import { useIntl } from "react-intl";

interface InputFieldFormikProps {
    id: any;
    name: string;
    className: string;
    labelKey?: any;
    placeholder?: any;
    defaultSelected?: any;
    onInputChange?: any;
    onChange: any;
    renderMenuItemChildren?: any;
    error?: string;
    onFocus?: any;
    isLoading?: boolean;
    options: any[];
    disabled?: boolean;
    formik: FormikProps;
}

const TypeaheadFormik = (props: InputFieldFormikProps) => {
	const intl = useIntl();
	const {
		id,
		name,
		className,
		placeholder,
		labelKey,
		onChange,
		options,
		defaultSelected,
		onInputChange,
		formik: {errors, touched},
	} = props;
	const formikError = touched[name] && !!errors[name];
	const error = formikError ? errors[name] : props.error;

	return (<>
		<Typeahead
			id={id}
			className={`${className} ${error ? "is-invalid" : ""}`}
			labelKey={labelKey}
			onChange={onChange}
			defaultSelected={defaultSelected}
			options={options}
			isLoading={props.isLoading}
			onFocus={props.onFocus}
			emptyLabel={intl.formatMessage(CommonMessages.noMatchesFound)}
			// isValid={value?.length}
			onInputChange={onInputChange}
			isInvalid={Boolean(error || props.error)}
			disabled={props.disabled}
			placeholder={placeholder}
			renderMenuItemChildren={props.renderMenuItemChildren}
			// onBlur={handleBlur}
		/>
		{error && (<div className="invalid-feedback">{error}</div>)}
	</>
	);
};

export {  TypeaheadFormik };