import React, { ChangeEvent, SetStateAction, Dispatch, FC } from "react";
import { ConnectionDay } from "./ConnectionDay";
import { FttbActivationEnum } from "../../../../shared/types/FttbEnum";
import { FormattedMessage } from "react-intl";
import { FTTBMessages } from "../../FTTB.messages";
import moment from "moment";
import { config } from "../../../../config/constants";
interface ActivationDateProps {
    currentDate: Date|undefined|null;
    onSelectedDate: Dispatch<SetStateAction<Date|undefined|null>>;
}

const ConnectionOption: FC<ActivationDateProps> = (props) => {
	const {onSelectedDate, currentDate} = props;
	const today = currentDate?.getDate() === new Date().getDate();
	const isFttbFastAvailable = moment(new Date(), config.TIME_FORMAT)
	.isBetween(
		moment(config.FTTBFAST_AVAILABLE_TIME_RANGE.start, config.TIME_FORMAT),
		moment(config.FTTBFAST_AVAILABLE_TIME_RANGE.end, config.TIME_FORMAT))
	const onChange = (value: ChangeEvent<HTMLInputElement>) => {
		if (value.target.name === FttbActivationEnum.tomorrow) {
			onSelectedDate(undefined);
		}
		if (value.target.name === FttbActivationEnum.today) {
			onSelectedDate(moment().add(1, "hour").toDate());
		}
	};

	const onChangeDate = (date: Date) => {
		onSelectedDate(date);
	};
	return (
		<table className="table w-tile-table">
			<tbody>
				<tr className="w-tile-table-row w-tile-table-row-primary">
					<td className="w-100 w-required-field"><FormattedMessage {...FTTBMessages.connectionDay} /></td>
				</tr>
				<tr>
					<td colSpan={2} className="w-details-drawer">
						<table className="table w-tile-table">
							<tbody>
								<tr className="w-tile-table-row">
									<td className="shrink lead"><i className="fa fa-cubes p-2 bg-gray-200"></i></td>
									<td>
										<div className="w-emphasize">
											<FormattedMessage {...FTTBMessages.connectToday}/>
												&nbsp;{moment().format(config.DATE_PICKER_FORMAT)}
											</div>
											{!isFttbFastAvailable && <div className="row">
												<div className="col-8"><FormattedMessage {...FTTBMessages.serviceFastConnectionAvailable}/>
												{` ${config.FTTBFAST_AVAILABLE_TIME_RANGE.start} - ${config.FTTBFAST_AVAILABLE_TIME_RANGE.end}`}</div>
											</div>}
											
									</td>
									<td className="actions">
										<div className="custom-control custom-checkbox">
											<input
												className="custom-control-input"
												id={FttbActivationEnum.today}
												name={FttbActivationEnum.today}
												type="checkbox"
												disabled={!isFttbFastAvailable}
												checked={today}
												onChange={onChange}
											/>
											<label className="custom-control-label" htmlFor={FttbActivationEnum.today}/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<table className="table w-tile-table">

							<tbody>
								<tr className="w-tile-table-row">
									<td className="shrink lead"><i className="fa fa-cubes p-2 bg-gray-200"></i></td>
									<td className="d-flex align-items-center">
										<div className="w-emphasize"><FormattedMessage {...FTTBMessages.selectDay}/>&nbsp;</div>
										<ConnectionDay
											disabled={today}
											currentDate={currentDate}
											onChange={onChangeDate}
										/>
									</td>
									<td className="actions">
										<div className="custom-control custom-checkbox">
											<input
												className="custom-control-input"
												id={FttbActivationEnum.tomorrow}
												name={FttbActivationEnum.tomorrow}
												type="checkbox"
												checked={!today}
												onChange={onChange}
											/>
											<label className="custom-control-label" htmlFor={FttbActivationEnum.tomorrow}/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

export { ConnectionOption };