import { config, GREENFIELD_TAG } from "../../config/constants";
import { Maybe, ProductFamily } from "../../graphql/types";

const filterOutActiveBundleFromAvailableFamily = (available_families, active_packages, isSimpleConnection?, previousActivationStatus?, isGigabitAvailable?, networkType?) => {
	return (active_packages && active_packages.length > 0 ? available_families.map(family => {

		let packages = family?.packages?.filter(value => !active_packages.find((bundle) => bundle!.code === value!.code)
		).filter(pack => !pack?.tags?.includes(config.REMOVE_FLAG_OFFER_PACKAGE));
		if (isSimpleConnection) {
			packages = packages?.filter(
				pack => config.NOT_ALLOWED_FOR_SIMPLE_CONNECTION_PACKAGE_TAGS.filter(
					tag => pack?.tags?.includes(tag)).length === 0);
		}
		if(!isGigabitAvailable) {
			packages = packages?.filter(pack => !pack.tags?.includes(config.GIGABIT_TAG));
		}

		if (networkType === config.FTTB_TAG) {
			packages = packages?.filter(pack => !pack.tags?.includes(config.GPON_TAG) && !pack.tags?.includes(config.GPON_ONU_TAG));
		}

		if (packages && packages?.length > 0) {
			return  {
				...family,
				packages: packages?.filter(pack =>
					!(previousActivationStatus && pack?.tags?.includes(GREENFIELD_TAG))
				)
			};
		}
	}) : available_families)?.filter(item => item !== undefined && item?.packages?.length !== 0);
};
const filterRouter = (available_families: Maybe<Array<Maybe<ProductFamily>>>): Array<Maybe<ProductFamily>> => {
	//TODO filter by name until tags not provided.
	return available_families?.filter(family => family?.name?.en?.toLowerCase().includes("router")) || [];
};

const findPackage = (available_families, code: string) => {
	return available_families?.flatMap(family => {
		return family.packages?.find(value => code === value!.code);
	})?.filter(item => item !== undefined)?.[0];
};

const filterChildPackages = (available_child_offers) => {
	return available_child_offers?.filter(pack =>
		config.NOT_ALLOWED_FOR_SIMPLE_CONNECTION_PACKAGE_TAGS.filter(tag =>pack?.tags?.includes(tag)).length === 0);
};

export {
	filterOutActiveBundleFromAvailableFamily,
	findPackage,
	filterChildPackages,
	filterRouter,
};
