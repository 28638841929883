import React from "react";
import { FormattedMessage } from "react-intl";
import { Storage } from "../../../../../shared/utils/storageUtils";
import { AcquisitionMessages } from "../../../Acquisition.messages";
import { MSISDN } from "../../../../../config/constants";

interface MsisdnSearchResultProps {
    msisdns: any;
    newMsisdn: string;
    setNewMsisdn: (value: string) => void;
}
const MsisdnSearchResult = (props: MsisdnSearchResultProps) => {
	const handleSelectMsisdn = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		props.setNewMsisdn(value);
		Storage.local.set(MSISDN, value);
	};
	return (
		<div className="row">
			<div className="col-12">
				<div className="acquisition-msisdn-msisdns mb-2">
					{props.msisdns.length > 0 && props.msisdns.map(item => (
						<div key={item.msisdn} className="custom-control custom-radio mb-2 mt-2">
							<input
								value={item.msisdn}
								className="custom-control-input"
								name="my-radio"
								type="radio"
								id={`my-radio-${item.msisdn}`}
								checked={props.newMsisdn === item.msisdn}
								onChange={handleSelectMsisdn}
							/>
							<label
								className="custom-control-label"
								htmlFor={`my-radio-${item.msisdn}`}
							>{item.msisdn}</label>
						</div>
					))}
					{props.msisdns.length === 0 && (<p><FormattedMessage {...AcquisitionMessages.noAvailableMsisdns} /></p>)}
				</div>
			</div>
		</div>
	);
};

export { MsisdnSearchResult };