import { ComponentType, useMemo, useState } from "react";
import cssns from "../../shared/utils/cssnsConfig";
import CommonMessages from "../../Common.messages";
import { FormattedMessage } from "react-intl";
import { DropdownLanguage } from "../login/DropdownLanguage";
import { UserProfileDropdown } from "../../shared/components/UserProfileDropdown";
import { Link } from "react-router-dom";
import { iposRoutes } from "../../routes/RoutesMap";
import { BurgerMenu } from "./BurgerMenu";

const { React } = cssns("Search-header");

const withSearch = (Component: ComponentType<any>) => {
	return (props: any) => {
		const [open, setOpen] = useState<boolean>(false);
		const className = open ? "mobile-nav-open" : "";
		const INDEX_PATH = useMemo(() => iposRoutes.INDEX.createLink(), []);
		const openBurgerMenu = () => {
			setOpen(!open);
		};
		return (<>
			<header id="w-app-header" className={className}>
				<div className="d-flex flex-grow-1 align-items-center justify-content-between">
					<Link to={INDEX_PATH}>
						<h1 id="w-app-title">
							<span id="client-logo"><FormattedMessage {...CommonMessages.companyName}/></span>
							<span id="w-app-name"><FormattedMessage {...CommonMessages.productName}/></span>
						</h1>
					</Link>
					<nav id="w-app-actions">
						<div className="w-action-item dropdown d-none d-md-block">
							<DropdownLanguage className="w-action-item-button"/>
						</div>
						<div className="w-action-item dropdown d-none d-md-block">
							<UserProfileDropdown/>
						</div>
						<div className="w-action-item d-md-none">
							<button onClick={openBurgerMenu} className="w-action-item-button">
								<i className="fa fa-fw fa-bars"></i>
							</button>
						</div>
					</nav>
				</div>
				{open && <BurgerMenu setOpen={openBurgerMenu}/>}
			</header>
			<Component {...props} />
		</>);
	};
};

export { withSearch };
