import { PersonalInfo } from "./../../graphql/localTypes";
import {Package, Maybe, Scalars, LimitedSubscription, Customer} from "./../../graphql/types";
import { iposRoutes } from "../../routes/RoutesMap";
import { PlanState } from "../../shared/components/tariffPlan/Plan";
export interface MNPRouteState extends PlanState {
    msisdn: string;
    donorOperator: string;
    is_starterkit: boolean;
    subscription: LimitedSubscription;
    temporaryMsisdn: string;
    options: Package[];
    personalInfo: PersonalInfo;
    dateValue: Maybe<Scalars["Date"]>;
    transferTime: Maybe<Scalars["Datetime"]>;
    passportization?: string;
    timeValue: string;
    isNewCustomer: boolean;
    customer?: CustomerRouteState;
    billing_status?: string;
    account_id?: string;
    billing_type?: string;
    iccId?: string;
    donor_segment: string;
    donor_first_name?: string;
    donor_last_name?: string;
    donor_passport_id_card?: string;
    donor_inn?: string;
    donor_okpo?: string;
    id_document_type?: string;
    msisdn_otp?: string;
    minPossibleDate?: boolean;
}

interface CustomerRouteState extends Customer {
    last_name_person?: string;
    first_name_person?: string;
    middle_name_person?: string;
    attorney_number?: string;
    authorized: boolean;
}
export const INDEX_PATH: string = iposRoutes.INDEX.createLink();
export const MNP_FLOW_PATH: string = iposRoutes.MNP_FLOW.createLink();
export const MNP_FLOW_MSISDN_PATH: string = iposRoutes.MNP_FLOW_MSISDN.createLink();
export const MNP_FLOW_TEMPORARY_MSISDN_PATH: string = iposRoutes.MNP_FLOW_TEMPORARY_MSISDN.createLink();
export const MNP_FLOW_PLAN_PATH: string = iposRoutes.MNP_FLOW_PLAN.createLink();
export const MNP_FLOW_OPTION_PATH: string = iposRoutes.MNP_FLOW_OPTION.createLink();
export const MNP_FLOW_CONTACT_INFO_PATH: string = iposRoutes.MNP_FLOW_CONTACT_INFO.createLink();
export const MNP_FLOW_SUMMARY_PATH: string = iposRoutes.MNP_FLOW_SUMMARY.createLink();