import { useMutation } from "@apollo/react-hooks";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { CommonMessages } from "../../Common.messages";
import { RESERVE_MSISDN } from "../../graphql/mutations/subscription";
import { Mutation, MutationReserve_MsisdnArgs } from "../../graphql/types";
import { ExistingCustomerRouteState } from "../../modules/acquisitionFlow/ExistingCustomer/Pages/Summary/Summary";
import { AcquisitionFlowState } from "../../modules/acquisitionFlow/NewCustomer/Pages/SummaryPage/SummaryPage";
import { msisdnActions, useStoreContext } from "../components/navigation/StepStateProvider";
import { useModal } from "./useModal";

const useMsisdnReservation = (pathname: string, newMsisdn: string) => {
	const [reserveMsisdn] = useMutation<Mutation, MutationReserve_MsisdnArgs>(RESERVE_MSISDN);
	const intl = useIntl();
	const showModal = useModal();
	const history = useHistory();
	const { state } = useLocation<AcquisitionFlowState|ExistingCustomerRouteState>();
	const {dispatch, state: {reservedMsisdns}} = useStoreContext();
	const msisdn = state?.msisdn || "";
	const nextStep = (msisdn, reservationId) => {
		history.push({
			pathname,
			state: {
				...state,
				msisdn,
				reservationId
			}
		});
	};
	return () => {
		if (newMsisdn && msisdn !== newMsisdn) {
			const alreadyReserved = reservedMsisdns.find(item => item.msisdn === newMsisdn);
			if (alreadyReserved?.reservationId) {
				nextStep(alreadyReserved.msisdn, alreadyReserved.reservationId);
			} else {
				reserveMsisdn({
					variables: { msisdn: newMsisdn },
					update: (cache, { data }) => {
						if (data?.reserve_msisdn?.attributes?.reservation_id) {
							const reservation_id = data?.reserve_msisdn?.attributes?.reservation_id;
							dispatch(msisdnActions.reserve({
								msisdn: newMsisdn,
								reservationId: reservation_id
							}));
							nextStep(newMsisdn, reservation_id);
						} else {
							showModal(data?.reserve_msisdn?.error || intl.formatMessage(CommonMessages.serverError), true);
						}
					}
				});
			}
		} else {
			history.push({
				pathname,
				state
			});
		}
	};
};

export {
	useMsisdnReservation
};