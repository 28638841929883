import moment from "moment";
import { IntlShape } from "react-intl";
import * as Yup from "yup";
import { CommonMessages } from "../../../../../../Common.messages";
import {
	emailExp,
	matchInnWithBirthDay,
	namingFIOExp,
	onlyDigitsExp,
	passwordExp
} from "../../../../../../shared/utils";
import { config } from "../../../../../../config/constants";
import { DocumentTypeEnum } from "../../../../../../shared/types/DocumentTypeEnum";

const validateDocType = (self, value, intl) => {
	if (value && self.parent.id_document_type === DocumentTypeEnum.passport) {
		if (value.length !== config.PASSPORT_LENGTH) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.amountSymbols}, { symbols: config.PASSPORT_LENGTH }),
				path: self.path,
			});
		} else if (!passwordExp.test(value)) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.inputFormat}, { format: config.PASSPORT_FORMAT }),
				path: self.path,
			});
		} else {
			return true;
		}
	} else if (value && self.parent.id_document_type === DocumentTypeEnum.national_id) {
		if (value.length !== config.ID_CARD_LENGTH) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.inputOnlyDigits}, { number: config.NATIONAL_ID }),
				path: self.path,
			});
		}
		return onlyDigitsExp.test(value) ? true : self.createError({
			message: intl.formatMessage({...CommonMessages.onlyDigits}),
			path: self.path,
		});
	}
	return true;
};

const schemaNewCustomer = (intl: IntlShape) => {
	const requiredErrorMsg: string = intl.formatMessage({...CommonMessages.required});
	const onlyDigitsErrorMsg: string = intl.formatMessage({...CommonMessages.onlyDigits});
	const capitalLetterErrorMsg: string = intl.formatMessage({...CommonMessages.capitalLetter});
	const inputOnlyDigits = intl.formatMessage({...CommonMessages.inputOnlyDigits}, {number: config.INDIVIDUAL_TAX_NUMBER});
	const followFormatErrorMsg = intl.formatMessage({...CommonMessages.inputFormat}, { format: config.DATE_PICKER_FORMAT });
	const intErrorMsg: string = intl.formatMessage({...CommonMessages.intErrorMgs});
	const birthDayErrorMsg: string = intl.formatMessage({...CommonMessages.birthDayErrorMsg});
	const dateDocIssueErrorMsg: string = intl.formatMessage({...CommonMessages.dateDocIssueErrorMsg});
	const selectFromDropdownList: string = intl.formatMessage({...CommonMessages.selectFromDropdownList});

	return Yup.object().shape({
		gender: Yup.string().required(requiredErrorMsg),
		first_name: Yup.string().matches(namingFIOExp, capitalLetterErrorMsg).required(requiredErrorMsg),
		middle_name: Yup.string().matches(namingFIOExp, capitalLetterErrorMsg),
		last_name: Yup.string().test("last_name", capitalLetterErrorMsg, (value) => {
			return value ? namingFIOExp.test(value) : true;
		}),
		date_of_birth: Yup.string()
			.test("date_of_birth_incorrect", followFormatErrorMsg, function (value) {
				return value === null ? false : true;
			})
			.test("date_of_birth", birthDayErrorMsg, function (value) {
				if (value && this.parent.individual_tax_number && this.parent.individual_tax_number !== config.INDIVIDUAL_TAX_NUMBER_EMPTY) {
					return matchInnWithBirthDay(this.parent.individual_tax_number, value);
				} else {
					return true;
				}
			}).nullable().required(requiredErrorMsg),
		individual_tax_number: Yup.string()
			.matches(onlyDigitsExp, onlyDigitsErrorMsg)
			.min(config.INDIVIDUAL_TAX_NUMBER, inputOnlyDigits)
			.test("int_match_to_email", intErrorMsg, function (value) {
				if (this.parent.date_of_birth && value && value !== config.INDIVIDUAL_TAX_NUMBER_EMPTY) {
					return matchInnWithBirthDay(value, this.parent.date_of_birth);
				} else {
					return true;
				}
			}),
		id_document_number: Yup.string().test({
			name: "id_document_number",
			test: function (value) {
				return validateDocType(this, value, intl);
			}
		}).required(requiredErrorMsg),
		id_doc_issue_date: Yup.string().test("id_doc_issue_date", dateDocIssueErrorMsg, function(value) {
			if (value && (this.parent.id_document_type === DocumentTypeEnum.passport || this.parent.id_document_type === DocumentTypeEnum.national_id)) {
				if (this.parent.date_of_birth) {
					return moment(value).isAfter(moment(this.parent.date_of_birth).add(16, "years"));
				} else {
					return true;
				}
			}
			return true;
		}).nullable(),
		city: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		street: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		province: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		district: Yup.object({
			selected: Yup.array()
		}),
		house_number: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		postal_code: Yup.string().required(requiredErrorMsg),

	});
};

const schemaNewCompany = (intl: IntlShape) => {
	const requiredErrorMsg: string = intl.formatMessage({...CommonMessages.required});
	const emailErrorMsg = intl.formatMessage({...CommonMessages.validEmail});
	const documentLengthMin: string = intl.formatMessage({...CommonMessages.companyIdMinAmount}, {amount: config.ID_DOCUMENT_MIN_LIMIT});
	const onlyDigitsErrorMsg: string = intl.formatMessage({...CommonMessages.onlyDigits});
	const selectFromDropdownList: string = intl.formatMessage({...CommonMessages.selectFromDropdownList});

	return Yup.object().shape({
		name: Yup.string().required(requiredErrorMsg),
		id_document_type: Yup.string().required(requiredErrorMsg),
		id_document_number: Yup.string().matches(onlyDigitsExp, onlyDigitsErrorMsg).min(8, documentLengthMin).max(10).required(requiredErrorMsg),
		email: Yup.string().matches(emailExp, emailErrorMsg).required(requiredErrorMsg),
		city: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		street: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		province: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		district: Yup.object({
			selected: Yup.array()
		}),
		house_number: Yup.object({
			selected: Yup.array().required(selectFromDropdownList)
		}),
		postal_code: Yup.string().required(requiredErrorMsg),
	});
};

export {
	schemaNewCustomer,
	schemaNewCompany,
};