import { CircularProgress } from "./CircularProgress";
import cssns from "../../shared/utils/cssnsConfig";
const { React } = cssns("Spinner-iPos");

const Spinner = () => {
	return (
		<div className="container">
			<div className="box">
				<CircularProgress/>
			</div>
		</div>
	);
};

export { Spinner };
