import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { CommonMessages } from "../../Common.messages";
import { FTTB_FLOW_ADDRESS_PATH } from "./Routes/FttbFlowRoutes";
import {B2B_FTTB_FLOW_ADDRESS_PATH} from "./b2bFttb/Routes/B2BFttbFlowRoutes";

const FTTB = () => {
	const history = useHistory();
	const onClick = () => {
		history.push(FTTB_FLOW_ADDRESS_PATH);
	};
	const onB2BClick = () => history.push(B2B_FTTB_FLOW_ADDRESS_PATH);
	return (
		<>
			<h4><FormattedMessage {...CommonMessages.fttbConnection} /></h4>
			<button className="btn btn-block btn-secondary" onClick={onClick}>
				<FormattedMessage {...CommonMessages.prepaidFttb} />
			</button>
			<button className="btn btn-block btn-secondary" onClick={onB2BClick}>
				<FormattedMessage {...CommonMessages.b2bFttb} />
			</button>
		</>
	);
};

export { FTTB };