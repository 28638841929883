import { IntlShape } from "react-intl";
import * as Yup from "yup";
import { phoneUkrExp } from "../../../../shared/utils";
import { CommonMessages } from "../../../../Common.messages";
import { config } from "../../../../config/constants";

const searchSubscription = (intl: IntlShape) => {
	const requiredErrorMsg: string = intl.formatMessage({...CommonMessages.required});
	const phoneFormatErrorMsg = intl.formatMessage({...CommonMessages.inputFormat}, { format: config.PHONE_FORMAT });
	return Yup.object().shape({
		msisdn: Yup.string().matches(phoneUkrExp, phoneFormatErrorMsg).required(requiredErrorMsg),
	});
};

const otpValidationSchema = (intl: IntlShape) => {
	const requiredErrorMsg: string = intl.formatMessage({...CommonMessages.required});
	return Yup.object().shape({
		otp: Yup.string().required(requiredErrorMsg),
	});
};

export { searchSubscription, otpValidationSchema };