import React, { useMemo } from "react";
import { Field, Form, Formik } from "formik";
import { DatePickerField, InputField, SelectField } from "../../../../../../shared/components";
import moment from "moment";
import { config } from "../../../../../../config/constants";
import { genders, initialLabels } from "../../../../../../shared/utils";
import { RegistrationMessages } from "../../../../../registration/Registration.messages";
import { useIntl } from "react-intl";
import { UserMessages } from "../../../../../user/User.messages";
import { postPayPersonal } from "./PostPayForm.schema";
import { WITHOUT_DOCUMENT_INIT_FORM_VALUE } from "../ContactInfoPage";

interface PostPayPersonalFormType {
    onSubmit: (values: any) => void;
    formId: string;
    initialValues: any;
}



const PostPayPersonalForm = (props: PostPayPersonalFormType) => {
	const initialValues = props.initialValues || WITHOUT_DOCUMENT_INIT_FORM_VALUE;
	const intl = useIntl();
	const labels = useMemo(() => initialLabels<any>(intl, initialValues, RegistrationMessages), [intl.locale]);
	const validationSchema = useMemo(() => postPayPersonal(intl), [intl.locale]);
	const placeholder = useMemo(() => intl.formatMessage({...UserMessages.choose}), [intl.locale]);

	return (
		<>
			<Formik
				onSubmit={props.onSubmit}
				initialValues={initialValues}
				validationSchema={validationSchema}
			>
				<Form id={props.formId} noValidate>
					<div className="row">
						<div className="col-12 col-sm-6">
							<Field
								required
								name="last_name"
								id="last-name"
								label={labels.last_name}
								attr={{maxLength: 256}}
								component={InputField}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<Field
								required
								name="first_name"
								id="first-name"
								label={labels.first_name}
								attr={{maxLength: 256}}
								component={InputField}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<Field
								id="second-name"
								name="middle_name"
								label={labels.middle_name}
								attr={{maxLength: 256}}
								component={InputField}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<Field
								required
								name="gender"
								id="gender"
								label={labels.gender}
								intl={intl}
								placeholder={placeholder}
								values={genders(intl)}
								component={SelectField}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<Field
								required
								max={moment().subtract(1, "day")}
								min={moment(config.MIN_DATE_FORM)}
								name="date_of_birth"
								label={labels.date_of_birth}
								format={config.DATE_PICKER_FORMAT}
								component={DatePickerField}
							/>
						</div>
					</div>
				</Form>
			</Formik>
		</>);
};

export { PostPayPersonalForm };