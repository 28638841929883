import React from "react";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { FTTBMessages } from "../../modules/fttb/FTTB.messages";

const Gigabit: FC = () =>  {
	return <div className="mt-2 alert alert-info">
		<div className="row">
			<div className="d-flex col-12">
				<label className="mr-2">
					<FormattedMessage {...FTTBMessages.gigabit} />
				</label>
			</div>
		</div>
	</div>;
};
export {
	Gigabit
};
