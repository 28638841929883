import { ContactInfoPage} from "./ContactInfo/ContactInfoPage";
import { MsisdnPage } from "./MsisdnPage/MsisdnPage";
import { PlanPage } from "./PlanPage/PlanPage";
import { OptionsPage } from "./OptionsPage/OptionsPage";
import { IccidPage } from "./IccidPage/IccidPage";
import { SummaryPage } from "./SummaryPage/SummaryPage";
import { AcquisitionPageRouter } from "../Routes/AcquisitionPageRouter";
const TARIFF_PLAN_TYPE_PREPAID = "prepaid";
const TARIFF_PLAN_TYPE_POSTPAID = "postpaid";
export {
	TARIFF_PLAN_TYPE_PREPAID,
	TARIFF_PLAN_TYPE_POSTPAID,
	ContactInfoPage,
	MsisdnPage,
	PlanPage,
	OptionsPage,
	IccidPage,
	SummaryPage,
	AcquisitionPageRouter,
};
