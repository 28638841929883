
import gql from "graphql-tag";

const UserDealerIdentity = gql`
    fragment DealerIdentity on UserDealerIdentity {
        contact_number
		reseller_code
		reseller_name
		chain_name
    }
`;

export {
	UserDealerIdentity,
};
