import React, { FC } from "react";
import { useIntl } from "react-intl";
import { PackagePrices } from "../../../modules/packages/PackagePrice";
import { RECURRENT } from "../../../config/constants";
import { SubscriptionType } from "../../../graphql/types";
import { createMarkup } from "../../utils/createMarkup";
interface TariffPlanSummaryProps {
  tariffPlan: SubscriptionType|undefined|null;
}
const CurrentPlanSummary: FC<TariffPlanSummaryProps> = (props: TariffPlanSummaryProps) => {
	const intl = useIntl();
	const bundle = props.tariffPlan?.option_groups?.[0]?.packages?.[0];
	return (
		<div className="ipos-content-box">
			<div className="row align-items-center">
				<div className="col-12 col-sm-1 item">
					<i className="fa fa-cubes p-2 bg-gray-200"></i>
				</div>
				<div className="col-12 col-sm-5 item">
					<div className="w-emphasize">
						{bundle?.name?.[intl.locale] ||
                props.tariffPlan?.name?.[intl.locale]}
					</div>
					<div
						dangerouslySetInnerHTML={createMarkup(
                bundle?.long_description?.[intl.locale] ||
                  bundle?.medium_description?.[intl.locale]
						)}
					/>
				</div>
				<div className="col-12 col-sm-3 text-success item">
					{bundle?.fees && (
						<PackagePrices
							fee={bundle?.fees?.[0]?.fee}
							fee_type={RECURRENT}
							periodic_amount={bundle?.periodic_amount}
							periodic_unit={bundle?.periodic_unit}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export { CurrentPlanSummary };
