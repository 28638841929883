import {Maybe, Offering, OrderTypeEnum, SubscriptionType, Scalars, Subscription, ProductFamily} from "./../../graphql/types";
import { PayloadProps } from "../hooks/useDocumentGeneration";
import { PersonalInfo } from "../../graphql/localTypes";
import { IdTypeEnum, LineTypeEnum } from "../../graphql/types";
import { Customer, OfferingSubscriptionType, OrderCustomerInput, Package } from "../../graphql/types";
import { transformAcquisitionContactFormDates } from "./personalizationUtils";
import moment from "moment";
import { BILLING, config, CUSTOMER, CUSTOMER_INFO, MSISDN, RECURRENT } from "../../config/constants";
import { Storage } from "./storageUtils";

function removeEmptyKeys(customerInfo: any, skip: Array<string> = []): any {
	return Object.keys(customerInfo)
		.filter(key => typeof customerInfo[key] === "boolean" || skip.find((item) => key === item) || customerInfo[key])
		.reduce((obj, key) => {
			return {
				...obj,
				[key]: customerInfo[key]
			};
		}, {});
}

const genCustomerCreateOrder = (customer: Omit<PersonalInfo, "__typename" | "isEmpty" | "building">, skipPersonalData: boolean|null|undefined): OrderCustomerInput => {
	const address = {
		street: customer.street?.selected?.[0]?.id,
		house_number: customer.house_number?.selected?.[0]?.id,
		// building: customer.building,
		apartment: customer.apartment,
		postal_code: customer.house_number?.selected?.[0]?.zip_id,
		city: customer.city?.selected?.[0]?.id,
		country: customer.country || "UA",
		street_type: customer.streetType,
		email: customer.email,
		additional_emails: customer.additional_emails,
		contact_phone: customer.contact_phone,
		province: customer.province?.selected?.[0]?.id,
		district: customer.district?.selected?.[0]?.id,
	};
	const customerInfo = {
		first_name: customer.first_name,
		is_anonymous_customer: Boolean(skipPersonalData),
		middle_name: customer.middle_name,
		last_name: customer.is_company ? customer.name : customer.last_name,
		first_name_alt: customer.first_name_alt,
		id_type: customer.id_document_type as IdTypeEnum,
		id_number: customer.id_document_number,
		password: customer.password,
		individual_tax_number: customer.individual_tax_number,
		...transformAcquisitionContactFormDates(customer),
		id_doc_issued_by: customer.id_doc_issued_by,
		language: customer.language || "uk",
		is_company: false,
		nationality: customer.nationality,
		address: {...removeEmptyKeys(address, ["contact_phone"])},
		without_documents: Boolean(customer.withoutDocument),
		title: customer.gender
	};
	return removeEmptyKeys(customerInfo, ["id_number", "first_name"]);
};

const genExistingCustomerGenerateDocument = (customer: Customer, language: string): PayloadProps => {
	return {
		address: {
			house_number: customer?.address?.[0]?.house_number || "",
			apartment: customer?.address?.[0]?.apartment || "",
			building: customer?.address?.[0]?.building || "",
			province: customer?.address?.[0]?.province["uk"] || "",
			// district: customer.address?.[0]?.district || "",
			postal_code: customer?.address?.[0]?.postal_code || "",
			street: customer?.address?.[0]?.street["uk"] || "",
			city: customer.address?.[0]?.city["uk"] || "",
			contact_phone: customer.contact_phone || "",
			email: customer.email || "",
			language: language || "uk",
		},
		info: {
			gender: customer.gender || "",
			first_name: customer.first_name || "",
			middle_name: customer.middle_name || "",
			last_name: customer.last_name || "",
			date_of_birth: customer.date_of_birth || "",
		},
		idDocuments: {
			id_document_number: customer.id_document_number || "",
			id_document_type: customer.id_document_type || "",
			id_doc_issue_date: customer.id_document_issue_date || "",
			id_doc_valid_till_date: customer.id_expiry || "",
			id_doc_issued_by: customer.id_document_issued_by || "",
			individual_tax_number: customer.tax_id || "",
			password: "",
		},
	};
};

const prepareAddressForExistingCustomer = (address, language, customerInfo) => {
	return {
		street: address?.[0]?.gis_street_id || customerInfo?.gis_street_id || "",
		house_number: address?.[0]?.gis_house_id || customerInfo?.gis_house_id || "",
		building: address?.[0]?.building || "",
		apartment: address?.[0]?.apartment || "",
		postal_code: address?.[0]?.postal_code || "03164",
		city: address?.[0]?.gis_city_id || customerInfo?.gis_city_id || "",
		country: address?.[0]?.country?.[language]  || "UA",
		province: address?.[0]?.gis_region_id || customerInfo?.gis_region_id || "",
		district: address?.[0]?.gis_district_id ||customerInfo?.gis_district_id || "",
	};
};

const prepareExistingCustomerForCreateOrder = (customerInfo, language) => {
	const {
		first_name,
		last_name,
		is_company,
		id_document_type,
		id_document_number,
		id_document_issued_by,
		id_expiry,
		id_document_issue_date,
		contact_phone,
		tax_id,
		middle_name,
		date_of_birth,
		email,
	} = customerInfo;

	const customer = {
		first_name,
		last_name,
		is_company,
		id_type: id_document_type,
		id_number: id_document_number,
		id_doc_issued_by: id_document_issued_by,
		id_doc_valid_till_date: id_expiry ? moment(id_expiry).format(config.DATE_PICKER_FORMAT) : null,
		id_doc_issue_date: id_document_issue_date ? moment(id_document_issue_date).format(config.DATE_PICKER_FORMAT) : null,
		individual_tax_number: tax_id,
		language,
		middle_name,
		birthday: moment(date_of_birth).format(config.DATE_PICKER_FORMAT),
		address: {
			email,
			contact_phone,
			...prepareAddressForExistingCustomer(customerInfo.address, language, customerInfo),
		}
	};
	return Object.keys(customer).reduce((object, prop) => {
		if(customer[prop] !== null) {
			object[prop] = customer[prop];
			return object;
		}
		return object;
	}, {} as any);
};

const genCustomerGenerateDocument = (customer: Omit<PersonalInfo, "__typename" | "isEmpty">): PayloadProps => {
	return {
		address: {
			house_number: customer.house_number?.selected?.[0]?.house_number!,
			apartment: customer.apartment!,
			building: customer.building!,
			contact_phone: customer.contact_phone!,
			email: customer.email!,
			province: customer.province?.selected?.[0]?.id!,
			postal_code: customer.house_number?.selected?.[0]?.zip_code!,
			street: customer.street?.selected?.[0]?.id!,
			city: customer.city?.selected?.[0]?.id!,
			language: customer.language!,
			// district: customer.district,
		},
		info: {
			gender: customer.gender!,
			first_name: customer.first_name!,
			middle_name: customer.middle_name!,
			last_name: customer.last_name! || customer.name!,
			date_of_birth: customer.date_of_birth!,
		},
		idDocuments: {
			id_document_number: customer.id_document_number!,
			id_document_type: customer.id_document_type!,
			id_doc_issue_date: customer.id_doc_issue_date!,
			id_doc_valid_till_date: customer.id_doc_valid_till_date!,
			id_doc_issued_by: customer.id_doc_issued_by!,
			password: customer.password!,
			individual_tax_number: customer.individual_tax_number!,
		},
	};
};

const getDaysFromINN = (inn: string): number => new Number(inn.slice(0, 5)).valueOf() - 1;

const clearLocalStorageFromExistingCustomer = async (): Promise<any> => {
	Storage.local.remove(MSISDN);
	Storage.local.remove(BILLING);
	Storage.local.remove(CUSTOMER);
	Storage.local.remove(CUSTOMER_INFO);
};

const clearLocalStorageFromNewCustomer = async (): Promise<any> => {
	Storage.local.remove(MSISDN);
	// Storage.local.remove(MSISDNS);
	Storage.local.remove(CUSTOMER);
};

const customerToLocalStorage = async (customer: Customer, searchParams: { id_number: string; id_type: string }): Promise<any> => {
	Storage.local.set(CUSTOMER, {
		id_number: searchParams.id_number,
		id_type: searchParams.id_type,
	});
	Storage.local.set(CUSTOMER_INFO, customer);
};

const matchInnWithBirthDay = (inn: string|null|undefined, birthDate: string|null|undefined): boolean => {
	if (!inn || !birthDate) return false;
	const daysFromINN = getDaysFromINN(inn);
	const birthDay = moment(birthDate);
	const innDay = moment(config.minYear, config.DATE_PICKER_FORMAT).add(daysFromINN, "days");
	return birthDay.date() === innDay.date() && birthDay.month() === innDay.month() && birthDay.year() === innDay.year();
};

const getAddressFromExistingCustomer = (address, locale) => {
	if(address?.length > 0) {
		const { street, building, apartment, postal_district, city, country, province, district, comments, gis_house_id, gis_city_id, gis_street_id,gis_region_id,gis_district_id } = address[0];
		return {
			street: street[locale], comments, house_number: building, apartment, postal_code: postal_district, city: city?.[locale], country, province: province?.[locale], district,
			gis_house_id, gis_city_id, gis_street_id,gis_region_id,gis_district_id
		};
	} else {
		return {
			street: "",
			house_number: "",
			building: "",
			apartment: "",
			postal_code: "",
			locality: "",
			country: "",
			region: "",
			district: "",
			gis_house_id: "",
			gis_city_id: "",
			gis_street_id: "",
			gis_region_id: "",
			gis_district_id: ""
		};
	}
};

const creditDecisionOrderer = (customer: Customer, lang: string) => {
	return {
		customer_id: customer.id,
		first_name: customer.first_name || "",
		last_name: customer.last_name || "",
		nationality: "",
		date_of_birth: customer.date_of_birth || "",
		id_document_type: customer.id_document_type as any,
		id_document_number: customer.id_document_number || "",
		legal_address_street_name: customer.address?.[0]?.street[lang] || "",
		legal_address_zip: customer.address?.[0]?.postal_code || "",
		legal_address_city: customer.address?.[0]?.city[lang] || "",
		legal_address_country: customer.address?.[0]?.country || "Ukraine",
		legal_address_start_date: "2020-10-10",
		billing_address_name: "",
		billing_address_street_name: "",
		billing_address_zip: "",
		billing_address_city: "",
		billing_address_country: "UK",
		customer_category: "CONSUMER" as any,
	};
};

const creditDecisionOrder = () => {
	return {
		brand: "KY",
		order_type: OrderTypeEnum.Retention as any,
		created_at: new Date().toISOString(),
		includes_mnp: false,
		delivery_address_name: "",
		delivery_address_street_name: "",
		delivery_address_zip: "",
		delivery_address_city: "",
		delivery_address_country: "",
	};
};

export interface OfferSubscriptionProps extends FlatOfferingSubscriptionType {
    offerId?: string|null;
    offerCode?: string|null;
    offerSimCardCode?: string|null;
}

export type FlatOfferingSubscriptionType = {
	__typename?: "OfferingSubscriptionType";
	id?: Maybe<Scalars["ID"]>;
	subscription_types?: Maybe<SubscriptionType>;
	line_type?: Maybe<LineTypeEnum>;
  };

const prepareOfferings = (offerings: Maybe<Array<Maybe<Offering>>>, line_type: string): (OfferSubscriptionProps | undefined)[] | undefined => {
	return offerings?.flatMap(offer => (
		offer?.offering_subscription_types?.flatMap((offering_subscription_type) => (
			offering_subscription_type?.subscription_types?.map(item => ({
				subscription_types: {
					...item
				},
				offerId: offer?.id,
				offerCode: offer?.code,
				offerSimCardCode: offer?.offering_sim_cards?.[0]?.catalog_sim_card?.code,
			}))
		)).filter((item) => (line_type ? item?.subscription_types.line_type === line_type : true ))));

};

const prepareFttbOfferings = (offeringSubsciptionType: Maybe<Array<Maybe<OfferingSubscriptionType>>>, greenFieldTags: Maybe<Array<Maybe<Scalars["String"]>>>|undefined, greenFieldTpStatus: Maybe<Scalars["Int"]>|undefined): (OfferSubscriptionProps | undefined)[] | undefined => {
	if (greenFieldTpStatus === 1) {
		return offeringSubsciptionType?.filter(
			offer => offer?.line_type === LineTypeEnum.Fttb && 
			offer.subscription_types?.every(sub_type => !greenFieldTags?.some(tag => sub_type?.tags?.includes(tag)))
		).flatMap((offering_subscription_type) => (
			offering_subscription_type?.subscription_types?.map((item) => ({
				subscription_types: {
					...item
				},
			}))
		));
	}
	else if (greenFieldTpStatus === 2) {
		return offeringSubsciptionType?.filter(
			offer => offer?.line_type === LineTypeEnum.Fttb && 
			offer.subscription_types?.every(
				sub_type => sub_type?.tags?.every(tag => greenFieldTags?.includes(tag) || !tag?.startsWith("Greenfield_"))
			)
		).flatMap((offering_subscription_type) => (
			offering_subscription_type?.subscription_types?.map((item) => ({
				subscription_types: {
					...item
				},
			}))
		));
	} else {
		return offeringSubsciptionType?.filter(offer => offer?.line_type === LineTypeEnum.Fttb).flatMap((offering_subscription_type) => (
			offering_subscription_type?.subscription_types?.map((item) => ({
				subscription_types: {
					...item
				},
			}))
	))
	}
};

const prepareFmcOfferings = (
		OfferingSubscriptionType: Maybe<Array<Maybe<OfferingSubscriptionType>>>,
		availableSubscriptions: Maybe<Array<Maybe<SubscriptionType>>>,
		greenFieldTags?: Maybe<Array<Maybe<Scalars["String"]>>>|undefined,
		greenFieldTpStatus?: Maybe<Scalars["Int"]>|undefined,
		isNoFttbSubscription?: Maybe<Scalars["Boolean"]>|undefined,
	): (OfferSubscriptionProps | undefined)[] | undefined => {

	const available_subscription_types_ids = new Set(availableSubscriptions?.flat()?.map(subscription => subscription?.id));

	if (isNoFttbSubscription) {
		if (greenFieldTpStatus === 1) {
			return OfferingSubscriptionType?.filter(
				(offer) => offer?.line_type === LineTypeEnum.Fmc &&
				offer.subscription_types?.every(sub_type => !greenFieldTags?.some(tag => sub_type?.tags?.includes(tag)))
			)?.flatMap((offering_subscription_type) => (
				offering_subscription_type?.subscription_types?.filter(item => item?.line_type === LineTypeEnum.Fmc)?.map((item) => ({
					subscription_types: {
						...item
					},
				} )))
			).filter(item =>available_subscription_types_ids.has(item?.subscription_types.id));
		}
		else if (greenFieldTpStatus === 2) {
			return OfferingSubscriptionType?.filter(
				(offer) => offer?.line_type === LineTypeEnum.Fmc &&
				offer.subscription_types?.every(
					sub_type => sub_type?.tags?.every(tag => greenFieldTags?.includes(tag) || !tag?.startsWith("Greenfield_"))
				)
			)?.flatMap((offering_subscription_type) => (
				offering_subscription_type?.subscription_types?.filter(item => item?.line_type === LineTypeEnum.Fmc)?.map((item) => ({
					subscription_types: {
						...item
					},
				} )))
			).filter(item =>available_subscription_types_ids.has(item?.subscription_types.id));
		} else {
			return OfferingSubscriptionType?.filter(
				(offer) => offer?.line_type === LineTypeEnum.Fmc
			)?.flatMap((offering_subscription_type) => (
				offering_subscription_type?.subscription_types?.filter(item => item?.line_type === LineTypeEnum.Fmc)?.map((item) => ({
					subscription_types: {
						...item
					},
				} )))).filter(item =>available_subscription_types_ids.has(item?.subscription_types.id));
		}
	}
	return OfferingSubscriptionType?.filter((offer) => offer?.line_type === LineTypeEnum.Fmc)?.flatMap((offering_subscription_type) => (
		offering_subscription_type?.subscription_types?.filter(item => item?.line_type === LineTypeEnum.Fmc)?.map((item) => ({
        		subscription_types: {
					...item
				},
			} )))).filter(item =>available_subscription_types_ids.has(item?.subscription_types.id));
};

const extractFee = (pckg: Package|null|undefined): number => {
	let fee = 0
	if (pckg?.fees){
		fee =  pckg?.fees?.find((fee) => fee?.fee_type === RECURRENT)?.fee || 0;
	}
	else if (pckg?.fee && pckg?.fee_type === RECURRENT)	{
		fee =  Number(pckg.fee)
	}
	return fee	
};

const prepareUncheckClick = (pack, value, childOffersData) => {
	if (pack.code !== value.optionalProduct.code) {
		if ((JSON.stringify(childOffersData?.child_offers))?.includes(JSON.stringify(pack))) {
			return false;
		} else return true;
	}  else return false;
};

const prepareOptionalProductsForRadio = (pack, value) => {
	if (value.familyName === config.SUPERPOWER_FAMILY) {
		return !(pack.tags?.includes(config.SUPERPOWER_TAG) && value.optionalProduct.tags?.includes(config.SUPERPOWER_TAG));
	} else if (value.familyName === config.HOME_TV_FAMILY) {
		return !(pack.tags?.includes(config.PACK_TV_TAG)
			&& !pack.tags?.includes(config.SUPERPOWER_TAG)
			&& value.optionalProduct.tags?.includes(config.PACK_TV_TAG));
	} else return true;
};

const checkIfPaidGigabitPack = (pack: Package): boolean => {
	return (pack?.tags?.includes(config.FTTB_PAID_GIGABIT_TAG) && pack?.tags?.includes(config.GIGABIT_TAG) ) || false;
};

const checkIfSBGigabitPack = (pack: Package): boolean => {
	return (pack?.tags?.includes(config.GIGABIT_TAG) && pack?.tags?.includes(config.FREE_GIGABIT_TAG) ) || false;
};

const isGPONFTTBPack = (pack: Package): boolean => {
	return pack?.tags?.includes(config.GPON_TAG) || false;
};

function hasGPONFTTBTag(data: Maybe<ProductFamily>[]): boolean {
    return data.some(pkg => 
        pkg?.packages?.some(subPkg => 
            subPkg?.tags?.includes("GPON")
        )
    );
}

export {
	extractFee,
	getDaysFromINN,
	prepareOfferings,
	matchInnWithBirthDay,
	genCustomerCreateOrder,
	genCustomerGenerateDocument,
	getAddressFromExistingCustomer,
	clearLocalStorageFromNewCustomer,
	genExistingCustomerGenerateDocument,
	prepareAddressForExistingCustomer,
	creditDecisionOrderer,
	creditDecisionOrder,
	customerToLocalStorage,
	clearLocalStorageFromExistingCustomer,
	prepareExistingCustomerForCreateOrder,
	prepareFttbOfferings,
	prepareFmcOfferings,
	prepareUncheckClick,
	prepareOptionalProductsForRadio,
	checkIfPaidGigabitPack,
	checkIfSBGigabitPack,
	isGPONFTTBPack,
	hasGPONFTTBTag
};
