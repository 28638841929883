import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-boost";
import { cache } from "./cache";
import gql from "graphql-tag";
import { setContext } from "apollo-link-context";
import { linkErorr } from "./erorrHandler";
import { resolvers } from "./resolvers/resolvers";
import { createUploadLink } from "apollo-upload-client";
import Cookies from "js-cookie";
import { CSRFTOKEN } from "../config/constants";

const authLink = setContext((_, { headers }) => {
	const csrf = Cookies.get(CSRFTOKEN);
	return {
		headers: {
			...headers,
			...(csrf && {"X-XSRF-TOKEN": csrf})
		},
		credentials: process.env.NODE_ENV === "development" ? "include" : "same-origin"
	};
});

const link = ApolloLink.from([
	linkErorr,
	authLink.concat(createUploadLink({
		uri: process.env.REACT_APP_API_URL,
	}))
]);

const typeDefs = gql`
    extend type Query {
        modal: ModalStatus!
        spinner: SpinnerStatus!
        context: ApplicationContext
        confirmationAction: ConfirmationAction
    }

    type ApplicationContext {
      msisdn: String!
      subscriptionId: String!
    }
    
    type ModalStatus {
        status: String!
        message: String
    }
    
    type ConfirmationAction {
        id: ID!,
        msisdn: String
        subscriptionId: String
        action: String
        status: Boolean
        code: String
        remove_package: ActionDetails
    }
    
    type ActionDetails {
        id: ID
        codes: [ActionCodeStatus] 
    }
    
    type ActionCodeStatus {
        id: ID
        code: String
        status: Boolean
    }
    
    type SpinnerStatus {
        status: Boolean
    }

`;

const client = new ApolloClient({
	cache,
	link,
	typeDefs,
	resolvers,
});


export { client };
