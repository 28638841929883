/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface RegistrationMessagesType {
	additionalUsers: MessageDescriptor;
	additional_emails: MessageDescriptor;
	address: MessageDescriptor;
	alt_first_name: MessageDescriptor;
	apartment: MessageDescriptor;
	authorizePerson: MessageDescriptor;
	building: MessageDescriptor;
	city: MessageDescriptor;
	comments: MessageDescriptor;
	communication_lang: MessageDescriptor;
	company_id: MessageDescriptor;
	company_name: MessageDescriptor;
	contact_phone: MessageDescriptor;
	country: MessageDescriptor;
	date_of_birth: MessageDescriptor;
	district: MessageDescriptor;
	edrpou: MessageDescriptor;
	email: MessageDescriptor;
	first_name: MessageDescriptor;
	first_name_alt: MessageDescriptor;
	flat: MessageDescriptor;
	gender: MessageDescriptor;
	homeAddress: MessageDescriptor;
	house_number: MessageDescriptor;
	idDocuments: MessageDescriptor;
	id_doc_issue_date: MessageDescriptor;
	id_doc_issue_date_additional: MessageDescriptor;
	id_doc_issued_by: MessageDescriptor;
	id_doc_valid_till_date: MessageDescriptor;
	id_document_number: MessageDescriptor;
	id_document_number_additional: MessageDescriptor;
	id_document_type: MessageDescriptor;
	id_document_type_additional: MessageDescriptor;
	individual_tax_number: MessageDescriptor;
	infoMessageExistingCustomer: MessageDescriptor;
	infoMessageNewCustomer: MessageDescriptor;
	is_company: MessageDescriptor;
	language: MessageDescriptor;
	last_name: MessageDescriptor;
	locality: MessageDescriptor;
	middle_name: MessageDescriptor;
	name: MessageDescriptor;
	nationality: MessageDescriptor;
	originalPassport: MessageDescriptor;
	password: MessageDescriptor;
	password_identity: MessageDescriptor;
	personalInfo: MessageDescriptor;
	postal_code: MessageDescriptor;
	province: MessageDescriptor;
	region: MessageDescriptor;
	startExisitingCustomerFlow: MessageDescriptor;
	street: MessageDescriptor;
	streetType: MessageDescriptor;
	withOut: MessageDescriptor;
	withOutEmail: MessageDescriptor;
}
const RegistrationMessages: RegistrationMessagesType = defineMessages({
	additionalUsers: {
		id: "registration-flow-additional-users",
		description: "Registration label street type",
		defaultMessage: "Additional users"
	},
	additional_emails: {
		id: "registration-field-email-second",
		description: "Registration label email second",
		defaultMessage: "Additional contact mails"
	},
	address: {
		id: "registration-flow-address",
		description: "Registration label street type",
		defaultMessage: "Address"
	},
	alt_first_name: {
		id: "registration-flow-alt-first-name",
		description: "Registration label alternative first name",
		defaultMessage: "Alternative first name"
	},
	apartment: {
		id: "registration-field-apartments",
		description: "Registration label apartments",
		defaultMessage: "Apartments"
	},
	authorizePerson: {
		id: "registration-field-authorize-persone",
		description: "Registration label authorize persone",
		defaultMessage: "Authorized Person"
	},
	building: {
		id: "registration-field-building",
		description: "Registration label building",
		defaultMessage: "Building"
	},
	city: {
		id: "registration-field-city",
		description: "Registration label city",
		defaultMessage: "City/Village"
	},
	comments: {
		id: "registration-field-comment",
		description: "Registration label comment",
		defaultMessage: "Comment"
	},
	communication_lang: {
		id: "registration-flow-communication language",
		description: "Registration label street type",
		defaultMessage: "Additional language"
	},
	company_id: {
		id: "registration-field-company-id",
		description: "Registration label company id",
		defaultMessage: "Company ID"
	},
	company_name: {
		id: "registration-field-company-name",
		description: "Registration label company name",
		defaultMessage: "Company Name"
	},
	contact_phone: {
		id: "registration-field-contact-phone",
		description: "Registration label contact phone",
		defaultMessage: "Contact Phone"
	},
	country: {
		id: "registration-field-country",
		description: "Registration label country",
		defaultMessage: "Country"
	},
	date_of_birth: {
		id: "registration-field-date-of-birth",
		description: "Registration label date of birth",
		defaultMessage: "Date of Birth"
	},
	district: {
		id: "registration-field-district",
		description: "Registration label district",
		defaultMessage: "District"
	},
	edrpou: {
		id: "registration-field-id-type-edrpou",
		description: "Registration label id type EDRPOU",
		defaultMessage: "EDRPOU"
	},
	email: {
		id: "registration-field-email",
		description: "Registration label email",
		defaultMessage: "Email"
	},
	first_name: {
		id: "registration-field-first-name",
		description: "Registration field label first name",
		defaultMessage: "First Name"
	},
	first_name_alt: {
		id: "registration-field-greeting-name",
		description: "Registration label greeting name",
		defaultMessage: "Greeting Name"
	},
	flat: {
		id: "registration-field-flat",
		description: "Registration label flat",
		defaultMessage: "Flat"
	},
	gender: {
		id: "registration-field-genders",
		description: "Registration label second name",
		defaultMessage: "Gender"
	},
	homeAddress: {
		id: "registration-title-block-home-address",
		description: "Registration label for block Home address",
		defaultMessage: "Home Address"
	},
	house_number: {
		id: "registration-field-house",
		description: "Registration label house",
		defaultMessage: "House"
	},
	idDocuments: {
		id: "registration-flow-id-documents",
		description: "Registration label street type",
		defaultMessage: "ID documents"
	},
	id_doc_issue_date: {
		id: "registration-field-date-of-issue",
		description: "Registration label date of issue",
		defaultMessage: "Date of issue"
	},
	id_doc_issue_date_additional: {
		id: "registration-field-date-of-issue-additional",
		description: "Registration label date of issue",
		defaultMessage: "Additional Document Date"
	},
	id_doc_issued_by: {
		id: "registration-field-issued-by",
		description: "Registration label issued by",
		defaultMessage: "Issued by"
	},
	id_doc_valid_till_date: {
		id: "registration-field-valid-till",
		description: "Registration label valid till",
		defaultMessage: "Valid till"
	},
	id_document_number: {
		id: "registration-field-document-number",
		description: "Registration label document-number",
		defaultMessage: "Document Number"
	},
	id_document_number_additional: {
		id: "registration-field-document-number-additional",
		description: "Registration label document-number",
		defaultMessage: "Additional Document Number"
	},
	id_document_type: {
		id: "registration-title-block-document-type",
		description: "Registration label for block Document Type",
		defaultMessage: "Document type"
	},
	id_document_type_additional: {
		id: "registration-title-block-document-type-additional",
		description: "Registration label for block Document Type",
		defaultMessage: "Additional Document Type"
	},
	individual_tax_number: {
		id: "registration-field-itn",
		description: "Registration label itn",
		defaultMessage: "ITN"
	},
	infoMessageExistingCustomer: {
		id: "registration-existing-customer-info-msg",
		description: "Info message about uploaded documents for existing customer",
		defaultMessage: "Upload signed contract"
	},
	infoMessageNewCustomer: {
		id: "registration-new-customer-info-msg",
		description: "Info message about uploaded documents for new customer",
		defaultMessage: "Upload signed contract and copy of customer's ID document"
	},
	is_company: {
		id: "registration-field-company-type",
		description: "Registration label Registration type",
		defaultMessage: "Registration type"
	},
	language: {
		id: "registration-field-language",
		description: "Registration label language",
		defaultMessage: "Preferred Language"
	},
	last_name: {
		id: "registration-field-last-name",
		description: "Registration label last name",
		defaultMessage: "Last Name"
	},
	locality: {
		id: "registration-field-locality",
		description: "Registration label locality",
		defaultMessage: "Locality"
	},
	middle_name: {
		id: "registration-field-second-name",
		description: "Registration label second name",
		defaultMessage: "Second Name"
	},
	name: {
		id: "registration-field-name",
		description: "Registration label name in Comapny",
		defaultMessage: "Name"
	},
	nationality: {
		id: "registration-field-nationality",
		description: "nationality label",
		defaultMessage: "Nationality"
	},
	originalPassport: {
		id: "registration-field-original-passport",
		description: "Used Original Passport ",
		defaultMessage: "Original Passport"
	},
	password: {
		id: "registration-field-password",
		description: "Registration label password",
		defaultMessage: "Security Passphrase"
	},
	password_identity: {
		id: "registration-flow-indenty-password",
		description: "Registration label street type",
		defaultMessage: "Identification Password"
	},
	personalInfo: {
		id: "registration-flow-personal-info",
		description: "Registration label street type",
		defaultMessage: "Personal info"
	},
	postal_code: {
		id: "registration-field-post-code",
		description: "Registration label post code",
		defaultMessage: "Post Code"
	},
	province: {
		id: "registration-field-province",
		description: "Registration label province",
		defaultMessage: "Province"
	},
	region: {
		id: "registration-field-region",
		description: "Registration label region",
		defaultMessage: "Region"
	},
	startExisitingCustomerFlow: {
		id: "registration-start-sales-flow-for-existing-customer-validation-error",
		description: "New acqusiont flow postpaid start existing customer flow",
		defaultMessage: "Start sales flow for existing customer"
	},
	street: {
		id: "registration-field-street-name",
		description: "Registration label street",
		defaultMessage: "Street"
	},
	streetType: {
		id: "registration-field-street-type",
		description: "Registration label street type",
		defaultMessage: "Street Type"
	},
	withOut: {
		id: "registration-field-without",
		description: "Registration label withoutEmail",
		defaultMessage: "Without {value}"
	},
	withOutEmail: {
		id: "registration-field-without-email",
		description: "Registration label withoutEmail",
		defaultMessage: "Without Email"
	},
});

export default RegistrationMessages;
export { RegistrationMessages };
