import React, { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SelectedTariffPlanDetails } from "../../../../graphql/localTypes";
import { Subscription } from "../../../../graphql/types";
import { errorActions, useStoreContext } from "../../../../shared/components/navigation/StepStateProvider";
import { StepWrapper } from "../../../../shared/components/navigation/StepWrapper";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";
import { FmcContactPhoneInfo } from "../../../../shared/components/phone/FmcContactPhoneInfo";
import { SearchMsisdn } from "../../../../shared/components/SearchMsisdn";
import { CurrentPlanSummary } from "../../../../shared/components/tariffPlan/CurrentPlanSummary";
import ErrorMessages from "../../../../shared/error/Error.messages";
import { TariffPlanUtils } from "../../../../shared/utils/TariffPlan.utils";
import { FMCMessages } from "../../FMC.messages";
import { NoFttbCheckbox } from "../../NoFttbCheckbox";
import { FmcPostpaidRouteState } from "../fmc.postpaid.types";
import { FttbDetails } from "../FttbDetails";
import { useBillingAccountValidation } from "../useBillingAccountValidation";
import { useSearchMsisdn } from "./useSearchMsisdn";

const Search: FC = () => {
	const state = useNavigationState<FmcPostpaidRouteState>();
	const [gsmSubscription, setGsmSubscription] = useState<Subscription|undefined>(state?.search?.gsmSubscription);
	const [fttbSubscription, setFttbSubscription] = useState<Subscription|undefined>(state?.search?.fttbSubscription);
	const {dispatch, state: {fmc}} = useStoreContext();
	const intl = useIntl();
	useEffect(() => {
		if (fmc?.unpairFmc) {
			setFttbSubscription(undefined);
		}
	}, [fmc?.unpairFmc]);
	const handleResetSearch = () => {
		setGsmSubscription(undefined);
		setFttbSubscription(undefined);
	};

	useBillingAccountValidation(gsmSubscription?.msisdn, gsmSubscription?.subscription_type?.line_type);
	const searchMsisdn = useSearchMsisdn(gsmSubscription, setGsmSubscription, fttbSubscription, setFttbSubscription);

	const isValid = Boolean((gsmSubscription && fttbSubscription) || (fmc?.withoutFttb && gsmSubscription) || (fmc?.unpairFmc && gsmSubscription));
	const [selectedFmcTariffPlan, setSelectedFmcTariffPlan ] = useState<SelectedTariffPlanDetails|undefined>();
	const isPopMerge = fmc.isPopMerge;
	const popAddonExist = fmc.popAddonExist;
	useEffect(() => { 
		if ((popAddonExist || isPopMerge) && gsmSubscription) {
			try {
				setSelectedFmcTariffPlan(TariffPlanUtils.convertToSelectedTariffPlan(intl, gsmSubscription?.subscription_type?.option_groups?.[0]?.packages?.[0], gsmSubscription?.subscription_type));
			} catch (e) {
				dispatch(errorActions.setError(intl.formatMessage(ErrorMessages.fmcNotPossible, {code: "30"})));
			}
		}
	}, [fmc.isPopMerge, fmc.popAddonExist, gsmSubscription]);

	
	const plan = (isPopMerge || popAddonExist ) && selectedFmcTariffPlan  ? {selectedFmcTariffPlan} : undefined;

	return (
		<StepWrapper<FmcPostpaidRouteState>
			isValid={isValid}
			newState={{
				plan,
				search: {
					fttbSubscription,
					gsmSubscription,
					withoutFttb: Boolean(fmc?.withoutFttb),
					unpairFmc: Boolean(fmc?.unpairFmc),
					popAddonExist: Boolean(fmc?.popAddonExist),
					allowFmcMerge: Boolean(fmc?.allowFmcMerge),
				}
			}}>
			<div className="form-row bg-light p-4">
				<NoFttbCheckbox
					cleanFttb={() => setFttbSubscription(undefined)}/>
				{/* <UnpairFmcCheckbox
					lineType={gsmSubscription?.subscription_type?.line_type}/> */}
			</div>
			<SearchMsisdn
				disabled={Boolean(fmc?.unpairFmc)}
				handleResetSearch={handleResetSearch}
				onCompleted={searchMsisdn}/>

			{gsmSubscription && <><div className="col-12 col-md-6">
				<h4><FormattedMessage {...FMCMessages.gsmSubscription} /></h4>
			</div>
			<div className="form-row mb-5">
				{/* <BillingAccountDetails
					lineType={gsmSubscription.subscription_type?.line_type}
					paymentType={gsmSubscription.payment_type}
					msisdn={gsmSubscription.msisdn}/>
				<CustomerDetails
					owner={gsmSubscription.owner}
					msisdn={gsmSubscription.msisdn}/> */}
				<FmcContactPhoneInfo msisdn={gsmSubscription.msisdn}/>
			</div>
			<CurrentPlanSummary
				tariffPlan={gsmSubscription.subscription_type}
			/>
			</>}

			{fttbSubscription && <><div className="col-12 col-md-6">
				<h4><FormattedMessage {...FMCMessages.fttbSubscription} /></h4>
			</div>
			<div className="row">
				<FttbDetails 
					lineType={fttbSubscription.subscription_type?.line_type}
					msisdn={fttbSubscription.msisdn}/>
				{/* <CustomerDetails
					owner={fttbSubscription.owner}
					msisdn={fttbSubscription.msisdn}/> */}
				<FmcContactPhoneInfo
					contactPhone={fttbSubscription.owner?.contact_phone}
					msisdn={fttbSubscription.msisdn}/>
			</div>
			<CurrentPlanSummary
				tariffPlan={fttbSubscription.subscription_type}
			/>
			</>}
		</StepWrapper>);
};

export {
	Search
};
