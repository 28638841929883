/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface FooterMessagesType {
	title: MessageDescriptor;
}
const FooterMessages: FooterMessagesType = defineMessages({
	title: {
		id: "ipos-footer",
		description: "IPos footer title",
		defaultMessage: "Qvantel"
	},
});

export default FooterMessages;
export { FooterMessages };
