import React, {useCallback, useEffect, useState} from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { useIntl } from "react-intl";
import {B2B_BILLING_ACCOUNT_TYPE, config, POSTPAID_PLAN} from "../../../../../config/constants";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import { changeSimMessages } from "../../../../change-bar/sim/ChangeSim.message";
import { ACQUISITION_FLOW_OPTIONS_PATH,
	ACQUISITION_FLOW_CONTACT_INFO_PATH,
	ACQUISITION_FLOW_MSISDN_PATH,
} from "../../Routes/AcquisitionFlowRoutes";
import { CommonMessages } from "../../../../../Common.messages";
import {Mutation, Query, QueryValidate_IccArgs} from "../../../../../graphql/types";
import { VALIDATE_ICC } from "../../../../../graphql/queries/icc";
import { iccValidationErrors } from "../../../../../shared/utils/mappingErrors";
import { CircularProgress, NavigationHeader, ErrorMessageTemplate } from "../../../../../shared/components";
import { ICCForm } from "../../../../../shared/components/ICCForm";
import { AcquisitionFlow } from "../../Routes/AcquisitionFlow";
import { AcquisitionFlowState } from "../SummaryPage/SummaryPage";
import {GET_RESERVED_BILLING_ACCOUNT_ID} from "../../../../../graphql/queries/company";

const formId = "acquisition-flow-icc-form-id";

const IccidPage = (props: RouteComponentProps<never, StaticContext, AcquisitionFlowState>) => {
	const { history, location } = props;
	const intl = useIntl();
	const {state} = location;

	const [error, setError] = useState<string>("");
	const [accountId, setAccountId] = useState<string|null|undefined>("");
	const iccValidationFail = intl.formatMessage({...changeSimMessages.iccValidationFail});

	const setErrorMessage = (data: Query) => {
		if (!data?.validate_icc) {
			setError(intl.formatMessage(CommonMessages.provideValidIcc));
			return;
		}
		const currentError = iccValidationErrors[data?.validate_icc?.error || ""];
		if (currentError) {
			setError(intl.formatMessage(CommonMessages[currentError]));
		} else {
			setError(data?.validate_icc?.error || iccValidationFail);
		}
	};

	const [reserveBillingAccountId] = useMutation<Required<Pick<Mutation, "billing_account_id_reservations">>>(GET_RESERVED_BILLING_ACCOUNT_ID);

	useEffect(()=> {
		if (state?.plan.planType === POSTPAID_PLAN && !state?.account_id) {
			reserveBillingAccountId({
				variables: {}
			}).then(async ({data}) => {
				setAccountId(data?.billing_account_id_reservations?.request_id);
			});
		}
	}, []);

	const [validateIcc, {loading, variables}] = useLazyQuery<Query, QueryValidate_IccArgs>(VALIDATE_ICC, {
		fetchPolicy: "no-cache",
		onCompleted: (data: Query) => {
			if (data.validate_icc && !data.validate_icc?.error) {
				history.push({
					pathname: ACQUISITION_FLOW_CONTACT_INFO_PATH,
					state: {
						...state,
						iccId: variables.icc,
						account_id: accountId || state?.account_id
					}
				});
			} else {
				setErrorMessage(data);
			}
		},
		onError: () => {
			setError(intl.formatMessage(CommonMessages.provideValidIcc));
		}
	});
	// redirect to MSISDN page if msisdn is empty
	if (!state?.msisdn) {
		history.push(ACQUISITION_FLOW_MSISDN_PATH);
	}
	const onSubmit = (values) => {
		setError("");
		validateIcc({
			variables: {
				icc: config.DEFAULT_ICC_PREFIX + values.iccId,
				offer_id: state?.plan?.offerId!,
			}
		});
	};
	const onClickBack = useCallback( () => history.push({
		pathname: ACQUISITION_FLOW_OPTIONS_PATH,
		state,
	}), []);
	const iccId = (config?.DEFAULT_ICC_PREFIX?.length && state?.iccId?.substring(config.DEFAULT_ICC_PREFIX.length!)) ||  "";
	const init = {iccId};
	return (
		<>
			<NavigationHeader FlowComp={AcquisitionFlow} formId={formId} back={onClickBack}/>
			<div className="ipos-content-box">
				<ICCForm initialValues={init} formId={formId} onSubmit={onSubmit} />
				{loading && <CircularProgress/>}
				<ErrorMessageTemplate show={error}>{error}</ErrorMessageTemplate>
			</div>
		</>
	);
};

export { IccidPage };
