import React, { FC } from "react";
import LoginMessages from "../login/Login.messages";
import { FormattedMessage } from "react-intl";
import { useAuthDataContext } from "../../shared/hooks/AuthentificationProvider";
import { useCustomIntlContext } from "../../shared/hooks/CustomIntlContextProvider";
import { config } from "../../config/constants";
import { DropdownLanguageMessages } from "../login/DropdownLanguage.messages";
import CommonMessages from "../../Common.messages";
import { msisdnActions, useStoreContext } from "../../shared/components/navigation/StepStateProvider";
interface BurgerMenuProps {
    setOpen: () => void;
}

const BurgerMenu: FC<BurgerMenuProps> = props => {
	const { setOpen } = props;
	const {lang, selectLanguage } = useCustomIntlContext();
	const {userName, onLogout} = useAuthDataContext();
	const languages = config.LANGUAGES;
	const {dispatch} = useStoreContext();

	const handleLogout = () => {
		setOpen();
		dispatch(msisdnActions.clean());
		onLogout();
	};
	const changeLanguage = (langItem: string) => ()=> {
		setOpen();
		selectLanguage(langItem);
	};
	return(
		<nav className="mobile-nav">
			<h3><FormattedMessage {...CommonMessages.language} /></h3>
			<ul>
				{languages.map((langItem) => (
					<li key={langItem} className={lang === langItem ? "active" : ""}>
						<button className="btn btn-block text-left" onClick={changeLanguage(langItem)}>
							<FormattedMessage {...DropdownLanguageMessages[langItem]} />
						</button>
					</li>
				))}
			</ul>
			<h3>{userName}</h3>
			<button className="btn text-left btn-block pl-0" onClick={handleLogout}>
				<i className="fa fa-sign-out-alt"/><FormattedMessage {...LoginMessages.logOut}/>
			</button>
		</nav>
	);
};

export {
	BurgerMenu
};
