import React from "react";
import { ErrorBoundaryRoute } from "../error/ErrorBoundaryRoute";
import { Redirect } from "react-router-dom";
import { LOGIN_PATH } from "../../routes/AppBodyRoutes";
import { useAuth } from "../hooks/useAuth";

const PrivateRouter = ({ component: Component, ...rest}) => {
	const {isAuthenticated} = useAuth();

	const FinalComponent = isAuthenticated ? Component :
		() => <Redirect to={{ pathname: LOGIN_PATH, state: { from: rest.location } }}/>;
	return <ErrorBoundaryRoute {...rest} component={FinalComponent} />;
};

export { PrivateRouter };