import gql from "graphql-tag";

const HANDLE_MODAL = gql`
  mutation ShowModal($message: String, $status: Boolean!, $title: String) {
    showModal(message: $message, status: $status, title: $title) @client
  }
`;

const SHOW_ACTION_MODAL = gql`
  mutation ShowActionModal($action: String!, $code: String!, $code3rdParty: Int) {
    showActionModal(action: $action, code: $code, code3rdParty: $code3rdParty) @client
  }
`;

const SET_APPLICATION_CONTEXT = gql`
  mutation SetApplicationContext($subscriptionId: String!, $msisdn: String!, $locale: String!) {
    setApplicationContext(subscriptionId: $subscriptionId, msisdn: $msisdn, locale: $locale) @client
  }
`;

const RESOLVE_ACTION_MODAL = gql`
  mutation ResolveActionModal($spinnerStatus: Boolean!, $code: String!, $action: String!, $globalSpinner: Boolean) {
    resolveActionModal(spinnerStatus: $spinnerStatus, code: $code, action: $action, globalSpinner: $globalSpinner) @client
  }
`;

const HANDLE_SPINNER = gql`
  mutation ShowSpinner($status: Boolean!) {
    showSpinner(status: $status) @client
  }
`;



const UPDATE_SIM_CARD_ICC = gql`
  mutation UpdateSimCardIcc($icc: String!, $subscriptioniId: String!) {
    updateSimCardIcc(icc: $icc, subscriptioniId: $subscriptioniId) @client
  }
`;

const UPDATE_AUTH = gql`
  mutation updateAuth($isAuthenticated: Boolean!) {
      updateAuth(isAuthenticated: $isAuthenticated) @client 
  }
`;
const HANDLE_MODAL_OTP_OPEN = gql`
  mutation HandleOtpOpen($msisdn: String!) {
    handleOtpOpen(msisdn: $msisdn) @client 
  }
`;
const HANDLE_MODAL_OTP_CLOSE = gql`
  mutation HandleOtp {
    handleOtpClose @client 
  }
`;
const HANDLE_OTPACTION_LOADING = gql`
  mutation HandleOtpActionLoading($loading: Boolean!) {
    handleOtpActionLoading(loading: $loading) @client 
  }
`;
const HANDLE_OTP_ERROR = gql`
  mutation HandleOtpError($error: String!) {
    handleOtpError(error: $error) @client 
  }
`;
const HANDLE_SET_OTP = gql`
  mutation HandleOtpSET($code: String!) {
    handleOtpSet(code: $code) @client 
  }
`;

const SET_DEALER_IDENTITY = gql`
  mutation SetDealerIdentity($dealerIdentity: UserDealerIdentity!) {
      setDealerIdentity(dealerIdentity: $dealerIdentity) @client 
  }
`;

export {
	HANDLE_MODAL,
	HANDLE_SPINNER,
	SHOW_ACTION_MODAL,
	RESOLVE_ACTION_MODAL,
	SET_APPLICATION_CONTEXT,
	UPDATE_SIM_CARD_ICC,
	UPDATE_AUTH,
	SET_DEALER_IDENTITY,
	HANDLE_MODAL_OTP_OPEN,
	HANDLE_MODAL_OTP_CLOSE,
	HANDLE_SET_OTP,
	HANDLE_OTPACTION_LOADING,
	HANDLE_OTP_ERROR,
};
