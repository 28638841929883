import React, {FC, useCallback, useMemo, useState} from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import {
	MNPRouteState,
	MNP_FLOW_OPTION_PATH,
	MNP_FLOW_TEMPORARY_MSISDN_PATH,
} from "../MNPFlowRoutes";
import { MNPFlow } from "./MNPFlow";
import {TARIFF_PLAN_TYPE_PREPAID} from "../../acquisitionFlow/NewCustomer/Pages";
import {PlanList} from "../../../shared/components/tariffPlan/PlanList";
import {NavigationHeader} from "../../../shared/components";
import {useQuery} from "@apollo/react-hooks";
import {LineTypeEnum, Query} from "../../../graphql/types";
import {GET_OFFERINGS_WITHOUT_OPTION_GROUPS} from "../../../graphql/queries/acquisitionFlow";
import {OfferSubscriptionProps, prepareOfferings} from "../../../shared/utils";
import {SelectedTariffPlanDetails} from "../../../graphql/localTypes";

const MnpPlan: FC<RouteComponentProps<never, StaticContext, MNPRouteState>> = (
	props: RouteComponentProps<never, StaticContext, MNPRouteState>
) => {
	const { location, history } = props;
	const {state} = location;

	const [mnpState, setMnpState] = useState<MNPRouteState | undefined>(state);

	if (!state?.temporaryMsisdn) {
		history.push(MNP_FLOW_TEMPORARY_MSISDN_PATH);
	}

	const onClickNext = useCallback(() => {
		if (mnpState?.plan?.code) {
			history.push(MNP_FLOW_OPTION_PATH, mnpState);
		}
	}, [mnpState]);
	const onClickBack = () =>
		history.push({ pathname: MNP_FLOW_TEMPORARY_MSISDN_PATH, state });

	const {data, loading} = useQuery<Query>(GET_OFFERINGS_WITHOUT_OPTION_GROUPS);
	const offerings: (OfferSubscriptionProps | undefined)[] | undefined = useMemo(() => {
		if (data?.offerings) {
			return prepareOfferings(data.offerings, LineTypeEnum.Mobile);
		} else {
			return [];
		}
	}, [data?.offerings]);

	const handleSelectTariffPlan = (plan: SelectedTariffPlanDetails) => {
		setMnpState({
			...state,
			plan,
			options: [],
		});
	};

	return (
		<>
			<NavigationHeader FlowComp={MNPFlow} next={onClickNext} back={onClickBack} disabledNext={loading}/>
			<div className="ipos-content-box">
				<PlanList
					offerings={offerings}
					type={TARIFF_PLAN_TYPE_PREPAID}
					loading={loading}
					plan={mnpState?.plan}
					handleSelectTariffPlan={handleSelectTariffPlan}
				/>
			</div>
		</>
	);
};

export { MnpPlan };
