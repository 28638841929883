import { OrderTypeEnum, Mutation, CdtOrdererNewResidentialCreditInput, CdtOrderInput } from "../../graphql/types";
import { UserMessages } from "../../modules/user/User.messages";
import moment from "moment";
import { config } from "../../config/constants";

class CdtUtils {

	static creditDecisionNewCustomerOrderer(customer: any): CdtOrdererNewResidentialCreditInput {
		return {
			first_name: customer.first_name || "",
			last_name: customer.last_name || "",
			nationality: customer.nationality || "UA",
			date_of_birth: moment(customer.date_of_birth).format(config.DATE_PICKER_FORMAT) || "",
			id_document_type: customer.id_document_type as any,
			id_document_number: customer.id_document_number || "",
			legal_address_street_name: customer.street?.selected?.[0]?.name || "",
			legal_address_zip: customer.postal_code || "",
			legal_address_city: customer.city?.selected?.[0]?.name || "",
			legal_address_country: customer.country || "UA",
			legal_address_start_date: "2020-10-10",
			billing_address_name: "",
			billing_address_street_name: "",
			billing_address_zip: "",
			billing_address_city: "",
			billing_address_country: "UK",
			customer_category: "CONSUMER" as any,
		};
	}
    
	static creditDecisionNewCustomerOrder(): CdtOrderInput {
		return {
			brand: "KY",
			order_type: OrderTypeEnum.Acquisition as any,
			created_at: new Date().toISOString(),
			includes_mnp: false,
			delivery_address_name: "",
			delivery_address_street_name: "",
			delivery_address_zip: "",
			delivery_address_city: "",
			delivery_address_country: "",
		};
	}

	static handleCdtResponse(errors, intl, data: Mutation | undefined) {
		if (errors) {
			throw intl.formatMessage({ ...UserMessages.credit_decision });
		}
		if (data && data.check_cdt_consumer_credit_decision) {
			const { business_instructions, errors, credit_decision } = data.check_cdt_consumer_credit_decision;
			if (errors?.length > 0) {
				throw errors[0].title;
			}
			if (credit_decision === "REJECTED") {
				if (business_instructions?.length > 0) {
					throw business_instructions.map((instruction) => instruction[intl.locale]).join(" ");
				}
				else {
					throw intl.formatMessage({ ...UserMessages.customerExistInSystemProceedWithExistingCustomerFlow });
				}
			}
		}
		else {
			throw intl.formatMessage({ ...UserMessages.credit_decision });
		}
	}
}

export {
	CdtUtils,
};