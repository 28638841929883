import { useState } from "react";
import {FormattedMessage, useIntl} from "react-intl";
import { Maybe, Package, ProductFamily} from "../../../../../graphql/types";
import { OptionProduct, OptionProductChecked } from "./OptionProduct";
import { CircularProgress } from "../../../../../shared/components";
import ChangePlanMessages from "../../../../change-bar/plan/ChangePlan.messages";
import cssns from "../../../../../shared/utils/cssnsConfig";

interface OptionsFamiliesProps {
    family: ProductFamily;
    onChecked: (value: OptionProductChecked) => void;
	onChildChecked: (value: OptionProductChecked) => void;
    selectedOptionalProduct: Package[];
	childOffersData: any;
	isParentChecked: boolean;
	loadingChildOffers: boolean;
	parentCode: string | null | undefined;
}

const {React} = cssns("Plan-Page");

const OptionsFamilies = (props: OptionsFamiliesProps) => {
	const [show, toggleShow] = useState<boolean>(true);
	const {locale} = useIntl();

	const onClick = () => toggleShow(!show);
	const familyName = props.family.name?.en;

	const maxChoiceFilter = (currentPackage) => {
		if(!props.family.max_choices){
			return false;
		}else{
			const familyPackagesCodeList = props.family.packages?.map(pack => pack?.code);
			const selectedOptionalProductCodeList = props.selectedOptionalProduct.map(selectedPackage => selectedPackage.code);
			const ifCurrentPackageFiltered = selectedOptionalProductCodeList.some(code => familyPackagesCodeList?.includes(code));
			if(selectedOptionalProductCodeList.includes(currentPackage.code)){
				return false;
			}else return ifCurrentPackageFiltered;
		}
	};

	return (<table  key={props.family.id!} className="table w-tile-table">
		<tbody>
			<tr className="w-tile-table-row w-tile-table-row-primary" onClick={onClick}>
				<td className="w-100">
					{props.family.name?.[locale]}
					<span className="text-gray-400 ml-2">({props.family?.packages?.length})</span>
				</td>
				<td className="shrink nowrap">
					{show ? <i className="fa fa-chevron-down"/> : <i className="fa fa-chevron-up"/>}
				</td>
			</tr>
			{show && (<tr>
				<td colSpan={2} className="w-details-drawer">
					{props.family.packages?.map((pack: Maybe<Package>) => (
						<>
							<table className="table w-tile-table" key={pack?.code!}>
								<tbody>
									<OptionProduct
										onChecked={props.onChecked}
										optionalProduct={pack!}
										familyName={familyName}
										disabled={maxChoiceFilter(pack)}
										selectedOptionalProduct={props.selectedOptionalProduct}/>
								</tbody>
							</table>
							{
								props.isParentChecked && (pack?.code === props.parentCode) &&
								<>
									{props.loadingChildOffers ? <CircularProgress/> :
										!(props.childOffersData?.length! > 0) &&
										<div className="column content" style={{ paddingLeft: "5%" }}>
											<FormattedMessage {...ChangePlanMessages.childProductNotFound} />
										</div>
									}
									{
										props.childOffersData?.map((childPack) => (
											<table className="table w-tile-table content" key={childPack?.code!}>
												<tbody>
													<OptionProduct
														onChecked={props.onChildChecked}
														optionalProduct={childPack!}
														selectedOptionalProduct={props.selectedOptionalProduct}/>
												</tbody>
											</table>
										))
									}
								</>
							}
						</>
					))}
				</td>
			</tr>)}
		</tbody>
	</table>
	);};

export { OptionsFamilies };
