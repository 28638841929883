import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useStoreContext } from "../components/navigation/StepStateProvider";
import NotificationsMessages from "./Notifications.messages";

const nofificatioinsCodes = {
	refund: NotificationsMessages.refund,
	router: NotificationsMessages.router,

};
const getNotifications = (errors, intl): []|undefined => errors?.map(code => nofificatioinsCodes[code] ? intl.formatMessage(nofificatioinsCodes[code]) : code);

const Notifications: FC = () =>  {
	const {state: { notifications}} = useStoreContext();
	const intl = useIntl();
	const translations = getNotifications(notifications, intl);
	
	return <>{translations?.map(item => (<div key={item} className="alert alert-info mb-0">
		<p className="mb-0">
			{item}
		</p>
	</div>))}</>;

};

export { Notifications };