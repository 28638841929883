import { useIntl } from "react-intl";
import React, { useCallback, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import {
	ACQUISITION_EXISTING_FLOW_CONTACT_INFO_PATH,
	ACQUISITION_EXISTING_FLOW_OPTIONS_PATH,
} from "../../Router/AcquisitionExistingFlowPath";
import { config } from "../../../../../config/constants";
import { CommonMessages } from "../../../../../Common.messages";
import { Query, QueryValidate_IccArgs } from "../../../../../graphql/types";
import { useLazyQuery } from "@apollo/react-hooks";
import { CircularProgress, NavigationHeader, ErrorMessageTemplate, ICCForm } from "../../../../../shared/components";
import { changeSimMessages } from "../../../../change-bar/sim/ChangeSim.message";
import { VALIDATE_ICC } from "../../../../../graphql/queries/icc";
import { iccValidationErrors } from "../../../../../shared/utils/mappingErrors";
import { AcquisitionExistingFlow } from "../../Router/AcquisitionExistingFlow";
import { ExistingCustomerRouteState } from "../Summary/Summary";

const formId = "acquisition-flow-icc-form-id";
//TODO should be refactored due to dump component without of ticket;

const IccId = (props: RouteComponentProps<never, StaticContext, ExistingCustomerRouteState>) => {
	const { location:{state}, history } = props;
	const intl = useIntl();
	const [error, setError] = useState<string>("");
	const iccValidationFail = intl.formatMessage({...changeSimMessages.iccValidationFail});
	const setErrorMessage = (data: Query) => {
		if (!data?.validate_icc) {
			setError(intl.formatMessage(CommonMessages.provideValidIcc));
			return;
		}
		const currentError = iccValidationErrors[data?.validate_icc?.error || ""];
		if (currentError) {
			setError(intl.formatMessage(CommonMessages[currentError]));
		} else {
			setError(data?.validate_icc?.error || iccValidationFail);
		}
	};
	const [validateIcc, {loading, variables}] = useLazyQuery<Query, QueryValidate_IccArgs>(VALIDATE_ICC, {
		fetchPolicy: "no-cache",
		onCompleted: (data: Query) => {
			if (data.validate_icc && !data.validate_icc?.error) {
				history.push(ACQUISITION_EXISTING_FLOW_CONTACT_INFO_PATH, {...state, iccId: variables.icc});
			} else {
				setErrorMessage(data);
			}
		},
		onError: () => {
			setError(intl.formatMessage(CommonMessages.provideValidIcc));
		}
	});

	const onClickBack = useCallback(() => {
		history.push(ACQUISITION_EXISTING_FLOW_OPTIONS_PATH, state);
	}, []);

	const onSubmit = (values) => {
		validateIcc({
			variables: {
				icc: config.DEFAULT_ICC_PREFIX + values.iccId,
				offer_id: state?.plan?.offerId!,
			}
		});
		setError("");
	};
	const iccId = (config?.DEFAULT_ICC_PREFIX?.length && state?.iccId?.substring(config.DEFAULT_ICC_PREFIX.length!)) ||  "";
	const init = { iccId };
	return (
		<>
			<NavigationHeader FlowComp={AcquisitionExistingFlow} formId={formId} back={onClickBack} />
			<div className="ipos-content-box">
				<ICCForm initialValues={init} formId={formId} onSubmit={onSubmit} />
				{loading && <CircularProgress/>}
				<ErrorMessageTemplate show={error}>{error}</ErrorMessageTemplate>
			</div>
		</>
	);
};

export { IccId };