import React, { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { HasPermission } from "../../shared/components/HasPermission";
import { fmcActions, useStoreContext } from "../../shared/components/navigation/StepStateProvider";
import { useNavigationState } from "../../shared/components/navigation/useNavigationState";
import { PermissionRolesEnum } from "../../shared/types/PermissionRolesEnum";
import { FMCMessages } from "./FMC.messages";
import { FmcRouteState } from "./fmc.type";
import { FmcPostpaidRouteState } from "./postpaid/fmc.postpaid.types";

const NoFttbCheckbox: FC<{cleanFttb?: () => void;}> = (props) => {
	const state = useNavigationState<FmcRouteState|FmcPostpaidRouteState>();
	const isNoFttbSubscription: boolean|undefined = (state as FmcRouteState)?.isNoFttbSubscription 
		|| (state as FmcPostpaidRouteState)?.search?.withoutFttb ;
	const [noFttb, setNoFttb] = useState<boolean>(Boolean(isNoFttbSubscription));
	const {dispatch} = useStoreContext();

	const onNofttbChange = () => {
		const withoutFttb = !noFttb;

		dispatch(fmcActions.setWithoutFttb(withoutFttb));

		setNoFttb(withoutFttb);
		props.cleanFttb?.();
		//TODO maybe reset fttb validation error but I quess general reset should be enought as we don't have comliccated reset logic
		// //setError("");
		
	};
	return (
		<div className="form-group col-xl-3 col-lg-6">
			<HasPermission accessFor={[PermissionRolesEnum.CREATE_FTTB_ORDER]}>
				<div className="custom-control custom-checkbox">
					<input
						type="checkbox"
						onChange={onNofttbChange}
						checked={noFttb}
						className="custom-control-input"
						id="no-fttb"
					/>
					<label className="custom-control-label" htmlFor="no-fttb">
						<FormattedMessage {...FMCMessages.noFttb} />
					</label>
				</div>
			</HasPermission>
		</div>
	);
};

export {
	NoFttbCheckbox
};