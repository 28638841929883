import React from "react";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { FTTBMessages } from "../../modules/fttb/FTTB.messages";
import { FttbAddressEnum } from "../types/FttbEnum";

export interface SimpleConnectionProps {
	connectionExist: string;
	formik: any;
};
const SimpleConnection: FC<SimpleConnectionProps> = ({connectionExist, formik}) =>  {
	return <div className="mt-2 alert alert-info">
		<div className="row">
			<div className="col-12">
				{connectionExist}
			</div>
			<div className="d-flex col-12">
				<label className="mr-2">
					<FormattedMessage {...FTTBMessages.simpleConnection} />
				</label>
				<div className="custom-control custom-checkbox mt2">
					<input
						className="custom-control-input"
						id={FttbAddressEnum.simpleConnection}
						name={FttbAddressEnum.simpleConnection}
						type="checkbox"
						checked={formik.values.simpleConnection}
						onChange={() => formik.setFieldValue(FttbAddressEnum.simpleConnection, !formik.values.simpleConnection)} />
					<label className="custom-control-label" htmlFor={FttbAddressEnum.simpleConnection}>
					</label>
				</div>
			</div>
		</div>
	</div>;
}
export {
	SimpleConnection
}
