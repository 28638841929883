import React, { useState } from "react";
import { SingleDatePicker, isSameDay, isInclusivelyAfterDay, isInclusivelyBeforeDay } from "react-dates";
import moment, { Moment } from "moment";
import { FIRST_DAY_OF_WEEK } from "../../config/constants";

interface DatePickerFieldProps {
    min: Moment;
    max: Moment;
    required: boolean;
    [key: string]: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DatePickerField = ({field, form, meta, ...props}: DatePickerFieldProps) => {
	const error = form.touched[field.name] && !!form.errors[field.name];
	const helperText = error ? form.errors[field.name] : "";
	const [focused , setFocused] = useState<boolean>(false);
	const labelClass = props.required ?  "w-required-field": "";

	const handleChange = date => {
		form.setFieldValue(field.name, date ? date.toDate() : null);
	};

	const onClose = () => {
		const { setTouched, touched } = form;
		setTimeout(() => {
			setTouched({...touched, [field.name]: true});
		}, 10);
	};

	const isOutsideRange = (day: moment.Moment): boolean => {
		const isAfterMin = props.min ? !isInclusivelyBeforeDay(day, moment(props.min)) : true;
		const isBeforeMax = props.max ?
			isSameDay(day, moment(props.max)) || !isInclusivelyAfterDay(day, moment(props.max)) :
			true;
		return !(isAfterMin && isBeforeMax);
	};

	const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
		const years: any[]= [];
		for(let i = props.max.year(); i >= props.min.year(); i--) {
			years.push(<option value={i} key={`year-${i}`}>{i}</option>);
		}
		return (
			<div style={{ display: "flex", justifyContent: "center" }}>
				<div>
					<select value={month.month()} onChange={e => onMonthSelect(month, parseInt(e.target.value))}>
						{moment.months().map((label, value) => (
							<option value={value} key={value}>{label}</option>
						))}
					</select>
				</div>
				<div>
					<select value={month.year()} onChange={e => onYearSelect(month, parseInt(e.target.value))}>
						{years}
					</select>
				</div>
			</div>
		);
	};
	const initialVisibleMonth = () => field.value ? moment(field.value) : props?.max?.isBefore() ? props?.max : moment();
	return (
		<div className="form-group">
			<div>{props.label && <label className={labelClass} htmlFor={props.id}>{props.label}</label>}</div>
			<SingleDatePicker
				initialVisibleMonth={initialVisibleMonth}
				id={field.name}
				regular={Boolean(error)}
				required={props.required}
				block={false}
				disabled={props.disabled}
				onDateChange={handleChange}
				onFocusChange={focusedInput => {setFocused(focusedInput?.focused);}}
				focused={focused}
				displayFormat={props.format}
				placeholder={props.format}
				numberOfMonths={1}
				date={field.value ? moment(field.value) : null}
				hideKeyboardShortcutsPanel={true}
				isOutsideRange={isOutsideRange}
				renderMonthElement={renderMonthElement}
				onClose={onClose}
				firstDayOfWeek={FIRST_DAY_OF_WEEK}
			/>
			<div className="form-text text-danger">{helperText}</div>
		</div>
	);
};

export { DatePickerField };
