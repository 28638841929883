import React, { FC, useEffect, useState, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { useFormik } from "formik";
import { loginFirstStepSchema, loginSecondStepSchema } from "./Login.schema";
import { ErrorMessageTemplate } from "../../shared/components";
import LoginMessages from "./Login.messages";
import { iposRoutes } from "../../routes/RoutesMap";
import { MutationLoginArgs, Query, QuerySend_PasswordArgs } from "../../graphql/types";
import CommonMessages from "../../Common.messages";
import { DropdownLanguage } from "./DropdownLanguage";
import { CircularProgress } from "../../shared/components/CircularProgress";
import { InputFieldFormik } from "../../shared/components/InputField";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_OTP } from "../../graphql/queries/authentication";
import { useAuthDataContext } from "../../shared/hooks/AuthentificationProvider";
import { MSISDN_WITH_OPTIONAL_COUNTRY_CODE } from "../../config/constants";
import { addPrefixToMsisdn } from "../../shared/utils/utils";
import { useAuth } from "../../shared/hooks/useAuth";


const INDEX_PATH = iposRoutes.INDEX.createLink();

const Login: FC = () => {
	const {isAuthenticated } = useAuth();
	const {loading, onLogin} = useAuthDataContext();
	const [error, setError] = useState<string|undefined>(undefined);
	const history = useHistory();
	const location = useLocation<any>();
	const intl = useIntl();

	const errorTable = useMemo(()=> ({
		"your account is blocked. please contact administrator.": intl.formatMessage(LoginMessages.acccountIsBlocked),
		"password expired.": intl.formatMessage(LoginMessages.passwordExpired),
		"invalid credentials": intl.formatMessage(LoginMessages.loginFailed)
	}), []);



	const [showFirstStep, setShowFirstStep] = useState<boolean>(true);
	const [requestOtp] = useLazyQuery<Query, QuerySend_PasswordArgs>(GET_OTP);
	const [username, setUserName] = useState<string>("");
	const firstStepFormId = "login-form-id-first-step";
	const secondStepFormId = "login-form-id-second-step";
	const passwordPlaceHolder = intl.formatMessage({...LoginMessages.enterOtp});

	const formikFirstStep = useFormik({
		initialValues: process.env.NODE_ENV === "development" ? {
			username: "qvantel_lchedella",
		} : {
			username: "",
		},
		validationSchema: loginFirstStepSchema(intl),
		onSubmit: (values: Omit<MutationLoginArgs, "password">) => {
			const username = values.username.split(" ").join("");
			if (new RegExp(MSISDN_WITH_OPTIONAL_COUNTRY_CODE).test(username)) {
				requestOtp({variables: {
					username: addPrefixToMsisdn(username)
				}});
			}
			setUserName(username);
			setShowFirstStep(false);
		}
	});
	const { from } = location.state || { from: { pathname: INDEX_PATH}};

	const formikSecondStep = useFormik({
		initialValues: process.env.NODE_ENV === "development" ? {
			password: "HelloQvantel@123456",
		} : {
			password: "",
		},
		validationSchema: loginSecondStepSchema(intl),
		onSubmit: (values: Omit<MutationLoginArgs, "username">) => {
			(onLogin({...values, username}) as Promise<string>)?.then(error => {
				error && setError(error);
				if (!error) {
					history.replace(from);
				}

			});
		}
	});


	useEffect(() => {
		if(isAuthenticated) {
			history.replace(from);
		}
	}, [isAuthenticated]);
	if (loading) {
		return <CircularProgress/>;
	}

	return (
		<div id="w-login">
			<header id="w-app-header">
				<h1 id="w-app-title">
					<strong id="client-logo"><FormattedMessage {...CommonMessages.companyName}/></strong>
					<span id="w-app-name"><FormattedMessage {...CommonMessages.productName}/></span>
				</h1>
			</header>
			<div id="w-login-body-custom">
				<header className="w-view-header">
					<h3><FormattedMessage {...LoginMessages.signIn}/></h3>
					<DropdownLanguage/>
				</header>
				<ErrorMessageTemplate show={Boolean(error)}>
					{error && errorTable[error.toLowerCase()] || intl.formatMessage(LoginMessages.loginFailed)}
				</ErrorMessageTemplate>
				{showFirstStep ? <form id={firstStepFormId} data-testid="form" onSubmit={formikFirstStep.handleSubmit}>
					<label htmlFor="w-login-username">
						<FormattedMessage {...LoginMessages.username}/>
					</label>
					<InputFieldFormik
						attr={{
							"data-test": "login-username-input"
						}}
						value={formikFirstStep.values.username}
						name="username"
						id="username"
						formik={formikFirstStep}/>
				</form> :
					<form id={secondStepFormId} data-testid="form" onSubmit={formikSecondStep.handleSubmit}>
						<label htmlFor="w-login-password">
							<FormattedMessage {...LoginMessages.password}/>
						</label>
						<InputFieldFormik
							attr={{
								"data-test": "login-password-input"
							}}
							value={formikSecondStep.values.password}
							name="password"
							id="password"
							type="password"
							placeholder={passwordPlaceHolder}
							formik={formikSecondStep}/>
					</form>}
				<footer className="mt-4">
					<button
						data-test="login-button"
						type="submit"
						className="btn btn-secondary btn-block"
						data-testid="submit-button"
						form={showFirstStep ? firstStepFormId : secondStepFormId}
					>
						<FormattedMessage {...LoginMessages.signIn}/>
					</button>
					{!showFirstStep && <button
						data-test="login-button-back"
						type="button"
						className="btn btn-outline-light btn-block"
						data-testid="submit-button"
						onClick={() => setShowFirstStep(true)}
					>
						<FormattedMessage {...CommonMessages.back}/>
					</button>}
				</footer>
			</div>
		</div>
	);
};

export {
	Login
};
