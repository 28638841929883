import React from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import { Route } from "react-router-dom";

const ErrorBoundaryRoute = ({ component: Component, ...rest }) => {
	const encloseInErrorBoundary = (props: any) => (
		<ErrorBoundary>
			<Component {...props} />
		</ErrorBoundary>
	);

	if (!Component) throw new Error(`A component needs to be specified for path ${(rest as any).path}`);

	return <Route {...rest} render={encloseInErrorBoundary} />;
};

export { ErrorBoundaryRoute };
