import React, { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Switch, useHistory } from "react-router-dom";
import { MsisdnTopBarInfo } from "../../../shared/components";
import { flowActions, fmcActions, useStoreContext } from "../../../shared/components/navigation/StepStateProvider";
import { ErrorBoundaryRoute } from "../../../shared/error/ErrorBoundaryRoute";
import { FmcPostpaidMessages } from "./FmcPostpaid.messages";
import { FMC_FLOW_POSTPAID_FTTB_ADDRESS_PATH, FMC_FLOW_POSTPAID_OPTIONS_PATH, FMC_FLOW_POSTPAID_PLAN_PATH, FMC_FLOW_POSTPAID_SEARCH_PATH, FMC_FLOW_POSTPAID_SUMMARY_PATH } from "./fmc.postpaid.routes";
import { FmcPostpaidRouteState } from "./fmc.postpaid.types";
import { Preorder } from "./steps/Preorder";
import { Options } from "./steps/Options";
import { Plan } from "./steps/Plan";
import { Search } from "./steps/Search";
import { Summary } from "./steps/Summary";
import { SubscriptionTypeCategoryEnum } from "../../../graphql/types";
import { cleanNavigationStateCallBack, useNavigationState } from "../../../shared/components/navigation/useNavigationState";

const FmcPostpaidPageRouter: FC = () => {
	const intl = useIntl();
	const {dispatch, state: {fmc}} = useStoreContext();
	const state  = useNavigationState<FmcPostpaidRouteState>();

	const history = useHistory();

	useEffect(() => {
		if (!state) {
			history.push(FMC_FLOW_POSTPAID_SEARCH_PATH);
		}
		const gsmSub = state?.search?.gsmSubscription;
		const fttbSub = state?.search?.fttbSubscription;
		if (gsmSub) {
			dispatch(fmcActions.setFmcPopMerge(gsmSub?.subscription_type?.subscription_type_category === SubscriptionTypeCategoryEnum.Fmcpopmerge));
		}
		if (fttbSub?.id) {
			dispatch(fmcActions.setWithoutFttb(false));
		}
	}, []);

	const searchStep = {
		pathname: FMC_FLOW_POSTPAID_SEARCH_PATH,
		message: intl.formatMessage({ ...FmcPostpaidMessages.search })
	};
	const addressStep = {
		pathname: FMC_FLOW_POSTPAID_FTTB_ADDRESS_PATH,
		message: intl.formatMessage({ ...FmcPostpaidMessages.address })
	};
	const planStep = {
		pathname: FMC_FLOW_POSTPAID_PLAN_PATH,
		message: intl.formatMessage({ ...FmcPostpaidMessages.plan })
	};
	const optionStep = {
		pathname: FMC_FLOW_POSTPAID_OPTIONS_PATH,
		message: intl.formatMessage({ ...FmcPostpaidMessages.options })
	};
	const summaryStep = {
		pathname: FMC_FLOW_POSTPAID_SUMMARY_PATH,
		message: intl.formatMessage({ ...FmcPostpaidMessages.summary })
	};
	const fmcRegularRoutes = [
		searchStep,
		planStep,
		summaryStep
	];
	const fmcWithoutFttbRoutes = [
		searchStep,
		addressStep,
		planStep,
		optionStep,
		summaryStep
	];
	const fmcPopMergeRegularRoutes = [
		searchStep,
		summaryStep
	];
	const fmcPopMergeWithoutFttbRoutes = [
		searchStep,
		addressStep,
		optionStep,
		summaryStep
	];
	//1) subscription_type_category === SubscriptionTypeCategoryEnum.Fmcpopmerge
	//2) without fttb
	const stepConfig = {
		true: {
			true: fmcPopMergeWithoutFttbRoutes,
			false: fmcPopMergeRegularRoutes
		},
		false: {
			true: fmcWithoutFttbRoutes,
			false: fmcRegularRoutes
		} 
	};
	
	//TODO unpair FMC impl chnage flow impl
	useEffect(() => {
		const allowFmcMerge = fmc.allowFmcMerge;
		const withoutFttb = fmc?.withoutFttb !== undefined ? fmc.withoutFttb : state?.search?.withoutFttb;
		const noTPtab = (!!(fmc.isPopMerge || fmc.popAddonExist) && !allowFmcMerge).toString();
		dispatch(flowActions.changeFlow(stepConfig[noTPtab][(!!withoutFttb).toString()]));
	}, [fmc?.withoutFttb, fmc.isPopMerge, fmc.popAddonExist,fmc]);

	useEffect(() => {
		return cleanNavigationStateCallBack;
	}, []);
	return (<>
		<MsisdnTopBarInfo>
			<FormattedMessage {...FmcPostpaidMessages.title} />
		</MsisdnTopBarInfo>
		<div id="w-app-body">
			<Switch>
				<ErrorBoundaryRoute exact path={FMC_FLOW_POSTPAID_SEARCH_PATH} component={Search}/>
				<ErrorBoundaryRoute exact path={FMC_FLOW_POSTPAID_FTTB_ADDRESS_PATH} component={Preorder}/>
				<ErrorBoundaryRoute exact path={FMC_FLOW_POSTPAID_OPTIONS_PATH} component={Options}/>
				<ErrorBoundaryRoute path={FMC_FLOW_POSTPAID_PLAN_PATH} component={Plan}/>
				<ErrorBoundaryRoute path={FMC_FLOW_POSTPAID_SUMMARY_PATH} component={Summary}/>
			</Switch>
		</div>
	</>);
};

export {
	FmcPostpaidPageRouter
};
