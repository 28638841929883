import gql from "graphql-tag";

export const GET_CITIES = gql`
    query getCities($language: LanguageEnum!, $city_name: String, $country_id: ID, $region_id: ID) {
        gis_cities(language: $language, city_name: $city_name, country_id: $country_id, region_id: $region_id) {
            id,
            name,
        }
    }
`;


export const GET_STREETS = gql`
    query getStreets($language: LanguageEnum!, $street_name: String, $city_id: ID) {
        gis_streets(language: $language, street_name: $street_name, city_id: $city_id) {
            id,
            name,
            available_houses {
                id,
                house_number,
                zip_code,
                zip_id,
                fttb_available,
                network_type_id,
            }
        }
    }
`;

export const GET_HOUSES = gql`
    query getStreets($language: LanguageEnum!, $house_id: ID!) {
        gis_houses(language: $language, house_id: $house_id) {
            id,
            street_id,
            house_number,
            fttb_available,
        }
    }
`;
export const GET_HOUSE_GREEN_FIELD = gql`
    query getStreets($language: LanguageEnum!, $house_id: ID!, $apartment: String) {
        gis_houses(language: $language, house_id: $house_id) {
            id,
            street_id,
            house_number,
            fttb_available,
            green_field(apartment: $apartment) {
                apartment
                gis_house_id
                green_field_status
                green_field_tp_status
                green_field_tp
            }
        }
    }
`;

export const GET_REGIONS = gql`
    query getRegions($language: LanguageEnum!, $country_id: ID!) {
        gis_regions(language: $language, country_id: $country_id) {
            id,
            name,
        }
    }
`;

export const GET_DISTRICTS = gql`
    query getDistrict($language: LanguageEnum!, $region_id: ID!) {
        gis_districts(language: $language, region_id: $region_id) {
            id,
            name,
        }
    }
`;

export const GET_FLATS = gql `
    query getFlats($house_id: String!,$flat_number: String!){
        gis_flats(house_id: $house_id, flat_number: $flat_number){
            is_1g_available
            house_id
            flat_number
        }
    }
`;
