import gql from "graphql-tag";

const Customer = gql`
    fragment Customer on Customer {
        id,
        first_name,
        last_name,
        is_company,
        is_anonymous_customer,
        billing_accounts {
            id,
            name,
            invoice_delivery_method,
            payment_method,
            status,
            type {
                code,
                name
            }
        },
        invoices(status: "unpaid") {
            invoice_id,
            invoice_external_id,
            total_without_vat,
            total,
            vat,
            invoice_date,
            due_date,
            pdf,
            claim_state,
            paid_amount,
            payment_status
        },
        id_document_type,
        id_document_number,
        id_document_issued_by,
        id_expiry,
        id_document_issue_date,
        additional_id_date,
        contact_phone,
        tax_id,
        middle_name,
        gender,
        date_of_birth,
        email,
        address {
            co_address,
            street,
            house_number,
            building,
            floor,
            apartment,
            room,
            po_box,
            postal_district,
            city,
            county,
            province,
            country,
            country_name,
            type,
            address_type,
            district,
            postal_code,
            comments,
            area,
            gis_house_id,
            gis_city_id,
            gis_street_id,
            gis_region_id,
            gis_district_id
        }
    }
`;

export {Customer};
