import React, {useEffect} from "react";
import { MsisdnTopBarInfo } from "../../../../shared/components";
import { Switch } from "react-router";
import { ErrorBoundaryRoute } from "../../../../shared/error/ErrorBoundaryRoute";
import {
	B2B_FTTB_FLOW_ADDRESS_PATH,
	B2B_FTTB_FLOW_PLAN_PATH,
	B2B_FTTB_FLOW_BILLING_ACCOUNT_PATH,
	B2B_FTTB_FLOW_SUMMARY_PATH,
	B2B_FTTB_FLOW_SEARCH_COMPANY_PATH
} from "./B2BFttbFlowRoutes";
import {FormattedMessage, useIntl} from "react-intl";
import { FTTBMessages } from "../../FTTB.messages";
import {Address, BillingAccount, Plan, Summary} from "../Pages";
import {SearchCompany} from "../Pages/SearchCompany";
import {b2bFttbFlowRoutesConfig} from "../../../../routes/RoutesMap";
import {flowActions, useStoreContext} from "../../../../shared/components/navigation/StepStateProvider";
import { cleanNavigationStateCallBack } from "../../../../shared/components/navigation/useNavigationState";

const B2BFttbPageRouter = () => {
	const intl = useIntl();
	const { dispatch } = useStoreContext();
	const b2bFttbRoutes = [
		{
			pathname: B2B_FTTB_FLOW_ADDRESS_PATH,
			message: intl.formatMessage({ ...FTTBMessages.address })
		},
		{
			pathname: B2B_FTTB_FLOW_SEARCH_COMPANY_PATH,
			message: intl.formatMessage({ ...FTTBMessages.company })
		},
		{
			pathname: B2B_FTTB_FLOW_PLAN_PATH,
			message: intl.formatMessage({ ...FTTBMessages.plan })
		},
		{
			pathname: B2B_FTTB_FLOW_BILLING_ACCOUNT_PATH,
			message: intl.formatMessage({ ...FTTBMessages.billingAccount })
		},
		{
			pathname: B2B_FTTB_FLOW_SUMMARY_PATH,
			message: intl.formatMessage({ ...FTTBMessages.summary })
		}
	];
	useEffect(() => {
		dispatch(flowActions.changeFlow(b2bFttbRoutes ));
		return cleanNavigationStateCallBack;
	}, []);
	return (
		<>
			<MsisdnTopBarInfo>
				<FormattedMessage {...FTTBMessages.b2bFttbConnection} />
			</MsisdnTopBarInfo>
			<div id="w-app-body">
				<Switch>
					<ErrorBoundaryRoute exact path={B2B_FTTB_FLOW_ADDRESS_PATH} component={Address}/>
					<ErrorBoundaryRoute exact path={B2B_FTTB_FLOW_SEARCH_COMPANY_PATH} component={SearchCompany}/>
					<ErrorBoundaryRoute path={B2B_FTTB_FLOW_PLAN_PATH} component={Plan}/>
					<ErrorBoundaryRoute path={B2B_FTTB_FLOW_BILLING_ACCOUNT_PATH} component={BillingAccount}/>
					<ErrorBoundaryRoute path={B2B_FTTB_FLOW_SUMMARY_PATH} component={Summary}/>
				</Switch>
			</div>
		</>
	);
};

export { B2BFttbPageRouter, b2bFttbFlowRoutesConfig };