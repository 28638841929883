import { useIntl } from "react-intl";
import React, { useMemo } from "react";
import { iccIdSchema } from "../../modules/acquisitionFlow/NewCustomer/Pages/IccidPage/IccidPage.schema";
import { changeSimMessages } from "../../modules/change-bar/sim/ChangeSim.message";
import { useFormik } from "formik";
import { InputFieldFormik } from "./InputField";
import { onChangeIcc } from "../utils/utils";
import { config } from "../../config/constants";

interface FormIccProps {
    formId: string;
    initialValues: any;
    onSubmit: (values?: any) => void;
}
const ICCForm = (props: FormIccProps) => {
	const { formId, initialValues, onSubmit } = props;
	const intl = useIntl();
	const validationSchema = useMemo(() => iccIdSchema(intl), [intl.locale]);
	const scanLabel = useMemo(() => intl.formatMessage({...changeSimMessages.scanLabel}), [intl.locale]);
	const { values, setFieldValue, errors, touched, handleSubmit, handleChange } = useFormik({
		onSubmit,
		initialValues,
		validationSchema,
	});
	const formik = {values, setFieldValue, errors, touched, handleChange};
	return (
		<form id={formId} onSubmit={handleSubmit}>
			<InputFieldFormik
				onChange={onChangeIcc}
				id="icc-id"
				startAdornment={config.DEFAULT_ICC_PREFIX}
				placeholder={scanLabel}
				value={values.iccId}
				name="iccId"
				formik={formik}
			/>
		</form>
	);
};

export { ICCForm };