import React, {FC, useMemo, useState} from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import {MNPRouteState} from "../../MNPFlowRoutes";
import {TARIFF_PLAN_TYPE_POSTPAID} from "../../../acquisitionFlow/NewCustomer/Pages";
import {PlanList} from "../../../../shared/components/tariffPlan/PlanList";
import {useQuery} from "@apollo/react-hooks";
import {LineTypeEnum, Query} from "../../../../graphql/types";
import {GET_OFFERINGS_WITHOUT_OPTION_GROUPS} from "../../../../graphql/queries/acquisitionFlow";
import {OfferSubscriptionProps, prepareOfferings} from "../../../../shared/utils";
import {SelectedTariffPlanDetails} from "../../../../graphql/localTypes";
import {StepWrapper} from "../../../../shared/components/navigation/StepWrapper";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";

const MnpPlan: FC<RouteComponentProps<never, StaticContext, MNPRouteState>> = (
	props: RouteComponentProps<never, StaticContext, MNPRouteState>
) => {
	const state = useNavigationState<MNPRouteState>();

	const [mnpState, setMnpState] = useState<MNPRouteState | undefined>(state);

	const {data, loading} = useQuery<Query>(GET_OFFERINGS_WITHOUT_OPTION_GROUPS);
	const offerings: (OfferSubscriptionProps | undefined)[] | undefined = useMemo(() => {
		if (data?.offerings) {
			return prepareOfferings(data.offerings, LineTypeEnum.Mobile);
		} else {
			return [];
		}
	}, [data?.offerings]);

	const handleSelectTariffPlan = (plan: SelectedTariffPlanDetails) => {
		setMnpState({
			...state,
			plan,
		});
	};

	return (
		<StepWrapper<MNPRouteState> isValid newState={{ plan: mnpState?.plan }}>
			<PlanList
				offerings={offerings}
				type={TARIFF_PLAN_TYPE_POSTPAID}
				loading={loading}
				plan={mnpState?.plan}
				handleSelectTariffPlan={handleSelectTariffPlan}
			/>
		</StepWrapper>
	);
};

export { MnpPlan };
