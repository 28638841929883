import React, { useState } from "react";
import { BillingAccount as BillingAccountType } from "../../../../../graphql/types";
import AcquisitionMessages from "../../../Acquisition.messages";
import { FormattedMessage } from "react-intl";
import {ACTIVE, B2B_BILLING_ACCOUNT_TYPE} from "../../../../../config/constants";
import {BillingAccountPagination} from "../../../../../shared/components/BillingAccountPagination";
import {CircularProgress} from "../../../../../shared/components";

interface BillingAccountProps {
    setFieldValue: (name: string, value: any) => void;
    account_id: string;
    customer?: any;
	isB2bBillingAccount: boolean;
}

const maskBillingAccount = (ba: string|null|undefined): string => {
	return ba ? ba.substring(0,1) + "XXXXXX" + ba.substring(7)
		: "";
};

const BillingAccount = (props: BillingAccountProps) => {
	if(!props?.customer) {
		return null;
	}
	const companyId = props?.customer?.id;
	const [billingAccountId, setBillingAccountId] = useState(props.account_id || "");
	const [billingAccounts, setBillingAccounts] = useState([]);
	const [loading, setLoading] = useState(false);

	const onChange = (e, billing) => {
		const account_id = e.target.value;
		const billing_type = billing.type?.code;
		props.setFieldValue("account_id", account_id);
		props.setFieldValue("billing_type", billing_type);
		setBillingAccountId(account_id);
		if (props?.customer) {
			props.customer["account_id"] = account_id;
		}
	};
	const filteredBillingAccounts = props.isB2bBillingAccount ?
		billingAccounts?.filter((billing: BillingAccountType) => (billing.status === ACTIVE && billing.type?.code === B2B_BILLING_ACCOUNT_TYPE))
		:billingAccounts?.filter((billing: BillingAccountType) => billing.status === ACTIVE);

	return (
		<div>
			{
				loading ? <CircularProgress /> :
					(
						filteredBillingAccounts?.length === 0 ?
							<div className="mt-2 alert alert-info">
								<FormattedMessage {...AcquisitionMessages.noBillingAccount} />
							</div> :
							filteredBillingAccounts.map((billing: BillingAccountType, index) => (
								<div key={billing?.id || index}>
									<div className="custom-control custom-radio">
										<input
											type="radio"
											name="account_id"
											className="custom-control-input"
											id={`my-account-${index}`}
											checked={billing?.id === billingAccountId}
											onChange={e => onChange(e, billing)}
											value={billing?.id || ""}
										/>
										<label className="custom-control-label" htmlFor={`my-account-${index}`}>
											<div><FormattedMessage {...AcquisitionMessages.billingAccountId} />: {maskBillingAccount(billing?.id)}</div>
											<div><FormattedMessage {...AcquisitionMessages.billingAccountName} />: {billing?.name || ""}</div>
											<div><FormattedMessage {...AcquisitionMessages.baInvoiceDeliveryMethod} />: {billing?.invoice_delivery_method || ""}</div>
											<div><FormattedMessage {...AcquisitionMessages.baPaymentMethod} />: {billing?.payment_method || ""}</div>
										</label>
									</div>
								</div>
							))
					)
			}
			{

			}
			<BillingAccountPagination companyId={companyId} setBillingAccounts={setBillingAccounts} setLoading={setLoading} />
		</div>
	);
};

export { BillingAccount };