import { useMutation, useQuery } from "@apollo/react-hooks";
import {FC, forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState} from "react";
import { useIntl } from "react-intl";
import CommonMessages from "../../Common.messages";
import { LocalMutation, LocalQuery, MutationHandleOtpActionLoading, MutationHandleOtpError, MutationHandleOtpSetArgs } from "../../graphql/localTypes";
import { HANDLE_MODAL_OTP_CLOSE, HANDLE_MODAL_OTP_OPEN, HANDLE_OTPACTION_LOADING, HANDLE_OTP_ERROR, HANDLE_SET_OTP } from "../../graphql/mutations/localMutation";
import { GET_OTP_MODAL } from "../../graphql/queries/localQuery";
import { Query } from "../../graphql/types";
import cssns from "../utils/cssnsConfig";
import { addPrefixToMsisdn } from "../utils/utils";
import { errorActions, useStoreContext } from "./navigation/StepStateProvider";
const {React} = cssns("Otp");

interface OtpProps {
	buttonType: "submit"|"button";
	onClick?: (e: React.MouseEventHandler<HTMLButtonElement>) => void;
}

interface OtpRefType {
	tick: () => void;
	// Add other properties if needed
};

const useOtp = (msisdn: string|undefined, searchMsisdn: (msisdn: string, otp: string) => Promise<string|void|Required<Pick<Query, "otp_subscription">>>): {
	handleOtp: (msisdn: string) => void;
	closeOtp: () => void;
	loading: boolean;
} => {
	const [setOtpLoading] = useMutation<LocalMutation, MutationHandleOtpActionLoading>(HANDLE_OTPACTION_LOADING);
	const [handleOtpError] = useMutation<LocalMutation, MutationHandleOtpError>(HANDLE_OTP_ERROR);
	const {data: optData} = useQuery<Required<Pick<LocalQuery, "modals">>>(GET_OTP_MODAL);
	const [handleOtp] = useMutation<LocalMutation>(HANDLE_MODAL_OTP_OPEN);
	const [closeOtp] = useMutation(HANDLE_MODAL_OTP_CLOSE);
	const {dispatch} = useStoreContext();

	const otp = optData?.modals.otp.code;
	const loading = optData?.modals.otp.loading;


	useEffect(()=> {
		if (otp && msisdn) {
			setOtpLoading({variables: {loading: true}});
			handleOtpError({ variables: { error: ""}});
			dispatch(errorActions.cleanMsisdnError(msisdn));
			searchMsisdn(addPrefixToMsisdn(msisdn), otp)
				.then((data) => {
					if (data === "erorr") {
						handleOtpError({ variables: { error: "error"}});
					} else {
						closeOtp();
					}
				})
				.catch(() => {
					handleOtpError({ variables: { error: "error"}});
				});
		}
	}, [otp]);
	return {
		handleOtp: (msisdn: string) => {
			handleOtp({ variables: { msisdn: addPrefixToMsisdn(msisdn)}});
		},
		closeOtp,
		loading: Boolean(loading)
	};
};


const Otp: ForwardRefRenderFunction<OtpRefType, OtpProps> = (props, ref) => {
	const { buttonType, onClick } = props;
	const intl = useIntl();
	const INTERVAL = 60;
	const [isOtpButtonDisabled, setOtpButtonDisabled] = useState<boolean>(false);
	const [timeLeft, setTimeLeft] = useState<number>(INTERVAL);
	const countRef = useRef<number>(INTERVAL);
	const timerRef = useRef<NodeJS.Timeout|null>(null);

	const valuenow = 1.66*timeLeft;

	const tick = () => {
		setOtpButtonDisabled(true);
		timerRef.current = setInterval(() => {
			const tick = countRef.current--;
			setTimeLeft(tick);
			if (tick === 0) {
				setOtpButtonDisabled(false);
				setTimeLeft(INTERVAL);
				countRef.current = INTERVAL;
				timerRef.current && clearInterval(timerRef.current);
			}
		}, 1000);
	};
	useEffect(() => {
		tick();
		return () => {
			timerRef.current && clearInterval(timerRef.current);
		};
	}, []);
	useImperativeHandle(ref, () => ({
		tick: () => {
			tick();
		}
	}));
	const handleClick = (e) => {
		tick();
		onClick?.(e);
	};

	return (<div className="this">
		<button
			type={buttonType}
			className="btn btn-outline-primary tick"
			disabled={isOtpButtonDisabled}
			onClick={buttonType === "button" ? handleClick : undefined}
		>
			{isOtpButtonDisabled && ` (${timeLeft} ${intl.formatMessage(CommonMessages.sec)})`}
			&nbsp;
			<i className="fas fa-sync-alt"></i>
		</button>
		{/* {isOtpButtonDisabled && <div className="progress">
			<div aria-valuemax={100} aria-valuemin={0} aria-valuenow={valuenow} role="progressbar" style={{width: `${valuenow}%`}} className="progress-bar progress-bar-striped progress-bar-animated bg-primary"></div>
		</div>} */}
	</div>

	);
};
const OtpRef = forwardRef<OtpRefType,OtpProps>(Otp);
export {
	OtpRef,
	useOtp
};
