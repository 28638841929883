import { useFormik } from "formik";
import React, { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CommonMessages } from "../../Common.messages";
import { config } from "../../config/constants";
import { Query } from "../../graphql/types";
import { FMCMessages } from "../../modules/fmc/FMC.messages";
import { UserMessages } from "../../modules/user/User.messages";
import { InputFieldFormik } from "./InputField";
import { errorActions, useStoreContext } from "./navigation/StepStateProvider";
import { useOtp } from "./Otp";

interface SearchMsisdnProps{
	handleResetSearch: () => void;
	disabled?: boolean;
	onCompleted: (msisdn: string, otp: string) => Promise<string|void|Required<Pick<Query, "otp_subscription">>>;
}
const SearchMsisdn: FC<SearchMsisdnProps> = (props) => {
	const { handleResetSearch, onCompleted} = props;
	const intl = useIntl();
	const {dispatch} = useStoreContext();
	const formik = useFormik({
		initialValues: { msisdn: ""},
		onSubmit: (values) => {
			dispatch(errorActions.cleanError());
			const { msisdn } = values;
			handleOtp(msisdn);
		},
		//validationSchema: searchSubscription(intl)
	});
	const {handleOtp, loading} = useOtp(formik.values.msisdn, onCompleted);
	const label = useMemo(() => intl.formatMessage({...CommonMessages.searchSubscription}), [intl.locale]);

	const resetForm = (e) => {
		dispatch(errorActions.cleanError());
		dispatch(errorActions.cleanMsisdnErrors());
		handleResetSearch();
		formik.handleReset(e);
	};
	return (
		<form onSubmit={formik.handleSubmit} className="form-row bg-light p-4 mb-5">
			<div className="form-group col-lg-4 col-12">
				<InputFieldFormik
					disabled={props.disabled}
					required
					name="msisdn"
					id="msisdn"
					attr={{maxLength: config.PHONE_LENGTH}}
					placeholder={label}
					value={formik.values.msisdn}
					formik={formik}
				/>
			</div>
			<div className="form-group col-lg-4 col-12">
				<button type="submit" disabled={loading || props.disabled} className="btn btn-block btn-primary">
					{loading ? <i className="w-spinner"></i> : <i className="fa fa-search"></i>} &nbsp;
					<FormattedMessage {...UserMessages.search} />
				</button>
			</div>
			<div className="form-group col-lg-4 col-12">
				<button type="button" disabled={loading} onClick={resetForm} className="btn btn-block btn-primary">
					<FormattedMessage {...FMCMessages.resetForm} />
				</button>
			</div>
		</form>
	);
};

export {
	SearchMsisdn
};