import Cookies from "js-cookie";
import moment from "moment";
import {BILLING_NEW_ACCOUNT, config, CSRFTOKEN} from "../../../config/constants";
import { UserDealerIdentity } from "../../../graphql/types";
import { DATE_FORMAT_DOC_GENERATION } from "../../utils/personalizationUtils";
import {B2BFttbRouteState} from "../../../modules/fttb/b2bFttb/Pages/b2bFttb.type";
import {CustomerRouteState} from "../../../modules/fttb/b2bFttb/Pages/b2bFttb.type";

export interface B2BFttbContractPayload {
    msisdn: string|undefined|null;
    confirmation_code: string|undefined;
    icc: string|undefined;
    first_name: string|undefined|null;
    last_name: string|undefined|null;
    middle_name: string|undefined|null;
    house_number: string|undefined|null;
    province: string|undefined|null;
    city: string|undefined|null;
    street: string|undefined|null;
    order_date: string|undefined|null;
    contact_phone_number: string|undefined|null;
    billing_account_number: string|undefined|null;
    isNewBA: boolean|undefined|null;
    other: string|undefined|null;
    repeat: number;
    signal: AbortSignal|undefined;
    is_company: boolean|undefined|null;
	rate_plan: string|undefined|null;
	id_number: string|undefined|null;
	id_type: string|undefined|null;
	company_name: string|undefined|null;
	subscription_fee: number|undefined;
	selected_package: string|undefined|null;
}
interface B2BFttbDocumetPayload {
    payload: B2BFttbContractPayload,
    currentDealerIdentety?: UserDealerIdentity | undefined;
    language: string;
}
class B2BFttbContractUtils {

	static extractCompanyDetails(company_id: string|undefined, companies:  [CustomerRouteState] | undefined ) {
		return companies?.find(company => company.id === company_id);
	}

	static formatPayload(state: B2BFttbRouteState, lang: string): B2BFttbContractPayload {
		const {fttbMsisdn, fttbMsisdnConfirmation_code, address,selectedFttbTariffPlan, company_id, companies} = state;
		const company: CustomerRouteState | undefined = B2BFttbContractUtils.extractCompanyDetails(company_id, companies);
		return {
			msisdn: fttbMsisdn,
			confirmation_code: fttbMsisdnConfirmation_code,
			icc: undefined,
			first_name: state?.first_name,
			last_name: state?.last_name,
			middle_name: state?.middle_name,
			house_number: address?.house,
			province: address?.province,
			city: address?.city,
			street: address?.street,
			order_date: moment().format(DATE_FORMAT_DOC_GENERATION),
			contact_phone_number: state?.contact_phone,
			billing_account_number: state?.account_id,
			isNewBA: (state?.billing_status === BILLING_NEW_ACCOUNT),
			other: undefined,
			repeat: 3,
			signal: undefined,
			is_company: true,
			rate_plan: selectedFttbTariffPlan?.code,
			subscription_fee: selectedFttbTariffPlan?.fee || 0,
			id_number: company?.id_document_number || "",
			id_type: company?.id_document_type || "",
			company_name: company?.first_name || "",
			selected_package: selectedFttbTariffPlan?.selectedOptionCode || ""
		};
	}

	static addSalesInfo({payload, currentDealerIdentety, language}: B2BFttbDocumetPayload) {
		return {
			...payload,
			shop_code: currentDealerIdentety?.reseller_code,
			sales_agent_full_name: currentDealerIdentety?.reseller_name,
			language,
		};
	}

	static getDocumentGetUrl (host: string, payload: B2BFttbDocumetPayload): string {
		const contractPayload = B2BFttbContractUtils.addSalesInfo(payload);

		const params = Object.keys(contractPayload).reduce((acc, cur) => {
			if (cur && cur !== "signal" && cur !== "repeat" && contractPayload[cur])
				return acc + `&${cur}=${contractPayload[cur]}`;
			else
				return acc;
		}, "");

		return `${window.location.protocol}//${host}/api/order-contract/document-download/\
			?order_type=is_fttb_b2b\
			${params}`;
	}

	static async fetchB2BFttbContractDocument (input : B2BFttbDocumetPayload): Promise<any> {
		const csrf = Cookies.get(CSRFTOKEN);
		const {payload} = input;
		return await fetch(B2BFttbContractUtils.getDocumentGetUrl(config.HOST, input), {
			signal: payload.signal,
			method: "GET",
			headers: {
				...(csrf && {"X-XSRF-TOKEN": csrf})
			},
			credentials: process.env.NODE_ENV === "development" ? "include" : "same-origin",
		});
	}

	static async getB2BFttbContractDocument (input: B2BFttbDocumetPayload): Promise<any> {
		const {payload: {repeat}} = input;

		try {
			const result = await B2BFttbContractUtils.fetchB2BFttbContractDocument(input);
			if (result.ok) {
				return await result.blob();
			} else if (result.status === 401 && repeat < config.REPEAT_REQUEST) {
				throw new Error("Unauthorized");
			} else {
				throw new Error("Generate document's link for download");
			}
		} catch (e) {
			throw new Error("Generate document's link for download");
		}
	}
}

export {
	B2BFttbContractUtils
};
