import React, { useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { MNPRouteState} from "../../MNPFlowRoutes";
import {Package} from "../../../../graphql/types";
import {Options} from "../../../../shared/components/option/Options";
import {StepWrapper} from "../../../../shared/components/navigation/StepWrapper";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";

const MnpOptions = (props: RouteComponentProps<never, StaticContext, MNPRouteState>) => {
	const state = useNavigationState<MNPRouteState>();
	const [optionalProduct, setOptionalProduct] = useState<Package[]>(state?.options || []);

	return (<StepWrapper<MNPRouteState> isValid newState={{ options: optionalProduct }}>
		<div className="ipos-content-box">
			<Options
				planId={state?.plan?.id}
				state={optionalProduct}
				setState={setOptionalProduct}
			/>
		</div>
	</StepWrapper>
	);
};

export { MnpOptions };