/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface UserMessagesType {
	alley: MessageDescriptor;
	avenue: MessageDescriptor;
	billing_account_number: MessageDescriptor;
	boulevard: MessageDescriptor;
	cert_of_a_stateless_person_to_travel_abroad: MessageDescriptor;
	cert_of_the_crew_member: MessageDescriptor;
	certificate_registration: MessageDescriptor;
	choose: MessageDescriptor;
	close: MessageDescriptor;
	companyType: MessageDescriptor;
	credit_decision: MessageDescriptor;
	customerExistInSystemProceedWithExistingCustomerFlow: MessageDescriptor;
	customerFound: MessageDescriptor;
	customerNotFind: MessageDescriptor;
	customerNotFound: MessageDescriptor;
	diplomatic_passport_of_ukraine: MessageDescriptor;
	documentNumber: MessageDescriptor;
	documentType: MessageDescriptor;
	driving_license: MessageDescriptor;
	embankment: MessageDescriptor;
	english: MessageDescriptor;
	enterIdDocumentDetails: MessageDescriptor;
	female: MessageDescriptor;
	findCustomer: MessageDescriptor;
	foreign_passport: MessageDescriptor;
	highway: MessageDescriptor;
	idDocumentNumber: MessageDescriptor;
	id_card: MessageDescriptor;
	id_card_for_return_to_ukraine: MessageDescriptor;
	id_card_that_needs_additional_protection: MessageDescriptor;
	individualPerson: MessageDescriptor;
	kyrgyz: MessageDescriptor;
	lane: MessageDescriptor;
	male: MessageDescriptor;
	migrant_card: MessageDescriptor;
	military_ticket: MessageDescriptor;
	newCustomer: MessageDescriptor;
	officer_certificate: MessageDescriptor;
	passport: MessageDescriptor;
	passport_doc_of_another_country: MessageDescriptor;
	passport_kg_external: MessageDescriptor;
	pension_certificate: MessageDescriptor;
	permanent_residence_permit: MessageDescriptor;
	phone_number: MessageDescriptor;
	refugee_certificate: MessageDescriptor;
	refugee_travel_document: MessageDescriptor;
	residence_permit: MessageDescriptor;
	road: MessageDescriptor;
	russian: MessageDescriptor;
	seafarers_identity_card: MessageDescriptor;
	search: MessageDescriptor;
	selectCustomer: MessageDescriptor;
	selectDocumentType: MessageDescriptor;
	selectTypeCustomer: MessageDescriptor;
	service_passport_of_ukraine: MessageDescriptor;
	square: MessageDescriptor;
	street: MessageDescriptor;
	tax_id: MessageDescriptor;
	temp_cert_of_a_citizen_of_ukraine: MessageDescriptor;
	travel_doc_of_a_person_who_had_been_granted_additional_protection: MessageDescriptor;
	ukrainian: MessageDescriptor;
	ukrainian_foreign_passport: MessageDescriptor;
	ukrainian_passport: MessageDescriptor;
}
const UserMessages: UserMessagesType = defineMessages({
	alley: {
		id: "street-type-alley",
		description: "Street type dropdown alley",
		defaultMessage: "Alley"
	},
	avenue: {
		id: "street-type-avenue",
		description: "Street type dropdown avenue",
		defaultMessage: "Avenue"
	},
	billing_account_number: {
		id: "billing-account-numberr",
		description: "Street type dropdown avenue",
		defaultMessage: "Billing Account Number"
	},
	boulevard: {
		id: "street-type-boulevard",
		description: "Street type dropdown boulevard",
		defaultMessage: "Boulevard"
	},
	cert_of_a_stateless_person_to_travel_abroad: {
		id: "user-type-of-doc-permanent_residence_permit",
		description: "Label for list of type's documents",
		defaultMessage: "Certificate of a stateless person to travel abroad"
	},
	cert_of_the_crew_member: {
		id: "user-type-of-doc-id_card_for_return_to_ukraine",
		description: "Label for list of type's documents",
		defaultMessage: "Certificate of the crew member"
	},
	certificate_registration: {
		id: "user-exist-document-type-certificate-registration",
		description: "Document Type label Certificate of registration of the application",
		defaultMessage: "Certificate of registration of the application"
	},
	choose: {
		id: "user-exist-choose",
		description: "Document type place holder",
		defaultMessage: "Please choose"
	},
	close: {
		id: "street-type-close",
		description: "Street type dropdown close",
		defaultMessage: "Close"
	},
	companyType: {
		id: "company-legal-person",
		description: "User type company",
		defaultMessage: "Company/legal person"
	},
	credit_decision: {
		id: "credit-decision-customer",
		description: "Street type dropdown avenue",
		defaultMessage: "Error with checking user by credit decision"
	},
	customerExistInSystemProceedWithExistingCustomerFlow: {
		id: "customer-exist-in-system-proceed-with-existing-customer-flow",
		description: "Street type dropdown avenue",
		defaultMessage: "Customer with ID document already exists in system, please proceed with Existing customer flow. "
	},
	customerFound: {
		id: "user-exist-customer-found",
		description: "Message for found customer",
		defaultMessage: "Existing customer found!"
	},
	customerNotFind: {
		id: "user-exist-customer-not-find",
		description: "Error message for Not found customer",
		defaultMessage: "Customer is not found"
	},
	customerNotFound: {
		id: "user-exist-customer-not-found",
		description: "Error message for Not found customer",
		defaultMessage: "No existing customer found for this ID document."
	},
	diplomatic_passport_of_ukraine: {
		id: "user-type-of-doc-service_passport_of_ukraine",
		description: "Label for list of type's documents",
		defaultMessage: "Diplomatic passport of Ukraine"
	},
	documentNumber: {
		id: "user-document-number",
		description: "Document number label/placeholder",
		defaultMessage: "Document Number"
	},
	documentType: {
		id: "user-document-type",
		description: "Document Type label/placeholder",
		defaultMessage: "Document Type"
	},
	driving_license: {
		id: "user-exist-document-type-driving-license",
		description: "Document Type Driving License Ticket",
		defaultMessage: "Driver's license"
	},
	embankment: {
		id: "street-type-embankment",
		description: "Street type dropdown embankment",
		defaultMessage: "Embankment"
	},
	english: {
		id: "languages-dropdown-english",
		description: "Prefered language dropdown",
		defaultMessage: "English"
	},
	enterIdDocumentDetails: {
		id: "user-enter-id-document-details",
		description: "Id Document number label/placeholder",
		defaultMessage: "Please enter ID Document details"
	},
	female: {
		id: "user-gender-female",
		description: "gender dropdown female",
		defaultMessage: "Female"
	},
	findCustomer: {
		id: "transfer-balance-dialog-amount-find-customer",
		description: "Existing User Find customer header",
		defaultMessage: "Find customer"
	},
	foreign_passport: {
		id: "user-exist-document-type-foreign-passport",
		description: "Document Type label Passport of non-KG",
		defaultMessage: "Passport of non-KG"
	},
	highway: {
		id: "street-type-highway",
		description: "Street type dropdown highway",
		defaultMessage: "Highway"
	},
	idDocumentNumber: {
		id: "user-document-id",
		description: "Id Document number label/placeholder",
		defaultMessage: "ID Document number"
	},
	id_card: {
		id: "user-type-of-doc-ukrainian_foreign_passport",
		description: "Label for list of type's documents",
		defaultMessage: "ID card"
	},
	id_card_for_return_to_ukraine: {
		id: "user-type-of-doc-temp_cert_of_a_citizen_of_ukraine",
		description: "Label for list of type's documents",
		defaultMessage: "ID card for return to Ukraine"
	},
	id_card_that_needs_additional_protection: {
		id: "user-type-of-id-card-doc-travel_doc_of_a_person_who_had_been_granted_additional_protection",
		description: "Label for list of type's documents",
		defaultMessage: "ID card that needs additional protection"
	},
	individualPerson: {
		id: "individual-person",
		description: "User type individual",
		defaultMessage: "Individual person"
	},
	kyrgyz: {
		id: "languages-dropdown-kyrgyz",
		description: "Prefered language dropdown",
		defaultMessage: "Kyrgyz"
	},
	lane: {
		id: "street-type-lane",
		description: "Street type dropdown lane",
		defaultMessage: "Lane"
	},
	male: {
		id: "user-gender-male",
		description: "gender dropdown male",
		defaultMessage: "Male"
	},
	migrant_card: {
		id: "user-type-of-doc-refugee_certificate",
		description: "Label for list of type's documents",
		defaultMessage: "Migrant card"
	},
	military_ticket: {
		id: "user-exist-document-type-military-ticket",
		description: "Document Type Military Ticket",
		defaultMessage: "Military Ticket"
	},
	newCustomer: {
		id: "user-exist-create-new-customer",
		description: "Button's label for create customer",
		defaultMessage: "Create new customer"
	},
	officer_certificate: {
		id: "user-exist-document-type-officer-certificate",
		description: "Document Type Officer Certificate Ticket",
		defaultMessage: "Officer Certificate"
	},
	passport: {
		id: "user-exist-document-type-passport",
		description: "Document Type Passport Ticket",
		defaultMessage: "Passport"
	},
	passport_doc_of_another_country: {
		id: "user-type-of-doc-passport_doc_of_another_country",
		description: "Label for list of type's documents",
		defaultMessage: " Passport document of another country"
	},
	passport_kg_external: {
		id: "user-exist-document-type-passport-external",
		description: "Document Type Passport Kg Ticket",
		defaultMessage: "Passport of KG (External)"
	},
	pension_certificate: {
		id: "user-exist-document-type-pension-certificate",
		description: "Document Type Pension Ticket",
		defaultMessage: "Pension Certificate"
	},
	permanent_residence_permit: {
		id: "user-type-of-doc-residence_permit",
		description: "Label for list of type's documents",
		defaultMessage: "Permanent residence permit"
	},
	phone_number: {
		id: "phone-number",
		description: "Street type dropdown avenue",
		defaultMessage: "Phone number"
	},
	refugee_certificate: {
		id: "user-type-of-doc-refugee_travel_document",
		description: "Label for list of type's documents",
		defaultMessage: "Refugee certificate"
	},
	refugee_travel_document: {
		id: "user-type-of-doc-id_card_that_needs_additional_protection",
		description: "Label for list of type's documents",
		defaultMessage: "Refugee travel document"
	},
	residence_permit: {
		id: "user-type-of-doc-migrant_card",
		description: "Label for list of type's documents",
		defaultMessage: "Residence permit"
	},
	road: {
		id: "street-type-road",
		description: "Street type dropdown road",
		defaultMessage: "Road"
	},
	russian: {
		id: "languages-dropdown-russian",
		description: "Prefered language dropdown",
		defaultMessage: "Russian"
	},
	seafarers_identity_card: {
		id: "user-type-of-doc-cert_of_the_crew_member",
		description: "Label for list of type's documents",
		defaultMessage: "Seafarer's identity card"
	},
	search: {
		id: "user-exist-search",
		description: "Existing User Document search button",
		defaultMessage: "Search"
	},
	selectCustomer: {
		id: "user-exist-customer-select-customer",
		description: "Button's label for select customer",
		defaultMessage: "Select this customer"
	},
	selectDocumentType: {
		id: "select_document_type",
		description: "Helper message",
		defaultMessage: "Please, select Document type"
	},
	selectTypeCustomer: {
		id: "user-exist-select-type-customer",
		description: "select type customer link",
		defaultMessage: "Subscription"
	},
	service_passport_of_ukraine: {
		id: "user-type-of-doc-seafarers_identity_card",
		description: "Label for list of type's documents",
		defaultMessage: "Service passports of Ukraine"
	},
	square: {
		id: "street-type-square",
		description: "Street type dropdown square",
		defaultMessage: "Square"
	},
	street: {
		id: "street-type-street",
		description: "Street type dropdown street",
		defaultMessage: "Street"
	},
	tax_id: {
		id: "tax-id",
		description: "Street type dropdown avenue",
		defaultMessage: "TAX ID (INN/ITN)"
	},
	temp_cert_of_a_citizen_of_ukraine: {
		id: "user-type-of-doc-cert_of_a_stateless_person_to_travel_abroad",
		description: "Label for list of type's documents",
		defaultMessage: "Temporary certificate of a citizen of Ukraine"
	},
	travel_doc_of_a_person_who_had_been_granted_additional_protection: {
		id: "user-type-of-doc-travel_doc_of_a_person_who_had_been_granted_additional_protection",
		description: "Label for list of type's documents",
		defaultMessage: "Travel document of a person who has been granted additional protection"
	},
	ukrainian: {
		id: "languages-dropdown-ukrainian",
		description: "Preferred language dropdown ukrainian",
		defaultMessage: "Ukrainian"
	},
	ukrainian_foreign_passport: {
		id: "user-type-of-doc-diplomatic_passport_of_ukraine",
		description: "Label for list of type's documents",
		defaultMessage: "Ukraine Foreign passport"
	},
	ukrainian_passport: {
		id: "user-type-of-doc-id_card",
		description: "Label for list of type's documents",
		defaultMessage: "Ukrainian Passport"
	},
});

export default UserMessages;
export { UserMessages };
