import React, { useMemo, useState, SetStateAction, Dispatch, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FTTBMessages } from "../../../FTTB.messages";
import { FttbAddressEnum } from "../../../../../shared/types/FttbEnum";
import { useGisSystems } from "../../../useGisSystems";
import { AddressStateProps } from "../AddressTypes";
import { InputFieldFormik } from "../../../../../shared/components/InputField";
import { FormikPropsGeneric } from "../../../../../shared/types";
import { TypeaheadFormik } from "../../../../../shared/components/formik/TypeaheadFormik";
import { AsyncTypeaheadFormik } from "../../../../../shared/components/formik/AsyncTypeaheadFormik";
import { QueryLazyOptions, useMutation } from "@apollo/react-hooks";
import {debounce, zip} from "lodash";
import {
	Mutation,
	MutationFttb_Add_Address_To_Waiting_ListArgs,
	QueryGis_House_Previous_Activation_StatusArgs,
	GisCity,
	GisStreet,
	GisHouse,
	GisRegion,
	GisDistrict,
	CmsChannelEnum,
	QueryGis_FlatsArgs, GisFlat,
} from "../../../../../graphql/types";
import { FTTB_ADD_ADDRESS_TO_WAITING_LIST } from "../../../../../graphql/queries/cms";
import { FttbAddressForm } from "../../../fttb.types";
interface AddressValidationFormProps {
    setAvailableHouse: React.Dispatch<React.SetStateAction<boolean | null>>;
    hasAvailableHouse: null | boolean;
    setPreviousHouseActivationStatus: Dispatch<SetStateAction<boolean>>;
    validatePreviousActivationStatus: (options: QueryLazyOptions<QueryGis_House_Previous_Activation_StatusArgs>) => void;
    formik: FormikPropsGeneric<FttbAddressForm>;
	setGigabitAvailable?: Dispatch<SetStateAction<boolean>> | undefined;
	checkGigabitEligibility?: boolean | null;
	setNetworktype?: React.Dispatch<React.SetStateAction<string>> | undefined;	isFttbB2b?: boolean | null;
}

const initState = { province: [], city: [], street: [], house_number: [], flat: []};
const AddressValidationForm = (props: AddressValidationFormProps) => {
	const intl = useIntl();
	const {values, setFieldValue} = props.formik;
	const [address, setAddress] = useState<AddressStateProps>(initState);
	const [fttbAddAddressToWaitingList] = useMutation<Required<Pick<Mutation, "fttb_add_address_to_waiting_list">>, MutationFttb_Add_Address_To_Waiting_ListArgs>(FTTB_ADD_ADDRESS_TO_WAITING_LIST, {
		fetchPolicy: "no-cache",
	});

	const getWaitinListDetails = ({house_id, apartment, first_name, contact_phone, last_name, middle_name}: {
        house_id: string;
        apartment: string;
        first_name: string;
        contact_phone: string;
        last_name: string;
        middle_name: string;
    }): MutationFttb_Add_Address_To_Waiting_ListArgs => ({
		details : {
			channel: CmsChannelEnum.Dealer,//TODO UKR-9431
			customer_name: first_name,
			customer_contact_phone: contact_phone,
			building_gis_id: parseInt(house_id),
			apartment_number: apartment!,
			customer_surname: last_name,
			customer_middle_name: middle_name,
			rate_plan: {
				id: 199,
				name: "Home Internet Kyivstar All along_Basic",
			},
		}});
	const {first_name, last_name, middle_name, gisHouseId, flat, contact_phone} = props.formik.values;
	const [isLoading, gisState, updateGisSystem, fetchDataFromGisSystem] = useGisSystems(undefined);

	const addToWaitingList = useCallback(debounce(({house_id, apartment, first_name, contact_phone, last_name, middle_name, fttb_available}: {
        house_id: string|null|undefined;
        first_name: string|null|undefined;
        contact_phone: string|null|undefined;
        last_name: string|null|undefined;
        middle_name: string|null|undefined;
        apartment: string;
        fttb_available: boolean|null|undefined;

    }) => {
		if (house_id && apartment && contact_phone && first_name && last_name && middle_name) {
			if (!fttb_available) {
				fttbAddAddressToWaitingList({variables: getWaitinListDetails({
					house_id,
					apartment,
					first_name,
					contact_phone,
					last_name,
					middle_name
				})});
			}
		}
	}, 500), []);
	const validatePreviousActivationStatus = useCallback(debounce((house_id: string|null|undefined, apartment: string, fttb_available: boolean|null|undefined) => {
		if (fttb_available && house_id && apartment) {
			props.validatePreviousActivationStatus({variables: {house_id, apartment}});
		}
	}, 500), []);

	useEffect(() => {
		validatePreviousActivationStatus(gisHouseId, flat, props.hasAvailableHouse);
	}, [gisHouseId, flat, props.hasAvailableHouse]);

	useEffect(() => {
		addToWaitingList({house_id: gisHouseId,contact_phone, apartment: flat, fttb_available: props.hasAvailableHouse, first_name, last_name, middle_name});
	}, [values.contact_phone, props.hasAvailableHouse, first_name, last_name, middle_name, gisHouseId, flat]);

	const eligibleForConnection = (fttb_available, network_type_id) => {
		if (fttb_available) {
			if (network_type_id === "FTTB" || network_type_id === "GPON") {
				props.setAvailableHouse(true);
				if (props.setNetworktype) {
					props.setNetworktype(network_type_id);
				}
			} else {
				props.setAvailableHouse(false);
			}
		} else {
			props.setAvailableHouse(false);
		}

	}

	const onChange = (type: keyof typeof FttbAddressEnum) => (selected: GisCity[]|GisStreet[]|GisHouse[]|GisRegion[]|GisDistrict[]) => {
		if(type !== FttbAddressEnum.flat){
			props.setAvailableHouse(null);
		}
		if (type === FttbAddressEnum.province) {
			if (selected.length === 0) {
				setAddress(initState);
				setFieldValue("province", "");
				setFieldValue("gisProvinceId", "");
			} else {
				setAddress({ ...initState, province: selected});
				setFieldValue("province", (selected[0]as GisRegion).name);
				setFieldValue("gisProvinceId", selected[0].id);
			}
		} else if (type === FttbAddressEnum.city) {
			if (selected.length === 0) {
				setAddress({...address, city: [],  street: [], house_number: [], flat: []} );
				setFieldValue("city", "");
				setFieldValue("gisCityId", "");
			} else {
				setAddress({ ...initState, city: selected});
				setFieldValue("city", (selected[0] as GisCity).name);
				setFieldValue("gisCityId", selected[0].id);
			}
		} else if (type === FttbAddressEnum.street) {
			if (selected.length === 0) {
				setAddress({ ...address, street: [], house_number: [], flat: []});
				setFieldValue("street", "");
				setFieldValue("flat", "");
				setFieldValue("gisStreetId", "");
			} else {
				const {available_houses}= (selected[0] as GisStreet);
				updateGisSystem(FttbAddressEnum.house_number, available_houses);
				setFieldValue("street", (selected[0] as GisStreet).name);
				setFieldValue("gisStreetId", selected[0].id);
				setAddress({ ...address, street: selected, house_number: []});
			}
		} else if (type === FttbAddressEnum.house_number) {
			setFieldValue("flat", "");
			setAddress({ ...address,flat: []});
			if (selected.length > 0) {
				const {fttb_available, network_type_id} = selected[0] as GisHouse;
				if (props.isFttbB2b) {
					props.setAvailableHouse(Boolean(fttb_available));
				} else {
					eligibleForConnection(fttb_available, network_type_id)
				}

				if (selected.length === 0) {
					setAddress({ ...address, house_number: []});
					props.setPreviousHouseActivationStatus(false);
					setFieldValue("house_number", "");
					setFieldValue("gisHouseId", "");
					setFieldValue("gisZipId", "");
					props.setAvailableHouse(null);
				} else {
					setAddress({ ...address, house_number: selected});
					setFieldValue("house_number", (selected[0] as GisHouse).house_number);
					setFieldValue("gisHouseId", selected[0].id);
					setFieldValue("gisZipId", (selected[0] as GisHouse).zip_id);
				}
			}
		}else if (type === FttbAddressEnum.flat) {
			if (selected.length > 0) {
				if (selected.length === 0) {
					setAddress({ ...address, flat: []});
					setFieldValue("flat", "");
					setFieldValue("flatId", "");
				} else {
					setAddress({ ...address, flat: selected});
					setFieldValue("flat", (selected[0] as GisFlat).flat_number);
					if(props.setGigabitAvailable){
						props.setGigabitAvailable(Boolean((selected[0] as GisFlat).is_1g_available));
					}
				}
			}
		}
	};
	const handleSearch = (type, typeSearchBy?: string) => (query) => {
		if (FttbAddressEnum.flat === type) {
			fetchDataFromGisSystem(type, query, typeSearchBy ? address?.[typeSearchBy]?.[0]?.id ? address?.[typeSearchBy]?.[0]?.id : gisHouseId : undefined);
		}
		else fetchDataFromGisSystem(type, query, typeSearchBy ? address?.[typeSearchBy]?.[0]?.id : undefined);
	};

	const placeholders = useMemo(() => {
		return {
			city: intl.formatMessage({...FTTBMessages.selectCity}),
			province: intl.formatMessage({...FTTBMessages.selectProvince}),
			street: intl.formatMessage({...FTTBMessages.selectStreet}),
			house_number: intl.formatMessage({...FTTBMessages.selectHouse}),
			flat: intl.formatMessage({...FTTBMessages.selectFlat}),
		};
	}, [intl.locale]);

	const onFlatChange = (fieldName, setFieldValue) =>(e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue(fieldName, e.target.value.trim());
	};
	const onHouoseInputChange = debounce((value) => {
		const selected: GisHouse|undefined = gisState.house_number?.find(item => item.house_number === value)
		props.setAvailableHouse(Boolean(selected?.fttb_available));
		if (selected) {
			setAddress({ ...address, house_number: [selected]});
			setFieldValue("house_number", selected.house_number);
			setFieldValue("gisHouseId", selected.id);
			setFieldValue("gisZipId", selected.zip_id);
		} else {
			setAddress({ ...address, house_number: []});
			props.setPreviousHouseActivationStatus(false);
			setFieldValue("house_number", "");
			setFieldValue("gisHouseId", "");
			setFieldValue("gisZipId", "");
		}

	}, 1000);

	return (
		<>
			<div className="head">
				<p><label className="w-required-field"><FormattedMessage {...FTTBMessages.addressToConnection} /></label></p>
			</div>
			<div>
				<TypeaheadFormik
					id="province"
					name={FttbAddressEnum.province}
					formik={props.formik}
					defaultSelected={[values.province]}
					className={"mt-2 address-spinner"}
					isLoading={isLoading.province}
					labelKey={"name"}
					onFocus={handleSearch(FttbAddressEnum.province)}
					onChange={onChange(FttbAddressEnum.province)}
					options={gisState?.province}
					placeholder={placeholders.province}
					renderMenuItemChildren={(option) => <span>{option.name}</span>}
				/>
				<AsyncTypeaheadFormik
					id="cities"
					disabled={!values?.province}
					name={FttbAddressEnum.city}
					formik={props.formik}
					defaultSelected={[values.city]}
					className={"mt-2 address-spinner"}
					isLoading={isLoading.city}
					labelKey={"name"}
					useCallBackRelationValue={address}
					multiple={false}
					onSearch={handleSearch(FttbAddressEnum.city,  FttbAddressEnum.province)}
					onChange={onChange(FttbAddressEnum.city)}
					options={gisState.city}
					placeholder={placeholders.city}
					minLength={2}
					renderMenuItemChildren={(option, props) => <><span>{option.name}</span><span>{props.name}</span></>}
				/>
				<AsyncTypeaheadFormik
					id="streets"
					name={FttbAddressEnum.street}
					formik={props.formik}
					className={"mt-2 address-spinner"}
					isLoading={isLoading.street}
					minLength={3}
					labelKey={"name"}
					multiple={false}
					useCallBackRelationValue={address}
					defaultSelected={[values[FttbAddressEnum.street]]}
					onSearch={handleSearch(FttbAddressEnum.street, FttbAddressEnum.city)}
					onChange={onChange(FttbAddressEnum.street)}
					options={gisState.street}
					disabled={!values?.city}
					placeholder={placeholders.street}
					renderMenuItemChildren={(option) => <div>{option.name}</div>}
				/>
				<TypeaheadFormik
					formik={props.formik}
					id="houses"
					//onInputChange={onHouoseInputChange}
					name={FttbAddressEnum.house_number}
					className={"mt-2"}
					labelKey={FttbAddressEnum.house_number}
					onChange={onChange(FttbAddressEnum.house_number)}
					defaultSelected={[values[FttbAddressEnum.house_number]]}
					options={gisState.house_number || []}
					disabled={!values.street}
					placeholder={placeholders.house_number}
					renderMenuItemChildren={(option) => <div>{option.house_number}</div>}
				/>
				<div className="mt-2">
					{props.checkGigabitEligibility ?
						<AsyncTypeaheadFormik
							id="flat"
							name={FttbAddressEnum.flat}
							formik={props.formik}
							className={"mt-2 address-spinner"}
							isLoading={isLoading.flat}
							minLength={1}
							labelKey={"flat_number"}
							multiple={false}
							useCallBackRelationValue={address}
							defaultSelected={[values[FttbAddressEnum.flat]]}
							onSearch={handleSearch(FttbAddressEnum.flat, FttbAddressEnum.house_number)}
							onChange={onChange(FttbAddressEnum.flat)}
							options={gisState.flat || []}
							disabled={!values?.house_number?.length || !values.street}
							placeholder={placeholders.flat}
							renderMenuItemChildren={(option) => <div>{option.flat_number}</div>}
							allowNew={true}
						/> :
						<InputFieldFormik
							name="flat"
							id="flat"
							required
							onChange={onFlatChange}
							placeholder={placeholders.flat}
							formik={props.formik}
							disabled={!values?.house_number?.length}
							value={values.flat}
						/>}
				</div>
			</div>
		</>
	);
};

export { AddressValidationForm };
