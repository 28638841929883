import React, { FC, useEffect } from "react";
import { Dropdown, ButtonProps } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { STORAGE_KEY_CURRENT_DEALER_IDENTITY } from "../../config/constants";
import CommonMessages from "../../Common.messages";
import { useAuthDataContext } from "../hooks/AuthentificationProvider";
import { Storage } from "../utils/storageUtils";
import { useMutation } from "@apollo/react-hooks";
import { LocalMutation } from "../../graphql/localTypes";
import { SET_DEALER_IDENTITY } from "../../graphql/mutations/localMutation";
import { UserDealerIdentity } from "../../graphql/types";


const DealerIdentities: FC<{variant?: ButtonProps["variant"]}> = props => {
	const {dealerIdentities, loading} = useAuthDataContext();
	const [setCurrentDealerIdentety] = useMutation<LocalMutation, {dealerIdentity: UserDealerIdentity}>(SET_DEALER_IDENTITY);
	useEffect(() => {
		if (dealerIdentities?.[0] && dealerIdentities?.length === 1) {
			const selectedIdentity = dealerIdentities[0];
			setCurrentDealerIdentety({variables: {dealerIdentity: selectedIdentity}}).then(() => {
				Storage.local.set(STORAGE_KEY_CURRENT_DEALER_IDENTITY, selectedIdentity);
			});
		}
	}, []);

	const handleSelect = (code: string) => {
		const selectedIdentity = dealerIdentities?.find(identity => identity?.reseller_code === code);
		if (setCurrentDealerIdentety && selectedIdentity) {
			setCurrentDealerIdentety({variables: {dealerIdentity: selectedIdentity}}).then(() => {
				Storage.local.set(STORAGE_KEY_CURRENT_DEALER_IDENTITY, selectedIdentity);
			});
		}
	};

	return (
		<Dropdown onSelect={handleSelect}>
			<Dropdown.Toggle variant={props.variant} className={`btn ${props.variant ? "" : "btn-context"} dropdown-toggle`} id="dropdown-identities">
				{loading ?  <i className="w-spinner"></i> : <FormattedMessage {...CommonMessages.changeIdentity} />}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				
				{dealerIdentities?.map((identity) => (
					<Dropdown.Item key={identity?.reseller_code!} className="dropdown-item" eventKey={identity?.reseller_code!}>
						{identity?.reseller_name}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export {
	DealerIdentities
};
