import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Field } from "formik";
import {InputField, SelectField} from "../../../../../../shared/components";
import { initialLabels } from "../../../../../../shared/utils";
import { RegistrationMessages } from "../../../../../registration/Registration.messages";
import {idDocumentType} from "../../../../../../shared/utils/common_mock_data";
import {UserMessages} from "../../../../../user/User.messages";

interface CompanyFormProps {
    setFieldValue: (field: string, value: any) => void;
    values: any;
    initialValues: any;
}

interface InitialValuesType {
    email: string;
    id_document_type: string;
    id_document_number: string;
    name: string;
}

const CompanyForm = (props: CompanyFormProps) => {
	const intl = useIntl();
	const labels = useMemo(() => initialLabels<InitialValuesType>(intl, props.initialValues, RegistrationMessages), [intl.locale]);
	const placeholder = useMemo(() => intl.formatMessage({...UserMessages.choose}), [intl.locale]);
	return (
		<div>
			<div className="row">
				<div className="col-12 col-sm-6">
					<Field
						required
						name="id_document_type"
						id="id_document_type"
						label={labels.id_document_type}
						values={idDocumentType(intl)}
						placeholder={placeholder}
						component={SelectField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						required
						name="id_document_number"
						id="id-document-number"
						label={labels.id_document_number}
						attr={{maxLength: 10}}
						component={InputField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						required
						name="name"
						id="name"
						label={labels.name}
						attr={{maxLength: 256}}
						component={InputField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						required
						name="email"
						id="email"
						label={labels.email}
						attr={{maxLength: 256}}
						component={InputField}
					/>
				</div>
			</div>
		</div>
	);
};

export {CompanyForm };