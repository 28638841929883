import { useMutation } from "@apollo/react-hooks";
import { HANDLE_MODAL } from "../../graphql/mutations";
import { LocalMutation, MutationModalArgs } from "../../graphql/localTypes";

const useModal = () => {
	const [showModalStatus] = useMutation<LocalMutation, MutationModalArgs>(HANDLE_MODAL);
	return (message: string, status: boolean, title?: string) => {
		showModalStatus({variables: { message, status, title }});
	};
};

export { useModal };