import { useQuery } from "@apollo/react-hooks";
import { LocalQuery } from "../../graphql/localTypes";
import { GET_AUTH } from "../../graphql/queries/localQuery";
import { useMemo } from "react";
import { ApolloQueryResult } from "apollo-boost";

//Do not use except AuthenticationProvider data not updated properly
const useAuth: () => ({isAuthenticated: boolean, refetch: () => Promise<ApolloQueryResult<Pick<LocalQuery, "auth">>>}) = () => {
	const { data, refetch, } = useQuery<Pick<LocalQuery, "auth">>(GET_AUTH);
	return useMemo(() => ({ isAuthenticated: Boolean(data?.auth?.isAuthenticated), refetch}), [data]);
};

export {
	useAuth
};