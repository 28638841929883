/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface PackagesMessagesType {
	activate: MessageDescriptor;
	active: MessageDescriptor;
	activePackages: MessageDescriptor;
	activePackagesEmpty: MessageDescriptor;
	availablePackages: MessageDescriptor;
	deactivate: MessageDescriptor;
	oneTimeDelimiter: MessageDescriptor;
	recurrentDelimiter: MessageDescriptor;
	removeBundleFromSubscription: MessageDescriptor;
}
const PackagesMessages: PackagesMessagesType = defineMessages({
	activate: {
		id: "packages-activate",
		description: "Activate button",
		defaultMessage: "Activate"
	},
	active: {
		id: "packages-active",
		description: "Activate button",
		defaultMessage: "Active"
	},
	activePackages: {
		id: "active-packages",
		description: "Active packages title",
		defaultMessage: "Active packages"
	},
	activePackagesEmpty: {
		id: "active-packages-empty",
		description: "Active packages empty",
		defaultMessage: "No active packages"
	},
	availablePackages: {
		id: "available-packages",
		description: "Available packages title",
		defaultMessage: "Available packages"
	},
	deactivate: {
		id: "packages-deactivate",
		description: "Deactivate button",
		defaultMessage: "Deactivate"
	},
	oneTimeDelimiter: {
		id: "package-price-one-time-delimiter",
		description: "Package price one time delimiter",
		defaultMessage: "for"
	},
	recurrentDelimiter: {
		id: "package-price-recurrent-delimiter",
		description: "Package price recurrent delimiter",
		defaultMessage: "/"
	},
	removeBundleFromSubscription: {
		id: "dialog-remove-bundle-title",
		description: "Dialog title Remove package from subscription?",
		defaultMessage: "Remove package from subscription?"
	},
});

export default PackagesMessages;
export { PackagesMessages };
