import React, { FC, useMemo, PropsWithChildren, ReactElement } from "react";
import { hasRole } from "../utils/utils";
import { useAuthDataContext } from "../hooks/AuthentificationProvider";

interface HasPermissionProps {
    accessFor: string[];
}

const HasPermission: FC<PropsWithChildren<HasPermissionProps>> = props => {
	const {userPermissions} = useAuthDataContext();
	const hasPermission = useMemo(() => hasRole(userPermissions, props.accessFor), [userPermissions]);

	if (hasPermission) {
		return props.children as ReactElement<any>;
	} else {
		return null;
	}
};

export { HasPermission };