import gql from "graphql-tag";

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
        error,
    }
  }
`;
const REFRESH_AUTH_TOKEN = gql`
  mutation RefreshAuthToken($refresh_token: String!) {
      refresh_token(refresh_token: $refresh_token) {
        token,
        refresh_token,
        expires_in,
    }
  }
`;
const ENCRYPT_COOKIE = gql`
  mutation EncryptCookie($auth_token: TokenInput!) {
    encrypt_cookie(auth_token: $auth_token)
  }
`;
const DECRYPT_COOKIE = gql`
  mutation DecryptCookie($auth_cookie: String) {
      decrypt_cookie(auth_cookie: $auth_cookie) {
        token,
        refresh_token,
        expires_in,
        error,
      }
  }
`;
const LOGOUT = gql`
  mutation Logout {
      logout {
          request_id,
          status,
          error,
    }
  }
`;
const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $domain: String!) {
      reset_password(email: $email, domain: $domain) {
          request_id,
          status,
          error,
    }
  }
`;
const RESET_PASSWORD_CONFIRMATION = gql`
  mutation ResetPasswordConfirmation($username: String!, $timestamp: String!, $hash: String!, $new_password: String) {
      reset_password_confirmation(username: $username, timestamp: $timestamp, hash: $hash, new_password: $new_password) {
          request_id,
          status,
          error,
    }
  }
`;
const REGULAR_CHANGE_PASSWORD = gql`
  mutation ChangePassword($username: String!, $old_password: String!, $new_password: String!) {
      change_password(username: $username, old_password: $old_password, new_password: $new_password) {
          request_id,
          status,
          error,
    }
  }
`;

export {
	LOGIN,
	LOGOUT,
	REFRESH_AUTH_TOKEN,
	RESET_PASSWORD,
	RESET_PASSWORD_CONFIRMATION,
	REGULAR_CHANGE_PASSWORD,
	ENCRYPT_COOKIE,
	DECRYPT_COOKIE,
};
