import { useQuery } from "@apollo/react-hooks";
import { GET_SPINNER_STATUS } from "../../graphql/queries";
import { CircularProgress } from "./CircularProgress";
import { Modal } from "react-bootstrap";
import cssns from "../../shared/utils/cssnsConfig";
const { React } = cssns("Spinner-iPos");

const LoadingSpinner = () => {
	const { data } = useQuery(GET_SPINNER_STATUS);
	const open = data ? data.spinner.status : false;
	return (
		<div>
			<Modal className="paper" show={open}>
				<CircularProgress />
			</Modal>
		</div>
	);
};

export { LoadingSpinner };
