
import gql from "graphql-tag";

const GIS_HOUSE_PREVIOUS_ACTIVATION_STATUS = gql`
    query GisHousePreviousActivationStatus($house_id: ID!, $apartment: String) {
        gis_house_previous_activation_status(house_id: $house_id, apartment: $apartment) {
            previous_activation_status,
            msisdn_with_max_last_rc_date
        }
    }
`;

const CHECK_GIGABIT_ELIGIBILITY = gql `
    query CheckGigabitEligibility($flat_number: String, $zip_code: String){
        gis_flats(flat_number: $flat_number,zip_code:$zip_code){
            is_1g_available
    }  
}
`;

const CRAMER_CHECK = gql `
    query CramerCheck($msisdn: String!){
        fttb_gigabit_status(msisdn: $msisdn){
            status
    }
}
`;

export {
	GIS_HOUSE_PREVIOUS_ACTIVATION_STATUS,
	CHECK_GIGABIT_ELIGIBILITY,
	CRAMER_CHECK
};
