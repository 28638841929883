import gql from "graphql-tag";

const ACTION_CODE_STATUS = gql`
    fragment actionCodeStatus on ActionCodeStatus {
        code,
        status,
    }
`;

export {
	ACTION_CODE_STATUS
};
