import {  NAVIGATION_ROUTES_SESSION_KEY, NAVIGATION_STATE_SESSION_KEY } from "../../../config/constants";
import { FmcPostpaidRouteState } from "../../../modules/fmc/postpaid/fmc.postpaid.types";
import {Storage} from "../../utils/storageUtils";
import { FmcState } from "./StepStateProvider";

function useNavigationState<T>(): T {
	const state = Storage.session.get(NAVIGATION_STATE_SESSION_KEY);
	return state;
}
function useUpdateNavigatioState<T>(): ((state: T) => void) {
	return (state) => Storage.session.set(NAVIGATION_STATE_SESSION_KEY, state);
}
function useUpdateNavigatioRoute<T>(): ((state: T) => void) {
	return (state) => Storage.session.set(NAVIGATION_ROUTES_SESSION_KEY, state);
}

const cleanNavigationStateCallBack = ():void => {
	Storage.session.remove(NAVIGATION_STATE_SESSION_KEY);
	Storage.session.remove(NAVIGATION_ROUTES_SESSION_KEY);
};
const getInitFmcPopFlagState = (): FmcState => {
	const state: FmcPostpaidRouteState = Storage.session.get(NAVIGATION_STATE_SESSION_KEY);
	const { popAddonExist, unpairFmc, withoutFttb, allowFmcMerge } = state?.search || {
		popAddonExist: undefined,
		unpairFmc: undefined,
		withoutFttb: undefined,
		allowFmcMerge: undefined,
	};
	return { popAddonExist,
		unpairFmc,
		withoutFttb,
		allowFmcMerge
	} as FmcState;
};

export {
	useNavigationState,
	useUpdateNavigatioState,
	useUpdateNavigatioRoute,
	cleanNavigationStateCallBack,
	getInitFmcPopFlagState,
}