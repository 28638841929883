import gql from "graphql-tag";
import { TariffPlan } from "../fragments/subscriptionType.fragments";
import { bundle } from "../fragments/Packages.fragments";
import { language } from "../fragments/Languages.fragment";
import {LineTypeEnum} from "../types";

const GET_OFFERINGS_WITHOUT_OPTION_GROUPS = gql`
    query Offerings {
        offerings {
            id,
            code,
            is_addional,
            is_sim_only,
            use_price_categories,
            offering_contract_periods {
                contract_type
                early_termination_fees
                length
            },
            offering_sim_cards {
                price,
                catalog_sim_card {
                    card_type,
                    code,
                    is_in_stock,
                    name,
                }
            },
            offering_subscription_types {
                id,
                line_type,
                subscription_types {
                    ...tariffPlanWithoutOptionGroupsDetails,
                    billing_account_type {
                        code
                    }
                }
            },
        }
    },
    ${TariffPlan.fragments.withoutOptionGrops}
`;
export const GET_FTTB_OFFERING = gql`
    query FttbOfferings($gis_city_id: ID!) {
        offerings_under_city(gis_city_id: $gis_city_id) {
            id,
            line_type,
            subscription_types {
                subscription_type_category,
                tags,
                ...tariffPlanWithoutOptionGroupsDetails,
            }
        }
    },
    ${TariffPlan.fragments.withoutOptionGrops}
`;

export const GET_FMC_PREPAID_TARIFF_PLANS = gql`
    query FmcOfferings($gis_city_id: ID!,$msisdn: String!,$payment_type: String!) {
        offerings_under_city(gis_city_id: $gis_city_id) {
            id,
            line_type,
            subscription_types {
                subscription_type_category,
                tags,
                ...tariffPlanWithoutOptionGroupsDetails,
            }
        },
        otp_subscription(msisdn: $msisdn, otp: "") {
            expires_in
            subscription{
                available_subscription_types(line_type: "${LineTypeEnum.Fmc.toUpperCase()}",payment_type:$payment_type) {
                    subscription_type_category
                    name{
                        en
                    }
                    id
                }
            }
        }
    },
    ${TariffPlan.fragments.withoutOptionGrops}
`;

export const GET_B2B_FTTB_OFFERING = gql`
    query Subscription {
        subscription_types_by_type_category {
            ...tariffPlanDetails
            subscription_type_category
        }
    },
    ${TariffPlan.fragments.full}
`;

const GET_OPTIONAL_PRODUCTS = gql`
    query OptionalProducts($subscription_type_id: ID!) {
        optional_products(subscription_type_id: $subscription_type_id) {
            id,
            max_choices,
            name {...languageFull},
            packages {
                tags,
				provisioning_parameters{
					code,
					raw_value
				},
                ...bundleFull
            },
        }
    },
    ${bundle.fragments.full},
    ${language.fragments.full},
`;

const GET_OPTIONAL_CHILD_PRODUCTS = gql`
    query GetChildOffers($subscription_type_id: ID!, $parent_product: Int!, $relation_type: String!) {
        child_offers(subscription_type_id: $subscription_type_id, parent_product: $parent_product, relation_type: $relation_type) {
            id,         
            name {...languageFull},
            code
            tags
            fee
            fee_type
           
        }
    },
    ${language.fragments.full},
`;

export {
	GET_OPTIONAL_PRODUCTS,
	GET_OFFERINGS_WITHOUT_OPTION_GROUPS,
    GET_OPTIONAL_CHILD_PRODUCTS,
};
