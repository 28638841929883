import * as Yup from "yup";
import { IntlShape } from "react-intl";
import CommonMessages from "../../Common.messages";

const loginFirstStepSchema = (intl: IntlShape) => {
	return Yup.object().shape({
		username: Yup.string()
			.required(intl.formatMessage({...CommonMessages.required})),
	});
};
const loginSecondStepSchema = (intl: IntlShape) => {
	return Yup.object().shape({
		password: Yup.string()
			.required(intl.formatMessage({...CommonMessages.required})),
	});
};

export {
	loginFirstStepSchema,
	loginSecondStepSchema,
};
