import React from "react";


interface PassportizationProps {
    setPassportization: (value: string) => void;
    passportization: string;
}
const Passportization = (props: PassportizationProps) => {
    const handleSelectMsisdn = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        props.setPassportization(value);
    };
    const passportized = [{key: "passportized", value: "Passportized"}, {key: "not_passportized", value: "Not Passportized"}];
    return (
        <div className="row">
            <div className="col-12">
                <div className="acquisition-msisdn-msisdns mb-2">
                    {passportized.map((item, ind) => (
                        <div key={ind} className="custom-control custom-radio mb-2 mt-2">
                            <input
                                required
                                value={item.key}
                                className="custom-control-input"
                                name="my-radio"
                                type="radio"
                                id={`my-radio-${item.value}`}
                                checked={props.passportization === item.key}
                                onChange={handleSelectMsisdn}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={`my-radio-${item.value}`}
                            >{item.value}</label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export { Passportization };