import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import CommonMessages from "../../../Common.messages";
import { InputGroup, FormControl } from "react-bootstrap";
import RegistrationMessages from "../../../modules/registration/Registration.messages";
interface FmcContactInfoProps {
  msisdn: string|null|undefined;
  contactPhone?: string|null;
}
const FmcContactPhoneInfo: FC<FmcContactInfoProps> = (props: FmcContactInfoProps) => {
	return (
		<>
			<div className="form-group col-12 col-lg-4">
				<label>
					<FormattedMessage {...CommonMessages.msisdn} />
				</label>
				{props.msisdn && <InputGroup>
					<FormControl disabled={true} value={props.msisdn} />
				</InputGroup>}
			</div>
			{props.contactPhone && (
				<>
					<div className="form-group col-12 col-lg-4">
						<label>
							<FormattedMessage {...RegistrationMessages.contact_phone} />
						</label>
						<InputGroup >
							<FormControl disabled={true} value={props.contactPhone} />
						</InputGroup>
					</div>
				</>
			)}
		</>
	);
};

export { FmcContactPhoneInfo };
