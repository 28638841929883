import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import React, { FC, createContext, useContext, useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { clearIdentity } from "../../apollo/erorrHandler";
import { config, STORAGE_KEY_CURRENT_DEALER_IDENTITY, STORAGE_KEY_IS_AUTHENTICATED } from "../../config/constants";
import { LocalMutation, LocalQuery } from "../../graphql/localTypes";
import { UPDATE_AUTH } from "../../graphql/mutations/localMutation";
import { LOGIN, LOGOUT } from "../../graphql/mutations/loginMutation";
import { GET_CURRENT_DEALER_IDENTITY, GET_USER_IDENTITY } from "../../graphql/queries/userIdentity";
import {
	Maybe,
	Mutation,
	MutationLoginArgs,
	MutationLogoutArgs,
	Query,
	UserDealerIdentity,
} from "../../graphql/types";
import { iposRoutes } from "../../routes/RoutesMap";
import { Storage } from "../utils/storageUtils";
import { useAuth } from "./useAuth";
import Cookies from "js-cookie";
import { CSRFTOKEN } from "../../config/constants";
interface AuthDataContextProps {
    userName: string|null|undefined;
    dealerIdentities?: Maybe<UserDealerIdentity>[] | null| undefined;
    currentDealerIdentety?: UserDealerIdentity | undefined;
    userPermissions: Record<string, boolean>|undefined;
    loading: boolean;
    onLogout: () => void;
    onLogin: (values: MutationLoginArgs) => Promise<string>|void;
}

interface AuthentificationProviderProps {
    children: JSX.Element | JSX.Element[];
}

export interface UserIdentity {
	username: string|null|undefined;
	dealerIdentities: Maybe<UserDealerIdentity>[] | null| undefined;
}

export const AuthDataContext = createContext<AuthDataContextProps>({
	userName: "",
	currentDealerIdentety: undefined,
	dealerIdentities: [],
	userPermissions: undefined,
	loading: false,
	onLogout: () => undefined,
	onLogin: () => undefined,
});


const AuthentificationProvider: FC<AuthentificationProviderProps> = props => {

	const { refetch} = useAuth();
	const history = useHistory();
	const [updateAuth] = useMutation<LocalMutation>(UPDATE_AUTH);
	const [logoutMutation] = useMutation<Mutation, MutationLogoutArgs>(LOGOUT);

	const [getUserIdentity, { data, loading: loadingIdentity }] = useLazyQuery<Required<Pick<Query, "user_identity">> & Record<"user_identity", Pick<LocalQuery, "dealerIdentity">>>(GET_USER_IDENTITY, {
		fetchPolicy: "network-only",
		onCompleted: (data) => {
			if (data?.user_identity) {
				updateAuth({variables: {
					isAuthenticated: true
				}}).then(() => {
					refetch();
					refetchCurrentIdentity();
				});
				Storage.local.set(STORAGE_KEY_IS_AUTHENTICATED, true);
			}
		},
		onError: (errors) => { return;}

	});

	const [handleLogin, { loading: loginLoading}] = useMutation<Required<Pick<Mutation, "login">>, MutationLoginArgs>(LOGIN, {
		fetchPolicy: "no-cache"
	});
	useEffect(() => {
		const csrf = Cookies.get(CSRFTOKEN);

		if (!csrf) {
			fetch(`${window.location.protocol}//${config.HOST}/api/csrf`, {
				method: "GET",
				credentials:  process.env.NODE_ENV === "development" ? "include" : "same-origin"
			}).then(() => {
				getUserIdentity();
			});
		} else {
			getUserIdentity();
		}
	}, []);

	const { data: dealerIdentityData, refetch: refetchCurrentIdentity } = useQuery<Required<Pick<LocalQuery, "dealerIdentity">>>(GET_CURRENT_DEALER_IDENTITY);
	const userName = data?.user_identity?.username;
	const currentDealerIdentety = dealerIdentityData?.dealerIdentity || Storage.local.get(STORAGE_KEY_CURRENT_DEALER_IDENTITY);
	const dealerIdentities = data?.user_identity?.dealer_identities;

	const userPermissions: Record<string, boolean> = useMemo<{[key: string]: boolean}>(() => {
		const user_permissions = data?.user_identity?.permissions || [];
		return user_permissions.reduce((acc, key) => {
			if (key) {
				acc[key] = true;
			}
			return acc;
		}, {});},[data?.user_identity?.permissions]);

	const onLogin: (values: MutationLoginArgs) => Promise<string> = (values) => {
		return handleLogin({variables: {...values}}).then(({data}) => {
			getUserIdentity();
			return data?.login?.error || "";
		});
	};
	const onLogout = () => {
		logoutMutation().finally(() => {
			clearIdentity().then(() => {
				refetch();
				history.push(iposRoutes.LOGOUT.createLink());
			});
		});

	};
	const loading = loginLoading || loadingIdentity;
    
	const authValues = useMemo(() => ({ userName, onLogin, onLogout, dealerIdentities, currentDealerIdentety, userPermissions,  loading}), [data, currentDealerIdentety, dealerIdentities, loading, userPermissions]);

	return (<AuthDataContext.Provider value={authValues} {...props} />);
};

export const useAuthDataContext: () => AuthDataContextProps = () => useContext<AuthDataContextProps>(AuthDataContext);

export { AuthentificationProvider };
