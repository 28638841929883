import React from "react";
import { FormattedMessage } from "react-intl";
import LoginMessages from "./Login.messages";
import CommonMessages from "../../Common.messages";
import { iposRoutes } from "../../routes/RoutesMap";
import { useHistory } from "react-router";
import { useAuth } from "../../shared/hooks/useAuth";

const Logout = () => {
	const history = useHistory();
	const {refetch} = useAuth();

	const handleLogIn = () => {
		refetch();
		history.push(iposRoutes.LOGIN.createLink());
	};
	return (
		<div id="w-login">
			<header id="w-app-header">
				<h1 id="w-app-title">
					<span id="client-logo"><FormattedMessage {...CommonMessages.companyName}/></span>
					<span id="w-app-name"><FormattedMessage {...CommonMessages.productName}/></span>
				</h1>
			</header>
			<div id="w-login-body-custom">
				<header className="w-view-header">
					<h3><FormattedMessage {...LoginMessages.loggedOut}/></h3>
				</header>
				<form noValidate>
				</form>
				<p><FormattedMessage {...LoginMessages.logoutSuccessfully}/></p>
				<footer className="mt-4">
					<button className="btn btn-secondary btn-block" onClick={handleLogIn}>
						<FormattedMessage {...LoginMessages.logInAgain}/>
					</button>
				</footer>
			</div>
		</div>
	);
};

export {
	Logout
};
