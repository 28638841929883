import gql from "graphql-tag";
import { Customer } from "../fragments/customer.fragments";

//TODO UKR-16716
export const GET_BILLING_ACCOUNT = gql`
    query getBillingAccount($billing_account_id: ID!) {
        billing_account(billing_account_id: $billing_account_id) {
            id,
            name,
            invoice_delivery_method,
            payment_method,
            owner {
                ...Customer
            },
            type {
                code
                name
            }
    }
}
${Customer}
`;