import React, {useState, FC, useRef, useEffect} from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { FormattedMessage } from "react-intl";
import { FMC_FLOW_PLAN_PATH } from "../Routes/FMCPageRoutes";
import { Maybe, Package, ProductFamily, Query, QueryAssociated_Subscription_TypeArgs, QuerySubscription_Type_OfferingArgs } from "../../../graphql/types";
import { FmcRouteState } from "../fmc.type";
import { OptionsSummary } from "../../../shared/components/tariffPlan/OptionsSummary";
import { ConnectionOption } from "../../fttb/Pages/Options/ConnectionOption";
import { OtherOptions, SelectedOtherOptions } from "../../fttb/Pages/Options/OtherOptions";
import { config } from "../../../config/constants";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import { CircularProgress } from "../../../shared/components";
import {
	filterChildPackages,
	filterOutActiveBundleFromAvailableFamily,
	findPackage
} from "../../../shared/utils/bundleUtils";
import { GET_ASSOCIATED_FTTB_SUBSCRIPTION_TYPE, GET_SUBSCRIPTION_TYPE_OFFERING } from "../../../graphql/queries/associatedFttbSubscriptionType";
import { OptionProductChecked } from "../../acquisitionFlow/NewCustomer/Pages/OptionsPage/OptionProduct";
import {GET_OPTIONAL_CHILD_PRODUCTS} from "../../../graphql/queries/acquisitionFlow";
import { OptionsFamilies } from "../../acquisitionFlow/NewCustomer/Pages/OptionsPage/OptionsFamilies";
import {prepareOptionalProductsForRadio, prepareUncheckClick} from "../../../shared/utils";
import { RegionOptionalProducts } from "../../../shared/components/option/RegionOptionalProducts";
import { StepWrapper } from "../../../shared/components/navigation/StepWrapper";
import { useNavigationState } from "../../../shared/components/navigation/useNavigationState";
import {AllowOnlyOneSBPackageNotification} from "../../../shared/components/AllowOnlyOneSBPackageNotification";
import { hasGPONFTTBTag } from "../../../shared/utils/acquisitionFlowUtils";
import { FMCMessages } from "../FMC.messages";

const Options: FC<RouteComponentProps<never, StaticContext, FmcRouteState>> = props => {
	const availableFamilies = useRef<Maybe<Array<Maybe<ProductFamily>>>>([]);

	const { history } = props;
	const state = useNavigationState<FmcRouteState>();
	const fttbOptions = state?.fttbOptions;

	const { flat, gisHouseId} = state?.address || {};
	const subscriptioTypeId = state?.selectedFttbTariffPlan?.id;

	const otherOptionRef = useRef<SelectedOtherOptions>({
		comment: state?.fttbOptions?.comment || "",
		complexOffer: Boolean(state?.fttbOptions?.complexOffer),
		starterPack: Boolean(state?.fttbOptions?.starterPack),
	});
	const initDesiredConnectionDate = state?.fttbOptions?.desiredConnectionDate ? new Date(state?.fttbOptions?.desiredConnectionDate) : undefined;

	const [date, setDate] = useState<Date|undefined|null>(initDesiredConnectionDate);
	const [bothGSMFTTBHaveActiveSBPackage,setBothGSMFTTBHaveActiveSBPackage] = useState<boolean>(false);
	const [deActivateExistingGSMSBPackageChecked, setDeActivateExistingGSMSBPackageChecked] = useState<boolean>(!Boolean(state?.isNoFttbSubscription));
	const[activeFttbPackages, setActiveFttbPackages] = useState(state?.fttbSubscription?.active_packages);

	const checkIfGsmSubscriptionHasSBGigabitPackages = Boolean(
		state && state.gsmSubscription && state.gsmSubscription.active_packages?.some(pack =>
			pack && pack.tags && Array.isArray(pack.tags) &&
			config.SBPACKAGEIDENTIFIERTAGS.some(value => pack?.tags?.includes(value))
		)
	);

	const checkIfFttbSubscriptionHasSBGigabitPackages = Boolean(
		state && state.fttbSubscription && state.fttbSubscription.active_packages?.some(pack =>
			pack && pack.tags && Array.isArray(pack.tags) &&
			config.SBPACKAGEIDENTIFIERTAGS.some(value => pack?.tags?.includes(value))
		)
	);

	const gsmActiveSBPackage = state && state.gsmSubscription && state.gsmSubscription.active_packages?.find(pack =>
		pack && pack.tags && Array.isArray(pack.tags) &&
		config.SBPACKAGEIDENTIFIERTAGS.find(value => pack?.tags?.includes(value)));

	const fttbActiveSBPackage = state && state.fttbSubscription && state.fttbSubscription.active_packages?.find(pack =>
		pack && pack.tags && Array.isArray(pack.tags) &&
		config.SBPACKAGEIDENTIFIERTAGS.find(value => pack?.tags?.includes(value)));

	useEffect(() => {
		if(checkIfGsmSubscriptionHasSBGigabitPackages && checkIfFttbSubscriptionHasSBGigabitPackages){
			setBothGSMFTTBHaveActiveSBPackage(true);
		}
	},[]);


	const[hasGPONFTTB, setHasGPONFTTB] = useState(true);

	const {data, loading } = useQuery<Required<Pick<Query, "associated_subscription_type"|"optional_products">>, QueryAssociated_Subscription_TypeArgs>(GET_ASSOCIATED_FTTB_SUBSCRIPTION_TYPE, {
		variables: {subscription_type_id: state?.selectedFmcTariffPlan?.id!},
		skip: !state?.selectedFmcTariffPlan?.id,
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			setHasGPONFTTB(hasGPONFTTBTag(data?.optional_products!));
		}
	});
	const {data:  {subscription_type_offering: offer, optional_products: optionalProducts} = {}, loading: loadOffer } = useQuery<Required<Pick<Query, "subscription_type_offering"|"optional_products">>, QuerySubscription_Type_OfferingArgs>(GET_SUBSCRIPTION_TYPE_OFFERING, {
		variables: {subscription_type_id: data?.associated_subscription_type?.id!},
		skip: !data?.associated_subscription_type?.id,
		fetchPolicy: "no-cache"
	});
	const [optionalProduct, setOptionalProduct] = useState<Package[]>(state?.fttbOptions?.packages || []);

	const [getChildOffers, {data: childOffersData, loading: loadingChildOffers}] = useLazyQuery<Query>(GET_OPTIONAL_CHILD_PRODUCTS);

	const [isParentChecked, setIsParentChecked] = useState<boolean>(false);
	const [parentCode, setParentCode] = useState<string | null | undefined>("");

	const onChecked = (value: OptionProductChecked) => {
		setIsParentChecked(value.checked);
		setParentCode(value.optionalProduct.code);
		getChildOffers({ variables: { subscription_type_id: state?.selectedFmcTariffPlan?.id, parent_product: Number(value.optionalProduct.id), relation_type: "all" }});
		const filteredOptions = optionalProduct.filter(pack => {
			return prepareOptionalProductsForRadio(pack, value);
		});

		if (value.checked) {
			// setOptionalProduct([...filteredOptions, value.optionalProduct]);
			setOptionalProduct(prevOptionalProduct => [...prevOptionalProduct, value.optionalProduct]);

		} else {
			if (!loadingChildOffers) {
				setOptionalProduct(optionalProduct.filter((pack) => {
					return  prepareUncheckClick(pack, value, childOffersData);
				}));
			}
		}
	};

	useEffect(() => {
		if(!state.isNoFttbSubscription){
			return;
		}
		if(checkIfGsmSubscriptionHasSBGigabitPackages){
			const optionalProductHasSBPackageSelected = Boolean(
				optionalProduct.some(pack =>
					pack && pack.tags && Array.isArray(pack.tags) &&
				config.SBPACKAGEIDENTIFIERTAGS.some(value => pack?.tags?.includes(value))
				)
			);
			if(optionalProductHasSBPackageSelected){
				setBothGSMFTTBHaveActiveSBPackage(!bothGSMFTTBHaveActiveSBPackage);
			}
		}
	},[optionalProduct]);


	useEffect(() => {
		if (!state.isNoFttbSubscription && bothGSMFTTBHaveActiveSBPackage) {
			setActiveFttbPackages(
				deActivateExistingGSMSBPackageChecked
					? state.fttbSubscription?.active_packages
					: activeFttbPackages?.filter(pack => !config.SBPACKAGEIDENTIFIERTAGS.some(value => pack?.tags?.includes(value)))
			);
		}
	}, [deActivateExistingGSMSBPackageChecked]);


	const onChildChecked = (value: OptionProductChecked) => {
		if (value.checked) {
			setOptionalProduct([...optionalProduct, value.optionalProduct]);
		} else {
			setOptionalProduct(optionalProduct.filter((pack) => pack.code !== value.optionalProduct.code));
		}
	};
	let available_child_offers = childOffersData?.child_offers;

	if (optionalProducts && optionalProducts.length > 0) {
		//const filteredProducts = checkIfGsmSubscriptionHasSBGigabitPackages ? optionalProducts.filter((family) => family?.name?.en !== config.SUPERPOWER_FAMILY) : optionalProducts;
		availableFamilies.current = filterOutActiveBundleFromAvailableFamily(
			optionalProducts, 
			[{code: config.FAST_CONNECTION_PACKAGE_CODE}], 
			state?.address?.simpleConnection, 
			state.housePreviousActivationStatus, 
			state?.isGigabitAvailable,
			state?.networkType
		);
	}

	if (available_child_offers && available_child_offers.length > 0 && state?.address?.simpleConnection) {
		available_child_offers = filterChildPackages(available_child_offers);
	}

	if (!state?.selectedFmcTariffPlan?.code) {
		history.push(FMC_FLOW_PLAN_PATH);
	}

	const stateCallback = () => (state?.isNoFttbSubscription ? {
		selectedFttbTariffPlan: {
			offerCode: offer?.code,
			...data?.associated_subscription_type
		},
		fttbOptions: {
			comment: otherOptionRef.current.comment,
			complexOffer: otherOptionRef.current.complexOffer,
			starterPack: otherOptionRef.current.starterPack,
			desiredConnectionDate: date?.toString(),
			packages: optionalProduct,
			fttbFast: findPackage(optionalProducts,  config.FAST_CONNECTION_PACKAGE_CODE),
			deactivatePackage: bothGSMFTTBHaveActiveSBPackage ? gsmActiveSBPackage : undefined
		}
	} : bothGSMFTTBHaveActiveSBPackage ? {
		bothGSMFTTBHaveActiveSBPackage,
		retainedPackage: deActivateExistingGSMSBPackageChecked ? fttbActiveSBPackage : gsmActiveSBPackage,
		deactivatePackage: deActivateExistingGSMSBPackageChecked ? gsmActiveSBPackage : fttbActiveSBPackage
	} : undefined);

	const handleGSMSBPackage = () => {
		setDeActivateExistingGSMSBPackageChecked(!deActivateExistingGSMSBPackageChecked);
	};

	const  calculateIsValid = () => {
		//const hasSeenGSMFTTBSBNotification = !bothGSMFTTBHaveActiveSBPackage || deActivateExistingGSMSBPackageChecked;
		//const isDateSelected = !state?.isNoFttbSubscription ? true : Boolean(state?.isNoFttbSubscription && date);
		//const isValid = (!loadOffer && state?.address?.simpleConnection) || isDateSelected || (!loadOffer && state?.isNoFttbSubscription ? hasSeenGSMFTTBSBNotification : true);

		if(!state?.isNoFttbSubscription || state?.address?.simpleConnection){
			console.log("we shouldnt be here when checkbox is checked");
			return !loadOffer;
		}else{
			console.log(date,Boolean(date));
			if(!Boolean(date)){
				console.log(date,Boolean(date));
				return Boolean(date);
			}
		}

	};
	const hasSeenGSMFTTBSBNotification = !bothGSMFTTBHaveActiveSBPackage || deActivateExistingGSMSBPackageChecked;


	return (
		<StepWrapper<FmcRouteState>
			isValid={
				// If there's no FTTB subscription or a simple connection address as simple connection doesnt have date,
				// allow if 'loadOffer' is false, else consider other conditions
				(!state?.isNoFttbSubscription || state?.address?.simpleConnection && !bothGSMFTTBHaveActiveSBPackage)
					? !loadOffer
					: (
						// If both GSM and FTTB have active SB packages, consider deactivation status
						bothGSMFTTBHaveActiveSBPackage ? deActivateExistingGSMSBPackageChecked
							// Otherwise, allow if 'date' is truthy when simple connection not there
							: state?.address?.simpleConnection || !!date
					)
			}
			stateCallBack={stateCallback}>
			{ !hasGPONFTTB &&
				<div className="alert alert-warning mt-2 message">
					<p><FormattedMessage {...FMCMessages.gponNotAvailable} /></p> 
				</div>
			}
			{state?.isNoFttbSubscription ? <>
				{!state?.address?.simpleConnection && <>
					<ConnectionOption
						currentDate={date}
						onSelectedDate={setDate}
					/>
					<OtherOptions
						otherOptionRef={otherOptionRef}
					/></>}
				{availableFamilies.current?.map((family: Maybe<ProductFamily>) => (
					family?.packages?.length && family.packages.length > 0) &&
					<OptionsFamilies
						key={family.id!}
						family={family}
						onChecked={onChecked}
						isParentChecked={isParentChecked}
						childOffersData={available_child_offers}
						parentCode={parentCode}
						onChildChecked={onChildChecked}
						loadingChildOffers={loadingChildOffers}
						selectedOptionalProduct={optionalProduct}
					/>
				)}
				<RegionOptionalProducts
					subscriptioTypeId={subscriptioTypeId}
					additionalLoading={loading}
					flat={flat}
					gisHouseId={gisHouseId}
					onChecked={onChecked}
					onChildChecked={onChildChecked}
					childOffersData={available_child_offers}
					isParentChecked={isParentChecked}
					parentCode={parentCode}
					loadingChildOffers={loadingChildOffers}
					selectedOptionalProduct={optionalProduct} />
			</>
		  : activeFttbPackages?.map((option: Maybe<Package>) => (<OptionsSummary
					key={option?.name?.en!}
					fees={option?.fees!}
					periodic_unit={option?.periodic_unit!}
					periodic_amount={option?.periodic_amount!}
					name={option?.name!}
					medium_description={option?.medium_description!}
					long_description={option?.long_description!}
				/>
				))}
			{(loading || loadOffer) && <CircularProgress/>}
			{bothGSMFTTBHaveActiveSBPackage && <AllowOnlyOneSBPackageNotification
				handleGSMSBPackage={handleGSMSBPackage}
				gsmActiveSBPackage={gsmActiveSBPackage}
				fttbActiveSbPackage={fttbActiveSBPackage}
				deActivateExistingGSMSBPackageChecked={deActivateExistingGSMSBPackageChecked}
				isNoFttbSubscription={state?.isNoFttbSubscription}/>}
		</StepWrapper>
	);
};

export { Options };
