import { defineMessages, MessageDescriptor } from "react-intl";

export interface MainMessagesType {
    targetMSISDN: MessageDescriptor;
    icc: MessageDescriptor;
    simCardInformation: MessageDescriptor;
    reason: MessageDescriptor;
    reasonLabel: MessageDescriptor;
    scanLabel: MessageDescriptor;
    newSim: MessageDescriptor;
    changeSimError: MessageDescriptor;
    dialogTitle: MessageDescriptor;
    replacementReason: MessageDescriptor;
    paymentInformation: MessageDescriptor;
    replaceSim: MessageDescriptor;
    damage: MessageDescriptor;
    simLost: MessageDescriptor;
    stolen: MessageDescriptor;
    manufacturingFault: MessageDescriptor;
    blocked: MessageDescriptor;
    iccValidationFail: MessageDescriptor;
    lteSimChange: MessageDescriptor;
    formatChange: MessageDescriptor;
    transitionToUsim: MessageDescriptor;
    transitionToMobileID: MessageDescriptor;
    changeToSpareSIM: MessageDescriptor;
}

const changeSimMessages: MainMessagesType = defineMessages({
	targetMSISDN: {
		id: "change-sim-target-msisdn",
		description: "Change sim dialog view title",
		defaultMessage: "Target MSISDN"
	},
	dialogTitle: {
		id: "change-sim-replace-sim-title",
		description: "Change sim dialog  title",
		defaultMessage: "Change SIM"
	},
	icc: {
		id: "change-sim-icc",
		description: "Change sim dialog view title",
		defaultMessage: "Current SIM ICC"
	},
	iccValidationFail: {
		id: "acquisition-flow-icc",
		description: "Acquisition flow ICC page ICC Validation fail common message",
		defaultMessage: "ICC validation failed"
	},
	simCardInformation: {
		id: "change-sim-sim-card-informmation",
		description: "Change sim dialog view title",
		defaultMessage: "Sim Card Information"
	},
	reason: {
		id: "change-sim-reason-change",
		description: "Change sim dialog view title",
		defaultMessage: "Reason"
	},
	reasonLabel: {
		id: "change-sim-placeholder-input-reason",
		description: "Change sim input reason",
		defaultMessage: "Reason"
	},
	scanLabel: {
		id: "change-sim-placeholder-input-scan-icc",
		description: "Change sim input scan icc",
		defaultMessage: "Enter ICC"
	},
	replacementReason: {
		id: "change-sim-replacement-reason",
		description: "Replacement sim reason label",
		defaultMessage: "Replacement reason"
	},
	paymentInformation: {
		id: "change-sim-payment-information",
		description: "Replacement reason label",
		defaultMessage: "Payment information"
	},
	replaceSim: {
		id: "change-sim-replace-sim",
		description: "Replace sim confirm button",
		defaultMessage: "Confirm"
	},
	newSim: {
		id: "change-sim-new-sim-card-icc",
		description: "Change sim dialog view title",
		defaultMessage: "New SIM card ICC"
	},
	changeSimError: {
		id: "change-sim-error-message",
		description: "Change sim error message",
		defaultMessage: "ChangeSim error, try again"
	},
	damage: {
		id: "change-sim-reason-damage",
		description: "Change sim reason damage",
		defaultMessage: "Damage"
	},
	simLost: {
		id: "change-sim-reason-sim-lost",
		description: "Change sim reason Sim lost",
		defaultMessage: "Sim lost"
	},
	stolen: {
		id: "change-sim-reason-stolen",
		description: "Change sim reason Stolen",
		defaultMessage: "Stolen"
	},
	manufacturingFault: {
		id: "change-sim-reason-manufacturing-fault",
		description: "Change sim reason Manufacturing Fault",
		defaultMessage: "Manufacturing Fault"
	},
	blocked: {
		id: "change-sim-reason-blocked",
		description: "Change sim reason Blocked",
		defaultMessage: "Blocked"
	},
	lteSimChange: {
		id: "change-sim-reason-lte-sim-change",
		description: "Change sim reson LTE SIM change",
		defaultMessage: "LTE SIM change"
	},
	formatChange: {
		id: "change-sim-reason-format-change",
		description: "Change sim reason Format change",
		defaultMessage: "Format change"
	},
	transitionToUsim: {
		id: "change-sim-reason-transition-to-usim",
		description: "Change sim reason Transition to USIM",
		defaultMessage: "Transition to USIM"
	},
	transitionToMobileID: {
		id: "change-sim-reason-transition-to-mobile-id",
		description: "Change sim reason transition to mobile ID",
		defaultMessage: "Transition to mobile ID"
	},
	changeToSpareSIM: {
		id: "change-sim-reason-transition-to-pare-sim",
		description: "Change sim reason change to spare SIM",
		defaultMessage: "Change to spare SIM"
	},
});

export { changeSimMessages };
