import { gql } from "apollo-boost";
import { MUTATION_RESPONSE_FRAGMENT } from "../fragments/response";

export const UNMERGE_SUBSCRIPTIONS = gql`mutation UnmergeSubscriptions($fmcSubscriptionId: String!,
    $date: Datetime!,
    $subscriptionCode: String!,
    $resellerCode: String!,
    $options: [String]) {
    create_fmc_order(details:{
        delivery_type: direct
        desired_connection_date: $date
        brand: 0
        sales_info:{
            sales_type: fmc_unmerge
            reseller_code: $resellerCode
        }
        products: {
            subscription: {
                type: $subscriptionCode
                product_type: Subscription
                fmc_subscription_id: $fmcSubscriptionId
                packages: $options
            }
        }
    }){
        ...MutationResponse
    }
}
${MUTATION_RESPONSE_FRAGMENT}`;