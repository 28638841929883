import React, { useState, useEffect } from "react";

const InputWithCheckBox = (props: any) => {
	const [selected, setSelect] = useState<boolean>(props.checked || false);
	const onChange = () => setSelect(!selected);
	useEffect(() => {
		if(props.onChange) {
			props.onChange(selected);
		}
	}, [selected]);
	const id = `check-box-without-${props.id}`;
	const className = props.className || "col-sm-6";
	return (
		<div className="row align-items-center">
			{props.children(selected)}
			<div className={className}>
				<div className="custom-control custom-checkbox">
					<input
						name={"hello"}
						type="checkbox"
						id={id}
						className="custom-control-input"
						checked={selected}
						onChange={onChange}
					/>
					<label className="custom-control-label" htmlFor={id}>
						{props.label}
					</label>
				</div>
			</div>
		</div>
	);
};

export { InputWithCheckBox };