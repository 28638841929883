import React, { useCallback, useState } from "react";
import { ACQUISITION_EXISTING_FLOW_MSISDN_PATH } from "../../Router/AcquisitionExistingFlowPath";
import { INDEX_PATH } from "../../../NewCustomer/Routes/AcquisitionFlowRoutes";
import { useFormik } from "formik";
import { BillingAccount } from "./BillingAccount";
import { FormattedMessage } from "react-intl";
import { AcquisitionMessages } from "../../../Acquisition.messages";
import {
	BILLING_EXISTING_ACCOUNT,
	BILLING_NEW_ACCOUNT
} from "../../../../../config/constants";
import { RouteComponentProps, StaticContext } from "react-router";
import {CircularProgress, NavigationHeader} from "../../../../../shared/components";
import { AcquisitionExistingFlow } from "../../Router/AcquisitionExistingFlow";
import { ExistingCustomerRouteState } from "../Summary/Summary";
import {CustomerDebts} from "./CustomerDebts";
import {useMutation} from "@apollo/react-hooks";
import {Mutation} from "../../../../../graphql/types";
import {GET_RESERVED_BILLING_ACCOUNT_ID} from "../../../../../graphql/queries/company";

const formId = "acquisition-existing-flow-billing-page-form-id";

const BillingPage = (props: RouteComponentProps<never, StaticContext, ExistingCustomerRouteState>) => {
	const {location: {state}, history} = props;
	const [billingStatus, setShowBilling] = useState<string>(state?.billing_status || "");
	const onClickBack = useCallback(() => history.push(INDEX_PATH), []);
	const [reserveBillingAccountId, { loading }] = useMutation<Required<Pick<Mutation, "billing_account_id_reservations">>>(GET_RESERVED_BILLING_ACCOUNT_ID);

	const onChange = (setFieldValue) => (e) => {
		const billingStatus = e.target.value;
		if (billingStatus === BILLING_NEW_ACCOUNT) {
			reserveBillingAccountId({
				variables: {}
			}).then(async ({data}) => {
				setFieldValue("account_id", data?.billing_account_id_reservations?.request_id);
			});
		}
		setFieldValue("status", billingStatus);
		setShowBilling(billingStatus);
	};

	const onSubmit = (value) => {
		const {status, account_id, billing_type} = value;

		if (billingStatus && BILLING_EXISTING_ACCOUNT === billingStatus) {
			value.account_id && history.push({
				pathname: ACQUISITION_EXISTING_FLOW_MSISDN_PATH,
				state: {
					...state,
					billing_status: status,
					account_id,
					billing_type,
				}
			});
		} else if (billingStatus && BILLING_NEW_ACCOUNT === billingStatus) {
			history.push(ACQUISITION_EXISTING_FLOW_MSISDN_PATH, {
				...state,
				billing_status: status,
				account_id,
			});
		}
	};

	const {setFieldValue, values, handleSubmit} = useFormik({
		initialValues: {
			status: billingStatus,
			account_id: state?.account_id || "",
			billing_type: state?.billing_type || ""
		},
		onSubmit
	});

	return (
		<>
			<NavigationHeader
				FlowComp={AcquisitionExistingFlow}
				formId={formId}
				back={onClickBack}
				disabledNext={!!(state?.customer?.invoices && state.customer.invoices?.length)}
			/>
			<div className="ipos-content-box">
				<form id={formId} onSubmit={handleSubmit}>
					<div className="custom-control custom-radio">
						<div>
							<input
								className="custom-control-input"
								name="status"
								type="radio"
								id={"my-radio-billing-first"}
								value={BILLING_NEW_ACCOUNT}
								checked={values.status === BILLING_NEW_ACCOUNT}
								onChange={onChange(setFieldValue)}
							/>
							<label className="custom-control-label" htmlFor={"my-radio-billing-first"}>
								<FormattedMessage {...AcquisitionMessages.createBilling} />
							</label>
						</div>
						{loading && <CircularProgress/>}
						<div>
							<input
								className="custom-control-input"
								name="status"
								type="radio"
								value={BILLING_EXISTING_ACCOUNT}
								checked={values.status === BILLING_EXISTING_ACCOUNT}
								id={"my-radio-billing-second"}
								onChange={onChange(setFieldValue)}
							/>
							<label className="custom-control-label" htmlFor={"my-radio-billing-second"}>
								<FormattedMessage {...AcquisitionMessages.chooseBilling} />
							</label>
						</div>
						{billingStatus === BILLING_EXISTING_ACCOUNT && (
							<div className="mt-2">
								<BillingAccount
									setFieldValue={setFieldValue}
									account_id={values.account_id}
									customer={state?.customer}
									isB2bBillingAccount={false}
								/>
							</div>
						)}
					</div>
				</form>
				{
					(state?.customer?.invoices && state.customer.invoices?.length > 0) && (
						<div className="mt-4">
							<CustomerDebts
								invoices={state?.customer?.invoices}
							/>
						</div>
					)
				}
			</div>
		</>
	);
};

export { BillingPage };