import React, { useState, useMemo, FC } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { FormikHandlers, FormikHelpers, FormikState, useFormik } from "formik";
import { CircularProgress } from "../../../shared/components/CircularProgress";
import { FTTBMessages } from "../../fttb/FTTB.messages";
import { useIntl } from "react-intl";
import { FmcRouteState } from "../fmc.type";
import { FttbAddressForm } from "../../fttb/fttb.types";
import {Mutation, MutationReserve_MsisdnArgs, Query} from "../../../graphql/types";
import { RESERVE_MSISDN } from "../../../graphql/mutations/subscription";
import { FttbStatus } from "../../fttb/Pages/Address/Components/FTTBStatus";
import { AddressValidationForm } from "../../fttb/Pages/Address/Components/AddressValidationForm";
import { ErrorMessage } from "../../../shared/components/ErrorMessageTemplate";
import { InputFieldFormik } from "../../../shared/components";
import { config } from "../../../config/constants";
import { Address } from "../../../graphql/localTypes";
import RegistrationMessages from "../../registration/Registration.messages";
import { initialLabels } from "../../../shared/utils";
import { addressValidation } from "../../fttb/Pages/Address/Address.schema";
import { GET_SINGLE_MSISDNS } from "../../../graphql/queries/msisdn";
import { UserNameInfo } from "../../../shared/components/formBlocks/UserNameInfo";
import { FMC_FLOW_PLAN_PATH } from "../Routes/FMCPageRoutes";
import { useGisHousePreviousActivationStatus } from "../../../shared/hooks/useGisHousePreviousActivationStatus";
import { SimpleConnection } from "../../../shared/components/SimpleConnection";
import { FormStepWrapper } from "../../../shared/components/navigation/FormStepWrapper";
import { useNavigationState, useUpdateNavigatioState } from "../../../shared/components/navigation/useNavigationState";
import {Gigabit} from "../../../shared/components/Gigabit";


const formId = "fttb-address-form";

const FmcAddress: FC<RouteComponentProps<never, StaticContext, FmcRouteState>> = props => {
	const { history} = props;
	const state = useNavigationState<FmcRouteState>();
	const updateNavigationState  = useUpdateNavigatioState<FmcRouteState>();

	const intl = useIntl();
	const [fmcState, setFmcbState] = useState<FmcRouteState|undefined|null>(state);
	const [error, setErorr] = useState<string>("");
	const [connectionExist, setConnectionExist] = useState<string>("");
	const [housePreviousActivationStatus, setPreviousHouseActivationStatus] = useState<boolean>(Boolean(state?.housePreviousActivationStatus));
	const [msisdnWithMaxLastRCDate, setMsisdnWithMaxLastRCDate] = useState<string>((state?.msisdnWithMaxLastRCDate as string) ?? "");
	const [isGigabitAvailable, setGigabitAvailable] = useState<boolean>(Boolean(state?.isGigabitAvailable));
	const [reserveMsisdn, {loading: loadingMsisdn}] = useMutation<Required<Pick<Mutation, "reserve_msisdn">>, MutationReserve_MsisdnArgs>(RESERVE_MSISDN);
	const initialValues = {
		last_name: state?.last_name || "",
		first_name: state?.first_name || "",
		middle_name: state?.middle_name || "",
		contact_phone: state?.gsmSubscription?.msisdn || "",
		city: state?.address?.city || "",
		street: state?.address?.street || "",
		gisStreetId: state?.address?.gisStreetId || "",
		house_number: state?.address?.house || "",
		flat: state?.address?.flat || "",
		province: state?.address?.province || "",
		gisProvinceId: state?.address?.gisProvinceId || "",
		gisCityId: state?.address?.gisCityId || "",
		gisDistrictId: state?.address?.gisDistrictId || "",
		gisHouseId: state?.address?.gisHouseId || "",
		gisZipId: state?.address?.gisZipId || "",
		simpleConnection: state?.address?.simpleConnection || false,
		msisdnWithMaxLastRCDate: state?.msisdnWithMaxLastRCDate || "",
		isGigabitAvailable: state?.isGigabitAvailable || false
	};

	const [handleMsisdnPart] = useLazyQuery<Required<Pick<Query, "msisdns">>>(GET_SINGLE_MSISDNS, {
		fetchPolicy: "no-cache",
		onCompleted: ({ msisdns }) => {
			const msisdn = msisdns?.[0]?.msisdn;
			if (msisdn) {
				reserveMsisdn({
					variables: {msisdn}
				}).then(async ({data}) => {
					const reservation_id = data?.reserve_msisdn?.attributes?.reservation_id;
					if (reservation_id) {
						updateNavigationState({
							...fmcState!,
							fttbMsisdn: msisdn,
							fttbMsisdnConfirmation_code: reservation_id,
							housePreviousActivationStatus: housePreviousActivationStatus
						});
						history.push({
							pathname: FMC_FLOW_PLAN_PATH,
						});
					} else {
						setErorr(intl.formatMessage(FTTBMessages.msisdnReservationFail));
					}
				});
			} else {
				setErorr(intl.formatMessage(FTTBMessages.inventoryNoMsisdn));
			}

		}
	});
	const [hasAvailableHouse, setAvailableHouse] = useState<null | boolean>(!!fmcState?.address?.house || null);
	const [networkType, setNetworktype] = useState("");

	const onSubmit = (values: FttbAddressForm) => {
		setErorr("");
		if (fmcState?.address?.gisHouseId && fmcState?.fttbMsisdnConfirmation_code && fmcState?.fttbMsisdn) {
			history.push(FMC_FLOW_PLAN_PATH);
		}
		if (hasAvailableHouse) {
			const fttb = {
				...state,
				contact_phone: values.contact_phone,
				last_name: values.last_name,
				first_name: values.first_name,
				middle_name: values.middle_name,
				msisdnWithMaxLastRCDate: msisdnWithMaxLastRCDate,
				isGigabitAvailable: isGigabitAvailable,
				networkType: networkType,
				address: {
					country: config.DEFAULT_COUNTRY_NAME,
					country_code: config.DEFAULT_COUNTRY,
					city: values.city,
					gisProvinceId: values.gisProvinceId,
					gisDistrictId: values.gisDistrictId,
					gisCityId: values.gisCityId,
					gisStreetId: values.gisStreetId,
					gisHouseId: values.gisHouseId,
					gisZipId: values.gisZipId,
					street: values.street,
					house: values.house_number,
					flat: values.flat,
					province: values.province,
					simpleConnection: values.simpleConnection
				} as Address
			} as FmcRouteState;
			if (state?.fttbMsisdnConfirmation_code && state?.fttbMsisdn) {
				updateNavigationState({
					...fttb,
				});
				history.push({
					pathname: FMC_FLOW_PLAN_PATH,
				});
			} else {
				setFmcbState(fttb);
				handleMsisdnPart();
			}

		}
	};
	const validationSchema = useMemo(() => addressValidation(intl), [intl.locale]);
	const labels = useMemo(() => initialLabels<any>(intl, initialValues, RegistrationMessages), [intl.locale]);

	const {errors, setFieldValue, touched, handleChange, values, handleBlur, handleSubmit} = useFormik<FttbAddressForm>({
		initialValues: initialValues, onSubmit, validationSchema
	});
	const formik = {errors, setFieldValue, touched, handleChange, values, handleBlur};
	const {validate: validatePreviousActivationStatus,loading:  loadingPreviousActivationStatus, data} = useGisHousePreviousActivationStatus({
		setPreviousHouseActivationStatus,
		setConnectionExist,
		setFieldValue
	});

	React.useEffect(() => {
		if (data?.gis_house_previous_activation_status?.msisdn_with_max_last_rc_date) {
			const msisdn_with_max_last_rc_date = data.gis_house_previous_activation_status?.msisdn_with_max_last_rc_date ?? "";
			setMsisdnWithMaxLastRCDate(msisdn_with_max_last_rc_date);
		}else setMsisdnWithMaxLastRCDate("");
	}, [data]);


	return (
		<FormStepWrapper<FmcRouteState>
			isValid={!(loadingMsisdn || loadingPreviousActivationStatus)}
			formId={formId}>
			<form id={formId} onSubmit={handleSubmit}>
				<div className={"row"}>
					<div className="col-12 col-sm-6">
						<InputFieldFormik
							required
							name="contact_phone"
							id="contact_phone"
							disabled={true}
							attr={{maxLength: config.PHONE_LENGTH}}
							label={labels.contact_phone}
							value={formik.values.contact_phone}
							formik={formik}
						/>
					</div>
					<UserNameInfo formik={formik as (FormikState<FttbAddressForm> & FormikHandlers & FormikHelpers<FttbAddressForm>)}/>
				</div>
				<AddressValidationForm
					hasAvailableHouse={hasAvailableHouse}
					setAvailableHouse={setAvailableHouse}
					formik={formik}
					validatePreviousActivationStatus={validatePreviousActivationStatus}
					setPreviousHouseActivationStatus={setPreviousHouseActivationStatus}
					setGigabitAvailable={setGigabitAvailable}
					checkGigabitEligibility={true}
					setNetworktype={setNetworktype}
				/>
				<div className={"mt-2"}>
					<ErrorMessage error={error}/>
				</div>
				<div className={"mt-2"}>
					{!error &&
					<FttbStatus values={formik.values} availableHouse={hasAvailableHouse} networkType={networkType} />}
				</div>
				{housePreviousActivationStatus && hasAvailableHouse &&
						<SimpleConnection connectionExist={connectionExist} formik={formik}/>}
				{isGigabitAvailable && <Gigabit/>}
			</form>
			{(loadingMsisdn || loadingPreviousActivationStatus) && (<CircularProgress/>)}
		</FormStepWrapper>
	);
};


export { FmcAddress };
