import {
	Maybe,
	Mutation,
	Package,
	Query,
	Scalars,
	Language,
	SubscriptionTypeCategoryEnum,
	GisCity,
	GisRegion,
	GisHouse,
	GisStreet,
	GisDistrict,
	UserDealerIdentity,
} from "./types";
import { UpdateStatusEnum } from "../shared/types";

export interface QuerySubscriptionsRequestArgs
	extends QuerySubscriptionRequestArgs {
	packageCode: Scalars["ID"];
}

export interface QuerySubscriptionRequestArgs {
	msisdn: Scalars["String"];
	requestId: Scalars["ID"];
	new_msisdn?: Scalars["String"];
	reason?: Scalars["String"];
	paramsMutation?: any;
}

export interface ModalStatus {
	__typename?: "ModalStatus";
	status: Scalars["Boolean"];
	message: Scalars["String"];
	title?: Scalars["String"];
}

export type ConfirmationActionType<T> = {
	[K in keyof Omit<Mutation, "__typename">]: T;
};

export interface ActionCodeStatus {
	__typename?: "ActionCodeStatus";
	id?: Scalars["ID"];
	code?: Scalars["String"];
	status?: Scalars["Boolean"];
	code3rdParty?: Scalars["Int"];
}

export interface ActionDetails {
	__typename?: "ActionDetails";
	id?: Scalars["ID"];
	codes?: [ActionCodeStatus];
}
export interface ConfirmationAction
	extends ConfirmationActionType<ActionDetails> {
	__typename?: "ConfirmationAction";
	id: Scalars["ID"];
	action: Scalars["String"];
	status: Scalars["Boolean"];
	code?: Scalars["String"];
	code3rdParty?: Scalars["Int"];
}

export type MutationShowActionModalArgs = {
	action: Scalars["String"];
	code?: Scalars["String"];
	code3rdParty?: Scalars["Int"];
};

export type MutationResolveActionModalArgs = {
	spinnerStatus: Scalars["Boolean"];
	globalSpinner?: Scalars["Boolean"];
	action: Scalars["String"];
	code?: Scalars["String"];
	code3rdParty?: Scalars["Int"];
};

export type MutationResolvePoolingArgs = {
	code: Scalars["String"];
	action: Scalars["String"];
};

export type MutationUpdateValuesArgs = {
	status: UpdateStatusEnum;
};

export type MutationApplicationContextArgs = {
	subscriptionId: Scalars["String"];
	msisdn: Scalars["String"];
	locale: Scalars["String"];
};
export type MutationAddUploadDocumentArgs = {
	files: Array<Scalars["Upload"]>;
};

export type MutationRemoveUploadDocumentArgs = {
	index: Scalars["Int"];
};

export interface ModalStatusArgs {
	modal: ModalStatus;
}
export interface SpinnerStatusArgs {
	spinner: SpinnerStatus;
}

export interface SpinnerStatus {
	__typename?: "SpinnerStatus";
	status: Scalars["Boolean"];
}
export interface ApplicationContext {
	__typename?: "ApplicationContext";
	msisdn: Scalars["String"];
	subscriptionId: Scalars["String"];
	locale: Scalars["String"];
}

export interface AcquisitionFlow {
	__typename: "AcquisitionFlow";
	id: Scalars["ID"];
	orderId: Scalars["String"];
	msisdn: Scalars["String"];
	plan: TariffPlan;
	options: [Package];
	iccId: Scalars["String"];
	reservationId: Scalars["String"];
	personalInfo: PersonalInfo;
	offerCode: Scalars["String"];
}

export interface OtpModal {
	msisdn: string;
	status: boolean;
	code: string;
	error: string;
	loading: boolean;
	__typename: "OtpModal";
}
export interface Modals {
	otp: OtpModal;
	__typename: "Modals";
}
export interface GisCitySearchResult {
	selected?: GisCity[]|null;
	result?: GisCity[]|null;
}
export interface GisStreetSearchResult {
	selected?: GisStreet[]|null;
	result?: GisStreet[]|null;
}
export interface GisRegionSearchResult {
	selected?: GisRegion[]|null;
	result?: GisRegion[]|null;
}
export interface GisDistrictSearchResult {
	selected?: GisDistrict[]|null;
	result?: GisDistrict[]|null;

}export interface GisHouseSearchResult {
	selected?: GisHouse[]|null;
	result?: GisHouse[]|null;
}


export interface PersonalInfo {
	__typename?: "PersonalInfo";
	id?: Scalars["String"];
	withoutDocument?: Scalars["Boolean"];
	additional_document?: Scalars["Boolean"];
	first_name?: Scalars["String"];
	skipPersonalData?: Scalars["Boolean"];
	last_name?: Scalars["String"];
	contact_phone?: Scalars["String"];
	email?: Scalars["String"];
	gender?: Scalars["String"];
	language?: Scalars["String"];
	date_of_birth?: Scalars["String"];
	country?: Scalars["String"];
	city?: GisCitySearchResult;
	street?: GisStreetSearchResult;
	house_number?: GisHouseSearchResult;
	co_address?: Scalars["String"];
	invoice_delivery_method?: Scalars["String"];
	title?: Scalars["String"];
	is_company?: Scalars["Boolean"];
	marketing_own?: Scalars["Boolean"];
	id_type?: Scalars["String"];
	id_number?: Scalars["String"];
	nationality?: Scalars["String"];
	occupation?: Scalars["String"];
	isEmpty?: Scalars["Boolean"];
	district?: GisDistrictSearchResult;
	province?: GisRegionSearchResult;
	additional_emails?: Scalars["String"];
	id_document_number?: Scalars["String"];
	id_document_type?: Scalars["String"];
	streetType?: Scalars["String"];
	building?: Scalars["String"];
	apartment?: Scalars["String"];
	password?: Scalars["String"];
	first_name_alt?: Scalars["String"];
	middle_name?: Scalars["String"];
	id_doc_issued_by?: Scalars["String"];
	id_doc_issue_date?: Scalars["String"];
	id_doc_valid_till_date?: Scalars["String"];
	individual_tax_number?: Scalars["String"];
	postal_code?: Scalars["String"];
	name?: Scalars["String"];
}

export interface TariffPlan {
	__typename?: "TariffPlan";
	id?: Scalars["ID"];
	offerId?: Scalars["ID"];
	offerCode: Scalars["String"];
	name: Scalars["String"];
	type: Scalars["String"];
	code: Scalars["String"];
	change_price: Scalars["String"];
	change_price_currency: Scalars["String"];
	fee: Scalars["String"];
	description: Scalars["String"];
	feeCurrency: Scalars["String"];
	feeRecurringPeriod: Scalars["String"];
	offerSimCardCode: Scalars["String"];
	isEmpty?: Scalars["Boolean"];
	selectedOptionCode?: Scalars["String"];
}

export interface SelectedTariffPlanDetails {
	__typename?: "SelectedTariffPlanDetails";
	id: Scalars["ID"];
	offerId?: Scalars["ID"];
	offerCode?: Scalars["String"];
	name: Language;
	planType: Scalars["String"];
	code: Scalars["String"];
	periodicAmount?: number;
	periodicUnit?: string;
	short_description?: Language;
	medium_description?: Language;
	long_description?: Language;
	info_text?: Language;
	long_info_text?: Language;
	change_price: number;
	change_price_currency: Scalars["String"];
	fee: number;
	description: Scalars["String"];
	feeCurrency: Scalars["String"];
	feeRecurringPeriod: Scalars["String"];
	offerSimCardCode?: Scalars["String"];
	selectedOptionCode?: Scalars["String"];
	selectedOptionId?: Scalars["String"];
	subscription_type_category?: Scalars["String"];
	billing_type?: Scalars["String"];
}

export interface CustomerAddress {
	id: "new_customer_address";
	__typename: "NewCustomerAddress";
	country: Scalars["String"];
	province: Scalars["String"];
	language: Scalars["String"];
	city: Scalars["String"];
	streetType: Scalars["String"];
	street: Scalars["String"];
	building: Scalars["String"];
	house_number: Scalars["String"];
	email: Scalars["String"];
	additional_emails: Scalars["String"];
	postal_code: Scalars["String"];
	apartment: Scalars["String"];
	district: Scalars["String"];
	password: Scalars["String"];
	contact_phone: Scalars["String"];
	isEmpty: Scalars["Boolean"];
}

export interface CustomerInfo {
	id: "new_customer_info";
	__typename: "NewCustomerInfo";
	first_name: Scalars["String"];
	last_name: Scalars["String"];
	gender: Scalars["String"];
	date_of_birth: Scalars["String"];
	middle_name: Scalars["String"];
	first_name_alt: Scalars["String"];
	nationality: Scalars["String"];
	isEmpty: Scalars["Boolean"];
}

export interface CustomerIdDocuments {
	id: "new_customer_id_documents";
	__typename: "NewCustomerIdDocuments";
	id_document_number: Scalars["String"];
	id_document_type: Scalars["String"];
	comments: Scalars["String"];
	password: Scalars["String"];
	id_doc_issued_by: Scalars["String"];
	id_doc_issue_date: Scalars["String"];
	id_doc_valid_till_date: Scalars["String"];
	individual_tax_number: Scalars["String"];
	isEmpty: Scalars["Boolean"];
}

export enum FmcPreOrderState {
	ACTIVE = 1,
	EXPIRED = 2,
	CANCLED = 3,
}

export interface NewCustomerFlow {
	id: "new_customer";
	__typename: "newCustomerFlow";
	address: CustomerAddress;
	info: CustomerInfo;
	idDocuments: CustomerIdDocuments;
}

export interface ExistingCustomer {
	__typename: "ExistingCustomerInfo";
	id: "existing_customer_info";
	isEmpty: Scalars["Boolean"];
	language: Scalars["String"];
	billing_status: Scalars["String"];
	account_id: Scalars["String"];
	customerInfo: CustomerInfo;
}

export interface ExistingCustomerFlow {
	id: "existing_customer";
	__typename: "ExistingCustomer";
	msisdn: Scalars["String"];
	plan: TariffPlan;
	options: [Package];
	iccId: Scalars["String"];
	customer: ExistingCustomer;
}

export interface LocalQuery {
	__typename?: "Query";
	documentUpload?: Array<Scalars["Upload"]>;
	context?: Maybe<ApplicationContext>;
	auth: {
		isAuthenticated: boolean;
	},
	dealerIdentity: UserDealerIdentity;
	modal?: ModalStatus;
	fttb?: Fttb;
	fttbSelectedPlan?: SelectedTariffPlanDetails;
	confirmationAction?: ConfirmationAction;
	spinner?: SpinnerStatus;
	acquisitionFlow?: AcquisitionFlow;
	newCustomerFlow?: NewCustomerFlow;
	acquisitionFlowExistingCustomer?: ExistingCustomerFlow;
	modals: Modals;
}
export interface AllQuery extends LocalQuery, Query {}

export interface LocalMutation {
	__typename?: "Mutation";
	showModal?: void;
	setApplicationContext?: void;
	showActionModal?: void;
	resolveActionModal?: void;
	resolvePooling?: void;
	addDocumentToUpload?: void;
	clearDocumentUpload?: void;
	updateAuth?: void;
	setFttbTariffPlan?: void;
	clearFttbFlow?: void;
	setDealerIdentity?: void,
	handleOtpOpen: void;
	handleOtpClose: void;
	handleOtpActionLoading: void;
	handleOtpError: void;
	handleOtpSet: void;
}

export type MutationModalArgs = {
	status: Scalars["Boolean"];
	message: Scalars["String"];
	title?: Scalars["String"];
};

export type MutationHandleOtpSetArgs = {
	code: Scalars["String"];
};
export type MutationHandleOtpError = {
	error: Scalars["String"];
};
export type MutationHandleOtpActionLoading = {
	loading: Scalars["Boolean"];
};

export interface Address extends GisIds {
	__typename: Scalars["String"];
	id: Scalars["ID"];
	country: Scalars["String"];
	country_code: Scalars["String"];
	city: Scalars["String"];
	street: Scalars["String"];
	house: Scalars["String"];
	flat: Scalars["String"];
	simpleConnection: Scalars["Boolean"];
	province: Scalars["String"];
	zip_code?: Scalars["String"]
}
export interface GisIds {
	gisProvinceId: string;
	gisDistrictId?: string;
	gisCityId: string;
	gisStreetId: string;
	gisHouseId: string;
	gisZipId: string;
}

export type Fttb = {
	__typename: Scalars["String"];
	id: Scalars["ID"];
	contact_phone: Scalars["String"];
	confirmation_code: Scalars["String"];
	msisdn: Scalars["String"];
	plan: SelectedTariffPlanDetails;
	options: Package[];
	address: Address;
};

export interface Params {
    subscriptionId: string;
    msisdn: string;
}
