import React from "react";
import { prepareClasses, RouteConfig } from "../utils/scss/classes";

interface CustomerNavigationProps<T> {
    pathname: string;
    baseRouteLength: number;
    routesConfig: T;
    routesIndexesConfig: RouteConfig;
}

const CustomerNavigation: <T extends readonly string[]>(p: React.PropsWithChildren<CustomerNavigationProps<T>>) => React.ReactElement<CustomerNavigationProps<T>> = (props) => {
	const { pathname, baseRouteLength, routesConfig, routesIndexesConfig } = props;
	const [classes, currentStep] = prepareClasses(pathname, routesIndexesConfig, baseRouteLength, routesConfig);
	return (
		<div className="CustomerNavigation">
			<div className="d-none d-sm-block">
				<div className="w-wizard">
					{routesConfig.map((item) => (
						<div key={item} onClick={routesIndexesConfig[item].link} className={classes[item]}>
							<div className="w-wizard-step-number">{routesIndexesConfig[item].step}</div>
							<div className="w-wizard-step-label">
								{routesIndexesConfig[item].message}
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="d-sm-none">
				<div className="w-wizard">
					<div className={classes[currentStep]}>
						<div className="w-wizard-step-number">{routesIndexesConfig?.[currentStep]?.step}</div>
						<div className="w-wizard-step-label">
							{routesIndexesConfig?.[currentStep]?.message}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { CustomerNavigation };
