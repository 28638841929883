import gql from "graphql-tag";

const EDIT_CUSTOMER = gql`
  mutation editCustomer($customer_id: ID!, $details: CustomerInput!) {
    edit_customer(customer_id: $customer_id, details: $details) {
        request_id,
        status,
        error
    }
  }
`;

const CHECK_CUSTOMER_CREDIT_DECISION = gql`
    mutation customerCreditDecision($orderer: CdtOrdererNewResidentialCreditInput!, $order: CdtOrderInput!) {
        check_cdt_consumer_credit_decision(orderer: $orderer, order: $order) {
            id,
            errors,
            order_id,
            credit_decision,
            business_instructions,
            allowed_payment_methods,
            credit_profile_proposal_id,
            allowed_bill_payment_methods,
        }
    }
`;

export {
	CHECK_CUSTOMER_CREDIT_DECISION,
	EDIT_CUSTOMER
};