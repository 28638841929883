import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { CommonMessages } from "../../../../Common.messages";
import { SearchExistingCustomer } from "./SearchExistingCustomer";

const ExistingCustomer = () => {
	const [showSearchExistingCustomer, setShowSearch] = useState<boolean>(false);
	const onClickExistingCustomer = () => setShowSearch(!showSearchExistingCustomer);
	return (
		<>
			<button
				className="btn btn-block btn-secondary"
				onClick={onClickExistingCustomer}
			>
				<FormattedMessage {...CommonMessages.customerExist} />
			</button>
			{showSearchExistingCustomer && <SearchExistingCustomer />}
		</>
	);
};

export { ExistingCustomer };