import React from "react";

interface DummyOptionCardProps {
    title: string|Element;
    description?: string;
}

const DummyOptionCard = (props: DummyOptionCardProps) => {

	return (
		<div className="col-12 col-sm-4 mb-1">
			<div className="bg-white border-info card text-dark h-100">
				<div className="card-body">
					<h5 className="card-title">{props.title}</h5>
					<p className="card-text">{props.description}</p>
				</div>
				<div className="card-footer text-success">
				</div>
			</div>
		</div>
         
	);
};

export { DummyOptionCard };