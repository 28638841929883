import gql from "graphql-tag";

export const CREATE_SUBSCRIPTION_PREORDER = gql`
	mutation createSubscriptionPreorder($details: Preorder!) {
		create_subscription_preorder(details: $details) {
			request_id
			status
			error
		}
	}
`;
