import React from "react";
import { useAuthDataContext } from "../hooks/AuthentificationProvider";
import { DealerIdentities } from "./DealerIdentities";

interface MsisdnTopBarInfoProps {
    children: JSX.Element|string;
    showIcon?: boolean;
}

const MsisdnTopBarInfo = (props: MsisdnTopBarInfoProps) => {
	const { showIcon = true} = props;
	const {currentDealerIdentety} = useAuthDataContext()!;

	return (
		<nav className="w-nav-context">
			<div className="w-nav-context-group">
				<div className="w-nav-context-item">
					{showIcon && <i className="fas fa-phone-square btn-icon-left"></i>}{" "}
					{props.children}
				</div>
				<div className="w-nav-context-subitem">
					{currentDealerIdentety?.reseller_name || ""}
				</div>
			</div>
			<div className="w-nav-context-actions">
				<DealerIdentities />
			</div>
		</nav>
	);
};

export { MsisdnTopBarInfo };
