import React, {useEffect} from "react";
import { Switch } from "react-router";
import {FormattedMessage, useIntl} from "react-intl";
import {flowActions, useStoreContext} from "../../../shared/components/navigation/StepStateProvider";
import {
	MNP_FLOW_CONTACT_INFO_POSTPAID,
	MNP_FLOW_MSISDN_POSTPAID,
	MNP_FLOW_OPTION_POSTPAID,
	MNP_FLOW_PLAN_POSTPAID,
	MNP_FLOW_SUMMARY_POSTPAID,
	MNP_FLOW_BILLING_ACCOUNT_POSTPAID,
	MNP_FLOW_ICC_POSTPAID,
	MNP_FLOW_EXISTING_CONTACT_INFO_POSTPAID,
} from "./MNPFlowRoutes";
import {MsisdnTopBarInfo} from "../../../shared/components";
import {ErrorBoundaryRoute} from "../../../shared/error/ErrorBoundaryRoute";
import {b2bFttbFlowRoutesConfig} from "../../../routes/RoutesMap";
import {MNPMsisdnValidationPage} from "./flow/MNPMsisdnValidationPage";
import {MnpSummary} from "./flow/MnpSummary";
import {MnpContactInfo} from "./flow/MnpContactInfo";
import {MnpOptions} from "./flow/MnpOptions";
import {MnpPlan} from "./flow/MnpPlan";
import {MnpBillingAccount} from "./flow/MnpBillingAccount";
import {MnpIccId} from "./flow/MnpIccId";
import {MnpExistingContactInfo} from "./flow/MnpExistingContactInfo";
import {MnpMessages} from "../Mnp.messages";
import AcquisitionMessages from "../../acquisitionFlow/Acquisition.messages";
import {
	cleanNavigationStateCallBack,
	useNavigationState
} from "../../../shared/components/navigation/useNavigationState";
import {MNPRouteState} from "../MNPFlowRoutes";

const PostpaidFttbPageRouter = () => {
	const intl = useIntl();
	const {dispatch} = useStoreContext();
	const state = useNavigationState<MNPRouteState>();
	const postpaidMnpRoutes = [
		{
			pathname: MNP_FLOW_MSISDN_POSTPAID,
			message: intl.formatMessage({ ...MnpMessages.mnpMsisdn })
		},
		{
			pathname: MNP_FLOW_PLAN_POSTPAID,
			message: intl.formatMessage({ ...AcquisitionMessages.plan })
		},
		{
			pathname: MNP_FLOW_OPTION_POSTPAID,
			message: intl.formatMessage({ ...AcquisitionMessages.options })
		},
		{
			pathname: MNP_FLOW_ICC_POSTPAID,
			message: intl.formatMessage({ ...AcquisitionMessages.iccid })
		},
		{
			pathname: MNP_FLOW_CONTACT_INFO_POSTPAID,
			message: intl.formatMessage({ ...AcquisitionMessages.contactInfo })
		},
		{
			pathname: MNP_FLOW_SUMMARY_POSTPAID,
			message: intl.formatMessage({ ...AcquisitionMessages.summary })
		}
	];
	const postpaidMnpRoutesExisting = [
		{
			pathname: MNP_FLOW_MSISDN_POSTPAID,
			message: intl.formatMessage({ ...MnpMessages.mnpMsisdn })
		},
		{
			pathname: MNP_FLOW_BILLING_ACCOUNT_POSTPAID,
			message: intl.formatMessage({ ...AcquisitionMessages.billing })
		},
		{
			pathname: MNP_FLOW_PLAN_POSTPAID,
			message: intl.formatMessage({ ...AcquisitionMessages.plan })
		},
		{
			pathname: MNP_FLOW_OPTION_POSTPAID,
			message: intl.formatMessage({ ...AcquisitionMessages.options })
		},
		{
			pathname: MNP_FLOW_ICC_POSTPAID,
			message: intl.formatMessage({ ...AcquisitionMessages.iccid })
		},
		{
			pathname: MNP_FLOW_EXISTING_CONTACT_INFO_POSTPAID,
			message: intl.formatMessage({ ...AcquisitionMessages.contactInfo })
		},
		{
			pathname: MNP_FLOW_SUMMARY_POSTPAID,
			message: intl.formatMessage({ ...AcquisitionMessages.summary })
		}
	];
	useEffect(() => {
		if (state?.isNewCustomer) {
			dispatch(flowActions.changeFlow(postpaidMnpRoutes ));
		} else {
			dispatch(flowActions.changeFlow(postpaidMnpRoutesExisting ));
		}

		return cleanNavigationStateCallBack;
	}, [state?.isNewCustomer]);
	return (
		<>
			<MsisdnTopBarInfo>
				<FormattedMessage {...MnpMessages.postpaidMnpMsisdn} />
			</MsisdnTopBarInfo>
			<div id="w-app-body">
				<Switch>
					<ErrorBoundaryRoute exact path={MNP_FLOW_MSISDN_POSTPAID} component={MNPMsisdnValidationPage}/>
					<ErrorBoundaryRoute exact path={MNP_FLOW_BILLING_ACCOUNT_POSTPAID} component={MnpBillingAccount}/>
					<ErrorBoundaryRoute exact path={MNP_FLOW_PLAN_POSTPAID} component={MnpPlan}/>
					<ErrorBoundaryRoute exact path={MNP_FLOW_ICC_POSTPAID} component={MnpIccId}/>
					<ErrorBoundaryRoute exact path={MNP_FLOW_OPTION_POSTPAID} component={MnpOptions}/>
					<ErrorBoundaryRoute exact path={MNP_FLOW_CONTACT_INFO_POSTPAID} component={MnpContactInfo}/>
					<ErrorBoundaryRoute exact path={MNP_FLOW_EXISTING_CONTACT_INFO_POSTPAID} component={MnpExistingContactInfo}/>
					<ErrorBoundaryRoute exact path={MNP_FLOW_SUMMARY_POSTPAID} component={MnpSummary}/>
				</Switch>
			</div>
		</>
	);
};

export { PostpaidFttbPageRouter, b2bFttbFlowRoutesConfig };