import React, {useState} from "react";
import { FormattedMessage } from "react-intl";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import { OptionProductChecked } from "../../../modules/acquisitionFlow/NewCustomer/Pages/OptionsPage/OptionProduct";
import {GET_OPTIONAL_CHILD_PRODUCTS, GET_OPTIONAL_PRODUCTS} from "../../../graphql/queries/acquisitionFlow";
import { Query, Package, Maybe, ProductFamily } from "../../../graphql/types";
import { PlanState } from "../tariffPlan/Plan";
import { CircularProgress } from "..";
import { ChangePlanMessages } from "../../../modules/change-bar/plan/ChangePlan.messages";
import { OptionsFamilies } from "../../../modules/acquisitionFlow/NewCustomer/Pages/OptionsPage/OptionsFamilies";
import { prepareUncheckClick } from "../../utils";

export interface OptionState {
    options: Package[];
}
type OptionsCommon = OptionState & PlanState;
export interface OptionsProps<T extends OptionsCommon> {
    state?: Package[];
    setState: (value: Array<Package>) => void;
    planId: string;
}
function Options<T extends OptionsCommon> (props: OptionsProps<T>) {
	const { state = [], setState, planId } = props;

	const {data, loading} = useQuery<Query>(GET_OPTIONAL_PRODUCTS, {
		variables: { subscription_type_id: planId },
	});

	const [getChildOffers, {data: childOffersData, loading: loadingChildOffers}] = useLazyQuery<Query>(GET_OPTIONAL_CHILD_PRODUCTS);

	const [isParentChecked, setIsParentChecked] = useState<boolean>(false);
	const [parentCode, setParentCode] = useState<string | null | undefined>("");
	const onChecked = (value: OptionProductChecked) => {
		setIsParentChecked(value.checked);
		setParentCode(value.optionalProduct.code);
		getChildOffers({ variables: { subscription_type_id: planId, parent_product: Number(value.optionalProduct.id), relation_type: "all" }});
		if (value.checked) {
			setState([...state, value.optionalProduct]);
		} else {
			if (!loadingChildOffers) {
				setState(state.filter((pack) => {
					return  prepareUncheckClick(pack, value, childOffersData);
				}));
			}
		}
	};

	const onChildChecked = (value: OptionProductChecked) => {
		if (value.checked) {
			setState([...state, value.optionalProduct]);
		} else {
			setState(state.filter(pack => pack.code !== value.optionalProduct.code));
		}
	};
	const available_child_offers = childOffersData?.child_offers;

	return (
		<>
			{loading ? <CircularProgress/> : !(data?.optional_products?.length! > 0) &&
				<div className="column">
					<FormattedMessage {...ChangePlanMessages.optionalProductsNotFound} />
				</div>
			}
			<table className="table w-tile-table">
				{data?.optional_products?.map((family: Maybe<ProductFamily>) => (
					family?.packages?.length && family.packages.length > 0) &&
						<OptionsFamilies
							key={family.id!}
							family={family}
							onChecked={onChecked}
							isParentChecked={isParentChecked}
							childOffersData={available_child_offers}
							parentCode={parentCode}
							onChildChecked={onChildChecked}
							loadingChildOffers={loadingChildOffers}
							selectedOptionalProduct={state}
						/>
				)}
			</table>
		</>
	);
}

export { Options};
