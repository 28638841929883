import React, { FC, useMemo } from "react";
import { InputFieldFormik } from "../../shared/components/InputField";
import { initialLabels } from "../../shared/utils";
import { useIntl, FormattedMessage } from "react-intl";
import { RegistrationMessages } from "../registration/Registration.messages";
import { FTTBMessages } from "./FTTB.messages";
import { OptionsCard } from "../../shared/components/option/OptionsCard";
import { Package } from "../../graphql/types";
import { SelectedTariffPlanDetails } from "../../graphql/localTypes";
import { config } from "../../config/constants";
import moment from "moment";
import { PlanCard } from "../fmc/Pages/Summary/PlanCard";
import { FttbOptionState } from "./fttb.types";
import { FormikTouched, FormikErrors, FormikState, FormikHandlers, FormikHelpers } from "formik";
import { DummyOptionCard } from "../../shared/components/option/DummyOptionCard";
import { UserNameInfoPartialProps, UserNameInfo } from "../../shared/components/formBlocks/UserNameInfo";
interface FttbSummaryDetailsProps {
	bundles: Package[];
	options: FttbOptionState|null|undefined;
	selectedFttbTariffPlan?: SelectedTariffPlanDetails;
	simpleConnection: boolean;
	isToday: boolean;
	formik: {
		values: DettailsValues;
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean | undefined
		) => any;
		touched: FormikTouched<DettailsValues>;
		handleChange: (
			eventOrPath: string | React.ChangeEvent<DettailsValues>
		) =>
			| void
			| ((
					eventOrTextValue: string | React.ChangeEvent<DettailsValues>
			  ) => void);
		errors: FormikErrors<DettailsValues>;
	};
}
interface DettailsValues extends UserNameInfoPartialProps {
	phone: string;
	city: string;
	province: string;
	street: string;
	house: string;
	flat: string;
}
const lablesField = {
	house_number: "",
	apartment: "",
	street: "",
	province: "",
	contact_phone: "",
	city: "",
	flat: "",
};
const FttbSummaryDetails: FC<FttbSummaryDetailsProps> = props => {
	const intl = useIntl();
	const { simpleConnection, bundles, selectedFttbTariffPlan, options, isToday, formik } = props;
	const {values} = formik;
	const labels = useMemo(
		() => initialLabels(intl, lablesField, RegistrationMessages),
		[intl.locale]
	);

	return (
		<>
			<div className="row">
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.contact_phone}
						id="phone"
						attr={{ disabled: true }}
						name="phone"
						value={values.phone}
						formik={formik}
					/>
				</div>
				<UserNameInfo disabled={true} formik={formik as (FormikState<DettailsValues> & FormikHandlers & FormikHelpers<DettailsValues>)}/>
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.province}
						id="province"
						attr={{ disabled: true }}
						name="province"
						value={values.province}
						formik={formik}
					/>
				</div>
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.city}
						id="city"
						attr={{ disabled: true }}
						name="city"
						value={values.city}
						formik={formik}
					/>
				</div>
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.street}
						id="street"
						attr={{ disabled: true }}
						name="street"
						value={values.street}
						formik={formik}
					/>
				</div>
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.house_number}
						id="house"
						attr={{ disabled: true }}
						name="house"
						value={values.house}
						formik={formik}
					/>
				</div>
				<div className="col-12 col-md-6">
					<InputFieldFormik
						label={labels.flat}
						id="flat"
						attr={{ disabled: true }}
						name="flat"
						value={values.flat}
						formik={formik}
					/>
				</div>
				{simpleConnection && <div className="col-12">
					<h4>
						<FormattedMessage {...FTTBMessages.simpleConnectionHasBeenSelected} />
					</h4>
				</div>}
				{!simpleConnection && <div className="col-12">
					<h4>
						<FormattedMessage {...FTTBMessages.connectionDay} />{" "}
						{options?.desiredConnectionDate
							? moment(options?.desiredConnectionDate).format(
								isToday
									? config.DATE_PICKER_FORMAT
									: config.DATE_TIME_FORMAT
							  )
							: "-"}
					</h4>
				</div>}
			</div>
			{selectedFttbTariffPlan && <div className="row">
				 <div className="col-12">
					<PlanCard
						change_price={selectedFttbTariffPlan?.change_price}
						fee={selectedFttbTariffPlan?.fee}
						periodic_unit={selectedFttbTariffPlan?.periodicUnit}
						periodic_amount={selectedFttbTariffPlan?.periodicAmount}
						name={selectedFttbTariffPlan?.name}
						medium_description={selectedFttbTariffPlan?.medium_description}
						long_description={selectedFttbTariffPlan?.long_description}
					/>
				</div>
			</div>}
			<div className="row">
				<div className="col-12 my-3">
					<OptionsCard packages={bundles} externalPackages={
						<>
							{props.options?.complexOffer && <DummyOptionCard title={intl.formatMessage(FTTBMessages.complexOffer)}/>}
							{props.options?.starterPack && <DummyOptionCard title={intl.formatMessage(FTTBMessages.starterPack)}/>}
							{props.options?.comment &&
								<DummyOptionCard
									title={intl.formatMessage(FTTBMessages.comment)}
									description={props.options.comment}
								/>}
						</>
					}/>
				</div>
			</div>
		</>
	);
};
export { FttbSummaryDetails };
