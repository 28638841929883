import ru from "../../translations/ru.json";
import uk from "../../translations/uk.json";
import en from "../../translations/en.json";
import React, { createContext, useContext, useState } from "react";
import { IntlProvider } from "react-intl";
import { Storage } from "../../shared/utils/storageUtils";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk"; 

const languages = {
	en: en || {},
	ru: ru || {},
	uk: uk || {},
};
const LANGUAGE_KEY = "lang";
const CustomIntlContext = createContext({} as any);

const CustomIntlContextProvider = (props: any) => {
	const locale = Storage.local.get(LANGUAGE_KEY) || "en";
	const [lang, setLang] = useState<string>(locale);
	moment.locale(locale);

	const selectLanguage = (lang: string) => {
		Storage.local.set(LANGUAGE_KEY, lang);
		setLang(lang);
		moment.locale(locale);
	};
	return (
		<CustomIntlContext.Provider value={{lang, selectLanguage}}>
			<IntlProvider locale={lang} messages={languages[lang]}>
				{props.children}
			</IntlProvider>
		</CustomIntlContext.Provider>
	);
};

export const useCustomIntlContext = () => useContext(CustomIntlContext);

export { CustomIntlContextProvider };