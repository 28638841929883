import React, {useState, useMemo, FC, useCallback} from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { FormikHandlers, FormikHelpers, FormikState, useFormik } from "formik";
import {useIntl} from "react-intl";
import {
	LineTypeEnum,
	Mutation,
	MutationReserve_MsisdnArgs,
	Query,
	QuerySubscriptionsArgs
} from "../../../../graphql/types";
import {GET_B2B_SINGLE_MSISDNS} from "../../../../graphql/queries/msisdn";
import {FTTBMessages} from "../../FTTB.messages";
import {FttbAddressForm} from "../../fttb.types";
import {ACTIVE, config} from "../../../../config/constants";
import {addressValidation} from "../../Pages/Address/Address.schema";
import {initialLabels} from "../../../../shared/utils";
import RegistrationMessages from "../../../registration/Registration.messages";
import {FormStepWrapper} from "../../../../shared/components/navigation/FormStepWrapper";
import {CircularProgress, InputFieldFormik, SuccessMessageTemplate} from "../../../../shared/components";
import {UserNameInfo} from "../../../../shared/components/formBlocks/UserNameInfo";
import {AddressValidationForm} from "../../Pages/Address/Components/AddressValidationForm";
import {ErrorMessage} from "../../../../shared/components/ErrorMessageTemplate";
import {FttbStatus} from "../../Pages/Address/Components/FTTBStatus";
import {SimpleConnection} from "../../../../shared/components/SimpleConnection";
import {Address as Addresses} from "../../../../graphql/localTypes";
import {RESERVE_MSISDN} from "../../../../graphql/mutations/subscription";
import {useGisHousePreviousActivationStatus} from "../../../../shared/hooks/useGisHousePreviousActivationStatus";
import { B2B_FTTB_FLOW_SEARCH_COMPANY_PATH} from "../Routes/B2BFttbFlowRoutes";
import {B2BFttbRouteState} from "./b2bFttb.type";
import {addPrefixToMsisdn, validateMsisdn} from "../../../../shared/utils/utils";
import {debounce} from "lodash";
import {GET_SUBSCRIPTIONS} from "../../../../graphql/queries/subscription";
import { useNavigationState, useUpdateNavigatioState } from "../../../../shared/components/navigation/useNavigationState";


const formId = "fttb-address-form";

const Address: FC<RouteComponentProps<never, StaticContext, B2BFttbRouteState>> = props => {
	const {history} = props;
	const state  = useNavigationState<B2BFttbRouteState>();
	const updateNavigationState  = useUpdateNavigatioState<B2BFttbRouteState>();
	const intl = useIntl();
	const [msisdnValidationErorr, setMsisdnValidationErorr] = useState<string>("");
	const [getSubscriptiions, {loading: loadingGetSubscriptions}] = useLazyQuery<Required<Pick<Query, "subscriptions">>, QuerySubscriptionsArgs>(GET_SUBSCRIPTIONS, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			if (!data.subscriptions || data.subscriptions?.length === 0) {
				setMsisdnValidationErorr("");
			}
            data.subscriptions?.forEach(sub => {
            	if (sub?.status === ACTIVE
                    && (sub?.subscription_type?.line_type === LineTypeEnum.Fttb || sub?.subscription_type?.line_type === LineTypeEnum.Fmc)) {
            		setMsisdnValidationErorr(intl.formatMessage(FTTBMessages.fttbConnectionExist));
            	}
            });
		}
	});
	const getAllSub = useCallback(debounce((contactPhone: string) => {
		getSubscriptiions({
			variables: {
				contact_phone: contactPhone
			}
		});
	}, 600), []);
	const [b2bFttbState, setB2bFttbState] = useState<B2BFttbRouteState|undefined|null>(state);
	const [error, setErorr] = useState<string>("");
	const [connectionExist, setConnectionExist] = useState<string>("");
	const [housePreviousActivationStatus, setPreviousHouseActivationStatus] = useState<boolean>(Boolean(state?.housePreviousActivationStatus));
	const [reserveMsisdn, {loading: loadingMsisdn}] = useMutation<Required<Pick<Mutation, "reserve_msisdn">>, MutationReserve_MsisdnArgs>(RESERVE_MSISDN);
	const initialValues = {
		last_name: state?.last_name || "",
		first_name: state?.first_name || "",
		middle_name: state?.middle_name || "",
		contact_phone: state?.contact_phone || "",
		city: state?.address?.city || "",
		street: state?.address?.street || "",
		gisStreetId: state?.address?.gisStreetId || "",
		house_number: state?.address?.house || "",
		flat: state?.address?.flat || "",
		province: state?.address?.province || "",
		gisProvinceId: state?.address?.gisProvinceId || "",
		gisCityId: state?.address?.gisCityId || "",
		gisDistrictId: state?.address?.gisDistrictId || "",
		gisHouseId: state?.address?.gisHouseId || "",
		gisZipId: state?.address?.gisZipId || "",
		simpleConnection: state?.address?.simpleConnection || false,
	};

	const [handleMsisdnPart] = useLazyQuery<Required<Pick<Query, "msisdns">>>(GET_B2B_SINGLE_MSISDNS, {
		fetchPolicy: "no-cache",
		onCompleted: ({ msisdns }) => {
			const msisdn = msisdns?.[0]?.msisdn;
			if (msisdn) {
				reserveMsisdn({
					variables: {msisdn}
				}).then(async ({data}) => {
					const reservation_id = data?.reserve_msisdn?.attributes?.reservation_id;
					if (reservation_id) {
						updateNavigationState( {
							...b2bFttbState!,
							fttbMsisdn: msisdn,
							fttbMsisdnConfirmation_code: reservation_id,
							housePreviousActivationStatus: housePreviousActivationStatus,
						});
						history.push({
							pathname: B2B_FTTB_FLOW_SEARCH_COMPANY_PATH,
						});
					} else {
						setErorr(intl.formatMessage(FTTBMessages.msisdnReservationFail));
					}
				});
			} else {
				setErorr(intl.formatMessage(FTTBMessages.inventoryNoMsisdn));
			}

		}
	});
	const [hasAvailableHouse, setAvailableHouse] = useState<null | boolean>(!!b2bFttbState?.address?.house || null);

	const onSubmit = (values: FttbAddressForm) => {
		setErorr("");
		if (b2bFttbState?.address?.gisHouseId && b2bFttbState?.fttbMsisdnConfirmation_code && b2bFttbState?.fttbMsisdn) {
			history.push(B2B_FTTB_FLOW_SEARCH_COMPANY_PATH);
		}
		if (hasAvailableHouse) {
			const fttb = {
				...state,
				contact_phone: values.contact_phone,
				last_name: values.last_name,
				first_name: values.first_name,
				middle_name: values.middle_name,
				address: {
					country: config.DEFAULT_COUNTRY_NAME,
					country_code: config.DEFAULT_COUNTRY,
					city: values.city,
					gisProvinceId: values.gisProvinceId,
					gisDistrictId: values.gisDistrictId,
					gisCityId: values.gisCityId,
					gisStreetId: values.gisStreetId,
					gisHouseId: values.gisHouseId,
					gisZipId: values.gisZipId,
					street: values.street,
					house: values.house_number,
					flat: values.flat,
					province: values.province,
					simpleConnection: values.simpleConnection
				} as Addresses
			} as B2BFttbRouteState;
			if (state?.fttbMsisdnConfirmation_code && state?.fttbMsisdn) {
				updateNavigationState({
					...fttb,
				});
				history.push({
					pathname: B2B_FTTB_FLOW_SEARCH_COMPANY_PATH,
				});
			} else {
				setB2bFttbState(fttb);
				handleMsisdnPart();
			}

		}
	};
	const validationSchema = useMemo(() => addressValidation(intl), [intl.locale]);
	const labels = useMemo(() => initialLabels<any>(intl, initialValues, RegistrationMessages), [intl.locale]);

	const {errors, setFieldValue, touched, handleChange, values, handleBlur, handleSubmit} = useFormik<FttbAddressForm>({
		initialValues: initialValues, onSubmit, validationSchema
	});
	const formik = {errors, setFieldValue, touched, handleChange, values, handleBlur};
	const {validate: validatePreviousActivationStatus,loading:  loadingPreviousActivationStatus} = useGisHousePreviousActivationStatus({
		setPreviousHouseActivationStatus,
		setConnectionExist,
		setFieldValue
	});
	const onChangeContactPhone = (fieldName, setFieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = (e.target.value).trim();
		setFieldValue(fieldName, value);
		const contactPhone = addPrefixToMsisdn(value);
		const [status] = validateMsisdn(contactPhone, intl);
		if (status) {
			getAllSub(contactPhone);
		} else {
			setMsisdnValidationErorr("");
		}
	};
	return (
		<FormStepWrapper<B2BFttbRouteState>
			isValid={!(loadingMsisdn || loadingPreviousActivationStatus)}
			formId={formId}>
			<form id={formId} onSubmit={handleSubmit}>
				<div className={"row"}>
					<div className="col-12 col-sm-6">
						<InputFieldFormik
							required
							name="contact_phone"
							id="contact_phone"
							attr={{maxLength: config.PHONE_LENGTH}}
							label={labels.contact_phone}
							value={formik.values.contact_phone}
							formik={formik}
							onChange={onChangeContactPhone}
						/>
					</div>
					<UserNameInfo formik={formik as (FormikState<FttbAddressForm> & FormikHandlers & FormikHelpers<FttbAddressForm>)}/>
					<SuccessMessageTemplate show={msisdnValidationErorr}>{msisdnValidationErorr}</SuccessMessageTemplate>
				</div>
				<AddressValidationForm
					hasAvailableHouse={hasAvailableHouse}
					setAvailableHouse={setAvailableHouse}
					formik={formik}
					validatePreviousActivationStatus={validatePreviousActivationStatus}
					setPreviousHouseActivationStatus={setPreviousHouseActivationStatus}
					isFttbB2b={true}
				/>
				<div className={"mt-2"}>
					<ErrorMessage error={error}/>
				</div>
				<div className={"mt-2"}>
					{!error &&
                    <FttbStatus values={formik.values} availableHouse={hasAvailableHouse} />}
				</div>
				{housePreviousActivationStatus && hasAvailableHouse &&
                <SimpleConnection connectionExist={connectionExist} formik={formik}/>}
			</form>
			{(loadingMsisdn || loadingPreviousActivationStatus || loadingGetSubscriptions) && (<CircularProgress/>)}
		</FormStepWrapper>
	);
};


export { Address };
