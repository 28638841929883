import React from "react";
import { FormattedMessage } from "react-intl";
import CommonMessages from "../../Common.messages";
interface CircularProgressProps {
    showText?: boolean;
}
const CircularProgress = (props: CircularProgressProps) => {
	const {showText = true} = props;
	return (
		showText ?
			<div className="text-center" data-testid="spinner">
				<img alt="" src={require("../../static/img/loader.gif")}/>
				<div><FormattedMessage {...CommonMessages.loading}/></div>
			</div>
			:  <img alt="" src={require("../../static/img/loader.gif")}/>
	);
};

export {
	CircularProgress
};
