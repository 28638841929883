import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { FormattedMessage } from "react-intl";
import { GET_CSR_TAB_LINK } from "../../../../../graphql/queries/livesettings";
import { LiveSettingEnum, Query, QueryLivesettingArgs } from "../../../../../graphql/types";
import { FTTBMessages } from "../../../FTTB.messages";
import { FttbAddressForm } from "../../../fttb.types";

interface FTTBStatusProps {
	availableHouse: null|boolean;
	values: FttbAddressForm;
	networkType?: string;
}

const FttbStatus = (props: FTTBStatusProps) => {
	const { values, availableHouse, networkType } = props;
	const {data} = useQuery<Query, QueryLivesettingArgs>(GET_CSR_TAB_LINK, {variables: {
		name: LiveSettingEnum.WttxConnectionUrl
	}});
	const handleWttx = () => {
		if (data?.livesetting?.value) {
			window.open(data?.livesetting?.value, "_blank");
		}
	};
	return (
		<>
			{availableHouse === true && (
				<div className="alert alert-success alert-solid mb-0">
					<p className="mb-0">
						<FormattedMessage {...FTTBMessages.availableAddress} values={{networkType}}/>
					</p>
				</div>
			)}
			{availableHouse === false && (
				<div className="alert alert-danger  mb-0">
					<div className="row">
						<div className="col-12 col-sm-9">
							<FormattedMessage {...FTTBMessages.notAvailableAddress}
								values={{city: values.city,
									street: values.street ,
									buildingNumber: values.house_number
								}}
							/>
						</div>
						<div className="col-12 col-sm-3 text-right"><button type="button" className="btn btn-outline-danger" onClick={handleWttx}><FormattedMessage {...FTTBMessages.details}/></button></div>
					</div>
				</div>
			)}
		</>
	);
};

export { FttbStatus };