import React, { FC, useCallback, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import {
	CircularProgress,
} from "../../../../shared/components";
import {
	INDEX_PATH,
	FMC_FLOW_PLAN_PATH,
} from "../../Routes/FMCPageRoutes";
import { useIntl, FormattedMessage } from "react-intl";
import CommonMessages from "../../../../Common.messages";
import {useMutation, useLazyQuery, useQuery} from "@apollo/react-hooks";
import { FMCMessages } from "../../FMC.messages";
import {
	Mutation,
	MutationCreate_Fmc_OrderArgs,
	MutationCreate_Fttb_OrderArgs,
	MutationCreate_Subscription_PreorderArgs,
	Query,
	QueryLimited_SubscriptionArgs,
} from "../../../../graphql/types";
import {
	CREATE_FMC_ORDER,
	CREATE_FTTB_ORDER,
	GET_FTTB_SUBSCRIPTION,
} from "../../../../graphql/mutations/order";
import { PlanCard } from "./PlanCard";
import { OptionsCard } from "../../../../shared/components/option/OptionsCard";
import { FmcRouteState } from "../../fmc.type";
import { CREATE_SUBSCRIPTION_PREORDER } from "../../../../graphql/queries/preorder";
import { FmcPreOrderState } from "../../../../graphql/localTypes";
import { config } from "../../../../config/constants";
import moment from "moment";
import { FmcService } from "../../service/FmcService";
import { FttbService, FTTB_FAIL } from "../../../fttb/service/FttbService";
import { SuccessMessageList } from "../../../../shared/components/SuccessMessageTemplate";
import { FttbSummaryDetails } from "../../../fttb/FttbSummaryDetails";
import { useFormik } from "formik";
import { useAuthDataContext } from "../../../../shared/hooks/AuthentificationProvider";
import { StepWrapper } from "../../../../shared/components/navigation/StepWrapper";
import { errorActions, useStoreContext } from "../../../../shared/components/navigation/StepStateProvider";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";
import {CRAMER_CHECK} from "../../../../graphql/queries/fttb";
import {FttbOptionState} from "../../../fttb/fttb.types";
import _ from "lodash";

const Summary: FC<RouteComponentProps<never, StaticContext, FmcRouteState>> = props => {
	const { history } = props;
	const [ state ] = useState<FmcRouteState>(useNavigationState<FmcRouteState>());
	const intl = useIntl();
	const {currentDealerIdentety} = useAuthDataContext();
	const {dispatch} = useStoreContext();

	const [success, setSuccess] = useState<string[]>([] as string[]);
	const [fttbSuccess, setFttbSuccess] = useState<string>("");
	const [spinner, runSpinner] = useState(false);
	const [createFttbOrder] = useMutation<Required<Pick<Mutation, "create_fttb_order">>, MutationCreate_Fttb_OrderArgs>(
		CREATE_FTTB_ORDER
	);
	const [createOrder, { loading }] = useMutation<Mutation, MutationCreate_Fmc_OrderArgs>(CREATE_FMC_ORDER);
	const [createPreOrder, { loading: preOrderLoading }] = useMutation<
	Required<Pick<Mutation, "create_subscription_preorder">>,
		MutationCreate_Subscription_PreorderArgs
	>(CREATE_SUBSCRIPTION_PREORDER);

	const [pollFttbSubscription, { stopPolling, variables }] = useLazyQuery<Required<Pick<Query, "limited_subscription">>, QueryLimited_SubscriptionArgs>(GET_FTTB_SUBSCRIPTION, {
		pollInterval: 2000,
		fetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
		onCompleted: (data: Query) => {
			const newlyCreatedFttbSubscriptionId = data?.limited_subscription?.id;
			if (newlyCreatedFttbSubscriptionId
				&& state.gsmSubscription?.id
				&& state.selectedFmcTariffPlan?.id
				&& state?.selectedFmcTariffPlan?.selectedOptionId) {
				stopPolling();
				createPreOrder({
					variables: {
						details: {
							subscription_id: newlyCreatedFttbSubscriptionId,
							mobile_subscription_id: state.gsmSubscription?.id,
							subscription_type_id: state.selectedFmcTariffPlan?.id,
							validity_of_preorder: moment()
								.add(30, "days")
								.format(config.DATE_PICKER_FORMAT),
							state: FmcPreOrderState.ACTIVE,
							packages: [state?.selectedFmcTariffPlan?.selectedOptionId]
						},
					},
				}).then(({ data, errors }) => {
					if (
						errors ||
							data?.create_subscription_preorder?.error ||
							data?.create_subscription_preorder?.error_code
					) {
						throw intl.formatMessage(
							FMCMessages.preOrderFailed,
							{
								fttbMsisdn: variables.msisdn,
								fmcId: state.selectedFmcTariffPlan?.id,
							}
						);
					}
					setSuccess([
						...success,
						intl.formatMessage(
							FMCMessages.preOrderCompleted,
							{
								fttbMsisdn: variables.msisdn,
								fmcId: state.selectedFmcTariffPlan?.id,
							}
						),
					]);
				}).catch((errmsg) => {
					dispatch(errorActions.setError(errmsg));
				}).finally(() => {
					runSpinner(false);
				});
			}
		},
		onError: () => {
			stopPolling();
			runSpinner(false);
			dispatch(errorActions.setError("searchNewFttbSubscriptionFailed"));
		},
	});

	const { data } = useQuery<Query>(CRAMER_CHECK, {
		skip: !state?.address?.simpleConnection,
		fetchPolicy: "no-cache",
		variables: {msisdn: state?.msisdnWithMaxLastRCDate}
	});
	const isCramerCheckOk = Boolean(data?.fttb_gigabit_status?.status);
	let checkFttbOptionsWithGigabit: FttbOptionState | null | undefined = null;
	if (state.fttbOptions) {
		const optionsCopy = _.cloneDeep(state.fttbOptions);
		optionsCopy.packages = optionsCopy?.packages || [];
		checkFttbOptionsWithGigabit = FttbService.gigaBitService(optionsCopy, isCramerCheckOk, state?.address?.simpleConnection);
	}
	const fttbOptions: Array<string> = [];
	const {
		options,
		isToday,
		bundles,
	} = FttbService.addFttbFastIntoOptionsIfPhisicalWireDayIsToday(
		checkFttbOptionsWithGigabit
	);

	// Filter out undefined or null values and cast to string[]
	const deactivatePackage = (state.isNoFttbSubscription && [state.fttbOptions?.deactivatePackage?.code] || []).filter((item) => !!item) as string[];


	const createOrderClick = () => {
		setSuccess([]);
		setFttbSuccess("");
		dispatch(errorActions.cleanError());
		if (state.isNoFttbSubscription) {
			runSpinner(true);
			const fttbOptionGroupCode = state.selectedFttbTariffPlan?.option_groups?.[0]?.packages?.[0]?.code;
			fttbOptionGroupCode && options.push(fttbOptionGroupCode);
			FttbService.handleFttbOrder(
				createFttbOrder,
				{
					msisdn: state.fttbMsisdn,
					housePreviousActivationStatus:
						state.housePreviousActivationStatus,
					contact_phone: state.contact_phone,
					first_name: state.first_name,
					last_name: state.last_name,
					middle_name: state.middle_name,
					confirmation_code: state.fttbMsisdnConfirmation_code,
					selectedFttbTariffPlan: state.selectedFttbTariffPlan,
					options: state.fttbOptions,
					address: state.address,
				},
				intl,
				options,
				setFttbSuccess,
				runSpinner,
				currentDealerIdentety?.reseller_code,
				deactivatePackage
			).catch((error) => {
				dispatch(errorActions.setError(error));
				return FTTB_FAIL;
			}).then((errorCode) => {
				if (errorCode !== FTTB_FAIL && state.fttbMsisdn) {
					runSpinner(true);
					pollFttbSubscription({
						variables: {
							msisdn: state.fttbMsisdn,
						},
					});
				}
			}).catch(() => {
				runSpinner(false);
			});
		} else {
			const postpaid = false;
			FmcService.handleFmcOrder(
				createOrder,
				state,
				fttbOptions,
				setSuccess,
				success,
				intl,
				dispatch,
				currentDealerIdentety?.reseller_code,
				postpaid
			).then(() => {
				runSpinner(false);
			});
		}
	};
	if (!state?.selectedFmcTariffPlan?.code) {
		history.push(FMC_FLOW_PLAN_PATH);
	}
	const noFttbLoading = spinner || loading || preOrderLoading;
	const showButton = useCallback(() => {
		return success.length > 0 ? (
			<button
				type="button"
				className="btn btn-primary"
				onClick={() => history.push(INDEX_PATH)}
			>
				<FormattedMessage {...CommonMessages.done} />
			</button>
		) : (
			<button
				type="button"
				onClick={createOrderClick}
				disabled={(loading || noFttbLoading)}
				className="btn btn-primary"
			>
				<FormattedMessage {...CommonMessages.order} />
			</button>
		);
	}, [loading, success]);
	const { values, setFieldValue, touched, handleChange, errors } = useFormik({
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onSubmit: () => {},
		initialValues: {
			phone: state?.contact_phone || "",
			last_name: state?.last_name || "",
			first_name: state?.first_name || "",
			middle_name: state?.middle_name || "",
			...state?.address,
		},
		enableReinitialize: true,
	});

	const filterSBPackage = (packages) => {
		if(state.bothGSMFTTBHaveActiveSBPackage){
			const deactivatePack = state?.deactivatePackage;
			return packages.filter(pack => pack.code !== deactivatePack?.code);
		}
	};
	const packagesForOptionsCard = filterSBPackage(state?.fttbSubscription?.active_packages) || state?.fttbOptions?.packages;


	const formik = { values, setFieldValue, touched, handleChange, errors };
	return (
		<StepWrapper<FmcRouteState>
			isValid={true}>
			<div className="row">
				<div className="col-12">
					<h4>
						<FormattedMessage {...FMCMessages.FMC} />
					</h4>
				</div>
				<div className="col-12">
					<h4>
						<FormattedMessage {...FMCMessages.phone} />:{" "}
						{state?.fttbSubscription?.msisdn ||
								state?.contact_phone}
					</h4>
				</div>
				<div className="col-12">
					<PlanCard
						change_price={
								state?.selectedFmcTariffPlan?.change_price
						}
						fee={state?.selectedFmcTariffPlan?.fee}
						periodic_unit={
								state?.selectedFmcTariffPlan?.periodicUnit
						}
						periodic_amount={
								state?.selectedFmcTariffPlan?.periodicAmount
						}
						name={state?.selectedFmcTariffPlan?.name}
						medium_description={
								state?.selectedFmcTariffPlan?.medium_description
						}
						long_description={
								state?.selectedFmcTariffPlan?.long_description
						}
					/>
				</div>
				{state?.selectedFttbTariffPlan && (
					<>
						<div className="col-12 mt-2">
							<h4>
								<FormattedMessage
									{...FMCMessages.fttOrder}
								/>
							</h4>
						</div>
						<div className="col-12">
							<FttbSummaryDetails
								bundles={bundles}
								simpleConnection={state?.address?.simpleConnection}
								options={state.fttbOptions}
								isToday={isToday}
								formik={formik}
							/>
						</div>
					</>
				)}
				<div className="col-12 my-3">
					{!state?.isNoFttbSubscription && <OptionsCard packages={packagesForOptionsCard}/>}
				</div>
				<div className="col-12">
					{(loading || noFttbLoading) && <CircularProgress />}
					<SuccessMessageList
						successMsgs={
							fttbSuccess
								? [...success, fttbSuccess]
								: success
						}
					/>
				</div>
				<div className="col-12">
					<div className="text-right">{showButton()}</div>
				</div>
			</div>
		</StepWrapper>
	);
};

export { Summary };
