import gql from "graphql-tag";
import { MutationsEnum } from "../../shared/hooks";

const GET_MODAL = gql`
    query getModal {
        modal @client {
            status,
            message,
            title
        }
    }
`;
const GET_CONFIRMATION_ACTION_STATUS = (actionType: MutationsEnum) => gql`
    query getConfirmationAction {
        context @client {
            msisdn,
            subscriptionId
        },
        confirmationAction @client {
            id,
            code,
            action,
            status,
            code3rdParty,
            ${actionType} {
                id,
                codes {
                    id,
                    code,
                    status
                }
            }
        }
    }
`;

const GET_TARIFF_PLAN_STATUS = gql`
    query getTariffPlanStatus {
        updateTariffName @client {
            change_subscription_type {
                status,
            }
        }
    }
`;
const GET_MODALS = gql`
    query GetModals {
        modals @client {
            otp {
                status,
            }
        }
    }
`;
const GET_OTP_MODAL = gql`
    query GetOtpModal {
        modals @client {
            otp {
                status
                msisdn
                code
                loading
                error
            }
        }
    }
`;

const GET_ALL_CONFIRMATION_ACTION_STATUS = gql`
    query getConfirmationAction {
        context @client {
            msisdn,
            subscriptionId
        },
        confirmationAction @client {
            id,
            code,
            action,
            status,
            code3rdParty,
            remove_package {
                id,
                codes {
                    id,
                    code,
                    status
                }
            }
        }
    }
`;

const GET_SPINNER_STATUS = gql`
    query getSpinner {
        spinner @client {
            status,
        }
    }
`;

const GET_APPLICATION_CONTEXT = gql`
    query Context {
        context @client {
            msisdn,
            subscriptionId,
            locale,
        }
    }
`;

const GET_ACQUISITION_FLOW_STEPS = gql`
    query getAcquisitionFlowSteps {
        acquisitionFlow @client {
            msisdn,
            plan {
                isEmpty,
            },
            iccId,
            options {
                code
            },
            personalInfo {
                isEmpty,
            }
        }   
    }
`;

const GET_AUTH = gql`
    query getIsAuthenticated {
        auth @client {
            isAuthenticated,
        }
    }
`;

export {
	GET_MODAL,
	GET_SPINNER_STATUS,
	GET_CONFIRMATION_ACTION_STATUS,
	GET_APPLICATION_CONTEXT,
	GET_TARIFF_PLAN_STATUS,
	GET_ALL_CONFIRMATION_ACTION_STATUS,
	GET_ACQUISITION_FLOW_STEPS,
	GET_AUTH,
	GET_MODALS,
	GET_OTP_MODAL,
};
