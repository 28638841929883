import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import React, { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { GET_REGION_OPTIONAL_PRODUCTS } from "../../../graphql/queries/getRegionOptionalProducts";
import { GET_HOUSE_GREEN_FIELD } from "../../../graphql/queries/gisSystem";
import { GisHouseGreen_FieldArgs, LanguageEnum, Maybe, Package, ProductFamily, Query, QueryGis_HousesArgs, QueryRegion_Optional_ProductsArgs } from "../../../graphql/types";
import { OptionProductChecked } from "../../../modules/acquisitionFlow/NewCustomer/Pages/OptionsPage/OptionProduct";
import { OptionsFamilies } from "../../../modules/acquisitionFlow/NewCustomer/Pages/OptionsPage/OptionsFamilies";
import { CircularProgress } from "../CircularProgress";

export interface RegionOptionalProductsProps {
	flat?: string;
	additionalLoading?: boolean;
	gisHouseId?: string;
	subscriptioTypeId?: string|null;
	onChecked: (value: OptionProductChecked) => void;
	onChildChecked: (value: OptionProductChecked) => void;
	selectedOptionalProduct: Package[];
	childOffersData: any;
	isParentChecked: boolean;
	loadingChildOffers: boolean;
	parentCode: string | null | undefined;
}
const RegionOptionalProducts:FC<RegionOptionalProductsProps> = props => {
	const {gisHouseId, flat, additionalLoading,
		onChecked,
		subscriptioTypeId,
		onChildChecked,
		selectedOptionalProduct,
		childOffersData,
		isParentChecked,
		loadingChildOffers,
		parentCode} = props;
	const intl = useIntl();

	const {data: gisHouses, loading } = useQuery<Required<Pick<Query, "gis_houses">>, GisHouseGreen_FieldArgs & QueryGis_HousesArgs>(GET_HOUSE_GREEN_FIELD, {
		variables: {
			language: intl.locale as LanguageEnum,
			house_id: gisHouseId!,
			apartment:  flat
		},
		skip: !(gisHouseId && flat && intl.locale)
	});
	const [getRegionOptionalProducts, {data , loading: familyLoading }] = useLazyQuery<Required<Pick<Query, "region_optional_products">>, QueryRegion_Optional_ProductsArgs>(GET_REGION_OPTIONAL_PRODUCTS);

	useEffect(() => {
		if (subscriptioTypeId && gisHouses?.gis_houses?.green_field?.green_field_status) {
			getRegionOptionalProducts({
				variables: {
					subscription_type_id: subscriptioTypeId,
					gis_house_id: gisHouseId,
					gis_flat_id: flat
				}
			});

		}
	}, [gisHouses]);
	
	if (!additionalLoading && (loading || familyLoading)) {
		return <CircularProgress />;
	}
	return (<>
		{data?.region_optional_products?.map((family: Maybe<ProductFamily>, index) => (
			family?.packages?.length && family.packages.length > 0) &&
			<OptionsFamilies
				key={family.id || `region-optional-product-${index}`}
				family={family}
				onChecked={onChecked}
				onChildChecked={onChildChecked}
				childOffersData={childOffersData}
				isParentChecked={isParentChecked}
				parentCode={parentCode}
				loadingChildOffers={loadingChildOffers}
				selectedOptionalProduct={selectedOptionalProduct}
			/>)}
	</>);
};

export {
	RegionOptionalProducts
};