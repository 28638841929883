/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

interface FTTBFlowMessagesType {
    searchByCompanyName: MessageDescriptor;
    searchByCompanyId: MessageDescriptor;
	searchType: MessageDescriptor;
	companyIdType: MessageDescriptor;
	enterCompanyIdOrName: MessageDescriptor;
	noCompanyFound: MessageDescriptor;
}

const B2BFTTBFlowMessages: FTTBFlowMessagesType = defineMessages({
	searchByCompanyName: {
		id: "fttb-b2b-flow-search-company-by-name-label",
		description: "FTTB b2b flow label search company by name",
		defaultMessage: "Search By Company Name"
	},
	searchByCompanyId: {
		id: "fttb-b2b-flow-search-company-by-id-label",
		description: "FTTB b2b flow label search company by id",
		defaultMessage: "Search By Company ID"
	},
	searchType: {
		id: "fttb-b2b-flow-company-search-type-label",
		description: "FTTB b2b flow label search company type",
		defaultMessage: "Search Type"
	},
	companyIdType: {
		id: "fttb-b2b-flow-company-id-type-label",
		description: "FTTB b2b flow label search company Id type",
		defaultMessage: "Company Id Type"
	},
	enterCompanyIdOrName: {
		id: "fttb-b2b-flow-search-company-id-or-name-placeholder",
		description: "FTTB b2b flow label search company Id or Name placeholder",
		defaultMessage: "Enter Company Id Or First Name"
	},
	noCompanyFound: {
		id: "fttb-b2b-flow-search-company-no-company-found-msg",
		description: "FTTB b2b flow label search company no company found msg",
		defaultMessage: "No Company Found"
	}
});
export default B2BFTTBFlowMessages;
export { B2BFTTBFlowMessages };