import { useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { SearchCustomerEnum } from "../../../shared/types";
import { addPrefixToMsisdn } from "../../../shared/utils/utils";
import { Customer, Query, QueryCustomersArgs, QueryOtp_SubscriptionArgs } from "../../../graphql/types";
import { GET_BILLING_ACCOUNT } from "../../../graphql/queries/billing";
import { GET_EXISTING_CUSTOMER, GET_PERSONALIZATION } from "../../../graphql/queries";

const useSearchExistingCustomer = (): [
	(Customer|null)[]|null,
	(id_number: string, id_type: keyof typeof SearchCustomerEnum, otp?: string) => void,
	boolean,
	React.Dispatch<React.SetStateAction<(Customer | null)[]|null>>
] => {
	const [customers, setCustomer] = useState<(Customer|null)[]|null>(null);
	const [existingCustomer, {loading: loadingCustomer}] = useLazyQuery<Query, QueryCustomersArgs>(GET_EXISTING_CUSTOMER, {
		onCompleted: (data: Query) => {
			setCustomer(data?.customers || []);
		}
	});

	const [getMsisdn, {loading: loadingMsisdn}] = useLazyQuery<Required<Pick<Query, "otp_subscription">>, QueryOtp_SubscriptionArgs>(GET_PERSONALIZATION, {
		onCompleted: (data) => {
			const owner = data?.otp_subscription?.subscription?.owner;
			setCustomer(owner ? [owner] : []);	
		}
	});
	const [getBillingAccount, {loading: loadingBilling}] = useLazyQuery(GET_BILLING_ACCOUNT, {
		onCompleted: (data) => {
			const owner = data?.billing_account?.owner;
			setCustomer(owner ? [owner] : []);
		}
	});

	const searchCustomer = (id_number: string, id_type: keyof typeof SearchCustomerEnum) => {
		if (id_type === SearchCustomerEnum.passport || id_type === SearchCustomerEnum.national_id) {
			existingCustomer({variables: {id_number, id_type}});
		} else if (id_type === SearchCustomerEnum.msisdn) {
			//getMsisdn({variables: {msisdn: addPrefixToMsisdn(id_number), otp}});
		} else if (id_type === SearchCustomerEnum.billing_account) {
			getBillingAccount({ variables: { billing_account_id: id_number}});
		} else if (id_type === SearchCustomerEnum.individual_tax_number) {
			existingCustomer({ variables: { individual_tax_number: id_number}});
		}
	};
	const loading = loadingCustomer || loadingMsisdn || loadingBilling;
	return [customers, searchCustomer, loading, setCustomer];
};

export { useSearchExistingCustomer };