
import gql from "graphql-tag";
import { language } from "../fragments/Languages.fragment";
import { bundle } from "../fragments/Packages.fragments";
import { TariffPlan } from "../fragments/subscriptionType.fragments";

const GET_ASSOCIATED_FTTB_SUBSCRIPTION_TYPE = gql`
    query GetAssociatedFttbSubscriptionType($subscription_type_id: ID!) {
        associated_subscription_type(subscription_type_id: $subscription_type_id) {
            ...tariffPlanDetails,
        },
        optional_products(subscription_type_id: $subscription_type_id) {
            packages {
                tags
            }   
        }
    }
    ${TariffPlan.fragments.full}
`;

const GET_SUBSCRIPTION_TYPE_OFFERING = gql`
    query GetSubscriptionTypeOffering($subscription_type_id: ID!) {
        subscription_type_offering(subscription_type_id: $subscription_type_id) {
            id,
            code,
        },
        optional_products(subscription_type_id: $subscription_type_id) {
            id,
            name {...languageFull},
            packages {
                provisioning_parameters{
                    code,
                    raw_value
                },
                tags
                ...bundleFull
            }
        }
    }
    ${bundle.fragments.full},
    ${language.fragments.full},
`;

export {
    GET_ASSOCIATED_FTTB_SUBSCRIPTION_TYPE,
    GET_SUBSCRIPTION_TYPE_OFFERING
};
