import { useQuery } from "@apollo/react-hooks";
import React, { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { config } from "../../../config/constants";
import { GET_BALANCES } from "../../../graphql/queries/subscription";
import { LineTypeEnum, Query, QueryMsisdnArgs } from "../../../graphql/types";
import { notificationActions, useStoreContext } from "../../../shared/components/navigation/StepStateProvider";
import { getFormattedCurrency } from "../../../shared/utils/currencyUtils";
import FmcPostpaidMessages from "./FmcPostpaid.messages";
interface FttbDetailsProps {
	lineType?: LineTypeEnum|null;
	msisdn?: string|null;

}
const FttbDetails:FC<FttbDetailsProps> = ({lineType, msisdn}) => {
	const { data } = useQuery<Required<Pick<Query, "otp_subscription">>, QueryMsisdnArgs>(GET_BALANCES, {
		variables: {msisdn: msisdn!},
		skip: !msisdn 
	});
	const {dispatch } = useStoreContext();
	const mainBalance = data?.otp_subscription?.subscription?.all_balances?.find(b => b?.balance_name?.toLowerCase() === "fttb main balance")?.amount;
	const mainBalanceFormatted = getFormattedCurrency(config.DEFAULT_CURRENCY, mainBalance, 0);
	useEffect(() => {
		if (mainBalance && mainBalance > 0) {
			dispatch(notificationActions.setNotification("refund"));
		}
	}, [mainBalance]);

	return (lineType ? <div className="form-group col-lg-4">
		<div className="w-key-value-list">
			<div className="w-key-value-row">
				<div className="w-key"><FormattedMessage {...FmcPostpaidMessages.eligibleForFmc} /></div>
				<div className="w-value">
					<span className="badge badge-success"><FormattedMessage {...FmcPostpaidMessages[ [LineTypeEnum.Mobile, LineTypeEnum.Fttb].includes(lineType) ? "yes" : "no"]} /></span>
				</div>
			</div>
			<div className="w-key-value-row">
				<div className="w-key"><FormattedMessage {...FmcPostpaidMessages.balance} /></div>
				<div className="w-value">{mainBalanceFormatted}</div>
			</div>
		</div>
	</div>: null);
}

export {
	FttbDetails
};