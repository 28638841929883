import React from "react";
import { useIntl } from "react-intl";
import { PackagePrices } from "../../../modules/packages/PackagePrice";
import { RECURRENT, SINGLE } from "../../../config/constants";
import { Language, Fee, Maybe } from "../../../graphql/types";
import { createMarkup } from "../../utils/createMarkup";
interface OptionsSummaryProps {
    fees: Maybe<Fee>[];
    name: Language;
    long_description?: Language;
    medium_description?: Language;
    periodic_amount: number;
    periodic_unit: string;
}
const OptionsSummary = (props: OptionsSummaryProps) => {
	const intl = useIntl();
	return (<div className="ipos-content-box">
		<div className="row align-items-center">
			<div className="col-12 col-sm-1 item">
				<i className="fa fa-cubes p-2 bg-gray-200"></i>
			</div>
			<div className="col-12 col-sm-5 item">
				<div className="w-emphasize">
					{props.name?.[intl.locale]}
				</div>
				<div dangerouslySetInnerHTML={createMarkup(
                    props?.long_description?.[intl.locale] ||
                    props?.medium_description?.[intl.locale])}
				/>
			</div>
			<div className="col-12 col-sm-3 text-success item">
				{props.fees?.map((item,index) => (
					<React.Fragment key={index}>
						{item?.fee_type === RECURRENT &&
                        <PackagePrices
                        	fee={item?.fee}
                        	fee_type={RECURRENT}
                        	periodic_amount={props.periodic_amount}
                        	periodic_unit={props.periodic_unit}
                        />}
						{item?.fee_type === SINGLE &&
                        <PackagePrices
                        	fee={item.fee}
                        	fee_type={SINGLE}
                        />}
					</React.Fragment>
				))}
			</div>
		</div>
	</div>
	);
};

export {
	OptionsSummary
};
