import React, {FC, useState} from "react";
import {StepWrapper} from "../../../../shared/components/navigation/StepWrapper";
import {RouteComponentProps, StaticContext} from "react-router";
import {B2BFttbRouteState} from "./b2bFttb.type";
import {SelectedTariffPlanDetails} from "../../../../graphql/localTypes";
import {CircularProgress, ErrorMessageTemplate, SuccessMessageTemplate} from "../../../../shared/components";
import {FormattedMessage, useIntl} from "react-intl";
import CommonMessages from "../../../../Common.messages";
import {useAuthDataContext} from "../../../../shared/hooks/AuthentificationProvider";
import {useMutation} from "@apollo/react-hooks";
import {Mutation, MutationCreate_Fttb_OrderArgs} from "../../../../graphql/types";
import {CREATE_FTTB_ORDER} from "../../../../graphql/mutations/order";
import {useFormik} from "formik";
import {B2BFttbSummaryDetails} from "../B2BFttbSummaryDetails";
import {B2BFttbService} from "../B2BFttbService";
import {B2B_FTTB_FLOW_ADDRESS_PATH, INDEX_PATH} from "../Routes/B2BFttbFlowRoutes";
import {useB2BFttbUploadDocument} from "../../../../shared/components/document/B2BUploadDocuments";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";

const Summary:FC<RouteComponentProps<never, StaticContext, B2BFttbRouteState>> = (props) => {
	const state = useNavigationState<B2BFttbRouteState>();

	const { history } = props;

	const intl = useIntl();
	const {currentDealerIdentety} = useAuthDataContext();
	const [uploadDocument, files, setFiles, uploadProgress] = useB2BFttbUploadDocument();

	const [errorMessage, setError] = useState("");
	const [spinner, runSpinner] = useState(false);
	const [success, setSuccess] = useState("");
	const [documentUploadSuccess, setDocumentUploadSuccess] = useState<boolean>(false);

	const [createOrder] = useMutation<Required<Pick<Mutation, "create_fttb_order">>, MutationCreate_Fttb_OrderArgs>(
		CREATE_FTTB_ORDER
	);

	const onSubmit = () => {
		setDocumentUploadSuccess(false);
		setSuccess("");
		setError("");
		runSpinner(true);
		B2BFttbService.handleFttbOrder(
			createOrder,
			state,
			intl,
			setSuccess,
			runSpinner,
			setError,
			currentDealerIdentety?.reseller_code,
			uploadDocument,
			files,
			setDocumentUploadSuccess
		);
	};

	const initialValues = {
		phone: state?.contact_phone || "",
		last_name: state?.last_name || "",
		first_name: state?.first_name || "",
		middle_name: state?.middle_name || "",
		city: state?.address.city || "",
		province: state?.address.province || "",
		street: state?.address.street || "",
		house: state?.address.house || "",
		flat: state?.address.flat || "",
	};

	const {
		values,
		setFieldValue,
		touched,
		handleSubmit,
		handleChange,
		errors,
	} = useFormik({
		onSubmit,
		initialValues,
		enableReinitialize: true,
	});
	const formik = { values, setFieldValue, touched, handleChange, errors };
	if (!state?.fttbMsisdn) {
		history.push(B2B_FTTB_FLOW_ADDRESS_PATH);
	}

	return (
		<StepWrapper<B2BFttbRouteState> isValid={true}>
			<div className="ipos-content-box">
				<form onSubmit={handleSubmit}>
					<B2BFttbSummaryDetails
						simpleConnection={state?.address?.simpleConnection}
						selectedFttbTariffPlan={state?.selectedFttbTariffPlan as SelectedTariffPlanDetails}
						formik={formik}
						setFiles={setFiles}
						files={files}
						uploadProgress={uploadProgress}
						state={state}
					/>
					{spinner && <CircularProgress />}
					<ErrorMessageTemplate show={errorMessage}>
						{errorMessage}
					</ErrorMessageTemplate>
					<SuccessMessageTemplate show={success}>
						{success}
					</SuccessMessageTemplate>
					{documentUploadSuccess && (<SuccessMessageTemplate show={true}>
						<FormattedMessage {...CommonMessages.documentUploadSuccess} />
					</SuccessMessageTemplate>)}
					<div className="text-right">
						{success ? (
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => history.push(INDEX_PATH)}
							>
								<FormattedMessage {...CommonMessages.done} />
							</button>
						) : (
							<button
								type="submit"
								disabled={spinner}
								className="btn btn-primary"
							>
								<FormattedMessage {...CommonMessages.order} />
							</button>
						)}
					</div>
				</form>
			</div>
		</StepWrapper>
	);
};

export { Summary };