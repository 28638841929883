import React, { FC, useCallback, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import {
	FTTB_FLOW_OPTIONS_PATH,
	INDEX_PATH,
	FTTB_FLOW_ADDRESS_PATH,
} from "../../Routes/FttbFlowRoutes";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
	NavigationHeader,
	SuccessMessageTemplate,
} from "../../../../shared/components";
import { FormattedMessage, useIntl } from "react-intl";
import CommonMessages from "../../../../Common.messages";
import { useFormik } from "formik";
import {
	ErrorMessageTemplate,
} from "../../../../shared/components";
import {
	Mutation,
	MutationCreate_Fttb_OrderArgs, Query
} from "../../../../graphql/types";
import { CircularProgress } from "../../../../shared/components/CircularProgress";
import { FttbFlow } from "../../Routes/FttbFlow";
import {FttbOptionState, FttbRouteState} from "../../fttb.types";
import { FttbService } from "../../service/FttbService";
import { FttbSummaryDetails } from "../../FttbSummaryDetails";
import { CREATE_FTTB_ORDER } from "../../../../graphql/mutations/order";
import { useAuthDataContext } from "../../../../shared/hooks/AuthentificationProvider";
import { SelectedTariffPlanDetails } from "../../../../graphql/localTypes";
import {CRAMER_CHECK} from "../../../../graphql/queries/fttb";
import _ from "lodash";

const Summary: FC<RouteComponentProps<never, StaticContext, FttbRouteState>> = (props) => {
	const { location:{state}, history } = props;

	const intl = useIntl();
	const {currentDealerIdentety} = useAuthDataContext();

	const [error, setError] = useState("");
	const [spinner, runSpinner] = useState(false);
	const [success, setSuccess] = useState("");

	const [createOrder] = useMutation<Required<Pick<Mutation, "create_fttb_order">>, MutationCreate_Fttb_OrderArgs>(
		CREATE_FTTB_ORDER
	);

	const { data } = useQuery<Query>(CRAMER_CHECK, {
		skip: !state?.address?.simpleConnection,
		fetchPolicy: "no-cache",
		variables: {msisdn: state?.msisdnWithMaxLastRCDate}
	});
	const isCramerCheckOk = Boolean(data?.fttb_gigabit_status?.status);
	let checkOptionsWithGigabit: FttbOptionState | null | undefined = null;
	if (state.options) {
		const optionsCopy = _.cloneDeep(state.options);
		optionsCopy.packages = optionsCopy?.packages || [];
		 checkOptionsWithGigabit = FttbService.gigaBitService(optionsCopy, isCramerCheckOk, state?.address?.simpleConnection);
	}
	const onClickBack = useCallback(() => {
		history.push({
			pathname: FTTB_FLOW_OPTIONS_PATH,
			state,
		});
	}, [success]);
	const {
		options,
		isToday,
		bundles,
	} = FttbService.addFttbFastIntoOptionsIfPhisicalWireDayIsToday(
		checkOptionsWithGigabit
	);
	const onSubmit = () => {
		FttbService.addTariffPlanOptionGroupCodeIntoOptionsIfItExist(
			state.selectedFttbTariffPlan as SelectedTariffPlanDetails,
			options
		);

		setSuccess("");
		setError("");
		runSpinner(true);
		FttbService.handleFttbOrder(
			createOrder,
			state,
			intl,
			options,
			setSuccess,
			runSpinner,
			currentDealerIdentety?.reseller_code
		).catch((error) => {
			setError(error);
		}).finally(() => {
			runSpinner(false);
		});
	};

	const initialValues = {
		phone: state?.contact_phone || "",
		last_name: state?.last_name || "",
		first_name: state?.first_name || "",
		middle_name: state?.middle_name || "",
		city: state?.address.city || "",
		province: state?.address.province || "",
		street: state?.address.street || "",
		house: state?.address.house || "",
		flat: state?.address.flat || "",
	};

	const {
		values,
		setFieldValue,
		touched,
		handleSubmit,
		handleChange,
		errors,
	} = useFormik({
		onSubmit,
		initialValues,
		enableReinitialize: true,
	});
	const formik = { values, setFieldValue, touched, handleChange, errors };
	if (!state?.msisdn) {
		history.push(FTTB_FLOW_ADDRESS_PATH);
	}
	return (
		<>
			<NavigationHeader
				disabledBack={Boolean(success)}
				disabledNext={true}
				FlowComp={FttbFlow}
				back={onClickBack}
				hide={Boolean(success)}
			/>
			<div className="ipos-content-box">
				<form onSubmit={handleSubmit}>
					<FttbSummaryDetails
						bundles={bundles}
						simpleConnection={state?.address?.simpleConnection}
						options={state.options!}
						selectedFttbTariffPlan={state.selectedFttbTariffPlan as SelectedTariffPlanDetails}
						isToday={isToday}
						formik={formik}
					/>
					{spinner && <CircularProgress />}
					<ErrorMessageTemplate show={error}>
						{error}
					</ErrorMessageTemplate>
					<SuccessMessageTemplate show={success}>
						{success}
					</SuccessMessageTemplate>
					<div className="text-right">
						{success ? (
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => history.push(INDEX_PATH)}
							>
								<FormattedMessage {...CommonMessages.done} />
							</button>
						) : (
							<button
								type="submit"
								disabled={spinner}
								className="btn btn-primary"
							>
								<FormattedMessage {...CommonMessages.order} />
							</button>
						)}
					</div>
				</form>
			</div>
		</>
	);
};

export { Summary };
