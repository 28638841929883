import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { acquisitionExistingFlowRoutesConfig, iposRoutes } from "../../../../routes/RoutesMap";
import { CustomerNavigation } from "../../../../shared/components/CustomerNavigation";
import { AcquisitionMessages } from "../../Acquisition.messages";
import { ExistingCustomerRouteState } from "../Pages/Summary/Summary";
import {
	ACQUISITION_EXISTING_FLOW_BILLING_PATH, ACQUISITION_EXISTING_FLOW_CONTACT_INFO_PATH,
	ACQUISITION_EXISTING_FLOW_ICCID_PATH,
	ACQUISITION_EXISTING_FLOW_MSISDN_PATH,
	ACQUISITION_EXISTING_FLOW_OPTIONS_PATH,
	ACQUISITION_EXISTING_FLOW_PLAN_PATH,
	ACQUISITION_EXISTING_FLOW_SUMMARY_PATH
} from "./AcquisitionExistingFlowPath";

const lengthMainPath = iposRoutes.ACQUISITION_EXISTING_FLOW.createLink().length;

const AcquisitionExistingFlow: FC = () => {
	const history = useHistory();
	const {
		pathname,
		state,
	} = useLocation<ExistingCustomerRouteState>();
	const intl = useIntl();
	const billingLink = () => {
		if (pathname !== ACQUISITION_EXISTING_FLOW_BILLING_PATH && state?.customer?.id) {
			history.push(ACQUISITION_EXISTING_FLOW_BILLING_PATH);
		}
	};

	const msisdnLink = () => {
		if (pathname !== ACQUISITION_EXISTING_FLOW_MSISDN_PATH && state?.customer?.id) {
			history.push(ACQUISITION_EXISTING_FLOW_MSISDN_PATH);
		}
	};

	const planLink = () => {
		if (pathname !== ACQUISITION_EXISTING_FLOW_PLAN_PATH && state?.msisdn) {
			history.push(ACQUISITION_EXISTING_FLOW_PLAN_PATH);
		}
	};

	const optionsLink = () => {
		if (pathname !== ACQUISITION_EXISTING_FLOW_OPTIONS_PATH && state?.plan?.id) {
			history.push(ACQUISITION_EXISTING_FLOW_OPTIONS_PATH);
		}
	};

	const iccIdLink = () => {
		if (pathname !== ACQUISITION_EXISTING_FLOW_ICCID_PATH && state?.plan?.id) {
			history.push(ACQUISITION_EXISTING_FLOW_ICCID_PATH);
		}
	};

	const contactInfoLink = () => {
		if (pathname !== ACQUISITION_EXISTING_FLOW_CONTACT_INFO_PATH && state?.iccId) {
			history.push(ACQUISITION_EXISTING_FLOW_SUMMARY_PATH);
		}
	};

	const summaryLink = () => {
		if (pathname !== ACQUISITION_EXISTING_FLOW_SUMMARY_PATH && state?.iccId) {
			history.push(ACQUISITION_EXISTING_FLOW_SUMMARY_PATH);
		}
	};

	const routesIndexesConfig = {
		[acquisitionExistingFlowRoutesConfig[0]]: {
			message: intl.formatMessage({...AcquisitionMessages.billing}),
			link: billingLink,
			step: 1,
		},
		[acquisitionExistingFlowRoutesConfig[1]]: {
			message: intl.formatMessage({...AcquisitionMessages.msisdn}),
			link: msisdnLink,
			step: 2,
		},
		[acquisitionExistingFlowRoutesConfig[2]]: {
			message: intl.formatMessage({...AcquisitionMessages.plan}),
			link: planLink,
			step: 3,
		},
		[acquisitionExistingFlowRoutesConfig[3]]: {
			message: intl.formatMessage({...AcquisitionMessages.options}),
			link: optionsLink,
			step: 4,
		},
		[acquisitionExistingFlowRoutesConfig[4]]: {
			message: intl.formatMessage({...AcquisitionMessages.iccid}),
			link: iccIdLink,
			step: 5,
		},
		[acquisitionExistingFlowRoutesConfig[5]]: {
			message: intl.formatMessage({...AcquisitionMessages.contactInfo}),
			link: contactInfoLink,
			step: 6,
		},
		[acquisitionExistingFlowRoutesConfig[6]]: {
			message: intl.formatMessage({...AcquisitionMessages.summary}),
			link: summaryLink,
			step: 7,
		},
	};
	return (
		<div>
			<CustomerNavigation<typeof acquisitionExistingFlowRoutesConfig>
				pathname={pathname}
				routesConfig={acquisitionExistingFlowRoutesConfig}
				routesIndexesConfig={routesIndexesConfig}
				baseRouteLength={lengthMainPath}
			/>
		</div>
	);
};

export { AcquisitionExistingFlow };