export interface DeliveryZoneOption {
    id: string;
    name: string;
}

export const DeliveryZoneList: DeliveryZoneOption[] = [
	{ id: "654", name: "Yaltynska" },
	{ id: "69", name: "Sevastopolska" },
	{ id: "65", name: "Simferopolska" },
	{ id: "43", name: "Vinnytska" },
	{ id: "33", name: "Volynska" },
	{ id: "56", name: "Dnipropetrovska" },
	{ id: "564", name: "Kryvorizka" },
	{ id: "62", name: "Donetska" },
	{ id: "629", name: "Mariupolska" },
	{ id: "41", name: "Zhytomyrska" },
	{ id: "31", name: "Zakarpatska" },
	{ id: "61", name: "Zaporizka" },
	{ id: "6142", name: "Melitopolska" },
	{ id: "34", name: "Ivano-Frankivska" },
	{ id: "45", name: "Kyyivska" },
	{ id: "52", name: "Kirovohradska" },
	{ id: "64", name: "Luhanska" },
	{ id: "32", name: "Lvivska" },
	{ id: "51", name: "Mykolayivska" },
	{ id: "48", name: "Odeska" },
	{ id: "5366", name: "Kremenchutska" },
	{ id: "36", name: "Rivnenska" },
	{ id: "54", name: "Sumska" },
	{ id: "35", name: "Ternopilska" },
	{ id: "57", name: "Kharkivska" },
	{ id: "55", name: "Khersonska" },
	{ id: "38", name: "Khmelnytska" },
	{ id: "47", name: "Cherkaska" },
	{ id: "37", name: "Chernivetska" },
	{ id: "46", name: "Chernihivska" },
	{ id: "450", name: "Monobrand DemoSim" },
	{ id: "53", name: "Poltavska" },
];
