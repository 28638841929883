import React from "react";
import { FormikProps } from "../types";

const SelectField = ({field, form: { errors, setFieldValue, touched, values }, ...props }) => {
	let options = [{ label: "", value: ""}];
	let helperText = "";

	if(props.dependBy) {
		options = props.values[values[props.dependBy]] || [];
	} else {
		options = props.values || [];
	}
	const error = touched[field.name] && errors[field.name];

	if (error) {
		helperText = errors[field.name];
	} else if (props.dependBy && !options.length) {
		helperText = props.helperText || "";
	}
	const labelClass = props.required ? "w-required-field": "";
	const selectClasses = "custom-select" + (Boolean(error) ? " is-invalid" : "");
	return (
		<div className="form-group">
			<label className={labelClass} htmlFor={props.id}>{props.label}</label>
			<select
				className={selectClasses}
				disabled={!options.length || props?.attr?.disabled}
				id={props.id}
				error={error}
				required={props.required}
				{...field}
				onChange={(props.onChange && props.onChange(field.name, setFieldValue)) || field.onChange}
			>
				{props.placeholder && (props.showPlaceholder ?
					<option value="">-- {props.placeholder} --</option> :
					<option value="" disabled hidden>-- {props.placeholder} --</option>)}
				{options.map((option, index) => (
					<option key={index} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
			{helperText && Boolean(error) ?
				<small className="form-text text-danger">{helperText}</small> :
				<small className="form-text text-muted">{helperText}</small>}
		</div>
	);
};

interface SelectFieldFormikProps {
    formik: FormikProps;
    name: string;
    dependBy?: any;
    values: any;
    helperText?: string;
    required?: boolean;
    attr: any;
    onChange?: any;
    id: string;
    label?: string;
    placeholder?: string;
    showPlaceholder?: boolean;
    value: any;
}

const SelectFieldFormik = (props: SelectFieldFormikProps) => {
	let options = [{ label: "", value: ""}];
	let helperText;
	const { name, formik: { setFieldValue, errors, touched, handleChange } } = props;
	if(props.dependBy) {
		options = props.values[props.formik.values[props.dependBy]] || [];
	} else {
		options = props.values || [];
	}
	const error = touched[name] && errors[name];

	if (error) {
		helperText = errors[name] || "";
	} else if (props.dependBy && !options.length) {
		helperText = props.helperText || "";
	}
	const labelClass = props.required ? "w-required-field" : "";
	const selectClasses = "custom-select" + (Boolean(error) ? " is-invalid" : "");
	return (
		<div className="form-group">
			{props.label && <label className={labelClass} htmlFor={props.id}>{props.label}</label>}
			<select
				className={selectClasses}
				name={props.name}
				disabled={!options.length || props?.attr?.disabled}
				id={props.id}
				required={props.required}
				defaultValue={props.value}
				onChange={(props.onChange && props.onChange(name, setFieldValue)) || handleChange}
			>
				{props.placeholder && (props.showPlaceholder ?
					<option value="">-- {props.placeholder} --</option> :
					<option value="" disabled hidden>-- {props.placeholder} --</option>)}
				{options.map((option, index) => (
					<option key={index} value={option.value} >
						{option.label}
					</option>
				))}
			</select>
			{helperText && Boolean(error) ?
				<small className="form-text text-danger">{helperText}</small> :
				<small className="form-text text-muted">{helperText}</small>}
		</div>
	);
};

export { SelectField, SelectFieldFormik };
