import {useState, FC} from "react";
import React from "react";
import {FormattedMessage} from "react-intl";
import {FMCMessages, FMCMessages as messages} from "../../modules/fmc/FMC.messages";
import {FttbAddressEnum} from "../types/FttbEnum";
import {Package,Maybe} from "../../graphql/types";
import {useIntl} from "react-intl";

export interface AllowOnlyOneSBPackageNotificationProps{
	handleGSMSBPackage: () => void;
	gsmActiveSBPackage: Maybe<Package> | undefined
	fttbActiveSbPackage: Maybe<Package> | undefined
	deActivateExistingGSMSBPackageChecked: boolean
	isNoFttbSubscription: boolean | undefined
}

export const  AllowOnlyOneSBPackageNotification: FC<AllowOnlyOneSBPackageNotificationProps> = ({handleGSMSBPackage,gsmActiveSBPackage,fttbActiveSbPackage,deActivateExistingGSMSBPackageChecked,isNoFttbSubscription}) => {
	const intl = useIntl();
	return (
		<div className="mt-2 alert alert-info">
			<div className="row">
				<div className="col-12">
					<div className="w-emphasize">
						<FormattedMessage {...messages.gsmSBPackage} /> {gsmActiveSBPackage?.name?.[intl.locale]}
					</div>
				</div>
				<div className="d-flex col-12">
					<label className="mr-2">
						{isNoFttbSubscription ? <FormattedMessage {...messages.deactivateGSMSBPackageAsFTTBSBPackageSelected} /> : <FormattedMessage {...messages.allowOnlyOneSBPackage} />}

					</label>
					<div className="custom-control custom-checkbox">
						<input
							className="custom-control-input"
							id="no-gsm-sb"
							type="checkbox"
							checked={deActivateExistingGSMSBPackageChecked}
							onChange={handleGSMSBPackage}
						/>
						<label className="custom-control-label" htmlFor="no-gsm-sb">
							<FormattedMessage {...messages.deactivateGSMSBPackage} />
						</label>
					</div>
				</div>
			</div>
		</div>
	);

}

