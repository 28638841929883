import React, { useMemo } from "react";
import { Field } from "formik";
import { DatePickerField, InputField, SelectField } from "../../../../../../shared/components";
import moment from "moment";
import { config } from "../../../../../../config/constants";
import { document_types, genders, initialLabels, transformToEngLetter } from "../../../../../../shared/utils";
import { UserMessages } from "../../../../../user/User.messages";
import InitialValuesType from "../../../../../registration/Registration.types";
import { RegistrationMessages } from "../../../../../registration/Registration.messages";
import { useIntl } from "react-intl";
import { CommonMessages } from "../../../../../../Common.messages";
import { InputWithCheckBox } from "../../../../../../shared/components";
import { DocumentTypeEnum } from "../../../../../../shared/types/DocumentTypeEnum";

interface PersonFormProps {
    setFieldValue: (field: string, value: any) => void;
    values: any;
    initialValues: any;
}

const PersonForm = (props: PersonFormProps) => {
	const intl = useIntl();
	const onlyDigitsPlaceholder = useMemo(() => intl.formatMessage({...CommonMessages.inputOnlyDigits}, {number: config.ITN_LENGTH}), [intl.locale]);
	const documentTypes = useMemo(() => document_types(intl), [intl.locale]);
	const labels = useMemo(() => initialLabels<InitialValuesType>(intl, props.initialValues, RegistrationMessages), [intl.locale]);
	const placeholder = useMemo(() => intl.formatMessage({...UserMessages.choose}), [intl.locale]);
	const checkBoxLabelInt = useMemo(() => intl.formatMessage({...RegistrationMessages.withOut}, {value: "INT"}), [intl.locale]);
	const lengthOfDocumentNumber = useMemo(() => {
		if (props.values.id_document_type === DocumentTypeEnum.passport) {
			return config.PASSPORT_LENGTH;
		}
		if (props.values.id_document_type === DocumentTypeEnum.national_id) {
			return config.NATIONAL_ID;
		}
		return config.DEFAULT_INPUT_LENGTH;
	}, [props.values]);

	const onChangeDocumentNumber = (fieldName, setFieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = e.target.value.trim().toUpperCase();
		const transformValue = transformToEngLetter(value);
		setFieldValue(fieldName, transformValue);
	};

	const onChangeInt = (selected: boolean) => {
		if (selected) {
			props.setFieldValue("individual_tax_number", config.INDIVIDUAL_TAX_NUMBER_EMPTY);
		} else {
			if (props.initialValues?.individual_tax_number === config.INDIVIDUAL_TAX_NUMBER_EMPTY) {
				props.setFieldValue("individual_tax_number", "");
			} else {
				props.setFieldValue("individual_tax_number", props.initialValues?.individual_tax_number || "");
			}
		}
	};

	return (
		<div>
			<div className="row">
				<div className="col-12 col-sm-6">
					<Field
						required
						name="id_document_type"
						id="id_document_type"
						label={labels.id_document_type}
						placeholder={placeholder}
						attr={{maxLength: 256}}
						values={documentTypes}
						component={SelectField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						required
						name="id_document_number"
						id="document-number"
						label={labels.id_document_number}
						fullWidth
						attr={{maxLength: lengthOfDocumentNumber}}
						onChange={onChangeDocumentNumber}
						component={InputField}
					/>
				</div>
				<div className="col-12 col-sm-12">
					<Field
						name="id_doc_issued_by"
						id="issued-by"
						label={labels.id_doc_issued_by}
						attr={{maxLength: 256}}
						component={InputField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						min={moment(config.MIN_DATE_FORM)}
						max={moment()}
						name="id_doc_issue_date"
						label={labels.id_doc_issue_date}
						format={config.DATE_PICKER_FORMAT}
						component={DatePickerField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						max={moment().endOf("year").add(config.DATE_STEP, "year")}
						min={moment().subtract(1, "day")}
						name="id_doc_valid_till_date"
						label={labels.id_doc_valid_till_date}
						format={config.DATE_PICKER_FORMAT}
						component={DatePickerField}
					/>
				</div>
			</div>
			<InputWithCheckBox
				label={checkBoxLabelInt}
				id={"individual_tax_number"}
				checked={props.values.individual_tax_number == config.INDIVIDUAL_TAX_NUMBER_EMPTY}
				onChange={onChangeInt}
			>
				{(selected) => (
					<div className="col-12 col-sm-6 col-md-4">
						<Field
							required={props.values.id_document_type === config.PASSPORT}
							name="individual_tax_number"
							id="itn"
							placeholder={onlyDigitsPlaceholder}
							attr={{
								maxLength: config.ITN_LENGTH,
								disabled: selected
							}}
							label={labels.individual_tax_number}
							component={InputField}
						/>
					</div>)}
			</InputWithCheckBox>
			<div className="row">
				<div className="col-12 col-sm-6">
					<Field
						required
						name="last_name"
						id="last-name"
						label={labels.last_name}
						attr={{maxLength: 256}}
						component={InputField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						required
						name="first_name"
						id="first-name"
						label={labels.first_name}
						attr={{maxLength: 256}}
						component={InputField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						id="second-name"
						name="middle_name"
						label={labels.middle_name}
						attr={{maxLength: 256}}
						component={InputField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						required
						name="gender"
						id="gender"
						label={labels.gender}
						intl={intl}
						placeholder={placeholder}
						values={genders(intl)}
						component={SelectField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						required
						max={moment().subtract(1, "day")}
						min={moment(config.MIN_DATE_FORM)}
						name="date_of_birth"
						label={labels.date_of_birth}
						format={config.DATE_PICKER_FORMAT}
						component={DatePickerField}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Field
						name="email"
						id="email"
						label={labels.email}
						component={InputField}
					/>
				</div>
			</div>
		</div>
	);
};

export { PersonForm };