import React, { useMemo } from "react";
import { InputField, InputFieldFormik } from "../../../../../shared/components";
import { Field } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { RegistrationMessages } from "../../../../registration/Registration.messages";
import { initialLabels } from "../../../../../shared/utils";
import { FormikProps } from "../../../../../shared/types";

interface InitialValuesType {
    first_name: string;
    middle_name: string;
    last_name: string;
}

const initialValues = {
	first_name: "",
	middle_name: "",
	last_name: "",
};

const AuthorizedPersonForm = (props: {
    setValues: (obj: any) => void;
    values: any;
}) => {
	const intl = useIntl();
	const showAuthorized = () => {
		props.setValues({
			...props.values,
			authorized: !props.values.authorized,
			last_name_person: "",
			first_name_person: "",
			middle_name_person: "",
			attorney_number: "",
		});
	};
	const labels = useMemo(() => initialLabels<InitialValuesType>(intl, initialValues, RegistrationMessages), [intl.locale]);
	return (
		<>
			<div className="custom-control custom-checkbox">
				<input
					type="checkbox"
					name="authorized"
					onChange={showAuthorized}
					checked={props.values.authorized}
					className="custom-control-input"
					id="authorized-checkbox"
				/>
				<label className="custom-control-label" htmlFor="authorized-checkbox">
					<FormattedMessage {...RegistrationMessages.authorizePerson} />
				</label>
			</div>
			{props.values.authorized && (
				<div className="row">
					<div className="col-12 col-sm-6">
						<Field
							name="last_name_person"
							id="last-name-person"
							label={labels.last_name}
							intl={intl}
							fullWidth
							attr={{maxLength: 256}}
							component={InputField}
						/>
					</div>
					<div className="col-sm-6">
						<Field
							name="first_name_person"
							id="first-name-person"
							label={labels.first_name}
							intl={intl}
							component={InputField}
						/>
					</div>
					<div className="col-sm-6">
						<Field
							name="middle_name_person"
							id="middle-name-person"
							label={labels.middle_name}
							intl={intl}
							component={InputField}
						/>
					</div>
					<div className="col-sm-6">
						<Field
							name="attorney_number"
							id="middle-name-person"
							label={"Power of attorney number"}
							intl={intl}
							component={InputField}
						/>
					</div>
				</div>)}
		</>);
};


const AuthorizedPersonFormFormik = (props: {
    formik: FormikProps;
}) => {
	const {formik}  = props;
	const intl = useIntl();
	const showAuthorized = () => {
        formik.setValues?.({
        	...formik.values,
        	authorized: !formik.values.authorized,
        	last_name_person: "",
        	first_name_person: "",
        	middle_name_person: "",
        	attorney_number: "",
        });
	};
	const labels = useMemo(() => initialLabels<InitialValuesType>(intl, initialValues, RegistrationMessages), [intl.locale]);
	return (
		<>
			<div className="custom-control custom-checkbox">
				<input
					type="checkbox"
					name="authorized"
					onChange={showAuthorized}
					checked={formik.values.authorized}
					className="custom-control-input"
					id="authorized-checkbox"
				/>
				<label className="custom-control-label" htmlFor="authorized-checkbox">
					<FormattedMessage {...RegistrationMessages.authorizePerson} />
				</label>
			</div>
			{formik.values.authorized && (
				<div className="row">
					<div className="col-12 col-sm-6">
						<InputFieldFormik
							name="last_name_person"
							id="last-name-person"
							label={labels.last_name}
							attr={{maxLength: 256}}
							value={formik.values.last_name_person}
							formik={formik}
						/>
					</div>
					<div className="col-sm-6">
						<InputFieldFormik
							name="first_name_person"
							id="first-name-person"
							label={labels.first_name}
							value={formik.values.first_name_person}
							formik={formik}
						/>
					</div>
					<div className="col-sm-6">
						<InputFieldFormik
							name="middle_name_person"
							id="middle-name-person"
							label={labels.middle_name}
							value={formik.values.middle_name_person}
							formik={formik}
						/>
					</div>
					<div className="col-sm-6">
						<InputFieldFormik
							name="attorney_number"
							id="middle-name-person"
							label={"Power of attorney number"}
							value={formik.values.attorney_number}
							formik={formik}
						/>
					</div>
				</div>)}
		</>);
};

export {
	AuthorizedPersonForm,
	AuthorizedPersonFormFormik
};