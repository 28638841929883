
import gql from "graphql-tag";
import { TariffPlan } from "../fragments/subscriptionType.fragments";

const GET_SUBSCRIPTION_TYPE = gql`
    query GetSubscriptionTypes($subscription_type_id: ID!) {
        subscription_type(subscription_type_id: $subscription_type_id) {
            ...tariffPlanDetails,
        }
    }
    ${TariffPlan.fragments.full}
`;

export {
	GET_SUBSCRIPTION_TYPE
};
