import * as Yup from "yup";
import { IntlShape } from "react-intl";
import CommonMessages from "../../Common.messages";
import { config } from "../../config/constants";
import {namingFIOExp, onlyDigitsExp, passwordExp, phoneUkrExp} from "../../shared/utils/validationRules";
import {DocumentTypeEnum} from "../../shared/types/DocumentTypeEnum";
import {DonorSegmentEnum} from "../../graphql/types";

const temporaryMsisdn = (intl: IntlShape) => {
	const requiredErrorMsg: string = intl.formatMessage({...CommonMessages.required});
	const phoneFormatErrorMsg = intl.formatMessage({...CommonMessages.inputFormat}, { format: config.PHONE_FORMAT });
	return Yup.object().shape({
		msisdn: Yup.string().matches(phoneUkrExp, phoneFormatErrorMsg).required(requiredErrorMsg),
	});
};

const validateDocType = (self: Yup.TestContext, value, intl: IntlShape) => {
	if (value && self.parent?.id_document_type === DocumentTypeEnum.passport) {
		if (value.length !== config.PASSPORT_LENGTH) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.amountSymbols}, { symbols: config.PASSPORT_LENGTH }),
				path: self.path,
			});
		} else if (!passwordExp.test(value)) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.inputFormat}, { format: config.PASSPORT_FORMAT }),
				path: self.path,
			});
		} else {
			return true;
		}
	} else if (value && self.parent?.id_document_type === DocumentTypeEnum.national_id) {
		if (value.length !== config.ID_CARD_LENGTH) {
			return self.createError({
				message: intl.formatMessage({...CommonMessages.inputOnlyDigits}, { number: config.NATIONAL_ID }),
				path: self.path,
			});
		}
		return onlyDigitsExp.test(value) ? true : self.createError({
			message: intl.formatMessage({...CommonMessages.onlyDigits}),
			path: self.path,
		});
	}
	return true;
};

const mnpData = (intl: IntlShape) => {
	const requiredErrorMsg: string = intl.formatMessage({...CommonMessages.required});
	const phoneFormatErrorMsg = intl.formatMessage({...CommonMessages.inputFormat}, { format: config.PHONE_FORMAT });
	const capitalLetterErrorMsg: string = intl.formatMessage({...CommonMessages.capitalLetter});
	return Yup.object().shape({
		msisdn: Yup.string().matches(phoneUkrExp, phoneFormatErrorMsg).required(requiredErrorMsg),
		donorOperator: Yup.string().required(requiredErrorMsg),
		donor_segment: Yup.string().required(requiredErrorMsg),
		donor_first_name: Yup.string().when("donor_segment", {
			is: DonorSegmentEnum.b2cPassportized || DonorSegmentEnum.mmc,
			then: Yup.string().matches(namingFIOExp, capitalLetterErrorMsg).required(requiredErrorMsg)
		}),
		donor_last_name: Yup.string().when("donor_segment", {
			is: DonorSegmentEnum.b2cPassportized || DonorSegmentEnum.mmc,
			then: Yup.string().test("donor_last_name", capitalLetterErrorMsg, (value) => (value ? namingFIOExp.test(value) : true))
		}),
		id_document_type: Yup.string().when("donor_segment", {
			is: DonorSegmentEnum.popEntrepreneur,
			then: Yup.string().required(requiredErrorMsg)
		}),
		donor_passport_id_card: Yup.string().when("donor_segment", {
			is: DonorSegmentEnum.popEntrepreneur,
			then: Yup.string().test({
				name: "donor_passport_id_card",
				test: function (value) {
					return validateDocType(this, value, intl);
				}
			})
		}),
		donor_okpo: Yup.string().when("donor_segment", {
			is: DonorSegmentEnum.popB2b,
			then: Yup.string().required(requiredErrorMsg)
		})
	});
};

export { temporaryMsisdn, mnpData};