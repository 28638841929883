import gql from "graphql-tag";

const CONFIRMATION_ACTION = gql`
    fragment confirmationAction on ConfirmationAction {
        code,
        status,
        action,
    }
`;

export {
	CONFIRMATION_ACTION,
};