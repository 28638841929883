import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FMCMessages } from "../../FMC.messages";
import { createMarkup } from "../../../../shared/utils/createMarkup";
import { PackagePrices } from "../../../packages/PackagePrice";
import { RECURRENT, SINGLE } from "../../../../config/constants";
import { Language } from "../../../../graphql/types";

interface PlanCardProps {
    change_price?: number|null;
    fee?: number|null;
    name?: Language|null;
    long_description?: Language|null;
    medium_description?: Language|null;
    periodic_amount?: number|null;
    periodic_unit?: string|null;
}

const PlanCard: FC<PlanCardProps> = props => {
	const intl = useIntl();
	return (
		<div className="bg-white border-info card">
			<h4 className="card-header"><FormattedMessage {...FMCMessages.plan} /></h4>
			<div className="card-body">
				<h5 className="card-title">{props.name?.[intl.locale]}</h5>
				<p className="card-text" dangerouslySetInnerHTML={createMarkup(
                    props?.long_description?.[intl.locale] ||
                    props?.medium_description?.[intl.locale])}>
				</p>
			</div>
			<div className="card-footer text-success">
				{<PackagePrices
					fee={props.fee}
					fee_type={RECURRENT}
					periodic_amount={props.periodic_amount}
					periodic_unit={props.periodic_unit}
				/>}
				{props.change_price && props.change_price === 0 ? <PackagePrices
					fee={props.change_price}
					fee_type={SINGLE}
				/> : null}
			</div>
		</div>
	);
};

export { PlanCard };