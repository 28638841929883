import React, { useCallback, useMemo } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { useIntl } from "react-intl";
import { InputFieldFormik, NavigationHeader } from "../../../../../shared/components";
import { useFormik } from "formik";
import { document_types, genders, initialLabels, getAddressFromExistingCustomer } from "../../../../../shared/utils";
import { RegistrationMessages } from "../../../../registration/Registration.messages";
import { AddressForm } from "./AddressForm";
import { ACQUISITION_EXISTING_FLOW_ICCID_PATH, ACQUISITION_EXISTING_FLOW_SUMMARY_PATH } from "../../Router/AcquisitionExistingFlowPath";
import { SelectFieldFormik } from "../../../../../shared/components/SelectField";
import { AcquisitionExistingFlow } from "../../Router/AcquisitionExistingFlow";
import { ExistingCustomerRouteState } from "../Summary/Summary";
import { AuthorizedPersonFormFormik } from "../../../NewCustomer/Pages/ContactInfo/AuthorizedPersonForm";
import moment from "moment";

// TODO move
const titles = {
	id_doc_valid_till_date: "",
	last_name: "",
	middle_name: "",
	first_name: "",
	gender: "",
	date_of_birth: "",
	id_document_type: "",
	id_document_number: "",
	id_doc_issued_by: "",
	individual_tax_number: "",
	id_doc_issue_date_additional: "",
	id_doc_issue_date: "",
	contact_phone: "",
	postal_code: "",
	province: "",
	district: "",
	email: "",
	city: "",
	house_number: "",
	street: "",
};

const initialValues = {
	id_expiry: "",
	id_doc_valid_till_date: "",
	tax_id: "",
	last_name: "",
	middle_name: "",
	first_name: "",
	gender: "",
	date_of_birth: "",
	id_document_type: "",
	id_document_number: "",
	id_document_issued_by: "",
	id_doc_issued_by: "",
	individual_tax_number: "",
	id_doc_issue_date_additional: "",
	id_document_issue_date: "",
	id_doc_issue_date: "",
	additional_id_date: "",
	contact_phone: "",
	postal_code: "",
	province: "",
	district: "",
	email: "",
	city: "",
	house_number: "",
	comments: "",
	street: "",
	address: [],
	last_name_person: "",
	first_name_person: "",
	middle_name_person: "",
	attorney_number: "",
	authorized: false,
};
const DISPLAY_DATE_FORMAT = "DD.MM.YYYY";

const ContactInfo = (props: RouteComponentProps<never, StaticContext, ExistingCustomerRouteState>) => {
	const formId = "existing-customer-contact-form";
	const {location:{state}, history} = props;
	const intl = useIntl();
	const {address, ...customerInfo} = state?.customer || initialValues;
	const customer = {
		...customerInfo,
		...getAddressFromExistingCustomer(address, intl.locale),
	};
	const labels = useMemo(() => initialLabels<any>(intl, titles, RegistrationMessages), [intl.locale]);
	const documentTypes = useMemo(() => document_types(intl), [intl.locale]);
	const onClickBack = useCallback(() => history.push(ACQUISITION_EXISTING_FLOW_ICCID_PATH, state), []);
	const {errors, setFieldValue, touched, handleChange, values, setValues, handleSubmit} = useFormik({initialValues: customer, onSubmit: (values) => {
		history.push(ACQUISITION_EXISTING_FLOW_SUMMARY_PATH, {
			...state,
			customer: {
				...customer,
				last_name_person: values.last_name_person,
				first_name_person: values.first_name_person,
				middle_name_person: values.middle_name_person,
				attorney_number: values.attorney_number,
				authorized: values.authorized,
				address
			}});
	}});
	const formik = {errors, setFieldValue, touched, handleChange, values, setValues, handleSubmit};
	return (
		<>
			<NavigationHeader FlowComp={AcquisitionExistingFlow} formId={formId}  back={onClickBack} />
			<div className="ipos-content-box">
				<form id={formId} onSubmit={handleSubmit} >
					<div className="row">
						<div className="col-12 col-sm-6">
							<SelectFieldFormik
								name="id_document_type"
								id="id_document_type"
								label={labels.id_document_type}
								attr={{disabled: true}}
								value={formik.values.id_document_type}
								values={documentTypes}
								formik={formik}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								name="id_document_number"
								id="document-number"
								label={labels.id_document_number}
								attr={{disabled: true}}
								value={formik.values.id_document_number}
								formik={formik}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								id="document_issue_date"
								name="id_document_issue_date"
								label={labels.id_doc_issue_date}
								attr={{disabled: true}}
								value={formik.values.id_document_issue_date ?
									moment(formik.values.id_document_issue_date).format(DISPLAY_DATE_FORMAT) :
									formik.values.id_document_issue_date}
								formik={formik}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								id="expiry"
								name="id_expiry"
								label={labels.id_doc_valid_till_date}
								attr={{disabled: true}}
								value={formik.values.id_expiry ?
									moment(formik.values.id_expiry).format(DISPLAY_DATE_FORMAT) :
									formik.values.id_expiry}
								formik={formik}
							/>
						</div>
						<div className="col-12 col-sm-12">
							<InputFieldFormik
								name="id_document_issued_by"
								id="issued-by"
								label={labels.id_doc_issued_by}
								value={formik.values.id_document_issued_by}
								attr={{disabled: true}}
								formik={formik}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								id="additional-date"
								name="additional_id_date"
								label={labels.id_doc_issue_date_additional}
								attr={{disabled: true}}
								formik={formik}
								value={formik.values.additional_id_date ?
									moment(formik.values.additional_id_date).format(DISPLAY_DATE_FORMAT) :
									formik.values.additional_id_date}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								name="tax_id"
								id="itn"
								attr={{disabled: true}}
								label={labels.individual_tax_number}
								value={formik.values.tax_id}
								formik={formik}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								name="contact_phone"
								id="contact-phone"
								label={labels.contact_phone}
								attr={{disabled: true}}
								value={formik.values.contact_phone}
								formik={formik}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								name="last_name"
								id="last-name"
								label={labels.last_name}
								attr={{disabled: true}}
								value={formik.values.last_name}
								formik={formik}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								id="second-name"
								name="middle_name"
								label={labels.middle_name}
								attr={{disabled: true}}
								formik={formik}
								value={formik.values.middle_name}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								name="first_name"
								id="first-name"
								label={labels.first_name}
								attr={{disabled: true}}
								value={formik.values.first_name}
								formik={formik}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<SelectFieldFormik
								name="gender"
								id="gender"
								label={labels.gender}
								attr={{disabled: true}}
								value={formik.values.gender}
								values={genders(intl)}
								formik={formik}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								id="date-birth"
								name="date_of_birth"
								label={labels.date_of_birth}
								attr={{disabled: true}}
								formik={formik}
								value={formik.values.date_of_birth ?
									moment(formik.values.date_of_birth).format(DISPLAY_DATE_FORMAT) :
									formik.values.date_of_birth}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								name="email"
								id="email"
								label={labels.email}
								attr={{disabled: true}}
								value={formik.values.email}
								formik={formik}
							/>
						</div>
					</div>
					<AddressForm formik={formik} />
					<AuthorizedPersonFormFormik formik={formik} />
				</form>
			</div>
		</>
	);
};

export { ContactInfo };