/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface FTTBMessagesType {
	address: MessageDescriptor;
	addressToConnection: MessageDescriptor;
	availableAddress: MessageDescriptor;
	b2bFttbConnection: MessageDescriptor;
	billingAccount: MessageDescriptor;
	comment: MessageDescriptor;
	company: MessageDescriptor;
	complexOffer: MessageDescriptor;
	connectToday: MessageDescriptor;
	connectionDay: MessageDescriptor;
	details: MessageDescriptor;
	fttbConnection: MessageDescriptor;
	fttbConnectionExist: MessageDescriptor;
	inventoryNoMsisdn: MessageDescriptor;
	msisdnReservationFail: MessageDescriptor;
	needsStarterPack: MessageDescriptor;
	newConnectionNotPossible: MessageDescriptor;
	newConnectionhasActiveConnection: MessageDescriptor;
	newFlatSelectionPrefix: MessageDescriptor;
	notAvailableAddress: MessageDescriptor;
	options: MessageDescriptor;
	otherOptions: MessageDescriptor;
	plan: MessageDescriptor;
	planTypeConvergent: MessageDescriptor;
	planTypeNormal: MessageDescriptor;
	plansNotFound: MessageDescriptor;
	selectCity: MessageDescriptor;
	selectCountry: MessageDescriptor;
	selectDay: MessageDescriptor;
	selectDistrict: MessageDescriptor;
	selectFlat: MessageDescriptor;
	selectHouse: MessageDescriptor;
	selectProvince: MessageDescriptor;
	selectRegion: MessageDescriptor;
	selectStreet: MessageDescriptor;
	serviceFastConnectionAvailable: MessageDescriptor;
	simpleConnection: MessageDescriptor;
	simpleConnectionHasBeenSelected: MessageDescriptor;
	starterPack: MessageDescriptor;
	summary: MessageDescriptor;
	gigabit: MessageDescriptor;
}
const FTTBMessages: FTTBMessagesType = defineMessages({
	address: {
		id: "fttb-flow-address-to-connection-title-address",
		description: "FTTB flow label current page",
		defaultMessage: "Address"
	},
	addressToConnection: {
		id: "fttb-flow-address-to-connection",
		description: "Label for block address",
		defaultMessage: "Address of FTTB connection"
	},
	availableAddress: {
		id: "fttb-flow-address-to-connection-is-available",
		description: "Label for address is available",
		defaultMessage: "{networkType} available to the given address"
	},
	b2bFttbConnection: {
		id: "fttb-b2b-flow-connection",
		description: "Title of B2B FTTB connection",
		defaultMessage: "B2B FTTB Connection"
	},
	billingAccount: {
		id: "fttb-b2b-flow-title-billingAccount",
		description: "FTTB b2b flow label current page billing account",
		defaultMessage: "Billing Account"
	},
	comment: {
		id: "fttb-flow-comment",
		description: "Fttb flow option page. Comment option title",
		defaultMessage: "Comment"
	},
	company: {
		id: "fttb-b2b-flow-title-company",
		description: "FTTB b2b flow label current page company",
		defaultMessage: "Company"
	},
	complexOffer: {
		id: "fttb-flow-complex-offer",
		description: "Fttb flow option page. Complex Offer option title",
		defaultMessage: "Complex offer"
	},
	connectToday: {
		id: "fttb-flow-connect-today",
		description: "I would like to connect today! Options page",
		defaultMessage: "I would like to connect today!"
	},
	connectionDay: {
		id: "fttb-flow-connection-day",
		description: "Label of desirable connection",
		defaultMessage: "Desirable connection date:"
	},
	details: {
		id: "fttb-flow-address-details",
		description: "A direct link/button title, leading to WTTX connection page",
		defaultMessage: "Details"
	},
	fttbConnection: {
		id: "fttb-flow-connection",
		description: "Title of FTTB connection",
		defaultMessage: "FTTB connection"
	},
	fttbConnectionExist: {
		id: "fttb-flow-connection-exist",
		description: "Error msg fttb connection exists",
		defaultMessage: "You already have the internet connection associated with this contact number, you can change the address in CSRTB instead of new connection"
	},
	inventoryNoMsisdn: {
		id: "fttb-inventory-does-not-have-available-msisdns",
		description: "Inventory does not have available Msisdns erorr",
		defaultMessage: "Inventory does not have available Msisdns"
	},
	msisdnReservationFail: {
		id: "fttb-msisdn-reservation-erorr",
		description: "Msisdn reservation is failed",
		defaultMessage: "Inventory does not have available Msisdns"
	},
	needsStarterPack: {
		id: "fttb-flow-needs-a-starter-pack-starter-pack",
		description: "Fttb flow option page.Needs a Starter Pack option title",
		defaultMessage: "Needs a starter pack"
	},
	newConnectionNotPossible: {
		id: "fttb-flow-new-connection-not-possible",
		description: "Existing active connection error msg",
		defaultMessage: "Active connection found in the given address. New connection is not possible."
	},
	newConnectionhasActiveConnection: {
		id: "fttb-flow-new-connection-already-has-active-connection-continue",
		description: "Existing active connection error msg",
		defaultMessage: "Address already has active connection, you can continue with new connection registration (Clicking \"Next\" button) or"
	},
	newFlatSelectionPrefix: {
		id: "fttb-flow-new-connection-select-new-flat",
		description: "FTTB flow select new flat not in GIS list",
		defaultMessage: "Enter Flat not existing in the list of flats"
	},
	notAvailableAddress: {
		id: "fttb-flow-address-to-connection-is-not-available",
		description: "Label for block address is not available",
		defaultMessage: "Unfortunately there is no technical possibility of «Home Internet and Home TV» connection at the address {city}, {street}, {buildingNumber}"
	},
	options: {
		id: "fttb-flow-address-to-connection-title-options",
		description: "FTTB flow label current page",
		defaultMessage: "Options"
	},
	otherOptions: {
		id: "fttb-flow-other-options",
		description: "Ftt flow option page. Other options optioin group label",
		defaultMessage: "Other Options:"
	},
	plan: {
		id: "fttb-flow-address-to-connection-title-plan",
		description: "FTTB flow label current page",
		defaultMessage: "Plan"
	},
	planTypeConvergent: {
		id: "fttb-flow-plan-type-convergent",
		description: "Fttb plan type convergent",
		defaultMessage: "FTTB for FMC"
	},
	planTypeNormal: {
		id: "fttb-flow-plan-type-normal",
		description: "Fttb plan type ",
		defaultMessage: "Normal"
	},
	plansNotFound: {
		id: "fttb-slow-tariff-paln-not-found",
		description: "Fttb tariff plans empty list",
		defaultMessage: "There is no tariff plans"
	},
	selectCity: {
		id: "fttb-flow-address-form-field-city",
		description: "Placeholder for select input",
		defaultMessage: "Select city"
	},
	selectCountry: {
		id: "fttb-flow-address-form-field-country",
		description: "Placeholder for select input",
		defaultMessage: "Select country"
	},
	selectDay: {
		id: "fttb-flow-select-day",
		description: "Select the day: msg Options page",
		defaultMessage: "Select the day:"
	},
	selectDistrict: {
		id: "fttb-flow-address-form-field-district",
		description: "Placeholder for select input",
		defaultMessage: "Select district"
	},
	selectFlat: {
		id: "fttb-flow-address-form-field-flat",
		description: "Placeholder for select input flat",
		defaultMessage: "Select flat"
	},
	selectHouse: {
		id: "fttb-flow-address-form-field-house",
		description: "Placeholder for select input",
		defaultMessage: "Select house"
	},
	selectProvince: {
		id: "fttb-flow-address-form-field-province",
		description: "Placeholder for select input",
		defaultMessage: "Select province"
	},
	selectRegion: {
		id: "fttb-flow-address-form-field-region",
		description: "Placeholder for select input",
		defaultMessage: "Select region"
	},
	selectStreet: {
		id: "fttb-flow-address-form-field-street",
		description: "Placeholder for select input",
		defaultMessage: "Select street"
	},
	serviceFastConnectionAvailable: {
		id: "fttb-flow-service-fast-connection-available",
		description: "Service fast connection available notification msg",
		defaultMessage: "Service fast connection is available today for: "
	},
	simpleConnection: {
		id: "fttb-flow-simple-connection",
		description: "Simple connection msg",
		defaultMessage: "Simple connection possible on the address - handle as simple connection"
	},
	simpleConnectionHasBeenSelected: {
		id: "fttb-flow-simpl-connection-service-has-been-selected",
		description: "Sumary msg simple connection has been selected",
		defaultMessage: "Simple connection service has been selected."
	},
	starterPack: {
		id: "fttb-flow-starter-pack",
		description: "Fttb flow option page. Starter Pack option title",
		defaultMessage: "Starter Pack"
	},
	summary: {
		id: "fttb-flow-address-to-connection-title-summary",
		description: "FTTB flow label current page",
		defaultMessage: "Summary"
	},
	gigabit: {
		id: "fttb-flow-gigabit-package",
		description: "Gigabit package message",
		defaultMessage: "Gigabit is available per address"
	}
});

export default FTTBMessages;
export { FTTBMessages };
