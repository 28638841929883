import React, {useEffect, useState} from "react";
import { FormattedMessage } from "react-intl";
import {useLazyQuery} from "@apollo/react-hooks";
import {Query, QueryBillingAccountsArgs} from "../../graphql/types";
import {GET_BILLING_ACCOUNTS} from "../../graphql/queries/company";
import {CommonMessages} from "../../Common.messages";

interface PaginationProps {
	setBillingAccounts?: any;
	setLoading?: any;
	companyId: string;
}

const BillingAccountPagination = (props: PaginationProps) => {
	const [counter, setCounter] = useState(1);
	const [billingAccountsLength, setBillingAccountsLength] = useState(0);
	const {companyId, setBillingAccounts, setLoading} = props;
	const [billingAccounts, {loading}] = useLazyQuery<Query, QueryBillingAccountsArgs>(GET_BILLING_ACCOUNTS, {
		onCompleted: (data: Query) => {
			setBillingAccounts(data?.company_billing_accounts || []);
			setBillingAccountsLength(data?.company_billing_accounts?.length || 0);
		}
	});
	setLoading(loading);
	useEffect(() => {
		billingAccounts({ variables: { company_id: companyId, page_number: counter, page_size: 10}});
	}, [counter]);
	const onPreviousClick = () => {
		if (counter !== 1) {
			setCounter(counter - 1);
		}
	};
	const onNextClick = () => {
		setCounter(counter + 1);
	};
	return (
		<div className="mb-0 text-center">
			<button className="btn btn-primary" onClick={onPreviousClick} disabled={(counter === 1) || loading}>
				<FormattedMessage {...CommonMessages.previous} />
			</button>
			<button className="btn btn-primary" onClick={onNextClick} disabled={(billingAccountsLength < 10) || loading}>
				<FormattedMessage {...CommonMessages.next} />
			</button>
		</div>
	);
};

export { BillingAccountPagination };
