import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { FormikProps } from "../types";

const InputField = ({ field, form: { errors, touched, setFieldValue }, ...props }) => {
	const error = touched[field.name] && !!errors[field.name];
	const helperText = error ? errors[field.name] : "";
	const inputProps = props.attr ? props.attr : {};
	const type = props.type ? props.type : "text";
	const labelClass = props.required ? "w-required-field" : "";
	return (
		<>
			{props.label && <label className={labelClass}>{props.label}</label>}
			<InputGroup className="mb-3">
				{props.startAdornment && <InputGroup.Prepend>
					<InputGroup.Text>{props.startAdornment}</InputGroup.Text>
				</InputGroup.Prepend>}
				<FormControl
					id={props.id}
					aria-label={props.placeholder}
					type={type}
					placeholder={props.placeholder}
					isInvalid={error || props.error}
					{...field}
					onChange={(props.onChange && props.onChange(field.name, setFieldValue)) || field.onChange}
					{...inputProps}
				/>
				<div className="invalid-feedback">{helperText || props.error}</div>
			</InputGroup>
		</>
	);
};

interface InputFieldFormikProps {
    id: any;
    attr?: any;
    name: string;
    label?: string;
    required?: boolean;
    placeholder?: any;
    startAdornment?: any;
    value: any;
    onChange?: any;
    error?: string;
    type?: string;
    disabled?: boolean;
    formik: FormikProps;
    className?: string;
}

const InputFieldFormik = (props: InputFieldFormikProps) => {
	const {
		id,
		attr,
		name,
		label,
		required,
		placeholder,
		formik: {errors, setFieldValue, handleChange, touched, handleBlur},
	} = props;
	const error = touched[name] && !!errors[name];
	const helperText = error ? errors[name] : "";
	const inputProps = attr ? attr : {};
	const labelClass = required ? "w-required-field": "";
	return (
		<>
			{label && <label className={labelClass}>{label}</label>}
			<InputGroup className="mb-3">
				{props.startAdornment && <InputGroup.Prepend>
					<InputGroup.Text>{props.startAdornment}</InputGroup.Text>
				</InputGroup.Prepend>}
				<FormControl
					id={id}
					aria-label={placeholder}
					name={name}
					type={props.type || "text"}
					disabled={props.disabled}
					placeholder={placeholder}
					isInvalid={error || props.error}
					onChange={(props.onChange && props.onChange(name, setFieldValue)) || handleChange}
					value={props.value}
					{...inputProps}
					onBlur={handleBlur}
				/>
				{/* {labelClass && !label && <label className={labelClass}></label>} */}
				<div className="invalid-feedback">{helperText || props.error}</div>

			</InputGroup>
		</>
	);
};

export { InputField, InputFieldFormik };
