import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { RouteComponentProps, StaticContext } from "react-router";
import {
	ACQUISITION_EXISTING_FLOW_MSISDN_PATH,
	ACQUISITION_EXISTING_FLOW_OPTIONS_PATH,
} from "../../Router/AcquisitionExistingFlowPath";
import { Query, LineTypeEnum } from "../../../../../graphql/types";
import { useQuery } from "@apollo/react-hooks";
import { CommonMessages } from "../../../../../Common.messages";
import { ChangePlanMessages } from "../../../../change-bar/plan/ChangePlan.messages";
import { TableRowList } from "../../../../change-bar/plan/ChangePlanRowList";
import { CircularProgress, ErrorMessageTemplate, NavigationHeader } from "../../../../../shared/components";
import { GET_OFFERINGS_WITHOUT_OPTION_GROUPS } from "../../../../../graphql/queries/acquisitionFlow";
import { SelectedTariffPlanDetails } from "../../../../../graphql/localTypes";
import { prepareOfferings, OfferSubscriptionProps } from "../../../../../shared/utils";
import cssns from "../../../../../shared/utils/cssnsConfig";
import { AcquisitionExistingFlow } from "../../Router/AcquisitionExistingFlow";
import { ExistingCustomerRouteState } from "../Summary/Summary";
import ErrorMessages from "../../../../../shared/error/Error.messages";

const {React} = cssns("Plan-Page");

const TARIFF_PLAN_TYPE_POSTPAID = "postpaid";

const Plan: FC<RouteComponentProps<never, StaticContext, ExistingCustomerRouteState>> = (props) => {
	const {location:{state}, history} = props;
	const [aqusitionState, setAcqusitionState] = useState<ExistingCustomerRouteState| undefined>(state);
	const [error, setError] = useState<string>("");
	const intl = useIntl();
	const {data, loading} = useQuery<Query>(GET_OFFERINGS_WITHOUT_OPTION_GROUPS);
	const offerings: (OfferSubscriptionProps | undefined)[] | undefined = useMemo(() => {
		if (data?.offerings) {
			return prepareOfferings(data.offerings, LineTypeEnum.Mobile);
		} else {
			return [];
		}
	}, [data?.offerings]);

	const onClickNext = useCallback(() => {
		if (!aqusitionState?.plan?.billing_type) {
			setError(intl.formatMessage(ErrorMessages.missedBillingAccount, {name: aqusitionState?.plan?.name?.[intl.locale]}));
		} else if (aqusitionState?.plan?.code) {
			history.push(ACQUISITION_EXISTING_FLOW_OPTIONS_PATH, aqusitionState);
		}
	}, [aqusitionState]);
	const onClickBack = () => history.push({ pathname: ACQUISITION_EXISTING_FLOW_MSISDN_PATH, state});

	const handleSelectTariffPlan = (plan: SelectedTariffPlanDetails) => {
		setAcqusitionState({
			...state,
			plan,
			options: [],
		});
	};
	return (
		<>
			<NavigationHeader FlowComp={AcquisitionExistingFlow} next={onClickNext} back={onClickBack}
				disabledNext={Boolean(error)}/>
			<div className="ipos-content-box">
				<div className="head">
					<div><FormattedMessage {...ChangePlanMessages.plan} /></div>
					<div><FormattedMessage {...CommonMessages.oneTimeFee} /></div>
				</div>
				{!loading && !(data?.offerings?.length! > 0) &&
                <div><FormattedMessage {...ChangePlanMessages.recommendedPlansNotFound} /></div>}
				{offerings?.filter(item => item?.subscription_types?.payment_type === TARIFF_PLAN_TYPE_POSTPAID)
					.map(offerSubType => {
						return (offerSubType?.subscription_types?.code &&
                        (<TableRowList
                        	balance={Infinity}
                        	key={offerSubType?.subscription_types?.code + "top-level"}
                        	tariffPlan={offerSubType?.subscription_types}
                        	offerId={offerSubType.offerId!}
                        	offerCode={offerSubType.offerCode!}
                        	offerSimCardCode={offerSubType.offerSimCardCode}
                        	selectTariffPlan={handleSelectTariffPlan}
                        	selected={aqusitionState?.plan?.code === offerSubType?.subscription_types?.code}
                        />));}
					)}
				<ErrorMessageTemplate show={error}>{error}</ErrorMessageTemplate>

				{loading && (<CircularProgress/>)}
			</div>
		</>
	);
};

export { Plan };