import React from "react";
import { CircularProgress } from "./CircularProgress";
import { FormattedMessage } from "react-intl";
import { CommonMessages } from "../../Common.messages";
import { PayloadProps, useDocumentGeneration } from "../hooks/useDocumentGeneration";

interface DocumentGenerationProps {
    skipCustomerData?: boolean;
    payload: PayloadProps;
    msisdn?: string;
    setContractGenerationError: (value: (((prevState: string) => string) | string)) => void;
}

const DocumentGeneration = (props: DocumentGenerationProps) => {
	const {skipCustomerData, payload, msisdn, setContractGenerationError} = props;
	const {loadingContractGeneration, contractUrl} = useDocumentGeneration({
		skipCustomerData,
		payload,
		msisdn,
		setContractGenerationError,
	});
	return (
		<>
			{(loadingContractGeneration ? <CircularProgress showText={false}/> :
				<div className="w-action-item-link mb-3">
					<a href={contractUrl} download={`contract_${msisdn}.pdf`}>
						<FormattedMessage {...CommonMessages.downloadContract} />
					</a>
				</div>)}
		</>
	);
};

export {DocumentGeneration };