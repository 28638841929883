import gql from "graphql-tag";

const CREATE_ACQUISITION_ORDER = gql`
    mutation CreateAcquisitionOrder($details: OrderInput!) {
        create_acquisition_order(details: $details) {
            request_id,
            status,
            error,
        }
    }
`;
const CREATE_RETENTION_ORDER = gql`
    mutation CreateRetentionOrder($details: OrderInput!) {
        create_retention_order(details: $details) {
            request_id,
            status,
            error,
        }
    }
`;
const CREATE_MNP_ORDER = gql`
    mutation CreateMnpOrder($details: OrderInput!) {
        create_mnp_order(details: $details) {
            request_id,
            status,
            error,
            attributes {
                confirmation_code
            }
        }
    }
`;
const CREATE_FTTB_ORDER = gql`
    mutation CreateFttbOrder($details: OrderInput!) {
        create_fttb_order(details: $details) {
            request_id,
            status,
            error,
        }
    }
`;
export const GET_FTTB_SUBSCRIPTION = gql`
    query Subscription($msisdn: String!) {
        limited_subscription(msisdn: $msisdn) {
            id,
        }
}`;

const CREATE_FMC_ORDER = gql`
    mutation createFmcOrder($details: OrderFmcInput!) {
        create_fmc_order(details: $details) {
            request_id,
            status,
            error,
        }
    }
`;
const UPLOAD_ACQUISITION_DOCUMENT = gql`
    mutation UploadAcquisitionDocument($details: UploadAcquisitionDocument!) {
        upload_acquisition_document(details: $details) {
            status,
            error
        }
    }
`;

export {
	CREATE_FMC_ORDER,
	UPLOAD_ACQUISITION_DOCUMENT,
	CREATE_ACQUISITION_ORDER,
	CREATE_RETENTION_ORDER,
	CREATE_MNP_ORDER,
	CREATE_FTTB_ORDER,
};
