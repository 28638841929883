/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface CountriesMessagesType {
	ad: MessageDescriptor;
	ae: MessageDescriptor;
	af: MessageDescriptor;
	ag: MessageDescriptor;
	al: MessageDescriptor;
	am: MessageDescriptor;
	an: MessageDescriptor;
	ao: MessageDescriptor;
	ar: MessageDescriptor;
	as: MessageDescriptor;
	at: MessageDescriptor;
	au: MessageDescriptor;
	aw: MessageDescriptor;
	az: MessageDescriptor;
	ba: MessageDescriptor;
	bb: MessageDescriptor;
	bd: MessageDescriptor;
	be: MessageDescriptor;
	bf: MessageDescriptor;
	bg: MessageDescriptor;
	bh: MessageDescriptor;
	bi: MessageDescriptor;
	bj: MessageDescriptor;
	bm: MessageDescriptor;
	bn: MessageDescriptor;
	bo: MessageDescriptor;
	br: MessageDescriptor;
	bs: MessageDescriptor;
	bt: MessageDescriptor;
	bw: MessageDescriptor;
	by: MessageDescriptor;
	bz: MessageDescriptor;
	ca: MessageDescriptor;
	cd: MessageDescriptor;
	cf: MessageDescriptor;
	cg: MessageDescriptor;
	ch: MessageDescriptor;
	ci: MessageDescriptor;
	ck: MessageDescriptor;
	cl: MessageDescriptor;
	cm: MessageDescriptor;
	cn: MessageDescriptor;
	co: MessageDescriptor;
	cr: MessageDescriptor;
	cu: MessageDescriptor;
	cv: MessageDescriptor;
	cy: MessageDescriptor;
	cz: MessageDescriptor;
	de: MessageDescriptor;
	dj: MessageDescriptor;
	dk: MessageDescriptor;
	dm: MessageDescriptor;
	do: MessageDescriptor;
	dz: MessageDescriptor;
	ec: MessageDescriptor;
	ee: MessageDescriptor;
	eg: MessageDescriptor;
	eh: MessageDescriptor;
	er: MessageDescriptor;
	es: MessageDescriptor;
	et: MessageDescriptor;
	fi: MessageDescriptor;
	fj: MessageDescriptor;
	fk: MessageDescriptor;
	fm: MessageDescriptor;
	fo: MessageDescriptor;
	fr: MessageDescriptor;
	ga: MessageDescriptor;
	gb: MessageDescriptor;
	gd: MessageDescriptor;
	ge: MessageDescriptor;
	gf: MessageDescriptor;
	gh: MessageDescriptor;
	gi: MessageDescriptor;
	gm: MessageDescriptor;
	gn: MessageDescriptor;
	gp: MessageDescriptor;
	gq: MessageDescriptor;
	gr: MessageDescriptor;
	gt: MessageDescriptor;
	gw: MessageDescriptor;
	gy: MessageDescriptor;
	hn: MessageDescriptor;
	hr: MessageDescriptor;
	ht: MessageDescriptor;
	hu: MessageDescriptor;
	id: MessageDescriptor;
	ie: MessageDescriptor;
	il: MessageDescriptor;
	in: MessageDescriptor;
	iq: MessageDescriptor;
	ir: MessageDescriptor;
	is: MessageDescriptor;
	it: MessageDescriptor;
	jm: MessageDescriptor;
	jo: MessageDescriptor;
	jp: MessageDescriptor;
	ke: MessageDescriptor;
	kg: MessageDescriptor;
	kh: MessageDescriptor;
	ki: MessageDescriptor;
	km: MessageDescriptor;
	kn: MessageDescriptor;
	kp: MessageDescriptor;
	kr: MessageDescriptor;
	kw: MessageDescriptor;
	ky: MessageDescriptor;
	kz: MessageDescriptor;
	la: MessageDescriptor;
	lb: MessageDescriptor;
	lc: MessageDescriptor;
	li: MessageDescriptor;
	lk: MessageDescriptor;
	lr: MessageDescriptor;
	ls: MessageDescriptor;
	lt: MessageDescriptor;
	lu: MessageDescriptor;
	lv: MessageDescriptor;
	ly: MessageDescriptor;
	ma: MessageDescriptor;
	mc: MessageDescriptor;
	md: MessageDescriptor;
	me: MessageDescriptor;
	mg: MessageDescriptor;
	mh: MessageDescriptor;
	mk: MessageDescriptor;
	ml: MessageDescriptor;
	mm: MessageDescriptor;
	mn: MessageDescriptor;
	mp: MessageDescriptor;
	mq: MessageDescriptor;
	mr: MessageDescriptor;
	mt: MessageDescriptor;
	mu: MessageDescriptor;
	mv: MessageDescriptor;
	mw: MessageDescriptor;
	mx: MessageDescriptor;
	my: MessageDescriptor;
	mz: MessageDescriptor;
	na: MessageDescriptor;
	nc: MessageDescriptor;
	ne: MessageDescriptor;
	nf: MessageDescriptor;
	ng: MessageDescriptor;
	ni: MessageDescriptor;
	nl: MessageDescriptor;
	no: MessageDescriptor;
	np: MessageDescriptor;
	nr: MessageDescriptor;
	nu: MessageDescriptor;
	nz: MessageDescriptor;
	om: MessageDescriptor;
	pa: MessageDescriptor;
	pe: MessageDescriptor;
	pf: MessageDescriptor;
	pg: MessageDescriptor;
	ph: MessageDescriptor;
	pk: MessageDescriptor;
	pl: MessageDescriptor;
	pr: MessageDescriptor;
	ps: MessageDescriptor;
	pt: MessageDescriptor;
	pw: MessageDescriptor;
	py: MessageDescriptor;
	qa: MessageDescriptor;
	re: MessageDescriptor;
	ro: MessageDescriptor;
	rs: MessageDescriptor;
	ru: MessageDescriptor;
	rw: MessageDescriptor;
	sa: MessageDescriptor;
	sb: MessageDescriptor;
	sc: MessageDescriptor;
	sd: MessageDescriptor;
	se: MessageDescriptor;
	sg: MessageDescriptor;
	si: MessageDescriptor;
	sk: MessageDescriptor;
	sl: MessageDescriptor;
	sm: MessageDescriptor;
	sn: MessageDescriptor;
	so: MessageDescriptor;
	sr: MessageDescriptor;
	ss: MessageDescriptor;
	st: MessageDescriptor;
	sv: MessageDescriptor;
	sy: MessageDescriptor;
	sz: MessageDescriptor;
	td: MessageDescriptor;
	tf: MessageDescriptor;
	tg: MessageDescriptor;
	th: MessageDescriptor;
	tj: MessageDescriptor;
	tk: MessageDescriptor;
	tm: MessageDescriptor;
	tn: MessageDescriptor;
	to: MessageDescriptor;
	tp: MessageDescriptor;
	tr: MessageDescriptor;
	tt: MessageDescriptor;
	tv: MessageDescriptor;
	tz: MessageDescriptor;
	ua: MessageDescriptor;
	ug: MessageDescriptor;
	us: MessageDescriptor;
	uy: MessageDescriptor;
	uz: MessageDescriptor;
	va: MessageDescriptor;
	vc: MessageDescriptor;
	ve: MessageDescriptor;
	vg: MessageDescriptor;
	vi: MessageDescriptor;
	vn: MessageDescriptor;
	vu: MessageDescriptor;
	ws: MessageDescriptor;
	ye: MessageDescriptor;
	za: MessageDescriptor;
	zm: MessageDescriptor;
	zw: MessageDescriptor;
	zz: MessageDescriptor;
	сс: MessageDescriptor;
}
const CountriesMessages: CountriesMessagesType = defineMessages({
	ad: {
		id: "country-ad",
		description: "Andorra",
		defaultMessage: "Andorra"
	},
	ae: {
		id: "country-ae",
		description: "United Arab Emirates",
		defaultMessage: "United Arab Emirates"
	},
	af: {
		id: "country-af",
		description: "Afghanistan",
		defaultMessage: "Afghanistan"
	},
	ag: {
		id: "country-ag",
		description: "Antigua & Barbuda",
		defaultMessage: "Antigua & Barbuda"
	},
	al: {
		id: "country-al",
		description: "Albania",
		defaultMessage: "Albania"
	},
	am: {
		id: "country-am",
		description: "Armenia",
		defaultMessage: "Armenia"
	},
	an: {
		id: "country-an",
		description: "Netherlands Antilles",
		defaultMessage: "Netherlands Antilles"
	},
	ao: {
		id: "country-ao",
		description: "Angola",
		defaultMessage: "Angola"
	},
	ar: {
		id: "country-ar",
		description: "Argentina",
		defaultMessage: "Argentina"
	},
	as: {
		id: "country-as",
		description: "American Samoa",
		defaultMessage: "American Samoa"
	},
	at: {
		id: "country-at",
		description: "Austria",
		defaultMessage: "Austria"
	},
	au: {
		id: "country-au",
		description: "Australia",
		defaultMessage: "Australia"
	},
	aw: {
		id: "country-aw",
		description: "Aruba",
		defaultMessage: "Aruba"
	},
	az: {
		id: "country-az",
		description: "Azerbaijan",
		defaultMessage: "Azerbaijan"
	},
	ba: {
		id: "country-ba",
		description: "Bosnia & Herzegovina",
		defaultMessage: "Bosnia & Herzegovina"
	},
	bb: {
		id: "country-bb",
		description: "Barbados",
		defaultMessage: "Barbados"
	},
	bd: {
		id: "country-bd",
		description: "Bangladesh",
		defaultMessage: "Bangladesh"
	},
	be: {
		id: "country-be",
		description: "Belgium",
		defaultMessage: "Belgium"
	},
	bf: {
		id: "country-bf",
		description: "Burkina Faso",
		defaultMessage: "Burkina Faso"
	},
	bg: {
		id: "country-bg",
		description: "Bulgaria",
		defaultMessage: "Bulgaria"
	},
	bh: {
		id: "country-bh",
		description: "Bahrain",
		defaultMessage: "Bahrain"
	},
	bi: {
		id: "country-bi",
		description: "Burundi",
		defaultMessage: "Burundi"
	},
	bj: {
		id: "country-bj",
		description: "Benin",
		defaultMessage: "Benin"
	},
	bm: {
		id: "country-bm",
		description: "Bermuda",
		defaultMessage: "Bermuda"
	},
	bn: {
		id: "country-bn",
		description: "Brunei Darussalam",
		defaultMessage: "Brunei Darussalam"
	},
	bo: {
		id: "country-bo",
		description: "Bolivia",
		defaultMessage: "Bolivia"
	},
	br: {
		id: "country-br",
		description: "Brazil",
		defaultMessage: "Brazil"
	},
	bs: {
		id: "country-bs",
		description: "Bahamas",
		defaultMessage: "Bahamas"
	},
	bt: {
		id: "country-bt",
		description: "Bhutan",
		defaultMessage: "Bhutan"
	},
	bw: {
		id: "country-bw",
		description: "Botswana",
		defaultMessage: "Botswana"
	},
	by: {
		id: "country-by",
		description: "Belarus",
		defaultMessage: "Belarus"
	},
	bz: {
		id: "country-bz",
		description: "Belize",
		defaultMessage: "Belize"
	},
	ca: {
		id: "country-ca",
		description: "Canada",
		defaultMessage: "Canada"
	},
	cd: {
		id: "country-cd",
		description: "Congo (Democratic Rep.)",
		defaultMessage: "Congo (Democratic Rep.)"
	},
	cf: {
		id: "country-cf",
		description: "Central African Republic",
		defaultMessage: "Central African Republic"
	},
	cg: {
		id: "country-cg",
		description: "Congo",
		defaultMessage: "Congo"
	},
	ch: {
		id: "country-ch",
		description: "Switzerland",
		defaultMessage: "Switzerland"
	},
	ci: {
		id: "country-ci",
		description: "Coted'Ivoire",
		defaultMessage: "Coted'Ivoire"
	},
	ck: {
		id: "country-ck",
		description: "Cook Islands",
		defaultMessage: "Cook Islands"
	},
	cl: {
		id: "country-cl",
		description: "Chili",
		defaultMessage: "Chili"
	},
	cm: {
		id: "country-cm",
		description: "Cameroon",
		defaultMessage: "Cameroon"
	},
	cn: {
		id: "country-cn",
		description: "China",
		defaultMessage: "China"
	},
	co: {
		id: "country-co",
		description: "Colombia",
		defaultMessage: "Colombia"
	},
	cr: {
		id: "country-cr",
		description: "Costa Rica",
		defaultMessage: "Costa Rica"
	},
	cu: {
		id: "country-cu",
		description: "Cuba",
		defaultMessage: "Cuba"
	},
	cv: {
		id: "country-cv",
		description: "Cape Verde",
		defaultMessage: "Cape Verde"
	},
	cy: {
		id: "country-cy",
		description: "Cyprus",
		defaultMessage: "Cyprus"
	},
	cz: {
		id: "country-cz",
		description: "Czech Republic",
		defaultMessage: "Czech Republic"
	},
	de: {
		id: "country-de",
		description: "Germany",
		defaultMessage: "Germany"
	},
	dj: {
		id: "country-dj",
		description: "Djibouti",
		defaultMessage: "Djibouti"
	},
	dk: {
		id: "country-dk",
		description: "Denmark",
		defaultMessage: "Denmark"
	},
	dm: {
		id: "country-dm",
		description: "Dominica",
		defaultMessage: "Dominica"
	},
	do: {
		id: "country-do",
		description: "Dominican Republic",
		defaultMessage: "Dominican Republic"
	},
	dz: {
		id: "country-dz",
		description: "Algeria",
		defaultMessage: "Algeria"
	},
	ec: {
		id: "country-ec",
		description: "Ecuador",
		defaultMessage: "Ecuador"
	},
	ee: {
		id: "country-ee",
		description: "Estonia",
		defaultMessage: "Estonia"
	},
	eg: {
		id: "country-eg",
		description: "Egypt",
		defaultMessage: "Egypt"
	},
	eh: {
		id: "country-eh",
		description: "Western Sahara",
		defaultMessage: "Western Sahara"
	},
	er: {
		id: "country-er",
		description: "Eritrea",
		defaultMessage: "Eritrea"
	},
	es: {
		id: "country-es",
		description: "Spain",
		defaultMessage: "Spain"
	},
	et: {
		id: "country-et",
		description: "Ethiopia",
		defaultMessage: "Ethiopia"
	},
	fi: {
		id: "country-fi",
		description: "Finland",
		defaultMessage: "Finland"
	},
	fj: {
		id: "country-fj",
		description: "Fiji",
		defaultMessage: "Fiji"
	},
	fk: {
		id: "country-fk",
		description: "Falkland Islds (Malvinas)",
		defaultMessage: "Falkland Islds (Malvinas)"
	},
	fm: {
		id: "country-fm",
		description: "Micronesia",
		defaultMessage: "Micronesia"
	},
	fo: {
		id: "country-fo",
		description: "Faroe Islands",
		defaultMessage: "Faroe Islands"
	},
	fr: {
		id: "country-fr",
		description: "France",
		defaultMessage: "France"
	},
	ga: {
		id: "country-ga",
		description: "Gabon",
		defaultMessage: "Gabon"
	},
	gb: {
		id: "country-gb",
		description: "United Kingdom",
		defaultMessage: "United Kingdom"
	},
	gd: {
		id: "country-gd",
		description: "Grenada",
		defaultMessage: "Grenada"
	},
	ge: {
		id: "country-ge",
		description: "Goergia",
		defaultMessage: "Goergia"
	},
	gf: {
		id: "country-gf",
		description: "French Guiana",
		defaultMessage: "French Guiana"
	},
	gh: {
		id: "country-gh",
		description: "Ghana",
		defaultMessage: "Ghana"
	},
	gi: {
		id: "country-gi",
		description: "Gibraltar",
		defaultMessage: "Gibraltar"
	},
	gm: {
		id: "country-gm",
		description: "Gambia",
		defaultMessage: "Gambia"
	},
	gn: {
		id: "country-gn",
		description: "Guinea",
		defaultMessage: "Guinea"
	},
	gp: {
		id: "country-gp",
		description: "Guadeloupe",
		defaultMessage: "Guadeloupe"
	},
	gq: {
		id: "country-gq",
		description: "Equatorial Guinea",
		defaultMessage: "Equatorial Guinea"
	},
	gr: {
		id: "country-gr",
		description: "Greece",
		defaultMessage: "Greece"
	},
	gt: {
		id: "country-gt",
		description: "Guatemala",
		defaultMessage: "Guatemala"
	},
	gw: {
		id: "country-gw",
		description: "Guinea-Bissau",
		defaultMessage: "Guinea-Bissau"
	},
	gy: {
		id: "country-gy",
		description: "Guyana",
		defaultMessage: "Guyana"
	},
	hn: {
		id: "country-hn",
		description: "Honduras",
		defaultMessage: "Honduras"
	},
	hr: {
		id: "country-hr",
		description: "Croatia",
		defaultMessage: "Croatia"
	},
	ht: {
		id: "country-ht",
		description: "Haiti",
		defaultMessage: "Haiti"
	},
	hu: {
		id: "country-hu",
		description: "Hungary",
		defaultMessage: "Hungary"
	},
	id: {
		id: "country-id",
		description: "Indonesia",
		defaultMessage: "Indonesia"
	},
	ie: {
		id: "country-ie",
		description: "Ireland",
		defaultMessage: "Ireland"
	},
	il: {
		id: "country-il",
		description: "Israel",
		defaultMessage: "Israel"
	},
	in: {
		id: "country-in",
		description: "India",
		defaultMessage: "India"
	},
	iq: {
		id: "country-iq",
		description: "Iraq",
		defaultMessage: "Iraq"
	},
	ir: {
		id: "country-ir",
		description: "Iran",
		defaultMessage: "Iran"
	},
	is: {
		id: "country-is",
		description: "Iceland",
		defaultMessage: "Iceland"
	},
	it: {
		id: "country-it",
		description: "Italy",
		defaultMessage: "Italy"
	},
	jm: {
		id: "country-jm",
		description: "Jamaica",
		defaultMessage: "Jamaica"
	},
	jo: {
		id: "country-jo",
		description: "Jordan",
		defaultMessage: "Jordan"
	},
	jp: {
		id: "country-jp",
		description: "Japan",
		defaultMessage: "Japan"
	},
	ke: {
		id: "country-ke",
		description: "Kenia",
		defaultMessage: "Kenia"
	},
	kg: {
		id: "country-kg",
		description: "Kyrgyzstan",
		defaultMessage: "Kyrgyzstan"
	},
	kh: {
		id: "country-kh",
		description: "Cambodia",
		defaultMessage: "Cambodia"
	},
	ki: {
		id: "country-ki",
		description: "Kiribati",
		defaultMessage: "Kiribati"
	},
	km: {
		id: "country-km",
		description: "Comoros",
		defaultMessage: "Comoros"
	},
	kn: {
		id: "country-kn",
		description: "Saint Kitts & Nevis",
		defaultMessage: "Saint Kitts & Nevis"
	},
	kp: {
		id: "country-kp",
		description: "Korea (Dem. Peo. Rep. of)",
		defaultMessage: "Korea (Dem. Peo. Rep. of)"
	},
	kr: {
		id: "country-kr",
		description: "Korea Rep.of",
		defaultMessage: "Korea Rep.of"
	},
	kw: {
		id: "country-kw",
		description: "Kuwait",
		defaultMessage: "Kuwait"
	},
	ky: {
		id: "country-ky",
		description: "Cayman Islands",
		defaultMessage: "Cayman Islands"
	},
	kz: {
		id: "country-kz",
		description: "Kazakhstan",
		defaultMessage: "Kazakhstan"
	},
	la: {
		id: "country-la",
		description: "Lao (Peo. Dem. Rep. of )",
		defaultMessage: "Lao (Peo. Dem. Rep. of )"
	},
	lb: {
		id: "country-lb",
		description: "Lebann",
		defaultMessage: "Lebann"
	},
	lc: {
		id: "country-lc",
		description: "Saint Lucia",
		defaultMessage: "Saint Lucia"
	},
	li: {
		id: "country-li",
		description: "Liechtenstein",
		defaultMessage: "Liechtenstein"
	},
	lk: {
		id: "country-lk",
		description: "Sri-Lanka",
		defaultMessage: "Sri-Lanka"
	},
	lr: {
		id: "country-lr",
		description: "Liberia",
		defaultMessage: "Liberia"
	},
	ls: {
		id: "country-ls",
		description: "Lesotho",
		defaultMessage: "Lesotho"
	},
	lt: {
		id: "country-lt",
		description: "Lituania",
		defaultMessage: "Lituania"
	},
	lu: {
		id: "country-lu",
		description: "Luxembourg",
		defaultMessage: "Luxembourg"
	},
	lv: {
		id: "country-lv",
		description: "Latvia",
		defaultMessage: "Latvia"
	},
	ly: {
		id: "country-ly",
		description: "Libya",
		defaultMessage: "Libya"
	},
	ma: {
		id: "country-ma",
		description: "Morocco",
		defaultMessage: "Morocco"
	},
	mc: {
		id: "country-mc",
		description: "Monaco",
		defaultMessage: "Monaco"
	},
	md: {
		id: "country-md",
		description: "Moldova (Rep. of )",
		defaultMessage: "Moldova (Rep. of )"
	},
	me: {
		id: "country-me",
		description: "Montenegro",
		defaultMessage: "Montenegro"
	},
	mg: {
		id: "country-mg",
		description: "Madagascar",
		defaultMessage: "Madagascar"
	},
	mh: {
		id: "country-mh",
		description: "Marshall Islands",
		defaultMessage: "Marshall Islands"
	},
	mk: {
		id: "country-mk",
		description: "North Macedonia",
		defaultMessage: "North Macedonia"
	},
	ml: {
		id: "country-ml",
		description: "Mali",
		defaultMessage: "Mali"
	},
	mm: {
		id: "country-mm",
		description: "Myanmar",
		defaultMessage: "Myanmar"
	},
	mn: {
		id: "country-mn",
		description: "Mongolia",
		defaultMessage: "Mongolia"
	},
	mp: {
		id: "country-mp",
		description: "Northern Mariana Islands",
		defaultMessage: "Northern Mariana Islands"
	},
	mq: {
		id: "country-mq",
		description: "Martinique",
		defaultMessage: "Martinique"
	},
	mr: {
		id: "country-mr",
		description: "Mauritania",
		defaultMessage: "Mauritania"
	},
	mt: {
		id: "country-mt",
		description: "Malta",
		defaultMessage: "Malta"
	},
	mu: {
		id: "country-mu",
		description: "Mauritius",
		defaultMessage: "Mauritius"
	},
	mv: {
		id: "country-mv",
		description: "Maldives",
		defaultMessage: "Maldives"
	},
	mw: {
		id: "country-mw",
		description: "Malawi",
		defaultMessage: "Malawi"
	},
	mx: {
		id: "country-mx",
		description: "Mexico",
		defaultMessage: "Mexico"
	},
	my: {
		id: "country-my",
		description: "Malaysia",
		defaultMessage: "Malaysia"
	},
	mz: {
		id: "country-mz",
		description: "Mozambique",
		defaultMessage: "Mozambique"
	},
	na: {
		id: "country-na",
		description: "Namibia",
		defaultMessage: "Namibia"
	},
	nc: {
		id: "country-nc",
		description: "New Caledonia",
		defaultMessage: "New Caledonia"
	},
	ne: {
		id: "country-ne",
		description: "Niger",
		defaultMessage: "Niger"
	},
	nf: {
		id: "country-nf",
		description: "Norfolk Island",
		defaultMessage: "Norfolk Island"
	},
	ng: {
		id: "country-ng",
		description: "Nigeria",
		defaultMessage: "Nigeria"
	},
	ni: {
		id: "country-ni",
		description: "Nicaragua",
		defaultMessage: "Nicaragua"
	},
	nl: {
		id: "country-nl",
		description: "Netherlands",
		defaultMessage: "Netherlands"
	},
	no: {
		id: "country-no",
		description: "Norway",
		defaultMessage: "Norway"
	},
	np: {
		id: "country-np",
		description: "Nepal",
		defaultMessage: "Nepal"
	},
	nr: {
		id: "country-nr",
		description: "Nauru",
		defaultMessage: "Nauru"
	},
	nu: {
		id: "country-nu",
		description: "Niue",
		defaultMessage: "Niue"
	},
	nz: {
		id: "country-nz",
		description: "New Zealand",
		defaultMessage: "New Zealand"
	},
	om: {
		id: "country-om",
		description: "Oman",
		defaultMessage: "Oman"
	},
	pa: {
		id: "country-pa",
		description: "Panama",
		defaultMessage: "Panama"
	},
	pe: {
		id: "country-pe",
		description: "Peru",
		defaultMessage: "Peru"
	},
	pf: {
		id: "country-pf",
		description: "French Polynesia",
		defaultMessage: "French Polynesia"
	},
	pg: {
		id: "country-pg",
		description: "Papua New Guinea",
		defaultMessage: "Papua New Guinea"
	},
	ph: {
		id: "country-ph",
		description: "Philippines",
		defaultMessage: "Philippines"
	},
	pk: {
		id: "country-pk",
		description: "Pakistan",
		defaultMessage: "Pakistan"
	},
	pl: {
		id: "country-pl",
		description: "Poland",
		defaultMessage: "Poland"
	},
	pr: {
		id: "country-pr",
		description: "Puerto Rico",
		defaultMessage: "Puerto Rico"
	},
	ps: {
		id: "country-ps",
		description: "Palestine",
		defaultMessage: "Palestine"
	},
	pt: {
		id: "country-pt",
		description: "Portugal",
		defaultMessage: "Portugal"
	},
	pw: {
		id: "country-pw",
		description: "Palau",
		defaultMessage: "Palau"
	},
	py: {
		id: "country-py",
		description: "Paraguay",
		defaultMessage: "Paraguay"
	},
	qa: {
		id: "country-qa",
		description: "Qatar",
		defaultMessage: "Qatar"
	},
	re: {
		id: "country-re",
		description: "Reunion",
		defaultMessage: "Reunion"
	},
	ro: {
		id: "country-ro",
		description: "Romania",
		defaultMessage: "Romania"
	},
	rs: {
		id: "country-rs",
		description: "Serbia",
		defaultMessage: "Serbia"
	},
	ru: {
		id: "country-ru",
		description: "Russia",
		defaultMessage: "Russia"
	},
	rw: {
		id: "country-rw",
		description: "Rwanda",
		defaultMessage: "Rwanda"
	},
	sa: {
		id: "country-sa",
		description: "Saudi Arabia",
		defaultMessage: "Saudi Arabia"
	},
	sb: {
		id: "country-sb",
		description: "Solomon Islands",
		defaultMessage: "Solomon Islands"
	},
	sc: {
		id: "country-sc",
		description: "Seychelles",
		defaultMessage: "Seychelles"
	},
	sd: {
		id: "country-sd",
		description: "Sudan",
		defaultMessage: "Sudan"
	},
	se: {
		id: "country-se",
		description: "Sweden",
		defaultMessage: "Sweden"
	},
	sg: {
		id: "country-sg",
		description: "Singapore",
		defaultMessage: "Singapore"
	},
	si: {
		id: "country-si",
		description: "Slovenia",
		defaultMessage: "Slovenia"
	},
	sk: {
		id: "country-sk",
		description: "Slovakia",
		defaultMessage: "Slovakia"
	},
	sl: {
		id: "country-sl",
		description: "Sierra Leone",
		defaultMessage: "Sierra Leone"
	},
	sm: {
		id: "country-sm",
		description: "San Marino",
		defaultMessage: "San Marino"
	},
	sn: {
		id: "country-sn",
		description: "Senegal",
		defaultMessage: "Senegal"
	},
	so: {
		id: "country-so",
		description: "Somalia",
		defaultMessage: "Somalia"
	},
	sr: {
		id: "country-sr",
		description: "Suriname",
		defaultMessage: "Suriname"
	},
	ss: {
		id: "country-ss",
		description: "South Sudan",
		defaultMessage: "South Sudan"
	},
	st: {
		id: "country-st",
		description: "San Tome & Principe",
		defaultMessage: "San Tome & Principe"
	},
	sv: {
		id: "country-sv",
		description: "El Salvador",
		defaultMessage: "El Salvador"
	},
	sy: {
		id: "country-sy",
		description: "Syrian (Arab Rep.)",
		defaultMessage: "Syrian (Arab Rep.)"
	},
	sz: {
		id: "country-sz",
		description: "Eswatini/Swaziland",
		defaultMessage: "Eswatini/Swaziland"
	},
	td: {
		id: "country-td",
		description: "Chad",
		defaultMessage: "Chad"
	},
	tf: {
		id: "country-tf",
		description: "French Southern Territories",
		defaultMessage: "French Southern Territories"
	},
	tg: {
		id: "country-tg",
		description: "Togo",
		defaultMessage: "Togo"
	},
	th: {
		id: "country-th",
		description: "Thailand",
		defaultMessage: "Thailand"
	},
	tj: {
		id: "country-tj",
		description: "Tajikistan",
		defaultMessage: "Tajikistan"
	},
	tk: {
		id: "country-tk",
		description: "Tokelau",
		defaultMessage: "Tokelau"
	},
	tm: {
		id: "country-tm",
		description: "Turkmenistan",
		defaultMessage: "Turkmenistan"
	},
	tn: {
		id: "country-tn",
		description: "Tunisia",
		defaultMessage: "Tunisia"
	},
	to: {
		id: "country-to",
		description: "Tonga",
		defaultMessage: "Tonga"
	},
	tp: {
		id: "country-tp",
		description: "EastTimor",
		defaultMessage: "EastTimor"
	},
	tr: {
		id: "country-tr",
		description: "Turkey",
		defaultMessage: "Turkey"
	},
	tt: {
		id: "country-tt",
		description: "Trinidad & Tobago",
		defaultMessage: "Trinidad & Tobago"
	},
	tv: {
		id: "country-tv",
		description: "Tuvalu",
		defaultMessage: "Tuvalu"
	},
	tz: {
		id: "country-tz",
		description: "Tanzania (United Rep.)",
		defaultMessage: "Tanzania (United Rep.)"
	},
	ua: {
		id: "country-ua",
		description: "Ukraine",
		defaultMessage: "Ukraine"
	},
	ug: {
		id: "country-ug",
		description: "Uganda",
		defaultMessage: "Uganda"
	},
	us: {
		id: "country-us",
		description: "Unated States of America",
		defaultMessage: "Unated States of America"
	},
	uy: {
		id: "country-uy",
		description: "Uruguay",
		defaultMessage: "Uruguay"
	},
	uz: {
		id: "country-uz",
		description: "Uzbekistan",
		defaultMessage: "Uzbekistan"
	},
	va: {
		id: "country-va",
		description: "Vatican City",
		defaultMessage: "Vatican City"
	},
	vc: {
		id: "country-vc",
		description: "St. Vincent & Grenadines",
		defaultMessage: "St. Vincent & Grenadines"
	},
	ve: {
		id: "country-ve",
		description: "Venezuela",
		defaultMessage: "Venezuela"
	},
	vg: {
		id: "country-vg",
		description: "British Virgin Islands",
		defaultMessage: "British Virgin Islands"
	},
	vi: {
		id: "country-vi",
		description: "US Virgin Islands",
		defaultMessage: "US Virgin Islands"
	},
	vn: {
		id: "country-vn",
		description: "Vietnam",
		defaultMessage: "Vietnam"
	},
	vu: {
		id: "country-vu",
		description: "Vanuatu",
		defaultMessage: "Vanuatu"
	},
	ws: {
		id: "country-ws",
		description: "Samoa",
		defaultMessage: "Samoa"
	},
	ye: {
		id: "country-ye",
		description: "Yemen",
		defaultMessage: "Yemen"
	},
	za: {
		id: "country-za",
		description: "South Africa",
		defaultMessage: "South Africa"
	},
	zm: {
		id: "country-zm",
		description: "Zambia",
		defaultMessage: "Zambia"
	},
	zw: {
		id: "country-zw",
		description: "Zimbabwe",
		defaultMessage: "Zimbabwe"
	},
	zz: {
		id: "country-zz",
		description: "Unknown",
		defaultMessage: "Unknown"
	},
	сс: {
		id: "country-сс",
		description: "Cocos Islands",
		defaultMessage: "Cocos Islands"
	},
});

export default CountriesMessages;
export { CountriesMessages };
