import React from "react";
import { FormattedMessage } from "react-intl";
import { CommonMessages } from "../../Common.messages";

interface NavigationHeaderProps {
    back: () => void;
    next?: () => void;
    formId?: string;
    disabledNext?: boolean;
    disabledBack?: boolean;
    FlowComp: React.FC;
    hide?: boolean;
}

const NavigationHeader = React.memo((props: NavigationHeaderProps) => {
	const { formId, next, back, FlowComp, disabledNext, disabledBack, hide } = props;

	return (<>
		<header className="ipos-flow-header">
			<nav className="ipos-flow-header-buttons">
				{!hide && back && (<button type="button" disabled={disabledBack} className="btn btn-outline-primary" onClick={back}>
					<FormattedMessage {...CommonMessages.back} />
				</button>)}
				{formId && <button disabled={disabledNext} form={formId} type="submit" className="btn btn-primary">
					<FormattedMessage {...CommonMessages.next} />
				</button>}
				{!formId && !hide && next && (<button disabled={disabledNext} type="button" className="btn btn-primary" onClick={next}>
					<FormattedMessage {...CommonMessages.next} />
				</button>)}
			</nav>
			<FlowComp/>
		</header>
	</>
	);
});

export { NavigationHeader };