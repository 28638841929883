import { showActionModal } from "./showActionModal";
import { resolveActionModal } from "./resolveActionModal";
import { Storage } from "../../shared/utils/storageUtils";
import { updateSimCardIcc } from "./updateSimCardIcc";

const resolvers = {
	Mutation: {
		setApplicationContext:  (_, variables, { cache }) => {
			const { msisdn, subscriptionId, locale } = variables;
			const data = { context: { msisdn, subscriptionId, locale, __typename: "ApplicationContext" }};
			Storage.local.set("applicationContext", data.context);
			cache.writeData({ data });
			return null;
		},
		showModal: (_, variables, { cache }) => {
			const { message, status, title } = variables;
			const data = { modal: { status, message, title, __typename: "ModalStatus" }};
			cache.writeData({ data });
			return null;
		},
		handleOtpOpen: (_, variables, { cache }) => {
			const { msisdn } = variables;
			const data = { modals: {
				otp: {
					msisdn,
					status: true,
					code: "",
					__typename: "OtpModal"	
				},
				__typename: "Modals"
			}};
			cache.writeData({ data });

		},
		handleOtpClose: (_, variables, { cache }) => {
			const data = { modals: {
				otp: {
					msisdn: "",
					loading: false,
					status: false,
					error: "",
					code: "",
					__typename: "OtpModal"
				},
				__typename: "Modals"
			}};
			cache.writeData({ data });
		},
		handleOtpActionLoading: (_, variables, { cache }) => {
			const { loading } = variables;
			const data = { modals: {
				otp: {
					loading,
					code: "",
					__typename: "OtpModal"
				},
				__typename: "Modals"
			}};
			cache.writeData({ data });
		},
		handleOtpError: (_, variables, { cache }) => {
			const { error } = variables;
			const data = { modals: {
				otp: {
					error,
					loading: false,
					code: "",
					__typename: "OtpModal"
				},
				__typename: "Modals"
			}};
			cache.writeData({ data });
		},
		handleOtpSet: (_, variables, { cache }) => {
			const { code } = variables;
			const data = { modals: {
				otp: {
					code,
					loading: true,
					error: "",
					__typename: "OtpModal"
				},
				__typename: "Modals"
			}};
			cache.writeData({ data });
		},
		showSpinner: (_, variables, { cache }) => {
			const { status } = variables;
			const data = { spinner: { status, __typename: "SpinnerStatus" }};
			cache.writeData({ data });
			return null;
		},
		updateAuth: (_, variables, { cache }) => {
			const { isAuthenticated } = variables;
			const data = { auth: { isAuthenticated, __typename: "Auth" }};
			cache.writeData({ data });
			return null;
		},
		setDealerIdentity: (_, variables, { cache }) => {
			const { dealerIdentity } = variables;
			cache.writeData({
				data: { dealerIdentity }
			});
			return null;
		},
		showActionModal,
		resolveActionModal,
		updateSimCardIcc,
	}
};

export {
	resolvers
};
