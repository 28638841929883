import { IntlShape } from "react-intl";
import { UserMessages } from "../../modules/user/User.messages";
import { ChangeMsisdnMessages } from "../../modules/change-bar/msisdn/ChangeMsisdn.messages";
import { changeSimMessages } from "../../modules/change-bar/sim/ChangeSim.message";
import { CountriesMessages } from "./Countries.messages";
import { SearchCustomerEnum } from "../types";
import { DocumentTypeEnum } from "../types/DocumentTypeEnum";
import { MnpMessages } from "../../modules/mnp/Mnp.messages";
import RegistrationMessages from "../../modules/registration/Registration.messages";
import {B2BFTTBFlowMessages} from "../../modules/fttb/b2bFttb/B2BFttb.messgaes";
import {SearchCompanyEnumEnum} from "../types/B2BFttbEnum";

const DONOR_OPERATORS = (intl) => [
	{label: intl.formatMessage({...MnpMessages.Vodafone}), value: "Vodafone"},
	{label: intl.formatMessage({...MnpMessages.Lifecell}), value: "Lifecell"},
	{label: intl.formatMessage({...MnpMessages.ThreeMob}), value: "3Mob"},
	{label: intl.formatMessage({...MnpMessages.Intertelecom}), value: "Intertelecom"},
];

const DONOR_SEGMENT = (intl) => [
	{label: intl.formatMessage({...MnpMessages.b2cPassportized}), value: "prp_b2c_passportized"},
	{label: intl.formatMessage({...MnpMessages.b2cNonPassportized}), value: "prp_non_passportized"},
	{label: intl.formatMessage({...MnpMessages.mmc}), value: "pop_mmc_first_last"},
	{label: intl.formatMessage({...MnpMessages.popEntrepreneur}), value: "pop_entrepreneur"},
	{label: intl.formatMessage({...MnpMessages.popB2b}), value: "pop_b2b"},
];

const countries = (intl) => [
	{label: intl.formatMessage({...CountriesMessages.au}), value: "AU"},
	{label: intl.formatMessage({...CountriesMessages.at}), value: "AT"},
	{label: intl.formatMessage({...CountriesMessages.az}), value: "AZ"},
	{label: intl.formatMessage({...CountriesMessages.al}), value: "AL"},
	{label: intl.formatMessage({...CountriesMessages.dz}), value: "DZ"},
	{label: intl.formatMessage({...CountriesMessages.vi}), value: "VI"},
	{label: intl.formatMessage({...CountriesMessages.as}), value: "AS"},
	{label: intl.formatMessage({...CountriesMessages.ao}), value: "AO"},
	{label: intl.formatMessage({...CountriesMessages.ad}), value: "AD"},
	{label: intl.formatMessage({...CountriesMessages.ag}), value: "AG"},
	{label: intl.formatMessage({...CountriesMessages.ar}), value: "AR"},
	{label: intl.formatMessage({...CountriesMessages.am}), value: "AM"},
	{label: intl.formatMessage({...CountriesMessages.aw}), value: "AW"},
	{label: intl.formatMessage({...CountriesMessages.af}), value: "AF"},
	{label: intl.formatMessage({...CountriesMessages.bs}), value: "BS"},
	{label: intl.formatMessage({...CountriesMessages.bd}), value: "BD"},
	{label: intl.formatMessage({...CountriesMessages.bb}), value: "BB"},
	{label: intl.formatMessage({...CountriesMessages.bh}), value: "BH"},
	{label: intl.formatMessage({...CountriesMessages.bz}), value: "BZ"},
	{label: intl.formatMessage({...CountriesMessages.by}), value: "BY"},
	{label: intl.formatMessage({...CountriesMessages.be}), value: "BE"},
	{label: intl.formatMessage({...CountriesMessages.bj}), value: "BJ"},
	{label: intl.formatMessage({...CountriesMessages.bm}), value: "BM"},
	{label: intl.formatMessage({...CountriesMessages.bg}), value: "BG"},
	{label: intl.formatMessage({...CountriesMessages.bo}), value: "BO"},
	{label: intl.formatMessage({...CountriesMessages.ba}), value: "BA"},
	{label: intl.formatMessage({...CountriesMessages.bw}), value: "BW"},
	{label: intl.formatMessage({...CountriesMessages.br}), value: "BR"},
	{label: intl.formatMessage({...CountriesMessages.vg}), value: "VG"},
	{label: intl.formatMessage({...CountriesMessages.bn}), value: "BN"},
	{label: intl.formatMessage({...CountriesMessages.bf}), value: "BF"},
	{label: intl.formatMessage({...CountriesMessages.bi}), value: "BI"},
	{label: intl.formatMessage({...CountriesMessages.bt}), value: "BT"},
	{label: intl.formatMessage({...CountriesMessages.vu}), value: "VU"},
	{label: intl.formatMessage({...CountriesMessages.va}), value: "VA"},
	{label: intl.formatMessage({...CountriesMessages.gb}), value: "GB"},
	{label: intl.formatMessage({...CountriesMessages.hu}), value: "HU"},
	{label: intl.formatMessage({...CountriesMessages.ve}), value: "VE"},
	{label: intl.formatMessage({...CountriesMessages.tp}), value: "TP"},
	{label: intl.formatMessage({...CountriesMessages.vn}), value: "VN"},
	{label: intl.formatMessage({...CountriesMessages.ga}), value: "GA"},
	{label: intl.formatMessage({...CountriesMessages.ht}), value: "HT"},
	{label: intl.formatMessage({...CountriesMessages.gy}), value: "GY"},
	{label: intl.formatMessage({...CountriesMessages.gm}), value: "GM"},
	{label: intl.formatMessage({...CountriesMessages.gh}), value: "GH"},
	{label: intl.formatMessage({...CountriesMessages.gp}), value: "GP"},
	{label: intl.formatMessage({...CountriesMessages.gt}), value: "GT"},
	{label: intl.formatMessage({...CountriesMessages.gn}), value: "GN"},
	{label: intl.formatMessage({...CountriesMessages.gw}), value: "GW"},
	{label: intl.formatMessage({...CountriesMessages.de}), value: "DE"},
	{label: intl.formatMessage({...CountriesMessages.gi}), value: "GI"},
	{label: intl.formatMessage({...CountriesMessages.hn}), value: "HN"},
	{label: intl.formatMessage({...CountriesMessages.ps}), value: "PS"},
	{label: intl.formatMessage({...CountriesMessages.gd}), value: "GD"},
	{label: intl.formatMessage({...CountriesMessages.gr}), value: "GR"},
	{label: intl.formatMessage({...CountriesMessages.ge}), value: "GE"},
	{label: intl.formatMessage({...CountriesMessages.dk}), value: "DK"},
	{label: intl.formatMessage({...CountriesMessages.dj}), value: "DJ"},
	{label: intl.formatMessage({...CountriesMessages.dm}), value: "DM"},
	{label: intl.formatMessage({...CountriesMessages.do}), value: "DO"},
	{label: intl.formatMessage({...CountriesMessages.eg}), value: "EG"},
	{label: intl.formatMessage({...CountriesMessages.zm}), value: "ZM"},
	{label: intl.formatMessage({...CountriesMessages.eh}), value: "EH"},
	{label: intl.formatMessage({...CountriesMessages.zw}), value: "ZW"},
	{label: intl.formatMessage({...CountriesMessages.il}), value: "IL"},
	{label: intl.formatMessage({...CountriesMessages.in}), value: "IN"},
	{label: intl.formatMessage({...CountriesMessages.id}), value: "ID"},
	{label: intl.formatMessage({...CountriesMessages.jo}), value: "JO"},
	{label: intl.formatMessage({...CountriesMessages.iq}), value: "IQ"},
	{label: intl.formatMessage({...CountriesMessages.ir}), value: "IR"},
	{label: intl.formatMessage({...CountriesMessages.ie}), value: "IE"},
	{label: intl.formatMessage({...CountriesMessages.is}), value: "IS"},
	{label: intl.formatMessage({...CountriesMessages.es}), value: "ES"},
	{label: intl.formatMessage({...CountriesMessages.it}), value: "IT"},
	{label: intl.formatMessage({...CountriesMessages.ye}), value: "YE"},
	{label: intl.formatMessage({...CountriesMessages.cv}), value: "CV"},
	{label: intl.formatMessage({...CountriesMessages.kz}), value: "KZ"},
	{label: intl.formatMessage({...CountriesMessages.ky}), value: "KY"},
	{label: intl.formatMessage({...CountriesMessages.kh}), value: "KH"},
	{label: intl.formatMessage({...CountriesMessages.cm}), value: "CM"},
	{label: intl.formatMessage({...CountriesMessages.ca}), value: "CA"},
	{label: intl.formatMessage({...CountriesMessages.qa}), value: "QA"},
	{label: intl.formatMessage({...CountriesMessages.ke}), value: "KE"},
	{label: intl.formatMessage({...CountriesMessages.cy}), value: "CY"},
	{label: intl.formatMessage({...CountriesMessages.kg}), value: "KG"},
	{label: intl.formatMessage({...CountriesMessages.ki}), value: "KI"},
	{label: intl.formatMessage({...CountriesMessages.cn}), value: "CN"},
	{label: intl.formatMessage({...CountriesMessages.kp}), value: "KP"},
	{label: intl.formatMessage({...CountriesMessages.сс}), value: "СС"},
	{label: intl.formatMessage({...CountriesMessages.co}), value: "CO"},
	{label: intl.formatMessage({...CountriesMessages.km}), value: "KM"},
	{label: intl.formatMessage({...CountriesMessages.cg}), value: "CG"},
	{label: intl.formatMessage({...CountriesMessages.cr}), value: "CR"},
	{label: intl.formatMessage({...CountriesMessages.ci}), value: "CI"},
	{label: intl.formatMessage({...CountriesMessages.cu}), value: "CU"},
	{label: intl.formatMessage({...CountriesMessages.kw}), value: "KW"},
	{label: intl.formatMessage({...CountriesMessages.ck}), value: "CK"},
	{label: intl.formatMessage({...CountriesMessages.la}), value: "LA"},
	{label: intl.formatMessage({...CountriesMessages.lv}), value: "LV"},
	{label: intl.formatMessage({...CountriesMessages.ls}), value: "LS"},
	{label: intl.formatMessage({...CountriesMessages.lr}), value: "LR"},
	{label: intl.formatMessage({...CountriesMessages.lb}), value: "LB"},
	{label: intl.formatMessage({...CountriesMessages.ly}), value: "LY"},
	{label: intl.formatMessage({...CountriesMessages.lt}), value: "LT"},
	{label: intl.formatMessage({...CountriesMessages.li}), value: "LI"},
	{label: intl.formatMessage({...CountriesMessages.lu}), value: "LU"},
	{label: intl.formatMessage({...CountriesMessages.mu}), value: "MU"},
	{label: intl.formatMessage({...CountriesMessages.mr}), value: "MR"},
	{label: intl.formatMessage({...CountriesMessages.mg}), value: "MG"},
	{label: intl.formatMessage({...CountriesMessages.mw}), value: "MW"},
	{label: intl.formatMessage({...CountriesMessages.my}), value: "MY"},
	{label: intl.formatMessage({...CountriesMessages.ml}), value: "ML"},
	{label: intl.formatMessage({...CountriesMessages.mv}), value: "MV"},
	{label: intl.formatMessage({...CountriesMessages.mt}), value: "MT"},
	{label: intl.formatMessage({...CountriesMessages.ma}), value: "MA"},
	{label: intl.formatMessage({...CountriesMessages.mq}), value: "MQ"},
	{label: intl.formatMessage({...CountriesMessages.mh}), value: "MH"},
	{label: intl.formatMessage({...CountriesMessages.mx}), value: "MX"},
	{label: intl.formatMessage({...CountriesMessages.mz}), value: "MZ"},
	{label: intl.formatMessage({...CountriesMessages.md}), value: "MD"},
	{label: intl.formatMessage({...CountriesMessages.mc}), value: "MC"},
	{label: intl.formatMessage({...CountriesMessages.mn}), value: "MN"},
	{label: intl.formatMessage({...CountriesMessages.mm}), value: "MM"},
	{label: intl.formatMessage({...CountriesMessages.na}), value: "NA"},
	{label: intl.formatMessage({...CountriesMessages.nr}), value: "NR"},
	{label: intl.formatMessage({...CountriesMessages.np}), value: "NP"},
	{label: intl.formatMessage({...CountriesMessages.ne}), value: "NE"},
	{label: intl.formatMessage({...CountriesMessages.ng}), value: "NG"},
	{label: intl.formatMessage({...CountriesMessages.an}), value: "AN"},
	{label: intl.formatMessage({...CountriesMessages.nl}), value: "NL"},
	{label: intl.formatMessage({...CountriesMessages.ni}), value: "NI"},
	{label: intl.formatMessage({...CountriesMessages.nu}), value: "NU"},
	{label: intl.formatMessage({...CountriesMessages.nz}), value: "NZ"},
	{label: intl.formatMessage({...CountriesMessages.nc}), value: "NC"},
	{label: intl.formatMessage({...CountriesMessages.no}), value: "NO"},
	{label: intl.formatMessage({...CountriesMessages.ae}), value: "AE"},
	{label: intl.formatMessage({...CountriesMessages.nf}), value: "NF"},
	{label: intl.formatMessage({...CountriesMessages.om}), value: "OM"},
	{label: intl.formatMessage({...CountriesMessages.pk}), value: "PK"},
	{label: intl.formatMessage({...CountriesMessages.pw}), value: "PW"},
	{label: intl.formatMessage({...CountriesMessages.pa}), value: "PA"},
	{label: intl.formatMessage({...CountriesMessages.pg}), value: "PG"},
	{label: intl.formatMessage({...CountriesMessages.py}), value: "PY"},
	{label: intl.formatMessage({...CountriesMessages.pe}), value: "PE"},
	{label: intl.formatMessage({...CountriesMessages.pl}), value: "PL"},
	{label: intl.formatMessage({...CountriesMessages.pt}), value: "PT"},
	{label: intl.formatMessage({...CountriesMessages.pr}), value: "PR"},
	{label: intl.formatMessage({...CountriesMessages.cd}), value: "CD"},
	{label: intl.formatMessage({...CountriesMessages.kr}), value: "KR"},
	{label: intl.formatMessage({...CountriesMessages.re}), value: "RE"},
	{label: intl.formatMessage({...CountriesMessages.ru}), value: "RU"},
	{label: intl.formatMessage({...CountriesMessages.rw}), value: "RW"},
	{label: intl.formatMessage({...CountriesMessages.ro}), value: "RO"},
	{label: intl.formatMessage({...CountriesMessages.sv}), value: "SV"},
	{label: intl.formatMessage({...CountriesMessages.ws}), value: "WS"},
	{label: intl.formatMessage({...CountriesMessages.sm}), value: "SM"},
	{label: intl.formatMessage({...CountriesMessages.st}), value: "ST"},
	{label: intl.formatMessage({...CountriesMessages.sa}), value: "SA"},
	{label: intl.formatMessage({...CountriesMessages.mk}), value: "MK"},
	{label: intl.formatMessage({...CountriesMessages.mp}), value: "MP"},
	{label: intl.formatMessage({...CountriesMessages.sc}), value: "SC"},
	{label: intl.formatMessage({...CountriesMessages.sn}), value: "SN"},
	{label: intl.formatMessage({...CountriesMessages.vc}), value: "VC"},
	{label: intl.formatMessage({...CountriesMessages.kn}), value: "KN"},
	{label: intl.formatMessage({...CountriesMessages.lc}), value: "LC"},
	{label: intl.formatMessage({...CountriesMessages.rs}), value: "RS"},
	{label: intl.formatMessage({...CountriesMessages.sg}), value: "SG"},
	{label: intl.formatMessage({...CountriesMessages.sy}), value: "SY"},
	{label: intl.formatMessage({...CountriesMessages.sk}), value: "SK"},
	{label: intl.formatMessage({...CountriesMessages.si}), value: "SI"},
	{label: intl.formatMessage({...CountriesMessages.sb}), value: "SB"},
	{label: intl.formatMessage({...CountriesMessages.so}), value: "SO"},
	{label: intl.formatMessage({...CountriesMessages.sd}), value: "SD"},
	{label: intl.formatMessage({...CountriesMessages.sr}), value: "SR"},
	{label: intl.formatMessage({...CountriesMessages.us}), value: "US"},
	{label: intl.formatMessage({...CountriesMessages.sl}), value: "SL"},
	{label: intl.formatMessage({...CountriesMessages.tj}), value: "TJ"},
	{label: intl.formatMessage({...CountriesMessages.th}), value: "TH"},
	{label: intl.formatMessage({...CountriesMessages.tz}), value: "TZ"},
	{label: intl.formatMessage({...CountriesMessages.tg}), value: "TG"},
	{label: intl.formatMessage({...CountriesMessages.tk}), value: "TK"},
	{label: intl.formatMessage({...CountriesMessages.to}), value: "TO"},
	{label: intl.formatMessage({...CountriesMessages.tt}), value: "TT"},
	{label: intl.formatMessage({...CountriesMessages.tv}), value: "TV"},
	{label: intl.formatMessage({...CountriesMessages.tn}), value: "TN"},
	{label: intl.formatMessage({...CountriesMessages.tm}), value: "TM"},
	{label: intl.formatMessage({...CountriesMessages.tr}), value: "TR"},
	{label: intl.formatMessage({...CountriesMessages.ug}), value: "UG"},
	{label: intl.formatMessage({...CountriesMessages.uz}), value: "UZ"},
	{label: intl.formatMessage({...CountriesMessages.ua}), value: "UA"},
	{label: intl.formatMessage({...CountriesMessages.uy}), value: "UY"},
	{label: intl.formatMessage({...CountriesMessages.fo}), value: "FO"},
	{label: intl.formatMessage({...CountriesMessages.fm}), value: "FM"},
	{label: intl.formatMessage({...CountriesMessages.fj}), value: "FJ"},
	{label: intl.formatMessage({...CountriesMessages.ph}), value: "PH"},
	{label: intl.formatMessage({...CountriesMessages.fi}), value: "FI"},
	{label: intl.formatMessage({...CountriesMessages.fk}), value: "FK"},
	{label: intl.formatMessage({...CountriesMessages.fr}), value: "FR"},
	{label: intl.formatMessage({...CountriesMessages.gf}), value: "GF"},
	{label: intl.formatMessage({...CountriesMessages.pf}), value: "PF"},
	{label: intl.formatMessage({...CountriesMessages.tf}), value: "TF"},
	{label: intl.formatMessage({...CountriesMessages.hr}), value: "HR"},
	{label: intl.formatMessage({...CountriesMessages.cf}), value: "CF"},
	{label: intl.formatMessage({...CountriesMessages.td}), value: "TD"},
	{label: intl.formatMessage({...CountriesMessages.me}), value: "ME"},
	{label: intl.formatMessage({...CountriesMessages.cz}), value: "CZ"},
	{label: intl.formatMessage({...CountriesMessages.cl}), value: "CL"},
	{label: intl.formatMessage({...CountriesMessages.ch}), value: "CH"},
	{label: intl.formatMessage({...CountriesMessages.se}), value: "SE"},
	{label: intl.formatMessage({...CountriesMessages.lk}), value: "LK"},
	{label: intl.formatMessage({...CountriesMessages.ec}), value: "EC"},
	{label: intl.formatMessage({...CountriesMessages.gq}), value: "GQ"},
	{label: intl.formatMessage({...CountriesMessages.er}), value: "ER"},
	{label: intl.formatMessage({...CountriesMessages.sz}), value: "SZ"},
	{label: intl.formatMessage({...CountriesMessages.ee}), value: "EE"},
	{label: intl.formatMessage({...CountriesMessages.et}), value: "ET"},
	{label: intl.formatMessage({...CountriesMessages.za}), value: "ZA"},
	{label: intl.formatMessage({...CountriesMessages.ss}), value: "SS"},
	{label: intl.formatMessage({...CountriesMessages.jm}), value: "JM"},
	{label: intl.formatMessage({...CountriesMessages.jp}), value: "JP"},
].sort((a, b) => {
	if (a.label > b.label) return 1;
	if (a.label < b.label) return -1;
	return 0;
}).concat({label: intl.formatMessage({...CountriesMessages.zz}), value: "ZZ"});

const languages = (intl: IntlShape) => [
	{label: intl.formatMessage({...UserMessages.russian}), value: "ru"},
	{label: intl.formatMessage({...UserMessages.english}), value: "en"},
	{label: intl.formatMessage({...UserMessages.ukrainian}), value: "uk"},
];

//street_type should be in capital letter
const streetTypes = (intl: IntlShape) => [
	{label: intl.formatMessage({...UserMessages.avenue}), value: "AVENUE"},
	{label: intl.formatMessage({...UserMessages.street}), value: "STREET"},
	{label: intl.formatMessage({...UserMessages.lane}), value: "LANE"},
	// {label: intl.formatMessage({...UserMessages.boulevard}), value: "BOULEVARD"},
	// {label: intl.formatMessage({...UserMessages.close}), value: "CLOSE"},
	// {label: intl.formatMessage({...UserMessages.road}), value: "ROAD"},
	// {label: intl.formatMessage({...UserMessages.embankment}), value: "EMBANKMENT"},
	// {label: intl.formatMessage({...UserMessages.alley}), value: "ALLEY"},
	// {label: intl.formatMessage({...UserMessages.square}), value: "SQUARE"},
	// {label: intl.formatMessage({...UserMessages.highway}), value: "HIGHWAY"},
];

const document_types_search = (intl: IntlShape) => ([
	{label: intl.formatMessage({...UserMessages.phone_number}), value: SearchCustomerEnum.msisdn},
	{label: intl.formatMessage({...UserMessages.ukrainian_passport}), value: SearchCustomerEnum.passport},
	{label: intl.formatMessage({...UserMessages.id_card}), value: SearchCustomerEnum.national_id},
	{label: intl.formatMessage({...UserMessages.billing_account_number}), value: SearchCustomerEnum.billing_account},
	{label: intl.formatMessage({...UserMessages.tax_id}), value: SearchCustomerEnum.individual_tax_number},
]);

const document_types = (intl: IntlShape) => ([
	{label: intl.formatMessage({...UserMessages.ukrainian_passport}), value: DocumentTypeEnum.passport},
	{label: intl.formatMessage({...UserMessages.id_card}), value: DocumentTypeEnum.national_id},
	{label: intl.formatMessage({...UserMessages.driving_license}), value: DocumentTypeEnum.driving_license},
	{label: intl.formatMessage({...UserMessages.ukrainian_foreign_passport}), value: DocumentTypeEnum.passport_foreign},
	{
		label: intl.formatMessage({...UserMessages.diplomatic_passport_of_ukraine}),
		value: DocumentTypeEnum.diplomatic_passport_of_ukraine
	},
	{
		label: intl.formatMessage({...UserMessages.service_passport_of_ukraine}),
		value: DocumentTypeEnum.service_passports_of_ukraine
	},
	{
		label: intl.formatMessage({...UserMessages.seafarers_identity_card}),
		value: DocumentTypeEnum["seafarer's_identity_card"]
	},
	{
		label: intl.formatMessage({...UserMessages.cert_of_the_crew_member}),
		value: DocumentTypeEnum.certificate_of_the_crew_member
	},
	{
		label: intl.formatMessage({...UserMessages.id_card_for_return_to_ukraine}),
		value: DocumentTypeEnum.id_card_for_return_to_ukraine
	},
	{
		label: intl.formatMessage({...UserMessages.temp_cert_of_a_citizen_of_ukraine}),
		value: DocumentTypeEnum.temporary_certificate_of_a_citizen_of_ukraine
	},
	{
		label: intl.formatMessage({...UserMessages.cert_of_a_stateless_person_to_travel_abroad}),
		value: DocumentTypeEnum.certificate_of_a_stateless_person_to_travel_abroad
	},
	{
		label: intl.formatMessage({...UserMessages.permanent_residence_permit}),
		value: DocumentTypeEnum.permanent_residence_permit
	},
	{label: intl.formatMessage({...UserMessages.residence_permit}), value: DocumentTypeEnum.residence_permit},
	{label: intl.formatMessage({...UserMessages.migrant_card}), value: DocumentTypeEnum.migrant_card},
	{label: intl.formatMessage({...UserMessages.refugee_certificate}), value: DocumentTypeEnum.refugee_certificate},
	{
		label: intl.formatMessage({...UserMessages.refugee_travel_document}),
		value: DocumentTypeEnum.refugee_travel_document
	},
	{
		label: intl.formatMessage({...UserMessages.id_card_that_needs_additional_protection}),
		value: DocumentTypeEnum.ID_card_that_needs_additional_protection
	},
	{
		label: intl.formatMessage({...UserMessages.travel_doc_of_a_person_who_had_been_granted_additional_protection}),
		value: DocumentTypeEnum.travel_document_of_a_person_who_has_been_granted_additional_protection
	},
	{
		label: intl.formatMessage({...UserMessages.passport_doc_of_another_country}),
		value: DocumentTypeEnum.passport_document_of_another_country
	},
]);

const document_type_post_pay = (intl: IntlShape) => ([
	{label: intl.formatMessage({...UserMessages.ukrainian_passport}), value: DocumentTypeEnum.passport},
	{label: intl.formatMessage({...UserMessages.id_card}), value: DocumentTypeEnum.national_id},
]);

const document_type_post_pay_additional = (intl: IntlShape) => ([
	{label: intl.formatMessage({...UserMessages.ukrainian_foreign_passport}), value: DocumentTypeEnum.passport_foreign},
	{
		label: intl.formatMessage({...UserMessages.diplomatic_passport_of_ukraine}),
		value: DocumentTypeEnum.diplomatic_passport_of_ukraine
	},
	{
		label: intl.formatMessage({...UserMessages.service_passport_of_ukraine}),
		value: DocumentTypeEnum.service_passports_of_ukraine
	},
	{label: intl.formatMessage({...UserMessages.driving_license}), value: DocumentTypeEnum.driving_license},
]);

const postal_code = {
	"chuy": [
		{label: "725000", value: "725000"}, {label: "725011", value: "725011"}, {label: "725012", value: "725012"},
		{label: "725013", value: "725013"}, {label: "725014", value: "725014"}, {label: "725015", value: "725015"},
		{label: "725016", value: "725016"}, {label: "725017", value: "725017"}, {label: "725019", value: "725019"},
		{label: "725020", value: "725020"}, {label: "725021", value: "725021"}, {label: "725022", value: "725022"},
		{label: "725023", value: "725023"}, {label: "725024", value: "725024"}, {label: "725025", value: "725025"},
		{label: "725026", value: "725026"}, {label: "725027", value: "725027"}, {label: "725028", value: "725028"},
		{label: "725029", value: "725029"}, {label: "725030", value: "725030"},
	],
	"batken": [
		{label: "720101", value: "720101"}, {label: "720102", value: "720102"}, {
			label: "720103",
			value: "720103"
		}, {label: "720104", value: "720104"},
		{label: "720100", value: "720100"}, {label: "720105", value: "720105"}, {
			label: "720106",
			value: "720106"
		}, {label: "720107", value: "720107"},
		{label: "720108", value: "720108"}, {label: "720109", value: "720109"}, {
			label: "720110",
			value: "720110"
		}, {label: "720111", value: "720111"},
		{label: "720112", value: "720112"}, {label: "720113", value: "720113"}, {
			label: "720114",
			value: "720114"
		}, {label: "720115", value: "720115"},
		{label: "720116", value: "720116"}, {label: "720117", value: "720117"},
	],
	"bishkek": [
		{label: "720051", value: "720051"}, {label: "720052", value: "720052"}, {
			label: "720053",
			value: "720053"
		}, {label: "720054", value: "720054"},
		{label: "720055", value: "720055"}, {label: "720060", value: "720060"}, {
			label: "720062",
			value: "720062"
		}, {label: "720064", value: "720064"},
		{label: "720065", value: "720065"}, {label: "720066", value: "720066"}, {label: "720070", value: "720070"},
	],
	"jalal-abad": [
		{label: "720611", value: "720611"}, {label: "720612", value: "720612"},
		{label: "720613", value: "720613"}, {label: "720614", value: "720614"},
		{label: "720615", value: "720615"}, {label: "720616", value: "720616"},
		{label: "720701", value: "720701"}, {label: "720702", value: "720702"},
		{label: "720703", value: "720703"}, {label: "720700", value: "720700"},
		{label: "720704", value: "720704"}, {label: "720705", value: "720705"},
		{label: "720706", value: "720706"},
	],
	"issyk-kul": [
		{label: "721804", value: "721804"}, {label: "721805", value: "721805"}, {label: "721806", value: "721806"},
		{label: "721807", value: "721807"}, {label: "721808", value: "721808"}, {label: "721809", value: "721809"},
		{label: "721810", value: "721810"}, {label: "721811", value: "721811"}, {label: "721812", value: "721812"},
	],
	"naryn": [
		{label: "722514", value: "722514"}, {label: "722515", value: "722515"}, {
			label: "722601",
			value: "722601"
		}, {label: "722602", value: "722602"},
		{label: "722603", value: "722603"}, {label: "722600", value: "722600"}, {
			label: "722604",
			value: "722604"
		}, {label: "722605", value: "722605"},
		{label: "722606", value: "722606"}, {label: "722607", value: "722607"},
	],
	"osh": [
		{label: "723207", value: "723207"}, {label: "723208", value: "723208"}, {
			label: "723209",
			value: "723209"
		}, {label: "723210", value: "723210"},
		{label: "723211", value: "723211"}, {label: "723212", value: "723212"}, {
			label: "723213",
			value: "723213"
		}, {label: "723214", value: "723214"},
		{label: "723215", value: "723215"}, {label: "723306", value: "723306"}, {label: "723301", value: "723301"},
	],
	"talas": [
		{label: "724103", value: "724103"}, {label: "724104", value: "724104"}, {
			label: "724100",
			value: "724100"
		}, {label: "724105", value: "724105"},
		{label: "724106", value: "724106"}, {label: "724107", value: "724107"}, {
			label: "724108",
			value: "724108"
		}, {label: "724109", value: "724109"},
		{label: "724110", value: "724110"},
	]
};

const genders = (intl: IntlShape) => [
	{label: intl.formatMessage({...UserMessages.male}), value: "male"},
	{label: intl.formatMessage({...UserMessages.female}), value: "female"}
];

const companyType = (intl: IntlShape) => [
	{label: intl.formatMessage({...UserMessages.companyType}), value: true},
	{label: intl.formatMessage({...UserMessages.individualPerson}), value: false}
];

const idDocumentType = (intl: IntlShape) => [
	{label: intl.formatMessage({...RegistrationMessages.edrpou}), value: "EDRPOU"},
];

const changeMsisdnReasons = (intl: IntlShape) => ([
	{label: intl.formatMessage({...ChangeMsisdnMessages.customerRequest}), value: "Customer request"},
	{label: intl.formatMessage({...ChangeMsisdnMessages.faultCorrection}), value: "Fault correction"},
	{label: intl.formatMessage({...ChangeMsisdnMessages.lost}), value: "Lost"},
	{label: intl.formatMessage({...ChangeMsisdnMessages.other}), value: "Other"},
]);


const changeSimReasons = (intl: IntlShape) => ([
	{label: intl.formatMessage({...changeSimMessages.damage}), value: "damage"},
	{label: intl.formatMessage({...changeSimMessages.simLost}), value: "sim lost"},
	{label: intl.formatMessage({...changeSimMessages.stolen}), value: "stolen"},
	{label: intl.formatMessage({...changeSimMessages.manufacturingFault}), value: "manufacturing fault"},
	{label: intl.formatMessage({...changeSimMessages.blocked}), value: "blocked"},
	{label: intl.formatMessage({...changeSimMessages.lteSimChange}), value: "LTE SIM change"},
	{label: intl.formatMessage({...changeSimMessages.formatChange}), value: "Format change"},
	{label: intl.formatMessage({...changeSimMessages.transitionToUsim}), value: "Transition to USIM"},
	{label: intl.formatMessage({...changeSimMessages.transitionToMobileID}), value: "Transition to Mobile ID"},
	{label: intl.formatMessage({...changeSimMessages.changeToSpareSIM}), value: "Change to Spare SIM"}
]);

const mappingLetters = {
	1040: "A",
	1042: "B",
	1045: "E",
	1050: "K",
	1052: "M",
	1053: "H",
	1054: "O",
	1056: "P",
	1057: "C",
	1058: "T",
	1059: "Y",
	1061: "X",
	1028: "E",
	1030: "I",
};

const gisSystem = {
	countries: [{id: "1", name: "Ukraine", code: "UA"}],
	cities: [{id: "1", name: "Vyshhorod", country_id: "1"}, {id: "2", name: "Lviv", country_id: "1"}],
	streets: [{
		id: "1", name: "Glibova", city_id: "1", available_houses: [{
			id: "806298",
			house_number: "2",
			fttb_available: true,
		}],
	},
	{
		id: "2", name: "Centralna", city_id: "2", available_houses: [{
			id: "806297",
			house_number: "10",
			fttb_available: false,
		}]
	}
	],
	districts: [],
	regions: [],
};

const company_search_type = (intl: IntlShape) => ([
	{label: intl.formatMessage({...B2BFTTBFlowMessages.searchByCompanyName}), value: SearchCompanyEnumEnum.byName},
	{label: intl.formatMessage({...B2BFTTBFlowMessages.searchByCompanyId}), value: SearchCompanyEnumEnum.byId},
]);

export {
	countries,
	companyType,
	gisSystem,
	mappingLetters,
	changeSimReasons,
	document_type_post_pay,
	document_type_post_pay_additional,
	languages, streetTypes, document_types, genders, postal_code,
	changeMsisdnReasons,
	document_types_search,
	DONOR_OPERATORS,
	idDocumentType,
	company_search_type,
	DONOR_SEGMENT,
};
