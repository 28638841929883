import React  from "react";
import { Invoice } from "../../../../../graphql/types";
import AcquisitionMessages from "../../../Acquisition.messages";
import { FormattedMessage } from "react-intl";

interface CustomerDebtsProps {
    invoices: any
}

const CustomerDebts = (props: CustomerDebtsProps) => {
    return (
        <div>
            <div className="alert alert-danger mt-2 message">
                <p className="mb-0">
                    <FormattedMessage {...AcquisitionMessages.debtMessage} />
                </p>
            </div>
            {props.invoices?.map((invoice: Invoice, index) => {
                const dueAmount = Number(invoice?.total) - Number(invoice?.paid_amount);
                return (
                    <div key={invoice?.invoice_id || index} className="mt-3">
                        <div><FormattedMessage {...AcquisitionMessages.debtDueAmount} />: {dueAmount || ""}</div>
                        <div><FormattedMessage {...AcquisitionMessages.debtDueDate} />: {invoice?.due_date || ""}
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export { CustomerDebts };