import React from "react";

interface SuccessMessageTemplateProps {
    show: string|boolean|undefined;
    children: any;
}
interface SuccessMessageListProps {
    successMsgs: string[];
}

const SuccessMessageTemplate = (props: SuccessMessageTemplateProps) => {
	return (<>{props.show ? <div className="alert alert-success">{props.children}</div> : null}</>);
};
const SuccessMessageList = (props: SuccessMessageListProps) => {
	return (<>{props.successMsgs.length > 0 ? <div className="alert alert-success">{props.successMsgs.join("; ")}</div> : null}</>);
};

export { SuccessMessageTemplate, SuccessMessageList };
