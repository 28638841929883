import gql from "graphql-tag";
import { config } from "../../config/constants";

export const GET_MSISDNS = gql`
    query getMsisdns($mask: String!, $category: Int, $page_no: Int) {
        msisdns(mask: $mask, category: $category, stock: ${config.MSISDN_NORMAL_STOCK} page_no: $page_no, page_size: ${config.MSISDN_SEARCH_PAGE_SIZE}) {
            price,
            msisdn,
            currency,
            number_category,
       }
    }
`;

export const GET_SINGLE_MSISDNS = gql`
     {
         msisdns(mask: "", category: ${config.MSISDN_NORMAL_CATEGORY}, stock: ${config.MSISDN_FTTB_STOCK}, page_no: 1, page_size: 1) {
            price,
            msisdn,
            currency,
            number_category,
        }
    }
`;

export const GET_AVAILABLE_MSISDNS = gql`
    query getAvailableMsisdns($mask: String!, $page_no: Int) {
        available_numbers(mask: $mask, page_size: ${config.MSISDN_SEARCH_PAGE_SIZE}, page_no: $page_no) {
            price,
            msisdn,
            currency,
            number_category,
       }
    }
`;

export const GET_B2B_SINGLE_MSISDNS = gql`
    {
        msisdns(mask: "", category: ${config.MSISDN_NORMAL_CATEGORY}, stock: ${config.MSISDN_B2B_FTTB_STOCK}, page_no: 1, page_size: 1) {
            price,
            msisdn,
            currency,
            number_category,
        }
    }
`;