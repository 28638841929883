import InitialValues from "../../modules/registration/Registration.types";
import moment from "moment";
import { AddressInput, CustomerInput } from "../../graphql/types";
import { IntlShape } from "react-intl";
import { handlePhone } from "./utils";
import { PayloadProps } from "../hooks/useDocumentGeneration";
import { config, CSRFTOKEN } from "../../config/constants";
import  {TARIFF_PLAN_TYPE_PREPAID} from "../../modules/acquisitionFlow/NewCustomer/Pages/index";
import Cookies from "js-cookie";
const dateFormat = "DD.MM.YYYY";
const DATE_FORMAT_DOC_GENERATION = "DD-MM-YYYY";

const generateDocumentPostUrl = (host: string) =>
	`${window.location.protocol}//${host}/api/contract/document-download`;
const generateDocumentGetUrl = (host: string, customerId: string, msisdn: string) =>
	`${window.location.protocol}//${host}/api/contract/document-download?customer_id=${customerId}&msisdn=${msisdn}`;

const customer = {
	gender: "",
	nationality: "",
	first_name: "",
	last_name: "",
	first_name_alt: "",
	middle_name: "",
	contact_phone: handlePhone,
	date_of_birth: (data: Date) => moment(data).format(dateFormat),
	id_doc_issue_date: (data: Date) => moment(data).format(dateFormat),
	id_doc_valid_till_date: (data: Date) => data && moment(data).format(dateFormat),
	language: "",
	id_document_type: "",
	id_document_number: "",
	id_doc_issued_by: "",
	email: "",
	password: "",
	additional_emails: "",
	individual_tax_number: "",
	comments: "",
};

const transformAcquisitionContactFormDates = (values) =>  {
	const requiredFields = {};
	if (values.id_doc_valid_till_date) {
		requiredFields["id_doc_valid_till_date"] = moment(values.id_doc_valid_till_date).format(dateFormat);
	} else if (values.id_doc_issue_date) {
		requiredFields["id_doc_issue_date"] = moment(values.id_doc_issue_date).format(dateFormat);
	}
	return {
		birthday: values.date_of_birth ? moment(values.date_of_birth).format(dateFormat) : moment("1900-01-01").format(dateFormat),
		...requiredFields
	};
};

const legal_address = [
	"city",
	"country",
	"province",
	"apartment",
	"building",
	"street",
	"house_number",
	"district",
	"postal_code",
];

const customerInput = (values: InitialValues): CustomerInput => {
	const initialCustomer: Partial<CustomerInput> = {};
	for(const key in customer) {
		if(!customer[key]) {
			initialCustomer[key] = values[key];
		} else {
			if (values[key]) {
				initialCustomer[key] = customer[key](values[key]);
			}
		}
	}
	const addressInput: AddressInput  = legal_address.reduce((acc, curr: string) => {
		if (values[curr]) {
			acc[curr] = values[curr];
		}
		return acc;
	}, {} as any);

	initialCustomer.legal_address = { attributes: addressInput };
	return initialCustomer;
};

const initialLabels = <T>(intl: IntlShape, labels: Partial<T>, messages: any): Record<keyof T, string> => {
	const initialLabels = {} as Record<keyof T, any>;
	for (const key in labels) {
		if(messages[key]) {
			initialLabels[key] = intl.formatMessage(messages[key]);
		}
	}
	return initialLabels;
};

const generateCustomer = (customer: PayloadProps) => {
	const customerInfo = {
		gender: customer?.info?.gender || "",
		first_name: customer?.info?.first_name || "",
		middle_name: customer?.info?.middle_name || "",
		last_name: customer?.info?.last_name || "",
		birthday: customer?.info?.date_of_birth ? moment(customer?.info?.date_of_birth).format(DATE_FORMAT_DOC_GENERATION) : moment("1900-01-01").format(DATE_FORMAT_DOC_GENERATION),
		id_number: customer?.idDocuments?.id_document_number || "",
		id_type: customer?.idDocuments?.id_document_type || "",
		id_doc_issued_date: customer?.idDocuments?.id_doc_issue_date ? moment(customer?.idDocuments?.id_doc_issue_date).format(DATE_FORMAT_DOC_GENERATION) : "",
		id_doc_valid_date: customer?.idDocuments?.id_doc_valid_till_date ? moment(customer?.idDocuments?.id_doc_valid_till_date).format(DATE_FORMAT_DOC_GENERATION) : "",
		id_doc_issued_by: customer?.idDocuments?.id_doc_issued_by || "",
		postal_code: customer?.address?.postal_code || "",
		province: customer?.address?.province || "",
		// district: customer?.address?.district || "",
		city: customer?.address?.city || "",
		street: customer?.address?.street || "",
		house_number: customer?.address?.house_number || "",
		apartment_number: customer?.address?.apartment || "",
		building: customer?.address?.building || "",
		contact_phone_number: customer?.address?.contact_phone || "",
		email: customer?.address?.email || "",
		password: customer?.idDocuments?.password || "",
		individual_tax_number: customer?.idDocuments.individual_tax_number || "",
	};
	return Object.keys(customerInfo)
		.reduce((obj, key) => {
			return {
				...obj,
				[key]: customerInfo[key]
			};
		}, {});
};

const fetchDocumentLinkExistingCustomer = async (customerId: string, msisdn: string, abortController: AbortController) => {
	const csrf = Cookies.get(CSRFTOKEN);

	return await fetch(generateDocumentGetUrl(config.HOST, customerId, msisdn), {
		signal: abortController.signal,
		method: "GET",
		headers: {
			...(csrf && {"X-XSRF-TOKEN": csrf})
		},
		credentials: process.env.NODE_ENV === "development" ? "include" : "same-origin",
	});
};

const fetchDocumentLinkNewCustomer = async (language: string, msisdn: string, payload: PayloadProps, orderType: string): Promise<any> => {
	let attributes;
	if (orderType !== config.PERSONALIZATION) {
		attributes = {
			is_default: true,
			order_type: payload.orderType || "acquisition",
			connection_type: "4G",
			sim_price: 0,
			confirmation_code: payload.msisdnReservationCode || "4CV7T",
			contract_number: payload.msisdnReservationCode || "4CV7T",
			icc: payload.icc,
			tariff_plan: payload.tariffPlanName || "empty",
			tariff_plan_type: payload.tariffPlanType || "empty",
			salesman_username: payload.salesman_username || "empty",
			language,
			billing_account: payload.billing_account,
			isNewBA: payload.isNewBA,
			msisdn_otp: payload.msisdnOtp,
			is_prepaid: payload.tariffPlanType == TARIFF_PLAN_TYPE_PREPAID,
			is_number_portability: true,
			transfer_time: payload.transferTime
		};
	} else {
		attributes = {
			order_type: orderType,
		};
	}
	const csrf = Cookies.get(CSRFTOKEN);

	return fetch(generateDocumentPostUrl(config.HOST), {
		method: "POST",
		credentials: process.env.NODE_ENV === "development" ? "include" : "same-origin",
		headers: {
			...(csrf && {"X-XSRF-TOKEN": csrf})
		},
		body: JSON.stringify({
			data: {
				type: "contract-doc-generate",
				attributes: {
					...attributes,
					msisdn,
					customer: generateCustomer(payload),
					product_quantity: 1,
					is_business: false,
				}
			},
			meta: {
				channel: "IPOS"
			}
		})
	});
};

const generateDocumentLinkExistingCustomer = async (customerId: string, msisdn: string, abortController: AbortController, repeat = 1): Promise<any> => {
	try {
		const result = await fetchDocumentLinkExistingCustomer(customerId, msisdn, abortController);
		if (result.ok) {
			return await result.blob();
		} else if (result.status === 401 && repeat < config.REPEAT_REQUEST) {
			//TODO maybe need to handle
		} else {
			throw new Error("Generate document's link for download");
		}
	} catch (e) {
		throw new Error("Generate document's link for download");
	}
};

const generateDocumentLinkNewCustomer = async (
	language: string,
	msisdn: string,
	payload: PayloadProps,
	repeat: number,
	orderType: string = ""): Promise<any> => {
	try {
		const result = await fetchDocumentLinkNewCustomer(language, msisdn, payload, orderType);
		if (result.ok) {
			return await result.blob();
		} else if (result.status === 401 && repeat < config.REPEAT_REQUEST) {
			//TODO maybe need to handle
		} else {
			throw new Error("Generate document's link for download");
		}
	} catch (e) {
		throw new Error("Generate document's link for download");
	}
};


export {
	customerInput,
	initialLabels,
	transformAcquisitionContactFormDates,
	generateCustomer,
	fetchDocumentLinkExistingCustomer,
	generateDocumentLinkExistingCustomer,
	generateDocumentLinkNewCustomer,
	DATE_FORMAT_DOC_GENERATION
};
