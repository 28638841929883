import React, { HTMLAttributes } from "react";
import { FormattedMessage } from "react-intl";
import { CommonMessages } from "../../Common.messages";

interface UserProfileToggle extends HTMLAttributes<HTMLDivElement>{
    userName: string;
}
const UserProfileToggle = React.forwardRef<HTMLDivElement, UserProfileToggle>(({ children, userName, onClick }, ref: any) => (
	<div ref={ref}
		onClick={e => {
			e.preventDefault();
			if (onClick) {
				onClick(e);
			}
		}}
		{...{children}}
		className="w-action-item-link dropdown-toggle">
		<div className="w-action-item-label">
			<div className="w-action-item-label-key d-block"><FormattedMessage {...CommonMessages.userProfile}/></div>
			<div className="w-action-item-label-value d-block">{userName}</div>
		</div>
		<div className="w-action-item-button">
			<i className="fas fa-user w-action-item-icon"></i>
		</div>
	</div>
));

export {
	UserProfileToggle
};
