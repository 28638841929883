import React, {ReactElement, useMemo} from "react";
import {useIntl} from "react-intl";
import { MnpMessages } from "./Mnp.messages";
import {SelectFieldFormik} from "../../shared/components/SelectField";
import {document_type_post_pay, DONOR_SEGMENT} from "../../shared/utils/common_mock_data";
import {config} from "../../config/constants";
import {FormikProps} from "../../shared/types";
import {DonorSegmentEnum} from "../../graphql/types";
import {InputFieldFormik} from "../../shared/components";
import {DocumentTypeEnum} from "../../shared/types/DocumentTypeEnum";

interface DonorSegmentProps {
    formik: FormikProps;
}

const DonorSegment = (props: DonorSegmentProps) => {
	const intl = useIntl();
	const documentTypes = useMemo(() => document_type_post_pay(intl), [intl.locale]);
	let display: ReactElement | null = null;

	const lengthOfDocumentNumber = (values) => {
		if (values.id_document_type === DocumentTypeEnum.passport) {
			return config.PASSPORT_LENGTH;
		}
		if (values.id_document_type === DocumentTypeEnum.national_id) {
			return config.NATIONAL_ID;
		}
		return config.DEFAULT_INPUT_LENGTH;
	};

	const donorName = <div>
		<InputFieldFormik
			required
			name="donor_first_name"
			id="donor_first_name"
			placeholder={intl.formatMessage(MnpMessages.donorFirstName)}
			value={props.formik.values.donor_first_name}
			formik={props.formik}
		/>
		<InputFieldFormik
			required
			name="donor_last_name"
			id="donor_last_name"
			placeholder={intl.formatMessage(MnpMessages.donorLastName)}
			value={props.formik.values.donor_last_name}
			formik={props.formik}
		/>
	</div>;

	switch (props.formik.values.donor_segment) {
		case DonorSegmentEnum.b2cPassportized:
			display = donorName;
			break;
		case DonorSegmentEnum.b2cNonPassportized:
			display = null;
			break;
		case DonorSegmentEnum.mmc:
			display = donorName;
			break;
		case DonorSegmentEnum.popEntrepreneur:
			display = <div>
				<SelectFieldFormik
					required
					showPlaceholder={true}
					name="id_document_type"
					id="id_document_type"
					values={documentTypes}
					attr={{maxLength: 256}}
					placeholder={intl.formatMessage(MnpMessages.chooseDocumentType)}
					value={props.formik.values.id_document_type}
					formik={props.formik}
				/>
				<InputFieldFormik
					required
					name="donor_passport_id_card"
					id="donor_passport_id_card"
					placeholder={intl.formatMessage(MnpMessages.donorPassportOrId)}
					value={props.formik.values.donor_passport_id_card}
					formik={props.formik}
					attr={{maxLength: lengthOfDocumentNumber(props.formik.values)}}
				/>
			</div>;
			break;
		case DonorSegmentEnum.popB2b:
			display = <div>
				<InputFieldFormik
					required
					name="donor_okpo"
					id="donor_okpo"
					placeholder={intl.formatMessage(MnpMessages.okpoNumber)}
					value={props.formik.values.donor_okpo}
					formik={props.formik}
				/>
			</div>;
			break;

	}

	return (
		<div className="row">
			<div className="col-sm-4 col-12">
				<SelectFieldFormik
					required
					showPlaceholder={true}
					name="donor_segment"
					id="donor-segment"
					values={DONOR_SEGMENT(intl)}
					attr={{ maxLength: config.PHONE_LENGTH }}
					placeholder={intl.formatMessage(MnpMessages.chooseDonorSegment)}
					value={props.formik.values.donor_segment}
					formik={props.formik}
				/>
				{display}
			</div>
		</div>
	);
};

export { DonorSegment };