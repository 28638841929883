import React, {useState} from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { MNPRouteState} from "../../MNPFlowRoutes";
import {useNavigationState, useUpdateNavigatioState} from "../../../../shared/components/navigation/useNavigationState";
import {CircularProgress, ErrorMessageTemplate, ICCForm} from "../../../../shared/components";
import {useIntl} from "react-intl";
import {changeSimMessages} from "../../../change-bar/sim/ChangeSim.message";
import {Query, QueryValidate_IccArgs} from "../../../../graphql/types";
import {CommonMessages} from "../../../../Common.messages";
import {iccValidationErrors} from "../../../../shared/utils/mappingErrors";
import {useLazyQuery} from "@apollo/react-hooks";
import {VALIDATE_ICC} from "../../../../graphql/queries/icc";
import {config} from "../../../../config/constants";
import {FormStepWrapper} from "../../../../shared/components/navigation/FormStepWrapper";
import {MNP_FLOW_CONTACT_INFO_POSTPAID, MNP_FLOW_EXISTING_CONTACT_INFO_POSTPAID} from "../MNPFlowRoutes";

const formId = "mnp-postpaid-flow-icc-form-id";
const MnpIccId = (props: RouteComponentProps<never, StaticContext, MNPRouteState>) => {
	const state = useNavigationState<MNPRouteState>();
	const updateNavigationState  = useUpdateNavigatioState<MNPRouteState>();

	const { history } = props;
	const intl = useIntl();
	const [error, setError] = useState<string>("");
	const iccValidationFail = intl.formatMessage({...changeSimMessages.iccValidationFail});
	const setErrorMessage = (data: Query) => {
		if (!data?.validate_icc) {
			setError(intl.formatMessage(CommonMessages.provideValidIcc));
			return;
		}
		const currentError = iccValidationErrors[data?.validate_icc?.error || ""];
		if (currentError) {
			setError(intl.formatMessage(CommonMessages[currentError]));
		} else {
			setError(data?.validate_icc?.error || iccValidationFail);
		}
	};
	const [validateIcc, {loading, variables}] = useLazyQuery<Query, QueryValidate_IccArgs>(VALIDATE_ICC, {
		fetchPolicy: "no-cache",
		onCompleted: (data: Query) => {
			if (data.validate_icc && !data.validate_icc?.error) {
				updateNavigationState({
					...state,
					iccId: variables.icc
				});
				history.push(state?.isNewCustomer ? MNP_FLOW_CONTACT_INFO_POSTPAID : MNP_FLOW_EXISTING_CONTACT_INFO_POSTPAID);
			} else {
				setErrorMessage(data);
			}
		},
		onError: () => {
			setError(intl.formatMessage(CommonMessages.provideValidIcc));
		}
	});

	const onSubmit = (values) => {
		validateIcc({
			variables: {
				icc: config.DEFAULT_ICC_PREFIX + values.iccId,
				offer_id: state?.plan?.offerId!,
			}
		});
		setError("");
	};
	const iccId = (config?.DEFAULT_ICC_PREFIX?.length && state?.iccId?.substring(config.DEFAULT_ICC_PREFIX.length!)) ||  "";
	const init = { iccId };
	return (<FormStepWrapper<MNPRouteState> isValid formId={formId}>
		<div className="ipos-content-box">
			<ICCForm initialValues={init} formId={formId} onSubmit={onSubmit} />
			{loading && <CircularProgress/>}
			<ErrorMessageTemplate show={error}>{error}</ErrorMessageTemplate>
		</div>
	</FormStepWrapper>
	);
};

export { MnpIccId };