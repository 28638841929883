import { gql } from "apollo-boost";
import { language } from "../fragments/Languages.fragment";

export const COMPATIBLE_PACKAGE = gql`
    fragment CompatiblePackageInfo on CompatiblePackage {
        name {
            ...languageFull
        }
        code
        package_product_type
    }
    ${language.fragments.full}
`;

export const GET_SUBSCRIPTION_COMPATIBLE_PRODUCTS = gql`query GetSubscriptionCompatibleProducts($msisdn: String!, $sub_type_code: String!) {
    otp_subscription(msisdn: $msisdn, otp: "") {
        subscription {
            id
            compatible_products(sub_type_code: $sub_type_code) {
                always_deactivated_packages {
                    ...CompatiblePackageInfo
                }
                always_saved_packages {
                    ...CompatiblePackageInfo
                }
                current_subscription_type
                packages_to_be_activated {
                    ...CompatiblePackageInfo
                }
                packages_to_be_deactivated {
                    ...CompatiblePackageInfo
                }
                target_subscription_type

            }
        }
    }
}
${COMPATIBLE_PACKAGE}
`;