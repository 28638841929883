enum DocumentTypeEnum {
    "passport" = "passport",
    "national_id" = "national_id",
    "driving_license" = "driving_license",
    "passport_foreign" = "passport_foreign",
    "diplomatic_passport_of_ukraine" = "diplomatic_passport_of_ukraine",
    "service_passports_of_ukraine" = "service_passports_of_ukraine",
    "seafarer's_identity_card" = "seafarer",
    "certificate_of_the_crew_member" = "certificate_of_the_crew_member",
    "id_card_for_return_to_ukraine" = "id_card_for_return_to_ukraine",
    "temporary_certificate_of_a_citizen_of_ukraine" = "temporary_certificate_of_a_citizen_of_ukraine",
    "certificate_of_a_stateless_person_to_travel_abroad" = "certificate_of_a_stateless_person_to_travel_abroad",
    "permanent_residence_permit" = "permanent_residence_permit",
    "residence_permit" = "residence_permit",
    "migrant_card" = "migrant_card",
    "refugee_certificate" = "refugee_certificate",
    "refugee_travel_document" = "refugee_travel_document",
    "ID_card_that_needs_additional_protection" = "ID_card_that_needs_additional_protection",
    "travel_document_of_a_person_who_has_been_granted_additional_protection" = "travel_document_of_a_person_who_has_been_granted_additional_protection",
    "passport_document_of_another_country" = "passport_document_of_another_country",
}

export {
	DocumentTypeEnum
};