import { useLazyQuery } from "@apollo/react-hooks";
import { useIntl } from "react-intl";
import { GIS_HOUSE_PREVIOUS_ACTIVATION_STATUS } from "../../graphql/queries/fttb";
import { Query, QueryGis_House_Previous_Activation_StatusArgs } from "../../graphql/types";
import { FTTBMessages } from "../../modules/fttb/FTTB.messages";
import { FttbAddressEnum } from "../types/FttbEnum";

const useGisHousePreviousActivationStatus = ({
	setPreviousHouseActivationStatus,
	setConnectionExist,
	setFieldValue
}) => {
	const intl = useIntl();
	const [validate, {loading, data}] = useLazyQuery<Required<Pick<Query, "gis_house_previous_activation_status">>, QueryGis_House_Previous_Activation_StatusArgs>(GIS_HOUSE_PREVIOUS_ACTIVATION_STATUS, {
		fetchPolicy: "no-cache",
		onCompleted: ({ gis_house_previous_activation_status }) => {
			const previousActivationStatus = gis_house_previous_activation_status?.previous_activation_status;
			if (previousActivationStatus === 2) {
				setPreviousHouseActivationStatus(true);
				setConnectionExist(intl.formatMessage(FTTBMessages.newConnectionhasActiveConnection));
				setFieldValue(FttbAddressEnum.simpleConnection, false);
			} else if (previousActivationStatus === 0){
				setPreviousHouseActivationStatus(true);
			}else {
				setPreviousHouseActivationStatus(false);
				setFieldValue(FttbAddressEnum.simpleConnection, false);
			}
		}
	});
	return {validate, loading, data};
};

export {
	useGisHousePreviousActivationStatus
};
