import React, { FC, ReactNode, useContext, useReducer } from "react";
import { MSISDNS, RESERVED_MSISDNS } from "../../../config/constants";
import { Msisdn } from "../../../graphql/types";
import { Action, argumentlessActionCreator, SingleArgumentAction, singleArgumentActionCreator } from "../../utils/actionCreator";
import { MsisdnErrorsUtils } from "../../utils/MsisdnErrorsUtils.utils";
import { Storage } from "../../utils/storageUtils";
import { getInitFmcPopFlagState } from "./useNavigationState";

export enum NotificationActions {
	CLEAN_NOTIFICATIONS = "CLEAN_NOTIFICATIONS",
	SET_NOTIFICATION = "SET_NOTIFICATION",
}
export enum ErrorActions {
	SET_ERROR = "SET_ERROR",
	SET_ERRORS = "SET_ERRORS",
	SET_MSISDN_ERRORS = "SET_MSISDN_ERRORS",
	CLEAN_MSISDN_ERROR = "CLEAN_MSISDN_ERROR",
	CLEAN_MSISDN_ERRORS = "CLEAN_MSISDN_ERRORS",
	CLEAN_ERROR = "CLEAN_ERROR",

}
export enum MsisdnActions {
	REMOVE_MSISDN = "REMOVE_MSISDN",
	REFRESH = "REFRESH_MSISDNS",
	CLEAN = "CLEAN_MSISDNS",
	RESERVE= "RESERVE_MSISDN",
	CLEAN_RESERVATION = "CLEAN_RESERVATION",
}
export enum FmcAction {
	SET_WITHOUT_FTTB = "SET_WITHOUT_FTTB",
	SET_POP_FMC_ADDON_EXISTS = "SET_POP_FMC_ADDON_EXISTS",
	SET_FMCPOPMERGE = "SET_FMCPOPMERGE",
	SET_ALLOWFMCMERGE = "SET_ALLOWFMCMERGE",
	SET_UNPAIR_FMC = "SET_UNPAIR_FMC",
}

export enum FlowAction {
	START_FLOW = "START_FLOW",
	FINISH_FLOW = "FINISH_FLOW",
	CHANGE_FLOW = "CHANGE_FLOW",

}
export interface StepConfig {
	pathname: string;
	message: string;
}
export interface MsisdnReservation {
	msisdn: string,
	reservationId: string
}
export interface FmcState {
	withoutFttb: boolean|undefined;
	allowFmcMerge: boolean|undefined;
	popAddonExist: boolean|undefined;
	unpairFmc: boolean|undefined;
	isPopMerge: boolean|undefined;
}
interface AppState {
	errors: string[];
	msisdns: Msisdn[];
	reservedMsisdns: MsisdnReservation[];
	notifications: string[];
	msisdnErrors: Record<string, string[]>|undefined;
	isFlowDone: boolean;
	paths: StepConfig[];
	fmc: FmcState;
}
interface Reducer {
	state: AppState;
	dispatch: React.Dispatch<StepActionPayload>;
}

type FmcNoFttbPayload = SingleArgumentAction<boolean, FmcAction.SET_WITHOUT_FTTB>;
type FmcPopAdddonExistsPayload = SingleArgumentAction<boolean, FmcAction.SET_POP_FMC_ADDON_EXISTS>;
type FmcPopMergePayload = SingleArgumentAction<boolean, FmcAction.SET_FMCPOPMERGE>;
type AllowFmcMergePayload = SingleArgumentAction<boolean, FmcAction.SET_ALLOWFMCMERGE>;
type FmcUnpairPayload = SingleArgumentAction<boolean, FmcAction.SET_UNPAIR_FMC>;

type CleanNotificationsPayload = Action<NotificationActions.CLEAN_NOTIFICATIONS>;
type SetNotificationPayload = SingleArgumentAction<string,NotificationActions.SET_NOTIFICATION>;
type CleanErrorPayload = Action<ErrorActions.CLEAN_ERROR>;
type MsisdnRefreshPayload = SingleArgumentAction<Msisdn[], MsisdnActions.REFRESH>;
type MsisdnRemovePayload = SingleArgumentAction<string, MsisdnActions.REMOVE_MSISDN>;
type MsisdnReservePayload = SingleArgumentAction<MsisdnReservation, MsisdnActions.RESERVE>;
type MsisdnsCleanPayload = Action<MsisdnActions.CLEAN>;
type CleanMsisdnErrorPayload = SingleArgumentAction<string, ErrorActions.CLEAN_MSISDN_ERROR>;
type SetErrorPayload = SingleArgumentAction<string,ErrorActions.SET_ERROR>;
type CleanMsisdnErrorsPayload = SingleArgumentAction<string,ErrorActions.CLEAN_MSISDN_ERRORS>;
type SetErrorsPayload = SingleArgumentAction<string[],ErrorActions.SET_ERRORS>;
type SetMsisdnErrorsPayload = SingleArgumentAction<Record<string, string[]>,ErrorActions.SET_MSISDN_ERRORS>;

type StartFlowPayload = Action<FlowAction.START_FLOW>;
type ChangeFlowPayload = SingleArgumentAction<StepConfig[],FlowAction.CHANGE_FLOW>;
type FinishFlowPayload = Action<FlowAction.FINISH_FLOW>;

export type StepActionPayload = CleanErrorPayload
	|SetErrorPayload
	|StartFlowPayload
	|FinishFlowPayload
	|ChangeFlowPayload
	|FmcUnpairPayload
	|FmcNoFttbPayload
	|FmcPopMergePayload
	|CleanNotificationsPayload
	|SetNotificationPayload
	|CleanMsisdnErrorsPayload
	|CleanMsisdnErrorPayload
	|SetErrorsPayload
	|MsisdnRefreshPayload
	|MsisdnRemovePayload
	|MsisdnsCleanPayload
	|FmcPopAdddonExistsPayload
	|AllowFmcMergePayload
	|MsisdnReservePayload
	|SetMsisdnErrorsPayload;

export const fmcActions = {
	setWithoutFttb: singleArgumentActionCreator<boolean, FmcNoFttbPayload>(FmcAction.SET_WITHOUT_FTTB),
	setPopFmcAddonExists: singleArgumentActionCreator<boolean, FmcPopAdddonExistsPayload>(FmcAction.SET_POP_FMC_ADDON_EXISTS),
	setFmcPopMerge: singleArgumentActionCreator<boolean, FmcPopMergePayload>(FmcAction.SET_FMCPOPMERGE),
	setAllowFmcMerge: singleArgumentActionCreator<boolean, AllowFmcMergePayload>(FmcAction.SET_ALLOWFMCMERGE),
	setUnpaitFmc: singleArgumentActionCreator<boolean, FmcUnpairPayload>(FmcAction.SET_UNPAIR_FMC),
};

export const notificationActions = {
	setNotification: singleArgumentActionCreator<string, SetNotificationPayload>(NotificationActions.SET_NOTIFICATION),
	cleanNotifications: argumentlessActionCreator<CleanNotificationsPayload>(NotificationActions.CLEAN_NOTIFICATIONS),
};
export const errorActions = {
	setError: singleArgumentActionCreator<string, SetErrorPayload>(ErrorActions.SET_ERROR),
	setErrors: singleArgumentActionCreator<string[], SetErrorsPayload>(ErrorActions.SET_ERRORS),
	setMsisdnErrors: singleArgumentActionCreator<Record<string, string[]>, SetMsisdnErrorsPayload>(ErrorActions.SET_MSISDN_ERRORS),
	cleanError: argumentlessActionCreator<CleanErrorPayload>(ErrorActions.CLEAN_ERROR),
	cleanMsisdnErrors: argumentlessActionCreator<CleanMsisdnErrorsPayload>(ErrorActions.CLEAN_MSISDN_ERRORS),
	cleanMsisdnError: singleArgumentActionCreator<string, CleanMsisdnErrorPayload>(ErrorActions.CLEAN_MSISDN_ERROR),
};

export const msisdnActions = {
	removeMsisdn: singleArgumentActionCreator<string, MsisdnRemovePayload>(MsisdnActions.REMOVE_MSISDN),
	reserve: singleArgumentActionCreator<MsisdnReservation, MsisdnReservePayload>(MsisdnActions.RESERVE),
	refresh: singleArgumentActionCreator<Msisdn[], MsisdnRefreshPayload>(MsisdnActions.REFRESH),
	clean: argumentlessActionCreator<MsisdnsCleanPayload>(MsisdnActions.CLEAN)
};
export const flowActions = {
	startFlow: argumentlessActionCreator<StartFlowPayload>(FlowAction.START_FLOW),
	changeFlow: singleArgumentActionCreator<StepConfig[], ChangeFlowPayload>(FlowAction.CHANGE_FLOW),
	finishFlow: argumentlessActionCreator<FinishFlowPayload>(FlowAction.FINISH_FLOW),
};
const initFmc = { fmc: getInitFmcPopFlagState()};
const initialState: AppState = {
	errors: [],
	msisdns: Storage.local.get(MSISDNS) || [],
	reservedMsisdns: Storage.local.get(RESERVED_MSISDNS) || [],
	msisdnErrors: undefined,
	notifications: [],
	isFlowDone: false,
	paths: [],
	...initFmc,
};

const store = React.createContext(undefined as any);
const { Provider } = store;


const StepStateProvider: FC<{children: ReactNode}> = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch(action.type) {
			case MsisdnActions.CLEAN:
				Storage.local.set(MSISDNS, []);
				Storage.local.set(RESERVED_MSISDNS, []);
				return {
					...state,
					msisdns: [],
					reservedMsisdns: [],
				};
			case MsisdnActions.REFRESH:
				Storage.local.set(MSISDNS, action.payload);
				return {
					...state,
					msisdns: action.payload,
				};
			case MsisdnActions.RESERVE:
				const reservedMsisdns = [...state.reservedMsisdns, action.payload];
				Storage.local.set(RESERVED_MSISDNS, reservedMsisdns);
				return {
					...state,
					reservedMsisdns,
				};
			case MsisdnActions.REMOVE_MSISDN:
				const msisdns = state.msisdns.filter(item => item.msisdn !== action.payload);
				Storage.local.set(MSISDNS, msisdns);
				return {
					...state,
					msisdns,
				};
			case NotificationActions.SET_NOTIFICATION:
				return {
					...state,
					notifications: Array.from(new Set(state.notifications.concat(action.payload)))
				};
			case NotificationActions.CLEAN_NOTIFICATIONS:
				return {
					...state,
					notifications: []
				};
			case ErrorActions.SET_ERROR:
				return {
					...state,
					errors: state.errors.concat(action.payload)
				};
			case ErrorActions.SET_ERRORS:
				return {
					...state,
					errors: Array.from(new Set(state.errors.concat(action.payload)))
				};
			case ErrorActions.CLEAN_ERROR:
				return {
					...state,
					errors: []
				};
			case ErrorActions.SET_MSISDN_ERRORS:
				return {
					...state,
					msisdnErrors: MsisdnErrorsUtils.joinMsisdnErrors(action.payload, state.msisdnErrors)
				};
			
			case ErrorActions.CLEAN_MSISDN_ERROR:
				return {
					...state,
					msisdnErrors: MsisdnErrorsUtils.removeMsisdnErrors(action.payload, state.msisdnErrors)
				};
			case ErrorActions.CLEAN_MSISDN_ERRORS:
				return {
					...state,
					msisdnErrors: undefined
				};
			case FlowAction.CHANGE_FLOW:
				return {
					...state,
					paths: action.payload
				};
			case FlowAction.START_FLOW:
				return {
					...state,
					isFlowDone: false
				};
			case FlowAction.FINISH_FLOW:
				return {
					...state,
					...initFmc,
					isFlowDone: true
				};
			case FmcAction.SET_WITHOUT_FTTB:
				return {
					...state,
					fmc: {
						...state.fmc,
						withoutFttb: action.payload,
					},
				};
			case FmcAction.SET_POP_FMC_ADDON_EXISTS:
				return {
					...state,
					fmc: {
						...state.fmc,
						popAddonExist: action.payload,
					},
				};
			case FmcAction.SET_FMCPOPMERGE:
				return {
					...state,
					fmc: {
						...state.fmc,
						isPopMerge: action.payload,
					},
				};
			case FmcAction.SET_ALLOWFMCMERGE:
				return {
					...state,
					fmc: {
						...state.fmc,
						allowFmcMerge: action.payload,
					},
				};
			case FmcAction.SET_UNPAIR_FMC:
				return {
					...state,
					fmc: {
						...state.fmc,
						unpairFmc: action.payload,
					},
				};
			default:
        		throw new Error("Action not supported");
		}
	}, initialState);
	
	return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

function useStoreContext(): Reducer {
	const {dispatch: wrapped, state} = useContext(store);
	const dispatch = (args) => {
		if (process.env.REACT_APP_ACTION_LOGGER === "true") {
			const date = new Date().toISOString();
			console.log(date, "Action: ", args.type, args.payload ? "Payload: " : "",  args.payload ? args.payload : "");
		}
		wrapped(args);
	};
	return { dispatch, state };
}

export {
	store,
	StepStateProvider,
	useStoreContext
};