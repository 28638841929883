import { useQuery } from "@apollo/react-hooks";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { GET_SUBSCRIPTION_COMPATIBLE_PRODUCTS } from "../../../../graphql/queries/getSubscriptionCompatibleProducts";
import { Query, QueryCas_Validate_OtpArgs, SubscriptionCompatible_ProductsArgs } from "../../../../graphql/types";
interface DeactivatedPackagesProps {
	msisdn: string;
	tariffPlanCode: string;
}
const DeactivatedPackages: FC<DeactivatedPackagesProps> = ({msisdn, tariffPlanCode}) => {
	const intl = useIntl();
	const {data: {otp_subscription } = {}} = useQuery<Required<Pick<Query, "otp_subscription">>, Omit<QueryCas_Validate_OtpArgs, "otp"> & SubscriptionCompatible_ProductsArgs>(GET_SUBSCRIPTION_COMPATIBLE_PRODUCTS, {
		variables: {
			msisdn,
			sub_type_code: tariffPlanCode
		}
	});
	const products = otp_subscription?.subscription?.compatible_products?.packages_to_be_deactivated
		?.filter(product => product?.package_product_type !== "TECHNICAL_OFFER");
	return(<div className="col-12 my-3">
		<div className="row">
			{products?.map((option, index) => (
				<div key={`packages_to_be_deactivated_${index}`} className="col-12 col-sm-4 mb-1">
					<div className="bg-white border-info card text-dark h-100">
						<div className="card-body">
							<h5 className="card-title">{option?.name?.[intl.locale]}</h5>
							<p className="card-text">
								{option?.code}
							</p>
						</div>
					</div>
				</div>))}
		</div>
	</div>
	)
}
export {
	DeactivatedPackages
}