import { FormattedMessage, FormattedNumber } from "react-intl";
import { CommonMessages } from "../../Common.messages";
import { useState } from "react";
import { CircularProgress } from "./CircularProgress";
// import pdf from "../../static/img/generic_template_form.pdf";
import { config } from "../../config/constants";
import { ErrorMessageTemplate } from "./ErrorMessageTemplate";
import cssns from "../utils/cssnsConfig";
const { React } = cssns("UploadFiles");

interface UploadFilesProps {
    files: any[];
    uploadProgress: boolean;
    error?: string;
    setFiles: (files: []) => void;
    hideDocumentLink?: boolean;
    textInfo?: string;
    required?: boolean;
}

const UploadFiles = (props: UploadFilesProps) => {
	const [error, showError] = useState(false);
	const addFile = (e) => {
		const totalSizeFiles = props.files.reduce((acc, file) => file.size + acc, 0) + [...e.target.files].reduce((acc, file) => file.size + acc, 0);
		if(config.SIZE_5_MB > totalSizeFiles) {
			props.setFiles([...props.files, ...e.target.files] as any);
			error && showError(false);
		} else {
			showError(true);
		}
	};
	const onRemove = (index) => () => {
		props.setFiles(props.files.filter((_, i) => i !== index) as any);
	};
	return (
		<div className="this form-group">
			{/* {!hideDocumentLink && <div className="w-action-item-link mb-3">
				<a href={pdf} download="generic_template_form">
					<FormattedMessage {...CommonMessages.downloadContract} />
				</a>
			</div>} */}
			<label className={props.required ? "w-required-field" : ""}><FormattedMessage {...CommonMessages.recipientFiles} /></label>
			{props.textInfo && <div className="alert alert-info">{props.textInfo}</div>}
			<div className="custom-file">
				<input
					className="custom-file-input"
					value={""}
					accept={config.UPLOAD_FILE_TYPE}
					id="multiupload"
					type='file'
					multiple
					onChange={addFile}
				/>
				<label className="custom-file-label" htmlFor="multiupload">
					<FormattedMessage {...CommonMessages.chooseFile} />
				</label>
			</div>
			<div className="mt-1">
				<ErrorMessageTemplate show={error}>
					<FormattedMessage {...CommonMessages.filesLimit} values={{size: 5}} />
				</ErrorMessageTemplate>
			</div>
			{props?.files?.map((item, index )=> (
				<div key={item.name} className="w-tile w-tile-block mt-2 bg-gray-100">
					<div className="w-tile-stripe"></div>
					<div className="w-tile-body">
						<h6 className="mb-0">
							<a href={URL.createObjectURL(item)} target="_blank" rel="noreferrer">{item.name}</a>
						</h6>
						<small className="text-muted">
							<FormattedNumber value={item.size} style='unit' unit='kilobyte' unitDisplay='short'/>
						</small>
					</div>
					<div className="w-tile-actions flex-column justify-content-center">
						{props.uploadProgress && <CircularProgress showText={false}/>}
					</div>
					<div className="w-tile-actions flex-column justify-content-center">
						<a className="ng-tns-c0-0" onClick={onRemove(index)}>
							<i className="fa fa-trash-alt btn-icon-left"></i>
							<FormattedMessage {...CommonMessages.removeFile} />
						</a>
					</div>

				</div>
			))}
			<ErrorMessageTemplate show={props.error}>
				{props.error}
			</ErrorMessageTemplate>
		</div>
	);
};

export {
	UploadFiles
};
