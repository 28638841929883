import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FMCMessages } from "../../../modules/fmc/FMC.messages";
import { createMarkup } from "../../utils/createMarkup";
import { RECURRENT, SINGLE } from "../../../config/constants";
import { PackagePrices } from "../../../modules/packages/PackagePrice";
import { Package, Maybe } from "../../../graphql/types";

interface OptionsCardProps {
    packages?: Maybe<Package>[] | null;
    externalPackages?: any;
}

const OptionsCard = (props: OptionsCardProps) => {
	const intl = useIntl();
	const packages = props.packages;

	return (
		<div>
			{Boolean(props?.packages?.length) && <h4><FormattedMessage {...FMCMessages.options} /></h4>}
			<div className="row">
				{props.packages?.map((option) => (
					<div key={option?.name?.en!} className="col-12 col-sm-4 mb-1">
						<div className="bg-white border-info card text-dark h-100">
							<div className="card-body">
								<h5 className="card-title">{option?.name?.[intl.locale]}</h5>
								<p className="card-text" dangerouslySetInnerHTML={createMarkup(
                                    option?.medium_description?.[intl.locale] ||
                                    option?.medium_description?.[intl.locale])}>
								</p>
							</div>
							<div className="card-footer text-success">
								{option?.fees?.map((item,index) => (
									<div key={index}>
										{item?.fee_type === RECURRENT &&
                                    <PackagePrices
                                    	fee={item?.fee}
                                    	fee_type={RECURRENT}
                                    	periodic_amount={option?.periodic_amount}
                                    	periodic_unit={option?.periodic_unit}
                                    />}
										{item?.fee_type === SINGLE &&
                                    <PackagePrices
                                    	fee={item.fee}
                                    	fee_type={SINGLE}
                                    />}
									</div>
								))}
								{option?.fee && (
									<div key={option?.fee!}>
										{option?.fee_type === RECURRENT &&
                                    <PackagePrices
                                    	fee={Number(option?.fee)}
                                    	fee_type={RECURRENT}
                                    	periodic_amount={option?.periodic_amount}
                                    	periodic_unit={option?.periodic_unit}
                                    />}
										{option?.fee_type === SINGLE &&
                                    <PackagePrices
                                    	fee={Number(option?.fee)}
                                    	fee_type={SINGLE}
                                    />}
									</div>
								)}
							</div>
						</div>
					</div>
				))}
				{props.externalPackages}
			</div>
		</div>
	);
};

export { OptionsCard };
