import { config, ICC_LENGTH } from "../../config/constants";

const digitalAndTextExp = /^([0-9A-Za-z\u0400-\u04FF])*$/;
const textExp = /^([A-Za-z\u0400-\u04FF])*$/;
const namingExp = /^[A-Za-z\u0400-\u04FF'-\s]+$/;
const emailExp = /[^@]+@[^@.]+\.[^@.]+/;
const digitalExp = /^[0-9,-]*$/;
const maxIccWithoutPrefix = ICC_LENGTH - config.DEFAULT_ICC_PREFIX.length;
const phoneExp = /^([0-9-+()]{9,}\,?\s?)*$/;
const intExp = (from: number, to: number) => new RegExp("^[0-9]{" + from +","+ to +"}$");
const onlyDigitsExp = new RegExp("^([0-9,X])*$");
const phoneUkrExp = /^38(0(50|63|65|66|67|68|73|75|77|91|92|93|94|95|96|97|98|99)\d{7})$/;
const passwordExp = /^[A-Z]{2}[0-9]{6}$/;
const namingFIOExp = /^[A-ZА-ЯЁЩЬЮҐЄІЇa-zа-яёщьюґєії\`\'\-\s]*[A-ZА-ЯЁЩЬЮҐЄІЇa-zа-яёщьюґєії]{1}$/;
const firstCapitalExp = /^[A-ZА-ЯЩЬЮҐЁЄІЇ].*$/;
const REMOVE_SPECIAL_CHARACTER = /[^a-zA-Z0-9]/g;

export {
	digitalAndTextExp,
	namingExp,
	passwordExp,
	emailExp,
	phoneUkrExp,
	namingFIOExp,
	firstCapitalExp,
	textExp,
	digitalExp,
	intExp,
	phoneExp,
	maxIccWithoutPrefix,
	onlyDigitsExp,
	REMOVE_SPECIAL_CHARACTER,
};
