import gql from "graphql-tag";

const VALIDATE_ICC = gql`
    query ValidateIcc($icc: ID!, $offer_id: ID!) {
        validate_icc(icc: $icc, offer_id: $offer_id) {
            status,
            title,
            error,
        }
    }
`;

export {
	VALIDATE_ICC
};
