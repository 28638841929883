import React, { FC } from "react";
import { useStoreContext } from "./navigation/StepStateProvider";
interface ErrorMessageTemplateProps {
    show: string|boolean|undefined;
    children: any;
}
interface ErrorMessageProps {
    error: string|boolean|undefined;
}
interface ErrorsMessageProps {
    errors: string[];
}
const ErrorMessageTemplate = (props: ErrorMessageTemplateProps) =>  {
	return (props.show ?
		<div className="alert alert-danger d-flex justify-content-between align-items-center w-100 mt-2">
			<div>
				<i className="fas fa-exclamation-circle"></i>
				{props.children}
			</div>
		</div> : null
	);
};

const ErrorMessage = (props: ErrorMessageProps) =>  {
	return (props.error ?
		<div className="alert alert-danger d-flex justify-content-between align-items-center w-100 mt-2">
			<div>
				<i className="fas fa-exclamation-circle"></i>
				{props.error}
			</div>
		</div> : null
	);
};
const ErrorMessageList = (props: ErrorsMessageProps) =>  {
	return (props.errors.length > 0 ?
		<div className="alert alert-danger d-flex justify-content-between align-items-center w-100 mt-2">
			<div>
				<i className="fas fa-exclamation-circle"></i>
				{props.errors.join("; ")}
			</div>
		</div> : null
	);
};




export {
	ErrorMessageTemplate,
	ErrorMessage,
	ErrorMessageList,
};
