import React from "react";
import { FormattedMessage } from "react-intl";
import { CommonMessages } from "../../../Common.messages";
import { CustomerNavigationPro } from "./CustomerNavigationPro";
import { useStoreContext } from "./StepStateProvider";

interface NavigationHeaderProps {
    back: () => void;
    formId: string;
    isValid: boolean;
}

const FormNavHeader = React.memo((props: NavigationHeaderProps) => {
	const { formId, back, isValid } = props;
	const {state: {
		isFlowDone
	}} = useStoreContext();
	return (<>
		<header className="ipos-flow-header">
			<nav className="ipos-flow-header-buttons">
				{!isFlowDone && (<button type="button" className="btn btn-outline-primary" onClick={back}>
					<FormattedMessage {...CommonMessages.back} />
				</button>)}
				<button disabled={!isValid} form={formId} type="submit" className="btn btn-primary">
					<FormattedMessage {...CommonMessages.next} />
				</button>
			</nav>
			<CustomerNavigationPro/>
		</header>
	</>
	);
});

export { FormNavHeader };