import React, { useCallback, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { FormattedMessage } from "react-intl";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {
	ACQUISITION_FLOW_ICCID_PATH,
	ACQUISITION_FLOW_MSISDN_PATH,
	ACQUISITION_FLOW_PLAN_PATH
} from "../../Routes/AcquisitionFlowRoutes";
import { Maybe, Package, ProductFamily, Query } from "../../../../../graphql/types";
import {GET_OPTIONAL_CHILD_PRODUCTS, GET_OPTIONAL_PRODUCTS} from "../../../../../graphql/queries/acquisitionFlow";
import { ChangePlanMessages } from "../../../../change-bar/plan/ChangePlan.messages";
import { OptionsFamilies } from "./OptionsFamilies";
import { OptionProductChecked } from "./OptionProduct";
import { CircularProgress, NavigationHeader } from "../../../../../shared/components";
import { AcquisitionFlow } from "../../Routes/AcquisitionFlow";
import { AcquisitionFlowState } from "../SummaryPage/SummaryPage";
import { prepareUncheckClick } from "../../../../../shared/utils";

const OptionsPage = (props: RouteComponentProps<never, StaticContext, AcquisitionFlowState>) => {
	const { history, location } = props;
	const {state} = location;
	const [optionalProduct, setOptionalProduct] = useState<Package[]>(state?.options || []);

	const {data, loading} = useQuery<Query>(GET_OPTIONAL_PRODUCTS, {
		variables: { subscription_type_id: state?.plan?.id },
	});

	const [getChildOffers, {data: childOffersData, loading: loadingChildOffers}] = useLazyQuery<Query>(GET_OPTIONAL_CHILD_PRODUCTS);

	const onClickNext = useCallback(async() => {
		history.push({
			pathname: ACQUISITION_FLOW_ICCID_PATH,
			state: {
				...state,
				options: optionalProduct
			}
		});
	}, [optionalProduct]);

	const onClickBack =() => history.push({ pathname: ACQUISITION_FLOW_PLAN_PATH, state});
	// redirect to MSISDN page if msisdn is empty
	if (!state?.msisdn) {
		history.push(ACQUISITION_FLOW_MSISDN_PATH);
	}

	const [isParentChecked, setIsParentChecked] = useState<boolean>(false);
	const [parentCode, setParentCode] = useState<string | null | undefined>("");
	const onChecked = (value: OptionProductChecked) => {
		setIsParentChecked(value.checked);
		setParentCode(value.optionalProduct.code);
		getChildOffers({ variables: { subscription_type_id: state?.plan?.id, parent_product: Number(value.optionalProduct.id), relation_type: "all" }});
		if (value.checked) {
			setOptionalProduct([...optionalProduct, value.optionalProduct]);
		} else {
			if (!loadingChildOffers) {
				setOptionalProduct(optionalProduct.filter((pack) => {
					return  prepareUncheckClick(pack, value, childOffersData);
				}));
			}
		}
	};

	const onChildChecked = (value: OptionProductChecked) => {
		if (value.checked) {
			setOptionalProduct([...optionalProduct, value.optionalProduct]);
		} else {
			setOptionalProduct(optionalProduct.filter((pack) => pack.code !== value.optionalProduct.code));
		}
	};
	const available_child_offers = childOffersData?.child_offers;

	return (
		<>
			<NavigationHeader FlowComp={AcquisitionFlow} next={onClickNext} back={onClickBack}/>
			<div className="ipos-content-box">
				{loading ? <CircularProgress/> : !(data?.optional_products?.length! > 0) &&
                    <div className="column">
                    	<FormattedMessage {...ChangePlanMessages.optionalProductsNotFound} />
                    </div>
				}
				<table className="table w-tile-table">
					{data?.optional_products?.map((family: Maybe<ProductFamily>) => (
                        family?.packages?.length && family.packages.length > 0) &&
                            <OptionsFamilies
                            	key={family.id!}
                            	family={family}
                            	onChecked={onChecked}
                            	isParentChecked={isParentChecked}
                            	childOffersData={available_child_offers}
                            	parentCode={parentCode}
								onChildChecked={onChildChecked}
								loadingChildOffers={loadingChildOffers}
                            	selectedOptionalProduct={optionalProduct}
                            />
					)}
				</table>
			</div>
		</>
	);
};

export { OptionsPage };
