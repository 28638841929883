import React, { FC, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { CircularProgress } from "../../../../shared/components";
import { useQuery } from "@apollo/react-hooks";
import { Query, QueryMsisdnArgs, SubscriptionTypeCategoryEnum } from "../../../../graphql/types";
import { SelectedTariffPlanDetails } from "../../../../graphql/localTypes";
import { FormattedMessage } from "react-intl";
import { FTTBMessages } from "../../../fttb/FTTB.messages";
import { StepWrapper } from "../../../../shared/components/navigation/StepWrapper";
import {B2BFttbRouteState} from "./b2bFttb.type";
import {GET_B2B_FTTB_OFFERING} from "../../../../graphql/queries/acquisitionFlow";
import {B2BFttbPlanRowList} from "../../../change-bar/plan/B2BFttbPlanRowList";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";

const Plan: FC<RouteComponentProps<never, StaticContext, B2BFttbRouteState>> = props => {
	const state = useNavigationState<B2BFttbRouteState>();

	const [b2bFttbState, setB2bFttbState] = useState<B2BFttbRouteState|undefined|null>(state);

	const {data, loading} = useQuery<Required<Pick<Query, "subscription_types_by_type_category">>, QueryMsisdnArgs>(GET_B2B_FTTB_OFFERING, {
		fetchPolicy: "cache-and-network",
	});

	const handleSelectTariffPlan = (plan: SelectedTariffPlanDetails) => {
		setB2bFttbState({
			...state,
			selectedFttbTariffPlan: plan,
		});
	};

	const tariffPlans = data?.subscription_types_by_type_category?.filter(item => item?.subscription_type_category === SubscriptionTypeCategoryEnum.B2BFTTB);

	return (
		<StepWrapper<B2BFttbRouteState>
			isValid={!loading && !!b2bFttbState?.selectedFttbTariffPlan?.code}
			newState={{selectedFttbTariffPlan: b2bFttbState?.selectedFttbTariffPlan}}>
			<div className="row">
				<div className="col-12">
					{!loading && !(tariffPlans?.length && tariffPlans.length > 0) &&
					<div><FormattedMessage {...FTTBMessages.plansNotFound} /></div>}
					{tariffPlans?.map(subscription_types => subscription_types?.code &&
						(<B2BFttbPlanRowList
							key={subscription_types?.code + "top-level"}
							tariffPlan={subscription_types}
							selectTariffPlan={handleSelectTariffPlan}
							selected={b2bFttbState?.selectedFttbTariffPlan?.code === subscription_types.code}
							selectedPlan={b2bFttbState?.selectedFttbTariffPlan}
						/>))}
					{loading && (<CircularProgress/>)}
				</div>
			</div>
		</StepWrapper>
	);
};

export {
	Plan,
};