import { OtpModal } from "../../graphql/localTypes";

const OTP_MODAL_ID = "otp-modal";
const MODALS_ID = "modals";

class ModalsUtils {
	static getOtpId(getCacheKey: (arg0: { __typename: string; id: string; }) => void): void {
		getCacheKey({ __typename: "OtpModal", id: OTP_MODAL_ID });
	}
	static getInitOtp (): {otp: OtpModal} {
		return {
			otp: {
				msisdn: "",
				status: false,
				loading: false,
				error: "",
				code: "",
				__typename: "OtpModal",
			} as OtpModal,
		};
	}
}

export {
	ModalsUtils,
	OTP_MODAL_ID,
	MODALS_ID,
};
