import React, { FC, useCallback, useMemo, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import {
	ACQUISITION_EXISTING_FLOW_CONTACT_INFO_PATH,
} from "../../Router/AcquisitionExistingFlowPath";
import { useMutation } from "@apollo/react-hooks";
import { CircularProgress } from "../../../../../shared/components/CircularProgress";
import { FormattedMessage, useIntl } from "react-intl";
import { CommonMessages } from "../../../../../Common.messages";
import {
	ErrorMessageTemplate,
	SuccessMessageTemplate,
	NavigationHeader,
	DocumentGeneration,
	UploadFiles
} from "../../../../../shared/components";
import { Mutation, Package, Customer, DeliveryTypeEnum, ProductTypeEnum, SalesTypeEnum, MutationCreate_Retention_OrderArgs, SubscriptionInput } from "../../../../../graphql/types";
import AcquisitionMessages from "../../../Acquisition.messages";
import { useAcquisitionUploadDocument } from "../../../../../shared/hooks/useAcquisitionUploadDocument";
import {
	genExistingCustomerGenerateDocument,
	prepareExistingCustomerForCreateOrder
} from "../../../../../shared/utils/acquisitionFlowUtils";
import { INDEX_PATH } from "../../../NewCustomer/Routes/AcquisitionFlowRoutes";
import { useAuthDataContext } from "../../../../../shared/hooks/AuthentificationProvider";
import { AcquisitionExistingFlow } from "../../Router/AcquisitionExistingFlow";
import { SelectedTariffPlanDetails } from "../../../../../graphql/localTypes";
import { PlanCard } from "../../../../fmc/Pages/Summary/PlanCard";
import { OptionsCard } from "../../../../../shared/components/option/OptionsCard";
import { PayloadProps } from "../../../../../shared/hooks/useDocumentGeneration";
import { CREATE_RETENTION_ORDER } from "../../../../../graphql/mutations/order";
import { REMOVE_SPECIAL_CHARACTER } from "../../../../../shared/utils";
import { msisdnActions, useStoreContext } from "../../../../../shared/components/navigation/StepStateProvider";
import {BILLING_NEW_ACCOUNT} from "../../../../../config/constants";

interface CustomerRouteState extends Customer {
    last_name_person?: string;
    first_name_person?: string;
    middle_name_person?: string;
    attorney_number?: string;
    authorized: boolean;
}
export interface ExistingCustomerRouteState {
    id_number?: string;
    id_type?: string;
    iccId?: string;
    billing_status?: string;
    msisdn?: string;
    account_id?: string;
    plan?: SelectedTariffPlanDetails;
    options?: Package[];
    customer?: CustomerRouteState;
    reservationId?: string;
	billing_type?: string;
}

const Summary: FC<RouteComponentProps<never, StaticContext, ExistingCustomerRouteState>> = props => {
	const { location: { state }, history } = props;
	const intl = useIntl();
	const {dispatch} = useStoreContext();
	const { userName, currentDealerIdentety} = useAuthDataContext();
	const [spinner, runSpinner] = useState<boolean>(false);
	const [documentUploadSuccess, setDocumentUploadSuccess] = useState<boolean>(false);
	const [errorMsg, setError] = useState<string>("");
	const [order, createdOrderFor] = useState<string>("");
	const {msisdn, plan, iccId, options, reservationId, customer} = state;
	const customerInfo = prepareExistingCustomerForCreateOrder(customer, intl.locale);
	const textInfo = useMemo(() => intl.formatMessage(AcquisitionMessages.infoMessageUploadDocument), [intl.locale]);
	const contractPayload = {
		...genExistingCustomerGenerateDocument(customer!, intl.locale),
		icc: iccId,
		tariffPlanName: plan?.code,
		tariffPlanType: plan?.planType,
		msisdnReservationCode: reservationId,
		salesman_username: userName,
		billing_account: state?.account_id,
		isNewBA: (state?.billing_status === BILLING_NEW_ACCOUNT)
	} as PayloadProps;

	const [uploadDocument, files, setFiles, uploadProgress, error] = useAcquisitionUploadDocument();
	const [createOrder] = useMutation<Required<Pick<Mutation, "create_retention_order">>, MutationCreate_Retention_OrderArgs>(CREATE_RETENTION_ORDER, {
		onError: () => {
			setError(intl.formatMessage(CommonMessages.createOrderFail, {msisdn}));
		}
	});

	const optionCodes: (string|null)[] = options?.filter((item: Package) => item.code)?.map(item => item.code!) || [];
	const productSubscription = (): SubscriptionInput => {
		return {
			msisdn: msisdn || "",
			type: plan?.code || "",
			packages: optionCodes,
			product_type: ProductTypeEnum.Subscription,
			payer: customerInfo,
			user: customerInfo,
			reservation_id: reservationId || "",
		};
	};
	const onClickBack = useCallback(() => history.push(ACQUISITION_EXISTING_FLOW_CONTACT_INFO_PATH, state), []);
	const onClickDone = useCallback(async (): Promise<any> => {
		history.push(INDEX_PATH, {});
	}, []);
	const confirmationCode = reservationId?.replace(REMOVE_SPECIAL_CHARACTER, "");
	const onSubmit = () => {
		runSpinner(true);
		setDocumentUploadSuccess(false);
		setError("");
		const billing_account = { billing_account: { id: state?.account_id, type: state?.billing_type || plan?.billing_type }};
		createOrder({
			variables: {
				details: {
					offer: plan?.offerCode || "",
					delivery_type: DeliveryTypeEnum.Direct,
					customer: customerInfo,
					confirmation_code: confirmationCode || "",
					sales_info: {
						sales_type: SalesTypeEnum.Acquisition,
						reseller_code: currentDealerIdentety?.reseller_code
					},
					products: {
						subscription: productSubscription(),
						sim: {
							product_type: ProductTypeEnum.Simcard,
							type: plan?.offerSimCardCode!,
							icc: iccId || "",
							msisdn: {
								msisdn: msisdn || "",
							},
							reservation_id: reservationId || "",
						}
					},
					...billing_account,
				}
			}}).then(({data}) => {
			if (data?.create_retention_order?.status === 202 ) {
				if (files.length > 0) {
					uploadDocument({
						purpose: "ACQUISITION_FLOW",
						confirmation_code: reservationId!
					}, () => {
						setDocumentUploadSuccess(true);
						runSpinner(false)
					}, () => runSpinner(false));
				} else {
					runSpinner(false);
				}
				createdOrderFor(msisdn!);
			} else if (data?.create_retention_order?.error) {
				throw data?.create_retention_order?.error;
			} else {
				throw intl.formatMessage(CommonMessages.createOrderFail, {msisdn});
			}
			dispatch(msisdnActions.removeMsisdn(msisdn!));
		}).catch((message) => {
			runSpinner(false);
			setError(message);
		});
	};
	const buttonStatus = (): React.ReactNode => {
		return Boolean(order) ? (<button type="button" className="btn btn-primary" onClick={onClickDone}>
			<FormattedMessage {...CommonMessages.done} />
		</button>) : (<button
			type="button"
			disabled={spinner}
			className="btn btn-primary" onClick={onSubmit}>
			<FormattedMessage {...CommonMessages.submit} />
		</button>);
	};

	
	return (
		<>
			<NavigationHeader
				FlowComp={AcquisitionExistingFlow}
				next={onClickDone}
				back={onClickBack}
				hide={Boolean(order)}
			/>
			<div className="ipos-content-box SummaryPage">
				<div className="row">
					<div className="col-12 col-md-4">
						<h4><FormattedMessage {...CommonMessages.msisdn} />: {state?.msisdn}</h4>
					</div>
					<div className="col-12 col-md-4">
						<h4><FormattedMessage {...CommonMessages.icc} />: {state?.iccId}</h4>
					</div>
					<div className="col-12">
						<PlanCard
							change_price={state?.plan?.change_price!}
							fee={state?.plan?.fee!}
							periodic_unit={state?.plan?.periodicUnit!}
							periodic_amount={state?.plan?.periodicAmount!}
							name={state?.plan?.name!}
							medium_description={state?.plan?.medium_description}
							long_description={state?.plan?.long_description}
						/>
					</div>
					<div className="col-12 my-3">
						<OptionsCard packages={state?.options}/>
					</div>
				</div>
				{!errorMsg && <DocumentGeneration
					skipCustomerData={false}
					payload={contractPayload}
					msisdn={msisdn}
					setContractGenerationError={setError}
				/>}
				<UploadFiles
					setFiles={setFiles}
					files={files}
					uploadProgress={uploadProgress}
					error={error}
					hideDocumentLink={true}
					textInfo={textInfo}
				/>
				{Boolean(order) && (<SuccessMessageTemplate show={true}>
					<FormattedMessage {...CommonMessages.createOrderSuccess} values={{order}}/>
				</SuccessMessageTemplate>)}
				{documentUploadSuccess && (<SuccessMessageTemplate show={true}>
					<FormattedMessage {...CommonMessages.documentUploadSuccess} values={{order}}/>
				</SuccessMessageTemplate>)}
				<ErrorMessageTemplate show={errorMsg}>{errorMsg}</ErrorMessageTemplate>
				<div className="text-right">
					{spinner && (<span className="pr-2"><CircularProgress showText={false}/></span>)}
					{buttonStatus()}
				</div>
			</div>
		</>);
};

export { Summary };
