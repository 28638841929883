import React, { FC, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { FormattedMessage } from "react-intl";
import { useApolloClient } from "@apollo/react-hooks";
import { Query, LineTypeEnum,  Maybe, QueryOtp_SubscriptionArgs, Subscription } from "../../../../graphql/types";
import { FMCMessages } from "../../FMC.messages";
import { SubscriptionStatus } from "../../../../shared/types/SubscriptionStatus";
import { CurrentPlanSummary } from "../../../../shared/components/tariffPlan/CurrentPlanSummary";
import { FmcContactPhoneInfo } from "../../../../shared/components/phone/FmcContactPhoneInfo";
import { FmcRouteState } from "../../fmc.type";
import { SearchMsisdn } from "../../../../shared/components/SearchMsisdn";
import { errorActions, fmcActions, useStoreContext } from "../../../../shared/components/navigation/StepStateProvider";
import { StepWrapper } from "../../../../shared/components/navigation/StepWrapper";
import { NoFttbCheckbox } from "../../NoFttbCheckbox";
import { SUBSCRIPTION_QUERY } from "../../../../graphql/queries/subscription";
import { TARIFF_PLAN_TYPE_POSTPAID } from "../../../acquisitionFlow/NewCustomer/Pages";
import { PackageUtils } from "../../../../shared/utils/Package.utils";
import { MsisdnErrorsUtils } from "../../../../shared/utils/MsisdnErrorsUtils.utils";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";

const SearchGsm: FC<RouteComponentProps<never, StaticContext, FmcRouteState>> = props => {
	const state = useNavigationState<FmcRouteState>();
	const client = useApolloClient();

	const [gsmSubscription, setGsmSubscription] = useState<Maybe<Subscription>|undefined|null>(state?.gsmSubscription);
	const [fttbSubscription, setFttbSubscription] = useState<Maybe<Subscription>|undefined|null>(state?.fttbSubscription);
	const {dispatch, state: {fmc}} = useStoreContext();


	const searchMsisdn = (msisdn: string, otp: string): Promise<string|void|Required<Pick<Query, "otp_subscription">>> => {
		return client.query<Required<Pick<Query, "otp_subscription">>, QueryOtp_SubscriptionArgs>({
			query: SUBSCRIPTION_QUERY,
			fetchPolicy: "network-only",
			variables: {
				msisdn,
				otp
			}
		}).then(({data}) => {
			if (!data) {
				return "erorr";
			}
			const subscription = data?.otp_subscription?.subscription;

			const lineType = subscription?.subscription_type?.line_type;

			const availaleStatues = [SubscriptionStatus.ACTIVE as string, SubscriptionStatus.SUSPENDED as string];
			if (!subscription?.id ||!(subscription?.status && availaleStatues.includes(subscription?.status)) || LineTypeEnum.Fmc === lineType) {
				dispatch(errorActions.setError("fmcNotPossible"));
			}

			if (lineType === LineTypeEnum.Mobile) {
				gsmSubscription?.msisdn && dispatch(errorActions.cleanMsisdnError(gsmSubscription?.msisdn));

				MsisdnErrorsUtils.validate(subscription?.subscription_type?.payment_type?.toLowerCase() === TARIFF_PLAN_TYPE_POSTPAID, "fmcNotAllowedForPostpaid")
					.next(subscription?.is_portout_ongoing, "msisdnPortingRequestOngoing")
					.next(subscription?.is_temporary_msisdn, "isTemporaryMsisdn")
					.dispatch(msisdn, dispatch);

				setGsmSubscription(data?.otp_subscription?.subscription);
			}
			if (lineType === LineTypeEnum.Fttb) {
				dispatch(fmcActions.setWithoutFttb(false));
				fttbSubscription?.msisdn && dispatch(errorActions.cleanMsisdnError(fttbSubscription?.msisdn));

				PackageUtils.handleFttbErrors(data?.otp_subscription?.subscription?.active_packages, dispatch, msisdn);
				MsisdnErrorsUtils.validate(subscription?.subscription_type?.payment_type?.toLowerCase() === TARIFF_PLAN_TYPE_POSTPAID, "fmcNotAllowedForPostpaid")
					.next(!subscription?.owner?.contact_phone, "noContactPhoneErorr")
					.next(subscription?.is_tv_tuner_debt, "tvTunerDebt")
					.dispatch(msisdn, dispatch);

				setFttbSubscription(data?.otp_subscription?.subscription);
			}
			return data;
		});
	};


	const handleResetSearch = () => {
		setGsmSubscription(undefined);
		setFttbSubscription(undefined);
	};

	return (
		<StepWrapper<FmcRouteState>
			isValid={Boolean((fmc?.withoutFttb || fttbSubscription) && gsmSubscription)}
			newState={{
				fttbSubscription,
				gsmSubscription,
				isNoFttbSubscription: fmc?.withoutFttb
			}}>
			<h2><FormattedMessage {...FMCMessages.search} /></h2>
			<div className="form-row bg-light p-4">
				<NoFttbCheckbox
					cleanFttb={() => setFttbSubscription(undefined)}/>
			</div>
			<SearchMsisdn
				handleResetSearch={handleResetSearch}
				onCompleted={searchMsisdn}/>
			{gsmSubscription && <><div className="col-12 col-md-6">
				<h4><FormattedMessage {...FMCMessages.gsmSubscription} /></h4>
			</div>
			<div className="form-row mb-5">
				<FmcContactPhoneInfo msisdn={gsmSubscription?.msisdn}/>
			</div>
			<CurrentPlanSummary
				tariffPlan={gsmSubscription?.subscription_type}
			/>
			</>}

			{fttbSubscription && <><div className="col-12 col-md-6">
				<h4><FormattedMessage {...FMCMessages.fttbSubscription} /></h4>
			</div>
			<div className="row">
				<FmcContactPhoneInfo
					contactPhone={fttbSubscription?.owner?.contact_phone}
					msisdn={fttbSubscription?.msisdn}/>
			</div>
			<CurrentPlanSummary
				tariffPlan={fttbSubscription?.subscription_type}
			/>
			</>}
		</StepWrapper>
	);
};

export { SearchGsm };
