import React from "react";
import { FormattedMessage } from "react-intl";
import { FooterMessages } from "./Footer.messages";

function Copyright() {
	return (<>
		{"Copyright © "}
		<a href="/">
			<FormattedMessage  {...FooterMessages.title}/>
		</a>{" "}
		{new Date().getFullYear()}
		{"."}
	</>);
}
const Footer: React.FC = () => (
	<footer className="w-app-footer">
		<Copyright />
	</footer>
);

export { Footer };
