import { StepConfig } from "../../components/navigation/StepStateProvider";

export interface RouteConfig {
    [key: string]: {
        step: number;
        message: string;
        link: () => void;
    };
}

const prepareClasses = (pathname: string, paths: RouteConfig, routeLength: number, pathArray: readonly string[]): [Record<string, string>, string]  => {
	const currentStep: string|undefined = pathname.substring(routeLength).split("/").pop();
	if (!currentStep) {
		return [{} as Record<string, string>, ""];
	}
	const activeIndex = paths?.[currentStep]?.step;
	const classes = {};
	pathArray.forEach((value, i) => {
		const index = i + 1;
		let className = "w-wizard-step";
		if(index < activeIndex) {
			className += " w-wizard-step-past";
		} else if(index > activeIndex) {
			className += " w-wizard-step-future";
		} else {
			className += " w-wizard-step-current";
		}
		classes[value] = className;
	});
	return [classes, currentStep];
};


export interface CurrentStep {
	index: number;
	message: string;
	pathname: string;
}
const prepareStepClasses = (currentPathName: string, paths: StepConfig[]): [Record<string, string>, CurrentStep|undefined]  => {

	if (!currentPathName || !paths) {
		return [{} as Record<string, string>, undefined];
	}
	const classes = {};
	const activeIndex = paths.findIndex((element) => element.pathname === currentPathName) + 1;
	const currentStep = {
		...paths[activeIndex],
		index: activeIndex
	};
	paths.forEach((value, i) => {
		
		const index = i + 1;
		let className = "w-wizard-step";
		if(index < activeIndex) {
			className += " w-wizard-step-past";
		} else if(index > activeIndex) {
			className += " w-wizard-step-future";
		} else {
			className += " w-wizard-step-current";
		}
		classes[value.pathname] = className;
	});
	return [classes, currentStep];
};


export {
	prepareClasses,
	prepareStepClasses,
};
