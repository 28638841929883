/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface UnitOfTimeMessagesType {
	DAY: MessageDescriptor;
	DAYS: MessageDescriptor;
	END_OF_DAY: MessageDescriptor;
	HOUR: MessageDescriptor;
	HOURS: MessageDescriptor;
	MONTH: MessageDescriptor;
	MONTHS: MessageDescriptor;
	WEEK: MessageDescriptor;
	WEEKS: MessageDescriptor;
	YEAR: MessageDescriptor;
	YEARS: MessageDescriptor;
}
const UnitOfTimeMessages: UnitOfTimeMessagesType = defineMessages({
	DAY: {
		id: "unit-of-time-period-day",
		description: "Unit of time: day",
		defaultMessage: "day"
	},
	DAYS: {
		id: "unit-of-time-period-days",
		description: "Unit of time: days",
		defaultMessage: "days"
	},
	END_OF_DAY: {
		id: "unit-of-time-period-end-of-days",
		description: "Unit of time: days",
		defaultMessage: "days"
	},
	HOUR: {
		id: "unit-of-time-period-hours",
		description: "Unit of time: hour",
		defaultMessage: "hour"
	},
	HOURS: {
		id: "unit-of-time-period-hour",
		description: "Unit of time: hours",
		defaultMessage: "hours"
	},
	MONTH: {
		id: "unit-of-time-period-month",
		description: "Unit of time: month",
		defaultMessage: "month"
	},
	MONTHS: {
		id: "unit-of-time-period-months",
		description: "Unit of time: months",
		defaultMessage: "months"
	},
	WEEK: {
		id: "unit-of-time-period-week",
		description: "Unit of time: week",
		defaultMessage: "week"
	},
	WEEKS: {
		id: "unit-of-time-period-weeks",
		description: "Unit of time: weeks",
		defaultMessage: "weeks"
	},
	YEAR: {
		id: "unit-of-time-period-year",
		description: "Unit of time: year",
		defaultMessage: "year"
	},
	YEARS: {
		id: "unit-of-time-period-years",
		description: "Unit of measure short: years",
		defaultMessage: "years"
	},
});

export default UnitOfTimeMessages;
export { UnitOfTimeMessages };
