import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { CustomerNavigation } from "../../../shared/components/CustomerNavigation";
import { iposRoutes, mnpFlowRoutesConfig } from "../../../routes/RoutesMap";
import { MNP_FLOW_MSISDN_PATH, MNP_FLOW_TEMPORARY_MSISDN_PATH, MNP_FLOW_PLAN_PATH, MNP_FLOW_CONTACT_INFO_PATH, MNP_FLOW_OPTION_PATH, MNP_FLOW_SUMMARY_PATH, MNPRouteState } from "../MNPFlowRoutes";
import { MnpMessages } from "../Mnp.messages";
import AcquisitionMessages from "../../acquisitionFlow/Acquisition.messages";
import { useLocation } from "react-router-dom";

const lengthMainPath = iposRoutes.MNP_FLOW.createLink().length;


const MNPFlow: FC = () => {
	const history = useHistory();
	const {
		pathname,
		state,
	} = useLocation<MNPRouteState>();
	const intl = useIntl();

	const routesIndexesConfig = {
		[mnpFlowRoutesConfig[0]]: {
			message: intl.formatMessage({...MnpMessages.mnpMsisdn}),
			link: () => history.push(MNP_FLOW_MSISDN_PATH, state),
			step: 1,
		}, [mnpFlowRoutesConfig[1]]: {
			message: intl.formatMessage({...MnpMessages.temporaryMsisdn}),
			link: () => history.push(MNP_FLOW_TEMPORARY_MSISDN_PATH, state),
			step: 2,
		}, [mnpFlowRoutesConfig[2]]: {
			message: intl.formatMessage({...AcquisitionMessages.plan}),
			link: () => history.push(MNP_FLOW_PLAN_PATH, state),
			step: 3,
		}, [mnpFlowRoutesConfig[3]]: {
			message: intl.formatMessage({...AcquisitionMessages.options}),
			link: () => history.push(MNP_FLOW_OPTION_PATH, state),
			step: 4,
		}, [mnpFlowRoutesConfig[4]]: {
			message: intl.formatMessage({...AcquisitionMessages.contactInfo}),
			link: () => history.push(MNP_FLOW_CONTACT_INFO_PATH, state),
			step: 5,
		}, [mnpFlowRoutesConfig[5]]: {
			message: intl.formatMessage({...AcquisitionMessages.summary}),
			link: () => history.push(MNP_FLOW_SUMMARY_PATH, state),
			step: 6,
		},

	};
	return (
		<div>
			<CustomerNavigation<typeof mnpFlowRoutesConfig>
				pathname={pathname}
				routesConfig={mnpFlowRoutesConfig}
				routesIndexesConfig={routesIndexesConfig}
				baseRouteLength={lengthMainPath}
			/>
		</div>
	);
};

export { MNPFlow };