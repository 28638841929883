import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ErrorBoundary } from "./shared/error/ErrorBoundary";
import { CustomIntlContextProvider } from "./shared/hooks/CustomIntlContextProvider";
import "./index.scss";
import "react-dates/initialize";

const rootEl = document.getElementById("w-app");

// eslint-disable-next-line react/no-render-return-value
const render = (Component: React.FC) => ReactDOM.render(
	<ErrorBoundary>
		<CustomIntlContextProvider>
			<Component/>
		</CustomIntlContextProvider>
	</ErrorBoundary>,
	rootEl
);

render(App);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
