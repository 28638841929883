import React, { FC, useCallback, useState } from "react";
import { Msisdn } from "../../../../../graphql/types";
import { config } from "../../../../../config/constants";
import { CircularProgress, NavigationHeader } from "../../../../../shared/components";
import { MsisdnSearchResult } from "../../../NewCustomer/Pages/MsisdnPage/MsisdnSearchResult";
import {
	ACQUISITION_EXISTING_FLOW_BILLING_PATH,
	ACQUISITION_EXISTING_FLOW_PLAN_PATH,
} from "../../Router/AcquisitionExistingFlowPath";
import { useGetMsisdn } from "../../../../../shared/hooks";
import { AcquisitionExistingFlow } from "../../Router/AcquisitionExistingFlow";
import { ExistingCustomerRouteState } from "../Summary/Summary";
import { useMsisdnReservation } from "../../../../../shared/hooks/useMsisdnReservation";
import { useHistory, useLocation } from "react-router-dom";

const MsisdnPage: FC = () => {
	const history = useHistory();
	const {state} = useLocation<ExistingCustomerRouteState>();
	const msisdn = state?.msisdn || "";
	const [newMsisdn, setNewMsisdn] = useState<string>(msisdn);
	const [data, loading] = useGetMsisdn();
	const reserveMsisdn = useMsisdnReservation(ACQUISITION_EXISTING_FLOW_PLAN_PATH, newMsisdn);

	const onClickNext = () => {
		reserveMsisdn();
	};
	const onClickBack = useCallback(() => history.push(ACQUISITION_EXISTING_FLOW_BILLING_PATH, state), []);

	let msisdns: Array<Msisdn | null> = [];
	if (data) {
		msisdns = data.filter((item) => (item?.number_category === config.MSISDN_NORMAL_CATEGORY && item?.msisdn));
		if (msisdn && !msisdns.find((item) => item?.msisdn === msisdn)) {
			msisdns.push({msisdn});
		}
	}
	return (
		<>
			<NavigationHeader
				FlowComp={AcquisitionExistingFlow}
				next={onClickNext}
				back={onClickBack}
			/>
			<div className="ipos-content-box">
				{loading ? <CircularProgress/> :
					<MsisdnSearchResult setNewMsisdn={setNewMsisdn} msisdns={msisdns} newMsisdn={newMsisdn}/>
				}
			</div>
		</>
	);
};

export { MsisdnPage };