import { InMemoryCache } from "apollo-cache-inmemory";
import { fragmentMatcher } from "./fragmentMatcher";
import { Storage } from "../shared/utils/storageUtils";
import {
	ConfirmationAction,
	LocalQuery,
	Modals,
	ModalStatus,
	SpinnerStatus,
} from "../graphql/localTypes";
import { context } from "./init/context";
import { STORAGE_KEY_IS_AUTHENTICATED } from "../config/constants";
import { STORAGE_KEY_CURRENT_DEALER_IDENTITY } from "../config/constants";
import { UserDealerIdentity } from "../graphql/types";
import { ModalsUtils } from "./utils/Modals.utils";

const cache = new InMemoryCache({ fragmentMatcher });
const initCurrentDealerIdentity: UserDealerIdentity = {
	__typename: "UserDealerIdentity",
	contact_number: "",
	reseller_code: "",
	reseller_name: "",
	chain_name: "",
};
const modals = {
	modals: {
		...ModalsUtils.getInitOtp(),
		__typename: "Modals",
	} as Modals,
};
const currentDealerIdentity = Storage.local.get(STORAGE_KEY_CURRENT_DEALER_IDENTITY)
const initCache = {
	...modals,
	modal: {
		__typename: "ModalStatus",
		status: false,
		message: "",
		title: "",
	} as ModalStatus,
	spinner: {
		__typename: "SpinnerStatus",
		status: false,
	} as SpinnerStatus,
	context,
	confirmationAction: {
		id: "confirmationAction",
		__typename: "ConfirmationAction",
		action: "",
		status: false,
		code: "",
		code3rdParty: null,
		remove_package: {
			__typename: "ActionDetails",
			id: "remove_package",
			codes: []
		},
		add_package: {
			__typename: "ActionDetails",
			id: "remove_package",
			codes: []
		}
	} as any as ConfirmationAction,
	auth: {
		__typename: "Auth",
		isAuthenticated: !!Storage.local.get(STORAGE_KEY_IS_AUTHENTICATED),
	},
	dealerIdentity: {
		...(currentDealerIdentity || initCurrentDealerIdentity),
	},
};

const initCleanCache = {
	modal: {
		__typename: "ModalStatus",
		status: false,
		message: "",
	} as ModalStatus,
	...modals,
	spinner: {
		__typename: "SpinnerStatus",
		status: false,
	} as SpinnerStatus,
	context,
	confirmationAction: {
		id: "confirmationAction",
		__typename: "ConfirmationAction",
		action: "",
		status: false,
		code: "",
		code3rdParty: null,
		remove_package: {
			__typename: "ActionDetails",
			id: "remove_package",
			codes: []
		},
		add_package: {
			__typename: "ActionDetails",
			id: "remove_package",
			codes: []
		}
	} as any as ConfirmationAction,
	auth: {
		__typename: "Auth",
		isAuthenticated: false,
	},
	dealerIdentity: {
		...initCurrentDealerIdentity,
	},
};


cache.writeData<LocalQuery>({
	data: {...initCache},
});

export { cache, initCache, initCleanCache };
