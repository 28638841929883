import { FormikHandlers, FormikHelpers, FormikState } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import RegistrationMessages from "../../../modules/registration/Registration.messages";
import { InputFieldFormik } from "../InputField";

interface UserNameInfoProps<T extends UserNameInfoPartialProps> {
    formik: FormikState<T> & FormikHandlers & FormikHelpers<T>;
    disabled?: boolean;
}
export interface UserNameInfoPartialProps extends Record<string, unknown> {
    last_name: string;
    middle_name: string;
    first_name: string;
}
const UserNameInfo = <T extends UserNameInfoPartialProps>(props: UserNameInfoProps<T>) => {
	const {formik, disabled = false} = props;
	const intl = useIntl();
	return (<>
		<div className="col-12 col-sm-6">
			<InputFieldFormik
				required={!disabled}
				name="last_name"
				id="last-name"
				label={intl.formatMessage(RegistrationMessages.last_name)}
				value={formik.values.last_name}
				attr={{ disabled }}
				formik={formik}
			/>
		</div>
		<div className="col-12 col-sm-6">
			<InputFieldFormik
				required={!disabled}
				id="second-name"
				name="middle_name"
				label={intl.formatMessage(RegistrationMessages.middle_name)}
				formik={formik}
				attr={{ disabled }}
				value={formik.values.middle_name}
			/>
		</div>
		<div className="col-12 col-sm-6">
			<InputFieldFormik
				required={!disabled}
				name="first_name"
				id="first-name"
				label={intl.formatMessage(RegistrationMessages.first_name)}
				value={formik.values.first_name}
				attr={{ disabled }}
				formik={formik}
			/>
		</div>
	</>
	);
};
export {
	UserNameInfo
};