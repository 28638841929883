import React, { useState, useMemo, FC } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { FormikHandlers, FormikHelpers, FormikState, useFormik } from "formik";
import { CircularProgress } from "../../../../shared/components/CircularProgress";
import { FTTBMessages } from "../../../fttb/FTTB.messages";
import { useIntl } from "react-intl";
import { FttbAddressForm } from "../../../fttb/fttb.types";
import { Mutation, MutationReserve_MsisdnArgs, Query } from "../../../../graphql/types";
import { RESERVE_MSISDN } from "../../../../graphql/mutations/subscription";
import { FttbStatus } from "../../../fttb/Pages/Address/Components/FTTBStatus";
import { AddressValidationForm } from "../../../fttb/Pages/Address/Components/AddressValidationForm";
import { ErrorMessage } from "../../../../shared/components/ErrorMessageTemplate";
import { InputFieldFormik } from "../../../../shared/components";
import { config } from "../../../../config/constants";
import RegistrationMessages from "../../../registration/Registration.messages";
import { initialLabels } from "../../../../shared/utils";
import { addressValidation } from "../../../fttb/Pages/Address/Address.schema";
import { GET_SINGLE_MSISDNS } from "../../../../graphql/queries/msisdn";
import { UserNameInfo } from "../../../../shared/components/formBlocks/UserNameInfo";
import { useGisHousePreviousActivationStatus } from "../../../../shared/hooks/useGisHousePreviousActivationStatus";
import { SimpleConnection } from "../../../../shared/components/SimpleConnection";
import { FormStepWrapper } from "../../../../shared/components/navigation/FormStepWrapper";
import { FmcPostpaidPreorderPage, FmcPostpaidRouteState } from "../fmc.postpaid.types";
import { useHistory } from "react-router-dom";
import { useNavigation } from "../../../../shared/components/navigation/useNavigation";
import { useNavigationState, useUpdateNavigatioState } from "../../../../shared/components/navigation/useNavigationState";

const formId = "fttb-address-form";


type PartialFmcState = Omit<FmcPostpaidRouteState,"preorder"> & {
	preorder: Omit<FmcPostpaidPreorderPage, "confirmationCode"|"msisdn"|"housePreviousActivationStatus">
	networkType?: String
};
const Preorder: FC = () => {
	const state = useNavigationState<FmcPostpaidRouteState>();
	const updateNavigatioState = useUpdateNavigatioState<FmcPostpaidRouteState>();
	const {pathNext} = useNavigation<FmcPostpaidRouteState>();
	const {
		search,
		preorder: {
			address = undefined,
			confirmationCode = undefined,
			msisdn: preoderMsisdn = undefined,
			last_name = "",
			middle_name = "",
			first_name = ""
		} = {}
	} = state || {};

	const history = useHistory<FmcPostpaidRouteState>();
	const intl = useIntl();
	const [fmcState, setFmcState] = useState<FmcPostpaidRouteState|undefined|null|PartialFmcState>(state);
	const [error, setErorr] = useState<string>("");
	const [connectionExist, setConnectionExist] = useState<string>("");
	const [housePreviousActivationStatus, setPreviousHouseActivationStatus] = useState<boolean>(Boolean(state?.preorder?.housePreviousActivationStatus));
	const [reserveMsisdn, {loading: loadingMsisdn}] = useMutation<Required<Pick<Mutation, "reserve_msisdn">>, MutationReserve_MsisdnArgs>(RESERVE_MSISDN);
	const initialValues = {
		last_name,
		first_name,
		middle_name,
		contact_phone: search?.gsmSubscription?.msisdn || "",
		
		city: address?.city || "",
		street: address?.street || "",
		gisStreetId: address?.gisStreetId || "",
		house_number: address?.house || "",
		flat: address?.flat || "",
		province: address?.province || "",
		gisProvinceId: address?.gisProvinceId || "",
		gisCityId: address?.gisCityId || "",
		gisDistrictId: address?.gisDistrictId || "",
		gisHouseId: address?.gisHouseId || "",
		gisZipId: address?.gisZipId || "",
		
		simpleConnection: address?.simpleConnection || false,
	};

	const [handleMsisdnPart] = useLazyQuery<Required<Pick<Query, "msisdns">>>(GET_SINGLE_MSISDNS, {
		fetchPolicy: "no-cache",
		onCompleted: ({ msisdns }) => {
			const msisdn = msisdns?.[0]?.msisdn;
			if (msisdn) {
				reserveMsisdn({
					variables: {msisdn}
				}).then(async ({data}) => {
					const reservation_id = data?.reserve_msisdn?.attributes?.reservation_id;
					if (reservation_id && pathNext) {
						updateNavigatioState({
							...fmcState,
							preorder: {
								...fmcState?.preorder,
								msisdn,
								confirmationCode: reservation_id,
								housePreviousActivationStatus,
							},
						} as FmcPostpaidRouteState);
						history.push({
							pathname: pathNext,
						});
					} else {
						setErorr(intl.formatMessage(FTTBMessages.msisdnReservationFail));
					}
				});
			} else {
				setErorr(intl.formatMessage(FTTBMessages.inventoryNoMsisdn));
			}

		}
	});
	const [hasAvailableHouse, setAvailableHouse] = useState<null | boolean>(!!fmcState?.preorder?.address?.house || null);
	const [networkType, setNetworktype] = useState("");

	const onSubmit = (values: FttbAddressForm) => {
		setErorr("");
		if (fmcState?.preorder?.address?.gisHouseId
			&& (fmcState as FmcPostpaidRouteState)?.preorder?.confirmationCode
			&& (fmcState as FmcPostpaidRouteState)?.preorder?.msisdn) {
			pathNext && history.push(pathNext);
		}
		if (hasAvailableHouse) {
			const fttb: PartialFmcState = {
				...state,
				networkType: networkType,
				preorder: {
					contact_phone: values.contact_phone,
					last_name: values.last_name,
					first_name: values.first_name,
					middle_name: values.middle_name,
					address: {
						country: config.DEFAULT_COUNTRY_NAME,
						country_code: config.DEFAULT_COUNTRY,
						city: values.city,
						gisProvinceId: values.gisProvinceId,
						gisDistrictId: values.gisDistrictId,
						gisCityId: values.gisCityId,
						gisStreetId: values.gisStreetId,
						gisHouseId: values.gisHouseId,
						gisZipId: values.gisZipId,
						street: values.street,
						house: values.house_number,
						flat: values.flat,
						province: values.province,
						simpleConnection: values.simpleConnection
					} 
				}
			};
			if (confirmationCode && preoderMsisdn && pathNext) {
				updateNavigatioState({
					...fttb,
					preorder: {
						...fttb.preorder,
						confirmationCode: confirmationCode,
						msisdn: preoderMsisdn,
						housePreviousActivationStatus,
					}
				});
				history.push({
					pathname: pathNext,
				});
			} else {
				setFmcState(fttb);
				handleMsisdnPart();
			}

		}
	};
	const validationSchema = useMemo(() => addressValidation(intl), [intl.locale]);
	const labels = useMemo(() => initialLabels<any>(intl, initialValues, RegistrationMessages), [intl.locale]);

	const {errors, setFieldValue, touched, handleChange, values, handleBlur, handleSubmit} = useFormik<FttbAddressForm>({
		initialValues: initialValues, onSubmit, validationSchema
	});
	const formik = {errors, setFieldValue, touched, handleChange, values, handleBlur};
	const {validate: validatePreviousActivationStatus,loading:  loadingPreviousActivationStatus} = useGisHousePreviousActivationStatus({
		setPreviousHouseActivationStatus,
		setConnectionExist,
		setFieldValue
	});
	return (
		<FormStepWrapper<FmcPostpaidRouteState>
			isValid={!(loadingMsisdn || loadingPreviousActivationStatus)}
			formId={formId}
			previousStepStateKey={"search"}>
			<form id={formId} onSubmit={handleSubmit}>
				<div className={"row"}>
					<div className="col-12 col-sm-6">
						<InputFieldFormik
							required
							name="contact_phone"
							id="contact_phone"
							disabled={true}
							attr={{maxLength: config.PHONE_LENGTH}}
							label={labels.contact_phone}
							value={formik.values.contact_phone}
							formik={formik}
						/>
					</div>
					<UserNameInfo formik={formik as (FormikState<FttbAddressForm> & FormikHandlers & FormikHelpers<FttbAddressForm>)}/>
				</div>
				<AddressValidationForm
					hasAvailableHouse={hasAvailableHouse}
					setAvailableHouse={setAvailableHouse}
					formik={formik}
					validatePreviousActivationStatus={validatePreviousActivationStatus}
					setPreviousHouseActivationStatus={setPreviousHouseActivationStatus}
					setNetworktype={setNetworktype}
				/>
				<div className={"mt-2"}>
					<ErrorMessage error={error}/>
				</div>
				<div className={"mt-2"}>
					{!error &&
					<FttbStatus values={formik.values} availableHouse={hasAvailableHouse} networkType={networkType} />}
				</div>
				{housePreviousActivationStatus && hasAvailableHouse &&
						<SimpleConnection connectionExist={connectionExist} formik={formik}/>}
			</form>
			{(loadingMsisdn || loadingPreviousActivationStatus) && (<CircularProgress/>)}
		</FormStepWrapper>
	);
};


export { Preorder };
