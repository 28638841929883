import React from "react";
import { useHistory } from "react-router";
import { CommonMessages } from "../../Common.messages";
import { FormattedMessage } from "react-intl";
import { FMC_FLOW_SEARCH_PATH } from "./Routes/FMCPageRoutes";
import { FmcPostpaidMessages, FMC_FLOW_POSTPAID_SEARCH_PATH } from "./postpaid";

const FMC = () => {
	const history = useHistory();
	const onClickPrepaid = () => {
		history.push(FMC_FLOW_SEARCH_PATH);
	};
	const onClickPostpaid = () => {
		history.push(FMC_FLOW_POSTPAID_SEARCH_PATH);
	};
	return (
		<>
			<h4><FormattedMessage {...CommonMessages.fmcFlow} /></h4>
			<button className="btn btn-block btn-secondary" onClick={onClickPrepaid}>
				<FormattedMessage {...CommonMessages.prepaidFmc} />
			</button>
			<button className="btn btn-block btn-secondary" onClick={onClickPostpaid}>
				<FormattedMessage {...FmcPostpaidMessages.title} />
			</button>
		</>
	);
};

export { FMC };