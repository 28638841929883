import React, { useState, FC, useRef } from "react";
import { Maybe, Package, ProductFamily, Query, QueryAssociated_Subscription_TypeArgs, QuerySubscription_Type_OfferingArgs } from "../../../../graphql/types";
import { OptionsSummary } from "../../../../shared/components/tariffPlan/OptionsSummary";
import { ConnectionOption } from "../../../fttb/Pages/Options/ConnectionOption";
import { OtherOptions, SelectedOtherOptions } from "../../../fttb/Pages/Options/OtherOptions";
import { config } from "../../../../config/constants";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import { CircularProgress } from "../../../../shared/components";
import {
	filterChildPackages,
	filterOutActiveBundleFromAvailableFamily,
	filterRouter,
	findPackage
} from "../../../../shared/utils/bundleUtils";
import { GET_ASSOCIATED_FTTB_SUBSCRIPTION_TYPE, GET_SUBSCRIPTION_TYPE_OFFERING } from "../../../../graphql/queries/associatedFttbSubscriptionType";
import { OptionProductChecked } from "../../../acquisitionFlow/NewCustomer/Pages/OptionsPage/OptionProduct";
import {GET_OPTIONAL_CHILD_PRODUCTS} from "../../../../graphql/queries/acquisitionFlow";
import { OptionsFamilies } from "../../../acquisitionFlow/NewCustomer/Pages/OptionsPage/OptionsFamilies";
import {prepareOptionalProductsForRadio, prepareUncheckClick} from "../../../../shared/utils";
import { StepWrapper } from "../../../../shared/components/navigation/StepWrapper";
import { FmcPostpaidRouteState } from "../fmc.postpaid.types";
import { ApolloError } from "apollo-boost";
import { errorActions, useStoreContext } from "../../../../shared/components/navigation/StepStateProvider";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorMessages } from "../../../../shared/error/Error.messages";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";
import { hasGPONFTTBTag } from "../../../../shared/utils/acquisitionFlowUtils";
import { FMCMessages } from "../../FMC.messages";

const Options: FC = () => {
	const availableFamilies = useRef<Maybe<Array<Maybe<ProductFamily>>>>([]);
	const state = useNavigationState<FmcPostpaidRouteState>();

	const {search: {withoutFttb = undefined, fttbSubscription = undefined} = {}, preorder, addons, plan: {selectedFmcTariffPlan = undefined} = {}} = state || {};
	const { address: { flat = undefined, gisHouseId = undefined, simpleConnection = undefined } = {}} = preorder || {};
	const subscriptioTypeId = addons?.preorderFttbTariffPlan?.id;
	const preorderOptions = addons?.preorderOptions;
	const {dispatch } = useStoreContext();
	const intl = useIntl();
	const otherOptionRef = useRef<SelectedOtherOptions>({
		comment: preorderOptions?.comment || "",
		complexOffer: Boolean(preorderOptions?.complexOffer),
		starterPack: Boolean(preorderOptions?.starterPack),
	});
	const initDesiredConnectionDate = preorderOptions?.desiredConnectionDate ? new Date(preorderOptions?.desiredConnectionDate) : undefined;
	const [date, setDate] = useState<Date|undefined|null>(initDesiredConnectionDate);
	const[hasGPONFTTB, setHasGPONFTTB] = useState(true);

	const {data, loading } = useQuery<Required<Pick<Query, "associated_subscription_type"|"optional_products">>, QueryAssociated_Subscription_TypeArgs>(GET_ASSOCIATED_FTTB_SUBSCRIPTION_TYPE, {
		variables: {subscription_type_id: selectedFmcTariffPlan?.id!},
		skip: !selectedFmcTariffPlan?.id,
		onCompleted: (data) => {
			setHasGPONFTTB(hasGPONFTTBTag(data?.optional_products!));
			if (!data?.associated_subscription_type?.code) {
				dispatch(errorActions.setError(intl.formatMessage(ErrorMessages.noConvergentFttb, {code: selectedFmcTariffPlan?.code})));
			}
		},
		onError: (error: ApolloError) => {
			if (error) {
				dispatch(errorActions.setError(intl.formatMessage(ErrorMessages.noConvergentFttb, {code: selectedFmcTariffPlan?.code})));
			}
		}
	});
	const {data:  {subscription_type_offering: offer, optional_products: optionalProducts} = {}, loading: loadOffer } = useQuery<Required<Pick<Query, "subscription_type_offering"|"optional_products">>, QuerySubscription_Type_OfferingArgs>(GET_SUBSCRIPTION_TYPE_OFFERING, {
		variables: {subscription_type_id: data?.associated_subscription_type?.id!},
		skip: !data?.associated_subscription_type?.id,
		fetchPolicy: "cache-and-network",
		onCompleted: (response) => {
			const id= data?.associated_subscription_type?.id
			if (!response?.subscription_type_offering?.code) {
				dispatch(errorActions.setError(intl.formatMessage(ErrorMessages.noSubscriptionTypeOffering, {id})));
			}
		},
		onError: (error: ApolloError) => {
			const id= data?.associated_subscription_type?.id

			if (error) {
				dispatch(errorActions.setError(intl.formatMessage(ErrorMessages.noSubscriptionTypeOffering, {id})));
			}
		}
	});
	const [optionalProduct, setOptionalProduct] = useState<Package[]>(preorderOptions?.packages || []);

	const [getChildOffers, {data: childOffersData, loading: loadingChildOffers}] = useLazyQuery<Query>(GET_OPTIONAL_CHILD_PRODUCTS);

	const [isParentChecked, setIsParentChecked] = useState<boolean>(false);
	const [parentCode, setParentCode] = useState<string | null | undefined>("");

	const onChecked = (value: OptionProductChecked) => {
		if (value.optionalProduct.code === "GPONFTTB") {
			setIsParentChecked(true);
		} else {
			setIsParentChecked(value.checked);
		}
		setParentCode(value.optionalProduct.code);
		getChildOffers({ variables: { subscription_type_id: selectedFmcTariffPlan?.id, parent_product: Number(value.optionalProduct.id), relation_type: "all" }});
		const filteredOptions = optionalProduct.filter(pack => {
			return prepareOptionalProductsForRadio(pack, value);
		});

		if (value.checked || value.optionalProduct.code === "GPONFTTB") {
			// setOptionalProduct([...filteredOptions, value.optionalProduct]);
			if (!optionalProduct.some(pkg => pkg?.code === value.optionalProduct.code)) {
				setOptionalProduct(prevOptionalProduct => [...prevOptionalProduct, value.optionalProduct]);
			}
		} else {
			if (!loadingChildOffers) {
				setOptionalProduct(optionalProduct.filter((pack) => {
					return  prepareUncheckClick(pack, value, childOffersData);
				}));
			}
		}
	};

	const onChildChecked = (value: OptionProductChecked) => {
		if (value.checked) {
			setOptionalProduct([...optionalProduct, value.optionalProduct]);
		} else {
			setOptionalProduct(optionalProduct.filter((pack) => pack.code !== value.optionalProduct.code));
		}
	};
	let available_child_offers = childOffersData?.child_offers;

	if (optionalProducts && optionalProducts.length > 0) {
		availableFamilies.current = withoutFttb ? 
			filterOutActiveBundleFromAvailableFamily(
				optionalProducts, 
				[{code: config.FAST_CONNECTION_PACKAGE_CODE}], 
				simpleConnection, 
				preorder?.housePreviousActivationStatus,
				null,
				state?.networkType
			)
			: filterRouter(optionalProducts);
	}

	if (available_child_offers && available_child_offers.length > 0 && simpleConnection) {
		available_child_offers = filterChildPackages(available_child_offers);
	}

	const stateCallback:() => Required<Pick<FmcPostpaidRouteState, "addons">>|undefined = () => (withoutFttb ? { addons: {
		options: [],
		preorderFttbTariffPlan: {
			offerCode: offer?.code,
			...data?.associated_subscription_type
		},
		preorderOptions: {
			comment: otherOptionRef.current.comment,
			complexOffer: otherOptionRef.current.complexOffer,
			starterPack: otherOptionRef.current.starterPack,
			desiredConnectionDate: date?.toString(),
			packages: optionalProduct,
			fttbFast: findPackage(optionalProducts,  config.FAST_CONNECTION_PACKAGE_CODE),
		}
	}} : undefined);
	return (
		<StepWrapper<FmcPostpaidRouteState>
			isValid={!loadOffer && !!data?.associated_subscription_type?.code}
			stateCallBack={stateCallback}
			previousStepStateKey={"plan"}>
			{ !hasGPONFTTB &&
				<div className="alert alert-warning mt-2 message">
					<p><FormattedMessage {...FMCMessages.gponNotAvailable} /></p> 
				</div>
			}
			{withoutFttb ? <>
				{!simpleConnection && <>
					<ConnectionOption
						currentDate={date}
						onSelectedDate={setDate}
					/>
					<OtherOptions
						otherOptionRef={otherOptionRef}
					/></>
				}
				{availableFamilies.current?.map((family: Maybe<ProductFamily>, index) => (
					family?.packages?.length && family.packages.length > 0) &&
					<OptionsFamilies
						key={family.id || `package-${index}`}
						family={family}
						onChecked={onChecked}
						isParentChecked={isParentChecked}
						childOffersData={available_child_offers}
						parentCode={parentCode}
						onChildChecked={onChildChecked}
						loadingChildOffers={loadingChildOffers}
						selectedOptionalProduct={optionalProduct}
					/>
				)}
			</>
		  : <>{fttbSubscription?.active_packages?.map((option: Maybe<Package>, index) => (<OptionsSummary
					key={option?.name?.en || `active-packages-${index}`}
					fees={option?.fees!}
					periodic_unit={option?.periodic_unit!}
					periodic_amount={option?.periodic_amount!}
					name={option?.name!}
					medium_description={option?.medium_description!}
					long_description={option?.long_description!}
				/>
				))}
				</>}
			{(loading || loadOffer) && <CircularProgress/>}

		</StepWrapper>
	);
};

export { Options };
