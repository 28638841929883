/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface LoginMessagesType {
	acccountIsBlocked: MessageDescriptor;
	changePassword: MessageDescriptor;
	checkYourEmail: MessageDescriptor;
	currentPassword: MessageDescriptor;
	email: MessageDescriptor;
	enterOtp: MessageDescriptor;
	forgotPassword: MessageDescriptor;
	logInAgain: MessageDescriptor;
	logOut: MessageDescriptor;
	loggedOut: MessageDescriptor;
	loginFailed: MessageDescriptor;
	logoutSuccessfully: MessageDescriptor;
	newPassword: MessageDescriptor;
	password: MessageDescriptor;
	passwordChangedSuccessfully: MessageDescriptor;
	passwordExpired: MessageDescriptor;
	passwordValidationIncludeDigit: MessageDescriptor;
	passwordValidationIncludeLastPassword: MessageDescriptor;
	passwordValidationIncludeLetter: MessageDescriptor;
	passwordValidationIncludeSpecialCharacter: MessageDescriptor;
	passwordValidationLength: MessageDescriptor;
	passwordValidationSimilarToEmail: MessageDescriptor;
	passwordsDoNotMatch: MessageDescriptor;
	repeatNewPassword: MessageDescriptor;
	resetPassword: MessageDescriptor;
	resetPasswordError: MessageDescriptor;
	resetPasswordServerError: MessageDescriptor;
	returnToLogIn: MessageDescriptor;
	signIn: MessageDescriptor;
	username: MessageDescriptor;
}
const LoginMessages: LoginMessagesType = defineMessages({
	acccountIsBlocked: {
		id: "login-account-is-blocked",
		description: "Your account is blocked. Please contact administrator. validation error",
		defaultMessage: "Your account is blocked. Please contact administrator."
	},
	changePassword: {
		id: "change-password",
		description: "Change password title and button label",
		defaultMessage: "Change password"
	},
	checkYourEmail: {
		id: "change-passwords-check-your-email",
		description: "Please check your email success confirmation message",
		defaultMessage: "Please check your email"
	},
	currentPassword: {
		id: "change-current-password",
		description: "Current password label",
		defaultMessage: "Current password"
	},
	email: {
		id: "reset-password-email",
		description: "Reset password email label",
		defaultMessage: "Email"
	},
	enterOtp: {
		id: "login-enter-otp",
		description: "Enter the verification code here",
		defaultMessage: "Enter the verification code here"
	},
	forgotPassword: {
		id: "login-forgot-password",
		description: "Forgot your password link",
		defaultMessage: "Forgot your password?"
	},
	logInAgain: {
		id: "login-log-in-again",
		description: "Log in again button",
		defaultMessage: "Log in again"
	},
	logOut: {
		id: "login-log-out",
		description: "Log out title",
		defaultMessage: "Log out"
	},
	loggedOut: {
		id: "login-logged-out",
		description: "Logout out title",
		defaultMessage: "Logged out"
	},
	loginFailed: {
		id: "login-failed",
		description: "Login failed error",
		defaultMessage: "Incorrect username or password"
	},
	logoutSuccessfully: {
		id: "login-logout-successfully-message",
		description: "Logout successfully",
		defaultMessage: "You are now logged out"
	},
	newPassword: {
		id: "change-new-password",
		description: "New password label",
		defaultMessage: "New password"
	},
	password: {
		id: "login-password",
		description: "Password login form",
		defaultMessage: "Password"
	},
	passwordChangedSuccessfully: {
		id: "change-passwords-changed-successfully",
		description: "Password changed successfully confirmation message",
		defaultMessage: "Your password has been changed successfully"
	},
	passwordExpired: {
		id: "login-password-expired",
		description: "Password expired login validation error",
		defaultMessage: "Password expired."
	},
	passwordValidationIncludeDigit: {
		id: "password-validation-include-digit",
		description: "Include at least 1 digit.",
		defaultMessage: "Include at least 1 digit."
	},
	passwordValidationIncludeLastPassword: {
		id: "password-validation-include-last-n-password",
		description: "You can not repeat any of the last 5 passwords.",
		defaultMessage: "You can not repeat any of the last 5 passwords."
	},
	passwordValidationIncludeLetter: {
		id: "password-validation-include-letter",
		description: "Include at least 1 letter.",
		defaultMessage: "Include at least 1 letter."
	},
	passwordValidationIncludeSpecialCharacter: {
		id: "password-validation-include-special-character",
		description: "Include at least 1 special character.",
		defaultMessage: "Include at least 1 special character."
	},
	passwordValidationLength: {
		id: "password-validation-length",
		description: "'Password must have at least n characters'",
		defaultMessage: "Password must have at least {value} characters"
	},
	passwordValidationSimilarToEmail: {
		id: "password-validation-similar-to-email",
		description: "The password is too similar to the email address",
		defaultMessage: "The password is too similar to the email address."
	},
	passwordsDoNotMatch: {
		id: "change-passwords-do-not-match",
		description: "Passwords do not match error",
		defaultMessage: "Passwords do not match"
	},
	repeatNewPassword: {
		id: "change-repeat-new-password",
		description: "Repeat new password label",
		defaultMessage: "Repeat new password"
	},
	resetPassword: {
		id: "reset-password-title",
		description: "Reset password title",
		defaultMessage: "Reset password"
	},
	resetPasswordError: {
		id: "reset-password-error",
		description: "Reset password fails message",
		defaultMessage: "Reset password fails"
	},
	resetPasswordServerError: {
		id: "reset-password-server-error",
		description: "Reset password server error message",
		defaultMessage: "Reset password server error"
	},
	returnToLogIn: {
		id: "reset-password-return-to-log-in",
		description: "Reset password return to log in link",
		defaultMessage: "Return to log in"
	},
	signIn: {
		id: "login-sign-in",
		description: "Sign In login form",
		defaultMessage: "Log In"
	},
	username: {
		id: "login-username",
		description: "User name login form",
		defaultMessage: "User Name"
	},
});

export default LoginMessages;
export { LoginMessages };
