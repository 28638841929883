import { LocalQuery } from "../../graphql/localTypes";
import { GET_CONFIRMATION_ACTION_STATUS } from "../../graphql/queries/localQuery";
import { InMemoryCache } from "apollo-cache-inmemory";

const showActionModal = (_, variables, {cache}: { cache: InMemoryCache }) => {
	const { action, code } = variables;

	const {confirmationAction, context } = cache.readQuery<LocalQuery>({
		query: GET_CONFIRMATION_ACTION_STATUS(action)
	})!;

	const newCodeItem = {
		__typename: "ActionCodeStatus",
		id: code + action + context?.subscriptionId,
		code,
		code3rdParty: variables?.code3rdParty,
		status: false
	};

	let codes = confirmationAction?.[action]?.codes;
	if (codes?.length > 0) {
		const index = codes.findIndex(x => x.code === code);
		if (index === -1) {
			codes.push(newCodeItem);
		} else {
			codes[index] = newCodeItem;
		}
	} else {
		codes = [newCodeItem];
	}
	cache.writeQuery({
		query: GET_CONFIRMATION_ACTION_STATUS(action),
		data: {
			confirmationAction: {
				...confirmationAction,
				action,
				status: true,
				code,
				code3rdParty: variables?.code3rdParty,
				[action]: {
					id: action,
					__typename: "ActionDetails",
					codes,
				}
			}
		},
	});
};

export {
	showActionModal
};
