import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ChangePlanMessages } from "./ChangePlan.messages";
import { Maybe, Package, SubscriptionType, Query, QuerySubscription_TypeArgs } from "../../../graphql/types";
import { createMarkup } from "../../../shared/utils/createMarkup";
import { PackagePrices } from "../../packages/PackagePrice";
import { config, RECURRENT } from "../../../config/constants";
import cssns from "../../../shared/utils/cssnsConfig";
import CommonMessages from "../../../Common.messages";
import { extractFee } from "../../../shared/utils";
import { SelectedTariffPlanDetails } from "../../../graphql/localTypes";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_SUBSCRIPTION_TYPE } from "../../../graphql/queries/getSubscriptiontTypes";
import { CircularProgress } from "../../../shared/components";

const {React} = cssns("Change-Plan-Row-List");

interface TableRowList {
    tariffPlan?: SubscriptionType | null;
    selectTariffPlan: (plan: SelectedTariffPlanDetails) => void;
    selected?: boolean;
    selectedPlan?: SelectedTariffPlanDetails|undefined;
}

const B2BFttbPlanRowList = (props: TableRowList) => {
    const intl = useIntl();
    const [getTariffPlanAllDetails, {data, loading}] = useLazyQuery<Required<Pick<Query, "subscription_type">>, QuerySubscription_TypeArgs>(GET_SUBSCRIPTION_TYPE);
    const subscription_types = data?.subscription_type;
    const [open, setState] = useState<boolean>(false);
    const onClick = () => {
        getTariffPlanAllDetails({variables: {
                subscription_type_id: props.tariffPlan?.id!
            }});
        setState(!open);
    };
    const onClickTariffPlan = () => {
        props.selectTariffPlan({
            id: props.tariffPlan?.id!,
            name: props.tariffPlan?.name!,
            code: props.tariffPlan?.code!,
            change_price: props.tariffPlan?.change_price!,
            change_price_currency: props.tariffPlan?.change_price_currency!,
            info_text: props.tariffPlan?.info_text!,
            long_info_text: props.tariffPlan?.long_info_text!,
            description: props.tariffPlan?.long_info_text?.[intl.locale]!,
            planType: props.tariffPlan?.payment_type!,
            fee: 0,
            feeCurrency: "",
            feeRecurringPeriod: "",
            subscription_type_category: props.tariffPlan?.subscription_type_category!,
        });
    };
    const onClickTariffPlanOptionGroup = (pack: Maybe<Package>) => () => {
        props.selectTariffPlan({
            periodicAmount: pack?.periodic_amount!,
            periodicUnit: pack?.periodic_unit!,
            short_description: pack?.short_description!,
            medium_description: pack?.medium_description!,
            long_description: pack?.long_description!,
            description: pack?.medium_description?.[intl.locale],
            name: pack?.name!,
            planType: props.tariffPlan?.payment_type!,
            change_price_currency: props.tariffPlan?.change_price_currency!,
            change_price: props.tariffPlan?.change_price!,
            fee: extractFee(pack),
            feeCurrency: config.DEFAULT_CURRENCY!,
            feeRecurringPeriod: RECURRENT,
            id: props.tariffPlan?.id!,
            selectedOptionCode: pack?.code!,
            selectedOptionId: pack?.id!,
            code: props.tariffPlan?.code!,
            subscription_type_category: props.tariffPlan?.subscription_type_category!,
            billing_type: props.tariffPlan?.billing_account_type?.code!,
        });
    };
    return (
        <>
            <div className={props.selected ? "head head-selected" : "head"} onClick={onClick}>
                <div>
                    {props.tariffPlan?.name?.[intl.locale]}
                    &nbsp;
                    {props.selected && <span className="fa fa-check selected"/>}
                </div>
                <div></div>
                <div className="chevron">
                    {open ? <i className="fa fa-chevron-down"/> : <i className="fa fa-chevron-up"/>}
                </div>
            </div>
            {open && ( loading ?
                    <CircularProgress/> :
                    (subscription_types?.option_groups?.length === 0) ? (
                        <div className={props.selected ? "nested nested-selected" : "nested"}>
                            <div dangerouslySetInnerHTML={createMarkup(subscription_types?.long_info_text?.[intl.locale])}/>
                            <div className="text-success"><FormattedMessage {...CommonMessages.withOutPeriodicFee} /></div>
                            <div className="text-right">
                                <button
                                    type="button"
                                    className={`btn ${props.selected ? "btn-success" : "btn-primary"}`}
                                    onClick={onClickTariffPlan}
                                    disabled={props.selected}
                                >
                                    {props.selected ?
                                        <FormattedMessage {...ChangePlanMessages.selected} /> :
                                        <FormattedMessage {...ChangePlanMessages.selectPlan} />
                                    }
                                </button>
                            </div>
                        </div>
                    ) : subscription_types?.option_groups?.map(group => {
                        return group?.packages?.map(pack => {
                            return (<div key={pack?.code!} className={props.selectedPlan?.selectedOptionCode === pack?.code ? "nested nested-selected" : "nested"}>
                                <div>
                                    <div className="w-emphasize">{pack?.name?.[intl.locale]}</div>
                                    <div dangerouslySetInnerHTML={createMarkup(
                                        pack?.long_description?.[intl.locale] ||
                                        pack?.medium_description?.[intl.locale])}/>
                                </div>
                                <div className="d-flex justify-content-between">
                                    {pack?.fees?.map((fee) => {
                                        return (
                                            <div key={fee?.fee!}>
                                                <PackagePrices
                                                    fee={fee?.fee}
                                                    fee_type={fee?.fee_type}
                                                    periodic_amount={pack?.periodic_amount}
                                                    periodic_unit={pack?.periodic_unit}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="text-right">
                                    <button
                                        type="button"
                                        className={`btn ${props.selectedPlan?.selectedOptionCode === pack?.code ? "btn-success" : "btn-primary"}`}
                                        onClick={onClickTariffPlanOptionGroup(pack)}
                                    >
                                        {props.selectedPlan?.selectedOptionCode === pack?.code ?
                                            <FormattedMessage {...ChangePlanMessages.selected} /> :
                                            <FormattedMessage {...ChangePlanMessages.selectPlan} />
                                        }
                                    </button>
                                </div>
                            </div>);
                        });
                    })
            )}
        </>);
};

export { B2BFttbPlanRowList };
