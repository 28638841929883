import { useMutation } from "@apollo/react-hooks";
import React, { FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CommonMessages from "../../../Common.messages";
import { UPLOAD_ACQUISITION_DOCUMENT } from "../../../graphql/mutations/order";
import { GET_UID_DOCUMENT_RESERVATION } from "../../../graphql/mutations/subscription";
import { Mutation, MutationUpload_Acquisition_DocumentArgs, SalesTypeEnum } from "../../../graphql/types";
import AcquisitionMessages from "../../../modules/acquisitionFlow/Acquisition.messages";
import { useAuthDataContext } from "../../hooks/AuthentificationProvider";
import { CircularProgress } from "../CircularProgress";
import { errorActions, useStoreContext } from "../navigation/StepStateProvider";
import { UploadFiles } from "../UploadFiles";
import {B2BFttbContractPayload, B2BFttbContractUtils} from "./B2BFttbContractUtils";
export interface UploadDocumentsProps {
    payload: B2BFttbContractPayload;
    files: any[];
    setFiles: (any) => void,
    uploadProgress: boolean;
}
const useB2BFttbDocumentGeneration = (payload: B2BFttbContractPayload, setLoadingContractGeneration: React.Dispatch<React.SetStateAction<boolean>>) => {
    const intl = useIntl();
    const [contractUrl, setContractUrl] = useState<string>("");
    const [isRequestFired, setRequestFired] = useState<boolean>(false);
    const {dispatch} = useStoreContext();
    const { currentDealerIdentety} = useAuthDataContext();
    const { msisdn } = payload;
    useEffect(() => {
        if (!isRequestFired && msisdn && currentDealerIdentety) {
            setRequestFired(true);
            const language = intl.locale;
            B2BFttbContractUtils.getB2BFttbContractDocument({ payload, currentDealerIdentety, language})
                .then(blob => {
                    setContractUrl(window.URL.createObjectURL(blob));
                    setLoadingContractGeneration(false);
                }).catch(() => {
                dispatch(errorActions.setError(intl.formatMessage(CommonMessages.contractGenerationFail)));
                setLoadingContractGeneration(false);
            });
        }
    }, [payload, currentDealerIdentety]);
    return contractUrl;
};

interface B2BFttbUploadDocumentDetails {
    purpose: string;
    confirmation_code: string;
}

const useB2BFttbUploadDocument = (): [
    ({purpose, confirmation_code}: B2BFttbUploadDocumentDetails, onDone: () => void, onFail?: () => void) => void,
    any[],
    (any) => void,
    boolean] => {
    const intl = useIntl();
    const [uploadProgress, setUploadProgress] = useState<boolean>(false);
    const [files, setFiles] = useState([]);

    const [uploadAcquisitionDocument] = useMutation<Mutation, MutationUpload_Acquisition_DocumentArgs>(UPLOAD_ACQUISITION_DOCUMENT);
    const [document_uid_reservation] = useMutation(GET_UID_DOCUMENT_RESERVATION);
    const errorLabel = intl.formatMessage({...CommonMessages.documentUploadFail});
    const {dispatch} = useStoreContext();

    return [({purpose, confirmation_code}: B2BFttbUploadDocumentDetails,
             onDone: () => void,
             onFail?: () => void) => {
        dispatch(errorActions.cleanError());
        setUploadProgress(true);
        document_uid_reservation().then(({ data }) => {
            if(data?.document_uid_number_reservations?.request_id) {
                uploadAcquisitionDocument({
                    fetchPolicy: "no-cache",
                    variables: {
                        details: {
                            confirmation_code,
                            document_type: SalesTypeEnum.B2BFttbAcquisition,
                            files,
                            purpose,
                            document_uid: data?.document_uid_number_reservations?.request_id
                        }
                    }
                }).then(result => {
                    setUploadProgress(false);
                    if (!Boolean(result.data?.upload_acquisition_document?.error || result.errors)) {
                        onDone();
                    } else {
                        dispatch(errorActions.setError(errorLabel));
                        if (onFail) {
                            onFail();
                        }
                    }
                }).catch(e => {
                    dispatch(errorActions.setError(errorLabel));
                });
            } else {
                dispatch(errorActions.setError(errorLabel));
                if (onFail) {
                    onFail();
                }
            }
        });

    }, files, setFiles, uploadProgress];
};

const UploadB2BFttbDocuments: FC<UploadDocumentsProps> = props => {
    const {
        payload: { msisdn },
        setFiles,
        files,
        uploadProgress
    } = props;
    const intl = useIntl();
    const [loadingContractGeneration, setLoadingContractGeneration] = useState<boolean>(true);
    const contractUrl = useB2BFttbDocumentGeneration(props.payload, setLoadingContractGeneration);

    const textInfo = useMemo(() => intl.formatMessage(AcquisitionMessages.infoMessageUploadDocument), [intl.locale]);

    return (
        <>
            {(loadingContractGeneration ? <CircularProgress showText={false}/> :
                <div className="w-action-item-link mb-3">
                    <a href={contractUrl} download={`contract_${msisdn}.pdf`}>
                        <FormattedMessage {...CommonMessages.downloadContract} />
                    </a>
                </div>)}
            <UploadFiles
                setFiles={setFiles}
                files={files}
                uploadProgress={uploadProgress}
                hideDocumentLink={true}
                textInfo={textInfo}
                required={true}
            />
        </>
    );
};

export { UploadB2BFttbDocuments, useB2BFttbUploadDocument };