import gql from "graphql-tag";

const language =  {
	fragments: {
		full: gql`
            fragment languageFull on Language {
                en,
                uk,
                ru,
            }
            `,
	}
};

export {
	language
};