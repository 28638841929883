import React, {FC} from "react";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import LoginMessages from "../../modules/login/Login.messages";
import { UserProfileToggle } from "./UserProfileToggle";
import { useAuthDataContext } from "../hooks/AuthentificationProvider";
import { msisdnActions, useStoreContext } from "./navigation/StepStateProvider";

const UserProfileDropdown: FC = () => {
	const {userName = "", onLogout, loading} = useAuthDataContext();

	const {dispatch} = useStoreContext();

	const handleLogout = () => {
		dispatch(msisdnActions.clean());
		onLogout();
	};
	return (
		<Dropdown>
			<Dropdown.Toggle userName={loading ? "" : userName!}
				as={UserProfileToggle}
				id="dropdown-user-profile">
			</Dropdown.Toggle>
			<Dropdown.Menu className="dropdown-menu" alignRight>
				<Dropdown.Item className="dropdown-item" onClick={handleLogout}>
					<FormattedMessage {...LoginMessages.logOut}/>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export {
	UserProfileDropdown
};
