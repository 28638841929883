import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { CommonMessages } from "../../../Common.messages";
import React from "react";
import { ACQUISITION_FLOW_MSISDN_PATH } from "./Routes/AcquisitionFlowRoutes";
import { clearLocalStorageFromNewCustomer } from "../../../shared/utils";

const NewCustomer = () => {
	const history = useHistory();
	const onClickNewSubscription = async(): Promise<any> => {
		await clearLocalStorageFromNewCustomer();
		history.push(ACQUISITION_FLOW_MSISDN_PATH);
	};
	return (
		<>
			<h4><FormattedMessage {...CommonMessages.createNewSubscription} /></h4>
			<button className="btn btn-block btn-secondary" onClick={onClickNewSubscription}>
				<FormattedMessage {...CommonMessages.newCustomer} />
			</button>
		</>
	);
};

export { NewCustomer };