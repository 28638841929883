import React, {FC, useEffect, useMemo, useState} from "react";
import { useIntl } from "react-intl";
import { RouteComponentProps, StaticContext } from "react-router";
import { CircularProgress } from "../../../shared/components";
import { FMC_FLOW_SEARCH_PATH } from "../Routes/FMCPageRoutes";
import { useQuery } from "@apollo/react-hooks";
import {
	Query,
	LanguageEnum,
	GisHouseGreen_FieldArgs,
	QueryGis_HousesArgs
} from "../../../graphql/types";
import { SelectedTariffPlanDetails } from "../../../graphql/localTypes";
import { FormattedMessage } from "react-intl";
import { FTTBMessages } from "../../fttb/FTTB.messages";
import { TableRowList } from "../../change-bar/plan/ChangePlanRowList";
import { GET_FTTB_PLANS } from "../../../graphql/queries/subscription";
import { FmcRouteState } from "../fmc.type";
import { StepWrapper } from "../../../shared/components/navigation/StepWrapper";
import { useNavigationState } from "../../../shared/components/navigation/useNavigationState";
import {GET_FMC_PREPAID_TARIFF_PLANS, GET_FTTB_OFFERING} from "../../../graphql/queries/acquisitionFlow";
import {OfferSubscriptionProps, prepareFmcOfferings, prepareFttbOfferings} from "../../../shared/utils";
import {TARIFF_PLAN_TYPE_PREPAID} from "../../acquisitionFlow/NewCustomer/Pages";
import { GET_HOUSE_GREEN_FIELD } from "../../../graphql/queries/gisSystem";

const Plan: FC<RouteComponentProps<never, StaticContext, FmcRouteState>> = props => {
	const {locale} = useIntl();
	const { history } = props;
	const state = useNavigationState<FmcRouteState>();

	const [fmcState, setFmcState] = useState<FmcRouteState|undefined>(state);

	const {data, loading} = useQuery<Required<Pick<Query, "offerings_under_city" | "otp_subscription">>>(GET_FMC_PREPAID_TARIFF_PLANS, {
		fetchPolicy: "cache-and-network",
		skip: !state?.fttbSubscription?.owner?.address && !state?.address.gisCityId,
		variables: {
			gis_city_id: state?.isNoFttbSubscription
				? state?.address?.gisCityId || ""
				: state?.fttbSubscription?.owner?.address?.[0]?.gis_city_id || "",
			msisdn: state?.gsmSubscription?.msisdn! || "",
			payment_type: TARIFF_PLAN_TYPE_PREPAID
		}});

	const handleSelectTariffPlan = (selectedFmcTariffPlan: SelectedTariffPlanDetails) => {
		setFmcState({
			...state,
			selectedFmcTariffPlan,
			options: [],
			fttbOptions: undefined,
			selectedFttbTariffPlan: undefined,
			plan: {
				selectedFmcTariffPlan,
			}
		});
	};
	if (!state?.gsmSubscription) {
		history.push(FMC_FLOW_SEARCH_PATH);
	}

	useEffect(() => {
		if (state?.networkType) {
			setFmcState({
				...state,
				...fmcState,
				networkType: state?.networkType
			});
		}
	}, [state?.networkType])

	const {data: gisHouses, loading: gisHouseLoading } = useQuery<Required<Pick<Query, "gis_houses">>, GisHouseGreen_FieldArgs & QueryGis_HousesArgs>(GET_HOUSE_GREEN_FIELD, {
		variables: {
			language: locale as LanguageEnum,
			house_id: state?.address?.gisHouseId!,
			apartment: state?.address?.flat
		},
	});

	const tariffPlans: (OfferSubscriptionProps | undefined)[] | undefined = useMemo(() => {
		if (data?.offerings_under_city && data?.otp_subscription?.subscription && data?.otp_subscription?.subscription?.available_subscription_types) {
			const greenFieldTags = gisHouses?.gis_houses?.green_field?.green_field_tp;
			const greenFieldTpStatus = gisHouses?.gis_houses?.green_field?.green_field_tp_status;
			return prepareFmcOfferings(
				data?.offerings_under_city, 
				data?.otp_subscription?.subscription?.available_subscription_types,
				greenFieldTags, greenFieldTpStatus, state?.isNoFttbSubscription
			);
		} else {
			return [];
		}
	}, [data?.offerings_under_city, gisHouses]);

	const code = fmcState?.selectedFmcTariffPlan?.code;
	return (
		<StepWrapper<FmcRouteState>
			isValid={true}
			newState={fmcState}>
			<div className="row">
				<div className="col-12">
					{!loading && !(tariffPlans?.length && tariffPlans.length > 0) &&
						<div><FormattedMessage {...FTTBMessages.plansNotFound} /></div>}
					{tariffPlans?.map(subscription_types => subscription_types?.subscription_types?.code &&
						(<TableRowList
							balance={Infinity}
							key={subscription_types?.subscription_types.code + "top-level"}
							tariffPlan={subscription_types.subscription_types}
							selectTariffPlan={handleSelectTariffPlan}
							selected={code === subscription_types.subscription_types.code}
						/>))}
					{loading && (<CircularProgress/>)}
				</div>
			</div>
		</StepWrapper>
	);
};

export {
	Plan,
};
