const iccValidationErrors = {
	"Invalid ICC format": "invalidIcc",
	"Please provide a valid ICC number": "provideValidIcc",
	"ICC already in use": "usedIcc",
	"ICC doesn't match to subscription": "notMatchIcc",
};

export {
	iccValidationErrors
};
