import { config } from "../../config/constants";
import { Maybe, Package } from "../../graphql/types";
import { errorActions, StepActionPayload } from "../components/navigation/StepStateProvider";

class PackageUtils {
	static intersectPackages(a: string[], b: Maybe<Array<Maybe<Package>>>|undefined): string[] {
		return a.filter(code => b?.some(item => item?.code === code));
	}
	static extractCodes(addons: Package[]|undefined): string[] {
		return addons?.map(item => item.code!) || [];
	}

	static handleFttbErrors(active_packages: Maybe<Array<Maybe<Package>>>|undefined, dispatch: React.Dispatch<StepActionPayload>, msisdn: string): void  {
		const {FAST_CONNECTION_PACKAGE_CODE, SERVICE_MASTER_PACKAGE_CODE, FTTB_PAUSE} = config;
		const result = PackageUtils.intersectPackages([FAST_CONNECTION_PACKAGE_CODE, SERVICE_MASTER_PACKAGE_CODE], active_packages);
		const validate = (result: string[]) => {
			const errors: string[] = [];
			const test = (code: string) => {
				if (result.includes(code)) {
					errors.push(code);
				}
				return {
					next: (c: string) => test(c),
					dispatch: (msisdn) => dispatch(errorActions.setMsisdnErrors({[msisdn]: errors}))
				};
			};
			return { next: (c: string) => test(c) };
		};
		validate(result)
			.next(FAST_CONNECTION_PACKAGE_CODE)
			.next(SERVICE_MASTER_PACKAGE_CODE)
			.next(FTTB_PAUSE)
			.dispatch(msisdn);
	}
}

export {
	PackageUtils
};