import React from "react";
import { FormattedMessage } from "react-intl";
import { ErrorMessages } from "./Error.messages";

class PageNotFound extends React.Component {
	render() {
		return (
			<div id="w-app-body" className="d-flex align-items-center justify-content-center">
				<div className="row">
					<div className="col-12 text-center"><h1>404</h1></div>
					<div className="col-12 text-center"><h6><FormattedMessage  {...ErrorMessages.pageNotFound}/></h6></div>
				</div>
			</div>
		);
	}
}

export default PageNotFound;
