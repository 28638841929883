import React, { useCallback, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { MNP_FLOW_PLAN_PATH, MNP_FLOW_CONTACT_INFO_PATH, MNPRouteState } from "../MNPFlowRoutes";
import { Options } from "../../../shared/components/option/Options";
import { Package } from "../../../graphql/types";
import { MNPFlow } from "./MNPFlow";
import { NavigationHeader } from "../../../shared/components/NavigationHeader";

const MnpOptions = (props: RouteComponentProps<never, StaticContext, MNPRouteState>) => {
	const { location, history } = props;
	const { state } = location;
	const [optionalProduct, setOptionalProduct] = useState<Package[]>(state?.options || []);

	const onClickNext = useCallback(() => {
		history.push(MNP_FLOW_CONTACT_INFO_PATH, {
			...state,
			options: optionalProduct,
		});
	}, [optionalProduct]);

	const onClickBack = useCallback(() => history.push(MNP_FLOW_PLAN_PATH, state), []);

	return (<>
		<NavigationHeader FlowComp={MNPFlow} next={onClickNext} back={onClickBack} />
			<div className="ipos-content-box">
				<Options
					planId={state?.plan?.id}
					state={optionalProduct}
					setState={setOptionalProduct}
				/>
			</div>
       </>
	);
};

export { MnpOptions };