import { OfferSubscriptionProps } from "../../utils";
import { FormattedMessage } from "react-intl";
import ChangePlanMessages from "../../../modules/change-bar/plan/ChangePlan.messages";
import { CommonMessages } from "../../../Common.messages";
import { CircularProgress } from "../CircularProgress";
import { TableRowList } from "../../../modules/change-bar/plan/ChangePlanRowList";
import { SelectedTariffPlanDetails } from "../../../graphql/localTypes";
import cssns from "../../utils/cssnsConfig";
import { FC } from "react";

export interface PlanListProps {
	offerings?: (OfferSubscriptionProps | undefined)[] | undefined;
    type: string;
    loading: boolean;
    plan?: SelectedTariffPlanDetails;
    handleSelectTariffPlan: (plan: SelectedTariffPlanDetails) => void;
}

const {React} = cssns("Plan-Page");

const PlanList: FC<PlanListProps> = (props) => {
	const { offerings = [], type, loading, handleSelectTariffPlan, plan } = props;
	return (
		<>
			<div className="head">
				<div>
					<FormattedMessage {...ChangePlanMessages.plan} />
				</div>
				<div>
					<FormattedMessage {...CommonMessages.oneTimeFee} />
				</div>
			</div>
			{!loading && !(offerings?.length > 0) && (
				<div>
					<FormattedMessage
						{...ChangePlanMessages.recommendedPlansNotFound}
					/>
				</div>
			)}
			{offerings
				?.filter(
					(item) => item?.subscription_types?.payment_type === type
				)
				.map(
					(offerSubType) =>
						offerSubType?.subscription_types?.code && (
							<TableRowList
								balance={Infinity}
								key={
									offerSubType.offerCode + offerSubType?.subscription_types?.code +
									"top-level"
								}
								tariffPlan={offerSubType?.subscription_types}
								offerId={offerSubType.offerId!}
								offerCode={offerSubType.offerCode!}
								offerSimCardCode={offerSubType.offerSimCardCode}
								selectTariffPlan={handleSelectTariffPlan}
								selected={
									plan?.code ===
									offerSubType?.subscription_types?.code
								}
							/>
						)
				)}
			{loading && <CircularProgress />}
		</>
	);
};

export { PlanList };
