import {iposRoutes} from "../../../routes/RoutesMap";

export const INDEX_PATH: string = iposRoutes.INDEX.createLink();
export const MNP_FLOW_POSTPAID: string = iposRoutes.MNP_FLOW_POSTPAID.createLink();
export const MNP_FLOW_MSISDN_POSTPAID: string = iposRoutes.MNP_FLOW_MSISDN_POSTPAID.createLink();
export const MNP_FLOW_PLAN_POSTPAID: string = iposRoutes.MNP_FLOW_PLAN_POSTPAID.createLink();
export const MNP_FLOW_OPTION_POSTPAID: string = iposRoutes.MNP_FLOW_OPTION_POSTPAID.createLink();
export const MNP_FLOW_CONTACT_INFO_POSTPAID: string = iposRoutes.MNP_FLOW_CONTACT_INFO_POSTPAID.createLink();
export const MNP_FLOW_SUMMARY_POSTPAID: string = iposRoutes.MNP_FLOW_SUMMARY_POSTPAID.createLink();
export const MNP_FLOW_BILLING_ACCOUNT_POSTPAID: string = iposRoutes.MNP_FLOW_BILLING_ACCOUNT_POSTPAID.createLink();
export const MNP_FLOW_ICC_POSTPAID: string = iposRoutes.MNP_FLOW_ICC_POSTPAID.createLink();
export const MNP_FLOW_EXISTING_CONTACT_INFO_POSTPAID: string = iposRoutes.MNP_FLOW_EXISTING_CONTACT_INFO_POSTPAID.createLink();