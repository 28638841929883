import React, { FC, useCallback, useState, useMemo } from "react";
import { FttbStatus } from "./Components/FTTBStatus";
import { RouteComponentProps, StaticContext } from "react-router";
import { FTTB_FLOW_PLAN_PATH, INDEX_PATH } from "../../Routes/FttbFlowRoutes";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { AddressValidationForm } from "./Components/AddressValidationForm";
import { FttbFlow } from "../../Routes/FttbFlow";
import {
	Query,
	Mutation,
	MutationReserve_MsisdnArgs,
	QuerySubscriptionsArgs,
	LineTypeEnum, Maybe, GisFlat
} from "../../../../graphql/types";
import { GET_SINGLE_MSISDNS } from "../../../../graphql/queries/msisdn";
import { RESERVE_MSISDN } from "../../../../graphql/mutations/subscription";
import { Address as AddressType } from "../../../../graphql/localTypes";
import { NavigationHeader } from "../../../../shared/components/NavigationHeader";
import { ErrorMessage } from "../../../../shared/components/ErrorMessageTemplate";
import { useIntl } from "react-intl";
import { FTTBMessages } from "../../FTTB.messages";
import {ACTIVE, config} from "../../../../config/constants";
import { addressValidation } from "./Address.schema";
import { FormikHandlers, FormikHelpers, FormikState, useFormik } from "formik";
import { initialLabels } from "../../../../shared/utils";
import RegistrationMessages from "../../../registration/Registration.messages";
import { InputFieldFormik, CircularProgress, SuccessMessageTemplate } from "../../../../shared/components";
import { FttbRouteState, FttbAddressForm } from "../../fttb.types";
import { addPrefixToMsisdn, validateMsisdn } from "../../../../shared/utils/utils";
import { GET_SUBSCRIPTIONS } from "../../../../graphql/queries/subscription";
import { debounce } from "lodash";
import { UserNameInfo } from "../../../../shared/components/formBlocks/UserNameInfo";
import { useGisHousePreviousActivationStatus } from "../../../../shared/hooks/useGisHousePreviousActivationStatus";
import { SimpleConnection } from "../../../../shared/components/SimpleConnection";
import {Gigabit} from "../../../../shared/components/Gigabit";

const formId = "fttb-address-form";

const Address: FC<RouteComponentProps<never, StaticContext, FttbRouteState>> = props => {
	const {location, history} = props;
	const {state} = location;
	const intl = useIntl();
	const [fttbState, setFttbState] = useState<FttbRouteState| undefined>(state);
	const [error, setErorr] = useState<string>("");
	const [msisdnValidationErorr, setMsisdnValidationErorr] = useState<string>("");
	const [connectionExist, setConnectionExist] = useState<string>("");

	const [getSubscriptiions, {loading: loadingGetSubscriptions}] = useLazyQuery<Required<Pick<Query, "subscriptions">>, QuerySubscriptionsArgs>(GET_SUBSCRIPTIONS, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			if (!data.subscriptions || data.subscriptions?.length === 0) {
				setMsisdnValidationErorr("");
			}
            data.subscriptions?.forEach(sub => {
            	if (sub?.status === ACTIVE
                    && (sub?.subscription_type?.line_type === LineTypeEnum.Fttb || sub?.subscription_type?.line_type === LineTypeEnum.Fmc)) {
            		setMsisdnValidationErorr(intl.formatMessage(FTTBMessages.fttbConnectionExist));
            	}
            });
		}
	});
	const getAllSub = useCallback(debounce((contactPhone: string) => {
		getSubscriptiions({
			variables: {
				contact_phone: contactPhone
			}
		});
	}, 600), []);
	const [housePreviousActivationStatus, setPreviousHouseActivationStatus] = useState<boolean>(Boolean(state?.housePreviousActivationStatus));
	const [msisdnWithMaxLastRCDate, setMsisdnWithMaxLastRCDate] = useState<string>((state?.msisdnWithMaxLastRCDate as string) ?? "");
	const [isGigabitAvailable, setGigabitAvailable] = useState<boolean>(Boolean(state?.isGigabitAvailable));

	const [reserveMsisdn, {loading: loadingMsisdn}] = useMutation<Required<Pick<Mutation, "reserve_msisdn">>, MutationReserve_MsisdnArgs>(RESERVE_MSISDN);
	const initialValues = {
		last_name: state?.last_name || "",
		first_name: state?.first_name || "",
		middle_name: state?.middle_name || "",
		contact_phone: state?.contact_phone || "",
		city: state?.address?.city || "",
		street: state?.address?.street || "",
		house_number: state?.address?.house || "",
		gisProvinceId: state?.address?.gisProvinceId || "",
		gisCityId: state?.address?.gisCityId || "",
		gisStreetId: state?.address?.gisStreetId || "",
		gisHouseId: state?.address?.gisHouseId || "",
		gisZipId: state?.address?.gisZipId || "",
		flat: state?.address?.flat || "",
		province: state?.address?.province || "",
		simpleConnection: state?.address?.simpleConnection || false,
		msisdnWithMaxLastRCDate: state?.msisdnWithMaxLastRCDate || "",
		isGigabitAvailable: state?.isGigabitAvailable || false
	};

	const [handleMsisdnPart] = useLazyQuery<Required<Pick<Query, "msisdns">>>(GET_SINGLE_MSISDNS, {
		fetchPolicy: "no-cache",
		onCompleted: ({ msisdns }) => {
			const msisdn = msisdns?.[0]?.msisdn;
			if (msisdn) {
				reserveMsisdn({
					variables: {msisdn}
				}).then(async ({data}) => {
					const reservation_id = data?.reserve_msisdn?.attributes?.reservation_id;
					if (reservation_id) {
						history.push({
							pathname: FTTB_FLOW_PLAN_PATH,
							state: {
								...fttbState!,
								msisdn,
								confirmation_code: reservation_id,
								housePreviousActivationStatus: housePreviousActivationStatus,
							}
						});
					} else {
						setErorr(intl.formatMessage(FTTBMessages.msisdnReservationFail));
					}
				});
			} else {
				setErorr(intl.formatMessage(FTTBMessages.inventoryNoMsisdn));
			}

		}
	});
	const [hasAvailableHouse, setAvailableHouse] = useState<null | boolean>(!!fttbState?.address?.house || null);
	const [networkType, setNetworktype] = useState("");

	const onSubmit = (values: FttbAddressForm) => {
		setErorr("");
		if (fttbState?.address?.gisHouseId && fttbState?.confirmation_code && fttbState?.msisdn) {
			history.push(FTTB_FLOW_PLAN_PATH);
		}
		if (hasAvailableHouse) {
			const fttb = {
				...state,
				contact_phone: values.contact_phone,
				last_name: values.last_name,
				first_name: values.first_name,
				middle_name: values.middle_name,
				msisdnWithMaxLastRCDate: msisdnWithMaxLastRCDate,
				isGigabitAvailable: isGigabitAvailable,
				housePreviousActivationStatus: housePreviousActivationStatus,
				networkType: networkType,
				address: {
					country: config.DEFAULT_COUNTRY_NAME,
					country_code: config.DEFAULT_COUNTRY,
					city: values.city,
					gisCityId: values.gisCityId,
					gisStreetId: values.gisStreetId,
					street: values.street,
					house: values.house_number,
					gisHouseId: values.gisHouseId,
					gisZipId: values.gisZipId,
					flat: values.flat,
					province: values.province,
					gisProvinceId: values.gisProvinceId,
					simpleConnection: values.simpleConnection
				} as AddressType
			};
			if (state?.confirmation_code && state?.msisdn) {
				history.push({
					pathname: FTTB_FLOW_PLAN_PATH,
					state: {
						...fttb,
					}
				});
			} else {
				setFttbState(fttb);
				handleMsisdnPart();
			}

		}
	};
	const onClickBack = useCallback(() => history.push(INDEX_PATH), []);
	const validationSchema = useMemo(() => addressValidation(intl), [intl.locale]);
	const labels = useMemo(() => initialLabels<any>(intl, initialValues, RegistrationMessages), [intl.locale]);

	const {errors, setFieldValue, touched, handleChange, values, handleBlur, handleSubmit} = useFormik<FttbAddressForm>({
		initialValues: initialValues, onSubmit, validationSchema
	});
	const formik = {errors, setFieldValue, touched, handleChange, values, handleBlur};
	const {validate: validatePreviousActivationStatus, loading:  loadingPreviousActivationStatus, data} = useGisHousePreviousActivationStatus({
		setPreviousHouseActivationStatus,
		setConnectionExist,
		setFieldValue
	});

	React.useEffect(() => {
		if (data?.gis_house_previous_activation_status?.msisdn_with_max_last_rc_date) {
			const msisdn_with_max_last_rc_date = data.gis_house_previous_activation_status?.msisdn_with_max_last_rc_date ?? "";
			setMsisdnWithMaxLastRCDate(msisdn_with_max_last_rc_date);
		}else setMsisdnWithMaxLastRCDate("");
	}, [data]);

	const onChangeContactPhone = (fieldName, setFieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = (e.target.value).trim();
		setFieldValue(fieldName, value);
		const contactPhone = addPrefixToMsisdn(value);
		const [status] = validateMsisdn(contactPhone, intl);
		if (status) {
			getAllSub(contactPhone);
		} else {
			setMsisdnValidationErorr("");
		}
	};
	const disableNextButton = loadingMsisdn || loadingPreviousActivationStatus;
	return (
		<>
			<NavigationHeader FlowComp={FttbFlow}  back={onClickBack} formId={formId} disabledNext={disableNextButton}/>
			<div className="ipos-content-box">
				<form id={formId} onSubmit={handleSubmit}>
					<div className={"row"}>
						<div className="col-12 col-sm-6">
							<InputFieldFormik
								required
								name="contact_phone"
								id="contact_phone"
								attr={{maxLength: config.PHONE_LENGTH}}
								label={labels.contact_phone}
								value={formik.values.contact_phone}
								onChange={onChangeContactPhone}
								formik={formik}/>
						</div>
						<UserNameInfo formik={formik as (FormikState<FttbAddressForm> & FormikHandlers & FormikHelpers<FttbAddressForm>)}/>
						<SuccessMessageTemplate show={msisdnValidationErorr}>{msisdnValidationErorr}</SuccessMessageTemplate>
					</div>
					<AddressValidationForm
						hasAvailableHouse={hasAvailableHouse}
						setAvailableHouse={setAvailableHouse}
						formik={formik}
						validatePreviousActivationStatus={validatePreviousActivationStatus}
						setPreviousHouseActivationStatus={setPreviousHouseActivationStatus}
						setGigabitAvailable={setGigabitAvailable}
						checkGigabitEligibility={true}
						setNetworktype={setNetworktype}
					/>
					<div className={"mt-2"}>
						<ErrorMessage error={error}/>
					</div>
					<div className={"mt-2"}>
						{!error &&
                        <FttbStatus values={formik.values} availableHouse={hasAvailableHouse} networkType={networkType}/>}
					</div>
					{housePreviousActivationStatus && hasAvailableHouse &&
						<SimpleConnection connectionExist={connectionExist} formik={formik}/>}
					{isGigabitAvailable && <Gigabit/>}
				</form>
				{(loadingMsisdn || loadingPreviousActivationStatus || loadingGetSubscriptions) && (<CircularProgress/>)}

			</div>
		</>);
};


export { Address };
