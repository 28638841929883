import React from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CommonMessages } from "../../Common.messages";
import { HANDLE_MODAL } from "../../graphql/mutations";
import { GET_MODAL } from "../../graphql/queries";
import { ModalStatusArgs } from "../../graphql/localTypes";
import { FormattedMessage, useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";

const ModalView = () => {
	const { data } = useQuery<ModalStatusArgs>(GET_MODAL);
	const intl = useIntl();
	const [handleModalStatus] = useMutation(HANDLE_MODAL,{ variables: { status: false } });
	const onClose = () => handleModalStatus();
	let message;
	if (data?.modal.message.includes("[GraphQL error]")) {
		message = intl.formatMessage({...CommonMessages.serverError});
	} else if (data?.modal.message.includes("[Network error]")) {
		message = intl.formatMessage({...CommonMessages.serverError});
	} else {
		message = data?.modal.message;
	}
	return (
		<Modal
			show={data?.modal?.status}
			onHide={handleModalStatus}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			size="sm"
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{ data?.modal?.title || <FormattedMessage {...CommonMessages.error} />}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{message}
			</Modal.Body>
			<Modal.Footer>
				<button type="button" className="btn btn-secondary" onClick={onClose}>
					<FormattedMessage {...CommonMessages.close} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export { ModalView };
