import React from "react";
import { MsisdnTopBarInfo } from "../../../shared/components";
import { Switch } from "react-router";
import { ErrorBoundaryRoute } from "../../../shared/error/ErrorBoundaryRoute";
import { Plan, Options, Summary, Address } from "../Pages";
import {
	FTTB_FLOW_ADDRESS_PATH,
	FTTB_FLOW_PLAN_PATH,
	FTTB_FLOW_OPTIONS_PATH,
	FTTB_FLOW_SUMMARY_PATH,
} from "./FttbFlowRoutes";
import { FormattedMessage } from "react-intl";
import { FTTBMessages } from "../FTTB.messages";

const FttbPageRouter = () => {
	return (
		<>
			<MsisdnTopBarInfo>
				<FormattedMessage {...FTTBMessages.fttbConnection} />
			</MsisdnTopBarInfo>
			<div id="w-app-body">
				<Switch>
					<ErrorBoundaryRoute exact path={FTTB_FLOW_ADDRESS_PATH} component={Address}/>
					<ErrorBoundaryRoute path={FTTB_FLOW_OPTIONS_PATH} component={Options}/>
					<ErrorBoundaryRoute path={FTTB_FLOW_PLAN_PATH} component={Plan}/>
					<ErrorBoundaryRoute path={FTTB_FLOW_SUMMARY_PATH} component={Summary}/>
				</Switch>
			</div>
		</>
	);
};

export { FttbPageRouter };