import { FC, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { FormattedMessage, useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";
import { CommonMessages } from "../../../../Common.messages";
import { InputFieldFormik } from "../../InputField";
import { useFormik } from "formik";
import { otpValidationSchema } from "../../../../modules/fmc/Pages/SearchSubscription/SearchSubscrition.schema";
import {  Mutation, MutationCas_Request_OtpArgs } from "../../../../graphql/types";
import { CAS_REQUEST_OTP } from "../../../../graphql/queries/otp";
import { HANDLE_MODAL_OTP_CLOSE, HANDLE_SET_OTP } from "../../../../graphql/mutations/localMutation";
import { LocalMutation, LocalQuery, MutationHandleOtpSetArgs } from "../../../../graphql/localTypes";
import { GET_OTP_MODAL } from "../../../../graphql/queries/localQuery";
import cssns from "../../../utils/cssnsConfig";
import { OtpRef } from "../../Otp";
const { React } = cssns("CasOtpCheck");

const CasOtpCheck:FC = () => {
	const intl = useIntl();
	const [casRequestOtp] = useMutation<Required<Pick<Mutation, "cas_request_otp">>, MutationCas_Request_OtpArgs>(CAS_REQUEST_OTP);
	const {data} = useQuery<Required<Pick<LocalQuery, "modals">>>(GET_OTP_MODAL);
	const msisdn = data?.modals?.otp?.msisdn;
	const status = data?.modals?.otp?.status;
	const loading = data?.modals?.otp?.loading;
	const error = data?.modals?.otp?.error;
	useEffect(()=> {
		if (msisdn) {
			casRequestOtp({variables: {msisdn}});
		}
	}, [msisdn]);
	const [onClose] = useMutation(HANDLE_MODAL_OTP_CLOSE);
	const [setOtp] = useMutation<LocalMutation, MutationHandleOtpSetArgs>(HANDLE_SET_OTP);

	const formikOtp = useFormik({
		initialValues: { otp: ""},
		onSubmit: ({otp: code}: {otp: string}) => {
			setOtp({variables: {code}});
		},
		validationSchema: otpValidationSchema(intl),
	});
	return (
		<Modal
			className="this"
			show={status}
			onHide={onClose}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			size="sm"
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<FormattedMessage {...CommonMessages.validateOtp} />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={formikOtp.handleSubmit}>
					<div className="row">
						<div className="col-12">
							<InputFieldFormik
								required
								name="otp"
								id="otp"
								value={formikOtp.values.otp}
								formik={formikOtp}
								placeholder={intl.formatMessage({...CommonMessages.otpConfirmation})}
							/>
						</div>
						<div className="col-12">
							<div role="group"  className="btn-group align-items-start">
								<button type="submit" className="btn btn-outline-primary btn-validate" disabled={loading}>
									{loading ? <i className="fas fa-spinner fa-spin mr-2" aria-hidden="true"/>
										: <FormattedMessage {...CommonMessages.validateOtp} />}
								</button>
								<OtpRef buttonType="button" onClick={() =>  msisdn && casRequestOtp({variables: {msisdn}})}/>
							</div>
						</div>
						<div className="col-12">
							<div className="alert alert-success">
								<FormattedMessage {...CommonMessages.otpWasSent} />
							</div>
						</div>
						{error && <div className="col-12">
							<div className="alert alert-danger">
								<FormattedMessage {...CommonMessages.otpValidationError} />
							</div>
						</div>}
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<button type="button" className="btn btn-secondary" onClick={() => onClose()}>
					<FormattedMessage {...CommonMessages.close}/>
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export { CasOtpCheck };
