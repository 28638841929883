import React from "react";
import { client } from "./apollo";
import { ApolloProvider } from "@apollo/react-hooks";
import { ErrorBoundary } from "./shared/error/ErrorBoundary";
import { AppBodyRoutes } from "./routes/AppBodyRoutes";
import { Router } from "react-router";
import { ModalView } from "./shared/components";
import { Footer } from "./shared/layout/footer/footer";
import { hot } from "react-hot-loader";
import { AuthentificationProvider } from "./shared/hooks";
import { IdentityModal } from "./shared/components/IdenityModal";
import { createBrowserHistory } from "history";
import { Modals } from "./shared/components/modals/Modals";

export const history = createBrowserHistory();


const App: React.FC = () => {
	return (
		<ApolloProvider client={client}>
			<ModalView />
			<Modals/>
			<Router history={history} >
				<AuthentificationProvider>
					<IdentityModal/>
					<ErrorBoundary>
						<AppBodyRoutes />
					</ErrorBoundary>
					<Footer />
				</AuthentificationProvider>
			</Router>
		</ApolloProvider>
	);
};

export default process.env.NODE_ENV === "development" ? hot(module)(App) : App;
