import React, { useCallback, useEffect, useState } from "react";
import {
	ACQUISITION_EXISTING_FLOW_ICCID_PATH,
	ACQUISITION_EXISTING_FLOW_PLAN_PATH
} from "../../Router/AcquisitionExistingFlowPath";
import { useLazyQuery } from "@apollo/react-hooks";
import { Maybe, Package, ProductFamily, Query } from "../../../../../graphql/types";
import {GET_OPTIONAL_CHILD_PRODUCTS, GET_OPTIONAL_PRODUCTS} from "../../../../../graphql/queries/acquisitionFlow";
import { CircularProgress, NavigationHeader } from "../../../../../shared/components";
import { FormattedMessage } from "react-intl";
import { ChangePlanMessages } from "../../../../change-bar/plan/ChangePlan.messages";
import { OptionsFamilies } from "../../../NewCustomer/Pages/OptionsPage/OptionsFamilies";
import { OptionProductChecked } from "../../../NewCustomer/Pages/OptionsPage/OptionProduct";
import { RouteComponentProps, StaticContext } from "react-router";
import { AcquisitionExistingFlow } from "../../Router/AcquisitionExistingFlow";
import { ExistingCustomerRouteState } from "../Summary/Summary";
import { prepareUncheckClick } from "../../../../../shared/utils";

const Options = (props: RouteComponentProps<never, StaticContext, ExistingCustomerRouteState>) => {
	const { location, history } = props;
	const { state } = location;
	const [optionalProduct, setOptionalProduct] = useState<Package[]>(state?.options || []);
	const [getOptional, {data, loading}] = useLazyQuery<Query>(GET_OPTIONAL_PRODUCTS);
	const [isParentChecked, setIsParentChecked] = useState<boolean>(false);
	const [parentCode, setParentCode] = useState<string | null | undefined>("");
	useEffect(() => {
		if(state?.plan?.id) {
			getOptional({variables: { subscription_type_id: state?.plan?.id }});
		}
	}, []);

	const [getChildOffers, {data: childOffersData, loading: loadingChildOffers}] = useLazyQuery<Query>(GET_OPTIONAL_CHILD_PRODUCTS);

	const onClickNext = useCallback(() => {
		history.push(ACQUISITION_EXISTING_FLOW_ICCID_PATH, {
			...state,
			options: optionalProduct,
		});
	}, [optionalProduct]);

	const onClickBack = useCallback(() => history.push(ACQUISITION_EXISTING_FLOW_PLAN_PATH, state), []);
	const onChecked = (value: OptionProductChecked) => {
		setIsParentChecked(value.checked);
		setParentCode(value.optionalProduct.code);
		getChildOffers({ variables: { subscription_type_id: state?.plan?.id, parent_product: Number(value.optionalProduct.id), relation_type: "all" }});
		if (value.checked) {
			setOptionalProduct([...optionalProduct, value.optionalProduct]);
		} else {
			if (!loadingChildOffers) {
				setOptionalProduct(optionalProduct.filter((pack) => {
					return  prepareUncheckClick(pack, value, childOffersData);
				}));
			}
		}
	};

	const onChildChecked = (value: OptionProductChecked) => {
		if (value.checked) {
			setOptionalProduct([...optionalProduct, value.optionalProduct]);
		} else {
			setOptionalProduct(optionalProduct.filter((pack) => pack.code !== value.optionalProduct.code));
		}
	};
	const available_child_offers = childOffersData?.child_offers;

	return (
		<>
			<NavigationHeader FlowComp={AcquisitionExistingFlow} next={onClickNext} back={onClickBack} />
			<div className="ipos-content-box">
				{loading ? <CircularProgress/>: !(data?.optional_products?.length! > 0) && (
					<div className="column">
						<FormattedMessage {...ChangePlanMessages.optionalProductsNotFound} />
					</div>)}
				<table className="table w-tile-table">
					{data?.optional_products?.map((family: Maybe<ProductFamily>) => (
                        family?.packages?.length && family.packages.length > 0) &&
                        <OptionsFamilies
                        	key={family.id!}
                        	family={family}
                        	onChecked={onChecked}
                        	isParentChecked={isParentChecked}
                        	childOffersData={available_child_offers}
                        	parentCode={parentCode}
							onChildChecked={onChildChecked}
							loadingChildOffers={loadingChildOffers}
                        	selectedOptionalProduct={optionalProduct}
                        />
					)}
				</table>
			</div>
		</>
	);
};

export { Options };