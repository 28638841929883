import React from "react";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useCustomIntlContext } from "../../shared/hooks/CustomIntlContextProvider";
import { DropdownLanguageMessages } from "./DropdownLanguage.messages";
import { config } from "../../config/constants";
const languages = config.LANGUAGES;

const DropdownLanguage = (props) => {
	const {lang, selectLanguage } = useCustomIntlContext();
	const handleSelect = (key: string) => {
		selectLanguage(key);
	};

	return (
		<Dropdown onSelect={handleSelect}>
			<Dropdown.Toggle variant="outline-light" className={props.className || "btn btn-sm"} id="dropdown-language">
				<FormattedMessage {...DropdownLanguageMessages[lang]} />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{languages.map((langItem) => (
					<Dropdown.Item key={langItem} className="dropdown-item" eventKey={langItem}>
						<FormattedMessage {...DropdownLanguageMessages[langItem]} />
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export {
	DropdownLanguage
};
