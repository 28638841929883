import React, {useState} from "react";
import { FormattedMessage } from "react-intl";
import { MnpMessages } from "./Mnp.messages";
import { useHistory } from "react-router";
import {MNP_FLOW_MSISDN_PATH, MNPRouteState} from "./MNPFlowRoutes";
import {MNP_FLOW_MSISDN_POSTPAID} from "./postpaidMnp/MNPFlowRoutes";
import {useNavigationState, useUpdateNavigatioState} from "../../shared/components/navigation/useNavigationState";
import {SearchExistingCustomer} from "../acquisitionFlow/ExistingCustomer/Main/SearchExistingCustomer";

const MainMnp = () => {
	const history = useHistory();
	const [showCustomerType, setShowCustomerType] = useState<boolean>(false);
	const [showSearchExistingCustomer, setShowSearchExistingCustomer] = useState<boolean>(false);
	const onClickPostpaidMnp = () => setShowCustomerType(!showCustomerType);
	const state = useNavigationState<MNPRouteState>();
	const updateNavigationState  = useUpdateNavigatioState<MNPRouteState>();
	const onCustomerTypeClick = isNewCustomer => {
		updateNavigationState( {
			...state,
			isNewCustomer
		} as MNPRouteState);
		if (isNewCustomer) {
			setShowSearchExistingCustomer(false);
			history.push({
				pathname: MNP_FLOW_MSISDN_POSTPAID,
			});
		} else setShowSearchExistingCustomer(!showSearchExistingCustomer);
	};

	return (
		<>
			<button
				className="btn btn-block btn-secondary"
				onClick={() => history.push(MNP_FLOW_MSISDN_PATH)}
			>
				<FormattedMessage {...MnpMessages.numberPortability} />
			</button>
			<button
				className="btn btn-block btn-secondary"
				onClick={onClickPostpaidMnp}
			>
				<FormattedMessage {...MnpMessages.numberPortabilityPostpaid} />
			</button>
			{ showCustomerType &&
				<>
					<div className="row mt-2">
						<div className="col-6">
							<button className="btn btn-block btn-primary" onClick={() => onCustomerTypeClick(true)}>
								<FormattedMessage {...MnpMessages.newCustomer} />
							</button>
						</div>
						<div className="col-6">
							<button className="btn btn-block btn-primary" onClick={() => onCustomerTypeClick(false)}>
								<FormattedMessage {...MnpMessages.existingCustomer} />
							</button>
						</div>
					</div>
					{showSearchExistingCustomer && <SearchExistingCustomer mnpPostpaid={true} />}
				</>
			}
		</>
	);
};

export { MainMnp };