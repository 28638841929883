import { errorActions, StepActionPayload } from "../components/navigation/StepStateProvider";

interface Validate {
	next: (s: boolean|null|undefined|string, c: string) => Test;
}
interface Test {
	next: (s: boolean|null|undefined|string, c: string) => Test;
	dispatch: (msisdn: string, dispatch:  React.Dispatch<StepActionPayload>) => void;
} 
class MsisdnErrorsUtils {
	static validate(statment: boolean|null|undefined|string, code: string): Validate {
		const errors: string[] = [];
		if (statment) {
			errors.push(code);
		}
		const test = (statment: boolean|null|undefined|string, code: string): Test => {
			if (statment) {
				errors.push(code);
			}
			return {
				next: (s, c) => test(s, c),
				dispatch: (msisdn, dispatch) => dispatch(errorActions.setMsisdnErrors({[msisdn]: errors}))
			};
		};
		return {
			next: (s, c) => test(s, c),
		};
	}
	
	static removeMsisdnErrors(payload: string, msisdnErrors: Record<string, string[]>|undefined): undefined|Record<string, string[]> {
		if (!msisdnErrors) {
			return msisdnErrors;
		}
		const { [payload]: removed, ...result} = msisdnErrors;
		return result;
	}
	static joinMsisdnErrors(payload: Record<string, string[]>, msisdnErrors: Record<string, string[]>|undefined): Record<string, string[]> {
		return {...msisdnErrors, ...Object.keys(payload).reduce((acc, key) => {
			if (!msisdnErrors?.[key]) {
				return {...acc, ...(payload[key].length > 0 && {[key]: payload[key]})};
			}
			const errors = Array.from(new Set(payload[key].concat(msisdnErrors[key])));
			return {
				...acc,
				...(errors.length > 0  && {[key]: errors})
			};
		}, {})};
	}
}

export {
	MsisdnErrorsUtils
};