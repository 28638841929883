import React, {FC, useState} from "react";
import {StepWrapper} from "../../../../shared/components/navigation/StepWrapper";
import {B2BFttbRouteState} from "./b2bFttb.type";
import {RouteComponentProps, StaticContext} from "react-router";
import {B2B_BILLING_ACCOUNT_TYPE, BILLING_EXISTING_ACCOUNT, BILLING_NEW_ACCOUNT} from "../../../../config/constants";
import {FormattedMessage} from "react-intl";
import {AcquisitionMessages} from "../../../acquisitionFlow/Acquisition.messages";
import {useFormik} from "formik";
import {B2B_FTTB_FLOW_BILLING_ACCOUNT_PATH} from "../Routes/B2BFttbFlowRoutes";
import {BillingAccount as ExistingBillingAccount} from "../../../acquisitionFlow/ExistingCustomer/Pages/Billing/BillingAccount";
import {useMutation} from "@apollo/react-hooks";
import {Mutation} from "../../../../graphql/types";
import {GET_RESERVED_BILLING_ACCOUNT_ID} from "../../../../graphql/queries/company";
import {CircularProgress, HasPermission} from "../../../../shared/components";
import {PermissionRolesEnum} from "../../../../shared/types";
import { useNavigationState, useUpdateNavigatioState } from "../../../../shared/components/navigation/useNavigationState";
import {DeliveryZoneDropdown} from "../../../../shared/components/DeliveryZoneDropdown";

const formId = "fttb-b2b-billing-account-form-id";

const BillingAccount:FC<RouteComponentProps<never, StaticContext, B2BFttbRouteState>> = (props) => {
	const state = useNavigationState<B2BFttbRouteState>();
	const updateNavigationState  = useUpdateNavigatioState<B2BFttbRouteState>();

	const { history } = props;
	const [billingStatus, setShowBilling] = useState<string>(state?.billing_status || "");
	const [reserveBillingAccountId, { loading }] = useMutation<Required<Pick<Mutation, "billing_account_id_reservations">>>(GET_RESERVED_BILLING_ACCOUNT_ID);

	const onChange = (setFieldValue) => (e) => {
		const billingStatus = e.target.value;
		if (billingStatus === BILLING_NEW_ACCOUNT) {
			reserveBillingAccountId({
				variables: {}
			}).then(async ({data}) => {
				setFieldValue("account_id", data?.billing_account_id_reservations?.request_id);
				setFieldValue("billing_type", B2B_BILLING_ACCOUNT_TYPE);
			});
		}else if(billingStatus === BILLING_EXISTING_ACCOUNT){
			setFieldValue("delivery_zone","");
		}
		setFieldValue("status", billingStatus);
		setShowBilling(billingStatus);
	};

	const onSubmit = (value) => {
		const {status, account_id, billing_type, delivery_zone} = value;

		if (billingStatus && BILLING_EXISTING_ACCOUNT === billingStatus) {
			updateNavigationState({
				...state,
				billing_status: status,
				delivery_zone:"",
				account_id,
				billing_type,
			});
			value.account_id && history.push({
				pathname: B2B_FTTB_FLOW_BILLING_ACCOUNT_PATH,
			});


		} else if (billingStatus && BILLING_NEW_ACCOUNT === billingStatus) {
			updateNavigationState({
				...state,
				billing_status: status,
				delivery_zone: delivery_zone,
				account_id,
			});
			history.push(B2B_FTTB_FLOW_BILLING_ACCOUNT_PATH);
		}
	};

	const formik = useFormik({
		initialValues: {
			status: billingStatus || "",
			account_id: state?.account_id || "",
			billing_type: state?.billing_type || "",
			delivery_zone: state?.delivery_zone || ""
		},
		onSubmit
	});

	const {values,setFieldValue, handleSubmit} = formik;

	const company = state?.companies?.find(item => item.id === state?.company_id);

	const statusOfNext = Boolean(
		values.status === BILLING_EXISTING_ACCOUNT
			? true
			: values.status === BILLING_NEW_ACCOUNT
				? Boolean(values.delivery_zone)
				: false
	);


	return (
		<StepWrapper<B2BFttbRouteState> isValid={statusOfNext} newState={{
				billing_status: values.status,
				billing_type: values.billing_type,
				account_id: values.account_id,
			...(values.status === BILLING_NEW_ACCOUNT ? { delivery_zone: values.delivery_zone } : {})
			}}>
			<div className="ipos-content-box">
				<form id={formId} onSubmit={handleSubmit}>
					<div className="custom-control custom-radio">
						<HasPermission accessFor={[PermissionRolesEnum.BILLING_ACCOUNT_ID_RESERVATION]}>
							<div>
								<input
									className="custom-control-input"
									name="status"
									type="radio"
									id={"my-radio-billing-first"}
									value={BILLING_NEW_ACCOUNT}
									checked={values.status === BILLING_NEW_ACCOUNT}
									onChange={onChange(setFieldValue)}
								/>
								<label className="custom-control-label" htmlFor={"my-radio-billing-first"}>
									<FormattedMessage {...AcquisitionMessages.createBilling} />
								</label>
							</div>
							{ billingStatus === BILLING_NEW_ACCOUNT && <div className="mt-2"><DeliveryZoneDropdown formik={formik}/></div> }
							{loading && <CircularProgress/>}
						</HasPermission>
						<div>
							<input
								className="custom-control-input"
								name="status"
								type="radio"
								value={BILLING_EXISTING_ACCOUNT}
								checked={values.status === BILLING_EXISTING_ACCOUNT}
								id={"my-radio-billing-second"}
								onChange={onChange(setFieldValue)}
							/>
							<label className="custom-control-label" htmlFor={"my-radio-billing-second"}>
								<FormattedMessage {...AcquisitionMessages.chooseBilling} />
							</label>
						</div>
						{billingStatus === BILLING_EXISTING_ACCOUNT && (
							<div className="mt-2">
								<ExistingBillingAccount
									setFieldValue={setFieldValue}
									account_id={values.account_id}
									customer={company}
									isB2bBillingAccount={true}
								/>
							</div>
						)}
					</div>
				</form>
			</div>
		</StepWrapper>
	);
};

export { BillingAccount };
