import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { CommonMessages } from "../../../Common.messages";
import { CustomerNavigationPro } from "./CustomerNavigationPro";
import { useStoreContext } from "./StepStateProvider";

interface NavigationHeaderProps {
    back: () => void;
    next: () => void;
    isValid: boolean;
}

const NavHeader = React.memo((props: NavigationHeaderProps) => {
	const { isValid, next, back,} = props;
	const {pathname} = useLocation();
	const {state: {
		isFlowDone,
		paths
	}} = useStoreContext();
	const lastStep = paths?.slice(-1)?.[0].pathname === pathname;
	return (<>
		<header className="ipos-flow-header">
			<nav className="ipos-flow-header-buttons">
				<button hidden={isFlowDone} type="button" className="btn btn-outline-primary" onClick={back}>
					<FormattedMessage {...CommonMessages.back} />
				</button>
				<button hidden={lastStep} disabled={!isValid} type="button" className="btn btn-primary" onClick={next}>
					<FormattedMessage {...CommonMessages.next} />
				</button>
			</nav>
			<CustomerNavigationPro/>
		</header>
	</>
	);
});

export { NavHeader };