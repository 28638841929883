import React, { FC, useMemo, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { RouteComponentProps, StaticContext } from "react-router";
import {
	FTTB_FLOW_ADDRESS_PATH,
	FTTB_FLOW_OPTIONS_PATH,
} from "../../Routes/FttbFlowRoutes";
import { useQuery } from "@apollo/react-hooks";
import { TableRowList } from "../../../change-bar/plan/ChangePlanRowList";
import { FormattedMessage } from "react-intl";
import { CircularProgress } from "../../../../shared/components/CircularProgress";
import { OfferSubscriptionProps, prepareFttbOfferings } from "../../../../shared/utils";
import { SelectedTariffPlanDetails } from "../../../../graphql/localTypes";
import { FTTBMessages } from "../../FTTB.messages";
import { FttbFlow } from "../../Routes/FttbFlow";
import { NavigationHeader } from "../../../../shared/components/NavigationHeader";
import { GET_FTTB_OFFERING } from "../../../../graphql/queries/acquisitionFlow";
import { Tabs, Tab } from "react-bootstrap";
import { Query, QueryOfferings_Under_CityArgs, SubscriptionTypeCategoryEnum, GisHouseGreen_FieldArgs, QueryGis_HousesArgs, LanguageEnum } from "../../../../graphql/types";
import { FttbRouteState } from "../../fttb.types";
import { GET_HOUSE_GREEN_FIELD } from "../../../../graphql/queries/gisSystem";

const Plan:FC<RouteComponentProps<never, StaticContext, FttbRouteState>> = (props) => {
	const {locale} = useIntl();
	const { location:{state}, history } = props;
	const [fttbState, setFttbState] = useState<FttbRouteState| undefined>(state);

	const {data, loading} = useQuery<Required<Pick<Query, "offerings_under_city">>, QueryOfferings_Under_CityArgs>(GET_FTTB_OFFERING, {
		fetchPolicy: "cache-and-network",
		skip: !state?.address?.gisCityId,
		variables: {
			gis_city_id: state?.address?.gisCityId	
		}
	});

	const {data: gisHouses, loading: gisHouseLoading } = useQuery<Required<Pick<Query, "gis_houses">>, GisHouseGreen_FieldArgs & QueryGis_HousesArgs>(GET_HOUSE_GREEN_FIELD, {
		variables: {
			language: locale as LanguageEnum,
			house_id: state.address.gisHouseId!,
			apartment: state.address.flat
		},
	});

	let offerings: (OfferSubscriptionProps | undefined)[] | undefined = useMemo(() => {
		if (data?.offerings_under_city) {
			const greenFieldTags = gisHouses?.gis_houses?.green_field?.green_field_tp;
			const greenFieldTpStatus = gisHouses?.gis_houses?.green_field?.green_field_tp_status;
			return prepareFttbOfferings(data.offerings_under_city, greenFieldTags, greenFieldTpStatus);
		} else {
			return [];
		}
	}, [data?.offerings_under_city, gisHouses]);

	const onClickNext = () => {
		if (fttbState?.selectedFttbTariffPlan?.code) {
			history.push({
				pathname: FTTB_FLOW_OPTIONS_PATH,
				state: fttbState,
			});
		}
	};
	const onClickBack = () => {
		history.push({
			pathname: FTTB_FLOW_ADDRESS_PATH,
			state,
		});
	};
	const handleSelectTariffPlan = (plan: SelectedTariffPlanDetails) => {
		setFttbState({
			...state,
			selectedFttbTariffPlan: plan,
			options: {
				packages: [],
				desiredConnectionDate: undefined,
				fttbFast: undefined,
			},
		});
	};
	if (!state?.msisdn) {
		history.push(FTTB_FLOW_ADDRESS_PATH);
	}
	const planList = (offerings: (OfferSubscriptionProps | undefined)[] | undefined = [], type: SubscriptionTypeCategoryEnum) => {
		return (
			<>{!loading && !(data?.offerings_under_city?.length && data.offerings_under_city.length > 0) &&
                <div><FormattedMessage {...FTTBMessages.plansNotFound} /></div>}
			{offerings
				.filter(item => item?.subscription_types?.subscription_type_category === type)
				.map(offerSubType => offerSubType?.subscription_types?.code &&
                        (<TableRowList
                        	balance={Infinity}
                        	key={offerSubType?.subscription_types?.code + "top-level"}
                        	tariffPlan={offerSubType?.subscription_types}
                        	offerId={offerSubType.offerId}
                        	offerCode={offerSubType.offerCode}
                        	offerSimCardCode={offerSubType.offerSimCardCode}
                        	selectTariffPlan={handleSelectTariffPlan}
                        	selected={fttbState?.selectedFttbTariffPlan?.code === offerSubType?.subscription_types.code}
                        />))}
			{loading && (<CircularProgress/>)}
			</>);
	};
	const defaulTab = state?.selectedFttbTariffPlan?.subscription_type_category || SubscriptionTypeCategoryEnum.Normal;

	return (
		<>
			<NavigationHeader FlowComp={FttbFlow} back={onClickBack} next={onClickNext} />
			<div className="ipos-content-box">
				<div className="w-box-tabset">
					<Tabs defaultActiveKey={defaulTab} id="uncontrolled-tab-example">
						<Tab eventKey={SubscriptionTypeCategoryEnum.Normal} title={<FormattedMessage {...FTTBMessages.planTypeNormal}/>}>
							{planList(offerings, SubscriptionTypeCategoryEnum.Normal)}
						</Tab>
						<Tab eventKey={SubscriptionTypeCategoryEnum.Convergent} title={<FormattedMessage {...FTTBMessages.planTypeConvergent}/>}>
							{planList(offerings, SubscriptionTypeCategoryEnum.Convergent)}
						</Tab>
					</Tabs>
				</div>
			</div>
		</>
	);
};

export { Plan };
