import React, { FC, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Msisdn } from "../../../../../graphql/types";
import { config } from "../../../../../config/constants";
import { INDEX_PATH, ACQUISITION_FLOW_PLAN_PATH } from "../../Routes/AcquisitionFlowRoutes";
import { CircularProgress, NavigationHeader } from "../../../../../shared/components";
import { useGetMsisdn } from "../../../../../shared/hooks";
import { MsisdnSearchResult } from "./MsisdnSearchResult";
import { AcquisitionFlow } from "../../Routes/AcquisitionFlow";
import { AcquisitionFlowState } from "../SummaryPage/SummaryPage";
import { useMsisdnReservation } from "../../../../../shared/hooks/useMsisdnReservation";

const MsisdnPage: FC = () => {
	const history = useHistory();
	const {state} = useLocation<AcquisitionFlowState>();
	const msisdn = state?.msisdn || "";
	const [newMsisdn, setNewMsisdn] = useState<string>(msisdn);
	const [data, loading] = useGetMsisdn();
	const reserveMsisdn = useMsisdnReservation(ACQUISITION_FLOW_PLAN_PATH, newMsisdn);

	const onClickNext = () => {
		reserveMsisdn();
	};

	const onClickBack = useCallback(() => history.push(INDEX_PATH), []);

	let msisdns: Array<Msisdn|null> = [];
	if (data) {
		msisdns = data.filter((item) => (item?.number_category === config.MSISDN_NORMAL_CATEGORY && item?.msisdn));
		if(msisdn && !msisdns.find((item) => item?.msisdn === msisdn)) {
			msisdns.push({msisdn: msisdn});
		}
	}
	return (
		<>
			<NavigationHeader FlowComp={AcquisitionFlow} next={onClickNext} back={onClickBack}/>
			<div className="ipos-content-box">
				<div className="msisdns-contant">
					{loading ? <CircularProgress/> : <MsisdnSearchResult setNewMsisdn={setNewMsisdn} msisdns={msisdns} newMsisdn={newMsisdn} />}
				</div>
			</div>
		</>
	);
};

export { MsisdnPage };
