import ApplicationRoute from "./ApplicationRoute";
const existingCustomerRoutesConfig = [
	"id-document",
	"additional",
] as const;

const newCustomerRoutesConfig = [
	"info",
	"address",
	"id-documents",
	"additional",
] as const;

const acquisitionFlowRoutesConfig = [
	"msisdn",
	"plan",
	"options",
	"iccid",
	"contact-info",
	"summary",
] as const;

const mnpFlowRoutesConfig = [
	"msisdn",
	"temporary-msisdn",
	"plan",
	"options",
	"contact-info",
	"summary",
] as const;

const mnpPostpaidFlowRoutesConfig = [
	"msisdn",
	"billing-aacount",
	"plan",
	"options",
	"iccid",
	"contact-info",
	"existing-contact-info",
	"summary",
] as const;

const acquisitionExistingFlowRoutesConfig = [
	"billing",
	"msisdn",
	"plan",
	"options",
	"iccid",
	"contact-info",
	"summary",
] as const;

const fttbFlowRoutesConfig = [
	"address",
	"plan",
	"options",
	"summary",
] as const;

const b2bFttbFlowRoutesConfig = [
	"address",
	"company",
	"plan",
	"billing-account",
	"summary",
] as const;

const fmcFlowRoutesConfig = [
	"search",
	"plan",
	"options",
	"summary",
] as const;
const fmcFlowWithoutFttbRoutesConfig = [
	"search",
	"address",
	"plan",
	"options",
	"summary",
] as const;

const iposRoutes = {
	INDEX: new ApplicationRoute("/"),
	LOGIN: new ApplicationRoute("/login"),
	LOGOUT: new ApplicationRoute("/logout"),
	ACTIVATION: new ApplicationRoute("/activation/:msisdn"),
	PERSONALIZATION_NEW_CUSTOMER: new ApplicationRoute("/personalization/new-customer/"),
	PERSONALIZATION_EXISTING_CUSTOMER_BASE: new ApplicationRoute("/personalization/existing-customer/"),
	PERSONALIZATION_NEW_CUSTOMER_PARAMS: new ApplicationRoute("/personalization/new-customer/:subscriptionId/:msisdn"),
	PERSONALIZATION_NEW_CUSTOMER_INFO: new ApplicationRoute(`/personalization/new-customer/:subscriptionId/:msisdn/${newCustomerRoutesConfig[0]}`),
	PERSONALIZATION_NEW_CUSTOMER_ADDRESS: new ApplicationRoute(`/personalization/new-customer/:subscriptionId/:msisdn/${newCustomerRoutesConfig[1]}`),
	PERSONALIZATION_NEW_CUSTOMER_ID_DOCUMENT: new ApplicationRoute(`/personalization/new-customer/:subscriptionId/:msisdn/${newCustomerRoutesConfig[2]}`),
	PERSONALIZATION_NEW_CUSTOMER_ADDITIONAL: new ApplicationRoute(`/personalization/new-customer/:subscriptionId/:msisdn/${newCustomerRoutesConfig[3]}`),
	PERSONALIZATION_EXISTING_CUSTOMER: new ApplicationRoute("/personalization/existing-customer/:subscriptionId/:msisdn"),
	PERSONALIZATION_EXISTING_CUSTOMER_ID_DOCUMENT: new ApplicationRoute(`/personalization/existing-customer/:subscriptionId/:msisdn/${existingCustomerRoutesConfig[0]}`),
	PERSONALIZATION_EXISTING_CUSTOMER_ADDITIONAL: new ApplicationRoute(`/personalization/existing-customer/:subscriptionId/:msisdn/${existingCustomerRoutesConfig[1]}`),
	PERSONALIZATION_CUSTOMER: new ApplicationRoute("/personalization/customer/:subscriptionId/:msisdn"),
	PERSONALIZATION: new ApplicationRoute("/personalization/:msisdn"),
	ACQUISITION_FLOW: new ApplicationRoute("/acquisition-flow"),
	ACQUISITION_EXISTING_FLOW: new ApplicationRoute("/acquisition-existing-flow"),
	ACQUISITION_EXISTING_FLOW_BILLING: new ApplicationRoute(`/acquisition-existing-flow/${acquisitionExistingFlowRoutesConfig[0]}`),
	ACQUISITION_EXISTING_FLOW_MSISDN: new ApplicationRoute(`/acquisition-existing-flow/${acquisitionExistingFlowRoutesConfig[1]}`),
	ACQUISITION_EXISTING_FLOW_PLAN: new ApplicationRoute(`/acquisition-existing-flow/${acquisitionExistingFlowRoutesConfig[2]}`),
	ACQUISITION_EXISTING_FLOW_OPTIONS: new ApplicationRoute(`/acquisition-existing-flow/${acquisitionExistingFlowRoutesConfig[3]}`),
	ACQUISITION_EXISTING_FLOW_ICCID: new ApplicationRoute(`/acquisition-existing-flow/${acquisitionExistingFlowRoutesConfig[4]}`),
	ACQUISITION_EXISTING_FLOW_CONTACT_INFO: new ApplicationRoute(`/acquisition-existing-flow/${acquisitionExistingFlowRoutesConfig[5]}`),
	ACQUISITION_EXISTING_FLOW_SUMMARY: new ApplicationRoute(`/acquisition-existing-flow/${acquisitionExistingFlowRoutesConfig[6]}`),
	ACQUISITION_FLOW_MSISDN: new ApplicationRoute(`/acquisition-flow/${acquisitionFlowRoutesConfig[0]}`),
	ACQUISITION_FLOW_PLAN: new ApplicationRoute(`/acquisition-flow/${acquisitionFlowRoutesConfig[1]}`),
	ACQUISITION_FLOW_OPTIONS: new ApplicationRoute(`/acquisition-flow/${acquisitionFlowRoutesConfig[2]}`),
	ACQUISITION_FLOW_ICCID: new ApplicationRoute(`/acquisition-flow/${acquisitionFlowRoutesConfig[3]}`),
	ACQUISITION_FLOW_CONTACT_INFO: new ApplicationRoute(`/acquisition-flow/${acquisitionFlowRoutesConfig[4]}`),
	ACQUISITION_FLOW_SUMMARY: new ApplicationRoute(`/acquisition-flow/${acquisitionFlowRoutesConfig[5]}`),
	FTTB_FLOW: new ApplicationRoute("/fttb-flow"),
	FTTB_FLOW_ADDRESS: new ApplicationRoute(`/fttb-flow/${fttbFlowRoutesConfig[0]}`),
	FTTB_FLOW_PLAN: new ApplicationRoute(`/fttb-flow/${fttbFlowRoutesConfig[1]}`),
	FTTB_FLOW_OPTIONS: new ApplicationRoute(`/fttb-flow/${fttbFlowRoutesConfig[2]}`),
	FTTB_FLOW_SUMMARY: new ApplicationRoute(`/fttb-flow/${fttbFlowRoutesConfig[3]}`),
	B2B_FTTB_FLOW: new ApplicationRoute("/b2b-fttb-flow"),
	B2B_FTTB_FLOW_ADDRESS: new ApplicationRoute(`/b2b-fttb-flow/${b2bFttbFlowRoutesConfig[0]}`),
	B2B_FTTB_FLOW_SEARCH_COMPANY: new ApplicationRoute(`/b2b-fttb-flow/${b2bFttbFlowRoutesConfig[1]}`),
	B2B_FTTB_FLOW_PLAN: new ApplicationRoute(`/b2b-fttb-flow/${b2bFttbFlowRoutesConfig[2]}`),
	B2B_FTTB_FLOW_BILLING_ACCOUNT: new ApplicationRoute(`/b2b-fttb-flow/${b2bFttbFlowRoutesConfig[3]}`),
	B2B_FTTB_FLOW_SUMMARY: new ApplicationRoute(`/b2b-fttb-flow/${b2bFttbFlowRoutesConfig[4]}`),
	FMC_FLOW: new ApplicationRoute("/fmc-flow"),
	FMC_FLOW_SEARCH: new ApplicationRoute(`/fmc-flow/${fmcFlowRoutesConfig[0]}`),
	FMC_FLOW_PLAN: new ApplicationRoute(`/fmc-flow/${fmcFlowRoutesConfig[1]}`),
	FMC_FLOW_OPTIONS: new ApplicationRoute(`/fmc-flow/${fmcFlowRoutesConfig[2]}`),
	FMC_FLOW_SUMMARY: new ApplicationRoute(`/fmc-flow/${fmcFlowRoutesConfig[3]}`),
	FMC_FLOW_FTTB_ADDRESS_PATH: new ApplicationRoute(`/fmc-flow/${fmcFlowWithoutFttbRoutesConfig[1]}`),
	MNP_FLOW: new ApplicationRoute("/mnp-flow"),
	MNP_FLOW_MSISDN: new ApplicationRoute(`/mnp-flow/${mnpFlowRoutesConfig[0]}`),
	MNP_FLOW_TEMPORARY_MSISDN: new ApplicationRoute(`/mnp-flow/${mnpFlowRoutesConfig[1]}`),
	MNP_FLOW_PLAN: new ApplicationRoute(`/mnp-flow/${mnpFlowRoutesConfig[2]}`),
	MNP_FLOW_OPTION: new ApplicationRoute(`/mnp-flow/${mnpFlowRoutesConfig[3]}`),
	MNP_FLOW_CONTACT_INFO: new ApplicationRoute(`/mnp-flow/${mnpFlowRoutesConfig[4]}`),
	MNP_FLOW_SUMMARY: new ApplicationRoute(`/mnp-flow/${mnpFlowRoutesConfig[5]}`),
	MNP_FLOW_POSTPAID: new ApplicationRoute("/mnp-postpaid-flow"),
	MNP_FLOW_MSISDN_POSTPAID: new ApplicationRoute(`/mnp-postpaid-flow/${mnpPostpaidFlowRoutesConfig[0]}`),
	MNP_FLOW_BILLING_ACCOUNT_POSTPAID: new ApplicationRoute(`/mnp-postpaid-flow/${mnpPostpaidFlowRoutesConfig[1]}`),
	MNP_FLOW_PLAN_POSTPAID: new ApplicationRoute(`/mnp-postpaid-flow/${mnpPostpaidFlowRoutesConfig[2]}`),
	MNP_FLOW_OPTION_POSTPAID: new ApplicationRoute(`/mnp-postpaid-flow/${mnpPostpaidFlowRoutesConfig[3]}`),
	MNP_FLOW_ICC_POSTPAID: new ApplicationRoute(`/mnp-postpaid-flow/${mnpPostpaidFlowRoutesConfig[4]}`),
	MNP_FLOW_CONTACT_INFO_POSTPAID: new ApplicationRoute(`/mnp-postpaid-flow/${mnpPostpaidFlowRoutesConfig[5]}`),
	MNP_FLOW_EXISTING_CONTACT_INFO_POSTPAID: new ApplicationRoute(`/mnp-postpaid-flow/${mnpPostpaidFlowRoutesConfig[6]}`),
	MNP_FLOW_SUMMARY_POSTPAID: new ApplicationRoute(`/mnp-postpaid-flow/${mnpPostpaidFlowRoutesConfig[7]}`),
};

export {
	iposRoutes,
	fmcFlowRoutesConfig,
	fmcFlowWithoutFttbRoutesConfig,
	fttbFlowRoutesConfig,
	newCustomerRoutesConfig,
	acquisitionFlowRoutesConfig,
	existingCustomerRoutesConfig,
	acquisitionExistingFlowRoutesConfig,
	mnpFlowRoutesConfig,
	b2bFttbFlowRoutesConfig,
	mnpPostpaidFlowRoutesConfig,
};
