import React, { useMemo } from "react";
import { AddressForm } from "../AddressForm";
import { AuthorizedPersonForm } from "../AuthorizedPersonForm";
import { Field, Form, Formik } from "formik";
import { DatePickerField, InputField, InputWithCheckBox, SelectField } from "../../../../../../shared/components";
import moment from "moment";
import { config } from "../../../../../../config/constants";
import { genders, initialLabels } from "../../../../../../shared/utils";
import { RegistrationMessages } from "../../../../../registration/Registration.messages";
import { useIntl } from "react-intl";
import { CommonMessages } from "../../../../../../Common.messages";
import { UserMessages } from "../../../../../user/User.messages";
import { postPayRegistration } from "./PostPayForm.schema";
import { RegistrationDocument } from "./RegistrationDocument/RegistrationDocument";
import { WITH_DOCUMENT_INIT_FORM_VALUE } from "../ContactInfoPage";

interface PostPayFormType {
    onSubmit: (values: any) => void;
    formId: string;
    initialValues: any;
}

const PostPayForm = (props: PostPayFormType) => {
	const intl = useIntl();
	const initialValues = props.initialValues || WITH_DOCUMENT_INIT_FORM_VALUE;
	const onlyDigitsPlaceholder = useMemo(() => intl.formatMessage({...CommonMessages.inputOnlyDigits}, {number: config.ITN_LENGTH}), [intl.locale]);
	const labels = useMemo(() => initialLabels<any>(intl, initialValues, RegistrationMessages), [intl.locale, initialValues]);
	const validationSchema = useMemo(() => postPayRegistration(intl), [intl.locale]);
	const checkBoxLabelInt = useMemo(() => intl.formatMessage({...RegistrationMessages.withOut}, {value: "ITN"}), [intl.locale]);
	const placeholder = useMemo(() => intl.formatMessage({...UserMessages.choose}), [intl.locale]);

	const onDisableInt = (setFieldValue) => (selected: boolean) => {
		if (selected) {
			setFieldValue("individual_tax_number", config.INDIVIDUAL_TAX_NUMBER_EMPTY);
		} else {
			if (props.initialValues?.individual_tax_number === config.INDIVIDUAL_TAX_NUMBER_EMPTY) {
				setFieldValue("individual_tax_number", "");
			} else {
				setFieldValue("individual_tax_number", props.initialValues?.individual_tax_number || "");
			}
		}
	};
	return (
		<>
			<Formik
				onSubmit={props.onSubmit}
				initialValues={initialValues}
				validationSchema={validationSchema}
			>
				{({values, setFieldValue, setTouched, errors, touched, setValues}) => {
					return (
						<Form id={props.formId} noValidate>
							<div className="row">
								<RegistrationDocument labels={labels} setFieldValue={setFieldValue} setValues={setValues} values={values}/>
							</div>
							<InputWithCheckBox
								label={checkBoxLabelInt}
								checked={Boolean(values.individual_tax_number === config.INDIVIDUAL_TAX_NUMBER_EMPTY)}
								id={"individual_tax_number"}
								onChange={onDisableInt(setFieldValue)}
							>
								{(selected) => (
									<div className="col-12 col-sm-6 col-md-4">
										<Field
											required={!values.additional_document && values.id_document_type === config.PASSPORT}
											name="individual_tax_number"
											id="itn"
											placeholder={onlyDigitsPlaceholder}
											attr={{
												maxLength: config.ITN_LENGTH,
												disabled: selected
											}}
											label={labels.individual_tax_number}
											component={InputField}
										/>
									</div>)}
							</InputWithCheckBox>
							<div className="row">
								<div className="col-12 col-sm-6 col-md-4">
									<Field
										required
										name="contact_phone"
										id="contact-phone"
										placeholder={config.PHONE_FORMAT}
										label={labels.contact_phone}
										attr={{maxLength: config.PHONE_LENGTH}}
										component={InputField}
									/>
								</div>
								<div className="col-12 col-sm-6">
									<Field
										required
										name="last_name"
										id="last-name"
										label={labels.last_name}
										attr={{maxLength: 256}}
										component={InputField}
									/>
								</div>
								<div className="col-12 col-sm-6">
									<Field
										required
										name="first_name"
										id="first-name"
										label={labels.first_name}
										attr={{maxLength: 256}}
										component={InputField}
									/>
								</div>
								<div className="col-12 col-sm-6">
									<Field
										id="second-name"
										name="middle_name"
										label={labels.middle_name}
										attr={{maxLength: 256}}
										component={InputField}
									/>
								</div>
								<div className="col-12 col-sm-6">
									<Field
										required
										name="gender"
										id="gender"
										label={labels.gender}
										intl={intl}
										placeholder={placeholder}
										values={genders(intl)}
										component={SelectField}
									/>
								</div>
								<div className="col-12 col-sm-6">
									<Field
										required
										max={moment().subtract(1, "day")}
										min={moment(config.MIN_DATE_FORM)}
										name="date_of_birth"
										label={labels.date_of_birth}
										format={config.DATE_PICKER_FORMAT}
										component={DatePickerField}
									/>
								</div>
							</div>
							<div>
								<AddressForm
									setTouched={setTouched}
									values={values}
									requiredShow={true}
									setFieldValue={setFieldValue}
									errors={errors}
									touched={touched}
									setValues={setValues}
								/>
							</div>
							<div className="row">
								<div className="col-12 col-sm-6">
									<Field
										name="email"
										id="email"
										label={labels.email}
										component={InputField}
									/>
								</div>
							</div>
							<AuthorizedPersonForm values={values} setValues={setValues}/>
						</Form>);
				}}
			</Formik>
		</>);
};

export { PostPayForm };