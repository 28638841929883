import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Notifications } from "../../alert/Notifications";
import { Errors } from "../../error/Errors";
import { CircularProgress } from "../CircularProgress";
import { NavHeader } from "./NavHeader";
import { errorActions, useStoreContext } from "./StepStateProvider";
import { useNavigationState, useUpdateNavigatioState } from "./useNavigationState";

export interface StepWrapperProps<S> {
	isValid: boolean;
	newState?: Partial<S>|null;
	stateCallBack?: () => Partial<S>|undefined;
	previousStepStateKey?: string;
}

const StepWrapper: <S>(p: React.PropsWithChildren<StepWrapperProps<S>>) => React.ReactElement<StepWrapperProps<S>>  = <S extends Record<string, unknown>>(props: React.PropsWithChildren<StepWrapperProps<S>>) => {
	const {isValid, newState, stateCallBack, previousStepStateKey} = props;
	const history = useHistory<S>();
	const {
		pathname,
	} = useLocation<S>();
	const state  = useNavigationState<S>();
	const updateNavigationState  = useUpdateNavigatioState<S>();
	useEffect(() => {
		dispatch(errorActions.cleanError());
		dispatch(errorActions.cleanMsisdnErrors());
	}, []);

	const {dispatch, state: {paths, errors, msisdnErrors}} = useStoreContext();

	const onClickNext = useCallback(async () => {
		updateNavigationState({
			...state,
			...newState,
			...stateCallBack?.()
		});
		if (pathNext) {
			history.push({
				pathname: pathNext,
			});
		}
	}, [paths, newState, stateCallBack]);
	
	const onClickBack = useCallback(() => {
		pathBack && history.push({ pathname: pathBack });
	}, [paths]);

	if (!paths || paths.length === 0) {
		return <CircularProgress/>;
	}
	const currentIndex = paths?.findIndex((config) => config.pathname === pathname);
	const pathBack = typeof(currentIndex) === "number" && (currentIndex === 0 ? "/" : paths[currentIndex-1]?.pathname);
	const pathNext = typeof(currentIndex) === "number" && (currentIndex === -1 ? "/" : paths[currentIndex+1]?.pathname);

	if (pathBack && previousStepStateKey && !state?.[previousStepStateKey]) {
		history.push(pathBack);
	}
	const isValidForNextStep = !(errors && errors?.length > 0 || (msisdnErrors && Object.keys(msisdnErrors).length > 0));
	return (
		<>
			<NavHeader back={onClickBack} next={onClickNext} isValid={isValid && isValidForNextStep}/>
			<div className="ipos-content-box">
				{props.children}
				<Notifications/>
				<Errors/>
			</div>
		</>
	);
};

export {
	StepWrapper,
};