import { config } from "../../config/constants";

const getFormattedCurrency = (currency: string | undefined | null, price: number | undefined | null, minimumFractionDigits: number): string => {
	return (price || price === 0) ? currency ? new Intl.NumberFormat(config.CURRENCY_LOCALE, {
		style: "currency",
		minimumFractionDigits,
		useGrouping: false,
		currency: currency
	}).format(price): price + "" : "";
};

export {
	getFormattedCurrency
};