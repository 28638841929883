/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface AcquisitionMessagesType {
	acquisitionFlow: MessageDescriptor;
	baInvoiceDeliveryMethod: MessageDescriptor;
	baPaymentMethod: MessageDescriptor;
	billing: MessageDescriptor;
	billingAccountId: MessageDescriptor;
	billingAccountName: MessageDescriptor;
	chooseBilling: MessageDescriptor;
	contactInfo: MessageDescriptor;
	createBilling: MessageDescriptor;
	customerExistError: MessageDescriptor;
	debtDueAmount: MessageDescriptor;
	debtDueDate: MessageDescriptor;
	debtMessage: MessageDescriptor;
	home: MessageDescriptor;
	iccid: MessageDescriptor;
	infoLabel: MessageDescriptor;
	infoMessageUploadDocument: MessageDescriptor;
	msisdn: MessageDescriptor;
	noAvailableMsisdns: MessageDescriptor;
	noBillingAccount: MessageDescriptor;
	options: MessageDescriptor;
	plan: MessageDescriptor;
	skipPersonalData: MessageDescriptor;
	summary: MessageDescriptor;
	withoutDocuments: MessageDescriptor;
}
const AcquisitionMessages: AcquisitionMessagesType = defineMessages({
	acquisitionFlow: {
		id: "aquisition-flow-title",
		description: "Aquisition flow title on the page",
		defaultMessage: "Issue new subscription"
	},
	baInvoiceDeliveryMethod: {
		id: "aquisition-flow-existing-billing-account-invoice-delivery-method",
		description: "Aquisition flow label billing account invoice delivery method",
		defaultMessage: "Invoice delivery method"
	},
	baPaymentMethod: {
		id: "aquisition-flow-existing-billing-account-payment-method",
		description: "Aquisition flow label billing account payment method",
		defaultMessage: "Payment method"
	},
	billing: {
		id: "aquisition-flow-billing",
		description: "Aquisition flow label current page",
		defaultMessage: "Billing account"
	},
	billingAccountId: {
		id: "aquisition-flow-existing-billing-account-id",
		description: "Aquisition flow label billing account id",
		defaultMessage: "ID"
	},
	billingAccountName: {
		id: "aquisition-flow-existing-billing-account-name",
		description: "Aquisition flow label billing account name",
		defaultMessage: "Name"
	},
	chooseBilling: {
		id: "aquisition-flow-existing-choose-billing",
		description: "Aquisition flow label choose billing account",
		defaultMessage: "Choose existing billing account"
	},
	contactInfo: {
		id: "aquisition-flow-contact-info",
		description: "Aquisition flow label current page",
		defaultMessage: "Contact Info"
	},
	createBilling: {
		id: "aquisition-flow-existing-create-billing",
		description: "Aquisition flow label create billing account",
		defaultMessage: "Create new billing account"
	},
	customerExistError: {
		id: "acquisition-customer-exist-form-error",
		description: "Acquisition contact form customer exist error message",
		defaultMessage: "Customer already exists"
	},
	debtDueAmount: {
		id: "aquisition-flow-existing-debt-due-amount",
		description: "Aquisition flow debt due amount",
		defaultMessage: "Due Amount"
	},
	debtDueDate: {
		id: "aquisition-flow-existing-debt-due-date",
		description: "Aquisition flow debt due date",
		defaultMessage: "Due Date"
	},
	debtMessage: {
		id: "aquisition-flow-existing-debt-message",
		description: "Aquisition flow debt message",
		defaultMessage: "Creation of new subscriptions is not possible because of below debts"
	},
	home: {
		id: "aquisition-flow-home",
		description: "Aquisition flow label current page",
		defaultMessage: "Home"
	},
	iccid: {
		id: "aquisition-flow-iccid",
		description: "Aquisition flow label current page",
		defaultMessage: "ICCID"
	},
	infoLabel: {
		id: "aquisition-flow-existing-info",
		description: "Aquisition flow search existing customer info about subscription",
		defaultMessage: "New PrP subscription should be created for New Customer.\n	New PoP subscription should be created under one unique Customer (passport).\n	PrP and PoP subscriptions can not exist under the same Customer entity."
	},
	infoMessageUploadDocument: {
		id: "acquisition-flow-upload-document-msg-info",
		description: "Info message about uploaded documents",
		defaultMessage: "Signed contract and scan copy of customer's document should be uploaded"
	},
	msisdn: {
		id: "aquisition-flow-msisdn",
		description: "Aquisition flow label current page",
		defaultMessage: "MSISDN"
	},
	noAvailableMsisdns: {
		id: "aquisition-search-no-available-msisdns",
		description: "Change msisdn Search not found",
		defaultMessage: "Requested MSISDN is not available"
	},
	noBillingAccount: {
		id: "aquisition-no-biling-account",
		description: "There are no active billing accounts info massage",
		defaultMessage: "There are no active billing accounts. Please create a new one."
	},
	options: {
		id: "aquisition-flow-options",
		description: "Aquisition flow label current page",
		defaultMessage: "Options"
	},
	plan: {
		id: "aquisition-flow-plan",
		description: "Aquisition flow label current page",
		defaultMessage: "Plan"
	},
	skipPersonalData: {
		id: "aquisition-skip-personal-data",
		description: "Contact page checkbox Skip personal data",
		defaultMessage: "Skip personal data"
	},
	summary: {
		id: "aquisition-flow-summary",
		description: "Aquisition flow label current page",
		defaultMessage: "Summary"
	},
	withoutDocuments: {
		id: "aquisition-flow-without-documents",
		description: "Aquisition flow title for checkbox",
		defaultMessage: "Without Documents"
	},
});

export default AcquisitionMessages;
export { AcquisitionMessages };
