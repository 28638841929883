import { useApolloClient } from "@apollo/react-hooks";
import { ALLOW_FMC_MERGE, config, POPFMCADDON } from "../../../../config/constants";
import { SUBSCRIPTION_FMC_POSTPAID_QUERY } from "../../../../graphql/queries/subscription";
import { LineTypeEnum, Query, QueryOtp_SubscriptionArgs, Subscription, SubscriptionTypeCategoryEnum } from "../../../../graphql/types";
import { SubscriptionStatus } from "../../../../shared/types";
import { MsisdnErrorsUtils } from "../../../../shared/utils/MsisdnErrorsUtils.utils";
import { PackageUtils } from "../../../../shared/utils/Package.utils";
import { TARIFF_PLAN_TYPE_PREPAID } from "../../../acquisitionFlow/NewCustomer/Pages";
import { errorActions, fmcActions, useStoreContext } from "../../../../shared/components/navigation/StepStateProvider";

function getFmcStatuses(fmcMergeStatus) {
	const notAllowFmcMergePreviousDone = "done" === fmcMergeStatus;
	const notAllowFmcMergeOngoingMerge = "ongoing" === fmcMergeStatus;
	const previousFmcMergeFailed = "failed" === fmcMergeStatus;
	return { notAllowFmcMergePreviousDone, notAllowFmcMergeOngoingMerge, previousFmcMergeFailed };
}

const useSearchMsisdn = (
	gsmSubscription: Subscription | undefined,
	setGsmSubscription: React.Dispatch<React.SetStateAction<Subscription | undefined>>,
	fttbSubscription: Subscription | undefined,
	setFttbSubscription: React.Dispatch<React.SetStateAction<Subscription | undefined>>): ((msisdn: string, otp: string) => Promise<string | void | Required<Pick<Query, "otp_subscription">>>) => {

	const { dispatch } = useStoreContext();
	const client = useApolloClient();
	return (msisdn: string, otp: string): Promise<string | void | Required<Pick<Query, "otp_subscription">>> => {
		return client.query<Required<Pick<Query, "otp_subscription">>, QueryOtp_SubscriptionArgs>({
			query: SUBSCRIPTION_FMC_POSTPAID_QUERY,
			fetchPolicy: "network-only",
			variables: {
				msisdn,
				otp
			}
		}).then(({ data }) => {
			if (!data) {
				return "erorr";
			}
			const subscription = data?.otp_subscription?.subscription;
			const lineType = subscription?.subscription_type?.line_type;
			if (!subscription?.id) {
				dispatch(errorActions.setError("fmcNotPossible"));
			}

			if (subscription && lineType && [LineTypeEnum.Mobile, LineTypeEnum.Fmc].includes(lineType)) {
				gsmSubscription?.msisdn && dispatch(errorActions.cleanMsisdnError(gsmSubscription?.msisdn));
				const gsmAvailaleStatues = [SubscriptionStatus.ACTIVE as string];

				const isPopMerge = subscription?.subscription_type?.subscription_type_category?.toLowerCase() === SubscriptionTypeCategoryEnum.Fmcpopmerge;
				dispatch(fmcActions.setFmcPopMerge(isPopMerge));
				
				const hasPopFmcAddon = !!data?.otp_subscription?.subscription?.available_packages?.find(pack => pack?.code?.toLowerCase() === POPFMCADDON);
				const allowFmcMerge = Boolean(subscription?.subscription_type?.tags?.includes(ALLOW_FMC_MERGE));
				dispatch(fmcActions.setAllowFmcMerge(allowFmcMerge));
				const notAllowFmcMerge = !(isPopMerge || hasPopFmcAddon || allowFmcMerge);
				const isPopFmcAddonActive = !!data?.otp_subscription?.subscription?.active_packages?.find(pack => pack?.code?.toLowerCase() === POPFMCADDON);
				const { notAllowFmcMergePreviousDone, notAllowFmcMergeOngoingMerge, previousFmcMergeFailed } = getFmcStatuses(data?.otp_subscription?.subscription?.fmc_merge_status);
				MsisdnErrorsUtils.validate(subscription?.payment_type?.toLowerCase() === TARIFF_PLAN_TYPE_PREPAID, "fmcNotAllowedForPrepaid")
					.next(subscription?.is_portout_ongoing, "msisdnPortingRequestOngoing")
					.next(!(subscription?.status && gsmAvailaleStatues.includes(subscription.status)), "fmcPostpaidMergeForGsmSuspendedSubscriptionProhibited")
					.next(subscription?.is_temporary_msisdn, "isTemporaryMsisdn")
					.next(notAllowFmcMerge, "notAllowFmcMerge")
					.next(notAllowFmcMergePreviousDone, "notAllowFmcMergePreviousDone")
					.next(notAllowFmcMergeOngoingMerge, "notAllowFmcMergeOngoingMerge")
					.next(previousFmcMergeFailed, "previousFmcMergeFailed")
					.next(isPopFmcAddonActive, "fmcNotPossible")
					.next(subscription?.owner?.is_anonymous_customer, "anonymousNotAllowed")
					.dispatch(msisdn, dispatch);

				setGsmSubscription(subscription);
				dispatch(fmcActions.setPopFmcAddonExists(hasPopFmcAddon));
			}
			if (subscription && lineType === LineTypeEnum.Fttb) {
				const { notAllowFmcMergePreviousDone, notAllowFmcMergeOngoingMerge, previousFmcMergeFailed } = getFmcStatuses(data?.otp_subscription?.subscription?.fmc_merge_status);

				const isFttbPauseActive = !!subscription?.active_packages?.find(eachPack => eachPack?.code === config.FTTB_PAUSE);
				const fttbAvailaleStatues = [SubscriptionStatus.ACTIVE as string, SubscriptionStatus.SUSPENDED as string];
				dispatch(fmcActions.setWithoutFttb(false));
				fttbSubscription?.msisdn && dispatch(errorActions.cleanMsisdnError(fttbSubscription?.msisdn));

				PackageUtils.handleFttbErrors(data?.otp_subscription?.subscription?.active_packages, dispatch, msisdn);
				MsisdnErrorsUtils.validate(!subscription?.owner?.contact_phone, "noContactPhoneErorr")
					.next(!(subscription?.status && fttbAvailaleStatues.includes(subscription.status)), "fmcPostpaidOnlyActiveAndSuspenedFttbSubscriptionAllowedToMerge")
					.next(subscription?.is_tv_tuner_debt, "tvTunerDebt")
					.next(notAllowFmcMergePreviousDone, "notAllowFmcMergePreviousDone")
					.next(notAllowFmcMergeOngoingMerge, "notAllowFmcMergeOngoingMerge")
					.next(previousFmcMergeFailed, "previousFmcMergeFailed")
					.next(isFttbPauseActive, "fttbPauseActive")
					.dispatch(msisdn, dispatch);

				setFttbSubscription(subscription);
			}
			return data;
		});
	};
};

export {
	useSearchMsisdn
}
