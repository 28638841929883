import React, { FC, useEffect } from "react";
import { MsisdnTopBarInfo } from "../../../shared/components";
import { Switch } from "react-router";
import { ErrorBoundaryRoute } from "../../../shared/error/ErrorBoundaryRoute";
import { FormattedMessage, useIntl } from "react-intl";
import { FMCMessages } from "../FMC.messages";
import {
	FMC_FLOW_PLAN_PATH,
	FMC_FLOW_SEARCH_PATH,
	FMC_FLOW_SUMMARY_PATH,
	FMC_FLOW_OPTIONS_PATH,
	FMC_FLOW_FTTB_ADDRESS_PATH,
} from "./FMCPageRoutes";
import { Plan } from "../Pages/Plan";
import { Options } from "../Pages/Options";
import { Summary } from "../Pages/Summary/Summary";
import { SearchGsm } from "../Pages/SearchSubscription/SearchGSM";
import { FmcAddress } from "../Pages/FmcAddress";
import { fmcFlowRoutesConfig, fmcFlowWithoutFttbRoutesConfig } from "../../../routes/RoutesMap";
import { flowActions, useStoreContext } from "../../../shared/components/navigation/StepStateProvider";
import { FmcRouteState } from "../fmc.type";
import { cleanNavigationStateCallBack, useNavigationState } from "../../../shared/components/navigation/useNavigationState";


const FmcPageRouter: FC = () => {
	const intl = useIntl();
	const {dispatch, state: { fmc}} = useStoreContext();
	const state  = useNavigationState<FmcRouteState>();


	const lastSteps = [{
		pathname: FMC_FLOW_PLAN_PATH,
		message: intl.formatMessage({ ...FMCMessages.plan })
	},
	{
		pathname: FMC_FLOW_OPTIONS_PATH,
		message: intl.formatMessage({ ...FMCMessages.options })
	},
	{
		pathname: FMC_FLOW_SUMMARY_PATH,
		message: intl.formatMessage({ ...FMCMessages.summary })
	}];
	const fmcRegularRoutes = [
		{
			pathname: FMC_FLOW_SEARCH_PATH,
			message: intl.formatMessage({ ...FMCMessages.search })
		},
		...lastSteps
	];
	const fmcWithoutFttbRoutes = [
		{
			pathname: FMC_FLOW_SEARCH_PATH,
			message: intl.formatMessage({ ...FMCMessages.search })
		},
		{
			pathname: FMC_FLOW_FTTB_ADDRESS_PATH,
			message: intl.formatMessage({ ...FMCMessages.address })
		},
		...lastSteps
	];


	useEffect(() => {
		const withoutFttb = fmc?.withoutFttb !== undefined ? fmc.withoutFttb : state?.isNoFttbSubscription;
		dispatch(flowActions.changeFlow(withoutFttb ? fmcWithoutFttbRoutes : fmcRegularRoutes ));
	}, [fmc?.withoutFttb]);


	useEffect(() => {
		return cleanNavigationStateCallBack;
	}, []);
	return (
		<>
			<MsisdnTopBarInfo>
				<FormattedMessage {...FMCMessages.FMC} />
			</MsisdnTopBarInfo>
			<div id="w-app-body">
				<Switch>
					<ErrorBoundaryRoute exact path={FMC_FLOW_SEARCH_PATH} component={SearchGsm}/>
					<ErrorBoundaryRoute path={FMC_FLOW_PLAN_PATH} component={Plan}/>
					<ErrorBoundaryRoute path={FMC_FLOW_OPTIONS_PATH} component={Options}/>
					<ErrorBoundaryRoute path={FMC_FLOW_SUMMARY_PATH} component={Summary}/>
					<ErrorBoundaryRoute path={FMC_FLOW_FTTB_ADDRESS_PATH} component={FmcAddress}/>
				</Switch>
			</div>
		</>
	);
};

export { FmcPageRouter,
	fmcFlowWithoutFttbRoutesConfig,
	fmcFlowRoutesConfig };
