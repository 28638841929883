import gql from "graphql-tag";

export const GET_CSR_TAB_LINK = gql`
    query Livesetting($name: LiveSettingEnum!) {
        livesetting(name: $name) {
            value,
            value_type,
       }
    }
`;
