/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface MnpMessagesType {
	Intertelecom: MessageDescriptor;
	Lifecell: MessageDescriptor;
	ThreeMob: MessageDescriptor;
	Vodafone: MessageDescriptor;
	b2cNonPassportized: MessageDescriptor;
	b2cPassportized: MessageDescriptor;
	chooeseDonorOperator: MessageDescriptor;
	chooseDocumentType: MessageDescriptor;
	chooseDonorSegment: MessageDescriptor;
	dateOfPortation: MessageDescriptor;
	donorFirstName: MessageDescriptor;
	donorLastName: MessageDescriptor;
	donorOprator: MessageDescriptor;
	donorPassportOrId: MessageDescriptor;
	enterMsisdn: MessageDescriptor;
	enterTemporaryMsisdn: MessageDescriptor;
	existingCustomer: MessageDescriptor;
	kyivstarMsisdn: MessageDescriptor;
	mmc: MessageDescriptor;
	mnpMsisdn: MessageDescriptor;
	newCustomer: MessageDescriptor;
	numberPortability: MessageDescriptor;
	numberPortabilityPostpaid: MessageDescriptor;
	okpoNumber: MessageDescriptor;
	popB2b: MessageDescriptor;
	popEntrepreneur: MessageDescriptor;
	postpaidMnpMsisdn: MessageDescriptor;
	search: MessageDescriptor;
	temporaryMsisdn: MessageDescriptor;
	userPassportization: MessageDescriptor;
}
const MnpMessages: MnpMessagesType = defineMessages({
	Intertelecom: {
		id: "mnp-donor-operator-Intertelecom",
		description: "Donor operator Intertelecom",
		defaultMessage: "Intertelecom"
	},
	Lifecell: {
		id: "mnp-donor-operator-Lifecell",
		description: "Donor operator Lifecell",
		defaultMessage: "Lifecell"
	},
	ThreeMob: {
		id: "mnp-donor-operator-3Mob",
		description: "Donor operator 3Mob",
		defaultMessage: "3Mob"
	},
	Vodafone: {
		id: "mnp-donor-operator-Vodafone",
		description: "Donor operator Vodafone",
		defaultMessage: "Vodafone"
	},
	b2cNonPassportized: {
		id: "mnp-donor-segment-b2c-non-passportized",
		description: "Donor segment B2C non passportized",
		defaultMessage: "B2C non passportized"
	},
	b2cPassportized: {
		id: "mnp-donor-segment-b2c-passportized",
		description: "Donor segment PrP B2C passportized",
		defaultMessage: "PrP B2C passportized"
	},
	chooeseDonorOperator: {
		id: "mnp-flow-choose-donor-operator",
		description: "Placeholder for donor operator drop down",
		defaultMessage: "Choose donor operator"
	},
	chooseDocumentType: {
		id: "mnp-donor-segment-document-type-placeholder",
		description: "Donor segment document type placeholder",
		defaultMessage: "Choose Document Type"
	},
	chooseDonorSegment: {
		id: "mnp-flow-choose-donor-segment",
		description: "Placeholder for donor segment drop down",
		defaultMessage: "Choose donor segment"
	},
	dateOfPortation: {
		id: "mnp-flow-user-dateOfPortation-label",
		description: "Label for date of portation",
		defaultMessage: "Date of Portation"
	},
	donorFirstName: {
		id: "mnp-donor-segment-donor-first-name-placeholder",
		description: "Donor segment donor first name placeholder",
		defaultMessage: "Enter Donor First Name"
	},
	donorLastName: {
		id: "mnp-donor-segment-donor-last-name-placeholder",
		description: "Donor segment donor last name placeholder",
		defaultMessage: "Enter Donor Last Name"
	},
	donorOprator: {
		id: "mnp-flow-donor-operator",
		description: "Donor operator label",
		defaultMessage: "Donor operator"
	},
	donorPassportOrId: {
		id: "mnp-donor-segment-passport-or-id-number-placeholder",
		description: "Donor segment passport or id number placeholder",
		defaultMessage: "Enter Passport/Id Card Number"
	},
	enterMsisdn: {
		id: "mnp-flow-enter-msisdn",
		description: "Placeholder text of MSISDN input box:",
		defaultMessage: "Enter MSISDN to be ported in"
	},
	enterTemporaryMsisdn: {
		id: "mnp-flow-enter-temporary-msisdn",
		description: "Text on top of temporary MSISDN input box:",
		defaultMessage: "Enter Starter Kit MSISDN or existing subscription MSISDN"
	},
	existingCustomer: {
		id: "mnp-flow-postpaid-number-portability-existing-customer-button",
		description: "Min View MNP Postpaid existing customer button text",
		defaultMessage: "Existing Customer"
	},
	kyivstarMsisdn: {
		id: "mnp-flow-user-kyivstarMsisdn-error",
		description: "Error for Kyivstar MSISDN",
		defaultMessage: "This is already a KyivstarMSISDN"
	},
	mmc: {
		id: "mnp-donor-segment-mmc",
		description: "Donor segment MMC",
		defaultMessage: "MMC"
	},
	mnpMsisdn: {
		id: "mnp-flow-mnp-msisdn",
		description: "First page Text in the ribbon:",
		defaultMessage: "MNP MSISDN"
	},
	newCustomer: {
		id: "mnp-flow-postpaid-number-portability-new-customer-button",
		description: "Min View MNP Postpaid new customer button text",
		defaultMessage: "New Customer"
	},
	numberPortability: {
		id: "mnp-flow-number-portability",
		description: "Min View MNP button text",
		defaultMessage: "Prepaid Number Portability"
	},
	numberPortabilityPostpaid: {
		id: "mnp-flow-postpaid-number-portability",
		description: "Min View MNP Postpaid button text",
		defaultMessage: "Postpaid Number Portability"
	},
	okpoNumber: {
		id: "mnp-donor-segment-okpo-number-placeholder",
		description: "Donor segment okpo number placeholder",
		defaultMessage: "Enter OKPO Number"
	},
	popB2b: {
		id: "mnp-donor-segment-pop-b2b",
		description: "Donor segment pop B2B",
		defaultMessage: "PoP B2B"
	},
	popEntrepreneur: {
		id: "mnp-donor-segment-pop-entrepreneur",
		description: "Donor segment pop Entrepreneur",
		defaultMessage: "PoP (Entrepreneur/FOP)"
	},
	postpaidMnpMsisdn: {
		id: "mnp-flow-postpaid-mnp-msisdn",
		description: "First page Text in the ribbon:",
		defaultMessage: "POSTPAID MNP MSISDN"
	},
	search: {
		id: "mnp-flow-postpaid-flow-search-existing-customer",
		description: "MNP Postpaid search existing customer",
		defaultMessage: "Search"
	},
	temporaryMsisdn: {
		id: "mnp-flow-temporary-msisdn",
		description: "Text in the ribbon",
		defaultMessage: "Temporary MSISDN"
	},
	userPassportization: {
		id: "mnp-flow-user-passportization-label",
		description: "Text on top of user passportization:",
		defaultMessage: "User Passportized"
	},
});

export default MnpMessages;
export { MnpMessages };
