import React, { FC, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { prepareStepClasses, } from "../../utils/scss/classes";
import { flowActions, useStoreContext } from "./StepStateProvider";


const CustomerNavigationPro: FC = () => {
	const globalState = useStoreContext();
	const {dispatch, state : {
		paths
	}} = globalState;
	const {
		pathname,
		state,
	} = useLocation();
	const history = useHistory();
	const [classes, currentStep] = prepareStepClasses(pathname, paths);
	//TODO test
	useEffect(() => {
		const isFirstStep = currentStep?.index === 0;
		if (isFirstStep)
			dispatch(flowActions.startFlow());
	}, []);
	const navigate = (pathname: string, pastStep: boolean) => () =>  {
		if (pastStep) {
			history.push(pathname, state);
		}
	};
	return (
		<div className="CustomerNavigation">
			<div className="d-none d-sm-block">
				<div className="w-wizard">
					{currentStep && paths.map((item, index) => (
						<div key={item.pathname} onClick={navigate(item.pathname, index < currentStep.index)} className={classes[item.pathname]}>
							<div className="w-wizard-step-number">{index + 1}</div>
							<div className="w-wizard-step-label">
								{item.message}
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="d-sm-none">
				{currentStep && <div className="w-wizard">
					<div className={classes[currentStep.pathname]}>
						<div className="w-wizard-step-number">{currentStep.index}</div>
						<div className="w-wizard-step-label">
							{currentStep.message}
						</div>
					</div>
				</div>}
			</div>
		</div>
	);
};

export { CustomerNavigationPro };
