import React, { useState } from "react";
import {SelectFieldFormik} from "./SelectField";
import {useIntl} from "react-intl";
import {CommonMessages} from "../../Common.messages";
import {FormikProps} from "../types";
import {DeliveryZoneList} from "../../config/deliveryZonesList";

interface DeliveryZoneDropdownProps {
	formik: FormikProps
}

const DeliveryZoneDropdown = (props: DeliveryZoneDropdownProps) => {
	const intl = useIntl();
	const handleChange = (fieldName, setFieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue(fieldName, e.target.value);
	};

	return (
		<SelectFieldFormik
			name="delivery_zone"
			id="delivery-zone-id"
			showPlaceholder={true}
			placeholder={intl.formatMessage({...CommonMessages.deliveryZone})}
			formik={props.formik }
			value={props.formik.values.delivery_zone}
			onChange={handleChange}
			attr={{maxLength: 256}}
			values={DeliveryZoneList.map(option => ({
				label: option.name,
				value: option.id
			}))}
		/>
	);
};

export {DeliveryZoneDropdown};
