enum FttbAddressEnum {
    countries = "countries",
    city = "city",
    street = "street",
    house_number = "house_number",
    province = "province",
    district = "district",
    simpleConnection = "simpleConnection",
    flat="flat"
}

enum FttbActivationEnum {
    today = "today",
    tomorrow = "tomorrow",
}

export { FttbAddressEnum, FttbActivationEnum };
