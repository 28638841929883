
import gql from "graphql-tag";
import { UserDealerIdentity } from "../fragments/dealerIdentity.fragments";

export const GET_USER_IDENTITY = gql`
    query UserIdentity {
        user_identity(get_permissions: true) {
			username
			permission_groups {
				id
    			name
			}
			permissions
			dealer_identities {
				contact_number
				reseller_code
				reseller_name
				chain_name
			}
		}
    }
`;

export const GET_CURRENT_DEALER_IDENTITY = gql`
    query getCurrentUserIdentity {
		dealerIdentity @client {
			...DealerIdentity
		} 
    }
	${UserDealerIdentity}
`;