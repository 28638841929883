
/**
 * Utils for creating typed redux action creators
 *
 * 1) Data for demo purpose:
 *
 * interface Y extends SingleArgumentAction<"y", boolean> {}
 * interface X extends Action<"x"> {
 *      amount: number;
 * }
 *
 * type DemoActionPayload = X | T;
 *
 * 2) Generating action creators examples:
 *
 *      a) const x = actionCreator<X>("x"); // <-- ok, can call x({amount: 42})
 *      b) singleArgumentActionCreator<number, X>("x"); // error, X uses object based argument
 *      c) actionCreator<Y>("y"); // <-- error, Y uses single argument payload, not object based
 *      d) const y = singleArgumentActionCreator<boolean, Y>("y"); // <-- ok, can call y(true)
 *      e) singleArgumentActionCreator<string, Y>("y"); // <-- error, Y uses boolean arg
 *
 *
 * 3) Usage in redux reducer:
 *
 * const demoReducer = (state: Partial<DemoState> = initialState, action: DemoActionPayload): DemoState => {
 * switch (action.type) { // <-- note, this will not work: const {type}=action; switch(type){}
 *      case "x":
 *          return {
 *              ...state,
 *              amount: action.amount, // <-- ok, here action has type X (determined by switch/case)
 *              amount: action.other, // <-- error, not "other" field in X
 *          };
 *      case "y":
 *          return {
 *              ...state,
 *              isDisplayed: action.payload, // <-- ok, here action has type Y (determined by switch/case), so have only payload field
 *              someStringField: action.payload, // <-- error, payload is boolean, defined in Y type
 *              amount: action.other, // <-- error, not "other" field in Y
 *          };
 *      case "z": // <-- error, unknown action type
 *
 * 4) Usage in saga:
 *
 *  export function* onX(action: X) {
 *  const amount: number = action.amount; // <-- ok, amount field is present and cannot be undefined (until not marked as optional in type X)
 *  // no need to add null-checks here
 *
*/

export interface Action<T = any> {
	type: T
}

export type ActionPayload<T> = Pick<T, Exclude<keyof T, "type">>;

export interface SingleArgumentAction<V, T> extends Action<T> {
	payload: V;
}

export type ExtractActionType<T> = T extends Action<infer K> ? K : never;

export interface ObjectArgumentAction<T = unknown> extends Action<T> {
	payload?: never;
}

export function argumentlessActionCreator<T extends Action>(type: ExtractActionType<T>): () => T {
	return () => ({ type } as T);
}

export function actionCreator<T extends ObjectArgumentAction>(type: ExtractActionType<T>): (args: ActionPayload<T>) => T {
	return (args: ActionPayload<T>) => ({ type, ...args } as T);
}

export function singleArgumentActionCreator<V, T extends SingleArgumentAction<V, ExtractActionType<T>>>(type: ExtractActionType<T>): (payload: V) => T {
	return (payload: V) => ({ type, payload } as T);
}
