import { useHistory } from "react-router";
import { useFormik } from "formik";
import React, { useMemo, useState } from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { FormattedMessage, useIntl } from "react-intl";
import { UserMessages } from "../../../user/User.messages";
import { SearchCustomerEnum } from "../../../../shared/types";
import { ExistingCustomerInfo } from "./ExistingCustomerInfo";
import { InputFieldFormik } from "../../../../shared/components";
import { useSearchExistingCustomer } from "../useSearchExistingCustomer";
import { schemaValidationExistingCustomer } from "./ExistingCustomer.schema";
import { CircularProgress } from "../../../../shared/components/CircularProgress";
import { ACQUISITION_EXISTING_FLOW_BILLING_PATH } from "../Router/AcquisitionExistingFlowPath";
import { CHECK_CUSTOMER_CREDIT_DECISION } from "../../../../graphql/mutations/customerMutation";
import { Mutation, MutationCheck_Cdt_Consumer_Credit_DecisionArgs, Query, QueryOtp_SubscriptionArgs } from "../../../../graphql/types";
import {
	creditDecisionOrder,
	creditDecisionOrderer,
	document_types_search,
	transformToEngLetter,
} from "../../../../shared/utils";
import { SelectFieldFormik } from "../../../../shared/components/SelectField";
import { config } from "../../../../config/constants";
import { useOtp } from "../../../../shared/components/Otp";
import { GET_PERSONALIZATION } from "../../../../graphql/queries";
import {MNP_FLOW_MSISDN_POSTPAID} from "../../../mnp/postpaidMnp/MNPFlowRoutes";
import {useNavigationState, useUpdateNavigatioState} from "../../../../shared/components/navigation/useNavigationState";
import {MNPRouteState} from "../../../mnp/MNPFlowRoutes";

interface ExistingCustomerProps {
	mnpPostpaid?: boolean;
}

const SearchExistingCustomer = (props: ExistingCustomerProps) => {
	const intl = useIntl();
	const history = useHistory();
	const client = useApolloClient();
	const initialValues = {id_number: "", id_type: ""};
	const [error, setError] = useState<string>("");
	const [checkingCustomer, setCheckingCustomer] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useState({id_number: "", id_type: ""});
	const documentTypes = useMemo(() => document_types_search(intl), [intl.locale]);
	
	const [checkCustomerCreditDecision] = useMutation<Mutation, MutationCheck_Cdt_Consumer_Credit_DecisionArgs>(CHECK_CUSTOMER_CREDIT_DECISION);
	const [customers, searchCustomer, loading, setCustomer] = useSearchExistingCustomer();
	const state = useNavigationState<MNPRouteState>();
	const updateNavigationState  = useUpdateNavigatioState<MNPRouteState>();

	const handleGetMsisdn = (msisdn: string, otp: string): Promise<string|void|Required<Pick<Query, "otp_subscription">>> => {
		return client.query<Required<Pick<Query, "otp_subscription">>, QueryOtp_SubscriptionArgs>({
			query: GET_PERSONALIZATION,
			fetchPolicy: "network-only",
			variables: {
				msisdn,
				otp
			}
		}).then(({data}) => {
			if (!data) {
				return "erorr";
			}
			const owner = data?.otp_subscription?.subscription?.owner;
			setCustomer(owner ? [owner] : []);
			return data;
		});
	};

	const onSubmit = (values) => {
		setError("");
		const { id_type, id_number } = values;
		if (id_type === SearchCustomerEnum.msisdn) {
			handleOtp(values.id_number);
		} else {
			searchCustomer(id_number, id_type, "");
		}
		setSearchParams({ id_number, id_type });
	};
	const validationSchema = schemaValidationExistingCustomer(intl);
	const formik = useFormik({initialValues, onSubmit, validationSchema});
	const {handleOtp} = useOtp(formik.values.id_number, handleGetMsisdn);

	const progressRender = (): React.ReactNode => {
		if (loading || checkingCustomer) {
			return <CircularProgress/>;
		}
	};
	const onChangeDocumentNumber = (fieldName, setFieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = e.target.value.trim().toUpperCase();
		if (value.length === config.PASSPORT_LENGTH && formik.values.id_type === SearchCustomerEnum.passport) {
			const transformValue = transformToEngLetter(value);
			setFieldValue(fieldName, transformValue);
		} else {
			setFieldValue(fieldName, e.target.value);
		}
	};
	const nextClick = async (customerId: string) => {
		if (customerId) {
			setCheckingCustomer(true);
			setError("");
			const customer = customers?.find((item) => item?.id && customerId === item.id);
			try {
				if (!customer) {
					throw intl.formatMessage({...UserMessages.customerNotFind});
				}
				await checkCustomerCreditDecision({
					variables: {
						orderer: creditDecisionOrderer(customer, intl.locale),
						order: creditDecisionOrder(),
					}
				}).then(({data, errors}) => {
					setCheckingCustomer(false);
					if(errors) {
						throw intl.formatMessage({...UserMessages.credit_decision});
					}
					if (data && data.check_cdt_consumer_credit_decision) {
						const {business_instructions, errors, credit_decision } = data.check_cdt_consumer_credit_decision;
						if (errors?.length > 0) {
							throw errors[0].title;
						}
						if (credit_decision === "REJECTED") {
							if (business_instructions?.length > 0) {
								throw business_instructions.map((instruction) => instruction[intl.locale]).join(" ");
							} else {
								throw intl.formatMessage({...UserMessages.credit_decision});
							}
						}
					} else {
						throw intl.formatMessage({...UserMessages.credit_decision});
					}
				});
				if (props?.mnpPostpaid) {
					updateNavigationState( {
						...state,
						customer
					} as MNPRouteState);
					history.push({
						pathname: MNP_FLOW_MSISDN_POSTPAID,
					});
				} else {
					history.push({
						pathname: ACQUISITION_EXISTING_FLOW_BILLING_PATH,
						state: {
							id_number: searchParams.id_number,
							id_type: searchParams.id_type,
							customer
						}
					});
				}
			} catch (error) {
				setCheckingCustomer(false);
				setError(error as string);
			}
		}
	};
	const idDocumentNumberPlaceholder = intl.formatMessage({...UserMessages.idDocumentNumber});
	const documentTypePlaceholder = intl.formatMessage({...UserMessages.documentType});
	// DELETE before realise;
	const onChange = process.env.NODE_ENV === "development" ? (n, s) => (e) => {
		s(n, e.target.value);
		if (e.target.value === SearchCustomerEnum.msisdn) {
			s("id_number", "0980003878");
		} else if (e.target.value === SearchCustomerEnum.passport) {
			s("id_number", "TG220117");
		} else if (e.target.value === SearchCustomerEnum.billing_account) {
			s("id_number", "90000000498"); // 90000000402
		} else if (e.target.value === SearchCustomerEnum.individual_tax_number) {
			s("id_number", "3338111144");
		}
	} : (n, s) => (e) => {
		s(n, e.target.value);
		if (formik.values.id_number.length === config.PASSPORT_LENGTH && e.target.value === SearchCustomerEnum.passport) {
			const transformValue = transformToEngLetter(formik.values.id_number);
			s("id_number", `${transformValue}`);
		}
	};

	return (
		<div className="mt-3">
			<div>
				<form noValidate onSubmit={formik.handleSubmit}>
					<div className="row">
						<div className="col-12">
							<SelectFieldFormik
								required
								name="id_type"
								id="id-type"
								showPlaceholder={true}
								placeholder={documentTypePlaceholder}
								attr={{maxLength: 256}}
								value={formik.values.id_type}
								values={documentTypes}
								formik={formik}
								onChange={onChange}
							/>
						</div>
						<div className="col-12">
							<InputFieldFormik
								required
								name="id_number"
								id="id-number"
								placeholder={idDocumentNumberPlaceholder}
								attr={{maxLength: 256}}
								value={formik.values.id_number}
								formik={formik}
								onChange={onChangeDocumentNumber}
							/>
						</div>
						<div className="col-12 mb-2">
							<button type="submit" className="btn btn-block btn-primary">
								<FormattedMessage {...UserMessages.search} />
							</button>
						</div>
					</div>
				</form>
			</div>
			{progressRender()}
			{error && !loading && !checkingCustomer && (<div className="alert alert-danger mt-2 message mb-2">
				<p className="mb-0">{error}</p>
			</div>)}
			{customers?.filter(item => item)?.length === 0 ? (
				<div className="alert alert-danger mt-2 message">
					<p className="mb-0">
						<FormattedMessage {...UserMessages.customerNotFind} />
					</p>
				</div>) : <ExistingCustomerInfo onClick={nextClick} customers={customers} mnpPostpaid={props?.mnpPostpaid}/>}
		</div>
	);
};

export { SearchExistingCustomer };