import React, { useMemo, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { Field } from "formik";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { InputField } from "../../../../../shared/components";
import { initialLabels } from "../../../../../shared/utils";
import { RegistrationMessages } from "../../../../registration/Registration.messages";
import { FttbAddressEnum } from "../../../../../shared/types/FttbEnum";
import { useGisSystems } from "../../../../fttb/useGisSystems";
import { FTTBMessages } from "../../../../fttb/FTTB.messages";
import { SelectedAddressState } from "../../../../fttb/Pages/Address/AddressTypes";
import { CommonMessages } from "../../../../../Common.messages";
import { GisRegion, GisHouse, GisStreet, GisDistrict, GisCity } from "../../../../../graphql/types";
import { useLocation } from "react-router-dom";
import { MNPRouteState } from "../../../../mnp/MNPFlowRoutes";
import { AcquisitionFlowState } from "../SummaryPage/SummaryPage";
import { debounce } from "lodash";
import { useNavigationState } from "../../../../../shared/components/navigation/useNavigationState";
import { PersonalInfo } from "../../../../../graphql/localTypes";

interface InitialAddressType {
    street: string;
    house_number: string;
    building: string;
    apartment: string;
    postal_code: string;
    locality: string;
    region: string;
    district: string;
}

const initialValues = {
	street: "",
	house_number: "",
	building: "",
	apartment: "",
	postal_code: "",
	locality: "",
	region: "",
	district: "",
};

interface AddressFormProps {
    requiredShow?: boolean;
    disabled?: boolean;
    setFieldValue: any;
    setTouched: any;
    errors: any;
    touched: any;
    values: any;
    setValues: any;
}

const AddressForm = (props: AddressFormProps) => {
	const intl = useIntl();
	const navigationState = useNavigationState<MNPRouteState>();

	const {state = navigationState} = useLocation<MNPRouteState|AcquisitionFlowState>();
	const { errors, touched, values, setValues, setTouched} = props;
	const refs = {
		[FttbAddressEnum.district]: useRef(),
		[FttbAddressEnum.city]: useRef(),
		[FttbAddressEnum.street]: useRef(),
		[FttbAddressEnum.house_number]:  useRef(),
	};
	const personalInfo = state?.personalInfo as PersonalInfo;
	const initState = { 
		province: personalInfo?.province?.selected?.[0] || {},
		district: personalInfo?.district?.selected?.[0] || {},
		city: personalInfo?.city?.selected?.[0] || {},
		street: personalInfo?.street?.selected?.[0] || {},
		house_number: personalInfo?.house_number?.selected?.[0] || {}
	} as SelectedAddressState;
	const affectFilds = {
		[FttbAddressEnum.province]: [FttbAddressEnum.district, FttbAddressEnum.city, FttbAddressEnum.street, FttbAddressEnum.house_number],
		[FttbAddressEnum.district]: [FttbAddressEnum.city, FttbAddressEnum.street, FttbAddressEnum.house_number],
		[FttbAddressEnum.city]: [FttbAddressEnum.street, FttbAddressEnum.house_number],
		[FttbAddressEnum.street]: [FttbAddressEnum.house_number],
	};

	const [address, setAddress] = useState<SelectedAddressState>(initState);

	const [isLoading, gisState, updateGisSystem, fetchDataFromGisSystem] = useGisSystems({
		city: personalInfo?.city?.result || [],
		street: personalInfo?.street?.result || [],
		house_number: personalInfo?.house_number?.result || [],
		district: personalInfo?.district?.result || [],
		province: personalInfo?.province?.result || [],
	});
	const labels = useMemo(() => initialLabels<InitialAddressType>(intl, initialValues, RegistrationMessages), [intl.locale]);

	const onChange = (type: FttbAddressEnum) => (selected: GisCity[]|GisStreet[]|GisHouse[]|GisRegion[]|GisDistrict[]) => {
		const init = affectFilds[type]?.reduce((acc, cur) => ({ ...acc, [cur]: [] }), {});
		const initAddress = affectFilds[type]?.reduce((acc, cur) => ({ ...acc, [cur]: "" }), {});
		setTouched({...touched, [type]: true });
		if (selected.length === 0) {
			init && setAddress({ ...address, ...init});
			setValues({...values, ...initAddress, [type]: ""}); // set value for formik
            affectFilds[type]?.forEach(item => refs[item].current.clear());
		} else {
			init && setAddress({ ...address, [type]: selected[0], ...init});
			if (type === FttbAddressEnum.house_number) {
				setValues({...values, ...initAddress, [type]: {
					selected: selected as GisHouse[],
					result: gisState.house_number
				},
				postal_code: (selected[0]as GisHouse).zip_code ?? ""
				});
			} else {
				setValues({...values, ...initAddress, [type]: {
					selected: selected,
					result: gisState[type],
				}
				}); // set value for formik
			}
		}
	};

	const onFocusHandle = (type: FttbAddressEnum, searchBy: FttbAddressEnum | string) => () => {
		fetchDataFromGisSystem(type, "", address[searchBy]?.id);
	};
	const handleSearch = (type: FttbAddressEnum, searchBy?: FttbAddressEnum | string) => debounce((query) => {
		fetchDataFromGisSystem(type, query, searchBy ? address[searchBy]?.id:  undefined);
	}, 1000);

	const onFocusHouse = (type: FttbAddressEnum, searchBy: FttbAddressEnum) => () => {
		const houses = gisState[searchBy].find(street => street.id === address.street?.id) || {};
		updateGisSystem(type, houses?.available_houses || []);
	};

	const placeholders = useMemo(() => {
		return {
			region: intl.formatMessage({...FTTBMessages.selectRegion}),
			district: intl.formatMessage({...FTTBMessages.selectDistrict}),
			city: intl.formatMessage({...FTTBMessages.selectCity}),
			street: intl.formatMessage({...FTTBMessages.selectStreet}),
		};
	}, [intl.locale]);

	const isInvalid = useMemo(() => {
		return { 
			province: errors[FttbAddressEnum.province]?.selected && touched[FttbAddressEnum.province],
			district: errors[FttbAddressEnum.district]?.selected && touched[FttbAddressEnum.district],
			city: errors[FttbAddressEnum.city]?.selected && touched[FttbAddressEnum.city],
			street: errors[FttbAddressEnum.street]?.selected && touched[FttbAddressEnum.street],
			house_number: errors[FttbAddressEnum.house_number]?.selected && touched[FttbAddressEnum.house_number],
		};
	}, [errors, touched]);

	const classes = useMemo(() => {
		return {
			province: `address-spinner mb-3 ${isInvalid.province ? "is-invalid" : ""}`,
			district: `address-spinner mb-3 ${isInvalid.district ? "is-invalid" : ""}`,
			city: `address-spinner mb-3 ${isInvalid.city ? "is-invalid" : ""}`,
			street: `address-spinner mb-3 ${isInvalid.street ? "is-invalid" : ""}`,
			house_number: `address-spinner mb-3 ${isInvalid.house_number ? "is-invalid" : ""}`,
		};
	}, [isInvalid]);

	const onHouoseInputChange = debounce((value) => {
		const selected: GisHouse|undefined = gisState.house_number?.find(item => item.house_number === value);
		onChange(FttbAddressEnum.house_number)(selected ? [selected] : []);
	}, 1000);
	const onStreetInputChange = debounce(() => {
		setTouched({...touched, [FttbAddressEnum.street]: true });

	}, 8000);
	const onCityInputChange = debounce(() => {
		setTouched({...touched, [FttbAddressEnum.city]: true });
	}, 8000);

	return (
		<div className="row">
			<div className="col-12 col-sm-6 col-md-4">
				<Field
					required={props.requiredShow}
					name="postal_code"
					id="post-code"
					label={labels.postal_code}
					attr={{disabled: props.disabled}}
					component={InputField}
				/>
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<label className={"w-required-field"}>{labels.region}</label>
				<Typeahead
					id="province"
					minLength={0}
					className={classes.province}
					labelKey={"name"}
					emptyLabel={intl.formatMessage(CommonMessages.noMatchesFound)}
					defaultSelected={personalInfo?.province?.selected}
					onFocus={onFocusHandle(FttbAddressEnum.province, "")}
					onChange={onChange(FttbAddressEnum.province)}
					options={gisState[FttbAddressEnum.province]}
					placeholder={placeholders.region}
					isInvalid={Boolean(isInvalid.province)}
					useCache={true}
				/>
				{isInvalid.province && (<div className="invalid-feedback mt-n3">{errors[FttbAddressEnum.province].selected}</div>)}
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<label>{labels.district}</label>
				<Typeahead
					id="district"
					ref={refs[FttbAddressEnum.district]}
					className={classes.district}
					isLoading={isLoading[FttbAddressEnum.district]}
					defaultSelected={personalInfo?.district?.selected}
					labelKey={"name"}
					emptyLabel={intl.formatMessage(CommonMessages.noMatchesFound)}
					minLength={0}
					onFocus={onFocusHandle(FttbAddressEnum.district, FttbAddressEnum.province)}
					disabled={!Boolean(values?.province?.selected?.length > 0)}
					onChange={onChange(FttbAddressEnum.district)}
					options={gisState[FttbAddressEnum.district]}
					placeholder={placeholders.district}
					isInvalid={Boolean(isInvalid.district)}
				/>
				{isInvalid.district && (<div className="invalid-feedback mt-n3">{errors[FttbAddressEnum.district].selected}</div>)}
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<label className="w-required-field">{labels.locality}</label>
				<AsyncTypeahead
					id="city"
					onInputChange={onCityInputChange}
					ref={refs[FttbAddressEnum.city]}
					className={classes.city}
					isLoading={isLoading.city}
					minLength={3}
					defaultSelected={personalInfo?.city?.selected}
					onSearch={handleSearch(FttbAddressEnum.city, FttbAddressEnum.province)}
					onChange={onChange(FttbAddressEnum.city)}
					emptyLabel={intl.formatMessage(CommonMessages.noMatchesFound)}
					options={gisState.city}
					disabled={!Boolean(values?.province?.selected?.length > 0)}
					labelKey={"name"}
					placeholder={placeholders.city}
					isInvalid={Boolean(isInvalid.city)}
					renderMenuItemChildren={(option) => <div>{option.name}</div>}
				/>
				{isInvalid.city && (<div className="invalid-feedback mt-n3">{errors[FttbAddressEnum.city]?.selected}</div>)}
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<label className={"w-required-field"}>{labels.street}</label>
				<AsyncTypeahead
					id="street"
					onInputChange={onStreetInputChange}
					ref={refs[FttbAddressEnum.street]}
					className={classes.street}
					isLoading={isLoading[FttbAddressEnum.street]}
					labelKey={"name"}
					defaultSelected={personalInfo?.street?.selected}
					onSearch={handleSearch(FttbAddressEnum.street, FttbAddressEnum.city)}
					disabled={!Boolean(values?.city?.selected?.length > 0)}
					emptyLabel={intl.formatMessage(CommonMessages.noMatchesFound)}
					onChange={onChange(FttbAddressEnum.street)}
					options={gisState[FttbAddressEnum.street]}
					isInvalid={Boolean(isInvalid.street)}
					placeholder={placeholders.district}
				/>
				{isInvalid.street && (<div className="invalid-feedback mt-n3">{errors[FttbAddressEnum.street].selected}</div>)}
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<label className={"w-required-field"}>{labels.house_number}</label>
				<Typeahead
					id="house_number"
					onInputChange={onHouoseInputChange}
					ref={refs[FttbAddressEnum.house_number]}
					className={classes.house_number}
					labelKey={"house_number"}
					defaultSelected={personalInfo?.house_number?.selected || ""}
					onFocus={onFocusHouse(FttbAddressEnum.house_number, FttbAddressEnum.street)}
					disabled={!Boolean(values?.street?.selected?.length > 0)}
					emptyLabel={intl.formatMessage(CommonMessages.noMatchesFound)}
					onChange={onChange(FttbAddressEnum.house_number)}
					options={gisState[FttbAddressEnum.house_number]}
					isInvalid={Boolean(isInvalid.house_number)}
					placeholder={placeholders.district}
				/>
				{isInvalid.house_number && (<div className="invalid-feedback mt-n3">{errors[FttbAddressEnum.house_number].selected}</div>)}
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<Field
					name="apartment"
					id="apartment"
					label={labels.apartment}
					attr={{
						maxLength: 10,
						disabled: !values.street
					}}
					component={InputField}
				/>
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<Field
					name="comments"
					id="comment"
					label={"Comments"}
					attr={{
						maxLength: 256,
						disabled: props.disabled
					}}
					component={InputField}
				/>
			</div>
		</div>
	);
};

export { AddressForm };