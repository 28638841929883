import { ChangeEvent, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import cssns from "../../../../../shared/utils/cssnsConfig";
import { Package } from "../../../../../graphql/types";
import { createMarkup } from "../../../../../shared/utils/createMarkup";
import { PackagePrices } from "../../../../packages/PackagePrice";
import {config, RECURRENT} from "../../../../../config/constants";
import {checkIfPaidGigabitPack, checkIfSBGigabitPack, isGPONFTTBPack, extractFee} from "../../../../../shared/utils";

const { React } = cssns("Optional-Product");

interface OptionProductProps {
    optionalProduct: Package;
    onChecked: (value: OptionProductChecked) => void;
    selectedOptionalProduct: Package[];
	familyName?: string|null|undefined;
	disabled?: boolean;
}
export interface OptionProductChecked {
    optionalProduct: Package;
    checked: boolean;
	familyName?: string|null|undefined;
	disabled?:boolean
}
const OptionProduct = (props: OptionProductProps) => {
	const intl = useIntl();
	const [isChecked, setIsChecked] = useState(false);
	const[disableRadioForGigabit, setDisableRadioForGigabit] = useState(false);
	const[disableCheckboxForGigabit, setDisableCheckboxForGigabit] = useState(false);
	const onChange = (value: Package, familyName: string|null|undefined) => (event: ChangeEvent<HTMLInputElement>) => {
		props.onChecked({ optionalProduct: value, checked: event.target.checked, familyName });
	};
	const { optionalProduct, familyName } = props;
	const checkboxOptionId = "id-select-" + optionalProduct.code;
	const isGigabitProduct = checkIfPaidGigabitPack(optionalProduct);
	const isOptionalGPONFTTBPack = isGPONFTTBPack(optionalProduct);
	const noOptionalProductSelected = props.selectedOptionalProduct.length === 0;
	const optionalProductIsSelected = Boolean(props.selectedOptionalProduct.find(item => item.code === optionalProduct.code));

	const checked = isOptionalGPONFTTBPack && noOptionalProductSelected ? isChecked : optionalProductIsSelected;

	useEffect(() => {
		// check the checkbox by default if it has to be auto-selected
		if (props.selectedOptionalProduct.length === 0 && isOptionalGPONFTTBPack && !isChecked && !checked) {
			setIsChecked(true);
			props.onChecked({
				optionalProduct,
				checked: true,
				familyName,
			});
		}
	}, []);

	useEffect(() => {
		const isOptionalProductSBGigabit = checkIfSBGigabitPack(optionalProduct);
		const isOptionalProductPaidGigabit = checkIfPaidGigabitPack(optionalProduct);
		const isOptionalGPONFTTBPack = isGPONFTTBPack(optionalProduct);

		const hasPaidGigabitSelected = props.selectedOptionalProduct.some(
			selectedProduct => checkIfPaidGigabitPack(selectedProduct)
		);
		const hasSBGigabitSelected = props.selectedOptionalProduct.some(
			selectedProduct => checkIfSBGigabitPack(selectedProduct)
		);
		const hasGPONFTTBSelected = props.selectedOptionalProduct.some(
			selectedProduct => isGPONFTTBPack(selectedProduct)
		);

		if (isOptionalProductSBGigabit && hasPaidGigabitSelected) {
			setDisableRadioForGigabit(true);
			setDisableCheckboxForGigabit(false);
		} else if (isOptionalProductPaidGigabit && hasSBGigabitSelected) {
			setDisableRadioForGigabit(false);
			setDisableCheckboxForGigabit(true);
		} else if (isOptionalGPONFTTBPack && hasGPONFTTBSelected){
			setDisableCheckboxForGigabit(true);
		} else {
			setDisableRadioForGigabit(false);
			setDisableCheckboxForGigabit(false);
		}
	}, [optionalProduct, props.selectedOptionalProduct]);


	const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
		setIsChecked(event.target.checked);
		onChange(optionalProduct, familyName)(event);
	};

	return (
		<tr className="w-tile-table-row">
			<td className="shrink lead"><i className="fa fa-cubes p-2 bg-gray-200"></i></td>
			<td>
				<div className="w-emphasize">{optionalProduct.name?.[intl.locale]}</div>
				<div dangerouslySetInnerHTML={createMarkup(
                    optionalProduct?.long_description?.[intl.locale] ||
                    optionalProduct?.medium_description?.[intl.locale])}
				/>
			</td>
			<td className="text-success text-right">
				<PackagePrices
					fee={extractFee(optionalProduct)}
					fee_type={RECURRENT}
					periodic_amount={optionalProduct.periodic_amount}
					periodic_unit={optionalProduct.periodic_unit}
				/>
			</td>
			{
				!((familyName === config.SUPERPOWER_FAMILY) || (familyName === config.HOME_TV_FAMILY)) ?
					<td className="actions">
						<div className="custom-control custom-checkbox">
							<input
								className="custom-control-input"
								id={checkboxOptionId}
								type="checkbox"
								checked={checked}
								onChange={onCheckboxChange}
								disabled={(checkIfPaidGigabitPack(optionalProduct) || isGPONFTTBPack(optionalProduct) ) ? disableCheckboxForGigabit : props.disabled}
							/>
							<label className="custom-control-label" htmlFor={checkboxOptionId}></label>
						</div>
					</td> :
					<td className="actions">
						<div className="custom-control custom-radio mb-2 mt-2 radio-button">
							<input
								required
								className="custom-control-input"
								type="radio"
								id={checkboxOptionId}
								checked={checked}
								onChange={onChange(optionalProduct, familyName)}
								disabled={checkIfSBGigabitPack(optionalProduct) ? disableRadioForGigabit : false}
							/>
						</div>
					</td>
			}
		</tr>
	);
};

export { OptionProduct } ;
