import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { initialLabels } from "../../../../../shared/utils";
import { RegistrationMessages } from "../../../../registration/Registration.messages";
import { InputFieldFormik } from "../../../../../shared/components";
import { FormikProps } from "../../../../../shared/types";

interface InitialAddressType {
    street: string;
    house_number: string;
    building: string;
    apartment: string;
    postal_code: string;
    locality: string;
    country: string;
    region: string;
    district: string;
}

const initialValues = {
	street: "",
	house_number: "",
	building: "",
	apartment: "",
	postal_code: "",
	locality: "",
	country: "",
	region: "",
	district: "",
};

interface AddressFormProps {
    formik: FormikProps;
}

const AddressForm = (props: AddressFormProps) => {
	const intl = useIntl();
	const labels = useMemo(() => initialLabels<InitialAddressType>(intl, initialValues, RegistrationMessages), [intl.locale]);
	return (
		<div className="row">
			<div className="col-12 col-sm-6 col-md-4">
				<InputFieldFormik
					id="post-code"
					name="postal_code"
					value={props.formik.values.postal_code}
					label={labels.postal_code}
					attr={{disabled: true}}
					formik={props.formik}
				/>
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<InputFieldFormik
					id="province"
					name="province"
					value={props.formik.values.province}
					label={labels.region}
					attr={{disabled: true}}
					formik={props.formik}
				/>
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<InputFieldFormik
					id="city"
					name="city"
					value={props.formik.values.city}
					label={labels.locality}
					attr={{disabled: true}}
					formik={props.formik}
				/>
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<InputFieldFormik
					id="house-number"
					name="house_number"
					value={props.formik.values.house_number}
					label={labels.house_number}
					attr={{disabled: true}}
					formik={props.formik}
				/>
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<InputFieldFormik
					id="street-name"
					name="street"
					label={labels.street}
					value={props.formik.values.street}
					attr={{
						maxLength: 256,
						disabled: true
					}}
					formik={props.formik}
				/>
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<InputFieldFormik
					id="apartment"
					name="apartment"
					label={labels.apartment}
					value={props.formik.values.apartment}
					attr={{
						maxLength: 256,
						disabled: true
					}}
					formik={props.formik}
				/>
			</div>
			<div className="col-12 col-sm-6 col-md-4">
				<InputFieldFormik
					id="comment"
					name="comments"
					label={"Comments"}
					value={props.formik.values.comments}
					attr={{
						maxLength: 256,
						disabled: true
					}}
					formik={props.formik}
				/>
			</div>
		</div>
	);
};

export { AddressForm };