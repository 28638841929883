import { useQuery } from "@apollo/react-hooks"
import React from "react";
import { client } from "../../../apollo";
import { LocalQuery } from "../../../graphql/localTypes";
import { GET_MODALS } from "../../../graphql/queries/localQuery"
import { CasOtpCheck } from "./otp/CasOtpCheck";

const Modals: React.FC = () => {
	const {data, refetch} = useQuery<Required<Pick<LocalQuery, "modals">>>(GET_MODALS);
	client.onClearStore(async () => {
		refetch();
	});
	return (<>
		{data?.modals?.otp.status && <CasOtpCheck/>}
	</>);
};



export {
	Modals
};