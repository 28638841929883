import React from "react";
import AcquisitionMessages from "../../../../Acquisition.messages";
import { FormattedMessage } from "react-intl";
import { PostPayForm } from "./PostPayForm";
import { PostPayPersonalForm } from "./PostPayPersonalForm";

interface PostPayFlowProps {
	formId: string;
	initialValues: any;
	withoutDocument?: boolean;
	showWithoudDocuments?: boolean; 
	onSubmit: (values: any) => void;
	onClickWithoutDocuments?: () => void;
}

const PostPayFlow = (props: PostPayFlowProps) => {
	const { onSubmit, formId, showWithoudDocuments = false } = props;

	return (
		<div className="ipos-content-box ContactInfo">
			{showWithoudDocuments && (
				<div
					className="custom-control custom-checkbox"
					onClick={props.onClickWithoutDocuments}
				>
					<input
						className="custom-control-input"
						type="checkbox"
						checked={props.withoutDocument}
					/>
					<label className="custom-control-label">
						<FormattedMessage
							{...AcquisitionMessages.withoutDocuments}
						/>
					</label>
				</div>
			)}
			<div className="mt-2">
				{props.withoutDocument ? (
					<PostPayPersonalForm
						initialValues={props.initialValues}
						onSubmit={onSubmit}
						formId={formId}
					/>
				) : (
					<PostPayForm
						initialValues={props.initialValues}
						onSubmit={onSubmit}
						formId={formId}
					/>
				)}
			</div>
		</div>
	);
};

export { PostPayFlow };
