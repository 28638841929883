import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { UNMERGE_SUBSCRIPTIONS } from "../../graphql/mutations/fmc";
import { Mutation } from "../../graphql/types";
import { ExecutionResult } from "@apollo/react-common";
import moment from "moment";

interface UnmergeVariables {
	fmcSubscriptionId: string;
	date: string;
	subscriptionCode: string;
	resellerCode: string;
	options: string[];
}
export enum ChangeTariffActivationType {
	IMMEDIATE = "IMMEDIATE",
	NEXT_MONTH = "NEXT_MONTH",
}


const useUnpairFmc = (): {
	unpairFmc: (args: UnmergeVariables) => Promise<ExecutionResult<Required<Pick<Mutation, "create_fmc_order">>>>
} => {

	const [unpair, {data}] = useMutation<Required<Pick<Mutation, "create_fmc_order">>, UnmergeVariables>(UNMERGE_SUBSCRIPTIONS);

	return {
		unpairFmc: (args: UnmergeVariables) => unpair({
			variables: args 
		})
	}
}

export {useUnpairFmc}